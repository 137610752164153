import React from "react";


const useCheckForm =(values, errors, properties)=>{
    const [formCompleted, setFormCompleted] = React.useState(false)
    const [errorCount, setErrorCount] = React.useState(0);
    

    React.useEffect(()=>{        
        let errorCount = 0;            
        for(let errorKey in errors){
            errors[errorKey] && errorCount++ ;
        }
        setErrorCount(errorCount)
        
        let completedFields = 0;
        for(let property of properties){
            
            //check if property is array
            if(Array.isArray(values[property])){
                //console.log("it is an array");
                values[property].length > 0 && completedFields++
            }else{                
                values[property] && completedFields++;         
            }
            
        }
        // console.log(completedFields);
        if(completedFields === properties.length){
            setFormCompleted(true)
        }else{
            formCompleted && setFormCompleted(false)
        }

        // console.log(formCompleted)
        // console.log(errorCount)
        
}, [errors, values, formCompleted, properties])


    return [formCompleted, errorCount]
}


export default useCheckForm;