import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    activities: [],
    isLoading: true,
    scrollPosition: null,
    nextCursor: null

}

export const activitySlice = createSlice({
    name:"activity",
    initialState,
    reducers: {
        addActivities(state, action){           
            state.activities = action.payload;
            state.isLoading = false;            
        },
        addNextActivities(state, action){           
            state.activities = [...state.activities, ...action.payload];
            state.isLoading = false;            
        },
        setNextCursor(state,action){
            state.nextCursor = action.payload
        },
        setActivityScrollPosition(state, action){
            state.scrollPosition = action.payload;
        },
        resetActivityScrollPosition(state, action){
            state.scrollPosition = 0;
        }           
    }
})

const {actions, reducer} = activitySlice

export const {addActivities, addNextActivities, setNextCursor, setActivityScrollPosition, resetActivityScrollPosition} = actions;

export default reducer;