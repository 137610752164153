import styled from '@emotion/styled/macro';
import { CardWrapper } from './Card';





const ResultBox= ({
    title,
    result,
    category
})=>{

    return(
        <ResultBoxWrapper>
            <Title>{title}</Title>
            <Result>{result}</Result>
            <Category>{category}</Category>
        </ResultBoxWrapper>

    )

}



const ResultBoxWrapper = styled(CardWrapper)`
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding:8px;
`


const Title = styled.p`
    margin: 0;
    font-size:0.7rem;
    flex:1;
    color: ${p=>p.theme.palette.grey[600]}
`

const Result = styled.p`
    margin: 0;
    font-size: 1.5rem;
    flex:2;
    color: ${p=>p.theme.palette.grey[800]}
`

const Category = styled.p`
    margin: 0;
    font-size: 0.7rem;
    flex:1;
    color: ${p=>p.theme.palette.grey[600]}
`

export const ResultBoxGrid = styled.div`
    flex:1;    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
    grid-template-rows: repeat(auto-fill, minmax(100px, auto));
    gap: 8px;        
`


export default ResultBox;