import Modal from "../../../Atoms/Modal";
import Filtro from "../../../cirugias/Filtro";
import AgendarCirugia from "../../../Forms/AgendarCirugia";
import ModifyCentro from "../../../Forms/ModifyCentro";
import SuspenderCirugia from "../../../Forms/SuspenderCirugia";
import Comentarios from "./Comentarios";
import NegativeReview from "./NegativeReview";
import PositiveReview from "./PositiveReview";
import ProductTableMd from "./ProductTableMd";



const CirugiasModalMd = ({
    openModal,
    setOpenModal,
    inProp,
    setInProp,
    close,
    formType,
    cirugiaId
})=>{

    const smallBottomSwipeDrawers = ["thumbsUp"]

return(
    <Modal
            openModal={openModal}
            setOpenModal={setOpenModal}
            inProp={inProp}
            setInProp={setInProp}
            close={close}
            smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
        >
            {formType === "agendar" && <AgendarCirugia cirugiaId={cirugiaId} close={close}/>}
            {formType === "suspender" && <SuspenderCirugia cirugiaId={cirugiaId} close={close}/>}
            {formType === "modifyCentro" && <ModifyCentro cirugiaId={cirugiaId} sclose={close}/>}
            {formType === "productos" && <ProductTableMd cirugiaId={cirugiaId} close={close}/>}
            {formType === "filtro" && <Filtro/>}
            {formType === "thumbsUp" && <PositiveReview cirugiaId={cirugiaId} close={close}/>}
            {formType === "thumbsDown" && <NegativeReview cirugiaId={cirugiaId} close={close}/>}
            {formType === "comentarios" && <Comentarios cirugiaId={cirugiaId} close={close}/>}
             
         </Modal>     

)
}


export default CirugiasModalMd;