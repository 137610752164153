import {createSlice } from '@reduxjs/toolkit';


//se crean dos pacientes, uno para los autocomplete y otro para el listado de pacientes en search

const initialState= {
    pacientes:[],
    pacientesSearch:[],
    isLoading: false,
    searchValue:"",
    scrollPosition: null
}

export const pacientesSlice = createSlice({
    name:"pacientes",
    initialState,
    reducers: {
        addPacientes(state, action){
            //console.log(action.payload);
            state.pacientes = action.payload;
            state.isLoading= false;            
        },
        addPacientesSearch(state,action){
            state.pacientesSearch = action.payload;
            state.isLoading= false;            
        },
        addUpdatedPaciente(state,action){                          
            state.pacientesSearch.forEach((paciente, index) => {                
                if(paciente._id === action.payload._id){                         
                    state.pacientesSearch[index] = action.payload
                }
                return                
            });
        },
        pacientesIsLoading(state, action){
            state.isLoading = true;
        },
        resetPacientes(state,action){
            state.pacientes=[];
        },
        setPacientesScrollPosition(state, action){
            state.scrollPosition = action.payload;
        },
        resetPacientesScrollPosition(state, action){
            state.scrollPosition = 0;
        },
        setSearchValue(state,action){
            state.searchValue = action.payload
        }      

    }
})

const {actions, reducer} = pacientesSlice

export const {addPacientes, addPacientesSearch, addUpdatedPaciente, pacientesIsLoading, resetPacientes, setPacientesScrollPosition, resetPacientesScrollPosition,
                setSearchValue } = actions;

export default reducer;