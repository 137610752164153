import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/system";
import ChevronDown from "../Icons/ChevronDown";
import Button from "./Button";
import styled from '@emotion/styled';
import React from "react";
// import {
//   Menu,
//   MenuList,
//   MenuButton,
//   MenuItem,
//   MenuItems,
//   MenuPopover,
//   MenuLink,
// } from "@reach/menu-button";
// import styled from '@emotion/styled/macro';
//import {StyledMenu, StyledSpan, StyledMenuButton, StyledMenuItem, StyledMenuList}from './StyledDropdownReach'

//   const DropDownButton = ({
//     setOpenModal,
//     setFormType,
//     cirugiaId,
//     setCirugiaId
//   })=>{
//     let theme = useTheme();
//     let buttonColor= theme.palette.grey[600];
//     let buttonColorHover= theme.palette.grey[400];
//     let textColorHover = "white"

//     const onSelect = (formType)=>{
//       setOpenModal(true);
//       setFormType(formType);
//       setCirugiaId(cirugiaId);
//     }


//     return (
//         <Menu>
//             {({isExpanded})=>{              
//                 isExpanded ? document.querySelector('body').style.overflow='hidden' :
//                             document.querySelector('body').style.overflow='visible'
//                 return(
//                     <>
//                     <StyledMenuButton
//                         style={{
//                             "--button-color": buttonColor,
//                             "--button-color-hover": buttonColorHover,
//                             "--text-color-hover": textColorHover
//                         }}             
//                     >
//                     <StyledSpan>Editar</StyledSpan>
//                     <ChevronDown size={20} color={buttonColor}/>
//                     </StyledMenuButton>
//                     <StyledMenuList>
//                         <StyledMenuItem onSelect={()=>onSelect('agendar')}>Fecha/Hora</StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>onSelect("modifyDoctor")}>Doctor</StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>onSelect("modifyCentro")}>Centro</StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>onSelect("modifyPago")}>Pago</StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>onSelect("modifyProductos")}>Productos</StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>onSelect("modifyVentas")}>Ventas</StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>{}}>Info Adic.</StyledMenuItem>
//                     </StyledMenuList>
//                 </>
//                 )
//             }}       
//         </Menu>
//     )
//   }

// const StyledSpan = styled.span`
// font-weight: 600;
// color: var(--button-color);
// font-size: .875rem;
// line-height: 1.75;
// `

// const StyledMenuButton = styled(MenuButton)`
//   padding: 5px 15px;
//   border-radius: 4px;
//   min-width: 64px;
//   max-height: 35px;
//   background-color: transparent;
//   border: 1px solid var(--button-color);
//   text-transform: none;
//   display: inline-flex;
//   align-items: center;
//   vertical-align: middle;
//   justify-content: center;
//   transition: transform 200ms ease-in;
//   transition: background-color 200ms ease-in;

//   &:hover{
//       transform: scale(1.03);
//       background-color: var(--button-color-hover);
//       box-shadow: 2px 2px 8px ${p=>p.theme.palette.grey[300]};
//       ${StyledSpan}{
//           color: var(--text-color-hover);
//       }
// }   
// `

// const StyledMenuList = styled(MenuList)`
//   position:relative;
//   z-index:1;
//   background-color: #ffff;
//   box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 
//   0px 3px 4px 0px rgb(0 0 0 / 14%), 
//   0px 1px 8px 0px rgb(0 0 0 / 12%);
//   & > [data-reach-menu-item][data-selected] {
//     background-color: ${p=>p.theme.palette.grey[300]};
    
//   }

// `

// const StyledMenuItem = styled(MenuItem)`
// padding: 12px 18px;
// font-size: 0.875rem;

// &:hover{
//    background-color: ${p=>p.theme.palette.grey[300]}
// };

// `

  
const DropDownButton = ({
  setOpenModal,
  setFormType,
  cirugiaId,
  setCirugiaId
})=>{
  let theme = useTheme();
  

  const onSelect = (formType)=>{
    setOpenModal(true);
    setFormType(formType);
    setCirugiaId(cirugiaId);
    setAnchorEl(null);
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }

  return (
      
                  <>
                  <Button
                    outline
                    postIcon={<ChevronDown size={20} color={theme.palette.grey[600]}/>}
                    onClick={onClickHandler}
                  >
                  <StyledSpan>Editar</StyledSpan>
                  
                  </Button>
                  <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onCloseHandler}
                  >
                      <MenuItem onClick={()=>onSelect('agendar')}>Fecha/Hora</MenuItem>
                      <MenuItem onClick={()=>onSelect("modifyDoctor")}>Doctor</MenuItem>
                      <MenuItem onClick={()=>onSelect("modifyCentro")}>Centro</MenuItem>
                      <MenuItem onClick={()=>onSelect("modifyPago")}>Pago</MenuItem>
                      <MenuItem onClick={()=>onSelect("modifyProductos")}>Productos</MenuItem>
                      <MenuItem onClick={()=>onSelect("modifyVentas")}>Ventas</MenuItem>
                      <MenuItem onClick={()=>{}}>Info Adic.</MenuItem>
                  </Menu>
              </>

  )
}

export const StyledSpan = styled.span`
font-weight: 600;
color: var(--button-color);
font-size: .875rem;
line-height: 1.75;
`

  export default DropDownButton;