import styled from '@emotion/styled';

export const CardWrapper = styled.div`
    background-color: #ffff;
    border-radius: 4px;
    max-width: 100%;
    padding: 16px;
    box-shadow: 0.2px 0.3px 0.4px hsl(0deg 0% 63% / 0.41),
    0.8px 1px 1.4px -0.8px hsl(0deg 0% 63%/ 0.41),
    2px 2.5px 3.6px -1.7px hsl(0deg 0% 63% / 0.41),
    4.9px 6.2px 8.9px -2.5px hsl(0deg 0% 63% / 0.41);
    margin:8px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
`

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;

`
export const CardTitle = styled.h3`
    margin: 0px;
    margin-bottom: 16px;
`


export const CardBody=styled.div`
    flex:2;
`

export const CardActions = styled.div`
    display:flex;
    width:100%;
    min-height: 65px; 
    align-items: center;
    justify-content: ${p=>p.justifyContent && p.justifyContent};
`