import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';




const InviteMd = ()=>{
    const theme = useTheme();
    return(
        <MainRowWrapper reverse={true} style={{position: 'relative', overflow:'hidden'}}>
        <ImageWrapper style={{zIndex: '2'}} >
                <Image  style={{maxHeight: '400px'}} src="/img/Enviar Invitacion-01.png"/>
        </ImageWrapper>
        <Info style={{alignItems: "flex-start", gap:'0px', zIndex:'2'}}>
            <Icons style={{maxWidth: '75px'}} src='/img/Icono_invitacion.png'/>   
            <MainTitle><span style={{color: theme.palette.success.light, fontWeight:300, textAlign: 'start'}}>Invita a tus medicos a </span><span style={{color: theme.palette.primary.dark}}>ver y agendar sus cirugias</span></MainTitle>                        
        </Info>
        <ImageFondo src='img/fondo.png'/>    
    </MainRowWrapper>   

    )
}


const MainRowWrapper = styled.div`
    display:flex;
    
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 36px;
    

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
        gap: 48px;
    }

`


const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 12px;    
    width:100%;
    padding: 8px;        
`


const MainTitle=styled.h1`
    margin:0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    color: ${p=>p.theme.palette.grey[800]};
    font-size: 2.5rem;
    text-align: start;
`


const ImageWrapper = styled.div`
    flex: 1;
    width:100%;
    height:100%;
    min-width:350px;
    display:flex;
    flex-direction: column;
    padding: 24px;
`

const Image = styled.img`    
    width:100%;
    height: 100%;
    object-fit:contain;
`



const Icons = styled.img`
    max-width: 50px;
    margin-left: -12px;
`


const ImageFondo = styled.img`
    position:absolute;
    object-fit:contain;
    aspect-ratio: 1/1;
    

    @media ${p=>p.theme.media.tablet}{
        top:-300px;
        left: -300px;
        height: 300%;
    }
`

export default InviteMd;