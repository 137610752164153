import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEC, fetchUsers } from "../../../app/thunkActions/usersActions";
import StyledAutocomplete from '../../Atoms/StyledMUIAutocomplete';
import { TextField } from '@mui/material';


const EspecialistasAutocomplete = ({
    onChange
})=>{

    const dispatch = useDispatch();
    const ec = useSelector(state=>state.users.ec)
    
    React.useEffect(()=>{
        dispatch(fetchEC())
    },[dispatch])
    

   /* return(
        <Autocomplete                  
            label="Centro"
            name="centro"
            id="centro"
            onChange={onChange}
            options={centros}
            getOptionLabel={(option)=>option.nombre?option.nombre : ""}
        />
    )

    */

    return (
        <StyledAutocomplete
            id="ventasAndEc"
            name="ventasAndEc"
            onChange={onChange}
            options={ec}
            getOptionLabel={(option)=>`${option.firstname} ${option.lastname}`}
            renderInput={(params) => <TextField {...params} label="Especialistas" />}
        />

    )

}


export default EspecialistasAutocomplete;