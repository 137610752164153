import styled from '@emotion/styled';
import { IconButton } from "@mui/material"
import ChevronLeft from "../Icons/ChevronLeft"



const BackButton = ({
    onClick,
    to,
    style
})=>{

    return(
        <Wrapper style={style}>        
            <IconButton to={to} onClick={onClick}>
                <ChevronLeft size="2rem" />
            </IconButton>
        </Wrapper>
    )
}


const Wrapper = styled.div`

`


export default BackButton;