import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';




const NotifyAndFilter = ()=>{
    
    return(
        <MainRowWrapper>                        
            <ImageWrapper>
                <Image  src="/img/imagen_asignar.png"/>
                <IconWrapper>
                    <Icons src='/img/Icono_notificacion_campana.png'/>
                    <MainTitle>Notifica a tus especialistas clinicos que tienen una cirugia asignada.</MainTitle>
                </IconWrapper>                
            </ImageWrapper>
            <ImageWrapper>
                <Image src="/img/Imagen_Filtrar.png"/>
                <IconWrapper>
                    <Icons src='/img/Icono_filtrar.png'/>
                    <MainTitle>Filtra las cirugias rapidamente</MainTitle>
                </IconWrapper>
                
            </ImageWrapper>
        </MainRowWrapper>       

    )
}


const MainRowWrapper = styled.div`
    display:flex;
    
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
        gap: 48px;
    }

`


const MainTitle=styled.h1`
    margin:0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    color: ${p=>p.theme.palette.primary.dark};
    font-size: 1.5rem;
    font-weight: 300;
`


const ImageWrapper = styled.div`
    flex: 1;
    width:100%;
    
    min-width:350px;
    display:flex;
    flex-direction: column;
`

const Image = styled.img`    
    width:100%;
    height: 100%;
    object-fit:contain;
    max-width: 400px;
    align-self:center;
`

const IconWrapper = styled.div`
    display:flex;
    align-items:center;
    gap: 8px;
    padding: 16px 32px;
    justify-content: center;
`

const Icons = styled.img`
    max-width: 75px;
`


export default NotifyAndFilter;