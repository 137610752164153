import React from 'react';
import styled from '@emotion/styled';

const SmallButton = React.forwardRef(({children, onClick, type="button"},ref)=>{

    return(
        <StyledButton onClick={onClick} ref={ref} type={type}>
            {children}
        </StyledButton>
    )

})


const StyledButton = styled.button`
    background-color: transparent;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${p=>p.theme.palette.grey[200]}
    }
`




export default SmallButton;