import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card"




const ReturnFromPortal = ()=>{
    
    const params = useParams()
    const {checkIfSubscribed} = useSubscriptionToken()
    const navigate = useNavigate();

    React.useEffect(()=>{        
        checkIfSubscribed()
        navigate('/app/admin/')        
    },[checkIfSubscribed, navigate])


    console.log(params)

    return (
        <Wrapper>
            <CardWrapper>
                <CardTitle>Revisando Status de Suscripction. </CardTitle>
                <CardBody>
                    
                </CardBody>
            </CardWrapper>

        </Wrapper>

    )
}



const Wrapper = styled.div`
`


export default ReturnFromPortal;