import styled from '@emotion/styled';
import { FormControlRadio, Radio } from '../../Atoms/Radio';



const PersonaRadio = ({
    persona,
    onChangeRadio
}) => {
    console.log('persona', persona)

    return (
        <RadioButtonWrapper>                
        <FormControlRadio
            value={persona}
            onChange={onChangeRadio}
        >
            <Radio
                label="Juridica"
                id="juridica"
                name="juridica"
                value="juridica"
                checked={persona === "juridica"}
            />
            <Radio
                label="Natural"
                id="natural"
                name="natural"
                value="natural"   
                checked={persona === "natural"}                     
            />                
     </FormControlRadio>        
     
    </RadioButtonWrapper>

    )

}


const RadioButtonWrapper = styled.div`
  
`


export default PersonaRadio