import Autocomplete from '@mui/material/Autocomplete';
import styled from '@emotion/styled/macro';


const StyledAutocomplete = styled(Autocomplete)`
& .MuiInputLabel-root{
    font-size: 0.875rem;
    font-weight: 500;
};
& .MuiOutlinedInput-root{
    padding: 9px;
}
& .MuiInputLabel-root.Mui-focused{
    transform: translate(14px, 4px) scale(0.70)
};
& .MuiInputLabel-root.MuiFormLabel-filled{
    transform: translate(14px, 4px) scale(0.70)
};
& fieldset{
    border-radius:12px;
    font-size:1rem;
    top:0;
};
& legend{
    display:none;
};
`

export default StyledAutocomplete;