import { apiUrl } from "../shared/url"
import { getCookie, signout } from "../shared/authHelpers";
import { useDispatch } from "react-redux";
import { errorMessage } from "../app/messagesReducer";
import {logoutSuccess} from "../app/authReducer"


export const createOptions = (method, body)=>{
    const token = getCookie('token');
   // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MTNmNjk0MGZlMDlhYzIzMTYwMTIzYjgiLCJjb21wYW55SWQiOiI2MTNiOTdhNGVjNmMzYmY0OTNkNTlhYTAiLCJpYXQiOjE2NDMyMTM1MDcsImV4cCI6MTY0MzM4NjMwN30._cUXWKBqh4gHWXc3Jm40j2ToXjZfQElB1KAvuEiidB4'
    const options = {
        method: method,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`bearer ${token}`
        }
    }

    if(method === 'POST' || method === "PUT"){
        options.body = JSON.stringify(body);
    }

    return options
}

//returns response in json format, checks for authroization,dispatches logout or error
export const goFetch = async (url,options, dispatch)=>{

    try{
        const response = await fetch(url,options).catch(err=>console.log(err));
        
        if(response.ok){            
            return response.json()
        }
    
        console.log(response.status);
        if(response.status===401){
            console.log('error 401')
            signout(); // clears cookie and localstorage
            dispatch && dispatch(logoutSuccess()); //changes state which triggers authenticated route
            throw new Error("Not Authorized")
        }

        return response.json();
    }catch(error){
        console.log(error)
        return {error: "Hubo un error en el servidor. Por favor intente mas tarde."}
    }
        

    
    
   
   

   

   

   
    // console.log(response.status);
    // const message = await response.json();
    // console.log(message)
    // //dispatch(errorMessage(message.error || 'Error con el servidor. Por favor intente mas tarde.'));        
    // return new Error({error:message.error})
    
}


