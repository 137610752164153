import React from "react"
import PermissionsContext from "../context/permissions-context"




const usePermissions = ()=>{


    return React.useContext(PermissionsContext)
}



export default usePermissions