import React from 'react';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import useCirugia from '../../../hooks/useCirugia';
import Button from '../../Atoms/Button';
import styled from '@emotion/styled';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import useMediaQuery from '../../../hooks/useMediaQuery';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import dateFormat from '../../../shared/dateFormatCalendar';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { es } from 'date-fns/locale';
import XCircle from '../../Icons/X-circle';
import ThumbsUp from '../../Icons/ThumbsUp';
import { useTheme } from '@mui/material';
import Modal from '../../Atoms/Modal';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import AgendarCirugia from '../../Forms/AgendarCirugia';


const CheckAvailability = ({cirugiaId, close}) => {
    const isTablet = useMediaQuery('tablet');    
    const theme = useTheme();
    
    //map over productos and get the codigo
    const cirugia = useCirugia(cirugiaId);
    const productos = cirugia.productos.map(producto=>producto.producto.codigo)

    const [isLoading, setIsLoading] = React.useState(false);
    const [successEvent, setSuccessEvent] = React.useState(false);
    
    const [dias, setDias] = React.useState([])
    const [noReglas, setNoReglas] = React.useState(false);
    const {openModal, setOpenModal, inProp, setInProp, close: closeModal} = useModalAndDrawer();
    const smallBottomSwipeDrawers = ["agendar"];
    const [formType, setFormType] = React.useState("");
    const [agendarDate, setAgendarDate] = React.useState(null);


    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
    
    const checkAvailability = async(event)=>{   
      event.preventDefault() 
        const options = createOptions('POST',{productos, fecha:Date()})

        console.log(options)
        let response;          
        try{
            setIsLoading(true)
            response = await goFetch(apiUrl+ "agenda/checkAvailability",options );
            if(response.noReglas === true){                
                setDias([])
                setNoReglas(true)       
                setIsLoading(false)
                return         
            }            
            setDias(response.dias )
            setIsLoading(false)

        }catch(error){
            console.log(error);
            setIsLoading(false)
        }        
    }
    
    
  

    React.useEffect(()=>{
       // checkAvailability()
    },[])


  const onClickHandler = (zonedDate, disponible)=>{
    console.log("clicked")
    if(!disponible) return
    setOpenModal(true)
    setFormType("agendar")
    setAgendarDate(zonedDate)
    console.log(zonedDate)
    console.log(formType)
  }

  return (
    // JSX markup goes here
    <>
    <FormWrapper onSubmit={checkAvailability}>
      <ModifiedFormTitle>Disponibilidad</ModifiedFormTitle>
      <FormBody isTablet={isTablet} style={{height: "90%"}}>
        
         {!noReglas && dias.length === 0 && 
         <Wrapper>
          <Text>Puedes verificar los dias disponsibles para esta cirugia. </Text> 
         </Wrapper>
}
        {noReglas &&
          <Wrapper>
          <Text>Ningun producto en esta cirugia tiene reglas limitando el numero de casos por dia. El equipo de operaciones comunicara si no hay disponibilidad. </Text>
        </Wrapper>  
        }
        
      

        {dias.length> 0 && dias.map((dia, index) => {
        
        const date = new Date(dia.fecha);
        const disponible = dia.disponible
        const color = disponible ? theme.palette.success.light : theme.palette.error.light
        
        const zonedDate = utcToZonedTime(date, 'America/Santo_Domingo');  
        return(
          <Wrapper key={`${dia}${index}`}>
            <Button 
              onClick={()=>onClickHandler(zonedDate, disponible)} 
              fullsize={true}  
              success={disponible}
              error={!disponible}

              >
                {capitalizeFirstLetter(format(zonedDate, 'EEEE, dd-MM-yy', {locale:es})) }
              </Button>
            {disponible ? <ThumbsUp color={color}/> : <XCircle color={color}/>  
                        
            }
          </Wrapper>
        )
        
        }
      
        )}
        
       </FormBody>
      
      <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                successText={"Revisar disponibilidad"}
                loading={isLoading}
                type="submit"
                as="button"
                
           />
    </FormWrapper>

    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      inProp={inProp}
      setInProp={setInProp}
      close={closeModal}
      smallDrawer = {smallBottomSwipeDrawers.includes("agendar")}
      >
      {formType === "agendar" && <AgendarCirugia cirugiaId={cirugiaId} close={close} inputDate={agendarDate}/>}
      
    </Modal>      

    </>

  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin: 2px 0;  
`;


const DateItem = styled.div`
  background-color: ${p=>p.color};
  color: white;
  font-weight: 600;
  padding: 10px;
  border-radius: 4px;
  margin: 5px 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Text = styled.p`
  color: ${p=>p.theme.palette.grey[700]};
  margin: 0;
  margin-bottom: 8px;
`;

const ModifiedFormTitle = styled(FormTitle)`
  
  margin-bottom: 16px;
`;

export default CheckAvailability;
