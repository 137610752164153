import React from "react";
import styled from '@emotion/styled';
import useSubscriptionToken from "../../../../hooks/useSubscriptionToken";
import { StyledCardSuccess } from "../../../Atoms/CardSuccess"
import { useSelector } from "react-redux";
import { StyledCardError } from "../../../Atoms/CardError";
import Button from "../../../Atoms/Button";
import { customerPortalSession } from "../../../../shared/customerPortalSession";




const SubscribedUsers = ()=>{

    const {numberOfUsers:subscribedUsers, plan} = useSubscriptionToken();
    const { activeUsers} = useSelector(state=>state.users);
    console.log(activeUsers);

    const numberOfActiveUsers = activeUsers?.length;

    if(plan === 'trial'){
        return (
            <CardSucessStyled>
                {`Tiempo de prueba de la aplicacion`}
            </CardSucessStyled>
        )
    }
    
    if(!activeUsers || !subscribedUsers) return

    if(numberOfActiveUsers > subscribedUsers){
        return (
            <CardErrorStyled>
                {`${numberOfActiveUsers} usuarios activos de ${subscribedUsers} usuarios suscritos. Actualizar suscripcion.`}
                <Button onClick={customerPortalSession} primary outline>Gestionar suscripcion</Button>
            </CardErrorStyled>
        )    
    }

    return (
        <CardSucessStyled>
            {`${numberOfActiveUsers} usuarios activos de ${subscribedUsers} usuarios suscritos`}
        </CardSucessStyled>
    )
}


const CardSucessStyled = styled(StyledCardSuccess)`    
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background-color: white;
    font-weight: 400;
    @media ${p=>p.theme.media.tablet}{
        flex-direction: row;
        align-self:flex-end;

     } 
`


const CardErrorStyled = styled(StyledCardError)` 
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    font-weight: 400;
    
    background-color: white;

    @media ${p=>p.theme.media.tablet}{
       flex-direction: row;
       align-self:flex-end;
    } 

`






export default SubscribedUsers