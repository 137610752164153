
import Chip from '../../Atoms/Chip';
import styled from '@emotion/styled';
import { useTheme } from '@mui/system';

const EspecialistaChip = ({especialista})=>{
    const theme = useTheme()

    if(!especialista?.asignado){
        return null;
    }

    let backgroundColor;
    if(especialista.asignado) backgroundColor = theme.palette.warning.light;
    if(especialista.confirmado) backgroundColor = theme.palette.success.light;
    if(especialista.rechazado) backgroundColor = theme.palette.error.light;
    
    return(        
        <Chip color={backgroundColor} label={especialista.nombre.firstname} />
    )
}



export default EspecialistaChip;