

const getDate = ()=>{
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    const msToAdd = 1*24*60*60*1000;
    let tomorrow = new Date(today.getTime() + msToAdd )

    const msToSubtract = 1*24*60*60*1000;
    let yesterday = new Date(today.getTime() - msToSubtract )

    //provides end of day date and time
    const startOfDay = (startDate)=>{        
        return new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), new Date(startDate).getDate())
    }

    const endOfDay =(endDate)=>{             
        let _endDate = new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate())
         const msToAdd = 1*24*60*60*999; // used to be 1000, but that would take us to the next day.
        return new Date(_endDate.getTime() + msToAdd )
    }

    return [today.toString(), tomorrow.toString(), yesterday.toString(), startOfDay, endOfDay]
}



export default getDate