import styled from '@emotion/styled';
import { FormControlRadio, Radio } from '../Atoms/Radio';


const CurrencyRadio = ({
    currency,
    onChangeRadio
}) => {


    return (
        <RadioButtonWrapper>                
        <FormControlRadio
            value={currency}
            onChange={onChangeRadio}
        >
            <Radio
                label="Pesos"
                id="pesos"
                name="pesos"
                value="pesos"
                checked={currency === "pesos"}
            />
            <Radio
                label="Dolares"
                id="dolares"
                name="dolares"
                value="dolares"   
                checked={currency === "dolares"}                     
            />                
     </FormControlRadio>        
     
    </RadioButtonWrapper>

    )

}


const RadioButtonWrapper = styled.div`
  
`


export default CurrencyRadio