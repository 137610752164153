import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetCirugias } from '../../../app/cirugiasReducer';
import Button from '../../Atoms/Button';
import Chip from '../../Atoms/Chip';
import Search from '../../Icons/Search';


const SearchButton = ()=>{
    const dispatch = useDispatch()

    return(
        <Wrapper>        
            <Button 
                fullsize="true" 
                primary 
                to={'search'}
                onClick={()=>dispatch(resetCirugias())}
                preIcon={<Search color="#ffff" size="18"/>}
            >Buscar
            </Button>     

            </Wrapper>               
    )
}

const Wrapper = styled.div`
width:100%
display:flex;
`


export default SearchButton;


