import styled from '@emotion/styled/macro';



const ProgressBar = ({
    progress
})=>{

    console.log(progress===100);

    if(!progress){
        return(null);
    }

    if(progress === 100){
        return null;
    }
    
    return(
        <Track>
            <Bar progress={progress} />
        </Track>
    )


}


const Track = styled.div`

    width: 100%;
    height: 16px;
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    border-radius: 15px;
    box-shadow: inset -2px 2px 4px  ${p=>p.theme.palette.grey[500]};
    display:flex;
    align-items: center;
    padding-left: 1px;
    padding-right: 2px;
`

const Bar = styled.div`
    width: ${p=>p.progress}%;
    height:10px;
    border-radius: 15px;
    background-color: ${p=>p.theme.palette.success.light};

`



export default ProgressBar;