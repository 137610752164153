import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { set } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../Atoms/BackButton';
import Button from '../Atoms/Button';
import TextInput from '../Atoms/TextInput';
import ConfirmarRegistro from './molecules/ConfirmarRegistro';
import CountrySelect from './molecules/CountrySelect';
import { isEmail, isLength, isMaxLength, validate } from '../../shared/validators';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import CardSuccess from '../Atoms/CardSuccess';
import useSuccessButton from '../../hooks/useSuccessButton';
import AlertMessages from '../molecules/AlertMessages';


const RegisterCompany =()=>{
    const navigate = useNavigate();
    //const [isLoading, setIsLoading] = React.useState(false);
    const [registrationSent, setRegistrationSent] = React.useState(false);
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    const [number, setNumber] = React.useState(0)
    const [values, setValues] = React.useState({
        pais: "",
        email: "",
        companyName: "",
        firstname: "",
        lastname: "",
        password:""
    })

    const [errors, setErrors] = React.useState({
        cotizacion: ""
      })

      console.log(errors);

    const onChange = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setValues(prevState => ({...prevState, [name]:value}))

    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }


    const registerCompany = async ()=>{
        setIsLoading(true);
        const options = createOptions('POST', values);

        const response = await goFetch(`${apiUrl}users/signup`, options)
        //setIsLoading(false);
        responseStateChanges(response,()=>setRegistrationSent(true),null)
        
        console.log(response);

    }


    const options = [
        {
            question: "En que pais esta ubicada la compañia?",
            field: "pais",
            component: <CountrySelect
                            name={"pais"}
                            id={"pais"}
                            value={values.pais}
                            onChange={onChange}                            
                        />
            
        },
        { question: "Cual es tu email?",
            field: "email",
            component: <TextInput 
                            name={"email"}
                            value={values.email}
                            onChange={onChange}
                            placeholder="Respuesta..."
                            helperText={errors.email}
                            error={errors.email}
                            onBlurValidation={(event)=>onBlur(event, [isEmail])}
                        />
            
        },
        { question: "Cual es el nombre de tu compañia?",
            field: "companyName",
            component: <TextInput 
                            name={"companyName"}
                            value={values.companyName}
                            onChange={onChange}
                            placeholder="Respuesta..."
                            helperText={errors.companyName}
                            error={errors.companyName}
                            onBlurValidation={(event)=>onBlur(event, [isLength])}
                        />
        },
        { question: "Cual es tu nombre?",
            field: "firstname",
            component: <TextInput 
                            name={"firstname"}
                            value={values.firstname}
                            onChange={onChange}
                            placeholder="Respuesta..."
                            helperText={errors.firstname}
                            error={errors.firstname}
                            onBlurValidation={(event)=>onBlur(event, [isLength])}
                        />
        },
        { question: "Cual es tu apellido?",
            field: "lastname",
            component: <TextInput 
                            name={"lastname"}
                            value={values.lastname}
                            onChange={onChange}
                            placeholder="Respuesta..."
                            helperText={errors.lastname}
                            error={errors.lastname}
                            onBlurValidation={(event)=>onBlur(event, [isLength])}
                        />
            
        },
        { question: "Elige tu contraseña?",
            field: "password",
            component: <TextInput 
                            name={"password"}
                            value={values.password}
                            onChange={onChange}
                            placeholder="Respuesta..."
                        />
        }
    ]

    

    

    return (
        <Wrapper>            
            <HeaderWrapper>                                        
                    <Logo src='/img/xirugias_logo_512x512.png' alt="company logo"/>                    
            </HeaderWrapper>
            <Body>
            {number === options.length &&
                <ConfirmarRegistro values={values}/>
            }
            
            { number < options.length &&
                <Box>                
                    {    
                        options.map((option, index)=>{

                            if(index === number){
                                return(
                                    <>
                                    <Text>{option.question}</Text>
                                    {option.component}
                                    </>
                                )
                            }else{
                                return null
                            }
                            
                        })
                    }
                </Box>
            }
        
        { !registrationSent ?
            <Actions>

                { number !== 0 ?
                    <Button outline tabIndex={1}
                        onClick={()=>setNumber(prevState=>prevState-1)}
                    >Volver</Button>

                    :
                    <Button outline tabIndex={1}
                        onClick={()=>navigate(-1)}
                    >Volver</Button>
                }

                
                
                {number < options.length ?
                    
                    <Button success 
                        disabled={!values[options[number].field] || errors[options[number].field]}
                        onClick={()=>setNumber(prevState=>prevState+1)}
                        onKeyDown={(e)=>{if(e.keyCode === 13)setNumber(prevState=>prevState+1)}}
                    >Siguiente</Button>
                :
                    <Button success 
                        loading={isLoading}
                        onClick={registerCompany}
                    >Registrar compañia</Button>
                }
                
            </Actions>
            :
            <Actions>
                <SuccessWrapper>
                    <CardSuccess>
                        Tu informacion fue enviada exitosamente. Pronto te enviarmos un email para que puedas acceder a tu cuenta. 
                    </CardSuccess>
                    <Button tabIndex={1} primary
                        onClick={()=>navigate('/')}
                    >Volver</Button>
                </SuccessWrapper>
                
                
            </Actions>    
        }
                {number < options.length &&
                    <Counter>{`${number + 1} de ${options.length}`}</Counter>
                }
            </Body>
            <AlertMessages/>
        </Wrapper>
    )
}


const slideLeft = keyframes`

    0%{
        transform: translateX(-100%)   
    }

    100%{
        transform: translateX(0%)
    }


`

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100%;
    background-color: #ffff;
`

const HeaderWrapper = styled.div`
    width:100%;
    max-width: 1250px;
    max-height: 150px;
    display:flex;
    align-items: center;
    justify-content: center;
`

const Logo = styled.img`    
    max-width: 175px;
    width: 100%;
    height: 100%;
    object-fit:contain;
    @media ${p=>p.theme.media.laptop}{
        max-width: 200px;
    }
`

const Body = styled.div`
    display:flex;
    flex-direction: column;
    
    margin-top: 10%;
    gap: 32px;
    height: 100%;

    width: 100%;
    @media ${p=>p.theme.media.laptop}{
        width: 400px;
    }


`

const Text = styled.p`
    margin: 0;
    margin-bottom: 32px;
    font-size: 1.5rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[700]};
    animation: ${slideLeft} 500ms ease-in;
`



const Actions = styled.div`
    display:flex;
    justify-content: space-between;
`

const Box = styled.div`
    width: 100%;
    overflow:hidden;
    height: 150px;
    
`

const Counter = styled.div`
    display:flex;
    justify-content: flex-end;
    
    color: ${p=>p.theme.palette.grey[400]}
`


const SuccessWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 16px;
`

export default RegisterCompany