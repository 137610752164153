import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';

const CountrySelect = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name
})=>{


    return(
        <FormControl fullWidth>
            <CustomInputMuiLabel>{label}</CustomInputMuiLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}                
                onChange={onChange}
                name="pais"
            >
                <MenuItem value="Argentina">Argentina</MenuItem>
                <MenuItem value="Colombia">Colombia</MenuItem>
                <MenuItem value="Mexico">Mexico</MenuItem>
                <MenuItem value="Peru">Peru</MenuItem>
                <MenuItem value="RepublicaDominicana">Republica Dominicana</MenuItem>                                
            </Select>
        </FormControl>


    )
}

export default CountrySelect