import TextInput from "./TextInput";
import Search from '../Icons/Search';
import { useTheme } from "@mui/system";
import styled from '@emotion/styled/macro';
import ClipLoader from 'react-spinners/ClipLoader'




const SearchBar = ({
    label,
    value,
    onChange,
    placeholder,
    fullWidth,
    isLoading
})=>{

    const theme = useTheme();

    return(
        <Wrapper fullWidth={fullWidth}>
            <StyledTextInput 
                
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                frontIcon={
                    <Search size="20" color={theme.palette.grey[500]}/>
                }
                endIcon={
                    isLoading && <ClipLoader size={20} color={theme.palette.grey[600]} />
                }
        />

        </Wrapper>
        
    )
}


const StyledTextInput = styled(TextInput)`
    border: none;
`

const Wrapper = styled.div`
    width: ${p=>p.fullWidth ? '100%' : "revert"};
    max-width:750px;    
    align-self: center;
    border-radius: 12px;

    @media ${p=>p.theme.media.tablet}{
        width:100%;
    }
    
`




export default SearchBar;