import styled from '@emotion/styled';
import Button from '../../Atoms/Button';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';



const DoctorNew = ({
    doctor,
    setFormType
})=>{


    return(
        <StyledFormWrapper>
            <StyledFormTitle>Usuario no encontrado en tu base de datos de Doctores</StyledFormTitle>
            <StyledFormBody>
                
                <Text>No tienes doctor con este email en tu base de datos de Doctores. Tienes dos opciones.</Text>
                <Ol>
                    <ListItem>Crear este doctor desde cero en tu base de datos.</ListItem>
                    <Button 
                        primary
                        outline
                        fullsize={true}
                        onClick={()=>setFormType("crearDoctor")}
                    >Crear Doctor</Button>
                    <ListItem>Si tienes a este usuario pero sin email o con otro email puedes fusionarlo. </ListItem>
                    <Button 
                        primary
                        outline
                        onClick={()=>setFormType("fusionarDoctor")}
                        fullsize={true}
                    >Fusionar Doctor</Button>
                </Ol> 
            </StyledFormBody>
            
        </StyledFormWrapper>
    )
}


const StyledFormTitle = styled(FormTitle)`
    margin-bottom: 24px;
`

const StyledFormWrapper = styled(FormWrapper)`
    

    @media ${p=>p.theme.media.tablet}{
        
        max-width: 400px;
    }

`

const StyledFormBody = styled(FormBody)`
    margin-bottom:36px;
`

const Text = styled.p`
    margin:0;
`

const Ol = styled.ol`
    padding-left:18px;
`

const ListItem = styled.li`
    margin: 0;
    padding: 8px;

`


export default DoctorNew