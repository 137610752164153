import styled from "@emotion/styled/macro";
import { faUserCircle, faPencil, faEdit, faLock, faHospital } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
// import {
//     Menu,
//     MenuButton,
//   } from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizontal from "../../Icons/MoreHorizontal";
//import { StyledMenuItem, StyledMenuLink, StyledMenuList } from "../../Atoms/StyledDropdownReach";



// const MoreButtonProfile = ({
//     setOpenModal,
//     setFormType
// })=>{
//     const dispatch= useDispatch();
//     const theme = useTheme();
//     const user = useSelector(state=>state.auth.user)
//     const isMd = user?.role?.md

//     const onSelect = (formType)=>{
//        setOpenModal(true);
//        setFormType(formType);
//       }
    
//     return(
//         <Menu>
//         {({isExpanded})=>{              
//             isExpanded ? document.querySelector('body').style.overflow='hidden' :
//                         document.querySelector('body').style.overflow='visible'
//             return(
//                 <>
//                 <MoreButton>                
//                     <MoreHorizontal color={theme.palette.grey[600]}/>
//                 </MoreButton>
//                 <StyledMenuList>                                
//                     <StyledMenuItem onSelect={()=>onSelect('editar')}>
//                         <MenuItemWrapper>
//                             <FontAwesomeIcon icon={faEdit} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
//                             <Text>Editar</Text>
//                         </MenuItemWrapper>
//                     </StyledMenuItem>
//                     {
//                         isMd &&
                    
//                             <StyledMenuItem onSelect={()=>onSelect('centros')}>
//                                 <MenuItemWrapper>
//                                     <FontAwesomeIcon icon={faHospital} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
//                                     <Text>Centros</Text>
//                                 </MenuItemWrapper>
//                             </StyledMenuItem>
//                     }
//                     <StyledMenuItem onSelect={()=>onSelect('foto')}>
//                         <MenuItemWrapper>
//                             <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
//                             <Text>Foto de Perfil</Text>
//                         </MenuItemWrapper>
//                     </StyledMenuItem>
//                     <StyledMenuItem onSelect={()=>onSelect('password')}>
//                         <MenuItemWrapper>
//                             <FontAwesomeIcon icon={faLock} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
//                             <Text>Contraseña</Text>
//                         </MenuItemWrapper>
//                     </StyledMenuItem>
//                 </StyledMenuList>
//             </>
//             )
//         }}       
//     </Menu>
//     )
// }










const MoreButtonProfile = ({
    setOpenModal,
    setFormType
})=>{
    const dispatch= useDispatch();
    const theme = useTheme();
    const user = useSelector(state=>state.auth.user)
    const isMd = user?.role?.md

    const onSelect = (formType)=>{
       setOpenModal(true);
       setFormType(formType);
      }


    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }
    
   
            return(
                <>
                <MoreButton
                    onClick={onClickHandler}
                >                
                    <MoreHorizontal color={theme.palette.grey[600]}/>
                </MoreButton>
                <Menu
                     open={open}
                     anchorEl={anchorEl}
                     onClose={onCloseHandler}
                >                                
                    <MenuItem onClick={()=>{
                                            onSelect('editar')
                                            setAnchorEl(null)
                                            }}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faEdit} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Editar</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                    {
                        isMd &&
                    
                            <MenuItem onClick={()=>{
                                                    onSelect('centros')
                                                    setAnchorEl(null)
                                                    }}>
                                <MenuItemWrapper>
                                    <FontAwesomeIcon icon={faHospital} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                                    <Text>Centros</Text>
                                </MenuItemWrapper>
                            </MenuItem>
                    }
                    <MenuItem onClick={()=>{
                                            onSelect('foto')
                                            setAnchorEl(null)
                                            }}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Foto de Perfil</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                    <MenuItem onClick={()=>{
                                            onSelect('password')
                                            setAnchorEl(null)
                                            }}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faLock} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Contraseña</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                </Menu>
            </>
            )
     
}

const MoreButton = styled.button`
    border-radius: 50%;
    font-size: 1.5rem;
    padding: 12px;
    text-align:center;
    background-color: ${p=>p.color ? p.color:'transparent'};
    border:0;
    outline:0;
    display: inline-flex;
    align-self: center;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }

`

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Text = styled.p`
    margin:0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}
`

export default MoreButtonProfile;



