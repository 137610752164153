import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";
import { addMd, addMds, addNextMds, loadingMds, loadingMoreMds } from "../mdReducer";

// export const fetchMds = (filter, nextCursor)=> async(dispatch)=>{

//     const options = createOptions('POST',{nextCursor})
//     let response;
      
//     try{
//         if(nextCursor){
//             dispatch(loadingMoreMds());
//             response = await goFetch(apiUrl+ "doctors",options );
//             dispatch(addNextMds(response));            
//         }else{
//             console.log('ready to get doctors')
//             response = await goFetch(apiUrl+ "doctors",options );
//             dispatch(addMds(response));
            
//         }                        
//     }catch(error){
//         console.log(error);
//     }        
// }



export const searchMds = (search)=> async(dispatch)=>{

    const options = createOptions('GET')
    let mds;
      
    try{
        dispatch(loadingMds());
        mds = await goFetch(apiUrl + 'md-users/md-search/'+ search, options );

        dispatch(addMds({mds}));
    }catch(error){
        console.log(error);
    }        
}




export const fetchAccess = (url)=> async(dispatch)=>{

    const options = createOptions('GET')
    let mds;
      
    try{
        dispatch(loadingMds());
        mds = await goFetch(apiUrl + url, options );

        dispatch(addMds({mds}));
    }catch(error){
        console.log(error);
    }        
}


// export const fetchMd = (mdId)=> async(dispatch)=>{

//     const options = createOptions('GET')
//     let md;
      
//     try{
//        md = await goFetch(apiUrl + 'doctors/'+ mdId, options );
//        dispatch(addMd(md));
//     }catch(error){
//         console.log(error);
//     }
    
    
// }