
import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetDoctor } from '../../app/doctorsReducer';
import { fetchDoctor } from '../../app/thunkActions/doctorsActions';
import BackButton from '../Atoms/BackButton';
import ResultsCardGrid from '../molecules/ResultsCardGrid';
import DoctorProfileCard from './Molecules/DoctorProfileCard';


const DoctorProfile = ()=>{
    
    const dispatch = useDispatch();
    const {doctorId} = useParams();
    const { doctorsSearch } = useSelector(state=>state.doctors);
    console.log(doctorsSearch)
    const {doctor:fetchedDoctor, isLoading} = useSelector(state=>state.doctors);
    const navigate = useNavigate();
    
    
    const getDoctorFromDoctorsState = (doctorId)=>{
        const doctor = doctorsSearch.filter((doctor)=>(doctor._id===doctorId))
        console.log(doctor)
        return doctor[0]
    }

    const _doctor = getDoctorFromDoctorsState(doctorId)
      // if doctor is available from previous navigation then use it. If not fetch from database. 
    React.useEffect(()=>{        
        dispatch(fetchDoctor(doctorId))                        
        return ()=>dispatch(resetDoctor());

    },[_doctor, dispatch, doctorId])

    //const doctor = _doctor || fetchedDoctor;

    const doctor = fetchedDoctor || _doctor;

    // gets pathname from doctorCardGrid via state in order to know whether to go back to Doctors list or Centros profile page. This is needed as using -1 does not carry the state from: profile required to disable list reload when we navigate back
    const location = useLocation()
    const {state} = location

    

    return (
        <Container>
            <ButtonContainer>
                 <BackButton onClick={()=>navigate(state.from,{state:{from:"profile"}})}/>
            </ButtonContainer>
            
            <Wrapper>
                {
                    isLoading ? <div>Loading</div> :
                    <>  
                        
                        <DoctorProfileCard doctor={doctor}/>                        
                        <ResultsCardGrid target={doctor} search={{doctorId: doctorId}}/>                                            
                    </>                
                }                            
             </Wrapper>
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
`

const Wrapper = styled.div`
    display:flex;
    flex-wrap: wrap;
    padding-top: 54px;
    justify-content: center;
    max-width: 1500px;
    flex:1;    
    width:100%;
`


const ButtonContainer = styled.div`
    align-self: flex-start;
`



export default DoctorProfile