
import styled from '@emotion/styled';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import { apiUrl } from '../../../shared/url';
import CardInfo from '../../Atoms/CardInfo';
import Billing from './Billing';
import MoonLoader from 'react-spinners/MoonLoader'
import GridLoader from 'react-spinners/GridLoader'
import ClipLoader from 'react-spinners/ClipLoader'
import { useTheme } from '@emotion/react';
import { customerPortalSession } from '../../../shared/customerPortalSession';

const BillingRedirect = ()=>{

    const theme = useTheme()

    


    const {plan} = useSubscriptionToken();

    console.log(plan)

    if(plan === 'trial' || !plan){
        return <Billing/>
    }else{
        customerPortalSession()
        return (
            <>
                <CardInfo>
                    <CardInfoBody>
                    <ClipLoader color={theme.palette.primary.main} size={24}/>
                    <span>Redireccionando al portal para manejo de tu cuenta</span>                        
                    </CardInfoBody>
                </CardInfo>                        
            </>
        )
        
    }




}


const CardInfoBody = styled.div`
    width:100%;
    display:flex;
    gap: 24px;
    align-items: center;

`


export default BillingRedirect;