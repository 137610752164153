import React from 'react';
import styled from '@emotion/styled';
import formatDate from '../../shared/dateFormatter';
import formatTime from '../../shared/timeFormatter';
import { useTheme } from '@mui/system';
import dateFormat from '../../shared/dateFormatCalendar';
import useDateAndTimeFormatter from '../../hooks/useDateAndTimeFormatter';


const Highlight = ({children, color})=>{

    return(
        <StyledSpan color={color}>{children}</StyledSpan>
    )
  }  


  const ActivityCreator=({activity})=>{
    
    const theme=useTheme();
    const {formatTime, dateFormat} = useDateAndTimeFormatter()

    let {cirugia, accionType} = activity;
    let [timeAgo, setTimeAgo] = React.useState("")

    //if (accionType !=='asignar') return false

    //let accionNew
    const formattedDate = dateFormat(activity.createdAt);


    const MINUTE = 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const WEEK = DAY * 7
    const MONTH = DAY * 30
    const YEAR = DAY * 365


    const calcTimeAgo = (dateString)=>{
      let timeAgoMili = new Date() - new Date(dateString)
      let timeAgoSeconds = Math.round(timeAgoMili/1000)
      let timeAgoMinutes = Math.round(timeAgoSeconds/60)
      let timeAgoHours = Math.round(timeAgoMinutes/60)
      let timeAgoDays = Math.round(timeAgoHours/24)
      let timeAgoMonths = Math.round(timeAgoDays/30)
      let timeAgoYears = Math.round(timeAgoMonths/12)

      if(timeAgoSeconds < MINUTE){
        setTimeAgo(`Hace momentos`);
      }else if(timeAgoSeconds < HOUR){
        if(timeAgoMinutes < 2){
          setTimeAgo(`Hace ${timeAgoMinutes} minuto`)
        }else{
          setTimeAgo(`Hace ${timeAgoMinutes} minutos`)
        }

      }else if(timeAgoSeconds < DAY){
          if(timeAgoHours<2){
              setTimeAgo(`Hace ${timeAgoHours} hora`)
          }else{
            setTimeAgo(`Hace ${timeAgoHours} horas`)
          }
      }else if (timeAgoSeconds < MONTH){
        if(timeAgoDays < 2){
          setTimeAgo(`Hace ${timeAgoDays} dia`)
        }else{
          setTimeAgo(`Hace ${timeAgoDays} dias`)
        }
      }else if(timeAgoSeconds < YEAR){
        if(timeAgoMonths < 2){
          setTimeAgo(`Hace ${timeAgoMonths} mes`)
        }else{
          setTimeAgo(`Hace ${timeAgoMonths} meses`)
        }
      }
      /*else if(timeAgoSeconds < YEAR){
        if(timeAgoYears < 2){
          setTimeAgo(`Hace ${timeAgoYears} mes`)
        }else{
          setTimeAgo(`Hace ${timeAgoYears} meses`)
        }
      }*/
      else{
        if(timeAgoYears < 2){
          return `Hace ${timeAgoYears} año`
        }else{
          return `Hace ${timeAgoYears} años`
        }
      }
    }



    React.useEffect(()=>{

      calcTimeAgo(activity.createdAt)

      let interval = setInterval(()=>{
        calcTimeAgo(activity.createdAt)
        //setTimeAgo(timeAgoMinutes);
      },60000)

      return ()=>clearInterval(interval)

    })


    const fecha = formatDate(cirugia.fechaRaw);
    const hora = formatTime(cirugia.fechaRaw);


  const accionComment=(accionType)=>{
    switch(accionType){
      case 'asignar':
          return (
                    <Text>
                      <span style={{fontWeight: 'bold'}}>{`${cirugia.especialista}`}</span>{ ` fue `}<Highlight color={theme.palette.highlight.lime}>{`asignado/a`}</Highlight>{` a la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                      Dr./a. ${cirugia.doctor} en ${cirugia.centro} el `}<span style={{fontWeight: 'bold'}}>{`${fecha} a las ${hora}.`}</span>
                    </Text>
          )
      case 'suspender':
          return (
                  <Text>
                  {`La cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora}`}</span>{` fue `}<Highlight color={theme.palette.highlight.red}>{`suspendida`}</Highlight>
                  </Text>
          )
      case 'agendar':
          return (
                  <Text>
                  {`La cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} fue `}<Highlight color={theme.palette.highlight.green}>{`agendada`}</Highlight>{` para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora}.`}</span>
                  </Text>
          )
      case 'concluir':
          return (
                  <Text >
                  {`La cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora}.`}</span>{`fue `}<Highlight color={theme.palette.highlight.purple}>{`concluida`}</Highlight>{` por `}<span style={{fontWeight: 'bold'}}>{`${cirugia.especialista}`}</span>
                  </Text>
          )
      case 'aceptar':
          return (
                  <Text>
                  {`La cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora} `}</span>{`fue `}<Highlight color={theme.palette.highlight.purple} >{`aceptada`}</Highlight>{` y esta agendada en cronograma `}
                  </Text>
          )
      case 'confirmado':
          return (
                  <Text>
                    <span style={{fontWeight: 'bold'}}>{`${cirugia.especialista} `}</span><Highlight color={theme.palette.highlight.deepOrange}>{`confirmo`}</Highlight> {`su participacion como EC en la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora} `}</span>
                  </Text>
          )
      case 'rechazado':
          return (
                  <Text>
                    <span style={{fontWeight: 'bold'}}>{`${cirugia.especialista} `}</span><Highlight color={theme.palette.highlight.red}>{`rechazo`}</Highlight> {`su participacion como EC en la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora} `}</span>
                  </Text>
          )
      case 'centro':
          return (
                  <Text>
                  {`El`}<span style={{fontWeight: 'bold'}}>{` Centro`}</span> {` de la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora}`}</span>{` fue `}<Highlight color={theme.palette.highlight.red}>{`cambiado`}</Highlight>{ ` y ahora sera en`}<span style={{fontWeight: 'bold'}}> {`${cirugia.centro}`}</span>
                  </Text>
          )
      case 'doctor':
          return (
                  <Text>
                {`El`}<span style={{fontWeight: 'bold'}}>{` Doctor`}</span> {` de la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} en \
                 ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                  a las ${hora}`}</span>{` fue `}<Highlight color={theme.palette.highlight.red}>{`cambiado`}</Highlight>{ ` y ahora sera`}<span style={{fontWeight: 'bold'}}> {`${cirugia.doctor}`}</span>
                </Text>
          )
      case 'productos':
          return (
                  <Text>
                    {`Se registro un `}<Highlight color={theme.palette.highlight.red}>{`cambio`}</Highlight>{` en los`}<span style={{fontWeight: 'bold'}}>{` productos`} </span> {` aprobados para la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} agendada para el `}<span style={{fontWeight: 'bold'}}>{`${fecha} \
                    a las ${hora} `}</span>
                  </Text>
          )
      case 'reporte':
          return (
                  <Text>
                    <Highlight color={theme.palette.highlight.purple}>{`Enviado`}</Highlight>{` el `}<span style={{fontWeight: 'bold'}}>{`reporte`}</span>{` de la cirugia ${cirugia.cotizacion} del paciente ${cirugia.paciente} con \
                    Dr./a. ${cirugia.doctor} en ${cirugia.centro} realizada el `}<span style={{fontWeight: 'bold'}}>{`${fecha}`}</span>
                  </Text>
          )

      default:
        break
    }



  }

  const comment = accionComment(accionType);

  return (
    <Wrapper>
      <CommentWrapper>
        {comment}
      </CommentWrapper>
      <InfoWrapper>
        <SubText>{`${activity.user.firstname} ${activity.user.lastname} ${formattedDate} - `}<span style={{fontStyle: "italic"}}>{`${timeAgo}`}</span></SubText>
      </InfoWrapper>
    </Wrapper>

  )
 
  }

  const Wrapper = styled.div`
        display:flex;
        flex-direction:column;
  `

  const Text = styled.p`
        font-size: 0.875rem;
        margin: 0;
        color: ${p=>p.theme.palette.grey[800]};
  `
  const SubText = styled.p`
        font-size: 0.75rem;
        margin: 0;
        color: ${p=>p.theme.palette.grey[700]};
  `

  const CommentWrapper = styled.div`

  `

  const InfoWrapper = styled.div`

  `

  const StyledSpan = styled.span`
    background-color: ${p=>p.color};
    
    font-weight: 500;  
  `




export default ActivityCreator;
