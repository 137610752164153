import styled from "@emotion/styled/macro"
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTheme } from "@mui/system"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchAccess, fetchMdsWithAccess } from "../../../app/thunkActions/mdsActions"
import { useCurrentUser } from "../../../hooks/useCurrentUser"
import useModalAndDrawer from "../../../hooks/useModalAndDrawer"
import getFullName from "../../../shared/getFullname"
import Avatar from "../../Atoms/Avatar"
import Button from "../../Atoms/Button"
import { CardBody, CardWrapper } from "../../Atoms/Card"
import Chip from "../../Atoms/Chip"
import Modal from "../../Atoms/Modal"
import CompartirAcceso from "./CompartirAcceso"
import MdSearchBar from "./MdSearchBar"
import MoreButtonMds from "./MoreButtonMds"




const MdUsers = ()=>{
    
    const theme = useTheme();
    const dispatch = useDispatch()
    const {mds, isLoading} = useSelector(state=>state.mds)
    const user = useCurrentUser();    
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [md,setMd] = React.useState();  
    const [formType, setFormType] = React.useState();
    const smallBottomSwipeDrawers = ["compartirAcceso"];
    const [firstRender, setFirstRender] = React.useState(true);
    const [resetSearchValue, setResetSearchValue] = React.useState(false);
    const [access, setAccess] = React.useState(false);



    const permissionStatus = (md)=>{
        const mdHasAccess = md?.sharedFrom?.filter((shared)=>shared.mdUserId === user._id)[0]
        console.log(mdHasAccess)
        if(mdHasAccess?.permissions?.agendar?.state){
            return 'Puede agendar tus cirugias'
        }else if(mdHasAccess?.permissions?.ver?.state){
            return 'Puede ver tus cirugias'
        }else{
            return 'No tiene acceso a tus cirugias'
        }
    }

    const onClick = (access)=>{
        if(firstRender)setFirstRender(false);
        setResetSearchValue(true)
        setAccess(access)
        dispatch(fetchAccess(access))

    }

    const withAccess = access === 'md-users/with-access';
    const providingAccess = access === 'md-users/providing-access';
    
    
    return(
        <Wrapper>
            <SearchBarWrapper>
                <MdSearchBar 
                    firstRender={firstRender} 
                    setFirstRender={setFirstRender}
                    resetSearchValue={resetSearchValue}                    
                    setResetSearchValue={setResetSearchValue}
                    access={access}
                    setAccess = {setAccess}
                />
                <FilterWrapper>
                    <Button                                             
                        onClick={()=>onClick('md-users/with-access')}                       
                        outline = {!withAccess}
                        preIcon={<FontAwesomeIcon icon={faUsers} color={withAccess ? '#ffff' : theme.palette.grey[700]}/>} 
                    >Usuarios con acceso a tus cirugias</Button>
                    <Button   
                        onClick={()=>onClick('md-users/providing-access')}                       
                        outline = {!providingAccess} 
                        preIcon={<FontAwesomeIcon icon={faUser} color={providingAccess ? '#ffff' : theme.palette.grey[700]}/>} 
                    
                    >Tus accesos</Button>
                </FilterWrapper>
            </SearchBarWrapper>
            

            <BodyWrapper>
            
            {
                mds.length<1 && !firstRender && !isLoading && <div>No se encontraron usuarios</div>
            }

            { !isLoading && mds.length>0 && 
                mds?.map((md)=>{
                    
                const fullname = getFullName(md);
                const mdHasAccess = md?.sharedFrom?.filter((shared)=>shared.mdUserId === user._id)[0]
                const userHasAccess = md?.sharedWith?.filter((shared)=>shared.mdUserId === user._id)[0]
                console.log(userHasAccess)

                return  (
                    <Card key={`${md._id}`}>
                        <CardBody>
                            <InnerWrapper>
                                <Avatar src={md.profilePicture}>{md.lastname.substring(0,1)}</Avatar>
                                <Info>
                                    <Text>{fullname}</Text>
                                    <SubText>{md.especialidad}</SubText>
                                    {userHasAccess && <SubText>Tienes acceso a sus cirugias</SubText> }
                                </Info>
                                <EndContainer>
                                {user._id !== md._id &&
                                     <Chip 
                                        icon={<FontAwesomeIcon icon={faUsers} color={theme.palette.grey[700]}/>} 
                                        outline color={theme.palette.grey[800]}  
                                        label={permissionStatus(md)}
                                    />
                                }
                                {user._id !== md._id && <MoreButtonMds setFormType={setFormType} setOpenModal={setOpenModal} setMd={setMd} md={md}/>}
                                </EndContainer>
                                
                                
                            </InnerWrapper>

                        </CardBody>
                    </Card>
                )
                    
                } )           
            }

            </BodyWrapper>

            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}   
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
            >
                {formType === "compartirAcceso" && <CompartirAcceso close={close} md={md}/>}                                          
                {formType === "cambiarAcceso" && <CompartirAcceso close={close} md={md} changeAccess={true}/>}                                          
            </Modal>    

            

        </Wrapper>
    )

}


const Wrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;    
`

const SearchBarWrapper = styled.div`
    width:100%;
    max-width:750px;
    display:flex;
    flex-direction: column;    
    gap:4px;
`

const FilterWrapper = styled.div`
    display:flex;
    gap:4px;
    flex-wrap: wrap;
`

const Card = styled(CardWrapper)`
    margin:0;
    margin-bottom: 8px;
    
`

const BodyWrapper = styled.div`
    max-width: 1000px;
    width:100%;
    
`


const InnerWrapper  = styled.div`
    display:flex;
    align-items: center;
    gap: 12px; 
    min-height:50px;
    flex-wrap: wrap;
`

const Text = styled.p`
    margin: 0;
    color: ${p=>p.theme.palette.grey[800]}
`

const SubText = styled.p`
    margin:0;
    font-size: 0.8rem;
    color: ${p=>p.theme.palette.grey[700]}
`

const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex:2;
`

const EndContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    gap: 4px;
    flex-wrap: wrap;

    @media ${p=>p.theme.media.tablet}{
        justify-content: revert;
        width:revert;
    }
    
`

const PermissionStatus = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border: 1px solid ${p=>p.theme.palette.grey[400]};
    border-radius: 4px;
    height: 35px;
    width:50px;
`


export default MdUsers