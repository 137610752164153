import React from 'react';
import { useJwt } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { createOptions, goFetch } from '../api/cirugiasApi';
import { setSubToken } from '../app/authReducer';
import { setCookie } from '../shared/authHelpers';
import { apiUrl } from '../shared/url';


const SubscriptionContext = React.createContext({
    isSubscribed: false,
    checkIfSubscribed: ()=>{}
})


//token comes from Redux store. It gets decoded here and then sent down via context. 

export const SubscriptionContextProvider = ({
    children
})=>{
   
    
    //const [subToken, setSubToken] = React.useState(getCookie('subToken'))
    const dispatch = useDispatch()
    const {subToken} = useSelector(state=>state.auth)
    const {decodedToken, isExpired} = useJwt(subToken)    
    
    const numberOfUsers = decodedToken?.users;
    const plan = decodedToken?.product;
    const companyPermissions = decodedToken?.permissions || [];
    

    const [isSubscribed, setIsSubscribed] = React.useState();

    const checkIfSubscribed = async()=>{
        const options = createOptions('GET')
        const response = await goFetch(`${apiUrl}users/get-subscription-token`, options);
        console.log(response)
        if(response.isSubscribed){        
            setCookie('subToken',response.subToken)
            dispatch(setSubToken(response.subToken))
        }
        
    }

    
    React.useEffect(()=>{
        if(subToken && !isExpired){
            setIsSubscribed(true)
        }else if(subToken && isExpired){
            setIsSubscribed(false)
        }
    },[subToken, isExpired])



    
    return(
        <SubscriptionContext.Provider value={{
            isSubscribed,
            numberOfUsers,
            plan,
            companyPermissions,
            checkIfSubscribed                        
        }}>
             {children}   
        </SubscriptionContext.Provider>
    )
}


export default SubscriptionContext