import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faHospital,
    faCalendarCheck, faUsersCog, faUserCircle, faSignOutAlt,
    faTachometerAlt,faSlidersH, faBell, faChartBar, faBed, faWarehouse, faUser
        } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';





const DrawerNavigationMd =({
    close
})=>{
     
    const isTablet = useMediaQuery('tablet');
    console.log(isTablet)

     const closeCheck =()=>{        
         if(isTablet) return        
         close()
     }

    return(
        <NavigationWrapper>
            {false &&
            <LinkButton to={"activity"}  onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>                    
                        <FontAwesomeIcon icon={faTachometerAlt} size="2x"/>                                        
                </Icon>
                <TextWrapper>
                    <Text>
                        Actividad
                    </Text>
                </TextWrapper>                                
            </LinkButton>
            
            }
            <LinkButton to={'cirugias'} onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                    <FontAwesomeIcon icon={faCalendarCheck} size="2x"/>
                </Icon>
                <Text>
                    Cirugias
                </Text>
            </LinkButton>
            <LinkButton to={"md-users"}  onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                   <FontAwesomeIcon icon={faUser} size="2x"/>
                </Icon>

                <TextWrapper>
                    <Text>
                        Usuarios
                    </Text>
                </TextWrapper>                
            </LinkButton>                        
            <LinkButton to={"casa-comercial"}  onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                   <FontAwesomeIcon icon={faWarehouse} style={{fontSize: '1.7rem'}}/>
                </Icon>

                <TextWrapper>
                    <Text>
                        Proveedor 
                    </Text>
                </TextWrapper>                
            </LinkButton>                        
            {
                false && <LinkButton to={"admin"} onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                    <Icon>
                        <FontAwesomeIcon icon={faUsersCog} size="2x"/>
                    </Icon>
                    <Text>
                        Admin
                    </Text>
                </LinkButton>
            }
            {   
                false && <LinkButton to={"reporte"}  onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                    <Icon>
                        <FontAwesomeIcon icon={faChartBar} size="2x"/>
                    </Icon>
                    <Text>
                        Reporte
                    </Text>
                </LinkButton>            
            }


        </NavigationWrapper>
    )
    

}


const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width:100%;
  gap: 8px;
  padding:16px 0px;
`


const LinkButton = styled(NavLink)`
    /*border: 1px solid white:*/
    width:100%;
    height: 65px;
    padding: 8px 8px 8px 20px;
    line-height: 50px;
    color: ${p=>p.theme.palette.grey[400]};
    display:flex;
    gap:26px;
    font-weight: 700;
    text-decoration: none;

    &:hover{
        background-color: ${p=>p.theme.palette.grey[300]};
        color: ${p=>p.theme.palette.grey[800]};
    }

    &.active{
        background-color: ${p=>p.theme.palette.grey[300]};
        color: ${p=>p.theme.palette.grey[800]};
    }
`

const Icon = styled.div`
    /*border: 1px solid white;*/
    
    width:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    
`
const TextWrapper = styled.div`
   

`
const Text = styled.span`

`



export default DrawerNavigationMd;