const isEmpty =(obj={},properties=[])=>{

    let isEmpty = true
    
    if(typeof obj !== "object") throw new Error("Obj must be an object")
    if(!Array.isArray(properties))throw new Error("Properties must be an array")
        
    for (let property of properties){            
        //checks to see if object has one of the properties in the array
        
        // Best implementation but hasOwn is not supported by safari yet
        /*if(Object.hasOwn(obj, property)){                
            //if the property exists and it has a value, then return that it is not empty                
            if(obj[property] !== undefined){                    
                console.log("fount property that is not empty");
                console.log(obj[property]);
                return false
            }
        }
        */

        //using this while getting better browswer support
        if(obj.hasOwnProperty(property)){                
            //if the property exists and it has a value, then return that it is not empty                
            if(obj[property] !== undefined){                    
                // console.log("fount property that is not empty");
                // console.log(obj[property]);
                return false
            }
        }
    }
                            
    return isEmpty
}


export default isEmpty
