import { useSelector } from "react-redux"


const useCirugia =(cirugiaId)=>{

    //checks for cirugia from Cirugias redux store
    //if there is none checks for cirugia in redux store and makes sure it is the same one. If so return the cirugia. 
    
    let cirugia;

    const cirugias = useSelector(state=>state.cirugias.cirugias)
    // find current surgery in order to add old center to history action.
    
    const cirugiaFromStore = useSelector(state=>state.cirugia.cirugia)

    cirugia= cirugias?.filter((cirugia)=>cirugia._id === cirugiaId)[0]

    if(!cirugia && cirugiaFromStore && cirugiaFromStore._id === cirugiaId){
      cirugia = cirugiaFromStore
    }
  
    return cirugia
  
  
  }


export const useCirugiaWithLoading =(cirugiaId)=>{
  
  let cirugia;

  const {cirugias, isLoadingUpdate} = useSelector(state=>state.cirugias)
  // find current surgery in order to add old center to history action.

  const cirugiaFromStore = useSelector(state=>state.cirugia.cirugia)
  
  cirugia= cirugias?.filter((cirugia)=>cirugia._id === cirugiaId)[0]

  if(!cirugia && cirugiaFromStore && cirugiaFromStore._id === cirugiaId){
    cirugia = cirugiaFromStore
  }

  console.log(cirugia)

  return [cirugia, isLoadingUpdate]


}

  export default useCirugia;