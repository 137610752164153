import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import MetodoPagoRadio from './CustomAutocomplete/MetodoPagoRadio';
import useCirugia from '../../hooks/useCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const ModifyPago = ({
    cirugiaId,
    close
})=>{

    const isTablet = useMediaQuery('tablet');
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);
    const [comments, handleChangeComments] = React.useState('');
    const cirugia = useCirugia(cirugiaId);
    const pago = cirugia.pago;

    const initialValues = pago && {
        pago: {
          tipo: pago.tipo,
          nombre: pago.nombre,
          monto: pago.monto
        }
    }
  
    const [values, setValues] = React.useState(initialValues ?? null);    
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();
       
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Forma y monto de pago actualizado`);
        
        let infoPago = {
            pago: values.pago,
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion:accion
          }
              
        updateCirugia("update-pago",infoPago)
    }        
      

    return(
        <FormWrapper onSubmit={onSubmit}>
             <FormTitle isTablet={isTablet} >Modificar Pago</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <MetodoPagoRadio
                    values={values}
                    setValues={setValues}
                />                

                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />                                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyPago;