


const ImageInput = ({
    children,
    onChange
})=>{

    return(
        <>        
            <input
                id="image"
                style={{display:"none"}}
                name="image"
                type='file'
                onChange={onChange}
            >
                </input>
            <label htmlFor="image">{children}</label>    
        </>
    )
}


export default ImageInput;