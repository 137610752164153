import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductos } from "../../../app/thunkActions/productosActions";
import SearchBar from "../../Atoms/SearchBar";
import { initProductos } from "../../../app/productosReducer";



const ProductosSearchBar = ()=>{
    const [searchValue, setSearchValue] = React.useState();

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.productos.isLoading);

    const onChangeSearchValue = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        setSearchValue(searchTerm)               
        if(searchTerm.length>2 && !isLoading){   
            dispatch(fetchProductos(searchTerm,'all'));
        }
        if(!searchTerm){            
            dispatch(initProductos())
        }
    }

    return (
        <SearchBar
        label="Buscar producto" 
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoading}
    />
    )
}



export default ProductosSearchBar