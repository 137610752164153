import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuPopover,
    MenuLink,
  } from "@reach/menu-button";
import styled from '@emotion/styled/macro';
import { Link } from "react-router-dom";


export const StyledMenu = styled(Menu)`
`


export const StyledSpan = styled.span`
font-weight: 600;
color: var(--button-color);
font-size: .875rem;
line-height: 1.75;
`

export const StyledIconMenuButton = styled(MenuButton)`
    border-radius: 50%;
    font-size: 1.5rem;
    padding: 12px;
    text-align:center;
    background-color: ${p=>p.color ? p.color:'transparent'};
    border:0;
    outline:0;
    display: inline-flex;
    align-self: center;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }
`

export const StyledMenuButton = styled(MenuButton)`
    padding: 5px 15px;
    border-radius: 4px;
    min-width: 64px;
    max-height: 35px;
    min-height: 35px;
    background-color: transparent;
    border: 1px solid var(--button-color);
    text-transform: none;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    transition: transform 200ms ease-in;
    transition: background-color 200ms ease-in;

    &:hover{
        
        background-color: var(--button-color-hover);
        box-shadow: 2px 2px 8px ${p=>p.theme.palette.grey[300]};
        ${StyledSpan}{
            color: var(--text-color-hover);
        }   
    }   
`

export const StyledMenuList = styled(MenuList)`
position:relative;
z-index:1;
background-color: #ffff;
box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 
0px 3px 4px 0px rgb(0 0 0 / 14%), 
0px 1px 8px 0px rgb(0 0 0 / 12%);
& > [data-reach-menu-item][data-selected] {
    background-color: ${p=>p.theme.palette.grey[300]};
    
  }
&:focus{
    outline:none;
}
`

export const StyledMenuItem = styled(MenuItem)`
padding: 12px 18px;
font-size: 0.875rem;

&:hover{
    background-color: ${p=>p.theme.palette.grey[300]}
}
`

export const StyledMenuLink = styled(Link)`
display: inline-block;

text-decoration:none;
width: 100%;
padding: 12px 18px;
font-size: 0.875rem;

&:hover{
    background-color: ${p=>p.theme.palette.grey[300]}
}
`

