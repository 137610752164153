import styled from '@emotion/styled/macro';
import { CardHeader, CardBody, CardWrapper as Card } from "../../Atoms/Card";
import ProfilePictureAvatar from '../../Atoms/ProfilePictureAvatar';
import Mail from '../../Icons/Mail';
import Phone from '../../Icons/Phone';
import blankProfile from '../../../blankProfile.svg'
import { useTheme } from '@mui/system';

import Modal from '../../Atoms/Modal';
import React from 'react';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import BackButton from '../../Atoms/BackButton';
import { useNavigate } from 'react-router-dom';



const UsersProfile = ({
    user
})=>{
    
    const [formType, setFormType] = React.useState(); 
    const theme = useTheme();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    let iconColor = theme.palette.grey[800];
    const smallBottomSwipeDrawers = ["foto", "password"]
    

    return (
        <Wrapper>
            
            <ProfileWrapper>                
                <StyledCardHeader>
                    <AvatarContainer>
                        <AvatarBorder/>
                        <ProfilePictureAvatar
                            src={user?.profilePicture || blankProfile}
                            size="large" 
                            alt="avatar" 
                            style={{position:"absolute", top:'-75px'}}
                        ></ProfilePictureAvatar>
                    </AvatarContainer>
                    <TitleContainer>
                        <StyledCardTitle>{`${user?.firstname} ${user?.lastname}`}</StyledCardTitle>
                        <CardSubtitle>{""}</CardSubtitle>    
                    </TitleContainer>                    
                    
                </StyledCardHeader>
                <StyledCardBody>
                    <Container>
                        <ContactInfoWrapper>
                            <ContactContainer>
                                <Mail color={iconColor}/>
                                <ContactText>{`${user?.email}`}</ContactText>
                            </ContactContainer>
                            <ContactContainer>
                                <Phone color={iconColor}/>
                                <ContactText>{`${user?.telefono || ""}`}</ContactText>
                            </ContactContainer>                                                        
                        </ContactInfoWrapper>
                        <Actions>                        
                            
                        </Actions>                                                
                    </Container>                                        
                </StyledCardBody>
            </ProfileWrapper>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
            >
                
                {formType === "password" && <div>Test3</div>}                
             </Modal> 
        </Wrapper>
    )
}


const Wrapper = styled.div`
    padding: 0px 0px 0px 0px;
    display:flex;
    flex-direction: column;
    
    
    
`

const ProfileWrapper= styled(Card)`
    position:relative;
    display:flex;
    flex-direction:column;
    gap:24px;
    flex:1;
    min-height: 250px;
    min-width:350px;
    max-width: 500px;
    margin-top: 64px;
    
`

const StyledCardHeader = styled(CardHeader)`
    flex-wrap: wrap;
    height: 100px;

    @media ${p=>p.theme.media.tablet}{
        height: 120px;
    }
    
    
`
const AvatarContainer = styled.div`
    height:100%;
    width:200px;
    position:relative;
`


const AvatarBorder = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ffff;
    position:absolute;
    top: -84px;
    
`



const TitleContainer = styled.div`
    display:flex;
    flex-direction: column;
    flex:2;
    min-width:200px;
    align-items: flex-start;
    height:100%;
    padding-left:12px;
    
`

const StyledCardTitle = styled.h2`
    margin:0;
    padding-top:4px;
    color: ${p=>p.theme.palette.grey[800]}
`

const CardSubtitle = styled.h4`
    margin:0;
    color: ${p=>p.theme.palette.grey[600]}
`

const StyledCardBody = styled(CardBody)`
    padding-top: 24px;
    
    @media ${p=>p.theme.media.tablet}{
        padding-top: 12px;
    }
`



const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap:8px;
    padding-left:12px;
`

const ContactInfoWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap:8px;
    flex:1
`
const ContactContainer = styled.div`
    display:flex;
    gap:8px;
    align-items: center;
    min-width:200px;
    height: 35px;
`
const ContactText = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]}
`



const Actions = styled.div`
    display:flex;
    padding-top: 24px;
    padding-left: 12px;
    gap: 8px;
    justify-content: flex-end;

`




export default UsersProfile;