import styled from '@emotion/styled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loading, resetCirugias, setSearchValueCirugias } from '../../app/cirugiasReducer';
import { searchCirugia } from '../../app/thunkActions/cirugiasActions';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import CirugiaAccordion from './CirugiaAccordion';
import CirugiasModal from './CirugiasModal';
import CirugiaLoading from './Molecules/CirugiaLoading';
import CirugiasCard from './Molecules/CirugiasCard';
import CirugiasSearchBar from './Molecules/CirugiasSearchBar';


const CirugiasSearch = ()=>{
     
    const dispatch = useDispatch();
    const {cirugias, isLoading, searchValue} = useSelector(state=>state.cirugias)
    //const [searchParameter, setSearchParameter] = React.useState("");
    //const [openModal, setOpenModal] = React.useState(false);
    const [cirugiaId, setCirugiaId] = React.useState(null);
    const [formType, setFormType] = React.useState();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const location = useLocation();
    const navigate = useNavigate();
    
    const onChangeHandler = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        dispatch(loading())        
        dispatch(setSearchValueCirugias(searchTerm)) 

        if(!searchTerm){
            dispatch(resetCirugias())
        } 
    }


    React.useEffect(()=>{
        if(location.state?.from === "cirugia" && cirugias.length!==0) {   
            navigate(location.pathname, {})
            return
        }
        if(searchValue.length>2){
           dispatch(searchCirugia(searchValue))
        }
    
    },[searchValue, dispatch])
    


    React.useEffect(()=>{
        const searchTerm = location?.state?.search
        if(searchTerm){
            dispatch(loading())        
            dispatch(setSearchValueCirugias(searchTerm))                    
            navigate(location.pathname, {})
        }


    },[])
    



    return(
        <MainWrapper>

        
            <Wrapper>
                <CirugiasSearchBar
                    value={searchValue}
                    onChange={onChangeHandler}
                />

                {isLoading && searchValue.length>2 ? <CirugiaLoading/> :

                     <CirugiasWrapper>
                        {
                            cirugias?.length > 0 && cirugias?.map((cirugia)=>(
                                <CirugiasCard
                                    key={`${cirugia._id}`}
                                    cirugia={cirugia}
                                    setOpenModal={setOpenModal}
                                    setCirugiaId={setCirugiaId}
                                    formType={formType}
                                    setFormType={setFormType}  
                                    withLinks={true}
                                />
                                
                            )) 
                        }
                    </CirugiasWrapper>                
                }

                <CirugiasModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    inProp={inProp}
                    setInProp={setInProp}
                    close={close}
                    formType={formType}
                    cirugiaId={cirugiaId}
                />  

            </Wrapper>
            </MainWrapper>
        )
}

const MainWrapper = styled.div`
    height: 200%;  
    display:flex;
    gap:8px;
    justify-content: center;        
`

const Wrapper = styled.div`
    width:100%;
    max-width:800px;
    height: 200%;    
    display:flex;
    flex-direction:column;
    
    @media ${p=>p.theme.media.tablet}{
        width: 100%;
    }

`

const CirugiasWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 8px;
    max-width: 800px;
    align-self: center;
`

export default CirugiasSearch;