import React from 'react'


function throttle (callbackFn, limit) {
    let wait = false;                  
    return function () {              
        if (!wait) {                  
            callbackFn.call();           
            wait = true;               
            setTimeout(function () {   
                wait = false;          
            }, limit);
        }
    }
}

const useScrollTrigger = ()=>{
    const [scrolled, setScrolled] = React.useState(false);

    const onScrollHandler = ()=>{
        if(window.scrollY > 10){
            setScrolled(true);
        }else{
            setScrolled(false);
        }             
    }
        

    React.useEffect(()=>{        
        window.addEventListener('scroll', throttle(onScrollHandler,50),{passive:true})
    },[])


    return scrolled
}

export default useScrollTrigger;