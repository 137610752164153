import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../../api/cirugiasApi';
import { errorMessage } from '../../../../app/messagesReducer';
import { useAccionMaker } from '../../../../hooks/useAccionMaker';
import useUpdateCirugia from '../../../../hooks/useUpdateCirugia';
import { apiUrl } from '../../../../shared/url';
import Button from '../../../Atoms/Button';
import FormActionsBasic from '../../../Atoms/FormActionsBasic';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../../../Atoms/Forms';
import Send from '../../../Icons/Send';



const PositiveReview = ({
    cirugiaId,
    close
})=>{    
    const accionMaker = useAccionMaker()
    const [comments, handleChangeComments] = React.useState();
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);    

    const onSubmit = async (event)=>{
        event.preventDefault();    
        const accion = accionMaker('Doctor dejo comentarios sobre su evaluacion.')         
        const info={
            cirugiaId,        
            comentarios: comments,
            accion
        }
        updateCirugia('mdreview',info)
    }

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <StyledFormTitle>Experiencia Positiva</StyledFormTitle>
            <StyledFormBody>
                <Text>Gracias! Nos alegra mucho poder darte un buen servicio! Por favor dejanos un comentario para saber que fue lo que mejor hicimos.</Text>
                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />
            </StyledFormBody>
            <StyledFormActions>
                <Button 
                    as="button"
                    type="submit"
                    success
                    fullsize={true}
                    preIcon={<Send color="#ffff" size="18"/>}
                    successEvent={successEvent}
                    loading={isLoading}
                >Enviar</Button>
            </StyledFormActions>
            

        </StyledFormWrapper>
    )
}


const StyledFormWrapper = styled(FormWrapper)`
    max-width:500px;
`

const StyledFormTitle = styled(FormTitle)`
    margin-bottom: 18px;

`
const StyledFormBody = styled(FormBody)`
    margin-bottom: 8px;
`

const StyledFormActions = styled(FormActions)`
    padding-left: 4px;
    padding-right: 4px;

    @media ${p=>p.theme.media.tablet}{
        padding-left: 4px;
        padding-right: 4px;
    }
    

`

const Text = styled.p`
    margin:0;
    margin-bottom: 12px;
`



export default PositiveReview;