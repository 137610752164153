import React from "react";
import { createOptions, goFetch } from "../../api/cirugiasApi";
import usePermissions from "../../hooks/usePermissions";
import { apiUrl } from "../../shared/url";
import Button from "../Atoms/Button";


const SendAccountInvite = () => {

    const [accounts, setAccounts] = React.useState([])

    React.useEffect(()=>{

        const getAccounts = async () => {

        const options = createOptions('GET')
        const response = await goFetch(`${apiUrl}users/get-accounts`, options)

        setAccounts(response)

        }

        getAccounts()

    },[])

    const permissions = usePermissions()

    const {       
        isMaster        
    }=permissions


    const sendInvitation = async (activationId) => {
        const options = createOptions('POST', {activationId})
        const response = await goFetch(`${apiUrl}users/send-activation-email`, options)
        console.log(response)
    }

    if(!isMaster) return <div>No tiene permisos para ver esta pagina</div>

    return (

        <>
        <div>Activate Account</div>

        {accounts.length> 0 && accounts.map((account)=>{
            return <>
                        <div key={account._id}>{account.companyName}</div>
                        <div>{account.email}</div>
                        <Button onClick={()=>sendInvitation(account._id)}>Send activation email</Button>
                    </>
        })
        }

        </>
    )

}


export default SendAccountInvite