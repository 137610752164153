import styled from '@emotion/styled';
import formatDate from '../../shared/dateFormatter';
import formatTime from '../../shared/timeFormatter';
import Accordion from '../Atoms/Accordion';
import LabelValue from '../Atoms/LabelValue';
import ChipContainer from './ChipContainer';
import CirugiaInfo from './CirugiaInfo';
import CirugiasActions from './CirugiasActions';
import CirugiasSearchBar from './Molecules/CirugiasSearchBar';
import ProductTable from './Molecules/ProductTable';


const CirugiaAccordion = ({
    cirugia,
    setOpenModal,
    setCirugiaId,
    formType,
    setFormType,    
})=>{

    

    return(
        <Accordion.Wrapper>                        
                        <Accordion.Header>
                            <HeaderContainer>
                                <ChipContainer cirugia={cirugia}/>     
                                <DateWrapper>                                    
                                    <LabelValue label="Fecha: " value={cirugia.info.agendada ? cirugia.info.fecha && formatDate(cirugia.info.fecha) : "Pendiente"}/>
                                    <LabelValue label="Hora: " value={cirugia.info.agendada ? cirugia.info.fecha && formatTime(cirugia.info.fecha) : "Pendiente"}/>
                                </DateWrapper>                        
                                <LabelValue label="Paciente: " value={`${cirugia.paciente.firstname} ${cirugia.paciente.lastname}`}/>
                                <LabelValue label="Doctor: " value={`${cirugia.doctor.firstname} ${cirugia.doctor.lastname}`}/>
                                <LabelValue label="Centro: " value={cirugia.centro.nombre}/>
                            </HeaderContainer>
                        </Accordion.Header>
                        <Accordion.Content>
                            <ContentContainer>
                                <CirugiaInfo cirugia={cirugia}/>
                                <InsideWrapper>
                                    <ProductWrapper>
                                        <h3 style={{width:'100%'}}>Productos</h3> 
                                        <ProductTable cirugia={cirugia} productos={cirugia.productos} laptopWidth="70%" />
                                    </ProductWrapper>                                                    
                                </InsideWrapper>                                                                                                
                            </ContentContainer>
                        </Accordion.Content>
                        <Accordion.Actions>
                            <CirugiasActions
                                setOpenModal={setOpenModal}
                                cirugiaId={cirugia._id}
                                setCirugiaId={setCirugiaId}
                                formType={formType}
                                setFormType={setFormType}                                
                            />                            
                        </Accordion.Actions>
                    </Accordion.Wrapper>
    )
}


const HeaderContainer = styled.div`
    display: flex;
    padding: 16px;
    flex-wrap:wrap;
`

const DateWrapper = styled.div`
    display: flex;
    flex-direction:column;
    flex:1;    
`

const ContentContainer = styled.div`
    display: flex;
    padding: 0px 16px 16px;
    flex-wrap:wrap;
    width:100%;
`

const InsideWrapper = styled.div`
    width:100%;
    display:flex;
    gap:8px;
    flex-wrap: wrap;
`

const ProductWrapper = styled.div`
    flex:2
`

export default CirugiaAccordion;