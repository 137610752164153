import { useDispatch } from "react-redux"
import { createOptions, goFetch } from "../../../api/cirugiasApi"
import { updateUserState } from "../../../app/authReducer";
import { successMessage } from "../../../app/messagesReducer"
import useSuccessButton from "../../../hooks/useSuccessButton";
import { setLocalStorage } from "../../../shared/authHelpers";
import { apiUrl } from "../../../shared/url";
import Button from "../../Atoms/Button";



const SolicitarInvitacionButton = ({
    user,
    company,
    companies,
    setCompanies
})=>{

    const dispatch = useDispatch();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    const onClickSolicitarInvitacion = async (company)=>{
        setIsLoading(true)
        const info = {
            mdEmail: user.email,
            mdFirstname: user.firstname,
            mdLastname: user.lastname,
            mdUserId: user._id,
            companyId: company._id, 
            companyName: company.name,

        }
        const options = createOptions('POST', info)
        
        const response = await goFetch(`${apiUrl}md-users/invitation-request`, options)
        responseStateChanges(response,null,
            
            ()=>{
                setLocalStorage('user', response.user);
                dispatch(updateUserState(response.user));                                
            }
            )
        
         
    }


    return (
        <Button 
            primary 
        outline
            onClick={()=>onClickSolicitarInvitacion(company)}
            successEvent={successEvent}
            loading={isLoading}
        >Solicitar Invitacion</Button>
    )
}


export default SolicitarInvitacionButton;