import React from "react";
import { useDispatch } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import Autocomplete from "../../Atoms/Autocomplete";



// this autocomplete is used in ProductoInput used in cotizaciones. It is a modification of the original one used in the form to create cirugias
//This version does not use the fetchProductos action creator, but instead uses the goFetch function to call the api directly

const ProductosAutocomplete = ({
    onChange,
    clear,
    setClear
})=>{

    const dispatch = useDispatch();    
    
    //productos coming from search
    const [productos, setProductos] = React.useState([]);
    
    // React.useEffect(()=>{
    //  //   dispatch(fetchProductos())
    // },[dispatch])

     //manages changes in keystroked in autocomplete to call up products

     console.log(productos)
     const onInputChange = async(e)=>{
        console.log(e.target.value.length);
        const search = e.target.value;
        if(search.length > 2){
            //dispatch(fetchProductos(e.target.value))

            const options = createOptions('GET')
            let productos;
              
            try{
               // productos = await goFetch(apiUrl+ "productos/" + search,options );
                productos = await goFetch(`${apiUrl}productos/${search}/active`,options );
                setProductos(productos)
            }catch(error){
                console.log(error);
            }
        
        }
    }
    

    return(
        <Autocomplete                  
            label="Productos"
            name="productos"
            id="productos"
            onChange={onChange}
            onInputChange={onInputChange}
            options={productos}
            clear={clear}
            setClear={setClear}
            getOptionLabel={(option)=>`${option.codigo} - ${option.descripcion}`}
        />
    )
}


export default ProductosAutocomplete;