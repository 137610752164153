import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import MapPin from '../../Icons/MapPin';



const DoctorCentrosList = ({
    doctor,
    size
})=>{   

    const theme = useTheme();
    let iconColor = theme.palette.grey[800]
    let iconSize;

    if(size==="small"){
        iconSize ="18"
    }else{
        iconSize="24"
    }

    if(doctor?.centros?.length === 0){

        return null
    }

    return(
        <>
        {doctor?.centros?.map((centro, index)=>(
                <ContactContainer key={`${centro} ${index}`}>
                    <IconWrapper>
                        <MapPin color={iconColor} size={iconSize}/>
                    </IconWrapper>
                    <TextWrapper>
                        <ContactText>{`${centro?.nombre}`}</ContactText>
                    </TextWrapper>
                    
                </ContactContainer>    
            ))} 
        </>
    )
}




const ContactContainer = styled.div`
    display:flex;
    gap:8px;
    align-items: center;
    min-width:200px;
    height: 35px;
`


const IconWrapper = styled.div`
    width:25px;
    height:35px;
    flex-shrink: 0;
    display:flex;
    align-items: center;
`


const TextWrapper = styled.div`
    height: 35px;
    display:flex;
    align-items: center;
`


const ContactText = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]}
`


const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[900]}

`

export default DoctorCentrosList