import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { updateUserState } from '../../../app/authReducer';
import { successMessage } from '../../../app/messagesReducer';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { setLocalStorage } from '../../../shared/authHelpers';
import { apiUrl } from '../../../shared/url';
import { CardActions, CardBody, CardHeader, CardTitle, CardWrapper } from '../../Atoms/Card';
import IconButton from "../../Atoms/IconButton";
import Modal from '../../Atoms/Modal';
import Check from '../../Icons/Check';
import Mail from "../../Icons/Mail";
import X from '../../Icons/X';




const InvitationNotification = ()=>{

    const theme =useTheme();
    const dispatch = useDispatch(); 
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const {isLoading: isLoadingDeny,setIsLoading: setIsLoadingDeny, successEvent: successEventDeny, responseStateChanges: responseStateChangesDeny} = useSuccessButton();
    const user = useCurrentUser();
    const numberOfNotifications = user?.linkRequests?.length;
    const pendingInvitations = numberOfNotifications > 0 ? true : false

    const approveInvitation = async(invitation, answer)=>{
        console.log(invitation)
        if(answer === 'accept'){
            setIsLoading(true)
        }else if(answer === 'deny'){
            setIsLoadingDeny(true)
        }
        
    
        const options = createOptions('POST', {
            mdId: user._id,
            companyId: invitation.companyId,
            companyName: invitation.companyName,
            companyDoctorId: invitation.companyDoctorId,
            answer            
        })                                    
        
        const response = await goFetch(apiUrl+ "md-users/accept-invitation", options);

        const success =  ()=>{
            //update the local storage and the store since updating localstorage wont trigger store update/               
            dispatch(successMessage(response.message));    
        }

        const delayed =  ()=>{
            //update the local storage and the store since updating localstorage wont trigger store update/               
            setLocalStorage('user', response.user);
            dispatch(updateUserState(response.user));
            close()
        }
        
        if(answer === 'accept'){
            responseStateChanges(response, success,delayed)            
        }else if(answer === 'deny'){
            responseStateChangesDeny(response, success,delayed)        
        }
                
    }


    

    return (
        <Wrapper>
            <IconButton onClick={()=>setOpenModal(true)}><Mail color={'#ffff'} size={"1.85rem"}/></IconButton>
            {pendingInvitations && <InfoDot>{numberOfNotifications}</InfoDot>}
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer
                
            >
                <InvitationWrapper>
                    <Title>Invitacion a connectar con Casa Comercial</Title>
                    <SubTitle>Al aceptar la invitacion podras ver tus cirugias aprobadas a estas empresas. </SubTitle>

                    { user.linkRequests.map((invitation, index)=>(
                            <Selection key={`${invitation} ${index}`}>
                                <CompanyName>{invitation.companyName}</CompanyName>
                                <Actions>
                                    <StyledIconButton  
                                        outline={true}                                       
                                        color={theme.palette.success.light}
                                        onClick={()=>approveInvitation(invitation, "accept")}
                                        loading={isLoading}
                                        successEvent={successEvent}
                                    >
                                        <Check color={theme.palette.success.light}/>
                                    </StyledIconButton>
                                    <StyledIconButton 
                                        outline={true} 
                                        color={theme.palette.error.light}
                                        onClick={()=>approveInvitation(invitation, "deny")}
                                        loading={isLoadingDeny}
                                        successEvent={successEventDeny}
                                    >
                                        <X color={theme.palette.error.light}/>
                                    </StyledIconButton>
                                </Actions>
                            </Selection>
                        ))
                    }       
                    { !pendingInvitations &&
                        <Selection>
                            <CompanyName>{"No tienes invitaciones pendientes de responder"}</CompanyName>
                        </Selection>                    
                    }
                </InvitationWrapper>
            </Modal>            
        </Wrapper>
    )

}


const Wrapper = styled.div`
    position: relative;
`

const InfoDot = styled.div`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top:5px;
    right:5px;
    background-color: ${p=>p.theme.palette.error.main};
    color: #ffff;
    font-size: 0.8rem;
    font-weight: 600;
    display: grid;
    place-content: center;
    line-height: 0;
`

const InvitationWrapper = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    min-height: 400px;

`

const Title = styled.h1`
    margin: 0;
    font-size: 1.25rem;
    color: ${p=>p.theme.palette.grey[800]};
`

const SubTitle = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[600]};
    font-weight: 500;
`

const Selection = styled.div`
    margin-top: 24px;
    display:flex;
    width: 100%;
    height: 65px;
    border: 1px solid;
    border-radius: 12px;
    padding: 8px 16px;
    align-items: center;    
    justify-content: space-between;
    background-color: ${p=>p.theme.palette.grey[100]}
`

const CompanyName = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[800]};
`

const Actions = styled.div`
    display: flex;
    gap: 8px;

`

const StyledIconButton = styled(IconButton)`
    padding: 8px;
    background-color: #ffff;
    transition: transform 200ms ease-out;

    &:hover{
        background-color: #ffff;
        transform: scale(1.1);        
    }
`

export default InvitationNotification;