import styled from '@emotion/styled/macro';
import { flexbox, useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import Button from '../Atoms/Button';
import BenefitsList from './molecules/BenefitsList';
import CloudInfo from './molecules/CloudInfo';
import InviteMd from './molecules/InviteMd';
import LandingHeader from './molecules/LandingHeader';
import NotifyAndFilter from './molecules/NotifyAndFilter';
import Registra from './molecules/Registra';
import Summary from './molecules/Summary';



const InfoRow = ({children, color, sidePadding})=>(
    <Row color={color} sidePadding={sidePadding}>        
            {children}        
    </Row>
)










const LandingPage = ()=>{

    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');

    return(
        <ExternalWrapper>
           <WidthWrapper>
                <LandingHeader/>                                    
                <InfoRow  sidePadding={'0px'}>
                   <Registra/>               
                </InfoRow>
                <InfoRow color={'rgb(238, 241, 245)'} >            
                    <BenefitsList/>                                                                      
                </InfoRow>
                
                <InfoRow >
                   <CloudInfo/>         
                </InfoRow>  
                
                <InfoRow>
                   <NotifyAndFilter/>           
                </InfoRow> 
                <InfoRow>
                    <InviteMd/>           
                </InfoRow>   
                <InfoRow >
                   <Summary/>       
                </InfoRow>   

                <Footer>
                    <FooterWrapper>

                    </FooterWrapper>
                </Footer>

            </WidthWrapper>   
                  
        </ExternalWrapper>

    )
}

const LoginLink = styled(Link)`
    text-decoration: none;
    padding: 16px;
    color: white;
    font-weight: 600;

`

const Logo = styled.img`    
    max-width: 375px;
    z-index: 2;
    
    @media ${p=>p.theme.media.laptop}{
        max-width: 350px;
    }
`




const ExternalWrapper = styled.div`
    width:100%;    
    display:flex;
    flex-direction: column;        
    background-color: #ffff;        
    align-items:center;    
    justify-content: center;
`
const WidthWrapper = styled.div`
    width:100%;
    height: 100%;
    max-width:1250px;
    display:flex;
    flex-direction: column;        
    background-color: #ffff;    
`

const Row = styled.div`
    display:flex;
    justify-content: center;    
    width: 100%;
        
    background-color: ${p=>p.color || '#ffff'};
    
    @media ${p=>p.theme.media.tablet}{
        
        padding-left: ${p=>p.sidePadding || '24px'};
        padding-right: ${p=>p.sidePadding || '24px'};
    }
    
`

const MaxWidth = styled.div`
    
    min-height: 100%;
    width:100%;
    
`

const Header = styled.div`
    height: 30%;    
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;            
`

const HeaderWrapper = styled.div`    
    width:100%;    
    display:flex;
    align-items: center;
    justify-content: flex-end;

    @media ${p=>p.theme.media.tablet}{
        justify-content: space-between;
    }
    
`

const MainRowWrapper = styled.div`
    display:flex;
    gap: 48px;
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: ${p=>p.reverse ? 'column-reverse' : 'revert'};
    

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
    }

`



const Image = styled.img`    
    width:100%;
    height: 100%;
    object-fit:contain;
`



const ImageFondo = styled.img`
    position:absolute;
    object-fit:contain;
    aspect-ratio: 1/1;
    

    @media ${p=>p.theme.media.tablet}{
        top:-300px;
        left: -300px;
        height: 350%;
    }
`




const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 12px;    
    width:100%;
    padding: 8px;        
`

const RegisterWrapper = styled.div`
    display:flex;
    flex-direction: column;
    aling-items: center;
    width:100%;    
    min-width: 350px;
`

const Actions = styled.div`
    display:flex;
    gap: 8px;
    justify-content: center;
`



const MainTitle=styled.h1`
    margin:0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    color: ${p=>p.theme.palette.grey[800]};
    font-size: 2.5rem;
`


const ParagraphWrapper = styled.div`
    
    padding: 32px;
    line-height: 1.8;

    @media ${p=>p.theme.media.tablet}{
        max-width: 75%;
    }

`

const Text = styled.p`
    margin: 0;
    padding-bottom: 16px;
    color: ${p=>p.theme.palette.grey[700]};
    font-size: 1.2rem;
    font-weight: 500;
`



const Footer = styled.div`
    height: 20%;    
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;        
    padding: 16px;
`

const FooterWrapper = styled.div`
    width:100%;
    max-width: 1250px;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
`


const RegisterButton = styled(Button)`
    border-radius: 18px;
    background: linear-gradient(90deg, rgba(0,141,54,1) 0%, rgba(57,169,53,1) 35%, rgba(147,192,31,1) 100%);
`

const IconWrapper = styled.div`
    display:flex;
    align-items:center;
    gap: 8px;
`

const Icons = styled.img`
    max-width: 50px;
`

const ImageWrapper = styled.div`
    flex: 1;
    width:100%;
    height:100%;
    min-width:350px;
    display:flex;
    flex-direction: column;
`



//background:  linear-gradient(90deg, rgba(76,175,80,1) 0%, rgba(74,204,14,1) 100%);
export default LandingPage;