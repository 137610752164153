import styled from '@emotion/styled';
import { CardHeader } from '@mui/material';
import { Link } from 'react-router-dom';
import Avatar from '../Atoms/Avatar';
import { CardBody, CardTitle, CardWrapper } from "../Atoms/Card"
import IconButton from '../Atoms/IconButton';
import MoreHorizontal from '../Icons/MoreHorizontal';
import MoreButtonClient from './molecules/MoreButtonClient';


const ClientSummaryCard = ({
    close,
    setOpenModal,
    setFormType,
    client,
    setClientToModify
})=>{


// todo: make card clikcable to go to client detail page   
//<CardLink key={`${client?._id}`} to={`/app/clients/${client._id}`}>                            

    return(
        
        <CardWrapper style={{margin:0, paddingBottom: "8px"}}>
            <StyledCardHeader>
                <Avatar src={client?.profilePicture} alt="user avatar">{client?.nombre?.[0]}</Avatar>
                <UserDescription >
                    <StyledCardTitle>{`${client?.name}`}</StyledCardTitle>
                    {client?.rnc && <CardSubTitle>{`RNC:  ${client.rnc}`}</CardSubTitle>}
                    {client?.cedula && <CardSubTitle>{`Cedula:  ${client.cedula}`}</CardSubTitle>}
                </UserDescription>
            
            </StyledCardHeader>
            <CardBody style={{flexDirection: "row"}}>
                <Container>
                   <MoreButtonClient 
                        setOpenModal={setOpenModal}
                        setFormType={setFormType}                        
                        setClientToModify={setClientToModify}
                        client={client}
                    />
                </Container>
                
            </CardBody>
        </CardWrapper>    
    )
}





const StyledCardTitle = styled(CardTitle)`
    font-size: 1rem;
    margin:0;
    color: ${p=>p.theme.palette.grey[800]}; 
`

const StyledCardHeader = styled.div`
    display:flex;
    gap: 12px;        
`

const UserDescription = styled.div`
    flex-direction: column;
    justify-content: center;
    min-height: 55px;
    margin-bottom: 16px;
`

const CardSubTitle = styled.h5`
    margin: 0px;
    color: ${p=>p.theme.palette.grey[600]};    
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    
`

const Container = styled.div`
    display:flex;
    justify-content: flex-end;
`


const CardLink = styled(Link)`
    text-decoration: none;    
`

const LabelValueContainer = styled.div`     
    gap: 8px;
    display: flex;
    flex-direction: column ;
`


const LabelValueRow = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 8px;
    `

const Label= styled.span`
    font-weight: bold;
    font-size: 0.75rem;
    min-width: 100px;
`

const Value = styled.span`
    font-size: 0.75rem;
`
export default ClientSummaryCard