import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { updateActivosFijosArray } from '../../../app/activosFijosReducer';
import { apiUrl } from '../../../shared/url';
import { FormWrapper, FormHeader, FormBody, FormActions, FormSubtitle } from '../../Atoms/Forms';
import Button from '../../Atoms/Button';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useSuccessButton from '../../../hooks/useSuccessButton';

const DesactivarActivosFijos = ({
    activoFijoObj,
    close
}) => {
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();
    const { isLoading, setIsLoading, successEvent, responseStateChanges } = useSuccessButton();

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const data = {
            deactivate: true,
        };

        const options = createOptions("POST", data);
        try {
            const response = await goFetch(`${apiUrl}activosFijos/deactivate/${activoFijoObj._id}`, options);
            responseStateChanges(
                response,
                () => dispatch(updateActivosFijosArray(response.activoFijo)),
                close
            );
        } catch (err) {
            responseStateChanges({ error: err.error });
        }
    };

    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormHeader isTablet={isTablet}>
                Desactivar Activo Fijo
                <FormSubtitle>{`${activoFijoObj?.codigo} - ${activoFijoObj?.descripcion}`}</FormSubtitle>
            </FormHeader>
            <FormBody isTablet={isTablet} style={{height: "90%"}}>
                <Wrapper>
                    <Text>El activo fijo será desactivado. No podrá ser seleccionado para operaciones futuras. Toda la información referente a este activo fijo continuará guardada en la base de datos.</Text>
                    <Text>Puedes volver a activar el activo fijo en el futuro si lo necesitas.</Text>
                    <Text>¿Desea continuar con la desactivación del activo fijo?</Text>
                </Wrapper>
            </FormBody>
            <FormActions>
                <Button error outline onClick={close}>Volver</Button>
                <Button
                    as="button"
                    type="submit"
                    success
                    loading={isLoading}
                    successEvent={successEvent}
                >
                    Desactivar
                </Button>
            </FormActions>
        </FormWrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 500px;
    padding-bottom: 20px;
    color: ${p => p.theme.palette.grey[800]};
`;

const Text = styled.p`
    margin: 0;
`;

export default DesactivarActivosFijos;
