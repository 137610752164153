import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Atoms/Alert"
import {resetMessage} from '../../app/messagesReducer'



const AlertMessages = ()=>{

    const dispatch = useDispatch();
    const {successMessage, errorMessage} = useSelector(state=>state.messages)
    const [open, setOpen] = React.useState();

    //only for testing
    //const successMessage = "Cirugia guardada exitosamente" 
    //const errorMessage = undefined

    React.useEffect(()=>{
        if(successMessage || errorMessage){
            setOpen(true)
            setTimeout(()=>dispatch(resetMessage()), 6000)
        }
                        

    },[successMessage, errorMessage, dispatch])

    

    return (<>
    
        { (successMessage || errorMessage) &&
            <Alert
            message={successMessage || errorMessage}
            success={successMessage}
            error={errorMessage}
            open={open}
            close
            />
        }
        </>
    )


}




export default AlertMessages;