import { TextField, useMediaQuery } from "@mui/material";
import React from "react";
import styled from '@emotion/styled';
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../app/messagesReducer";
import useCheckForm from "../../../hooks/useCheckForm";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { isCedula, isEmail, isLength, isNSS, isTelefono, validate } from "../../../shared/validators";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms";





const PacienteInput = ({
    close,
    pacienteInfo,
    setPacienteInfo
}) => {
    const isLaptop = useMediaQuery('laptop');
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();    
    const [checkDatabase, setCheckDatabase] = React.useState(false);    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()

    const initialState = { 
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        nss: '',        
        telefono: '',
        email: '',
        contacto: {
            firstnameC:'',
            lastnameC: '',
            relacionC: '',
            telefonoC: '',
            emailC: '',
          }        
      }

    const [values, setValues] = React.useState(initialState)
    

      const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        nss: '',        
        telefono: '',
        email: '',
      })

      const [formCompleted, errorCount] = useCheckForm(values,errors, ["firstname", "lastname"])

      const textFieldStyle = {
        '& .MuiOutlinedInput-root':{
            borderRadius: '12px'
        }
    }

     
    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onFocus = (event, validators)=>{
        event.target.setAttribute('autocomplete', 'off');
                    
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target        
        const error = validate(value, validators)        
        setErrors(prevState=>({...prevState, [name]:error}))
        return error
    }

    const onBlurNss = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    const onBlurCedula = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    



    const onSubmit = async (event)=>{
        //event.preventDefault();
        //check if form is completed and has no errors setPaciete info, if not show error message
        if(formCompleted && errorCount===0){
            setPacienteInfo(values)
            close()

        }else{
            dispatch(errorMessage('Formulario no completado'))
            console.log('Form is not completed')
        }             
    }

    console.log(errors)
  

    // not using onSubimt as we are not sending to the database yet. We are updating the state of the cotizaciones Object
    return (
        <StyledFormWrapper>
            
            <FormTitle isTablet={isTablet}>{'Informacion Paciente'}</FormTitle>
                   
            <StyledFormBody isTablet={isTablet} style={{marginTop: '8px'}}>
                <TextField 
                    value = {values.firstname} label = "Primer Nombre" name = "firstname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    required={true}
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isLength])}
                    spellCheck="false"
                />
                <TextField 
                    value = {values.secondname} label = "Segundo Nombre" name = "secondname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={errors.secondname}
                    error={errors.secondname}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck="false"
                />
                <TextField 
                    value = {values.lastname} label = "Primer Apellido" name = "lastname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    required
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck="false"
                />
                <TextField 
                    value = {values.secondLastname} label = "Segundo Apellido" name = "secondLastname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.cedula} label = "Cedula" name = "cedula"
                    onChange = {(event)=>onChangeValues(event,[isCedula],true)}
                    variant = "outlined"
                    helperText={errors.cedula}
                    error={errors.cedula}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlurCedula(event, [isCedula])}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.nss} label = "NSS" name = "nss"
                    onChange = {(event)=>onChangeValues(event,[isNSS], true)}
                    variant = "outlined"
                    helperText={errors.nss}
                    error={errors.nss}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlurNss(event, [isNSS])}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.telefono} label = "Telefono (888)888-8888" name = "telefono"
                    onChange = {(event)=>onChangeValues(event,[isTelefono], true)}
                    variant = "outlined"
                    helperText={errors.telefono}
                    error={errors.telefono}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isTelefono])}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.email} label = "Email" name = "email"
                    onChange = {(event)=>onChangeValues(event,[isEmail], true)}
                    variant = "outlined"
                    helperText={errors.email}
                    error={errors.email}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isEmail])}
                    spellCheck="false"
                />
                
            </StyledFormBody>
            
            <FormActionsBasic
                onSubmit={onSubmit}
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                disabledSuccess={!formCompleted || errorCount>0 || checkDatabase}
            />
            
        </StyledFormWrapper>
    )
    
}

const StyledFormWrapper = styled(FormWrapper)`
    
    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }
`


const StyledFormBody = styled(FormBody)`
    margin-bottom: 85px;
    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 20px;
    }

`


export default PacienteInput