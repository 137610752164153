import React from 'react';
import styled from '@emotion/styled';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../../Atoms/Forms';
import { useDispatch } from 'react-redux';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { addUpdatedUser, updateActiveUsers } from '../../../app/usersReducer';
import { apiUrl } from '../../../shared/url';

const DesactivarUser = ({
    userId,
    close
})=>{

    const isTablet= useMediaQuery('tablet');
    const dispatch = useDispatch();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    
    const onSubmit = async (event) =>{
        event.preventDefault();
        setIsLoading(true);

        let data = {
            desactivar:true,
            userId: userId           
          }

        const options = createOptions('PUT', data)
                        
        try{
            const response = await goFetch(`${apiUrl}users/deactivate-user/${data.userId}`, options)  
            console.log(response)
            responseStateChanges(response,null,()=>{
                dispatch(addUpdatedUser(response.updatedUser))
                //updates active users in order to determine if they have enough seats.
                dispatch(updateActiveUsers(response.activeUsers))
                close()    
            })            
        }catch(error){
            console.log(error);
        }  
    }        
          

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Desactivar Usuario</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <Wrapper>
                    <Text>El usuario desactivado no podra acceder a la aplicacion. Toda la informacion referente a este usuario continuara guardada en la base de datos.</Text>
                    <Text>Desea continuar con la desactivacion del usuario?</Text>                                
                </Wrapper>
                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                successText="Desactivar"
           />
            
        </FormWrapper>
    )
}


const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:12px;
    max-width: 500px;
    color: ${p=>p.theme.palette.grey[800]}
`

const Text = styled.p`
    margin:0;
`

export default DesactivarUser