import styled from '@emotion/styled';


const BenefitsList = ()=>{


    return(
        <GridWrapper>

                   
                        <IconWrapper>
                            <Icons src='/img/Icono_calendar.png'/>
                            <TextIcons>Cronograma de cirugias</TextIcons>
                        </IconWrapper>
                        <IconWrapper>
                            <Icons src='/img/Icono_solicitud.png'/>
                            <TextIcons>Solicitud de cirugias por fuerza de ventas o cirujanos</TextIcons>
                        </IconWrapper>
                        <IconWrapper>
                            <Icons src='/img/Icono_notificacion.png'/>
                            <TextIcons>Notificacion de cirugias a Especialistas Clinicos o Tecnicos de Cirugia</TextIcons>
                        </IconWrapper>
                        <IconWrapper>
                            <Icons src='/img/Icono_documentacion.png'/>
                            <TextIcons>Estatus de documentacion</TextIcons>
                        </IconWrapper>
                        <IconWrapper>
                            <Icons src='/img/Icono_reportes.png'/>
                            <TextIcons>Reportes post-cirugia</TextIcons>
                        </IconWrapper>
                        <IconWrapper>
                            <Icons src='/img/Icono_evaluacion.png'/>
                            <TextIcons>Evaluacion de servicio por parte de los cirujanos</TextIcons>
                        </IconWrapper>
                        <IconWrapper>
                            <Icons src='/img/Icono_checkIn.png'/>
                            <TextIcons>Check-in de los especialistas clinicos o tecnicos de cirugia cuando llegan al centro</TextIcons>
                        </IconWrapper>
                    </GridWrapper>    

    )
}



const GridWrapper = styled.div`
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    padding: 40px 24px 24px 24px;
    gap: 12px;

    @media ${p=>p.theme.media.laptop}{
        padding: 24px 200px 24px 200px;
    }

    
`

const IconWrapper = styled.div`
    display:flex;
    align-items:center;
    gap: 8px;
`

const Icons = styled.img`
    max-width: 50px;
`

const TextIcons = styled.p`
    margin: 0;
    padding-bottom: 16px;
    color: ${p=>p.theme.palette.primary.dark};
    font-size: 1rem;
    font-weight: 500;
`


export default BenefitsList