import cookie from 'js-cookie'
//import {Route, Switch, Redirect} from "react-router-dom";



export const setCookie = (key, value) => {
   if (window !== undefined){
     cookie.set(key, value, {expires: 1});
   }
}


export const removeCookie = (key) => {
  if (window !== undefined){
    cookie.remove(key, {expires: 1});
  }
}

export const getCookie = (key) => {
  if (window !== undefined){
    return cookie.get(key);
  }
}


export const setLocalStorage = (key, value) => {
   if (window !== undefined){
     localStorage.setItem(key, JSON.stringify(value));
   }
}


export const removeLocalStorage = (key, value) => {
   if (window !== undefined){
     localStorage.removeItem(key);
   }
}

//works like middleware. Next is callback function provided to authenticate. Can set alert or any other action
export const authenticate = (response, next) => {
    //console.log('Authenticas helpr on signin response', response);
    console.log(response);
    setCookie('token', response.token);
    setCookie('subToken', response.subToken);
    setLocalStorage('user', response.user);
    setLocalStorage('configuration', response.configuration);
    //next()
    return response
}

export const isAuth = ()=> {
  if (window !== undefined){
    const cookieChecked = getCookie('token');
    if (cookieChecked){
      if(localStorage.getItem('user')){
        return JSON.parse(localStorage.getItem('user'))
      }else{
        return false
      }
    }else return false
  }else return false
}


export const signout = next =>{
  removeCookie('token');
  removeLocalStorage('user');
  removeCookie('subToken')
  removeLocalStorage('configuration');
}

// needs to be reviewed
export const updateUser = (user, next)=>{
  if (window !== undefined){
   localStorage.setItem("user", JSON.stringify(user));
 }
}
