import Autocomplete from "../../Atoms/Autocomplete"
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { searchPacientes } from '../../../app/thunkActions/pacientesActions';

//Wraps pacientes specific logic for autocomplete


const PacientesAutocomplete = ({
    onChange
})=>{

    const dispatch = useDispatch();
    const pacientes = useSelector(state=>state.pacientes.pacientes)
    
    /*React.useEffect(()=>{
        dispatch(fetchPacientes())
    },[dispatch])
    */

    const onInputChange = (e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length > 2){
            dispatch(searchPacientes(e.target.value))
        }
    }

    console.log(pacientes)
    return(
        <Autocomplete                  
            label="Paciente"
            name="paciente"
            id="paciente"
            onChange={onChange}
            onInputChange={onInputChange}
            options={pacientes}
            getOptionLabel={(option)=>`${option.firstname} ${option.secondname} ${option.lastname} ${option.secondLastname} - ${option.nss} `}
        />
    )
}


export default PacientesAutocomplete;