import Autocomplete from "../../Atoms/Autocomplete"
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import { addDoctors } from "../../../app/doctorsReducer";
//Wraps pacientes specific logic for autocomplete


const DoctorsAutocomplete = ({
    onChange,
    value
})=>{

    const dispatch = useDispatch();
   const doctors = useSelector(state=>state.doctors.doctors)
    
    const [_value, set_Value] = React.useState();
    
    //console.log(value)
    // React.useEffect(()=>{
    //     dispatch(fetchDoctors())
    // },[dispatch])


    //if value is passed selects doctor from doctor list and sets value to pass down to input. This is done to persist filter. 
    React.useEffect(()=>{
        if(value){
          let  doctor = doctors?.filter((doctor)=>doctor._id === value)[0];
          console.log(doctor);
            set_Value(`${doctor?.firstname} ${doctor?.secondname} ${doctor?.lastname} ${doctor?.secondLastname}`);
        }
    
      },[dispatch, value, doctors])
    
      //console.log(_value);


    const onInputChange=async (event)=>{

        if(event.target.value.length > 2){
           

           const options = createOptions('GET')
           let response;
             
           try{
               //dispatch(loadingDoctors());
               response = await goFetch(apiUrl + 'doctors/search/'+ event.target.value,options, dispatch );
               console.log(response.doctors)
               dispatch(addDoctors({doctors: response.doctors}));
           }catch(error){
               console.log(error);
           }
        }

    }

    return(
        <Autocomplete                  
            label="Doctor"
            name="doctor"
            id="doctor"
            value={_value}
            onChange={onChange}
            onInputChange={onInputChange}
            options={doctors}
            getOptionLabel={(option)=>`${option?.firstname} ${option?.secondname} ${option?.lastname} ${option?.secondLastname}`}
        />
    )
}


export default DoctorsAutocomplete;