import styled from '@emotion/styled/macro';
import Loading from '../../Atoms/Loading';
import { CardBody,CardTitle, CardWrapper as Card } from "../../Atoms/Card";



const CirugiaLoading = ()=>{


    const list = [1,2,3,4,5,6]


    return(
        <Wrapper>

            {
                list.map((list, index)=>(
                    <StyledCard key={`${list}} ${index}`}>
                        <InnerWrapper>
                            <Item>
                                <ChipLoading/>
                                <ChipLoading/>
                                
                            </Item>
                            <Item column>
                                <LoadingText/>
                                <LoadingText/>
                            </Item>
                            <Item>
                                <LoadingText/>
                                
                            </Item>
                            <Item >
                                <LoadingText/>                        
                            </Item>
                            <Item >
                                <LoadingTextCentro/>                        
                            </Item>
                        </InnerWrapper>                
                    </StyledCard>

                ))
            }
            

            
        </Wrapper>
        
    )

}



const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:12px;
    flex-wrap: wrap;
`

/*
const Card = styled(Loading)`    
    height: 100px;
    border-radius: 10px;    
`
*/

const StyledCard = styled(Card)`
    min-height: 100px;
    border-radius: 10px;   
    
`

const Item = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: ${p=>p.column? "column": "row"}
    

`

const InnerWrapper = styled.div`
    display:flex;
    gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
`


const ChipLoading = styled(Loading)`
    width: 65px;
    border-radius: 16px;
    height:20px
`
const LoadingText =styled(Loading)`
    width: 125px;
    height:20px;
    border-radius: 8px;
`
const LoadingTextCentro =styled(Loading)`
    width: 175px;
    height:20px;
    border-radius: 8px;
`



export default CirugiaLoading;