import styled from '@emotion/styled/macro';
import TextInput from '../../Atoms/TextInput';


const ProductosTableMobile = ({
    cirugia,
    productos,
    onChangeHandler,
    consumo
})=>{

    console.log(productos);

    return(
        <Wrapper>
            {productos?.map((producto, index)=>(
                    <ProductoWrapper key={`${producto} ${index}`}>
                        <Container>
                        <Codigo>{producto.producto.codigo}</Codigo>
                            <Description>{producto.producto.descripcion}</Description>
                            <Text>{`Cantidad aprobada: ${producto.cantidad || 0}`}</Text>
                            <Text>{`Cantidad usada: ${producto.cantidadUsada || 0}`}</Text>
                        </Container>
                        {
                            consumo &&
                            <Item>
                             <SmallTextInput value={productos[index].cantidadUsada} onChange={(event)=>onChangeHandler(event, index)}/>
                            </Item>
                        }
                        
                        

                    </ProductoWrapper>
                ))
            }
        </Wrapper>
    )
}



const Wrapper = styled.div`
`

const Item = styled.div`

`


const ProductoWrapper = styled.div`
    display:flex;
    gap:4px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[300]};
    padding: 8px 0px;
    justify-content: space-between;
`

const Codigo = styled.p`
    margin: 0;
    font-size: .75rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]};
`

const Description = styled.p`
    font-size: .875rem;
    margin: 0;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]};
`

const Text = styled.p`
    margin: 0;
    font-size: .75rem;
    color: ${p=>p.theme.palette.grey[700]};
`   
const Container = styled.div`
    display:flex;
    flex-direction:column;
    gap:4px;
    max-width: 80%;
`
const SmallTextInput = styled(TextInput)`
    max-width: 50px;
`


export default ProductosTableMobile;