import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import useCirugia from '../../../hooks/useCirugia';
import CardInfo from '../../Atoms/CardInfo';
import React, { useState, useRef, useEffect } from 'react';
import Button from '../../Atoms/Button';
import { apiUrl } from '../../../shared/url';
import { getCookie } from '../../../shared/authHelpers';

const Reporte = ({
    cirugiaId,
    cirugia
}) => {
    let _cirugia = useCirugia(cirugiaId)
    

    if (_cirugia) {
        cirugia = _cirugia
    }

    

    if (!cirugia.reporte.enviadoReporte && !cirugia.info.realizada) {
        return (
            <Wrapper>
                <Header>
                    <Title>{`Reporte de Cirugia: ${cirugia.paciente.firstname} ${cirugia.paciente.lastname}`}</Title>
                </Header>
                <CardInfoWrapper>
                    <CardInfo>
                        Reporte estara disponible cuando concluya la cirugia.
                    </CardInfo>
                </CardInfoWrapper>
            </Wrapper>
        )
    }

    if (!cirugia.reporte.enviadoReporte && cirugia.info.realizada) {
        return (
            <Wrapper>
                <Header>
                    <Title>{`Reporte de Cirugia: ${cirugia.paciente.firstname} ${cirugia.paciente.lastname}`}</Title>
                </Header>
                <CardInfoWrapper>
                    <CardInfo>
                        Especialista Clinico aun no a enviado el reporte.
                    </CardInfo>
                </CardInfoWrapper>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Header>
                <Title>{`Reporte de Cirugia: ${cirugia.paciente.firstname} ${cirugia.paciente.lastname}`}</Title>
            </Header>
            <Row>
                <Label>Clasificacion: </Label>
                <Text>{cirugia.reporte.classificacion}</Text>
            </Row>            
            <Row>
                <Label>Descripcion: </Label>
                <Text>{cirugia.reporte.descripcion}</Text>                
            </Row>
            <Row>
                <Label>Comentarios: </Label>
                <Text>{cirugia.reporte.comentarios}</Text>                
            </Row>
        </Wrapper>
    )
}

const CardInfoWrapper = styled.div`
    display:grid;
    place-content:center;
    padding: 16px 16px 24px ;
    max-width: 700px;
    gap: 16px;
    @media ${p=>p.theme.media.tablet}{
        min-width: 500px;
        min-height: 350px;
    }
`

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    padding: 16px 16px 24px ;
    max-width: 700px;
    gap: 16px;
    @media ${p=>p.theme.media.tablet}{
        min-width: 500px;
        min-height: 350px;
    }
`

const Header = styled.div`
    height: 45px;
    margin-bottom: 8px;

    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 0px;
    }
`
const Title = styled.p`
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
`

const Row = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const Label = styled.p`
    margin: 0;
    color: ${p=>p.theme.palette.grey[800]};
    font-weight: 600;
    font-size: 0.875rem;
`

const Text = styled.p`
    margin: 0;
    color: ${p=>p.theme.palette.grey[800]};
    font-size: 0.875rem;
`

export default Reporte