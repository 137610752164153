import React from 'react';
import Chip from '../../Atoms/Chip';
import styled from '@emotion/styled/macro';
import Check from '../../Icons/Check';
import X from '../../Icons/X';
import { useTheme } from '@mui/system';
import { ThemeContext } from '@emotion/react';
import LabelValue from '../../Atoms/LabelValue';



const DocumentosChips = (props)=>{

    const theme = useTheme();

    
    //console.log(props.documentos.carnet?.delivered)
    const initialValues = React.useMemo(()=> {
    
      return {
          carnet: {
            required: props.documentos?.carnet?.required,
            delivered: props.documentos?.carnet?.delivered
          },
          cedula: {
            required: props.documentos?.cedula?.required,
            delivered: props.documentos?.cedula?.delivered
          },
          aprobacion: {
            required: props.documentos?.aprobacion?.required,
            delivered: props.documentos?.aprobacion?.delivered
          },
          formulario: {
            required: props.documentos?.formulario?.required,
            delivered: props.documentos?.formulario?.delivered
          },
          indicacion: {
            required: props.documentos?.indicacion?.required,
            delivered: props.documentos?.indicacion?.delivered
          },
          pago: {
            required: props.documentos?.pago?.required,
            delivered: props.documentos?.pago?.delivered
          }
      }
    } ,[props.documentos?.carnet?.required,
      props.documentos?.carnet?.delivered,
      props.documentos?.cedula?.required,
      props.documentos?.cedula?.delivered,
      props.documentos?.aprobacion?.required,
      props.documentos?.aprobacion?.delivered,
      props.documentos?.formulario?.required,
      props.documentos?.formulario?.delivered,
      props.documentos?.indicacion?.required,
      props.documentos?.indicacion?.delivered,
      props.documentos?.pago?.required,
      props.documentos?.pago?.delivered
    ])
    
    

    

    
    const [values, setValues] = React.useState(initialValues);
    
    
    
    React.useEffect(()=>{
      setValues(initialValues)
    },[initialValues])
    
    
    const iconSize = "12px"
    const colorSuccess = theme.palette.success.light
    const colorError = theme.palette.error.light
    
      return (
    
        <Wrapper>

        <LabelValue label="Documentos:"/>  
    
          {values.cedula.required &&
                <Chip
                 icon={values.cedula.delivered ? <Check size={iconSize} color={colorSuccess}/> : <X size={iconSize} color={colorError}/>}
                 success={values.cedula.delivered}
                 error={!values.cedula.delivered}
                 label="Cedula"
                 outline
                 small
                />
          }
    
          {values.carnet.required &&
                <Chip
                 icon={values.carnet.delivered ? <Check size={iconSize} color={colorSuccess}/> : <X size={iconSize} color={colorError}/>}
                 success={values.carnet.delivered}
                 error={!values.carnet.delivered}
                 label="Carnet"
                 outline
                 small
                />
          }
    
    
         {values.formulario.required &&
               <Chip
                icon={values.formulario.delivered ? <Check size={iconSize} color={colorSuccess}/> : <X size={iconSize} color={colorError}/>}
                success={values.formulario.delivered}
                 error={!values.formulario.delivered}
                label="Formulario"                
                outline
                small
               />
          }
    
            {values.indicacion.required &&
               <Chip
                icon={values.indicacion.delivered ? <Check size={iconSize} color={colorSuccess}/> : <X size={iconSize} color={colorError}/>}
               success={values.indicacion.delivered}
                 error={!values.indicacion.delivered}
                label="Indicacion"
                outline
                small
               />
            }
    
    
           {values.aprobacion.required &&
                 <Chip
                  icon={values.aprobacion.delivered ? <Check size={iconSize} color={colorSuccess}/> : <X size={iconSize} color={colorError}/>}
                  success={values.aprobacion.delivered}
                  error={!values.aprobacion.delivered}
                  label="Aprobacion"
                  outline
                  small
                 />
            }
    
           {values.pago.required &&
                 <Chip
                  icon={values.pago.delivered ? <Check size={iconSize} color={colorSuccess}/> : <X size={iconSize} color={colorError}/>}
                  success={values.pago.delivered}
                  error={!values.pago.delivered}
                  label="Pago"
                  outline
                  small
                 />
            }
    
    
    
    
        </Wrapper>
    
    
    
      )
    
    
    }

    const Wrapper = styled.div`
    
    `


    export default DocumentosChips;