




const ciudades ={
    mexico: [
        "Ciudad de México",
        "Iztapalapa",
        "Ecatepec de Morelos",
        "Guadalajara",
        "Puebla",
        "Ciudad Juárez",
        "Tijuana",
        "León",
        "Gustavo A. Madero",
        "Zapopan",
        "Monterrey",
        "Nezahualcóyotl",
        "Chihuahua",
        "Naucalpan de Juárez",
        "Mérida"
      ],
      republica_dominicana: [        
            "Santo Domingo",
            "Santiago",
            "San Pedro de Macoris",
            "La Romana",
            "San Cristobal",
            "Puerto Plata",
            "San Francisco de Macoris",
            "Higuey",
            "Moca",
            "La Vega"                  
      ]

  }

  export default ciudades;