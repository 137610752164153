import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import Button from '../Atoms/Button';

const InfoRow = ({children, color})=>(
    <Row color={color}>
        <MaxWidth>
            {children}
        </MaxWidth>
    </Row>
)





const WelcomeMd = ()=>{

    const theme = useTheme();
    

    return (
        <ExternalWrapper>
                        
        <Header>
            <HeaderWrapper>                    
                
                <Logo src='/img/xirugias_logo_512x512.png' alt="company logo"/>
                <Button  primary to={"/auth"}>Login</Button>
            </HeaderWrapper>
                
                
        </Header>
       <InfoRow >
           <MainRowWrapper>                                                 
               <Info>
                    <MainTitle>Una plataforma pensada para Cirujanos y su equipo de trabajo</MainTitle>
                    <ParagraphWrapper>
                        <Text>
                            Retoma el control de tus cirugias. Con Xirugias podras ver los casos que tienes aprobados con cada casa comercial incluyendo 
                            los productos, el status de la documentacion y el especialista clinico asignado.
                        </Text>                                              
                        <Text>
                            Podras evaluar cada cirugia ayudando a continuar mejorando el servico de las casas comerciales.
                        </Text>
                        <Text>
                            Un servicio sin costo para Cirujanos y su equipo de trabajo.
                        </Text>                        
                    </ParagraphWrapper>                                                          
               </Info>
               <ImageWrapper>
                    <Image src="/img/control_panel.svg"/>
               </ImageWrapper>
           </MainRowWrapper>                    
        </InfoRow>

        <InfoRow >
                <MainRowWrapper>
                    <Info style={{alignItems: "center"}}>
                         <MainTitle>Podras solicitar agendar o suspender la cirugia directamente en la plataforma.</MainTitle>                         
                    </Info>
                    <ImageWrapper>
                        <Image  src="/img/ficha_cirugia.png"/>
                    </ImageWrapper>
                </MainRowWrapper>            
            </InfoRow> 

            <InfoRow  >
                <MainRowWrapper reverse={true}>
                    <ImageWrapper  >
                            <Image  style={{maxHeight: '400px'}} src="/img/filtro-cirugia.png"/>
                    </ImageWrapper>
                    <Info style={{alignItems: "center"}}>
                         <MainTitle>Podras evaluar el servicio y los productos al concluir la cirugia</MainTitle>
                    </Info>
                    
                </MainRowWrapper>            
            </InfoRow>  
            <InfoRow >
                <MainRowWrapper>
                    <Info style={{alignItems: "center"}}>
                         <MainTitle>Quieres tener mejor control sobre tus cirugias?</MainTitle>                         
                         <Text>Pidele a tu casa comercial favorita que te invite a unirte a Xirugias! </Text>                         
                    </Info>
                    <ImageWrapper>
                        <Image  src="/img/ficha_cirugia.png"/>
                    </ImageWrapper>
                </MainRowWrapper>            
            </InfoRow> 
        

        <Footer>
            <FooterWrapper>

            </FooterWrapper>
        </Footer>
    </ExternalWrapper>
    )


}


const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100%;
    background-color: #ffff;
`

const HeaderWrapper = styled.div`
    width:100%;
    max-width: 1250px;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
`

const Body = styled.div`
    display:flex;
    flex-direction: column;
    
    margin-top: 10%;
    gap: 32px;
    height: 100%;

    width: 100%;
    @media ${p=>p.theme.media.laptop}{
        width: 400px;
    }
`

const Logo = styled.img`    
    max-width: 175px;
    width: 100%;
    height: 100%;
    object-fit:contain;
    @media ${p=>p.theme.media.laptop}{
        max-width: 200px;
    }
`

const ExternalWrapper = styled.div`
    width:100%;
    height: 100%;
    display:flex;
    flex-direction: column;        
    background-color: #ffff;    
`


const Row = styled.div`
    display:flex;
    justify-content: center;    
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: ${p=>p.color || '#ffff'};

    @media ${p=>p.theme.media.laptop}{
        
    }
    
`

const MaxWidth = styled.div`
    max-width: 1250px;
    min-height: 100%;
    padding: 16px;
`

const Header = styled.div`
    height: 20%;    
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;        
    padding: 16px;
`

const MainRowWrapper = styled.div`
    display:flex;
    gap: 48px;
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: ${p=>p.reverse ? 'column-reverse' : 'revert'};

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
    }

`

const ImageWrapper = styled.div`
    flex: 1;
    width:100%;
    height:100%;
    min-width:350px;
`

const Image = styled.img`    
    width:100%;
    height: 100%;
    object-fit:contain;
`


const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 8px;    
    width:100%;
    aling-self:center;
    
`

const MainTitle=styled.h1`
    margin:0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    color: ${p=>p.theme.palette.grey[800]};
`


const ParagraphWrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    
    padding: 32px;
    line-height: 1.8;

    @media ${p=>p.theme.media.tablet}{
        
    }

`

const Text = styled.p`
    margin: 0;
    padding-bottom: 16px;
    color: ${p=>p.theme.palette.grey[700]};
    font-size: 1rem;
    font-weight: 500;
`

const Footer = styled.div`
    height: 20%;    
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;        
    padding: 16px;
`

const FooterWrapper = styled.div`
    width:100%;
    max-width: 1250px;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
`


export default WelcomeMd;