import React from "react";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "../app/messagesReducer";


// manages states for loading and success state for buttons. 

const useSuccessButton = ()=>{
    const dispatch = useDispatch()
    const [isLoading,setIsLoading] = React.useState(false);
    const [successEvent, setSuccessEvent] = React.useState(false);
    

    //accepts two callbacks, one for success event and the other for timoutcall back
    const responseStateChanges = (response,successDispatch, timeoutCallback)=>{
        
        if(!response.error){            
            successDispatch && successDispatch()
            dispatch(successMessage(response.message));            
            setIsLoading(false);
            setSuccessEvent(true)
            setTimeout(()=>{                       
                setSuccessEvent(false)                
                timeoutCallback && timeoutCallback()
            }, 2000)  
        }else{                                   
            dispatch(errorMessage(response.error));
            setIsLoading(false);                
        }

    }



    return {
        isLoading,
        setIsLoading,
        successEvent,
        setSuccessEvent,
        responseStateChanges
    }

}


export default useSuccessButton;