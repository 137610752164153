import {useSelector} from 'react-redux';


const useCommentMaker =()=>{
  const {user} = useSelector(state=>state.auth);
  
  const commentMaker = (comments)=>{
      let comentariosObj;

      if(comments){
        comentariosObj = {
            autor: {
              _id: user._id,
            firstname: user.firstname|| "",
            lastname: user.lastname || ""
            },
            fecha: new Date(),
            comentario: comments
          }

        }else {
          comentariosObj = false
        }

      return comentariosObj
    
  }
  
  return commentMaker


}

export default useCommentMaker
