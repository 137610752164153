
import { utcToZonedTime } from 'date-fns-tz'



function formatTime(time){

  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // console.log(timezone)
  
  if(!time) return '[hora pendiente]'
  // included this function in order to always get the DR time zone regardless of where the client is located. 
  let newTime = utcToZonedTime(time, 'America/Santo_Domingo')

  //let newTime = new Date(time);
    newTime = newTime.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
    return newTime
}


  export default  formatTime;
