import { useTheme } from "@mui/system";



const useColor = (color)=>{
    const theme = useTheme();

    let selectedColor;
    let textColor= '#000000';
    let pastel;

    

    switch (color) {
        case "primary":
            selectedColor = theme.palette.primary.main;
            textColor = "#fff";
            return [selectedColor, textColor, pastel];
        case "secondary":
            selectedColor = theme.palette.secondary;
            textColor = "#fff";
            return [selectedColor, textColor, pastel];
        case "success":
            selectedColor = theme.palette.success.light;
            pastel = theme.palette.success.pastel;
            textColor = "#fff";
            return [selectedColor, textColor, pastel];
        case "warning":
            selectedColor = theme.palette.warning.light;
            pastel = theme.palette.warning.pastel;
            textColor = "#fff";
            return [selectedColor, textColor, pastel];
        case "error":
            selectedColor = theme.palette.error.light;
            pastel = theme.palette.error.pastel;
            textColor = "#fff";
            return [selectedColor, textColor, pastel];
        default:
            return [selectedColor, textColor, pastel];
    }

}

export default useColor;