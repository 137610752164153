import styled from "@emotion/styled/macro";
import { faUserCircle, faPencil, faEdit, faLock, faLockOpen, faFile, faFileInvoice, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/system";
// import {
//     Menu,
//     MenuButton,
//   } from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";

import MoreHorizontal from "../../Icons/MoreHorizontal";


// import { StyledMenuItem, StyledMenuLink, StyledMenuList } from "../../Atoms/StyledDropdownReach";
import { errorMessage, successMessage } from "../../../app/messagesReducer";
import { updateProductosArray } from "../../../app/productosReducer";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import { Menu, MenuItem } from "@mui/material";
import Button from "../../Atoms/Button";
import React from "react";
import IconButton from "../../Atoms/IconButton";
import useSubscriptionToken from "../../../hooks/useSubscriptionToken";


const MoreButtonProducts= ({
    setOpenModal,
    setFormType,
    productoObj,
    setProductoObj,
})=>{
    const dispatch= useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

    const subscription = useSubscriptionToken();
    const planAllowsCotizaciones = subscription?.companyPermissions?.includes('checkIn');;
    

    const onSelect = (formType)=>{
        setOpenModal(true);
        setFormType(formType);       
        setProductoObj(productoObj);
        setAnchorEl(null)
       
      }

    const activate = async(producto)=>{
        let data = {
            deactivate:false,            
          }
        
        const options = createOptions("POST", data)
        try{
            const response = await goFetch(`${apiUrl}productos/deactivate/${producto._id}`, options)
            dispatch(successMessage(response.message));
            dispatch(updateProductosArray(response.producto));                                                
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        
    }
           
    const onClickHandler = (event)=>{
        setAnchorEl(event.currentTarget)
    }
 
    const onCloseHandler = ()=>{
        setAnchorEl(null)
    }

    return(
        <React.Fragment>
            <IconButton
            onClick={onClickHandler}
            >                
                <MoreHorizontal color={theme.palette.grey[600]}/>
            </IconButton>
            <Menu
                open={open}
                onClose={onCloseHandler}
                anchorEl={anchorEl}
            >
                <MenuItem onClick={()=>onSelect('editar')}>
                    <MenuItemWrapper>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: "0.9rem"}} color={theme.palette.grey[600]}/>
                        <Text>Editar</Text>
                    </MenuItemWrapper>
                </MenuItem>
                {planAllowsCotizaciones && [
                    <MenuItem key="precios" onClick={()=>onSelect('precios')}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faDollarSign} style={{fontSize: "0.9rem"}} color={theme.palette.grey[600]}/>
                            <Text>Precios</Text>
                        </MenuItemWrapper>
                    </MenuItem>,
                    <MenuItem key="accesoCot" onClick={()=>onSelect('accesoCot')}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faFileInvoice} style={{fontSize: "0.9rem"}} color={theme.palette.grey[600]}/>
                            <Text>Acceso Cot.</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                ]}
                {productoObj?.deactivated &&
                    <MenuItem onClick={()=>activate(productoObj)}>
                    <MenuItemWrapper>
                        <FontAwesomeIcon icon={faLockOpen} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                        <Text>Activar</Text>
                    </MenuItemWrapper>
                </MenuItem>
                }
                
                {!productoObj?.deactivated &&
                    <MenuItem onClick={()=>onSelect('deactivate')}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faLock} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Desactivar</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                }
            </Menu>

        </React.Fragment>
    )


}


const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Text = styled.p`
    margin:0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}
`


export default MoreButtonProducts;
