import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { errorMessage } from '../../../app/messagesReducer';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useCheckForm from '../../../hooks/useCheckForm';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import { isLength, validate } from '../../../shared/validators';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import TextInput from '../../Atoms/TextInput';


// import useCheckForm from '../../../hooks/useCheckForm';
// import useSuccessButton from '../../../hooks/useSuccessButton';
// import { apiUrl } from '../../../shared/url';
// import FormActionsBasic from "../../Atoms/FormActionsBasic"
// import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
// import TextInput from '../../Atoms/TextInput';
// import { isLength, validate } from '../../shared/validators';


const CentroEdit = ({
    close,
    centro
})=>{
    const dispatch = useDispatch();
    const user = useCurrentUser()
    const initialState = {
        nombre: centro?.nombre || "",
        ciudad: centro?.ciudad || "",
        zona: centro?.zona || "",
        location: {
            latitude: centro?.location?.latitude || undefined,
            longitude: centro?.location?.longitude || undefined
        }
    }

    const accionMaker = useAccionMaker()

    console.log(initialState)
    const [centroInfo, setCentroInfo] = React.useState(initialState)
    console.log(centroInfo)
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();    
    const [errors, setErrors] = React.useState({
      
    })

    //const [formCompleted, errorCount] = useCheckForm(centroInfo,errors, ["nombre", "ciudad", "zona"])
    
    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setCentroInfo(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

   

    const onSubmit = async (event)=>{
        event.preventDefault();
        setIsLoading(true)
        const accion = accionMaker(`Centro editado por ${user?.firstname} ${user?.lastname}`)
        
        const options = createOptions('POST', {...centroInfo, ...accion})
        let response;

        try {
            response = await goFetch(apiUrl+'centros/update/'+ centro?._id, options);
            responseStateChanges(response, ()=>{}, ()=>close())            
        } catch (error) {
            console.log(error)            
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }

        
        
    }


    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Editar informacion del Centro</FormTitle>
            <FormBody>
                <TextInput
                    label="Nombre" name="nombre" id="nombre"
                    value={centroInfo?.nombre}                    
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.nombre ? "Campo Obligatorio": errors.nombre}
                    error={errors.nombre}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Ciudad" name="ciudad" id="ciudad"
                    value={centroInfo?.ciudad}                    
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.ciudad ? "Campo Obligatorio": errors.ciudad}
                    error={errors.ciudad}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="latitude" name="latitude" id="latitude"
                    value={centroInfo?.location?.latitude}                    
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.latitude ? "Campo Obligatorio": errors.latitude}
                    error={errors.latitude}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="longitude" name="longitude" id="longitude"
                    value={centroInfo?.location?.longitude}                    
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.longitude ? "Campo Obligatorio": errors.longitude}
                    error={errors.longitude}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />                

            </FormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"                                
                loading={isLoading}
                successEvent={successEvent}   
                type="submit"
                as="button"         
            />

            
        </FormWrapper>
    )

}




export default CentroEdit; 