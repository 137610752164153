import React from "react"
import { createOptions, goFetch } from "../../api/cirugiasApi"
import { apiUrl } from "../../shared/url"
import styled from '@emotion/styled/macro';
import CirugiaDataCard from "./molecules/CirugiaDataCard";


const ReporteGeneral = ()=>{

    const [data, setData] = React.useState()
    let stringData

    React.useEffect(()=>{

        const getData = async()=>{

            const options = createOptions('POST',{today:new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())})
            const response =await  goFetch(`${apiUrl}cirugias/dataDashboard`, options)

            console.log(response)
            setData(response)

        }
        
        getData();


    },[])

   console.log(data?.todayCount?.statusCount)
    
    return(
        <Wrapper>
            <CirugiaDataCard when={"Ayer"} values={data?.yesterdayCount?.statusCount}/>
            <CirugiaDataCard when={"Hoy"} values={data?.todayCount?.statusCount}/>            
            <CirugiaDataCard when={"Mañana"} values={data?.tomorrowCount?.statusCount}/>
            <CirugiaDataCard when={"Mes actual"} values={data?.currentMonthCount?.statusCount}/>
            <CirugiaDataCard when={"Mes anterior"} values={data?.lastMonthCount?.statusCount}/>
        </Wrapper>
        
    )
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
`




export default ReporteGeneral