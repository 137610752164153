import React from 'react';
import { FormControl, MenuItem } from '@mui/material';
import CustomInputMuiLabel from '../../Atoms/CustomMuiInputLabel';
import { FormControlRadio, Radio } from '../../Atoms/Radio';
import Select from '../../Atoms/Select';
import TextInput from '../../Atoms/TextInput';






const MetodoPagoRadio = ({
    values,
    setValues
})=>{

    
    const [metodoPago, setMetodoPago] = React.useState(values.pago.tipo)
    console.log(metodoPago)

    console.log(values)

    React.useEffect(()=>{
        //if differnet payment method is selected resets payment method in values object
        if(metodoPago !== values.pago.tipo){
            setValues(prevState => ({...prevState, pago:{...prevState.pago, tipo: metodoPago }}));
        }

        //if privado is selected after first selecting ARS, resets nombre field
        if(metodoPago === "Privado" && values.pago.nombre){
            setValues(prevState => ({...prevState, pago:{...prevState.pago, nombre: "" }}));
        }

        if(metodoPago === "Centro"){
            setValues(prevState => ({...prevState, pago:{...prevState.pago, nombre: values?.centro?.nombre || ""}}));
        }
        
    },[metodoPago, values.pago.tipo, values?.pago?.nombre, values?.centro?.nombre, setValues])

    

    const onChangeRadio = (event)=>{
        //console.log("Radio triggered")
        setMetodoPago(event.target.value)

    }

    const onChangePagos = (event)=>{
        const name = event.target.name
        const value = event.target.value
        const newPago = {...values.pago, [name]:value}
        setValues(prevState => ({...prevState, pago:newPago}))
    }
 
    React.useEffect(()=>{

    },[])

    return(

        <>
             <FormControlRadio 
                    value={metodoPago}
                    onChange={onChangeRadio}
                >
                    <Radio
                        label="ARS"
                        id="ars-selection"
                        name="pago"
                        value="Ars"
                        defaultChecked={metodoPago === "Ars" ? true: false}
                    />
                    <Radio
                        label="Centro"
                        id="centro-selection"
                        name="pago"
                        value="Centro"
                        defaultChecked={metodoPago === "Centro" ? true: false}
                    />
                    <Radio
                        label="Privado"
                        id="privado"
                        name="pago"
                        value="Privado"   
                        defaultChecked={metodoPago === "Privado" ? true: false}                     
                    />                
            </FormControlRadio>

            {
                metodoPago === "Ars" &&
                    <>
                        <FormControl fullWidth>
                            <CustomInputMuiLabel>Ars</CustomInputMuiLabel>
                            <Select
                                labelId="Ars"
                                id="ars"
                                value={values.pago.nombre}                
                                onChange={onChangePagos}
                                name="nombre"
                            >
                                <MenuItem value ="Humano">Humano</MenuItem>
                                <MenuItem value ="Primera ARS Humano">Primera ARS Humano</MenuItem>
                                <MenuItem value ="Mapre">Mapfre</MenuItem>
                                <MenuItem value ="Universal">Universal</MenuItem>
                                <MenuItem value ="Senasa">Senasa</MenuItem>
                                <MenuItem value ="Futuro">Futuro</MenuItem>
                                <MenuItem value ="Idoppril">Idoppril</MenuItem>
                                <MenuItem value ="Meta Salud">Meta Salud</MenuItem>
                                <MenuItem value ="Semma">Semma</MenuItem>
                                <MenuItem value ="GMA">GMA</MenuItem>
                                <MenuItem value ="Simag">Simag</MenuItem>
                                <MenuItem value ="Monumental">Monumental</MenuItem>
                                <MenuItem value ="Banco Central">Banco Central</MenuItem>
                                <MenuItem value ="Ministerio de Salud Publica">Ministerio de Salud Publica</MenuItem>
                                <MenuItem value ="ASEMAP">ASEMAP</MenuItem>
                                <MenuItem value ="Renacer">Renacer</MenuItem>
                                <MenuItem value ="Yunen">Yunen</MenuItem>
                                <MenuItem value ="UASD">UASD</MenuItem>
                                <MenuItem value ="Semunased">Semunased</MenuItem>
                                <MenuItem value ="Colegio Medico Dominicano">Colegio Medico Dominicano</MenuItem>
                            </Select>
                        </FormControl>
                        <TextInput
                            label="Monto a Pagar"
                            name="monto"
                            id="montoAPagar"
                            value={values.pago.monto}
                            onChangeHandler={onChangePagos}
                        />
                    </>
                }

                {
                    metodoPago === "Privado" &&
                    <TextInput
                        label="Diferencia"
                        name="monto"
                        id="diferencia"
                        value={values.pago.monto}
                        onChangeHandler={onChangePagos}
                    />
                }
                {
                    metodoPago === "Centro" &&
                    <TextInput
                        label="Monto a Pagar"
                        name="monto"
                        id="montoAPagar"
                        value={values.pago.monto}
                        onChangeHandler={onChangePagos}
                    /> 
                }

            </>
    )
}

export default MetodoPagoRadio