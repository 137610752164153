import React from "react";
import useSuccessButton from "../../../hooks/useSuccessButton";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import ClientsSearchBar from "../../clients/molecules/ClientsSearchBar";
import ClientsAutocomplete from "../../Forms/CustomAutocomplete/ClientsAutocomplete";




const SearchClients = ({
    close,
    setSelectedClient,
}) => {

    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const [client, setClient] = React.useState([]);
   
    const onChange =(event, option)=>{
           setSelectedClient(option) 
    }


    const onSubmit = (event) =>{
        event.preventDefault();
        close()
    }



    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Seleccionar Cliente</FormTitle>
            <FormBody>
                <ClientsAutocomplete onChange={onChange}/>
                
            </FormBody>
            <FormActionsBasic
                type="submit"
                as='button'
                close={close}                          
                successEvent={successEvent}
                loading={isLoading}
            />

        </FormWrapper>
    )
}



export default SearchClients;