import LabelValue from "../Atoms/LabelValue"
import styled from '@emotion/styled';
import DocumentosPendientes from "./DocumentosPendientes";
import useMediaQuery from "../../hooks/useMediaQuery";
import Facturacion from "./Facturacion";
import ProductTable from "./Molecules/ProductTable";
import DocumentosChips from "./Chips/DocumentosChips";
import usePermissions from "../../hooks/usePermissions";

const CirugiaInfo =({cirugia})=>{
    const isLaptop = useMediaQuery('laptop')
    const permissions = usePermissions()
    const {canViewFacturacionStages} = permissions;


    let wrapperWidth = "100%"

    if(isLaptop && !cirugia.facturacion) wrapperWidth = "50%";
    

    return(
        <>
            <Wrapper style={{'--wrapper-width': wrapperWidth}}>
                <Column>
                    <LabelValue label="Cotizacion: " value={cirugia.cotizacion}/>
                    <LabelValue label="NSS: " value={cirugia.paciente.nss}/>
                    <LabelValue label="Ventas" value={`${cirugia?.ventas?.firstname} ${cirugia?.ventas?.lastname}`}/>            
                </Column>
                <Column>
                    <LabelValue label="Tipo de Pago: " value={cirugia.pago.tipo === 'Ars' ? cirugia.pago.nombre : cirugia.pago.tipo}/>
                    <LabelValue label="Pago RD$: " value={cirugia.pago.monto}/>
                </Column>
                <Column>
                    {/*<DocumentosPendientes documentos={cirugia.documentos} />*/}
                    <DocumentosChips documentos = {{...cirugia.documentos}}/>
                </Column>
                {canViewFacturacionStages && <Facturacion facturacion={cirugia.facturacion}/>}
            </Wrapper>
            
        </>       
    )
}


const Wrapper = styled.div`
    display:flex;
    width:var(--wrapper-width);
    flex-wrap: wrap;
    gap:16px;    
`

const Column = styled.div`
    flex:1;    
`



export default CirugiaInfo;