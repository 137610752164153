import React from 'react';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled/macro';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import useMediaQuery from '../../hooks/useMediaQuery';
import { isEmail, isLength, isCedula, isNSS, isTelefono, validate } from '../../shared/validators';
import { useDispatch } from 'react-redux';
import useCheckForm from '../../hooks/useCheckForm';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useSuccessButton from '../../hooks/useSuccessButton';
import { addUpdatedPaciente } from '../../app/pacientesReducer';
import { useConfiguration } from '../../hooks/useConfiguration';
import useForm from '../../hooks/useForm';

const CrearPaciente = ({
    showTitle=true,
    close,
    paciente,
    edit
})=>{
    const isLaptop = useMediaQuery('laptop');
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();    
    const [checkDatabase, setCheckDatabase] = React.useState(false);    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    
    const configuration = useConfiguration();
    const idRequired = (configuration?.paciente?.id?.required === false) ? false : true;
    const idName = configuration?.paciente?.id?.name || "Cedula";
    const isTelefonoRequired = configuration?.paciente?.telefonoRequired;
    const insuranceIdRequired = (configuration?.paciente.insuranceId?.required === false) ? false : true;
    const insuranceIdName = configuration?.paciente.insuranceId?.name || "NSS";
    const insuranceIdShow = (configuration?.paciente.insuranceId?.show === false) ? false : true;
    const country = configuration?.country || "republica_dominicana";

    const initialState = { 
        firstname: paciente?.firstname || '',
        secondname: paciente?.secondname || '',
        lastname: paciente?.lastname || '',
        secondLastname: paciente?.secondLastname || '',
        cedula: paciente?.cedula || '',
        nss: paciente?.nss || '',
        ars: paciente?.ars || '',
        direccion: paciente?.address || '',
        telefono: paciente?.telefono || '',
        email: paciente?.email || '',
        contacto: {
          firstnameC: paciente?.contacto?.firstnameC || '',
          lastnameC: paciente?.contacto?.lastnameC || '',
          relacionC: paciente?.contacto?.relacionC || '',
          telefonoC: paciente?.contacto?.telefonoC || '',
          emailC: paciente?.contacto?.emailC || '',
        }
      }

    const [values, setValues] = React.useState(initialState)
    

      const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        nss: '',
        ars: '',
        direccion: '',
        telefono: '',
        email: '',
      })

      const {onChangeValuesValidation, onFocusDisableAutocomplete, onBlurValidate} = useForm({setValues, setErrors, errors})
      
      //names on object must be cedula and nss not the names of the fields to be shown
      let requiredFields = ["firstname", "lastname"]
      if(insuranceIdRequired)requiredFields.push('nss')
      if(idRequired)requiredFields.push('cedula')
      if(isTelefonoRequired)requiredFields.push("telefono")
            
      console.log(requiredFields)
      const [formCompleted, errorCount] = useCheckForm(values,errors, requiredFields)
      console.log(formCompleted)

      const textFieldStyle = {
        '& .MuiOutlinedInput-root':{
            borderRadius: '12px'
        }
    }

     
    const onBlurNss = async (event, validators)=>{
        const error = onBlurValidate(event,validators)
        if(!error){
            setCheckDatabase(true)
            const options = createOptions('GET');
            const response = await goFetch(`${apiUrl}pacientes/paciente-exists-nss/${event.target.value}`, options)
            if(response.exists){
                setErrors(prevState=>({...prevState, [event.target.name]:"Nss existe en la base de datos. No se puede repetir."}))
            }else{
                setErrors(prevState=>({...prevState, [event.target.name]:""}))
            }
            setCheckDatabase(false)
        }
    }

    const onBlurCedula = async (event, validators)=>{
        const error = onBlurValidate(event,validators)
        if(!error){
            setCheckDatabase(true)
            const options = createOptions('GET');
            const response = await goFetch(`${apiUrl}pacientes/paciente-exists-cedula/${event.target.value}`, options)            
            if(response.exists){
                setErrors(prevState=>({...prevState, [event.target.name]:"Cedula existe en la base de datos. No se puede repetir."}))
            }else{
                setErrors(prevState=>({...prevState, [event.target.name]:""}))
            }
            setCheckDatabase(false)
        }
    }

    



    const onSubmit = async (event)=>{
        event.preventDefault();
        const options = createOptions('POST', values)
        let response;
        setIsLoading(true)
        let path = edit ? `pacientes/update/${paciente._id}` : 'pacientes';
    
        response = await goFetch(apiUrl+path, options, dispatch );
        responseStateChanges(response, ()=>{
            if(edit){                
                dispatch(addUpdatedPaciente(response.paciente))
            }
        }, ()=>{
            if(!edit){
                setValues(initialState)
                close();
            }; 
        });
             
    }
  

    //console.log(values)
    return (
        <StyledFormWrapper onSubmit={onSubmit}>
            
            <FormTitle isTablet={isTablet}>{!edit ? 'Crear Paciente' : 'Editar Paciente'}</FormTitle>
                   
            <StyledFormBody isTablet={isTablet} style={{marginTop: '8px'}}>
                <TextField 
                    value = {values.firstname} label = "Primer Nombre" name = "firstname"
                    onChange = {(event)=>onChangeValuesValidation(event,[isLength])}
                    variant = "outlined"
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    required={true}
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={(event)=>onBlurValidate(event, [isLength])}
                    spellCheck="false"
                />
                <TextField 
                    value = {values.secondname} label = "Segundo Nombre" name = "secondname"
                    onChange = {(event)=>onChangeValuesValidation(event,[isLength])}
                    variant = "outlined"
                    helperText={errors.secondname}
                    error={errors.secondname}                    
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={onBlurValidate}
                    spellCheck="false"
                />
                <TextField 
                    value = {values.lastname} label = "Primer Apellido" name = "lastname"
                    onChange = {(event)=>onChangeValuesValidation(event,[isLength])}
                    variant = "outlined"
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    required
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={onBlurValidate}
                    spellCheck="false"
                />
                <TextField 
                    value = {values.secondLastname} label = "Segundo Apellido" name = "secondLastname"
                    onChange = {(event)=>onChangeValuesValidation(event,[isLength])}
                    variant = "outlined"
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={onBlurValidate}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.cedula} label = {`${idName}`} name = "cedula"
                    onChange = {(event)=>onChangeValuesValidation(event,[(e)=>isCedula(e,country)],true)}
                    variant = "outlined"
                    helperText={!errors.cedula ? "Campo Obligatorio": errors.cedula}
                    error={errors.cedula}
                    required={true}
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={(event)=>onBlurCedula(event, [(e)=>isCedula(e,country)])}
                    spellCheck="false"
                />
                { insuranceIdShow &&
                    <TextField 
                        value = {values.nss} label={`${insuranceIdName || "NSS"}`} name = "nss"
                        onChange = {(event)=>onChangeValuesValidation(event,[isNSS], true)}
                        variant = "outlined"
                        helperText={!errors.nss ? insuranceIdRequired && "Campo Obligatorio" : errors.nss}
                        error={errors.nss}
                        required={insuranceIdRequired}
                        sx={textFieldStyle}
                        onFocus={onFocusDisableAutocomplete}
                        onBlur={(event)=>onBlurNss(event, [isNSS])}
                        spellCheck="false"
                    />
                }
                 <TextField 
                    value = {values.telefono} label = "Telefono" name = "telefono"
                    onChange = {(event)=>onChangeValuesValidation(event,[(event)=>isTelefono(event,country)], true)}
                    variant = "outlined"
                    helperText={!errors.telefono ? isTelefonoRequired && "Campo Obligatorio": errors.telefono}
                    error={errors.telefono}
                    required={isTelefonoRequired}
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={(event)=>onBlurValidate(event, [(event)=>isTelefono(event,country)])}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.email} label = "Email" name = "email"
                    onChange = {(event)=>onChangeValuesValidation(event,[isEmail], true)}
                    variant = "outlined"
                    helperText={errors.email}
                    error={errors.email}                    
                    sx={textFieldStyle}
                    onFocus={onFocusDisableAutocomplete}
                    onBlur={(event)=>onBlurValidate(event, [isEmail])}
                    spellCheck="false"
                />
                
            </StyledFormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={!formCompleted || errorCount>0 || checkDatabase}
            />
            
        </StyledFormWrapper>
    )

}

const StyledFormWrapper = styled(FormWrapper)`
    
    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }
`


const StyledFormBody = styled(FormBody)`
    margin-bottom: 85px;
    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 20px;
    }

`
export default CrearPaciente