
import React from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import useHasPermissions from '../hooks/useHasPermissions';


const PermissionsContext = React.createContext({
    canAgendar: false
})


export const PermissionsContextProvider = ({
    children
})=>{
    const user =  useCurrentUser()
    
    const canCrearCirugias = useHasPermissions("can_crear_cirugias", user);
    const canAgendar = useHasPermissions("can_agendar", user);
    const canAceptarCirugias = useHasPermissions("can_aceptar_cirugias", user);
    const canEditarCirugias = useHasPermissions("can_editar_cirugias", user);
    const canSuspenderCirugias = useHasPermissions("can_suspender_cirugias", user);
    const canConcluirCirugias = useHasPermissions("can_concluir_cirugias", user);
    const canEditDocumentacion = useHasPermissions("can_edit_documentacion", user);
    const canEditReporte = useHasPermissions("can_edit_reporte", user);
    const canResetReporte = useHasPermissions("can_reset_reporte", user);
    const canSeeAllCirugias = useHasPermissions("can_see_all_cirugias", user);
    const canUpdateOperacionesStages = useHasPermissions("can_update_operaciones_stages", user);
    const canUpdateFacturacionStages = useHasPermissions("can_update_facturacion_stages", user);
    const canViewFacturacionStages = useHasPermissions("can_view_facturacion_stages", user);
    const canAutorizarCirugias = useHasPermissions("can_autorizar_cirugias", user);
    const canAsignarEc = useHasPermissions("can_asignar_ec", user);
    const canSeeCotizacionAndAutorizacion = useHasPermissions("can_see_cotizacion_and_autorizacion", user);
    const canCrearEditarInvitarDoctores = useHasPermissions("can_crear_editar_invitar_doctores", user);
    const canCrearProductos = useHasPermissions("can_crear_productos", user);
    const canSeeAllReportes = useHasPermissions("can_see_all_reportes", user);
    const canCrearPacientes = useHasPermissions('can_crear_pacientes', user)
    const isMaster = useHasPermissions("isMaster", user);
    const canInvitarUsuarios = useHasPermissions("can_invite_users", user);
    const canEditCentroInformation = useHasPermissions("can_edit_centro_information", user);
    const canViewDashboard = useHasPermissions("can_view_dashboard", user);
    const canReceiveCirugiaReporteEmails = useHasPermissions("can_receive_cirugia_reporte_emails", user);
    const canCrearCotizaciones = useHasPermissions('can_crear_cotizaciones', user);
    const canEditarCotizaciones = useHasPermissions('can_editar_cotizaciones', user);
    const canEditarProductosAndVentasCotizaciones = useHasPermissions('can_editar_productos_and_ventas_cotizaciones', user);
    const canVerCotizaciones = useHasPermissions('can_ver_cotizaciones', user);
    const canVerTodasCotizaciones = useHasPermissions('can_ver_todas_cotizaciones', user);
    const canCrearCentros = useHasPermissions('can_crear_centros', user);
    const canAccessBilling = useHasPermissions("can_access_billing", user);     
    const canCancelarCirugias = useHasPermissions("can_cancelar_cirugias", user);  
    const canReactivarCirugias = useHasPermissions("can_reactivar_cirugias", user);
    const canViewOperacionesMenu = useHasPermissions("can_view_operaciones_menu", user);

    
    return(
        <PermissionsContext.Provider value={{
            canCrearCirugias,
            canAgendar,
            canAceptarCirugias,
            canEditarCirugias,
            canSuspenderCirugias,
            canConcluirCirugias,
            canEditDocumentacion,
            canEditReporte,
            canResetReporte,
            canSeeAllCirugias,
            canUpdateOperacionesStages,
            canUpdateFacturacionStages,
            canViewFacturacionStages,
            canAutorizarCirugias,
            canAsignarEc,
            canSeeCotizacionAndAutorizacion,
            canCrearEditarInvitarDoctores,
            canCrearProductos,
            canSeeAllReportes,
            canCrearPacientes,
            isMaster,
            canInvitarUsuarios,
            canEditCentroInformation,
            canViewDashboard,
            canReceiveCirugiaReporteEmails,
            canCrearCotizaciones,
            canEditarCotizaciones,
            canEditarProductosAndVentasCotizaciones,
            canVerCotizaciones,
            canVerTodasCotizaciones,
            canCrearCentros,
            canAccessBilling,
            canCancelarCirugias,
            canReactivarCirugias,
            canViewOperacionesMenu
        }}>
             {children}   
        </PermissionsContext.Provider>
    )
}


export default PermissionsContext