import styled from '@emotion/styled';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Atoms/Button';
import IconButton from '../../Atoms/IconButton';
import ChevronLeft from '../../Icons/ChevronLeft'
import ChevronRight from '../../Icons/ChevronRight'
import TextInput from '../../Atoms/TextInput';
import useCirugia, { useCirugiaWithLoading } from '../../../hooks/useCirugia';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import { updateCirugia } from '../../../app/thunkActions/cirugiasActions';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../app/messagesReducer';


const Facturacion = ({
    cirugiaId,
    close
})=>{
    const [activeStep, setActiveStep] = React.useState(0);
    const tablet= useMediaQuery('tablet');
    const dispatch = useDispatch();
    const [cirugia, isLoading] = useCirugiaWithLoading(cirugiaId);
    
    
  

    const steps = React.useMemo(()=>[
        "Transf.",
        "Entrega",
        "Revision",
        "Cambio",
        "Facturada"
    ],[])

    
    const [formValues, setFormValues] = React.useState({
        transferencia: "",
        entrega:"",
        factura:""
      });
    
    const accionMaker = useAccionMaker()

    React.useEffect(()=>{
    setFormValues({
        transferencia: cirugia?.facturacion?.transferencia || "",
        entrega: cirugia?.facturacion?.entrega || "",
        factura:cirugia?.facturacion?.factura || "",
    })
    },[cirugia])

    React.useEffect(()=>{
        if(formValues.transferencia && !formValues.entrega && !formValues.factura){          
            setActiveStep(1)
        }else{
          //  setActiveStep(0)
        }

        if(formValues.entrega && !formValues.factura) setActiveStep(2) 
        //if(formValues.entrega && formValues.factura) setActiveStep(5)
        if(formValues.factura){
            setActiveStep(5);
        }else if(!formValues.factura && activeStep === 5){
            setActiveStep(2);
        }
    },[formValues])

    React.useEffect(()=>{
        steps.forEach((step, index)=>{
            if(cirugia?.info?.statusFacturacion === step){                
                setActiveStep(index)
            }
        })
      },[cirugia, steps])

    const onChangeValues = (event) => {
        const {name, value} = event.target
        setFormValues(prevState => ({...prevState,[name]:value}));
    }

    const handleNext = ()=>{
        if(activeStep > steps.length-1)return
        // do not go to next step until Inovice number is entered
        if(activeStep === 3 && !formValues.factura){
            dispatch(errorMessage("Debes ingresar el numero de factura."))
            return
        } 
        setActiveStep(prevState => prevState + 1)
    }

    const handleBack = ()=>{
        if(activeStep===0)return
        if(activeStep === 5 && formValues.factura) {
            dispatch(errorMessage("Para cambiar de status debes borrar el numero de factura."))
            return
        }
        setActiveStep(prevState => prevState - 1)
    }


    const onSubmit = (status)=>{

        const accion = accionMaker(`Cambio status facturacion a ${steps[activeStep-1]}`)
        let statusFacturacion;
        
        if(activeStep===5) statusFacturacion = "Facturada"
        else statusFacturacion = status

        let facturacionInfo={
          cirugiaId: cirugiaId,
          statusFacturacion: statusFacturacion,
          transferencia: formValues.transferencia,
          entrega: formValues.entrega,
          factura: formValues.factura,
          accion: accion
        }
        console.log(facturacionInfo)
        dispatch(updateCirugia('facturacion',facturacionInfo));
        
      }

      console.log(activeStep)

    return(
        <Wrapper>
            <Title>Facturacion</Title>
            <StepperWrapper>            
                <Stepper activeStep={activeStep} alternativeLabel={tablet?true:false}  orientation={tablet? "horizontal" : "vertical"}>
                    {
                        steps.map((step, index)=>(
                            <Step key={`${step} ${index}`}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </StepperWrapper>
            <FormWrapper>
                <TextInput label="Transferencia" id="transferencia" name="transferencia" value={formValues.transferencia} onChange={onChangeValues}/>
                <TextInput label="Entrega" id="entrega" name="entrega" value={formValues.entrega} onChange={onChangeValues}/>
                <TextInput label="Factura" id="factura" name="factura" value={formValues.factura} onChange={onChangeValues}/>
            </FormWrapper>
            <Actions>
                    <Button error onClick={()=>close()}>Volver</Button>
                    <Controls>
                        <IconButton onClick={handleBack}>
                            <ChevronLeft/>
                        </IconButton>
                        <IconButton onClick={handleNext}>
                            <ChevronRight />
                        </IconButton>
                    </Controls>
                    <Button 
                        success
                        onClick={()=>onSubmit(steps[activeStep])}
                        loading={isLoading}
                    >Guardar</Button>
            </Actions>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction:column;   
    width:100%;
    padding: 24px 12px;
    

    @media ${p=>p.theme.media.tablet}{
        padding: 24px 36px 12px ;
    }
`

const StepperWrapper = styled.div`
    flex:2;
    width:100%;
    display:flex;
    justify-content: center;
    
`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    margin-bottom: 36px;
    
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0px;
`

const Actions = styled.div`
    flex:1;
    max-height: 65px;
    display:flex;
    justify-content: space-between;
    align-items: center;
`

const Controls = styled.div`
    display:flex;
`

export default Facturacion