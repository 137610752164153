import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import { CardBody, CardWrapper as Card } from "../../../Atoms/Card";
import Loading from '../../../Atoms/Loading';


const UsersListLoading = ({
    doctors
})=>{
    const theme = useTheme();
    console.log(theme.media.tablet)

    const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]

    return (
        <Wrapper>
            
            {
                list?.map((item,index)=>{

                    return ( 
                        
                            <Card style={{margin:0, paddingBottom: "8px"}} key={`${item} ${index}`}>
                                <StyledCardHeader>
                                    <LoadingAvatar />
                                    <UserDescription >
                                        <LoadingTitle/>
                                        <LoadingText/>
                                    </UserDescription>                                
                                </StyledCardHeader>
                                <CardBody style={{flexDirection: "row"}}>
                                    <Container>
                                        <InfoContainer>
                                            <LoadingSmallText/>
                                        </InfoContainer>                                                                 
                                    </Container>
                                    
                                </CardBody>
                            </Card>
                        
                    )
         })
        }
                
</Wrapper>
    )
}


const LoadingAvatar = styled(Loading)`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

const LoadingTitle = styled(Loading)`
    width: 175px;
    height:25px;
    margin-bottom: 8px;
    border-radius: 8px;
`

const LoadingText =styled(Loading)`
    width: 125px;
    height:20px;
    border-radius: 8px;
`

const LoadingSmallText =styled(Loading)`
    width: 50px;
    height: 15px;
    border-radius: 8px;
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    width: 100%;       
    gap: 8px;
    
    @media ${p=>p.theme.media.tablet}{
        width: 75%;
        margin-right: auto;
        margin-left: auto;
    }
    
`



const StyledCardHeader = styled.div`
    display:flex;
    gap: 12px;
    
    
`

const UserDescription = styled.div`
    flex-direction: column;
    justify-content: center;
    min-height: 55px;
    margin-bottom: 16px;
`




const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    
`

const Container = styled.div`
    display:flex;
    justify-content: space-between;
`


export default UsersListLoading;