import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody, FormActionsMobile, FormActions} from '../Atoms/Forms';
import { TextField, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCirugia from '../../hooks/useCirugia';
import ClasificacionSelect from './CustomAutocomplete/ClasificacionSelect';
import styled from '@emotion/styled';
import Button from '../Atoms/Button';
import EnviarButtonGroup from '../cirugias/Molecules/EnviarButtonGroup';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { addUpdatedCirugia } from '../../app/cirugiasReducer';
import { errorMessage, successMessage } from '../../app/messagesReducer';
import { apiUrl } from '../../shared/url';
import useAudioRecording from '../../hooks/useAudioRecordings';
import useSuccessButton from '../../hooks/useSuccessButton';
import Mic from '../Icons/MIc';
import ScaleLoader from 'react-spinners/ScaleLoader';
import BeatLoader from 'react-spinners/BeatLoader';

const EditarReporte = ({
    cirugiaId,
    close
})=>{

    const isTablet = useMediaQuery('tablet');
    console.log('isTablet', isTablet)
    const theme = useTheme();
    const dispatch = useDispatch();
    const cirugia = useCirugia(cirugiaId)
    const { startRecording, stopRecording, sendAudioToBackend } = useAudioRecording(cirugiaId);
    const { isLoading: isLoadingGuardar, setIsLoading: setIsLoadingGuardar, successEvent: successEventGuardar, setSuccessEvent: setSuccessEventGuardar, responseStateChanges: responseStateChangesGuardar } = useSuccessButton();
    const { isLoading: isLoadingEnviar, setIsLoading: setIsLoadingEnviar, successEvent: successEventEnviar, setSuccessEvent: setSuccessEventEnviar, responseStateChanges: responseStateChangesEnviar } = useSuccessButton();
    
    const [classificacion, setClassificacion] = React.useState(cirugia.reporte?.classificacion || "")
    const [descripcion, setDescripcion] = React.useState(cirugia.reporte?.descripcion || "")
    const [comentarios, setComentarios] = React.useState(cirugia.reporte?.comentarios || "")
    const [disabled, setDisabled] = React.useState(true);
    const [previousReporteGuardado, setPreviousReporteGuardado] = React.useState(cirugia.reporte.classificacion && cirugia.reporte.descripcion && cirugia.reporte.comentarios)
    const [recordingState, setRecordingState] = useState({
        isRecordingDescripcion: false,
        isRecordingComentarios: false,
        isTranscribingDescripcion: false,
        isTranscribingComentarios: false,
        recordingTime: 0,
    });

    const isRecording = useMemo(() => 
        recordingState.isRecordingDescripcion || recordingState.isRecordingComentarios,
        [recordingState.isRecordingDescripcion, recordingState.isRecordingComentarios]
    );

    useEffect(() => {
        let interval;
        if (isRecording) {
            interval = setInterval(() => {
                setRecordingState(prev => {
                    if (prev.recordingTime >= 59) {
                        clearInterval(interval);
                        handleRecording(prev.isRecordingDescripcion ? 'descripcion' : 'comentarios');
                        return { ...prev, recordingTime: 0 };
                    }
                    return { ...prev, recordingTime: prev.recordingTime + 1 };
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isRecording]);

    const handleRecording = useCallback(async (field) => {
        if (recordingState[`isRecording${field.charAt(0).toUpperCase() + field.slice(1)}`]) {
            // Stopping recording
            const audioBlob = await stopRecording();
            if (audioBlob) {
                setRecordingState(prev => ({ 
                    ...prev, 
                    [`isRecording${field.charAt(0).toUpperCase() + field.slice(1)}`]: false,
                    [`isTranscribing${field.charAt(0).toUpperCase() + field.slice(1)}`]: true,
                    recordingTime: 0 // Reset the timer when stopping recording
                }));
                const transcription = await sendAudioToBackend(audioBlob, field);
                if (transcription) {
                    if (field === 'descripcion') {
                        setDescripcion(prevDesc => prevDesc + ' ' + transcription);
                    } else {
                        setComentarios(prevComm => prevComm + ' ' + transcription);
                    }
                }
                setRecordingState(prev => ({ 
                    ...prev, 
                    [`isTranscribing${field.charAt(0).toUpperCase() + field.slice(1)}`]: false 
                }));
            }
        } else {
            // Starting recording
            await startRecording();
            setRecordingState(prev => ({
                ...prev,
                [`isRecording${field.charAt(0).toUpperCase() + field.slice(1)}`]: true,
                recordingTime: 0 // Reset the timer when starting recording
            }));
        }
    }, [stopRecording, sendAudioToBackend, startRecording]);

    // Move these useEffects outside the component or memoize their dependencies
    React.useEffect(() => {
        const hasAllFields = classificacion && descripcion && comentarios;
        setDisabled(!previousReporteGuardado || !hasAllFields);
    }, [classificacion, descripcion, comentarios, previousReporteGuardado]);

    React.useEffect(() => {
        const hasAllFields = cirugia.reporte.classificacion && cirugia.reporte.descripcion && cirugia.reporte.comentarios;
        setPreviousReporteGuardado(hasAllFields);
    }, [cirugia.reporte]);

    React.useEffect(() => {
        const hasChanges = 
            descripcion !== cirugia.reporte.descripcion || 
            comentarios !== cirugia.reporte.comentarios || 
            classificacion !== cirugia.reporte.classificacion;
        if (hasChanges) {
            setPreviousReporteGuardado(false);
        }
    }, [cirugia.reporte, descripcion, comentarios, classificacion]);

    const accionMaker = useAccionMaker()
  
    const onSubmit =  async(event) =>{
        const accion = accionMaker(`Reporte Guardado.`)
        
        let infoReporte = {
            cirugiaId:cirugia._id,
            classificacion: classificacion,
            descripcion: descripcion,
            comentarios: comentarios,
            accion: accion
          }
        
        const options = createOptions('PUT', infoReporte)   
        try{
            setIsLoadingGuardar(true);
            const response = await goFetch(`${apiUrl}cirugias/update-reporte/${infoReporte.cirugiaId}`, options, dispatch)        
            responseStateChangesGuardar(response, 
                () => {
                    dispatch(addUpdatedCirugia(response));
                },
                () => {}
            );
        }catch(error){
            console.log('error en guardar reporte')
            console.log(error);
            dispatch(errorMessage("Problema de servidor, por favor intente mas tarde."))
        }
      }

    const onEnviar = async(event)=>{
        const accion = accionMaker(`Reporte enviado.`)

        let enviarReporteInfo= {
            cirugiaId: cirugia._id,
            enviadoReporte: true,
            enviadoReporteFlag:true, //used server side for conditional
            accion:accion
          }
                    
        const options = createOptions('PUT', enviarReporteInfo)   
        try{
            setIsLoadingEnviar(true);
            const response = await goFetch(`${apiUrl}cirugias/update-reporte/${enviarReporteInfo.cirugiaId}`, options, dispatch)        
            responseStateChangesEnviar(response, 
                () => {
                    dispatch(addUpdatedCirugia(response));
                },
                () => {
                    close();
                }
            );
        }catch(error){
            console.log(error);
            dispatch(errorMessage("Problema de servidor, por favor intente mas tarde."))
        }
    }
    
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return(
        <FormWrapper>
            <FormTitle isTablet={isTablet}>Reporte</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <Wrapper>
                <ClasificacionSelect 
                    label={'Clasificacion'}
                    id="classificacion" 
                    name="classificacion" 
                    value={classificacion} 
                    onChange={(e)=>setClassificacion(e.target.value)}
                    required // Add this to indicate it's a required field
                />
                <TextField
                    label="Descripcion de la Cirugia"
                    multiline
                    variant= 'outlined'
                    rows={7}
                    value={descripcion}
                    onChange={(e)=>setDescripcion(e.target.value)}
                />
                <Button 
                    error={recordingState.isRecordingDescripcion} 
                    success={!recordingState.isRecordingDescripcion} 
                    preIcon={<Mic color={'white'} size={20} />}  
                    onClick={() => handleRecording('descripcion')}
                    disabled={recordingState.isTranscribingDescripcion}
                >
                    {recordingState.isRecordingDescripcion ? (
                        <ScaleLoaderWrapper>
                            <ScaleLoader color="white" height={15} width={2} radius={2} margin={2} />
                            Parar Grabacion ({formatTime(recordingState.recordingTime)})
                        </ScaleLoaderWrapper>
                    ) : recordingState.isTranscribingDescripcion ? (
                        <BeatLoaderWrapper>
                            <BeatLoader color="white" size={8} />
                            Transcribiendo...
                        </BeatLoaderWrapper>
                    ) : (
                        'Comienza Grabacion Descripcion'
                    )}
                </Button>
                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={7}
                    value={comentarios}
                    onChange={(e)=>setComentarios(e.target.value)}
                />
                <Button 
                    error={recordingState.isRecordingComentarios} 
                    success={!recordingState.isRecordingComentarios} 
                    preIcon={<Mic color={recordingState.isTranscribingComentarios ? theme.palette.grey[800] : 'white'} size={20} />}  
                    onClick={() => handleRecording('comentarios')}
                    disabled={recordingState.isTranscribingComentarios}
                >
                    {recordingState.isRecordingComentarios ? (
                        <ScaleLoaderWrapper>
                            <ScaleLoader color="white" height={15} width={2} radius={2} margin={2} />
                            Parar Grabacion ({formatTime(recordingState.recordingTime)})
                        </ScaleLoaderWrapper>
                    ) : recordingState.isTranscribingComentarios ? (
                        <BeatLoaderWrapper>
                            <BeatLoader color={theme.palette.grey[800]} size={8} />
                            Transcribiendo...
                        </BeatLoaderWrapper>
                    ) : (
                        'Comienza Grabacion Comentarios'
                    )} 
                </Button>
                </Wrapper>
                
                
                
            </FormBody>
            
            <>
           { !isTablet ?
            <FormActionsMobile>
                <Button outline error onClick={()=>close()}>{"Cancelar"}</Button> 
                <EnviarButtonGroup 
                    disabled={disabled} // Update this line
                    onClickGuardar={onSubmit} 
                    onClickEnviar={onEnviar}
                    successEventGuardar={successEventGuardar}
                    loadingGuardar={isLoadingGuardar}
                    successEventEnviar={successEventEnviar}
                    loadingEnviar={isLoadingEnviar}                    
                ></EnviarButtonGroup>
            </FormActionsMobile>
            :
            <FormActions>
                <Button outline error onClick={()=>close()}>{"Cancelar"}</Button> 
                <EnviarButtonGroup 
                    disabled={disabled} // Update this line
                    onClickGuardar={onSubmit} 
                    onClickEnviar={onEnviar}
                    successEventGuardar={successEventGuardar}
                    loadingGuardar={isLoadingGuardar}
                    successEventEnviar={successEventEnviar}
                    loadingEnviar={isLoadingEnviar}                    
                ></EnviarButtonGroup>
            </FormActions>
            }
        </>
            
        </FormWrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width:50vw;

`
const ScaleLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;
`

const BeatLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
`

export default EditarReporte;