import styled from '@emotion/styled/macro';

import { CardBody, CardTitle } from '../../Atoms/Card';

import useMediaQuery from '../../../hooks/useMediaQuery';



//import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card"






const ProductosTableRegla = ({
    productos
})=>{

    let containerWidth = "100%"
    const isLaptop = useMediaQuery('laptop');
    if(isLaptop) containerWidth =  "100%";
    
    console.log(productos)
    return (
        <Wrapper>
        <CardTitle>Productos</CardTitle>
        <CardBody>                
        <TableContainer style={{'--container-width': containerWidth}}>
                    <Table >
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell>Codigo</TableHeadCell>
                                <TableHeadCell>Descripcion</TableHeadCell>                                
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {productos.map((producto, index)=>(            
                                    <TableRow key={`${producto._id}${index}`}>
                                        <TableCell>{producto.codigo}</TableCell>
                                        <TableCell>{producto.descripcion}</TableCell>                                                                                                                        
                                    </TableRow>
                            ))}                            
                        </TableBody>
                    </Table>
            </TableContainer>                
        </CardBody>

    </Wrapper>
        
    )
}


const Wrapper = styled.div`
    padding: 16px;
    
`


const TableContainer = styled.div`
    
    border-radius: 4px;
    width: var(--container-width);
    overflow: auto;
`

const Table = styled.table`
    border-collapse: collapse;
    width:100%
`

const TableHead = styled.thead`
`

const TableHeadRow = styled.tr`
 border-top: 1px solid ${p=>p.theme.palette.grey[400]};
    
`
const TableHeadCell = styled.th`
    padding: 16px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    font-size: 0.875rem;
    text-align: left;
    white-space: wrap;
    
`

const TableBody = styled.tbody`
`

const TableRow = styled.tr`
    
`

const TableCell = styled.td`
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    padding: 16px;
    font-size: 0.875rem;


`



export default ProductosTableRegla