


const calcMinutesCheckIn = (checkIn, cirugiaTime)=>{

  console.log(checkIn);
  console.log(cirugiaTime);

  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const WEEK = DAY * 7
  const MONTH = DAY * 30
  const YEAR = DAY * 365

  let checkInTime = new Date(checkIn);
  let cirugiaTimeDate = new Date(cirugiaTime)

  let timeDifMili =  cirugiaTimeDate - checkInTime

  let timeDifSeconds = Math.round(timeDifMili/1000)
  let timeDifMinutes = Math.round(timeDifSeconds/60)
  let timeDifHours = Math.round(timeDifMinutes/60)
  let timeAgoDays = Math.round(timeDifHours/24)
  //let timeAgoMonths = Math.round(timeAgoDays/30)
  //let timeAgoYears = Math.round(timeAgoMonths/12)

  console.log(timeDifMinutes)
  console.log(timeDifSeconds)
  console.log(MINUTE * -1);

  if(timeDifSeconds < MINUTE &&  timeDifSeconds > 0){
    return `llego ${timeDifSeconds} segundos antes de la hora agendada de la cirugia`;
  }else if(timeDifSeconds < HOUR &&  timeDifSeconds > 0){
    if(timeDifMinutes < 2){
      return `llego ${timeDifMinutes} minuto antes de la hora agendada de la cirugia`
    }else{
      return `llego ${timeDifMinutes} minutos antes de la hora agendada de la cirugia`
    }
  }else if(timeDifSeconds < DAY &&  timeDifSeconds > 0){
      if(timeDifHours<2){
          return `llego ${timeDifHours} hora antes de la hora agendada de la cirugia`
      }else{
        return `llego ${timeDifHours} horas antes de la hora agendada de la cirugia`
    }
  }else if(timeDifSeconds < 0 && timeDifSeconds > (MINUTE * -1)){ // recordar que es negativa, mas grande quiere decir menos negativo
    console.log("landed here 1")
    return `llego ${-1 * timeDifSeconds} segundos despues de la hora agendada de la cirugia`;
  }
  else if(timeDifSeconds <0 && timeDifSeconds > (HOUR * -1)){
    console.log("landed here 2")
    if(timeDifMinutes > -2){
      return `llego ${-1 * timeDifMinutes} minuto despues de la hora agendada de la cirugia`
    }else{
      return `llego ${-1 * timeDifMinutes} minutos despues de la hora agendada de la cirugia`
    }
  }else if(timeDifSeconds < 0 && timeDifSeconds > (DAY * -1)){
    console.log("landed here 3")
    if(timeDifHours > -2){
        return `llego ${-1 * timeDifHours} hora despues de la hora agendada de la cirugia`
    }else{
      return `llego ${-1 * timeDifHours} horas despues de la hora agendada de la cirugia`
    }
  }else if (timeDifSeconds < 0 && timeDifSeconds > (WEEK * -1)){
    return "Check-In mas de 24 horas depues del comienzo de la cirugia"
  }
}

export default calcMinutesCheckIn;
