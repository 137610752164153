import styled from '@emotion/styled';
import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import { CardTitle, CardWrapper } from "../../Atoms/Card"
import Modal from '../../Atoms/Modal';
import EditProductoCotizacion from '../EditProductoCotizacion';
import ProductosTableCotizacion from "./ProductosTableCotizacion"
import TotalsCard from "./TotalsCard"


const CotizacionInformationSummary = ({ 
    selectedClient,
    pacienteInfo,
    doctor,
    centro,
    selectedProductos,
    setFormType,
    setOpenModal,
    setProductoId,
    setSelectedProductoEdit,
    totalCotizacion,    
    productoId,
    selectedProductoEdit,
    setSelectedProductos,
    setTotalCotizacion,
    currency,
    nota
}) => {

    //this component uses two setFormTypes, depending on the device size it will open a modal or a drawer
       
    const isPhone = useMediaQuery('phone');
    const {openModal, setOpenModal:setOpenModalSecond, inProp, setInProp, close} = useModalAndDrawer();
    const [formTypeSecond, setFormTypeSecond] = React.useState("");
    console.log(formTypeSecond)
    const smallBottomSwipeDrawers = ['editProducto']
    const drawerOffsetPercentInputArray = []
    return(
        <>
            <CardWrapper>
                <CardTitle>Cliente</CardTitle>
                <InfoAdicionalContainer>
                    <InfoAdicionalRow>
                        <TotalLabel>Nombre:</TotalLabel>
                        <Value>{selectedClient?.name}</Value>                            
                    </InfoAdicionalRow>
                    {selectedClient?.rnc &&
                        <InfoAdicionalRow>
                            <TotalLabel>RNC:</TotalLabel>
                            <Value>{selectedClient?.rnc}</Value>
                        </InfoAdicionalRow>
                    }
                    {selectedClient?.nss &&
                        <InfoAdicionalRow>
                        <TotalLabel>NSS:</TotalLabel>
                        <Value>{selectedClient?.nss}</Value>
                    </InfoAdicionalRow>
                    }
                    {selectedClient?.cedula &&
                        <InfoAdicionalRow>
                        <TotalLabel>Cedula:</TotalLabel>
                        <Value>{selectedClient?.cedula}</Value>
                    </InfoAdicionalRow>
                    }
                    {selectedClient?.address   &&            
                        <InfoAdicionalRow>
                            <TotalLabel>Direccion:</TotalLabel>
                            <Value>{selectedClient?.address}</Value>
                        </InfoAdicionalRow>
                    }
                    {selectedClient?.phone &&
                        <InfoAdicionalRow>
                            <TotalLabel>Telefono:</TotalLabel>
                            <Value>{selectedClient?.phone}</Value>
                        </InfoAdicionalRow>
                    }
                    {selectedClient?.email &&
                        <InfoAdicionalRow>
                            <TotalLabel>Email:</TotalLabel>
                            <Value>{selectedClient?.email}</Value>
                        </InfoAdicionalRow>
                    }                                                
                </InfoAdicionalContainer>                    
            </CardWrapper>  
            <CardWrapper>
                <CardTitle>Informacion Cirugia</CardTitle>
                <InfoAdicionalContainer>    
                    <InfoAdicionalRow>
                        <TotalLabel>Nombre paciente:</TotalLabel>
                        <Value>{`${pacienteInfo?.firstname || ""} ${pacienteInfo?.secondname || ""} ${pacienteInfo?.lastname || ""} ${pacienteInfo?.secondLastname || ""}`}</Value>                            
                    </InfoAdicionalRow>
                    <InfoAdicionalRow>
                        <TotalLabel>Cedula paciente:</TotalLabel>
                        <Value>{pacienteInfo?.cedula}</Value>
                    </InfoAdicionalRow>
                    <InfoAdicionalRow>
                        <TotalLabel>NSS paciente:</TotalLabel>
                        <Value>{pacienteInfo?.nss}</Value>
                    </InfoAdicionalRow>
                    <InfoAdicionalRow>
                        <TotalLabel>Doctor:</TotalLabel>
                        <Value>{`${doctor?.firstname || ""} ${doctor?.lastname || ""}`}</Value>
                    </InfoAdicionalRow>
                    <InfoAdicionalRow>
                        <TotalLabel>Centro:</TotalLabel>
                        <Value>{`${centro?.nombre || ""}`}</Value>
                    </InfoAdicionalRow>
                    <InfoAdicionalRow>
                        <TotalLabel>Nota:</TotalLabel>
                        <Value>{`${nota || ""}`}</Value>
                    </InfoAdicionalRow>
                </InfoAdicionalContainer>                        
            </CardWrapper>                 
            <ProductosTableCotizacion
                productos={selectedProductos}
                setFormType={isPhone ? setFormTypeSecond : setFormType}
                setOpenModal={isPhone ? setOpenModalSecond : setOpenModal}
                setProductoId={setProductoId}
                setSelectedProductoEdit={setSelectedProductoEdit}
            />
        <TotalsCard totalCotizacion={totalCotizacion}/>
        <Modal
                openModal={openModal}
                setOpenModal={setOpenModalSecond} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formTypeSecond)}  
                drawerOffsetPercentInput={drawerOffsetPercentInputArray.includes(formTypeSecond) && "14%"}      
                  
            >
                
                {formTypeSecond === "editProducto" && <EditProductoCotizacion  
                                                    close={close}
                                                    productoId={productoId} 
                                                    selectedProductoEdit={selectedProductoEdit} 
                                                    selectedProductos={selectedProductos} 
                                                    setSelectedProductos={setSelectedProductos}
                                                    setTotalCotizacion={setTotalCotizacion}
                                                    currency={currency}
                                                />}                                          

            </Modal>             


   </>
    )   

}



const TotalLabel = styled.span`
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    font-size: 0.8rem;
`




const InfoAdicionalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
    flex:1;
`


const InfoAdicionalRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
`


const Value = styled.span`
    font-size: 0.8rem;    
    
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    flex:2;
`

export default CotizacionInformationSummary