


const getFullName = (data)=>{
    console.log(data)
    let firstAndSecondname;
    let lastname;
    if(data?.secondname){
        firstAndSecondname = `${data.firstname} ${data.secondname}`
    }else{
        firstAndSecondname = data?.firstname
    }
    
    if(data?.secondLastname){
        lastname = `${data.lastname} ${data.secondLastname}`
    }else{
        lastname = data?.lastname
    }

    return `${firstAndSecondname} ${lastname}`

}


export default getFullName