
import { utcToZonedTime } from 'date-fns-tz'
import calcTimeAgo from '../shared/calcTimeAgo'
import { useCurrentUser } from './useCurrentUser'
import { useConfiguration } from './useConfiguration'






const useDateAndTimeFormatter = () => {

    const user = useCurrentUser()
    const configuration = useConfiguration()
    let timeZone = 'America/Santo_Domingo'

    if(configuration?.country === 'mexico'){
        timeZone = user.timeZone
    }

    function formatTime(time){
  
        if(!time) return '[hora pendiente]'
        // included this function in order to always get the DR time zone regardless of where the client is located. 
        let newTime = utcToZonedTime(time, timeZone)
      
        //let newTime = new Date(time);
          newTime = newTime.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
          return newTime
      }
      
      
  
  const dateFormatDR = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${day}/${month}/${year}`;    
}

const dateAndTimeFormatDR = (date) => {
    const d = new Date(date);
    const options = {
      timeZone: timeZone,
    };
    const formatter = new Intl.DateTimeFormat('en-US', {
      ...options,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  
    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },      
    ] = formatter.formatToParts(d);

    const formattedTime = formatTime(date)
    const timeAgo = calcTimeAgo(date)

    return `${day}/${month}/${year}, ${formattedTime}, ${timeAgo} `;
  };

  const dateFormat = (date) => {
    if (!date) return;
    const fecha = new Date(date);
  
    const options = {
      timeZone: timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
  
    const formattedDate = fecha.toLocaleString('en-US', options);
  
    return formattedDate;
  };
  


        

  return {formatTime, dateFormatDR, dateAndTimeFormatDR, dateFormat}


}




export default useDateAndTimeFormatter;