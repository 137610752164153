import styled from '@emotion/styled';
import useCirugia from '../../../hooks/useCirugia';
import calcMinutesCheckIn from '../../../shared/calcMinutesCheckIn'
import CardInfo from '../../Atoms/CardInfo';

const CheckInResult = ({
    cirugiaId
})=>{

    const cirugia = useCirugia(cirugiaId);
    const checkIn = cirugia?.especialista?.checkIn
    const timeCheckIn = calcMinutesCheckIn(checkIn?.checkInFecha, cirugia?.info?.fecha)
    console.log(timeCheckIn);

    return(
        <Wrapper>
            <Title>Check-In</Title>
            {
                !checkIn?.latitude && !checkIn?.longitude ?
                <CardInfo>
                    Especialista Clinico todavia no ha hecho Check-in.
                </CardInfo>
                :
                <>
                    <Text>{`${cirugia?.especialista?.nombre?.firstname} ${timeCheckIn || "No hay hora registrada"}`}</Text>   
                    {
                    !checkIn?.centroLocation && <Text>Centro no tiene ubicacion en sistema. No se pudo determinar si el Check-In fue en el centro.</Text>
                    }     
                    {   //if centro has a location and check in was not at location.
                        !checkIn?.checkInAtLocation && checkIn?.centroLocation && <TextRed>Especialista no hizo check-in en el centro.</TextRed>
                    }
                    {   checkIn?.checkInAtLocation  && <TextGreen>Especialista hizo check-in en el centro.</TextGreen>}
                    <Body>
                        <iframe
                            title="embedMaps"
                            width="600"
                            height="450"
                            style={{border:0}}
                            loading="lazy"
                            allowFullScreen
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBJgj8onWEjNtVpkLWhKvCMJUx-igLlPm4
                            &q=${checkIn?.latitude},${checkIn?.longitude}`}>
                        </iframe>
                    </Body>
                </>
            }
            
        </Wrapper>
    )

}


const Wrapper = styled.div`
    width:100%;
    padding: 16px;
    display:flex;
    flex-direction: column;
    gap: 12px;

    @media ${p=>p.theme.media.tablet}{
        padding: 24px;
    }

`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    margin-bottom: 18px;
    color: ${p=>p.theme.palette.grey[800]}
    
`
const Text = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[700]};
    margin-bottom: 18px;        
`

const TextRed = styled(Text)`
    color: ${p=>p.theme.palette.error.light}
`
const TextGreen = styled(Text)`
    color: ${p=>p.theme.palette.success.light}
`




const Body = styled.div`
    width:100%;
    flex:2;
    display:flex;
    flex-direction: column;
    overflow: auto;
    align-items:center;
`


export default CheckInResult