import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';


const ReporteChip = ({cirugia})=>{
    
    let cirugiaRealizada = cirugia.info?.realizada;
    let reporteEnviado = cirugia.reporte?.enviadoReporte;
    
    if(!cirugiaRealizada)return null    
    
    if(reporteEnviado) return <Chip label={"Reporte"} success/>
    if(!reporteEnviado) return <Chip label={"Reporte"} error/>
    
    return null;
}



export default ReporteChip;