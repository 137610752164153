import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from "../../Atoms/Chip";
import styled from '@emotion/styled';



export const GrupoSelectMultiple = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name,    
})=>{

    const especialidades = [
        'Neurocirugia',
        'Cardiologia',
        'Tiroide',
        'Maxilo',
        'Cirugia General',
        'Artroscopia',
        'Ortopedia',
        'Ginecologia',
        'Urologia',        
        'ORL',
        'Columna',
        'Grapado Abierto',
        'Grapado TriStaple', 
        `General`       
    ]


    return(
        <FormControl fullWidth>
            <CustomInputMuiLabel>{label}</CustomInputMuiLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}                
                onChange={onChange}
                name="especialidad"
                multiple
                renderValue = {(selected)=>(
                    <Container>
                        {selected.map((value)=>(
                            <Chip primary key={value} outline label={value}/>
                        ))}
                    </Container>
                )}
            >
                {
                    especialidades.map((especialidad, index)=>(
                        <MenuItem key={`${especialidad} ${index}`}value={especialidad}>{especialidad}</MenuItem>        
                    ))
                }                
            </Select>
        </FormControl>


    )
}



const Container = styled.div`
    display: flex;
    padding-top: 8px;
    gap: 4px;
    flex-wrap: wrap;

`

export default GrupoSelectMultiple; 