import styled from '@emotion/styled';
import LabelValue from '../Atoms/LabelValue';




const Facturacion = ({facturacion})=>{

    return(
        <>
            {facturacion?.transferencia &&
                <Column>
                    <LabelValue label="Transferencia: " value={facturacion.transferencia}/>
                </Column>
            }
            {facturacion?.entrega &&
                <Column>
                    <LabelValue label="Entrega: " value={facturacion.entrega}/>
                </Column>
            }
            {facturacion?.factura &&
                <Column>    
                    <LabelValue label="Factura: " value={facturacion.factura}/>
                </Column>
            }
            
        </>
    )

}


const Column = styled.div`
    flex:1;
`

export default Facturacion;