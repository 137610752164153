import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import LayoutMd from "../layout/LayoutMd";





const RequireAuthMd = ()=>{

    const {isAuthenticated} = useSelector(state=>state.auth);
    const user = useCurrentUser();


    if(!isAuthenticated || !user?.role?.md){
        return <Navigate to="/auth/login"/>
    }


    return (
        <LayoutMd />
    )


}


export default RequireAuthMd;