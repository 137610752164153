import React from "react";
import { useDispatch } from "react-redux";
import { loading, loadingCentros } from "../../../app/centrosReducer";
import { fetchCentros, searchCentros } from "../../../app/thunkActions/centrosActions";
import SearchBar from "../../Atoms/SearchBar";




const CentrosSearchBar = ()=>{
    const [searchValue, setSearchValue] = React.useState();

    const dispatch = useDispatch();

    const onChangeSearchValue = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        setSearchValue(searchTerm)
        if(searchTerm.length>2){            
            dispatch(searchCentros(searchTerm))
        }
        if(!searchTerm){            
            dispatch(fetchCentros())
        }
         

    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar Centro"
        value={searchValue}
        onChange={onChangeSearchValue}
    />
    )
}



export default CentrosSearchBar