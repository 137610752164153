import styled from '@emotion/styled';
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { successMessage } from '../../../app/messagesReducer';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { apiUrl } from '../../../shared/url';
import Avatar from '../../Atoms/Avatar';
import Button from '../../Atoms/Button';
import { CardBody, CardWrapper } from '../../Atoms/Card';
import CardError from '../../Atoms/CardError';
import Chip from '../../Atoms/Chip';
import Modal from '../../Atoms/Modal';
import CasaSearchBar from './CasaSearchBar';
import SolicitarInvitacionButton from './SolicitarInvitacionButton';


const CasaComercial =()=>{

    const theme = useTheme(); 
    const user = useCurrentUser();
    const [openModal, setOpenModal] = React.useState(false);  
    const [formType, setFormType] = React.useState();
    const smallBottomSwipeDrawers = ["compartirAcceso"];
    const [firstRender, setFirstRender] = React.useState(true);
    const [resetSearchValue, setResetSearchValue] = React.useState(false);
    const [companies, setCompanies] = React.useState([]);
    const [companyLinks, setCompanyLinks] = React.useState([]);
    const [linkRequests, setLinkRequests] = React.useState([]);
    const [isLoadingConectadas, setIsLoadingConectadas] = React.useState(false);
    const [isLoadingInvitadas, setIsLoadingInvitadas] = React.useState(false);
    const [status, setStatus] = React.useState(false);

    const getCompanies = async(type)=>{

        const options = createOptions("GET") 
        try {
            type==="connected" ? setIsLoadingConectadas(true) : setIsLoadingInvitadas(true)
            const {companies, companyLinks, linkRequests} = await goFetch(`${apiUrl}md-users/casas-comerciales-conectadas/${type}`, options)
            
            setCompanies(companies)
            setCompanyLinks(companyLinks);
            setLinkRequests(linkRequests)   
            type==="connected" ? setIsLoadingConectadas(false) : setIsLoadingInvitadas(false)
        } catch (error) {
            console.log(error)
            type==="connected" ? setIsLoadingConectadas(false) : setIsLoadingInvitadas(false)
        }       
                
    }


    const onClickGetCompanies = async(status)=>{

        setStatus(status)
        setResetSearchValue(true)
        await getCompanies(status)
        if(firstRender)setFirstRender(false); 

    }

    const connected = status === 'connected';
    const invited = status === 'invited';

    return (
        <Wrapper>
            <SearchBarWrapper>
                <CasaSearchBar 
                    firstRender={firstRender} 
                    setFirstRender={setFirstRender}
                    resetSearchValue={resetSearchValue}                    
                    setResetSearchValue={setResetSearchValue}
                    setCompanies={setCompanies}
                    setCompanyLinks={setCompanyLinks}
                    setLinkRequests={setLinkRequests}
                    status={status}
                    setStatus={setStatus}
                />                
            
            <FilterWrapper>
                    <Button
                        loading={isLoadingConectadas}
                        onClick={()=>onClickGetCompanies('connected')}                       
                        outline= {!connected}
                        preIcon={<FontAwesomeIcon icon={faLink} color={connected ? '#ffff' : theme.palette.grey[700]}/>} 
                    >Compañias conectadas</Button>
                    <Button   
                        loading={isLoadingInvitadas}
                        onClick={()=>onClickGetCompanies('invited')}                                                                    
                        outline= {!invited}
                        preIcon={<FontAwesomeIcon icon={faEnvelope} color={invited ? '#ffff' : theme.palette.grey[700]}/>} 
                    
                    >Compañias con invitacion enviada</Button>
                </FilterWrapper>
            </SearchBarWrapper>
            <BodyWrapper>
            
            {
                companies?.length<1 && !firstRender && (!isLoadingInvitadas || !isLoadingConectadas) && 
                <CardError>
                    No se encontraron compañias    
                </CardError>
            }

            { (!isLoadingInvitadas || !isLoadingConectadas) && companies?.length>0 && 
                companies?.map((company)=>{
                    console.log(company)
                const isConnected = companyLinks?.filter((companyLink)=>companyLink.companyId===company?._id)[0]
                const isInvited = linkRequests?.filter((companyLink)=>companyLink.companyId===company?._id)[0]
                const isInvitationRequested = user?.invitationRequests.filter((invitationRequest)=>invitationRequest.companyId === company?._id)[0]
                
            console.log(user)
                return  (
                    <Card key={`${company._id}`}>
                        <CardBody>
                            <InnerWrapper>
                                <Avatar>{company.name.substring(0,1)}</Avatar>
                                <Info>
                                    <Text>{company.name}</Text>                                                                        
                                </Info>
                                <EndContainer>                                                                
                                
                                { (isConnected || isInvited) && 
                                     <Chip 
                                        icon={<FontAwesomeIcon icon={faLink} color={theme.palette.grey[600]}/>} 
                                        outline 
                                        color={theme.palette.grey[800]}  
                                        label={isConnected?"Conectada" : "Invitacion pendiente"}
                                    />
                                }
                                {
                                    (!isConnected && !isInvited && !isInvitationRequested) && 
                                        <SolicitarInvitacionButton user={user} company={company} companies={companies} setCompanies={setCompanies}/>
                                }
                                </EndContainer>
                            </InnerWrapper>

                        </CardBody>
                    </Card>
                )
                    
                } )           
            }

            </BodyWrapper>

            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}   
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
            >
                
            </Modal>    

            

        </Wrapper>
    )
}




const Wrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;    
`

const SearchBarWrapper = styled.div`
    width:100%;
    max-width:750px;
    display:flex;
    flex-direction: column;    
    gap:4px;
`

const FilterWrapper = styled.div`
    display:flex;
    gap:4px;
    flex-wrap: wrap;
`

const Card = styled(CardWrapper)`
    margin:0;
    margin-bottom: 8px;
    
`

const BodyWrapper = styled.div`
    max-width: 1000px;
    width:100%;
    
`


const InnerWrapper  = styled.div`
    display:flex;
    align-items: center;
    gap: 12px; 
    min-height:50px;
    flex-wrap: wrap;
`

const Text = styled.p`
    margin: 0;
    color: ${p=>p.theme.palette.grey[800]}
`

const SubText = styled.p`
    margin:0;
    font-size: 0.8rem;
    color: ${p=>p.theme.palette.grey[700]}
`

const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex:2;
`

const EndContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    gap: 4px;
    flex-wrap: wrap;

    @media ${p=>p.theme.media.tablet}{
        justify-content: revert;
        width:revert;
    }
    
`





export default CasaComercial