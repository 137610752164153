import styled from '@emotion/styled';
import React from 'react';
import Button from '../Atoms/Button';
import Modal from '../Atoms/Modal';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import CrearRegla from './CrearRegla';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import { CardWrapper } from '../Atoms/Card';
import { useTheme } from '@mui/material';
import ProductosTableRegla from './molecules/ProductosTableRegla';
import EliminarRegla from './EliminarRegla';
import EditarRegla from './EditarRegla';
import MoreOptionsRegla from './molecules/MoreOptionsRegla';
import Plus from '../Icons/Plus';




function GestionAgendaCirugias() {

  const theme = useTheme();
  const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
  const [formType, setFormType] = React.useState();
  const [reglas, setReglas] = React.useState([])
  const [productos, setProductos] = React.useState([])
  const [regla, setRegla] = React.useState()
  const smallBottomSwipeDrawers = []


const fetchReglas = React.useCallback(async ()=> {
    const options = createOptions('GET')
    let response;
      
    try{
        response = await goFetch(apiUrl+ "agenda",options );
        setReglas(response.reglas)
       console.log(response);
    }catch(error){
        console.log(error);
    }
  },[])


  React.useEffect(()=>{
    fetchReglas()
  } 
  ,[])


  const handleOnClick = (formType)=>{
    setOpenModal(true);    
    setFormType(formType)
  }

 



  return (
    <Container>
    <ExternalWrapper>
      <ButtonWrapper>
        <Button outline postIcon={<Plus size="20" color={theme.palette.grey[600]}/>} onClick={()=>handleOnClick('crearRegla')}>Crear Regla</Button>   
      </ButtonWrapper>
      

      {
        reglas && reglas.map((regla, index)=>{
          return(
            <CardWrapper key={index}>
              <Wrapper>
              <div>{regla.nombreGrupo}</div>
              <div>Cirugias por dia: {regla.numeroCirugiasDia}</div>
              <MoreOptionsRegla setOpenModal={setOpenModal} setFormType={setFormType} setProductos={setProductos} setRegla={setRegla} regla={regla} theme={theme}/>
              </Wrapper>

              
            </CardWrapper>
          )
        })
      }
      
    </ExternalWrapper>
    <Modal
    openModal={openModal}
    setOpenModal={setOpenModal} 
    inProp={inProp}
    setInProp={setInProp}
    close={close}
    smallDrawer = {smallBottomSwipeDrawers.includes(formType)}         
    >
      {formType=== "productos" && <ProductosTableRegla productos={productos}/> }
      {formType=== 'crearRegla' && <CrearRegla close={close} regla={regla} reglas={reglas} setReglas={setReglas}/>}
      {formType=== 'editarRegla' && <EditarRegla close={close} regla={regla} reglas={reglas} setReglas={setReglas}/>}
      {formType=== 'eliminarRegla' && <EliminarRegla close={close} regla={regla} reglas={reglas} setReglas={setReglas}/>}      
    </Modal>


  </Container>
  );
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const ExternalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1200px;
     
    
` 

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
`    

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    margin: 16px;
`

export default GestionAgendaCirugias;
