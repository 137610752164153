import styled from '@emotion/styled/macro';
import { useTheme } from "@mui/system";

// import {StyledMenu, StyledSpan, StyledMenuButton, StyledMenuItem, StyledMenuList}from '../../Atoms/StyledDropdownReach'

//import usePermissions from '../../../hooks/usePermissions';
import { Menu, MenuItem } from '@mui/material';

import React from 'react';
import Button from '../../../Atoms/Button';
import ChevronDown from '../../../Icons/ChevronDown';
import FileText from '../../../Icons/File-text';
//import useSubscriptionToken from '../../../hooks/useSubscriptionToken';



  const PreciosMenuButton = ({
    selectedPriceList,
    setSelectedPriceList
  })=>{
    let theme = useTheme();
    
    
    const [priceList, setPriceList] = React.useState([{
        id: 1,
        name: "Base"
    },
    {
        id: 2,
        name: "Senasa"
    },
    {   
        id: 3,
        name: "Humano"

    },
    {
        id: 4,
        name: "Mapfre"
    },  
    {
        id: 5,
        name: "Universal"
    },    
])

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

    let buttonColor= theme.palette.grey[600];
    



    const onSelect = (priceList)=>{
      setSelectedPriceList(priceList)  
      setAnchorEl(null)
    }

    

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }

    return (
        <div>
                    
                    <Button
                        outline    
                        onClick={onClickHandler} 
                        postIcon={<ChevronDown size={20} color={buttonColor}/>}       
                    >
                    <StyledSpan>{`${selectedPriceList}` || "Lista"}</StyledSpan>
                    
                    </Button>
                    <Menu 
                        open={open}
                        anchorEl={anchorEl}
                        onClose={onCloseHandler}
                        
                    >
                       {
                            priceList.map((price)=>{
                                return (
                                    <MenuItem
                                    key={price.id}
                                     onClick={()=>onSelect(price.name)}>
                                        <WrapperMenuItem>                                            
                                            {price.name}
                                        </WrapperMenuItem>                            
                                    </MenuItem>
                                )
                            })
                       } 
                            
                    </Menu>                      
                
                
                
        </div>
    )
  }


//   const NewStyledMenuList = styled(StyledMenuList)`
    
//   `

  const WrapperMenuItem = styled.div`
        display:flex;   
        gap: 8px; 
        align-items: center;
  `





  export const StyledSpan = styled.span`
  font-weight: 600;
  color: var(--button-color);
  font-size: .875rem;
  line-height: 1.75;
  `


  

  export default PreciosMenuButton;