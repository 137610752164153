import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    mds:[],
    md: null,
    nextCursor: "",
    isLoading: false,
    isLoadingMore: false
}

export const mdsSlice = createSlice({
    name:"mds",
    initialState,
    reducers: {
        loadingMds(state, action){
            state.isLoading = true
        },
        loadingMoreMds(state,action){
            state.isLoadingMore = true;
        },
        addMds(state, action){
           // console.log(action.payload);
            state.mds = action.payload.mds;
            state.nextCursor = action.payload.nextCursor;
            state.isLoading= false;            
        },
        addUpdatedMd(state,action){     
            state.mds.forEach((md, index) => {
                if(md._id === action.payload._id){                 
                    state.mds[index] = action.payload
                }
                return                
            });
            state.isLoadingUpdate = false;
        }, 
        addNextMds(state, action){
            // console.log(action.payload);
             state.mds = [...state.mds, ...action.payload.mds];
             state.nextCursor = action.payload.nextCursor;
             state.isLoadingMore= false;            
         },
        addMd(state,action){
            state.md = action.payload
            state.isLoading= false;            
        },
        resetMds(state,action){
            state.mds = [];
        }, 
        resetMd(state,action){
            state.md = null;
        }        
    }
})

const {actions, reducer} = mdsSlice

export const {loadingMds, loadingMoreMds, addMds, addUpdatedMd, addNextMds, addMd, resetMds, resetMd} = actions;

export default reducer;