//import Autocomplete from "../../Atoms/Autocomplete"
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCentros } from "../../../app/thunkActions/centrosActions";
//Wraps pacientes specific logic for autocomplete

import { TextField } from '@mui/material';

import StyledAutocomplete from '../../Atoms/StyledMUIAutocomplete';

const CentrosAutocomplete = ({
    onChange,
    value,    
})=>{

    const dispatch = useDispatch();
    const centros = useSelector(state=>state.centros.centros);
    //const [_value, setValue] = React.useState('')
    //const [inputValue, setInputValue] = React.useState('testing')

    console.log(value);
    
    React.useEffect(()=>{
        dispatch(fetchCentros())
    },[dispatch])

    

    /*
    React.useEffect(()=>{
        if(value){
            console.log("see value, will get centro")
           let currentCentro = centros?.filter((centro)=>centro._id === value)[0];
           console.log(currentCentro)
           currentCentro && setValue(currentCentro._id)
          // currentCentro && setInputValue(currentCentro.nombre)
           return
        }
        
        //this is needed in order to reset _value after change in input following initial render. Has to be empty, string was causing problems
        console.log("No value, setting to undefined")
        setValue()
      },[dispatch, centros, value])

    const onInputChange = (e,nv)=>{
        console.log(nv);
        setInputValue(nv);
        //if(nv){
        //    setInputValue(nv);
       // }
        

      }
    */

   /* return(
        <Autocomplete                  
            label="Centro"
            name="centro"
            id="centro"
            onChange={onChange}
            options={centros}
            getOptionLabel={(option)=>option.nombre?option.nombre : ""}
        />
    )

    */

    


    return (
        <StyledAutocomplete
            id="centros"
            name="centros"                     
            value={value}
            onChange={onChange}            
            options={centros}
            getOptionLabel={(option)=>option.nombre?option.nombre : ""}
            renderInput={(params) => <TextField {...params} outlined="true" label="Centros" />}
        />

    )

}






export default CentrosAutocomplete;