import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { login } from '../../app/thunkActions/authActions';
import BackButton from '../Atoms/BackButton';
import Button from '../Atoms/Button';
import { CardWrapper } from '../Atoms/Card';
import PasswordInput from '../Atoms/PasswordInput';
import TextInput from '../Atoms/TextInput';
import AlertMessages from '../molecules/AlertMessages';



const LoginLayout = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isAuthenticated} = useSelector(state=>state.auth);
    



    return(
        <>
        {
            isAuthenticated ? <Navigate to={'/app'} /> :
            <Wrapper>
                
                <LoginSection>
                    <BackButtonWrapper>
                        <BackButton onClick={()=>navigate(-1)}/>
                    </BackButtonWrapper>
                    <Card>
                    
                    <LogoWrapper>
                        <Logo src='/img/xirugias_logo_512x512.png' alt="company logo"/>                        
                        
                    </LogoWrapper>
                    
                    <LoginWrapper>
                        <Outlet/>                
                    </LoginWrapper>
                    </Card>
                </LoginSection>
            </Wrapper>
        }
        <AlertMessages/>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgb(238, 241, 245);
    justify-content: center;
`

const Card = styled(CardWrapper)`
    min-width: 400px;
    padding-top: 24px;
    padding-bottom: 54px;
    align-items: center;
`



const LoginSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    
    max-width: 1250px;
        
`

const LogoWrapper = styled.div`
    align-self: center;
`

const BackButtonWrapper = styled.div`
    align-self: flex-start;
    margin-left: 24px;
    margin-top: 24px; 
`

const Title = styled.h1`
    margin:0;
    margin-bottom: 24px;
    align-self: center;
    
`

const LoginWrapper = styled.div`
    width: 100%;
    max-width: 350px;
    display:flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    
`



const LoginButton = styled(Button)`
    margin-top: 24px;
    border-radius: 8px;
    max-height: revert;
    height: 45px;
`

const Logo = styled.img`    
    max-width: 175px;
    @media ${p=>p.theme.media.laptop}{
        max-width: 175px;
    }
`
const PasswordReset = styled(Link)`
    font-size: 0.75rem;
    text-decoration: none;
    color: ${p=>p.theme.palette.grey[600]}

`

export default LoginLayout;