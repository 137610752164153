import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { addDoctor } from '../../../app/doctorsReducer';
import { apiUrl } from '../../../shared/url';
import EditarCentros from '../../molecules/EditarCentros';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { errorMessage } from '../../../app/messagesReducer';



const DoctorEditarCentros = ({
    doctor,
    close
})=>{
    const dispatch = useDispatch();    
    const initialCentrosArray = doctor?.centros || []
    const [centrosArray, setCentrosArray] = React.useState(initialCentrosArray)    
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    
    

   
    const onSubmit = async (event)=>{
        event.preventDefault();
        setIsLoading(true)

        const options = createOptions('PUT', {centros:centrosArray})
        let response;

        try {
            response = await goFetch(apiUrl+'doctors/centros/update/'+ doctor?._id, options);    
            responseStateChanges(response, ()=>dispatch(addDoctor(response.doctor)), ()=>close())        
        } catch (error) {
            console.log(error)            
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }                
    }


    return(
       <EditarCentros
            onSubmit={onSubmit}
            initialCentrosArray={initialCentrosArray}
            centrosArray={centrosArray}
            setCentrosArray={setCentrosArray}
            close={close}
            isLoading={isLoading}
            successEvent={successEvent}
       />
    )

}






export default DoctorEditarCentros; 