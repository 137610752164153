import { addProductos, setLoading} from "../productosReducer";
import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";

export const fetchProductos = (search, filter="active")=> async(dispatch)=>{
    const options = createOptions('GET')
    let productos;
    dispatch(setLoading(true));
      
    try{
        productos = await goFetch(`${apiUrl}productos/${search}/${filter}`,options );
        dispatch(addProductos(productos));
    }catch(error){
        console.log(error);
        dispatch(setLoading(false));
    }

    

}