import React from 'react';



const useSwipe = (onClose,sensitivity,swipeTopToBottom)=>{
    const [swipeDistance, setSwipeDistance] = React.useState(0)
    const [sliderRef, setSliderRef] = React.useState();

    //created this function in order to capture node and trigger a rerender that wil allow useEffect to attach listeners to node. 
    const refCallback = React.useCallback((node)=>{
        //console.log('ref callback called')
        if(node){
          //  console.log(node);
            setSliderRef(node);
        }

    },[])
    

    const getGesturePointFromEvent =(event)=>{
        let point = {}

        if(event.targetTouches){
            point.x=event.targetTouches[0].clientX;
            point.y=event.targetTouches[0].clientY;
        }else{
            point.x=event.clientX;
            point.y=event.clientY;
        }
        

        return point
    }

    let initialTouchPosition =React.useRef();
    let lastTouchPosition =React.useRef();

   
    const handleGestureStart = React.useCallback( (event)=>{
       // event.preventDefault();
        if(event.touches && event.touches.length > 1) {
            return;
          }
        event.target.setPointerCapture(event.pointerId)
        initialTouchPosition.current= getGesturePointFromEvent(event)
       // console.log(initialTouchPosition.current);
    },[])

    const handleGestureMove=React.useCallback((event)=>{
        event.preventDefault();
        let _swipeDistance;
        if(!initialTouchPosition?.current)return
        lastTouchPosition.current = getGesturePointFromEvent(event)
        
        if(swipeTopToBottom){
            _swipeDistance =lastTouchPosition.current.y-initialTouchPosition.current.y
            //console.log(_swipeDistance);
            if(_swipeDistance>0) setSwipeDistance(_swipeDistance);
            return
        }
        _swipeDistance =initialTouchPosition.current.x-lastTouchPosition.current.x
        
       if(_swipeDistance>0) setSwipeDistance(_swipeDistance);

    },[swipeTopToBottom])

    const handleGestureEnd = React.useCallback((event)=>{
        event.preventDefault();

        const resetPointerInfo=()=>{
            event.target.releasePointerCapture(event.pointerId);
            initialTouchPosition.current = null;
            lastTouchPosition.current = null;
        }

        if(swipeTopToBottom){
            if(lastTouchPosition.current?.y){
                let _swipeDistance =lastTouchPosition.current.y-initialTouchPosition.current.y;
                if(_swipeDistance>sensitivity){
                    onClose()
                    resetPointerInfo();  
                   // setSwipeDistance(0);     
                    return
                }
            }
        }
        //Proceed only if there is a swipe.
        if(lastTouchPosition.current?.x){
            let _swipeDistance =initialTouchPosition.current.x-lastTouchPosition.current.x
            if(_swipeDistance>sensitivity){
                onClose()
                resetPointerInfo();       
                return
            }
        }
        
        resetPointerInfo();
        setSwipeDistance(0);
    },[onClose, sensitivity, swipeTopToBottom])

    React.useEffect(()=>{
        
        //does not need current as we are passing a custom function(wrapped with useCallback) to the component ref
        let element = sliderRef;
       // console.log('Event listeners registered')
        element?.addEventListener('pointerdown', handleGestureStart, true);
        element?.addEventListener('pointermove', handleGestureMove, true);
        element?.addEventListener('pointerup', handleGestureEnd, true);
        element?.addEventListener('pointercancel', handleGestureEnd, true);

        return ()=>{
           // console.log('Event listener removed');
            element?.removeEventListener('pointerdown', handleGestureStart, true);
            element?.removeEventListener('pointermove', handleGestureMove, true);
            element?.removeEventListener('pointerup', handleGestureEnd, true);
            element?.removeEventListener('pointercancel', handleGestureEnd, true);
            setSwipeDistance(0)
        }
        
    },[sliderRef, handleGestureStart,handleGestureMove, handleGestureEnd])

   



return [refCallback, swipeDistance];

}



export default useSwipe;
