import { useState, useRef, useEffect } from 'react';
import { apiUrl } from '../shared/url';
import { getCookie } from '../shared/authHelpers';

const useAudioRecording = (cirugiaId) => {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const streamRef = useRef(null);

    const startRecording = async (field) => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            streamRef.current = stream;
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            return new Promise((resolve) => {
                mediaRecorderRef.current.onstop = () => {
                    if (audioChunksRef.current.length > 0) {
                        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                        resolve(audioBlob);
                    } else {
                        console.error('No audio data recorded');
                        resolve(null);
                    }
                    audioChunksRef.current = [];

                    if (streamRef.current) {
                        streamRef.current.getTracks().forEach(track => track.stop());
                        streamRef.current = null;
                    }
                };

                mediaRecorderRef.current.stop();
                setIsRecording(false);
            });
        } else {
            return Promise.resolve(null);
        }
    };

    const sendAudioToBackend = async (audioBlob, field) => {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'audio.wav');
        formData.append('field', field);
        formData.append('cirugiaId', cirugiaId);

        try {
            const response = await fetch(`${apiUrl}cirugias/transcribe-audio/${cirugiaId}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${getCookie('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.transcription;
        } catch (error) {
            console.error('Error sending audio to backend:', error);
            return null;
        }
    };

    useEffect(() => {
        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    return { isRecording, startRecording, stopRecording, sendAudioToBackend };
};

export default useAudioRecording;