import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    activosFijos: [],
    isLoading: false
}

export const activosFijosSlice = createSlice({
    name: "activosFijos",
    initialState,
    reducers: {
        addActivosFijos(state, action){
            state.activosFijos = action.payload;
            state.isLoading = false;            
        },
        updateActivosFijosArray(state, action){            
            state.activosFijos.map((activoFijo, index) => {            
                if(activoFijo._id === action.payload._id){
                    state.activosFijos[index] = action.payload
                }
            })
        },
        initActivosFijos(state, action){
            state.activosFijos = [];
        },
        setLoading(state, action){
            state.isLoading = action.payload;
        }        
    }
})

const {actions, reducer} = activosFijosSlice

export const {addActivosFijos, updateActivosFijosArray, initActivosFijos, setLoading} = actions;

export default reducer;