import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    cirugia: null,
    isLoading: true

}

export const cirugiaSlice = createSlice({
    name:"cirugia",
    initialState,
    reducers: {
        addOneCirugia(state, action){
            console.log(action)
            state.cirugia = action.payload;
            state.isLoading = false;
            
        },
        resetCirugia(state,action){
            state.cirugia = null;
        }         
    }
})

const {actions, reducer} = cirugiaSlice

export const {addOneCirugia, resetCirugia} = actions;

export default reducer;