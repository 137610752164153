import React from 'react';
import styled from '@emotion/styled';
import TextInput from '../../Atoms/TextInput';
import { FormWrapper, FormTitle, FormBody, FormActions, FormActionsMobile } from '../../Atoms/Forms';
import { isEmail, isLength, isCedula, isTelefono,  validate } from '../../../shared/validators';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import { crearDoctor } from '../../../app/thunkActions/doctorsActions';
import useCheckForm from '../../../hooks/useCheckForm';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { updateMdProfileUser, updateProfileUser } from '../../../app/thunkActions/authActions';
import Button from '../../Atoms/Button';
import useCentrosArray from '../../../hooks/useCentrosArray';
import CentrosAutocomplete from '../../Forms/CustomAutocomplete/CentrosAutocomplete';
import SmallButton from '../../Atoms/SmallButton';
import XCircle from '../../Icons/X-circle';
import Plus from '../../Icons/Plus';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { setLocalStorage } from '../../../shared/authHelpers';
import { updateUserState } from '../../../app/authReducer';
import EspecialidadSelect from '../../Forms/CustomAutocomplete/EspecialidadSelect';

const EditMdUserProfile = ({
    showTitle=true,
    close
})=>{

    
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();
    const theme = useTheme();

    const user = useSelector(state=>state.auth.user);
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()

    console.log(user);

    const initialState = {
        id: user._id,
        firstname: user.firstname || '',
        secondname: user.secondname || '',
        lastname: user.lastname || '',
        secondLastname: user.secondLastname || '',  
        especialidad: user.especialidad || "",      
        email: user.email || '',
        telefono: user.telefono || '',
        centros: user.centros || []        
    }
    const [values,setValues] = React.useState(initialState)
    
    const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',        
        email: '',
        telefono: ''        
    })

    
    const [formCompleted, errorCount] = useCheckForm(values,errors, ["firstname", "lastname", "email"])
    
    
    const onChangeHandler = (event)=>{
        const {name, value} = event.target
        setValues(prevState => ({...prevState, [name]:value}))
    }


    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

    const onSubmit = async (event)=>{
        event.preventDefault();                
        setIsLoading(true);
        const options = createOptions("PUT", values)

        try{
            let response = await goFetch(apiUrl + `md-users/update/${values.id}`, options)
            //update the local storage and the store since updating localstorage wont trigger store update/
            responseStateChanges(response,
                null,
                ()=>{
                    setLocalStorage('user', response.user);
                    dispatch(updateUserState(response.user));
                    close();
                }
            )            
        }catch(error){
            console.log(error);
        }
        
    }

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            
            <FormTitle isTablet={isTablet}>Editar Perfil</FormTitle>
            
            <FormBody isTablet={isTablet}>
                <TextInput
                    label="Primer Nombre" name="firstname" id="firstname"
                    value={values.firstname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Segundo Nombre" name="secondname" id="secondname"
                    value={values.secondname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={errors.secondname}
                    error={errors.secondname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Apellido" name="lastname" id="lastname"
                    value={values.lastname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Segundo Apellido" name="secondLastname" id="secondLastname"
                    value={values.secondLastname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />           
                <EspecialidadSelect
                    label="Especialidad"
                    id="especialidad"
                    name="especialidad"
                    value={values.especialidad}
                    onChange={onChangeHandler}
                />    
               
                <TextInput
                    disabled
                    label="Email" name="email" id="email"
                    value={values.email}
                    onChangeHandler={onChangeHandler}                    
                    error={errors.email}
                    onBlurValidation={(event)=>onBlur(event, [isEmail])}
                />
                 <TextInput
                    label="Telefono" name="telefono" id="telefono"
                    value={values.telefono}
                    onChangeHandler={(event)=>onChangeValues(event,[isTelefono], true)}
                    helperText={!errors.telefono ? "Campo Obligatorio": errors.telefono}
                    error={errors.telefono}
                    onBlurValidation={(event)=>onBlur(event, [isTelefono])}
                />                                                      
            </FormBody>
            <FormActionsBasic
                successEvent={successEvent}
                loading={isLoading}   
                close={close}
                type="submit"
                as="button"
                disabledSuccess={!formCompleted || errorCount>0}
            />
        </StyledFormWrapper>
    )
}

const StyledFormWrapper = styled(FormWrapper)`
    

    @media ${p=>p.theme.media.tablet}{
        min-width: 400px;
    }
`


export default EditMdUserProfile;