import styled from '@emotion/styled';
import { faCalendarCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPacientesScrollPosition } from '../../app/pacientesReducer';
import useMediaQuery from '../../hooks/useMediaQuery';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import usePermissions from '../../hooks/usePermissions';
import { CardBody, CardWrapper } from '../Atoms/Card';
import CardError from '../Atoms/CardError';
import CardInfo from '../Atoms/CardInfo';
import IconButton from '../Atoms/IconButton';
import Modal from '../Atoms/Modal';
import DoctorsListLoading from '../Doctors/Molecules/DoctorsListLoading';
import CrearPaciente from '../Forms/CrearPaciente';
import Phone from '../Icons/Phone';
import Plus from '../Icons/Plus';
import PacientesSearchBar from './molecules/PacientesSearchBar';


const PhoneIconButton = ({paciente})=>(
    <IconButton size="small" as='a' href={`tel:+1${paciente.telefono}`} outline><Phone size='18'/></IconButton>
)



const PacientesList =()=>{

    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();
    const [formType, setFormType] = React.useState();   
    const {pacientesSearch, isLoading, scrollPosition, searchValue} = useSelector(state=>state.pacientes)
    const [paciente, setPaciente] = React.useState();
    const smallBottomSwipeDrawers = []    
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const location = useLocation();

    const permissions = usePermissions()

    const {        
        canSeeAllCirugias,
        canCrearPacientes               
     }=permissions;

  

    const handleOnClick = (formType, paciente)=>{
        setOpenModal(true);        
        setFormType(formType);
        setPaciente(paciente);
    }

    const setScroll= ()=>{
        dispatch(setPacientesScrollPosition(window.scrollY))
    }
    
    React.useEffect(() => {
      window.scrollTo(0, scrollPosition)
    }, [])
    



    console.log(pacientesSearch)
    
    return(
        <ExternalWrapper>
        <Wrapper>            
            <Header>
                <SearchBarWrapper>
                    <PacientesSearchBar isLoading={isLoading}/>                                            
                    {canCrearPacientes && <StyledIconButton onClick={()=>handleOnClick('addPaciente')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>  }                                                                                           
                </SearchBarWrapper>   
                <FilterWrapper>
                    
                </FilterWrapper>                      
                
            </Header>
            <Body>
                {/*isLoading && <DoctorsListLoading/> */}
                {!isLoading && pacientesSearch?.length === 0 && searchValue.length<2 &&
                    <CardInfo>
                        Puedes buscar pacientes por nombre, nss o cedula. 
                    </CardInfo>
                }
                {
                    (pacientesSearch?.length === 0 && searchValue.length>2 ) ?
                        <CardError>
                            No se encontraron pacientes
                        </CardError>
                    :
                    !isLoading &&
                    pacientesSearch?.map((paciente)=>(
                        <CardWrapper key={`${paciente._id}`}>
                            <CardBody>
                                <CardBodyWrapper>

                                
                                <PacienteInfoWrapper>
                                    <Name>{`${paciente.firstname} ${paciente.secondname} ${paciente.lastname} ${paciente.secondLastname}`}</Name>
                                    <Text>{`Nss: ${paciente?.nss || ""} `}</Text>
                                    <Text>{`Cedula: ${paciente?.cedula || ""}`}</Text>
                                    <Text>{`Email: ${paciente?.email || ""} `}</Text>

                                    {
                                        !isTablet &&
                                        <TelephoneWrapper style={{marginTop: '8px'}}>
                                            <PhoneIconButton paciente={paciente}/>
                                            <Text>{`${paciente.telefono || ""}`}</Text>
                                        </TelephoneWrapper>
                                    }
                                    
                                </PacienteInfoWrapper>
                                {
                                    isTablet && 
                                    <TelephoneWrapper>
                                        <PhoneIconButton paciente={paciente}/>
                                        <Text>{`${paciente.telefono}`}</Text>
                                    </TelephoneWrapper>
                                }
                                <CirugiaInfoWrapper>
                                    <IconButton 
                                        to={canSeeAllCirugias ? `/app/cirugias/search`:''}
                                        state ={{
                                                    path:location.pathname,
                                                    search: `${paciente?.firstname}`
                                                }}                                                                        
                                        onClick={setScroll}    
                                    >
                                        <FontAwesomeIcon icon={faCalendarCheck} style={{fontSize: '1.8rem'}} color={theme.palette.grey[600]}/>                                        
                                    </IconButton>
                                    <IconButton onClick={()=>handleOnClick('editarPaciente', paciente)}>
                                        <FontAwesomeIcon icon={faPencilAlt} style={{fontSize: '1.4rem'}} color={theme.palette.grey[600]}/>                                        
                                    </IconButton>
                                </CirugiaInfoWrapper>
                                </CardBodyWrapper>
                            </CardBody>
                        </CardWrapper>                        
                    ))
                    
                }
                <br/>
             
            </Body>    
            
        </Wrapper>
        <Modal
            openModal={openModal}
            setOpenModal={setOpenModal} 
            inProp={inProp}
            setInProp={setInProp}
            close={close}
            smallDrawer = {smallBottomSwipeDrawers.includes(formType)}           
        >
            {formType === "addPaciente" && <CrearPaciente  close={close}/>}            
            {formType === "editarPaciente" && <CrearPaciente edit close={close} paciente={paciente}/>}                
            
        </Modal>             
        
    </ExternalWrapper>
    )
}



const ExternalWrapper = styled.div`
    height:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 36px;
    
`

const Wrapper = styled.div`
    width:100%;
    max-width: 1000px;
`

const Header = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-bottom: 16px;
`

const Body = styled.div`
    width:100%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 24px;

    @media ${p=>p.theme.media.tablet}{
        
    }

`


const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`

const SearchBarWrapper = styled.div`
    width:100%;
    display: flex;
    gap:8px;
    justify-content: center;
    align-items:center;    
`

const FilterWrapper = styled.div`
    display:flex;
    gap: 8px;
    flex-wrap: wrap;
    
`

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
`

const Name = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 1.1rem;

`

const CardBodyWrapper = styled.div`
    display:flex;
    gap: 24px;
    justify-content: space-between;

    @media ${p=>p.theme.media.tablet}{
        justify-content: space-between;
    }
`

const PacienteInfoWrapper = styled.div`
    display:flex;
    flex-direction: column;    
`

const TelephoneWrapper = styled.div`
    display: flex;
    align-items: center;
    gap:8px;
`

const CirugiaInfoWrapper = styled.div`
    display:flex;
    align-items:center;
`


export default PacientesList