import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { activateAccount } from '../../app/thunkActions/authActions';
import Button from '../Atoms/Button';



const ActivateAccount = ()=>{

    const dispatch = useDispatch()
    const token = useParams()

    const activate = ()=>{
        dispatch(activateAccount(token))
        
    }

    return (
        <Wrapper>
            <Text>Su email fue verificado. Por favor activa tu cuenta. </Text>
            <Button primary onClick={activate}>Activa tu Cuenta</Button>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display:grid;
    place-content: center;
    height: 100%;
`

const Text = styled.h3`
    margin: 0;
    margin-bottom: 100px;
`

export default ActivateAccount;