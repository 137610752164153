import { useCurrentUser } from "./useCurrentUser"


const useIsMd = ()=>{

    const user = useCurrentUser();

    return user?.role?.md
}

export default useIsMd;

