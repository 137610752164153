import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import Button from '../../Atoms/Button';



const CloudInfo = ()=>{
    const theme = useTheme();
    return(
        <MainRowWrapper>
        <Info style={{alignItems: "center"}}>
            <MainTitle style={{color: theme.palette.primary.dark, fontWeight:'300'}}>Toda la informacion relevante de la cirugia disponible para todo tu equipo en tiempo real.</MainTitle>
            <MainTitle style={{color: theme.palette.success.light}}>¡Todo en la nube!</MainTitle>
        </Info>
        <ImageWrapper>
            <Image  src="/img/ficha_cirugia.png"/>
        </ImageWrapper>
    </MainRowWrapper>   

    )
}


const MainRowWrapper = styled.div`
    display:flex;
    
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: ${p=>p.reverse ? 'column-reverse' : 'revert'};
    padding: 24px;

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
        padding: 32px;
        gap: 48px;
    }

`


const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 12px;    
    width:100%;
    padding: 8px;        
`


const MainTitle=styled.h1`
    margin:0;
    margin-bottom: 12px;
    text-align: center;
    color: ${p=>p.theme.palette.grey[800]};
    font-size: 2rem;

    @media ${p=>p.theme.media.laptop}{
        font-size: 2.5rem;
    }
`


const ImageWrapper = styled.div`
    flex: 1;
    width:100%;
    
    min-width:350px;
    display:flex;
    flex-direction: column;
    padding: 8px;
`

const Image = styled.img`    
    width:100%;
    height: 100%;
    object-fit:contain;
`



export default CloudInfo;