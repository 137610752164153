import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";
import { authenticate, setLocalStorage } from "../../shared/authHelpers";
import { loginSuccess, logoutSuccess, setConfiguration, setSubToken, updateUserState } from "../authReducer";
import { errorMessage, successMessage } from "../messagesReducer";
import { signout } from "../../shared/authHelpers";
import getRestrictedCirugias from "../../shared/getRestrictedCirugias";
import { setRestrictions } from "../filterReducer";


export const signUp = (values)=> async(dispatch)=>{

    const options = createOptions('POST',values)
      
    try{
        let response = await goFetch(apiUrl+ "md-users/signup",options, dispatch ); 
        console.log(response);       
    }catch(error){
        console.log(error);        
    }
    
    dispatch(successMessage("Solicitud recibida exitosamente. Te enviamos un email con la informacion para verificar tu email y activar tu cuenta."))
}


//md account
export const activateAccount = (values)=> async(dispatch)=>{

    const options = createOptions('POST',values)
      
    try{
        let response = await goFetch(apiUrl+ "md-users/account-activation",options, dispatch ); 
        console.log(response);       
    }catch(error){
        console.log(error);        
    }
    
    dispatch(successMessage("Solicitud recibida exitosamente. Te enviamos un email con la informacion para verificar tu email y activar tu cuenta."))
}



export const login = (values)=> async(dispatch)=>{

    const options = createOptions('POST',values)
      
    try{
        let response = await goFetch(apiUrl+ "users/login",options, dispatch );
        console.log(response);
        if(response.token){
            authenticate(response)
            dispatch(loginSuccess({token: response.token, user:response.user}))
            dispatch(setSubToken(response.subToken))
            dispatch(setConfiguration(response.configuration))
        }else if(response.error){

            dispatch(errorMessage(response.error))
        }
        
    }catch(error){
        console.log(error);
    }
    
}

export const loginGhost = (values)=> async(dispatch)=>{

    const options = createOptions('POST',values)
      
    try{
        let response = await goFetch(apiUrl+ "admin/external-login",options, dispatch );
        console.log(response);
        if(response.token){
            signout()
            dispatch(logoutSuccess())
            authenticate(response)
            dispatch(loginSuccess({token: response.token, user:response.user}))
            dispatch(setSubToken(response.subToken))
            dispatch(setConfiguration(response.configuration))
            const [restrictedVentasId, restrictedECId] =  getRestrictedCirugias()
            dispatch(setRestrictions({restrictedVentasId, restrictedECId}))
            
        }else if(response.error){

            dispatch(errorMessage(response.error))
        }
        
    }catch(error){
        console.log(error);
    }
    
}


export const updateProfileUser = values => async(dispatch)=>{

    const options = createOptions('PUT',values)

    try{
        let user = await goFetch(apiUrl + `users/update/${values.id}`, options)
        //update the local storage and the store since updating localstorage wont trigger store update/
        setLocalStorage('user', user);
        dispatch(updateUserState(user));
    }catch(error){
        console.log(error);
    }

}


export const updateMdProfileUser = values => async(dispatch)=>{

    const options = createOptions('PUT',values)

    try{
        let response = await goFetch(apiUrl + `md-users/update/${values.id}`, options)
        //update the local storage and the store since updating localstorage wont trigger store update/
        setLocalStorage('user', response.user);
        dispatch(updateUserState(response.user));
    }catch(error){
        console.log(error);
    }

}