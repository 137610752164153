import styled from '@emotion/styled';
import Chip from "../../Atoms/Chip";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '../../Atoms/Button';
import React from 'react';


const DateRangeFilter = ({
    openDatePicker,
    fechaFilter,
    onClickAplicar,
    startDate,
    handleStartDateChange,
    endDate,
    handleEndDateChange,
    
})=>{

    
    const startDateFormatted = startDate && (new Date(fechaFilter.startDate).getMonth()+ 1)+"/"+ new Date(fechaFilter.startDate).getDate() + "/" + new Date(fechaFilter.startDate).getFullYear();
    const endDateFormatted = endDate && (new Date(fechaFilter.endDate).getMonth() + 1) +"/"+ new Date(fechaFilter.endDate).getDate() + "/" + new Date(fechaFilter.endDate).getFullYear();

    return(
        <>
        { fechaFilter.startDate && fechaFilter.endDate && 
            <>
                <DateSelected>{`Rango seleccionado:`}</DateSelected>
                <Chip success label={startDateFormatted}/>                                
                <span>-</span>
                <Chip success label={endDateFormatted}/>                                
            </> 
         }
                                         
                        
                    

        { openDatePicker && 
        <>
        
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerWrapper>
                    <DatePicker
                    renderInput={(props) => <TextField {...props} size="small"/>}
                    label ="Principio de Rango"
                    value={startDate}
                    onChange={handleStartDateChange}
                    
                    >                    
                    </DatePicker>
                    <DatePicker
                    renderInput={(props) => <TextField {...props} size="small"/>}
                    label ="Final de Rango"
                    value={endDate}
                    onChange={handleEndDateChange}
                    ></DatePicker>
                    <Button primary onClick={onClickAplicar} >Aplicar</Button>
                    
                    
                </DatePickerWrapper>                    
            </LocalizationProvider> 
            
        </>
        }
    </>
    )    
        

}

const DatePickerWrapper = styled.div`
    margin-top: 12px;
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap:12px;
    width:100%;
`


const DateSelected = styled.p`
    margin: 4px 0px;
    
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[600]}
`




export default DateRangeFilter;