



export const isEmail = (value)=>{
    const valid =/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
    return !valid && value?.length > 0 ? "El formato del email debe ser - xxx@xxx.xxx" : "";
}


export const isCedula = (value, country='republica_dominicana')=>{
    console.log('In validator cedula', country)

    if(country === 'republica_dominicana'){
        console.log('checking cedula')
        const valid = /^[0-9]{3}-[0-9]{7}-([0-9]{2}|[0-9]{1})$/.test(value);
        return !valid && value?.length > 0 ? "El formato de la cedula debe ser - xxx-xxxxxxx-xx o xxx-xxxxxxx-x" : "";
    }
    
    if(country === 'mexico'){

            const valid = /^[A-Z]{4}\d{6}[HMhm]\w{2}[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9]\d$/.test(value);
            return !valid && value?.length > 0 ? "CURP invalido. Debe tener 18 caracteres(letras y numeros).": "";
 
        }
            
}

export const isNSS = (value)=>{
    const valid = /^([0-9]{9}|[0-9]{8}|[0-9]{7})$/.test(value);
    return !valid && value?.length > 0 ? "El NSS debe tener entre 7 y 9 digitos": "";
}

//check if rnc 130-96324-1 
export const isRNC = (value)=>{
    const valid = /^[0-9]{3}-[0-9]{5}-[0-9]{1}$/.test(value);
    return !valid && value?.length > 0 ? "El formato del RNC debe ser - xxx-xxxxx-x" : "";
}

export const isLength = (value)=>{
    return value?.length > 20 ? "Campo debe tener menos de 20 caracters" : "";
}

export const isMaxLength = (max,value)=>{   
    console.log(max,value) 
    return value?.length > max ? `Campo debe tener menos de ${max} caracters` : "";
}

export const isCotizacion = (value)=>{
    const valid = /^[0-9]{0,9}$/.test(value)
    return !valid && value?.length > 0 ? "Maximo de 9 digitos permitidos.": "";
}

export const isTelefono = (value, country='republica_dominicana')=>{

    if(country === 'republica_dominicana'){
        const valid = /^\(?[0-9]{3}\)?-?[0-9]{3}-?[0-9]{4}$/.test(value)
        return !valid && value?.length > 0 ? "El formato del telefono debe ser (xxx)xxx-xxxx": "";
    }
    
    if(country === 'mexico'){        
        const valid = /^(\+?52[-\s]?)?(1[-\s]?)?\d{2}[-\s]?\d{2}[-\s]?\d{2}[-\s]?\d{2}[-\s]?\d{2}$/.test(value);
        return !valid && value?.length > 0 ? "El formato del telefono debe ser: xx xxxx xxxx" : "";
    }
}

export const isPassword = (value)=>{
    console.log('checking password')
    return value?.length < 6 ? 'Contrasena debe tener por lo menos 6 caracteres': "" 
}

//check if value is a number or a string with digits, commas and decimals
export const isNumber = (value)=>{
    const valid = /^-?\d{1,3}(,\d{3})*(\.\d{1,2})?$|^[-]?\d+(\.\d{1,2})?$/.test(value);
    console.log(valid)

    if(!valid && value?.length > 0){
        return "Solo se permiten numeros";  
    }else{
        return "";
    }
}

//check if value is in the range of min and max. Allow for a number or a string

export const isInRange = (min, max, value)=>{
    // First, check if the value is a number
  if (typeof value === "number") {
    // Check if the value is between 0 and 100
    if(value < min || value > max){
        return `El valor debe estar entre ${min} y ${max}`
    }else{
        return "";
    }    
  }

  // If the value is not a number, try to convert it to a number
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return "El valor debe ser un numero";
    // If the value cannot be converted to a number, it is not a valid number    
  }

  if(numberValue < min || numberValue > max){
    return `El valor debe estar entre ${min} y ${max}`
    }else{
        return "";
    }  
}






export const validate =(value, validators)=>{
    
    const errors = validators.map((validator)=>{
                if(value){                    
                    return validator(value) ;
                }else{                    
                    return validator() ;
                }
                
    })
    return errors[0];
}