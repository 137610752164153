import styled from "@emotion/styled/macro";
import { faUserCircle, faPencil, faEdit, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/system";
import {Menu,MenuButton,} from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizontal from "../../Icons/MoreHorizontal";
import { StyledMenuButton, StyledMenuItem, StyledMenuLink, StyledMenuList } from "../../Atoms/StyledDropdownReach";
import { errorMessage, successMessage } from "../../../app/messagesReducer";
import { updateProductosArray } from "../../../app/productosReducer";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";



const MoreButtonDoctorProfile= ({
    setOpenModal,
    setFormType,
        
})=>{
    
    const theme = useTheme();
    let buttonColor= theme.palette.grey[600];
    let buttonColorHover= theme.palette.grey[400];
    let textColorHover = "white"
    

    const onSelect = (formType)=>{
        setOpenModal(true);
        setFormType(formType);               
       
      }

    
    return(
        <Menu>
        {({isExpanded})=>{              
            isExpanded ? document.querySelector('body').style.overflow='hidden' :
                        document.querySelector('body').style.overflow='visible'
            return(
                <>
                <StyledMenuButton
                style={{
                    "--button-color": buttonColor,
                    "--button-color-hover": buttonColorHover,
                    "--text-color-hover": textColorHover
                }}   >
                    <ButtonWrapper>
                        <FontAwesomeIcon icon={faEdit} size={'1rem'} color={theme.palette.grey[600]}/>
                        <ButtonText>Editar</ButtonText>
                    </ButtonWrapper>
                    
                    
                </StyledMenuButton>
                <StyledMenuList>                                
                    <StyledMenuItem onSelect={()=>onSelect('editar')}>
                        <MenuItemWrapper>                            
                            <Text>Info. General</Text>
                        </MenuItemWrapper>
                    </StyledMenuItem>
                    <StyledMenuItem onSelect={()=>onSelect('editarVentas')}>
                        <MenuItemWrapper>
                            
                            <Text>Ventas</Text>
                        </MenuItemWrapper>
                    </StyledMenuItem>                          
                    <StyledMenuItem onSelect={()=>onSelect('editarCentros')}>
                        <MenuItemWrapper>
                            
                            <Text>Centros</Text>
                        </MenuItemWrapper>
                    </StyledMenuItem>                                                                        
                </StyledMenuList>
            </>
            )
        }}       
    </Menu>
    )
}


const MoreButton = styled(MenuButton)`
    border-radius: 50%;
    font-size: 1.5rem;
    padding: 12px;
    text-align:center;
    background-color: ${p=>p.color ? p.color:'transparent'};
    border:0;
    outline:0;
    display: inline-flex;
    align-self: center;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }

`

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Text = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[800]}
`

const ButtonWrapper = styled.div`
    display:flex;
    gap: 8px;
`

const ButtonText = styled.p`
    margin:0;
    color: ${p=>p.theme.palette.grey[800]};
    font-weight: 500;
`

export default MoreButtonDoctorProfile;