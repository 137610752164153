import styled from '@emotion/styled';
import React from 'react';

const BackDrop = ({onClick})=>{

    React.useEffect(()=>{
        //freeze scrolling
        document.querySelector('body').style.overflow='hidden' 
        return ()=>document.querySelector('body').style.overflow='visible'
    },[])
                        
    return <StyledBackDrop onClick={onClick}/>
}



const StyledBackDrop = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:-50px;
    background-color: hsla(360, 0%, 29%, 0.77); 
`

export default BackDrop;