import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from "../../Atoms/Chip";
import styled from '@emotion/styled';

const EspecialidadSelect = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name,    
})=>{


    return(
        <FormControl fullWidth>
            <CustomInputMuiLabel>{label}</CustomInputMuiLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}                
                onChange={onChange}
                name="especialidad"
            >
                <MenuItem value="Neurocirugia">Neurocirugia</MenuItem>
                <MenuItem value="Maxilo">Maxilo</MenuItem>
                <MenuItem value="Cirugia General">Cirugia General</MenuItem>
                <MenuItem value="Artroscopia">Artroscopia</MenuItem>
                <MenuItem value="Ortopedia">Ortopedia</MenuItem>
                <MenuItem value="Ginecologia">Ginecologia</MenuItem>
                <MenuItem value="Urologia">Urologia</MenuItem>
                <MenuItem value="Bariatrico">Bariatrico</MenuItem>
                <MenuItem value="ORL">ORL</MenuItem>
                <MenuItem value="Columna">Columna</MenuItem>
            </Select>
        </FormControl>


    )
}


export const EspecialidadSelectMultiple = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name,    
})=>{

    const especialidades = [
        'Neurocirugia',
        'Maxilo',
        'Cirugia General',
        'Artroscopia',
        'Ortopedia',
        'Ginecologia',
        'Urologia',
        'Bariatrico',
        'ORL',
        'Columna'
    ]


    return(
        <FormControl fullWidth>
            <CustomInputMuiLabel>{label}</CustomInputMuiLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}                
                onChange={onChange}
                name="especialidad"
                multiple
                renderValue = {(selected)=>(
                    <Container>
                        {selected.map((value)=>(
                            <Chip primary key={value} outline label={value}/>
                        ))}
                    </Container>
                )}
            >
                {
                    especialidades.map((especialidad, index)=>(
                        <MenuItem key={`${especialidad} ${index}`}value={especialidad}>{especialidad}</MenuItem>        
                    ))
                }                
            </Select>
        </FormControl>


    )
}


const Container = styled.div`
    display: flex;
    padding-top: 8px;
    gap: 4px;
    flex-wrap: wrap;

`
export default EspecialidadSelect