import React from "react";
import useSuccessButton from "../../../hooks/useSuccessButton";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import CentrosAutocomplete from "../../Forms/CustomAutocomplete/CentrosAutocomplete";
import DoctorsAutocomplete from "../../Forms/CustomAutocomplete/DoctorsAutocomplete";



const DoctorAndCentroInput = ({
    setDoctor,
    setCentro,
    close
}) => {


    const [values, setValues] = React.useState({
        doctor: "",
        centro: ""
    })


    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()

    const onChangeValuesId = (name, option, type)=>{
        console.log('Running on change')

        console.log(option)

        let info = {
            _id: option._id,
            firstname: option.firstname,
            secondname: option.secondname,
            lastname: option.lastname,
            secondLastname: option.secondLastname,
        }

        if(type ==="doctors") info.mdId = option?.connected?.mdId

        setValues(prevState => ({...prevState, [name]:info}))
        setValues(prevState=> {console.log(prevState); return prevState})
    }


     //version for MUI Autocomplete
     const onChangeValuesAutocomplete = (event, option, name)=>{

        let info;

        if(name==="centro" && option){
            info = {
                _id : option._id,
                nombre : option.nombre,
                ciudad : option.ciudad,
                zona : option.zona 
            }            
        }else if(option){
            info = {
                _id: option._id,
                firstname: option.firstname,
                secondname: option.secondname,
                lastname: option.lastname,
                secondLastname: option.secondLastname
            }
        }

        if(option){
            setValues(prevState => ({...prevState, [name]:info}))
        }else{
            setValues(prevState => ({...prevState, [name]:""}))
        }
        
    }

    const onSubmit = (e)=>{
        //e.preventDefault() was causing the modal to close
        if(values.doctor){
            setDoctor(values.doctor)
        }

        if(values.centro){
            setCentro(values.centro)
        }
        close()
    }




    return (
        <FormWrapper>
            <FormTitle>Seleccion Doctor y Centro</FormTitle>
            <FormBody>
                <DoctorsAutocomplete onChange={(event, option)=>onChangeValuesId(event, option, 'doctors')}/>
                <CentrosAutocomplete onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "centro")} />   
                <div>{`${values?.doctor.firstname || "" }  ${values?.doctor.lastname || ""}`}</div>
                <div>{`${values?.centro.nombre || ""}`}</div>             
            </FormBody>
            <FormActionsBasic
                close={close}                
                successText="Aceptar"
                successEvent={successEvent}
                loading={isLoading}
                onSubmit={onSubmit}                             
           />

        </FormWrapper>
    )
}




export default DoctorAndCentroInput;