import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react';



const placeholderShimmer = keyframes`

0% {
    transform: translateX(-100%);
  }
  
  100% {
    transform: translateX(100%);
  }


`

const Loading = ({
    className
})=>{


    return (
        <Wrapper className={className}>
            <Shimmer/>
        </Wrapper>
    )
}


const Wrapper = styled.div`    
    width: 100%;
    height: 50px;
    overflow:hidden;
    background: ${p=>p.theme.palette.grey[200]};  
    
`


const Shimmer = styled.div`
    height:100%;
    width:100%;
    background: linear-gradient(to right,${p=>p.theme.palette.grey[200]},${p=>p.theme.palette.grey[300]},${p=>p.theme.palette.grey[200]});  
    animation: ${placeholderShimmer} 2s linear infinite;  

`

/*const Shimmer= styled.div`
background: ${p=>p.theme.palette.grey[200]};
background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
background-repeat: no-repeat;

display: inline-block;
position: relative; 
animation: ${placeholderShimmer} 2s linear infinite;

    
    height: 100%;
    width: 100%;
`
*/
export default Loading;