import React from 'react';
import styled from '@emotion/styled/macro';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';
import useCirugia from '../../hooks/useCirugia';
import TextInput from '../Atoms/TextInput';



const ConcluirCirugia = ({
    cirugiaId,
    close,
    setOpenModal
})=>{

    const isTablet = useMediaQuery('tablet');
    const [startTime, handleStartTimeChange] = React.useState(new Date());
    const [endTime, handleEndTimeChange] = React.useState(new Date());
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);    
    const cirugia = useCirugia(cirugiaId);
    const [confirmacion, setConfirmacion] = React.useState("");
    
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker(); 
  
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker('Cirugia concluida');

        let infoConcluir = {
            startTime: startTime,
            endTime: endTime,
            realizada: true,
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion: accion
        }
    
        updateCirugia('concluir-cirugia', infoConcluir)
  }

  const onChangeHandler = (event)=>{
    setConfirmacion(event.target.value)
}

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>{`Concluir Cirugia de ${cirugia?.paciente?.firstname} ${cirugia?.paciente?.lastname}`}</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePickerWrapper>                        
                            <TimePicker
                                label="Comienzo Cirugia"
                                value={startTime}
                                onChange={handleStartTimeChange}
                                renderInput={(params) => <TextField sx={{width:'100%'}} {...params} />}
                            />                        
                            <TimePicker
                                label="Final Cirugia"
                                value={endTime}
                                onChange={handleEndTimeChange}
                                renderInput={(params) => <TextField sx={{width:'100%'}} {...params} />}
                            />                                                                           
                    </TimePickerWrapper>
                    
                </LocalizationProvider>
                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                /> 
                <Text>Por favor confirmar que desea concluir esta cirugia escribiendo la palabra <StyledConcluir>concluir.</StyledConcluir></Text>
                <TextInput
                    label="Concluir"
                    value={confirmacion}
                    onChange={onChangeHandler}
                />                               
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={confirmacion.toLowerCase() !=="concluir"}
           />
            
        </FormWrapper>
    )
}

const TimePickerWrapper = styled.div`
    display:flex;
    gap: 8px;
    
`

const Text=styled.p`
    margin:0;
    margin-bottom: 8px;
`

const StyledConcluir = styled.span`
    font-weight:700;
    font-style: italic;
`



export default ConcluirCirugia