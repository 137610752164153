import React from 'react';

import { FormWrapper, FormTitle, FormBody} from '../../Atoms/Forms';
import { TextField } from '@mui/material';
import styled from '@emotion/styled/macro';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import useSuccessButton from '../../../hooks/useSuccessButton';
import useMediaQuery from '../../../hooks/useMediaQuery';


const NotaCotizacion = ({
    nota,
    setNota,
    close
})=>{

    const isTablet = useMediaQuery('tablet');    
    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    
    const onSubmit = (event) =>{
        event.preventDefault();        
        close()                         
    }        
      
       
    

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Nota</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>                
                <StyledTextField
                    label="Nota"
                    sx={{fontSize:'3rem'}}
                    multiline
                    variant='outlined'
                    rows={4}
                    value={nota}
                    onChange={(e)=>setNota(e.target.value)}
                />                                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                disabledSuccess={nota?.length===0}
                successText="Guardar"
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


const Text=styled.p`
    margin:0;
    margin-bottom: 8px;
`

const StyledTextField = styled(TextField)`
    & .MuiInputLabel-root{
        font-size: 0.875rem;
        font-weight: 500;
    };
    & fieldset{
        border-radius:12px;
        font-size:1rem;
        top:0;
    };
`

const StyledSuspender = styled.span`
    font-weight:700;
    font-style: italic;
`


export default NotaCotizacion