import styled from '@emotion/styled';
import React from 'react';
import TextInput from '../Atoms/TextInput';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import Button from '../Atoms/Button';
import useForm from '../../hooks/useForm';
import { isLength } from '../../shared/validators';
import ProductosAutocompleteAndList from '../molecules/ProductosAutocompleteAndList';
import useSuccessButton from '../../hooks/useSuccessButton';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';

const CrearRegla = ({
    reglas,
    setReglas,
    close}) => {

const [values, setValues] = React.useState({
    nombreGrupo: "",
    numeroCirugiasDia: "",
})
const initialStateProductos = []
const [productos, setProductos] = React.useState(initialStateProductos)

const [errors, setErrors] = React.useState({})
const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

const {onChangeHandler, onChangeValuesValidation, onBlurValidate} = useForm({setValues, setErrors, errors})

console.log(initialStateProductos)
console.log(values)


const onSubmit = async(e)=>{
    e.preventDefault();

    const reglaInfo = {
        nombreGrupo: values.nombreGrupo,
        numeroCirugiasDia: values.numeroCirugiasDia,
        productos: productos
    }

        setIsLoading(true)
        const options = createOptions('POST', reglaInfo)
        let response;
          
        try{
            console.log('Trying to create regla')
            response = await goFetch(apiUrl+ "agenda/crearRegla", options );
            console.log(response);

            //add regla to reglas
            const updatedReglas = [...reglas, response.regla]

            setReglas(updatedReglas)

            responseStateChanges(response,null,()=>close())    
        }catch(error){
            console.log(error);
        }
    
    console.log(values) 
}

  return (
    
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Crear Regla</FormTitle>
            <FormBody>
            <TextInput
                    label="Nombre del Grupo" name="nombreGrupo" id="nombreGrupo"
                    value={values.nombreGrupo}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isLength])}
                    helperText={!errors.nombreGrupo ? "Campo Obligatorio": errors.nombreGrupo}
                    error={errors.nombreGrupo}
                    onBlurValidation={(event)=>onBlurValidate(event, [isLength])}
                />
                <TextInput 
                    label="Numero de cirugias por dia" 
                    name="numeroCirugiasDia"
                    id="numeroCirugiasDia"
                    value={values.numeroCirugiasDia} 
                    onChangeHandler={(event)=>onChangeHandler(event)}
                    type="number"
                    helperText={!errors.numeroCirugiasDia ? "Campo Obligatorio": errors.numeroCirugiasDia}
                />
                <ProductosAutocompleteAndList productos={productos} setProductos={setProductos}/>   
            </FormBody>  

            <FormActions>
                <Button
                as={"button"}
                fullsize={true}
                success
                type="submit"
                disabled={false}
                loading={isLoading}
                successEvent={successEvent}                      
                >Crear Regla</Button>
           
            </FormActions> 
                  
        </FormWrapper>             
    
  );
};





export default CrearRegla;
