import React from 'react';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import ResultBox, { ResultBoxGrid } from '../Atoms/ResultBox';





const ResultsCardGrid = ({
    search
})=>{

    const [cirugiaData, setCirugiaData] = React.useState()

    React.useEffect(()=>{
        
        const fetchData = async()=>{
            const options = createOptions('POST', search)
            let _cirugiaData;
                
            try{
                _cirugiaData = await goFetch(apiUrl+ "cirugias/cirugia/data", options );
                setCirugiaData(_cirugiaData);
            }catch(error){
                console.log(error);
            }

        }

        fetchData();
        
    },[search])


    let countCurrentMonth = cirugiaData?.countCurrentMonth || 0;
    let countLastMonth = cirugiaData?.countLastMonth || 0;
    let count3Months = cirugiaData?.count3Months || 0;
    let count6Months = cirugiaData?.count6Months || 0;
    let count12Months = cirugiaData?.count12Months || 0;

    return(
        <ResultBoxGrid>
            <ResultBox title="Mes Actual" result={countCurrentMonth} category="Cirugias"/>
            <ResultBox title="Mes Anterior" result={countLastMonth} category="Cirugias"/>
            <ResultBox title="3 meses" result={count3Months} category="Cirugias"/>
            <ResultBox title="6 meses" result={count6Months} category="Cirugias"/>
            <ResultBox title="12 meses" result={count12Months} category="Cirugias"/>
            
        </ResultBoxGrid>
    )


}





export default ResultsCardGrid;