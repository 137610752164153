import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { errorMessage } from '../../app/messagesReducer';
import useSuccessButton from '../../hooks/useSuccessButton';
import { apiUrl } from '../../shared/url';
import Button from '../Atoms/Button';
import CardSuccess from '../Atoms/CardSuccess';
import TextInput from '../Atoms/TextInput';

const EmailLinkLoginRequest = ()=>{

    const dispatch = useDispatch();

    const [email, setEmail] = React.useState('')
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const [requestSent, setRequestSent] = React.useState(false)

    //For plain textinput and select fields
    const onChangeHandler = (event)=>{        
        const value = event.target.value;        
        setEmail(value);
    }


    const onClickHandler = async()=>{
        //dispatch(login(loginValues));

        setIsLoading(true)
        const options = createOptions("POST", {email})
        try {
            
            const response = await goFetch(`${apiUrl}users/email-link-login-request`, options)
            responseStateChanges(response) 
            console.log(response)
            if(!response.error){
                setRequestSent(true);
            }   
            
        } catch (error) {
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."))
            console.log(error)
        }
        

    }

    return(

        <>
        {
            !requestSent ?
            <>
                <Title>Ingresa el email relacionado a tu cuenta y te enviaremos un enlace para que puedas iniciar session.</Title>
                <TextInput label="Email" name="email" value={email} onChange={(onChangeHandler)}/>                        
                
                <StyledButton 
                    success 
                    onClick={onClickHandler}
                    loading={isLoading}
                    successEvent={successEvent}

                >Solicitar Enlace para Iniciar Session</StyledButton>                                      
            </>
            :
            <CardSuccess>
                Email enviado con enlace para inicar session. 
            </CardSuccess>    
        }
        
        </>
    )

}



const Title = styled.h1`
    margin:0;
    margin-bottom: 24px;
    align-self: center;
    font-size: 1rem;
    
`




const StyledButton = styled(Button)`
    margin-top: 24px;
    border-radius: 8px;
    max-height: revert;
    height: 45px;
`





export default EmailLinkLoginRequest;