import styled from '@emotion/styled/macro';
import React from 'react';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import LabelValue from '../../Atoms/LabelValue';
import { FormControlRadio, Radio } from '../../Atoms/Radio';
import DoctorsAutocomplete from '../../Forms/CustomAutocomplete/DoctorsAutocomplete';
import User from '../../Icons/User';
import DoctorCentrosList from './DoctorCentrosList';
import CardSuccess from '../../Atoms/CardSuccess'
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../app/messagesReducer';


const DoctorFusionar = ({
    doctor,
    close
})=>{

    const user = useCurrentUser();
    const dispatch = useDispatch();

    const [doctorToUpdate, setDoctorToUpdate]= React.useState({});
    const [change, setChange] = React.useState();
    const notification = React.useRef();
    const isEmpty = Object.keys(doctorToUpdate).length === 0;
    const disabled = isEmpty || !change;
    const {isLoading, successEvent, responseStateChanges} = useSuccessButton()

    React.useEffect(()=>{
        
            notification?.current?.scrollIntoView({
                behavior: 'smooth'
            })
        
    },[disabled])

    const onChangeValuesId = (name, option)=>{
        console.log('Running on change')
        console.log(option);

        let info = {
            _id: option._id,
            firstname: option.firstname,
            secondname: option.secondname,
            lastname: option.lastname,
            secondLastname: option.secondLastname,
            especialidad: option.especialidad,
            centros: option.centros
        }

        //setDoctorToUpdate(prevState => ({...prevState, [name]:info}))
        setDoctorToUpdate(info)
    }


    const onChangeRadio = (event)=>{
        console.log("Radio triggered")
        setChange(event.target.value)

    }


    const onSubmit = async(event)=>{
        event.preventDefault();
        let onlyEmail;
        let doctorToUpdateId = doctorToUpdate._id;
        if(change === "onlyEmail") onlyEmail= true;
        const fusionarInfo = {...doctor, onlyEmail, doctorToUpdateId, companyName: user.company.name}
        const options = createOptions('POST', fusionarInfo)
        try {
            const response = await goFetch(`${apiUrl}doctors/fusionar-doctor`, options)
            responseStateChanges(response,null, ()=>close())    
        } catch (error) {
            console.log(error)            
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }
        
    }
    

    

    console.log(doctorToUpdate)
    console.log(isEmpty)
    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle>Fusionar Doctor</FormTitle>
            <StyledFormBody>
                <Text>Elige el doctor que quieres fusionar.</Text>
                <DoctorsAutocomplete onChange={onChangeValuesId}/>                
                
            { !isEmpty &&
            <>
                <Text>Informacion en base de datos:</Text>    
                <InfoWrapper>
                    <LabelValue label="Email: " value={doctorToUpdate?.email || ""}/>
                    <LabelValue label="Nombre: " value={`${doctorToUpdate.firstname || ""} ${doctorToUpdate.lastname || ""}`}/>
                    <LabelValue label="Especialidad: " value={`${doctorToUpdate?.especialidad || ""}`}/>
                    <LabelValue label="Centros:  " value={<DoctorCentrosList doctor={doctorToUpdate} size="small"/>}/>                    
                </InfoWrapper>

                <Text>Informacion nueva:</Text>    
                <InfoWrapper>
                    <LabelValue label="Email: " value={doctor?.email || "No disponible"}/>
                    <LabelValue label="Nombre: " value={`${doctor?.firstname} ${doctor?.lastname}` || "No disponible"}/>
                    <LabelValue label="Especialidad: " value={`${doctor?.especialidad || ""}`}/>
                    <LabelValue label="Centros:  " value={<DoctorCentrosList doctor={doctor} size="small"/>}/>                    
                </InfoWrapper>
                                                                                    
                <Text>Selecciona que informacion quieres fusionar:</Text>                    
                <RadioButtonWrapper>                
                    <FormControlRadio 
                        value={change}
                        onChange={onChangeRadio}
                    >
                        <Radio
                            label="Solo email"
                            id="onlyEmail"
                            name="onlyEmail"
                            value="onlyEmail"
                            checked={change === "onlyEmail" ? true: false}
                        />
                        <Radio
                            label="Toda la informacion"
                            id="all"
                            name="all"
                            value="all"   
                            checked={change === "all" ? true: false}                     
                        />                
                 </FormControlRadio>        
                 
                </RadioButtonWrapper>
                {!disabled && <StyledCardSuccess><TextGreen ref={notification}>{`Al fusionar enviaras una invitacion al doctor a conectarse con ${user.company.name} `}</TextGreen></StyledCardSuccess>  }                                         
            </>
            }
            </StyledFormBody>
            <FormActionsBasic
                successText={"Fusionar doctor"}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                close={close}
                disabledSuccess={disabled}
            />

            
        </StyledFormWrapper>
    )


}


const StyledFormWrapper = styled(FormWrapper)`

    
    @media ${p=>p.theme.media.tablet}{
        min-width: 500px;
        min-height: 400px;
    }
`

const StyledFormBody = styled(FormBody)`
    

    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 16px;        
    }
`

const Text = styled.p`
    margin: 0;
    font-size: 0.95rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]};
    background-color: ${p=>p.theme.palette.grey[200]};
    padding: 8px;
    border-radius: 4px;
`

const InfoWrapper = styled.div`
    width:100%;
    align-self: center;
    display:flex;
    flex-direction: column;
    margin-left: 8px;
`

const RadioButtonWrapper = styled.div`
    margin-bottom: 16px;

    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 0px;
        
    }
`

const TextGreen = styled(Text)`
    color: ${p=>p.theme.palette.success.light};
    background-color: ${p=>p.theme.palette.success.pastel};
    font-weight: 700;
    margin-bottom: -8px;
    margin-top: -8px;
`
const StyledCardSuccess = styled(CardSuccess)`
    margin-bottom: 16px;
`



export default DoctorFusionar;