import React from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import TextInput from "../../Atoms/TextInput"
import useForm from "../../../hooks/useForm";
import useCheckForm from "../../../hooks/useCheckForm";
import { isLength, isMaxLength } from "../../../shared/validators";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import useSuccessButton from "../../../hooks/useSuccessButton";
import CiudadesSelect from "./CiudadesSelect";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../app/messagesReducer";
import { fetchCentros } from "../../../app/thunkActions/centrosActions";




const CentroCrear = ({
    edit,
    close
}) => {

    const dispatch = useDispatch();
    const isTablet = useMediaQuery('tablet');
    const configuration = useConfiguration()
    const [values,setValues] = React.useState({
        nombre: '',
        ciudad: '',
        zona: '',
        pais: configuration?.country || "republica_dominicana",
    })

    const [errors, setErrors] = React.useState({})
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const {onChangeHandler, onChangeValuesValidation, onBlurValidate} = useForm({setValues, setErrors, errors})
    const [formCompleted, errorCount] = useCheckForm(values,errors, ["nombre", "ciudad"]);
    const accionMaker = useAccionMaker()
    console.log(errors)

    const onSubmit = async (event)=>{

        event.preventDefault(); 
        setIsLoading(true)
        const accion = accionMaker('Centro creado')
        const centroInfo = {
            ...values,
            accion
        }
        const options = createOptions("POST",centroInfo)
        try {
            const response = await goFetch(`${apiUrl}centros`,options)
            responseStateChanges(response, ()=>dispatch(fetchCentros()), ()=>close())                                
        } catch (error) {
            dispatch(errorMessage('Error al craer el centro'))
        }
        
    }



    return (
        <FormWrapper onSubmit={onSubmit}>
             <FormTitle isTablet={isTablet}>{!edit ? "Crear Centro": "Editar Centro" }</FormTitle>
            
            <FormBody isTablet={isTablet}>
                <TextInput
                    label="Nombre" name="nombre" id="nombre"
                    value={values.nombre}                    
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[()=>isMaxLength(50, event.target.value)])}
                    helperText={!errors.nombre ? "Campo Obligatorio": errors.nombre}
                    error={errors.nombre}
                    
                />
               <CiudadesSelect
                    label="Ciudad"
                    id="ciudad"
                    name="ciudad"
                    value={values.ciudad}
                    onChange={onChangeHandler}
               />
               
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={!formCompleted || errorCount>0}
            />
        </FormWrapper>
    )




}

export default CentroCrear;