import styled from '@emotion/styled/macro';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../../app/thunkActions/authActions';
import useCheckForm from '../../hooks/useCheckForm';
import { isEmail, isLength, validate } from '../../shared/validators';
import BackButton from '../Atoms/BackButton';
import Button from '../Atoms/Button';
import { CardActions, CardBody, CardHeader, CardTitle, CardWrapper } from '../Atoms/Card';
import TextInput from '../Atoms/TextInput';
import ChevronLeft from '../Icons/ChevronLeft';
import ChevronRight from '../Icons/ChevronRight';
import AlertMessages from '../molecules/AlertMessages';


const RegisterMd =()=>{
    const theme =useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = {
        email: "",
        firstname: "",
        secondname: "",
        lastname: "",
        secondLastname: "",
        password: "",
        passwordCheck: ""
      }

    const [next, setNext] = React.useState(false);  
    const [values, setValues] = React.useState(initialState);
    

    const [errors, setErrors] = React.useState({
        email: ""
    })

    

      const [formCompleted, errorCount] = useCheckForm(values,errors, [
        "email",
        "firstname",
        "lastname"
        ]);

    const onChangeHandler = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setValues(prevState => ({...prevState, [name]:value}))
    }    

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

    const passwordCheckFunc = ()=>{
        if(values.passwordCheck){
            if(values.passwordCheck === values.password){
                if(values.password){
                    setErrors(prevState=>({...prevState, passwordCheck:""}))    
                }
            }
            else{ 
                console.log("Password does not match")
                setErrors(prevState=>({...prevState, passwordCheck:"Contraseñas no es igual."}))
            } 
        }

    }

    const onSubmit = (event)=>{
        event.preventDefault();
        console.log(values)
        dispatch(signUp(values));
        setValues(initialState);
    }

    return(
        <Wrapper>
            <BackButton style={{alignSelf: "start"}} onClick={()=>navigate(-1)}/>
            <HeaderWrapper>                                        
                    <Logo src='/img/xirugias_logo_512x512.png' alt="company logo"/>                    
            </HeaderWrapper>
            <Card>
                <CardHeader>
                    <CardHeaderWrapper>
                        <StyledCardTitle>Registro para Personal Medico</StyledCardTitle>
                        <CardSubtitle>Si eres medico cirujano o trabajas con un cirujano para agendar o gestionar sus cirugias, esta es la cuenta que debes abrir. </CardSubtitle>
                        <CardSubtitle>Si trabajas para una casa comercial debes pedir una invitacion. </CardSubtitle>
                    </CardHeaderWrapper>
                    
                </CardHeader>
                <CardBody>
                                    
                    <Container>
                        { !next &&                         
                            <>
                            <TextInput 
                                label="Email"                        
                                name="email"
                                id="email"
                                value={values.email}
                                onChangeHandler={onChangeHandler}
                                helperText={!errors.email ? "Campo Obligatorio": errors.email}
                                error={errors.email}
                                onBlurValidation={(event)=>onBlur(event, [isEmail])}
                            />
                            <TextInput 
                                label="Primer Nombre"
                                name="firstname"
                                id="firstname"
                                value={values.firstname}
                                onChangeHandler={onChangeHandler}
                                helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                                error={errors.firstname}
                                onBlurValidation={(event)=>onBlur(event, [isLength])}
                            />
                            <TextInput 
                                label="Segundo Nombre"
                                name="secondname"
                                id="secondname"
                                value={values.secondname}
                                onChangeHandler={onChangeHandler}
                                helperText={errors.secondname && errors.secondname}
                                error={errors.secondname}
                                onBlurValidation={(event)=>onBlur(event, [isLength])}
                            />
                            <TextInput 
                                label="Apellido"
                                name="lastname"
                                id="lastname"
                                value={values.lastname}
                                onChangeHandler={onChangeHandler}
                                helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                                error={errors.lastname}
                                onBlurValidation={(event)=>onBlur(event, [isLength])}
                            />
                            <TextInput 
                                label="Segudo Apellido"
                                name="secondLastname"
                                id="secondLastname"
                                value={values.secondLastname}
                                onChangeHandler={onChangeHandler}
                                helperText={errors.secondLastname && errors.secondname}
                                error={errors.secondLastname}
                                onBlurValidation={(event)=>onBlur(event, [isLength])}
                            />
                        </>
                        }
                        {
                        next && 
                            <>
                                <TextInput 
                                    label="Contraseña"                        
                                    name="password"
                                    id="password"                                
                                    value={values.password}
                                    onChangeHandler={onChangeHandler}
                                    helperText={!errors.password ? "Campo Obligatorio": errors.password}
                                    error={errors.password}
                                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                                />
                                <TextInput 
                                    label="Confirmar Contraseña"                        
                                    name="passwordCheck"
                                    id="passwordCheck"                                
                                    value={values.passwordCheck}
                                    onChangeHandler={onChangeHandler}
                                    helperText={!errors.passwordCheck ? "Campo Obligatorio": errors.passwordCheck}
                                    error={errors.passwordCheck}
                                    onBlurValidation={(event)=>passwordCheckFunc()}
                                />
                            </>
                        }
                    </Container>
                </CardBody>
                <CardActions justifyContent="flex-end">  
                    <CardActionWrapper>
                        {next && <Button outline preIcon={<ChevronLeft color={theme.palette.grey[500]}/>} onClick={()=>setNext(false)}>Volver</Button>}
                        {!next && <Button primary postIcon={<ChevronRight color={"#ffff"} /> } onClick={()=>setNext(true)}>Siguiente</Button>}
                        {next && <Button success onClick={onSubmit}>Enviar</Button>}
                        
                    </CardActionWrapper>                  
                    
                </CardActions>                
            </Card>
            <AlertMessages/>
        </Wrapper>
    )

}

const HeaderWrapper = styled.div`
    width:100%;
    max-width: 1250px;
    max-height: 150px;
    display:flex;
    align-items: center;
    justify-content: center;
`

const Logo = styled.img`    
    max-width: 175px;
    width: 100%;
    height: 100%;
    object-fit:contain;
    @media ${p=>p.theme.media.laptop}{
        max-width: 200px;
    }
`

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100%;
    background-color: #ffff;
`

const Card = styled(CardWrapper)`
    min-width: 350px;
    max-width: 500px;
    
    border-radius: 12px;
    background-color: ${p=>p.theme.palette.grey[100]}
`

const StyledCardTitle = styled(CardTitle)`
    margin-bottom: 8px;
    color: ${p=>p.theme.palette.grey[800]}
`

const CardSubtitle = styled.h5`
    margin: 0px;
    color: ${p=>p.theme.palette.grey[600]}
`

const CardHeaderWrapper = styled.div`
    display:flex;
    flex-direction: column;
    padding: 8px 0px;
    
`

const CardActionWrapper = styled.div`
    display: flex;
    gap: 8px;

`

const Container = styled.div`
    display:flex;
    flex-direction: column;
    gap: 8px;
`




export default RegisterMd;