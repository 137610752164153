import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import UploadCloud from '../Icons/UploadCloud';
import IconButton from './IconButton';
import BeatLoader from 'react-spinners/BeatLoader'


const UploadButton = ({
    id,
    name,
    onChange,
    multiple,
    disabled,
    children,
    loading=false
})=>{

    const theme = useTheme();

    return(
        <div>          
                <HiddenInput 
                    id={id} 
                    name={name} 
                    type="file" 
                    onChange={onChange}
                    multiple={multiple}
                    disabled={disabled}
                />
                <label htmlFor={name} >
                   {children ? children :

                    <IconButton as="span" >                        
                        <UploadCloud 
                            size="22" 
                            color={theme.palette.grey[800]}                                
                        />
                    </IconButton>}
                </label>         
        </div> 
    )
}



const HiddenInput = styled.input`
    display:none;
`


export default UploadButton