import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React from 'react';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useCheckForm from '../../../hooks/useCheckForm';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import { isCedula, isEmail, isLength, isMaxLength, isNSS, isRNC, isTelefono, validate } from '../../../shared/validators';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import PersonaRadio from './PersonaRadio';
import { useDispatch } from 'react-redux';
import { fetchClients } from '../../../app/thunkActions/clientsActions';
import TipoRadio from '../../clients/molecules/TipoRadio';



const ClientePrivadoInput = ({ 
        clientePrivado, 
        setClientePrivado,
        setSelectedClient,
        crearNewClient = false,
        close 
    }) => {
    
    const accionMaker = useAccionMaker()
    const dispatch = useDispatch()

    const isTablet = useMediaQuery('tablet');
    const [values, setValues] = React.useState({
        firstname: clientePrivado?.firstname || '',
        secondname: clientePrivado?.secondname || '',
        lastname: clientePrivado?.lastname  || '',
        secondLastname: clientePrivado?.secondLastname || '',
        cedula: clientePrivado?.cedula || '',
        nss: clientePrivado?.nss || '',
        telefono: clientePrivado?.telefono || '',
        email: clientePrivado?.email || '',
        persona: clientePrivado?.persona || 'juridica',
        clientCompanyName: clientePrivado?.clientCompanyName || '',
        rnc: clientePrivado?.rnc || '',
        address: clientePrivado?.address || '',
        tipo: clientePrivado?.tipo || (clientePrivado?.persona === 'natural' ? 'Privado' : "")
    })

    
    const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        nss: '',
        telefono: '',
        email: '',
        address: '',
    })

    console.log(errors)

    const textFieldStyle = {
        '& .MuiOutlinedInput-root':{
            borderRadius: '12px'
        }
    }

    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()

    let requiredFieldsArray = []
    if(values.persona === 'natural'){
        requiredFieldsArray = ["firstname", "lastname", "cedula"]
    }else{
        requiredFieldsArray = ["clientCompanyName", "rnc", 'tipo']
    }

    const [formCompleted, errorCount] = useCheckForm(values,errors, requiredFieldsArray)
     
    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)        

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onFocus = (event, validators)=>{
        event.target.setAttribute('autocomplete', 'off');
                    
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target                
        const error = validate(value, validators)          
        setErrors(prevState=>({...prevState, [name]:error}))
        return error
    }

    const onBlurNss = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    const onBlurCedula = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    const onBlurRNC = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    //this component is used in two different places. If we are creating a new client, we need to send the info to the database. If we are not, we need to send the info to the parent component.
    let onSubmit

    
    if(crearNewClient){
        onSubmit = async (event) => {
                event.preventDefault();
                let clientInfo
                 if(values.persona === 'natural'){
                    clientInfo = {
                        name: `${values?.firstname || ""} ${values?.secondname || ""} ${values?.lastname || ""} ${values?.secondLastname || ""}`,
                        nss: values?.nss,
                        cedula: values?.cedula,
                        telefono: values?.telefono,
                        email: values?.email,
                        tipo: "Privado"
                        
                    }
                }else{
                        clientInfo = {
                            name: values?.clientCompanyName,
                            rnc: values?.rnc,
                            address: values?.address,
                            telefono: values?.telefono,
                            email: values?.email,    
                            tipo: values?.tipo                        
                        }
                    }
                    
                const accion = accionMaker('Cliente creado')
                clientInfo.accion = accion          
                
                console.log(clientInfo)
                try {
                    const options = createOptions('POST', clientInfo)
                    setIsLoading(true)
                    const response = await goFetch(`${apiUrl}clients/create-client`, options)
                    console.log(response)
                    responseStateChanges(response, ()=>{                        
                        dispatch(fetchClients())
                    }, ()=>close())        
                } catch (error) {
                    console.log(error)
                }                    
        }       
    }else{


     onSubmit = (event) => {
        //event.preventDefault();
        console.log('submitting')
        setClientePrivado(values)
        if(values.persona === 'natural'){

            setSelectedClient({
                name: `${values?.firstname || ""} ${values?.secondname || ""} ${values?.lastname || ""} ${values?.secondLastname || ""}`,
                nss: values?.nss,
                cedula: values?.cedula,
                telefono: values?.telefono,
                email: values?.email,
                newClient: true,
                tipo: "Privado"
            })
        }else if(values.persona === 'juridica'){
            setSelectedClient({
                name: values?.clientCompanyName,
                rnc: values?.rnc,
                telefono: values?.telefono,
                email: values?.email,
                newClient: true,
                tipo: values?.tipo
            })  
        }

        close()
    }

}

    const onChangeRadio = (event) => {        
        setValues(prevState => ({...prevState,persona:event.target.value}));
    }
    const onChangeRadioTipo = (event) => {        
        setValues(prevState => ({...prevState,tipo:event.target.value}));
    }

    return (
        <StyledFormWrapper>
            
            <FormTitle isTablet={isTablet}>{'Crear Cliente Privado'}</FormTitle>
                   
            <StyledFormBody isTablet={isTablet} style={{marginTop: '8px'}}>
                <RadioWrapper>
                    Persona:
                    <PersonaRadio
                        persona={values.persona}                    
                        onChangeRadio={onChangeRadio}
                    />
                </RadioWrapper>
                {values.persona === 'natural' && 
                <>
                    <TextField 
                        value = {values.firstname} label = "Primer Nombre" name = "firstname"
                        onChange = {(event)=>onChangeValues(event,[isLength])}
                        variant = "outlined"
                        helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                        error={!!errors.firstname}
                        required={true}
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={(event)=>onBlur(event, [isLength])}
                        spellCheck="false"
                    />
                    <TextField 
                        value = {values.secondname} label = "Segundo Nombre" name = "secondname"
                        onChange = {(event)=>onChangeValues(event,[isLength])}
                        variant = "outlined"
                        helperText={errors.secondname}
                        error={!!errors.secondname}                    
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        spellCheck="false"
                    />
                    <TextField 
                        value = {values.lastname} label = "Primer Apellido" name = "lastname"
                        onChange = {(event)=>onChangeValues(event,[isLength])}
                        variant = "outlined"
                        helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                        error={!!errors.lastname}
                        required
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        spellCheck="false"
                    />
                    <TextField 
                        value = {values.secondLastname} label = "Segundo Apellido" name = "secondLastname"
                        onChange = {(event)=>onChangeValues(event,[isLength])}
                        variant = "outlined"
                        helperText={errors.secondLastname}
                        error={!!errors.secondLastname}
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        spellCheck="false"
                    />
                    <TextField 
                        value = {values.cedula} label = "Cedula" name = "cedula"
                        onChange = {(event)=>onChangeValues(event,[isCedula],true)}
                        variant = "outlined"
                        helperText={!errors.cedula ? "Campo Obligatorio": errors.cedula}
                        error={!!errors.cedula}                    
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        required={true}
                        onBlur={(event)=>onBlurCedula(event, [isCedula])}
                        spellCheck="false"
                    />
                    <TextField 
                        value = {values.nss} label = "NSS" name = "nss"
                        onChange = {(event)=>onChangeValues(event,[isNSS], true)}
                        variant = "outlined"
                        helperText={errors.nss}
                        error={!!errors.nss}                    
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={(event)=>onBlurNss(event, [isNSS])}
                        spellCheck="false"
                    />
                </>
                }
                {values.persona === 'juridica' &&
                <>

                <RadioWrapper>
                    Tipo:
                    <TipoRadio
                        tipo={values.tipo}                    
                        onChangeRadio={onChangeRadioTipo}
                    />
                </RadioWrapper>
                    <TextField 
                        value = {values.clientCompanyName} label = "Nombre" name = "clientCompanyName"
                        onChange = {(event)=>onChangeValues(event,[()=>isMaxLength(50,values.clientCompanyName)])}
                        variant = "outlined"
                        helperText={!errors.clientCompanyName ? "Campo Obligatorio": errors.clientCompanyName}
                        error={!!errors.clientCompanyName}
                        required={true}
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={(event)=>onBlur(event, [()=>isMaxLength(50,values.clientCompanyName)])}
                        spellCheck="false"
                    />

                    <TextField 
                        value = {values.rnc} label = "RNC" name = "rnc"
                        onChange = {(event)=>onChangeValues(event,[isRNC], true)}
                        variant = "outlined"
                        helperText={!errors.rnc ? "Campo Obligatorio": errors.rnc}
                        error={!!errors.rnc} 
                        required={true}                  
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={(event)=>onBlurRNC(event, [isRNC])}
                        spellCheck="false"
                    />
                    <TextField 
                        value = {values.address} label = "Direccion" name = "address"
                        onChange = {(event)=>onChangeValues(event,[()=>isMaxLength(150,values.address)])}
                        variant = "outlined"
                        helperText={errors.address}
                        error={!!errors.address}                                           
                        sx={textFieldStyle}
                        onFocus={onFocus}
                        onBlur={(event)=>onBlur(event, [()=>isMaxLength(150,values.address)])}
                        spellCheck="false"
                    />
                
                </>
                 }
                 <TextField 
                    value = {values.telefono} label = "Telefono (888)888-8888" name = "telefono"
                    onChange = {(event)=>onChangeValues(event,[isTelefono], true)}
                    variant = "outlined"
                    helperText={errors.telefono}
                    error={!!errors.telefono}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isTelefono])}
                    spellCheck="false"
                />
                 <TextField 
                    value = {values.email} label = "Email" name = "email"
                    onChange = {(event)=>onChangeValues(event,[isEmail], true)}
                    variant = "outlined"
                    helperText={errors.email}
                    error={!!errors.email}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isEmail])}
                    spellCheck="false"
                />
                
            </StyledFormBody>
            
            <FormActionsBasic
                onSubmit={onSubmit}
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                disabledSuccess={!formCompleted || errorCount>0}
            />
            
        </StyledFormWrapper>
    )



   
}


const StyledFormWrapper = styled(FormWrapper)`
    
    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }
`


const StyledFormBody = styled(FormBody)`
    margin-bottom: 85px;
    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 20px;
    }

`

const RadioWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
    color: ${p=>p.theme.palette.grey[700]};
`


export default ClientePrivadoInput