import styled from '@emotion/styled';
import imageCompression from 'browser-image-compression';
import UploadCloud from '../../Icons/UploadCloud';
import Trash2 from '../../Icons/Trash2'
import { useTheme } from '@mui/system';
import Modal from '../../Atoms/Modal';
import React from 'react';
import IconButton from '../../Atoms/IconButton';
import { useDispatch } from 'react-redux';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import { apiUrl } from '../../../shared/url';
import axios from 'axios';
import useCirugia from '../../../hooks/useCirugia';
import { getCookie } from '../../../shared/authHelpers';
import X from '../../Icons/X';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import {addUpdatedCirugia } from '../../../app/cirugiasReducer';
import ProgressBar from '../../Atoms/ProgressBar';
import TextInput from '../../Atoms/TextInput';
import DeleteArchivo from '../../Forms/DeleteArchivo';
import usePermissions from '../../../hooks/usePermissions';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';

const Archivos = ({
    cirugiaId,
    
})=>{

    const theme = useTheme();
    const cirugia = useCirugia(cirugiaId);
    const permissions = usePermissions()
    const {canSeeCotizacionAndAutorizacion} = permissions;
    const dispatch = useDispatch();
    //const [openModal, setOpenModal] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [src, setSrc] = React.useState();
    const tablet = useMediaQuery("tablet");
    //const [deleteModal, setDeleteModal] = React.useState(false);

    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const {openModal:deleteModal, setOpenModal:setDeleteModal, inProp: inPropDelete, setInProp: setInpropDelete, close:closeDelete} = useModalAndDrawer();
    

    const [files, setFiles] = React.useState({
        aprobacion: {
          fileName:"test",
          src:"",
        },
        cotizacion: "",
        ordenArs: ""
      });

      const [deleteInfo, setDeleteInfo] = React.useState({
        docType: "",
        url:""
      }) 

      React.useEffect(()=>{
        console.log(cirugia?.files?.aprobacion?.fileName)
        if(!cirugia) return
        let initialState ={
          aprobacion: {
            fileName:cirugia.files?.aprobacion?.fileName,
            src:cirugia.files?.aprobacion?.url,
          },
          cotizacion: {
            fileName:cirugia.files?.cotizacion?.fileName,
            src:cirugia.files?.cotizacion?.url,
          },
          ordenArs: {
            fileName:cirugia.files?.ordenArs?.fileName,
            src:cirugia.files?.ordenArs?.url,
          },
          pago: {
            fileName:cirugia.files?.pago?.fileName,
            src:cirugia.files?.pago?.url,
          },
          indicacion: {
            fileName:cirugia.files?.indicacion?.fileName,
            src:cirugia.files?.indicacion?.url,
          },
          id1: {
            fileName:cirugia.files?.id1?.fileName,
            src:cirugia.files?.id1?.url,
          },
          id2: {
            fileName:cirugia.files?.id2?.fileName,
            src:cirugia.files?.id2?.url,
          }
        }
        setFiles(initialState)
    }, [cirugia])  


    const uploadImageAxios = (formData, fileType) =>{
        const token = getCookie('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
             }, 
            onUploadProgress: function(progressEvent){
                var percentCompleted =  parseInt(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
                console.log(percentCompleted);
                setProgress(percentCompleted);                           
          }
        }
  
        axios.post(apiUrl + 'cirugias/upload-files/' + cirugiaId, formData, config)
        .then(response => {
          dispatch(successMessage("Archivo guardado exitosamente!"))
          dispatch(addUpdatedCirugia(response.data.cirugia))          
      })
        .catch((error)=> {dispatch(errorMessage(error.errors)); console.error('Error:', error)})
  
  }



    const uploadPdfFile = (file, fileName, docType)=>{
        console.log(file.type);
        if(file.type==="image/jpeg"){
          console.log(file.type);
        }
    
        if (file.type !== "application/pdf" && file.type !== "image/png" && file.type !== "image/jpeg"){
          dispatch(errorMessage("Archivo debe ser un PDF o imagen png/jpeg"))
          return
        }
    
        const reader = new FileReader();
        reader.onloadend = (e)=>{
          setFiles(prevState=>{
            let newDoc = {...prevState["docType"]}
            newDoc.fileName = fileName
            //look into this part, do I need it? What is it doing. March 18,2022
            newDoc.src = e.target.result
    
            return {...prevState, [docType]:newDoc}
          })
      }
    
          reader.readAsDataURL(file); // when file is read onloadend function is triggered. result has a url which is then used to render preview.
          const formData = new FormData();
          formData.append('docType', docType)
    
          var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 750
          }
    
          if(file.type === "image/png" || file.type === "image/jpeg"){    
            imageCompression(file, options)
              .then((compressedFile)=>{
                //const formData = new FormData();
                formData.append('file',compressedFile)
                formData.append('name', fileName)
                uploadImageAxios(formData);
    
              })
              .catch(err=>console.log(err))
          }else{
            formData.append('file',file)
            uploadImageAxios(formData);
    
          }
      }

  
    const onUpload = (e,docType) =>{
        let fileName = e.target.files[0].name
        let file = e.target.files[0]    
        uploadPdfFile(file,fileName, docType)
      }

    const openDocument = (src)=>{
        if(src){
            setOpenModal(true)        
            setSrc(src)
        }        
    }

    const onClickDelete = (docType, url)=>{
      setDeleteInfo({docType, url})
      setDeleteModal(true)
    }


    const documentOptions = [
        {documentType: "aprobacion", title: "Aprobacion", fileName: files?.aprobacion?.fileName, src: files?.aprobacion?.src},
        {documentType: "cotizacion", title: "Cotizacion", fileName: files?.cotizacion?.fileName, src: files?.cotizacion?.src},
        {documentType: "ordenArs", title: "Orden Ars", fileName: files?.ordenArs?.fileName, src: files?.ordenArs?.src},
        {documentType: "pago", title: "Pago", fileName: files?.pago?.fileName, src: files?.pago?.src},
        {documentType: "indicacion", title: "Indicacion", fileName: files?.indicacion?.fileName, src: files?.indicacion?.src},
        {documentType: "id1", title: "Cedula", fileName: files?.id1?.fileName, src: files?.id1?.src},
        {documentType: "id2", title: "Carnet", fileName: files?.id2?.fileName, src: files?.id2?.src}
    ]

    //()=>deleteImage(document.documentType, document.src)

    return(
        <Wrapper>
            <InnerWrapper>
                <Title>Guardar Documentos</Title>
                <ProgressBar progress={progress}/>
                <br/>

                {
                    documentOptions.map((document)=>{

                      if((document.documentType === "aprobacion" || 
                            document.documentType === "cotizacion" || 
                              document.documentType === "ordenArs" ||
                              document.documentType === "pago"
                              ) && 
                              !canSeeCotizacionAndAutorizacion) return null

                        return(
                            <DocumentInfo >
                                <DocumentType onClick={()=>openDocument(document.src)}>{document.title}</DocumentType>
                                <DocumentName onClick={()=>openDocument(document.src)}>{document.fileName}</DocumentName>
                                <DocumentActions>
                                    <div>
                                        <HiddenInput id={document.documentType} name={document.documentType} type="file" onChange={(event)=>onUpload(event, document.documentType)}/>
                                        <label htmlFor={document.documentType} >
                                            <IconButton as="span"  >
                                                <UploadCloud 
                                                    size="22" 
                                                    color={theme.palette.grey[600]}                                
                                                />
                                            </IconButton>
                                        </label>
                                    </div>                        
                                    <IconButton onClick={()=>onClickDelete(document.documentType, document.src)}>
                                        <Trash2 size="22" color={theme.palette.grey[600]}/>
                                    </IconButton>
                                </DocumentActions>
                            </DocumentInfo>
                        )

                    })
                }

                
            </InnerWrapper>

            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                fullWidth={true}
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                
            >
                <ModalWrapper>
                    {
                        tablet &&
                          <Header>
                              <IconButton onClick={()=>setOpenModal(false)}><X color={'#ffff'}/></IconButton>
                          </Header>
                    }                    
                    <StyledIFrame  title="pdfViewer" src={src}>
                    </StyledIFrame>
              </ModalWrapper>

            </Modal>

            <Modal
                openModal={deleteModal}
                setOpenModal={setDeleteModal}
                fullWidth={true}
                inProp={inPropDelete}
                setInProp={setInpropDelete}
                close={closeDelete}
                smallDrawer="true"
            >
               <DeleteArchivo cirugiaId={cirugiaId} deleteInfo={deleteInfo} setOpenModal={setDeleteModal} close={closeDelete}/>

            </Modal>
            
        </Wrapper>
    )
}



const ModalWrapper = styled.div`
    width:100vw;
    height:100vh;
`
const HiddenInput = styled.input`
    display:none;
`
const StyledIFrame = styled.iframe`
    margin-top:65px;
    width:100%;
    height: 100%;
`
const Header = styled.div`
    height: 65px;
    width:100%;
    background-color: ${p=>p.theme.palette.primary.main}; 

    position: fixed;
    top:0;
    display: flex;
    padding: 16px;
    
    
    z-index:100;

`

const Wrapper = styled.div`
    
    width:100%;
    min-height: 500px;

    @media ${p=>p.theme.media.tablet}{
        min-width: 700px;
    }
`

const InnerWrapper = styled.div`
    display:flex;
    flex-direction: column;
    padding: 8px;
    width:100%;

    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
    }


`
const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    padding-bottom: 36px;
`


const DocumentInfo = styled.div`
    display:flex;
    width:100%;
    height:100%;
    padding:8px;
    gap:12px;
    align-items: center;
    border-top: 1px solid ${p=>p.theme.palette.grey[400]};

    &:last-of-type{
        border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    }
    
    &:hover{
        background-color: ${p=>p.theme.palette.grey[200]};
        cursor: pointer;
    }

    @media ${p=>p.theme.media.tablet}{
        padding: 12px;
    }
`

const DocumentType = styled.div`
    margin:0;        
    flex:1;
    padding: 16px 0px;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]};
    
    
`

const DocumentName = styled.p`
    margin:0;
    padding: 16px 0px;
    flex:2;
    color: ${p=>p.theme.palette.grey[700]};
    overflow-wrap: anywhere;
`


const DocumentActions = styled.div`
    display:flex;
    flex:1;
    gap: 8px;
    justify-content: flex-end;
    

`



export default Archivos