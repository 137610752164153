import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivosFijos } from "../../../app/thunkActions/activosFijosActions";

import SearchBar from "../../Atoms/SearchBar";
import { initActivosFijos } from "../../../app/activosFijosReducer";





const ActivosFijosSearchBar = ()=>{
    const [searchValue, setSearchValue] = React.useState();

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.activosFijos.isLoading);
    const onChangeSearchValue = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        setSearchValue(searchTerm)
        if(searchTerm.length>2 && !isLoading){   
            dispatch(fetchActivosFijos(searchTerm,'all'));
        }
        if(!searchTerm){            
            dispatch(initActivosFijos())
        }
    }

    return (
        <SearchBar        
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoading}
    />
    )
}



export default ActivosFijosSearchBar