import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';
import Archive from '../../Icons/Archive';
import ThumbsUp from '../../Icons/ThumbsUp';
import ThumbsDown from '../../Icons/ThumbsDown';

const EvaluacionChip = ({cirugia})=>{
    const theme = useTheme()
    
    // console.log(cirugia?.evaluacion?.negativa)
    // console.log(cirugia)
    if(!cirugia?.evaluacion?.positiva && !cirugia?.evaluacion?.negativa) return null

    return(        
        <>
            {
                cirugia?.evaluacion?.positiva && <ThumbsUp size="22" color={theme.palette.success.light}/>
            }
            {
                cirugia?.evaluacion?.negativa && <ThumbsDown size="22" color={theme.palette.error.light}/>
            }
        </>
    )
}



export default EvaluacionChip;