import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import usePermissions from '../../../hooks/usePermissions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import UploadCloud from '../../Icons/UploadCloud';
import ActivosFijosSearchBar from './ActivosFijosSearchBar';
import BackButton from '../../Atoms/BackButton';
import { CardBody, CardWrapper } from '../../Atoms/Card';
import Modal from '../../Atoms/Modal';
import IconButton from '../../Atoms/IconButton';
import Plus from '../../Icons/Plus';
import useMediaQuery from '../../../hooks/useMediaQuery';
import MoreButtonActivosFijos from './MoreButtonActivosFijos';
import AddActivosFijos from './AddActivosFijos';
import UploadActivosFijos from './UploadActivosFijos';
import EditarActivosFijos from './EditarActivosFijos';
import DesactivarActivosFijos from './DesactivarActivosFijos';
import { initActivosFijos } from '../../../app/activosFijosReducer';

const ActivosFijos = () => {
    const theme = useTheme();    
    const { openModal, setOpenModal, inProp, setInProp, close } = useModalAndDrawer();  
    const [formType, setFormType] = React.useState();
    const [activoFijoObj, setActivoFijoObj] = React.useState();    
    const navigate = useNavigate();
    const isTablet = useMediaQuery('tablet');
    const activosFijosArray = useSelector(state => state.activosFijos.activosFijos);
    const permissions = usePermissions()
    const { canCrearActivosFijos } = permissions;
    const dispatch = useDispatch();

    const handleOnClick = (formType) => {
        setOpenModal(true);        
        setFormType(formType)
    }
    React.useEffect(()=>{
        return ()=>dispatch(initActivosFijos());
    },[])

    const smallBottomSwipeDrawers = ["addProducto", "editar", "deactivate", "accesoCot"]

    return (
        <ExternalWrapper>
            <Wrapper>
                <Header>
                    <BackButton onClick={() => navigate(-1)} />
                    <ActivosFijosSearchBar />
                    {true &&
                        <>
                            <StyledIconButton onClick={() => handleOnClick('addActivosFijos')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>
                            {isTablet && <StyledIconButton onClick={() => handleOnClick('upload')}><UploadCloud color={theme.palette.grey[700]}/></StyledIconButton>}                        
                        </>
                    }
                </Header>

                <Body>
                    {activosFijosArray.length > 0 && activosFijosArray.map((activoFijo, index) => (
                        <Card key={`${activoFijo._id}`}>
                            <CardBody>
                                <ProductWrapper>
                                    <InfoColumn>
                                        <Label>Código:</Label>
                                        <Codigo deactivated={activoFijo?.deactivated}>                                            
                                            {activoFijo?.codigo}
                                            {activoFijo?.deactivated && <SmallText>Desactivado</SmallText>}
                                        </Codigo>
                                    </InfoColumn>
                                    <InfoColumn flex={2}>
                                        <Label>Descripción:</Label>
                                        <Descripcion deactivated={activoFijo?.deactivated}>
                                            {activoFijo?.descripcion}
                                        </Descripcion>
                                    </InfoColumn>
                                    <InfoColumn>
                                        <Label>Fabricante:</Label>
                                        <Fabricante deactivated={activoFijo?.deactivated}>
                                            {activoFijo?.fabricante || 'N/A'}
                                        </Fabricante>
                                    </InfoColumn>
                                    <InfoColumn>
                                        <Label>Serial:</Label>
                                        <Serial deactivated={activoFijo?.deactivated}>
                                            {activoFijo?.serial || 'N/A'}
                                        </Serial>
                                    </InfoColumn>
                                    <MoreButtonWrapper>
                                        <MoreButtonActivosFijos 
                                            setFormType={setFormType} 
                                            setOpenModal={setOpenModal}
                                            activoFijoObj={activoFijo}
                                            setActivoFijoObj={setActivoFijoObj}
                                        />
                                    </MoreButtonWrapper>
                                </ProductWrapper>
                            </CardBody>                                
                        </Card>   
                    ))}
                </Body>
            </Wrapper>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer={smallBottomSwipeDrawers.includes(formType)}           
            >
                {formType === 'addActivosFijos' && <AddActivosFijos close={close} />}
                {formType === 'upload' && <UploadActivosFijos close={close} />}
                {formType === 'editar' && <EditarActivosFijos close={close} activoFijoObj={activoFijoObj} />}
                {formType === 'deactivate' && <DesactivarActivosFijos close={close} activoFijoObj={activoFijoObj} />}
            </Modal>     
        </ExternalWrapper>
    )
}

const ExternalWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 1000px;
`

const Card = styled(CardWrapper)`
    position: relative;
`

const Header = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p => p.theme.palette.grey[500]};
    background-color: #ffff;
`

const ProductWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: ${props => props.flex || 1};
`

const Label = styled.span`
    font-size: 0.75rem;
    color: ${p => p.theme.palette.grey[600]};
    font-weight: 500;
`

const Codigo = styled.div`
    color: ${p => p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[800]};
`

const SmallText = styled.p`
    margin: 0;
    font-size: 0.75rem;
`

const Descripcion = styled.div`
    color: ${p => p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[900]};
    word-break: break-word;
`

const Fabricante = styled.div`
    color: ${p => p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[900]};
`

const Serial = styled.div`
    color: ${p => p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[900]};
`

const MoreButtonWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: 16px;
`

const Banner = styled.div`
    position: absolute;
    top: 5px;
    left: 10px;
    border: 1px solid;
    background-color: ${p => p.theme.palette.error.light};
    color: white;
    border-radius: 8px;
    padding: 4px;
    font-weight: 500;
    font-size: 0.75rem;
`

export default ActivosFijos;