import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../Atoms/Button';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import CentrosAutocomplete from '../Forms/CustomAutocomplete/CentrosAutocomplete';
import XCircle from '../Icons/X-circle';



const EditarCentros = ({
    doctor,    
    onSubmit,
    centrosArray,
    initialCentrosArray,
    setCentrosArray,
    close,
    isLoading,
    successEvent
})=>{
    const dispatch = useDispatch();
    const theme = useTheme();
    const [centroToAdd, setCentroToAdd] = React.useState("")

    //const initialCentrosArray = doctor?.centros || []
    //const [centrosArray, setCentrosArray] = React.useState(initialCentrosArray)
    //const [isLoading, setIsLoading] = React.useState();
    //const [successEvent, setSuccessEvent] = React.useState();
    const [error, setError] = React.useState();
    

    const onChangeCentros = (event, option)=>{

        setCentroToAdd({
            _id:option?._id,
            nombre: option?.nombre,
            ciudad: option?.ciudad,
            zona: option?.zona
        })

    }

    const onAdd = ()=>{
        console.log(centroToAdd)
        if(!centroToAdd._id || !centroToAdd.nombre) return

        if(centrosArray.length>2){
            setError("Solo se permite colocar un maximo de 3 centros.")
            return
        }
        setCentrosArray(prevState =>[...prevState, centroToAdd])
    }

    const onDelete = (index)=>{
        const newCentrosArray = [...centrosArray]
        newCentrosArray.splice(index,1);
        setCentrosArray(newCentrosArray)

    }

    


    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Editar Centros</FormTitle>
            <FormBody>
                
                <CentrosAutocomplete onChange={(event, option)=>onChangeCentros(event, option)}/>   
                <Button primary onClick={onAdd}>Agregar</Button>   
                
                <CentrosWrapper>  
                                  
                <Error>{error}</Error>
                    {centrosArray.map((centro, index)=>(
                                    <CentroWrapper key={`${centro}${index}`}>
                                        {centro.nombre}  
                                        <IconButton onClick={()=>onDelete(index)}><XCircle size="18" color={theme.palette.error.light}/></IconButton>                  
                                    </CentroWrapper>
                                ))                    
                    }
                </CentrosWrapper>
            </FormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"                                
                loading={isLoading}
                successEvent={successEvent}   
                type="submit"
                as="button"
                disabledSuccess={JSON.stringify(initialCentrosArray) === JSON.stringify(centrosArray)}         
            />

            
        </FormWrapper>
    )

}



const CentroWrapper = styled.div`
    margin-bottom: 8px;
    color: ${p=>p.theme.palette.grey[900]};
    display:flex;
    justify-content: space-between;
    align-items:center;
    gap:4px;
`

const CentrosWrapper = styled.div`
    align-self:center;
    
`

const Error = styled.p`
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.error.light};
    margin-bottom: 12px;

`


export default EditarCentros; 