import io from 'socket.io-client';
import { socketUrl } from './url';






const user = JSON.parse(localStorage.getItem('user'));
//let socket = io(socketUrl);

let socket
if(user?.company?._id){
    socket = io(socketUrl+'/'+ user?.company?._id)
    //console.log(user.company);

socket?.on('messageFromServer', (dataFromServer)=>{
       // console.log(dataFromServer)
        socket.emit('messageToServer', {data:"This is from the client"})
    })

socket?.on('sendTenantIdToServer',(data)=>{
    socket.emit('clientTenantId', user?.company._id)
})

socket?.on(`${user.company._id}`)


//console.log(socket)
}





export default socket;
