import styled from '@emotion/styled/macro';


export const FormWrapper = styled.form`
    width:100%;
    height:100%;
    overflow-y:auto;
    padding-top: 8px;
    display:flex;
    flex-direction:column;
    
    @media ${p=>p.theme.media.tablet}{
        padding-left: 18px;
        padding-right: 18px;
        min-width: 400px;
    }
`

export const FormTitle = styled.div`
    width:100%;
    padding: 0px 24px;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 8px 0px ${p=>p.isTablet ? '20px' : '30px'};
    display:flex;
    justify-content: ${p=>p.isTablet ? 'revert' : 'center'};    
`

export const FormBody = styled.div`
    width:100%;
    display:flex;
    flex-direction: column;
    padding: 0px ${p=>p.isTablet ? '24px' : '8px'};
    gap: 8px;
    margin-bottom:${p=>p.isTablet ? '8px' : '90px'};;
    flex:2;
`

export const FormActions = styled.div`
    display:flex;
    width:100%;
    padding: 16px 24px 24px;
    justify-content: space-between;
    border-top: 1px solid ${p=>p.theme.palette.grey[300]};

    @media ${p=>p.theme.media.tablet}{
        padding: 24px 24px 18px 24px;
    }
    
`

export const FormActionsMobile = styled.div`
    position:fixed;
    bottom:0px;
    background-color: #ffff;
    height:80px;
    display:flex;
    width:95%;
    padding: 16px 24px 24px;
    justify-content: space-between;
    border-top: 1px solid ${p=>p.theme.palette.grey[300]};
    z-index:5;
`


export const FormHeader = styled.div`
    width:100%;
    padding: 0px 24px;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 8px 0px ${p=>p.isTablet ? '20px' : '40px'};
    display:flex;
    align-items: ${p=>p.isTablet ? 'revert' : 'center'};    
    flex-direction: column;    
`
export const FormSubtitle = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[600]};
    margin-bottom: 16px;
`

