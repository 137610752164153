import React from 'react';
import styled from '@emotion/styled';
import TextInput from '../Atoms/TextInput';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import Button from '../Atoms/Button';
import useSuccessButton from '../../hooks/useSuccessButton';
import { TextField } from '@mui/material';

import { apiUrl } from '../../shared/url';
import { createOptions, goFetch } from '../../api/cirugiasApi';

const EliminarRegla = ({
    regla,
    reglas,
    setReglas,
    close}) => {





const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
const [confirmacion, setConfirmacion] = React.useState('')

const onChangeHandler = (event)=>{
    setConfirmacion(event.target.value)
}

const onSubmit = async(e)=>{
          e.preventDefault();

        console.log(regla)
        setIsLoading(true)
        const options = createOptions('POST',{})
        let response;
          
        try{            
            response = await goFetch(`${apiUrl}agenda/delete-regla/${regla._id}`, options );
                        
            //find regla in reglas and take it out as it has been deleted
            const updatedReglas = reglas.filter(reglaItem=>reglaItem._id !== regla._id)

            setReglas(updatedReglas)

            responseStateChanges(response,null,()=>close())    
        }catch(error){
            console.log(error);
        }
    
}

  return (
    
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Eliminar Regla</FormTitle>
            <FormBody>            
            <Text>Por favor confirmar que desea eliminar esta regla escribiendo la palabra <StyledEliminar>eliminar.</StyledEliminar></Text>
                <TextInput
                    label="Eliminar"
                    value={confirmacion}
                    onChange={onChangeHandler}
                />                
            </FormBody>  

            <FormActions>
                <Button
                as={"button"}
                fullsize={true}
                success
                type="submit"                
                disabled={confirmacion.toLowerCase() !=="eliminar"}
                loading={isLoading}
                successEvent={successEvent}                      
                >Eliminar Regla</Button>
           
            </FormActions> 
                  
    </FormWrapper>          
    
  );
};



const ExternalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    
    gap: 16px;
    padding: 16px;
    max-width: 1200px;
    @media ${p=>p.theme.media.tablet}{
        flex-direction: row;
    }
`

const Text=styled.p`
    margin:0;
    margin-bottom: 8px;
`

const StyledTextField = styled(TextField)`
    & .MuiInputLabel-root{
        font-size: 0.875rem;
        font-weight: 500;
    };
    & fieldset{
        border-radius:12px;
        font-size:1rem;
        top:0;
    };
`


const StyledEliminar = styled.span`
    font-weight:700;
    font-style: italic;
`


export default EliminarRegla;
