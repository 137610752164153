import { addUsers, addUpdatedUser, loadingUsers, addVentas, addEC, addActiveUsers, addSearchedUsers} from "../usersReducer";
import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";
import { successMessage } from "../messagesReducer";

export const fetchUsers = (filter)=> async(dispatch)=>{
    let url;
    
    if(filter==="all") url = "users";
    if(filter==="ventas") url = "users/ventas";
    if(filter==="ventasAndEc") url = "users/ventas/ec";
    
    const options = createOptions('GET')
    let users;
      
    try{
        dispatch(loadingUsers())
        const response = await goFetch(apiUrl + url,options );
        dispatch(addUsers({users:response.users, activeUsers:response.activeUsers}));       
    }catch(error){
        console.log(error);
    }
    
   // dispatch(addUsers(users));
}

//fetch active users
export const fetchActiveUsers = ()=> async(dispatch)=>{
    
    const options = createOptions('GET')
    
    try{
        dispatch(loadingUsers())
        const respones = await goFetch(apiUrl + 'users/active-users', options);
        dispatch(addActiveUsers({activeUsers:respones.activeUsers}));
    }catch(error){
        console.log(error);
    }


}

export const fetchVentas = ()=> async(dispatch)=>{
    
    const url = "users/ventas";

    const options = createOptions('GET')
    let users;
      
    try{
        dispatch(loadingUsers())
        const response = await goFetch(apiUrl + url,options );
        dispatch(addVentas(response.users));       
    }catch(error){
        console.log(error);
    }
    
   
}

export const fetchEC = ()=> async(dispatch)=>{
    
    const url = "users/ventas/ec";

    const options = createOptions('GET')
    let users;
      
    try{
        dispatch(loadingUsers())
        const response = await goFetch(apiUrl + url,options );
        dispatch(addEC(response.users));       
    }catch(error){
        console.log(error);
    }
    
   
}


export const searchUsers = (search)=> async(dispatch)=>{

    const options = createOptions('GET')
    let searchedUsers;
      
    try{
        dispatch(loadingUsers())
        searchedUsers = await goFetch(apiUrl + 'users/search/'+ search,options );
        console.log(searchedUsers)
        dispatch(addSearchedUsers({searchedUsers}));       
    }catch(error){
        console.log(error);
    }
    
    
}


//used to update current user
export const updateUser =(url, values)=>async (dispatch)=>{
    
    const options = createOptions('PUT', values)
    let response;
    
    console.log(values)

    try{
        response = await goFetch(`${apiUrl}users/${url}/${values.userId}`, options)  
        console.log(response);      
        dispatch(addUpdatedUser(response))
    }catch(error){
        console.log(error);
    }
}


export const updateCompanyUsers =(url, values)=>async (dispatch)=>{
    
    const options = createOptions('PUT', values)
    let response;
    
    console.log(values)

    try{
        response = await goFetch(`${apiUrl}users/${url}/${values.userId}`, options)  
        console.log(response);
        dispatch(addUpdatedUser(response.user))      
        dispatch(successMessage(response.message))
    }catch(error){
        console.log(error);
    }
}