import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { doctorsSlice } from '../../../app/doctorsReducer';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import LabelValue from '../../Atoms/LabelValue';
import DoctorCentrosList from './DoctorCentrosList';



const DoctorCreate =({
    doctor,
    close
})=>{

    const user = useCurrentUser();
    const dispatch = useDispatch();
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()


    const onSubmit = async(event)=>{
        event.preventDefault();
        setIsLoading(true);

        const info ={
            mdUserId: doctor.mdUserId,
            companyName: user.company.name,
            firstname: doctor.firstname,
            lastname: doctor.lastname,
            email: doctor.email,
            centros: doctor.centros,
            especialidad: doctor.especialidad            
        }

        const options = createOptions('POST', info)

        try {
            const response = await goFetch(`${apiUrl}doctors/create-doctor-form-request`, options)
            responseStateChanges(response, null, ()=>close())    
        } catch (error) {
            console.log(error)            
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }

        
        
    }

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle>Crear Doctor</FormTitle>
            <FormBody>
                <Text>Crearas un Doctor con las siguiente informacion:</Text>
                <InfoWrapper>
                    <LabelValue label="Email: " value={doctor.email}/>
                    <LabelValue label="Nombre: " value={`${doctor.firstname} ${doctor.lastname}`}/>
                    <LabelValue label="Especialidad: " value={`${doctor.especialidad || "No disponible"}`}/>
                    <LabelValue label="Centros:  " value={<DoctorCentrosList doctor={doctor} size="small"/>}/>                    
                </InfoWrapper>
                
                <Text>{`Tambien mandara una invitacion al doctor para conctarse con ${user.company.name}`}</Text>
            </FormBody>
            <FormActionsBasic
                successText={"Crear doctor"}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"                
                close={close}
            />

            
        </StyledFormWrapper>
    )
}


const StyledFormWrapper = styled(FormWrapper)`
    

    @media ${p=>p.theme.media.tablet}{
        min-width: 500px;
        
    }

`

const Text = styled.p`
    margin: 0;
    font-size: 0.95rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]}

`

const InfoWrapper = styled.div`
    min-width:350px;
    align-self: center;
    display:flex;
    
    flex-direction: column;

`



export default DoctorCreate;