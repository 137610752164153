import React from 'react';
import styled from '@emotion/styled';
import useColor from '../../hooks/useColor';



export const FormControlRadio =({
    children, 
    color="primary",
    onChange
})=>{
    const [selectedColor] = useColor(color);

    /*const onChange = (event)=>{
        console.log(event.target.value)
    }
    */
    return(
        <FormControl style={{"--selected-color": selectedColor}} onChange={onChange}>
            {children}
        </FormControl>
    )

}


export const Radio = ({
    id,
    name,
    color,
    label,
    value,
    ...props
})=>{

    
    return(
        <RadioWrapper>
            <StyledInput type ="radio" id={id} name={name} value={value} {...props} />   
            <StyledLabel htmlFor={id} name={name}>
                
                {label}
            </StyledLabel>
        </RadioWrapper>
    )
}

const FormControl = styled.div`
    width: 100%;
    display:flex;
    align-items:center;
    gap:8px;
`

const RadioWrapper = styled.div`
    display:flex;
    gap:4px;
`

const StyledInput = styled.input`
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    width: 1.15rem;
    height: 1.15rem;
    border-radius:50%;
    border: 1px solid var(--selected-color);
    display:grid;
    place-content:center;

    &::before{
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--selected-color);
    }

    &:checked::before{
        transform: scale(1);
    }
    
`

const StyledLabel = styled.label`
    font-size:0.875rem;
    color: ${p=>p.theme.palette.grey[600]}
`


