import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody } from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import VentasAutocomplete from './CustomAutocomplete/VentasAutocomplete';
import useCirugia from '../../hooks/useCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const ModifyVentas = ({
    cirugiaId,
    close
})=>{

    const isTablet = useMediaQuery('tablet');    
    const [selectedVentas, setSelectedVentas] = React.useState(null);
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);

    
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();
    const cirugia = useCirugia(cirugiaId);
  
    
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Ventas cambio de ${cirugia?.ventas?.firstname} ${cirugia?.ventas?.lastname} a ${selectedVentas?.firstname} ${selectedVentas?.lastname}`);

        console.log(selectedVentas);
        let infoVentas = {
            ventasId: selectedVentas._id,
            cirugiaId: cirugiaId,
            firstname: selectedVentas.firstname,
            secondname: selectedVentas.secondname || "",
            lastname: selectedVentas.lastname,
            secondLastname: selectedVentas.secondname || "",
            comentarios: commentObj,
            accion:accion
          }
        
        updateCirugia("update-ventas",infoVentas)
    }        
      

    const onChangeHandler = (event, option)=>{
        if(option){
            setSelectedVentas(option)
        }else{
            setSelectedVentas("")
        }
        
    }

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet} >Modificar Ventas</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <VentasAutocomplete
                    onChange={onChangeHandler}
                />                  

                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />                                
            </FormBody>
            
           <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyVentas;