import styled from "@emotion/styled/macro";
import { faUserCircle, faPencil, faEdit, faLock, faLockOpen, faFile, faFileInvoice, faDollarSign, faDolly } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizontal from "../../Icons/MoreHorizontal";
import { errorMessage, successMessage } from "../../../app/messagesReducer";
import { updateProductosArray } from "../../../app/productosReducer";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import { Menu, MenuItem } from "@mui/material";
import Button from "../../Atoms/Button";
import React from "react";
import IconButton from "../../Atoms/IconButton";
import useSubscriptionToken from "../../../hooks/useSubscriptionToken";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Trash2 from "../../Icons/Trash2";


const MoreButtonReglas= ({
    setOpenModal,
    setFormType,
    setProductos,
    setRegla,
    regla,
    
})=>{
    const dispatch= useDispatch();
    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

    const subscription = useSubscriptionToken();
    const planAllowsCotizaciones = subscription?.companyPermissions?.includes('checkIn');;
    

        
    const onClickHandler = (event)=>{
        setAnchorEl(event.currentTarget)
    }
 
    const onCloseHandler = ()=>{
        setAnchorEl(null)
    }


    
      const handleOnClickProductos = (formType, productos)=>{
        setOpenModal(true);    
        setFormType(formType)
        setProductos(productos)
        setAnchorEl(null)
      }
    
      const handleOnClickEditarAndEliminar = (formType, regla)=>{
        setOpenModal(true);    
        setFormType(formType)
        setRegla(regla)
        setAnchorEl(null)
      }

    
        return(
            <>
                <IconButton
                onClick={onClickHandler}
                >                
                    <MoreHorizontal color={theme.palette.grey[600]}/>
                </IconButton>
                <Menu
                    open={open}
                    onClose={onCloseHandler}
                    anchorEl={anchorEl}
    
                >
                    <MenuItem onClick={()=>handleOnClickProductos("productos", regla.productos)}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faDolly} style={{fontSize: "1rem"}} color= {theme.palette.grey[700]}/>
                            <Text>Ver productos</Text>
                        </MenuItemWrapper>
                    </MenuItem>                
                    <MenuItem onClick={()=>handleOnClickEditarAndEliminar("editarRegla", regla)}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faEdit} style={{fontSize: "0.9rem"}} color={theme.palette.grey[600]}/>
                            <Text>Editar</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                    <MenuItem onClick={()=>handleOnClickEditarAndEliminar('eliminarRegla',regla)}>
                        <MenuItemWrapper>
                        <Trash2 size="20" color={theme.palette.grey[600]}/>
                            <Text>Eliminar</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                    
                    
                    
                </Menu>
    
            </>
        )


}


const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Text = styled.p`
    margin:0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}
`
const IconWrapper = styled.div`
    display: flex;
    gap: 8px;
`

export default MoreButtonReglas;
