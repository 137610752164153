import React from 'react';
import styled from '@emotion/styled/macro';
import {useSelector, useDispatch} from 'react-redux';
import socket from '../../shared/socketConnection';
//import AccionCreator from './AccionCreator';
import { useTheme } from '@mui/material';
import { CardBody, CardWrapper } from '../Atoms/Card';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import Avatar from '../Atoms/Avatar';
import Chip from '../Atoms/Chip';
import ActivityCreator from './ActivityCreator';
import useMediaQuery from '../../hooks/useMediaQuery';
import Button from '../Atoms/Button';
import ActivityLoading from './molecules/ActivityLoading';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addActivities, addNextActivities, setActivityScrollPosition, setNextCursor } from '../../app/activityReducer';
import usePermissions from '../../hooks/usePermissions';
import { resetCirugias } from '../../app/cirugiasReducer';


const Activity = ()=>{

  
  const theme = useTheme();
  const tablet = useMediaQuery('tablet');
  const dispatch = useDispatch();
  const {activities, scrollPosition, nextCursor} = useSelector(state=>state.activity)
  const {cirugias} = useSelector(state=>state.cirugias)
  //const [nextCursor, setNextCursor] = React.useState("");  
  const [loading, setLoading] = React.useState(false)
  const [loadingMore, setLoadingMore] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = usePermissions()

    const {        
        canSeeAllCirugias,               
    }=permissions
  let activitiesSorted;

  
  const [prevViewActivity, setPrevViewActivity] = React.useState(localStorage.getItem('lastViewedActivity') && JSON.parse(localStorage.getItem('lastViewedActivity')));
  const [lastViewedActivity, setLastViewedActivity] = React.useState(); //time of latest fetch to database

  //console.log(`The previous view time was ${prevViewActivity}`)

  const getActivity = React.useCallback(async(nextCursor)=>{    
  
     setLoading(true)
    
    const options = createOptions('POST',{nextCursor})
    const response = await goFetch(`${apiUrl}activity`,options, dispatch);   
    
    dispatch(addActivities(response.activity))
    dispatch(setNextCursor(response.nextCursor))
    setLoading(false);
    
  },[])



  const getNextActivity = React.useCallback(async(nextCursor)=>{    
    
    setLoadingMore(true)
    
    const options = createOptions('POST',{nextCursor})
    const response = await goFetch(`${apiUrl}activity`,options, dispatch);   
    
    dispatch(addNextActivities(response.activity))
    dispatch(setNextCursor(response.nextCursor))    
    setLoading(false);
    
  },[])

  React.useEffect(()=>{     
   
    //if coming back from activity then do not get info from server
    if(location.state?.from === "cirugia" && activities.length!==0) { 
      window.scrollTo(0, scrollPosition)  
      navigate(location.pathname, {})
      return
    }
   
    getActivity()
    setLastViewedActivity(new Date());
    return ()=> localStorage.setItem('lastViewedActivity', JSON.stringify(new Date()));

  },[getActivity])

  
  const [newActivity, setNewActivity] = React.useState(false)

  React.useEffect(()=>{    
    socket?.on('activityChange', (data)=>{
      let activity = data.fullDocument;         
      setNewActivity(activity);      
      return ()=>socket.disconnect()
    })
  },[])


  React.useEffect(()=>{
    if(cirugias){
      dispatch(resetCirugias())
    }
  },[])

  React.useEffect(()=>{
    
    if(newActivity){      
      dispatch(addActivities([...activities, newActivity]))
      setNewActivity(false)        
    }
  },[newActivity,dispatch])

  const compareFunction =(a,b)=>{
    if (a.createdAt<b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    else return 0
  }

  const loadMore = async()=>{    
    await getNextActivity(nextCursor);  
    setLoadingMore(false);  
  }

  const setScroll= ()=>{
    dispatch(setActivityScrollPosition(window.scrollY))
}

// React.useEffect(() => {
//   window.scrollTo(0, scrollPosition)
// }, [])

// needed in case activities are not available
  if(activities?.length >0){
    activitiesSorted = [...activities]
  }
  



  return(
    <ExternalWrapper>

    
    <Wrapper>

              { loading && <ActivityLoading/>}
      
        
            {activitiesSorted?.sort(compareFunction).map((activity, index)=>
              {
                //const formattedDate = dateFormat(activity.createdAt);
                //console.log(activity)
                const newActivity = new Date(lastViewedActivity) < new Date(activity.createdAt)                
                const notSeenActivity = new Date(activity.createdAt) > new Date(prevViewActivity) && new Date(activity.createdAt)< new Date(lastViewedActivity);
                const accionesUrgent = ["agendar", "rechazar", "centro", "productos", "suspender", "doctor"]

                const rightNow = new Date()
                const cirugiaDate = new Date(activity.cirugia.fechaRaw)
                const hoursUntilCirugia = (cirugiaDate- rightNow)/1000/60/60
                let horas24 = false
                let horas48 = false

                if(accionesUrgent.includes(activity.accionType)){
                  if (hoursUntilCirugia < 24 && hoursUntilCirugia > -0.1){
                    horas24= true;
                  }
                  if (hoursUntilCirugia < 48 && hoursUntilCirugia >24 && hoursUntilCirugia > 0){
                    horas48= true;
                  }
                }
                
                
                
                
                if(tablet){
                    return(
                      <CardLink 
                        to={canSeeAllCirugias && activity.cirugia.cirugiaId ? `/app/cirugias/${activity.cirugia.cirugiaId}`:''}
                        state ={{path:location.pathname, from:'activity'}}                                                                        
                        onClick={setScroll}    
                        key={`${activity} ${index}`}       
                      >
                        <Card highlight={newActivity || notSeenActivity} >
                            <CardBody >
                                <ActivityWrapper>
                                    <ChipWrapper>
                                          {newActivity && <Chip label="Nuevo" color={theme.palette.success.light}/> }
                                          {notSeenActivity && <Chip label="Sin leer" color={theme.palette.success.light}/> }
                                    </ChipWrapper>                                                           
                                    <ActivityData>
                                      <Avatar src={activity.user?._id?.profilePicture}/>
                                      <ActivityCreator activity={activity}/>
                                    </ActivityData>
                                    <ChipWrapper>
                                        {horas24 && <Chip outline label="24 horas" color={theme.palette.error.light}/>}
                                        {horas48 && <Chip outline label="48 horas" color={theme.palette.warning.light}/>}
                                    </ChipWrapper>
                                    
                                </ActivityWrapper>
      
                            </CardBody>
                        </Card>
                        </CardLink>
                      )
                }
                    
                
                return(
                  <CardLink 
                    to={canSeeAllCirugias && activity.cirugia.cirugiaId  ? `/app/cirugias/${activity.cirugia.cirugiaId}`:''}
                    state ={{path:location.pathname}}                                                                        
                    onClick={setScroll}    
                    key={`${activity} ${index}`}               
                  >                  
                    <Card highlight={newActivity || notSeenActivity} >
                        <CardBody >
                            <ActivityWrapper>
                                <MobileChipWrapper>
                                      <Avatar src={activity.user?._id?.profilePicture}/>
                                      {newActivity && <Chip label="Nuevo" color={theme.palette.success.light}/> }
                                      {notSeenActivity && <Chip label="Sin leer" color={theme.palette.success.light}/> }
                                      {horas24 && <Chip outline label="24 horas" color={theme.palette.error.light}/>}
                                      {horas48 && <Chip outline label="48 horas" color={theme.palette.warning.light}/>} 
                                </MobileChipWrapper>                                                           
                                <ActivityData>
                                  
                                  <ActivityCreator activity={activity}/>
                                </ActivityData>
                                
                                
                            </ActivityWrapper>

                        </CardBody>
                    </Card>
                  </CardLink>
                )


              }

            )
          }

        {nextCursor && <Button primary onClick={loadMore} loading={loadingMore}>Bajar mas actividad </Button>}
    </Wrapper>

    </ExternalWrapper>

  )

}

const Card = styled(CardWrapper)`
    background-color: ${p=>p.highlight? p.theme.palette.grey[200] : 'none'};    
    border: ${p=>p.highlight? '2px solid' + p.theme.palette.grey[500] : 'none'};    
    margin-left:0px;
    margin-right:0px;

    
`

const ExternalWrapper = styled.div`
    width:100%;
    display:flex;
    justify-content: center;

`


const Wrapper = styled.div`
    max-width: 1000px;
    display:flex;
    flex-direction: column;
    margin-bottom: 54px;
    
`

const ChipWrapper = styled.div`    
    min-width: 80px;
`

const ActivityWrapper = styled.div`
    
    display:flex;
    flex-direction: column;
    gap: 8px;
    flex:2;

    @media ${p=>p.theme.media.tablet}{
        flex-direction:row;
    }

    
`

const ActivityData = styled.div`
    display:flex;
    gap: 12px;
    flex:1;

    
`


const MobileChipWrapper = styled.div`
    display:flex;
    gap: 8px;
`

const CardLink = styled(Link)`
    text-decoration: none;    
`


export default Activity;