import React from "react";
import styled from '@emotion/styled';
import { useParams } from "react-router-dom";
import Button from "../Atoms/Button";
import CardInfo from "../Atoms/CardInfo";
import { createOptions, goFetch } from "../../api/cirugiasApi";
import { apiUrl } from "../../shared/url";
import { authenticate } from "../../shared/authHelpers";
import { useDispatch } from "react-redux";
import { loginSuccess, setConfiguration, setSubToken } from "../../app/authReducer";
import { errorMessage } from "../../app/messagesReducer";
import useSuccessButton from "../../hooks/useSuccessButton";





const EmailLinkLogin = ()=>{
    const dispatch = useDispatch()
    const params = useParams()
    const {token} = params;
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()

    
    const onClick = async()=>{
        if(isLoading || successEvent) return

        setIsLoading(true);
        const options = createOptions("POST",{test:'test'})

        const response = await goFetch(`${apiUrl}users/email-link-login/${token}`, options)
        if(response.token){
            authenticate(response)
            dispatch(loginSuccess({token: response.token, user:response.user}))
            dispatch(setSubToken(response.subToken))
            dispatch(setConfiguration(response.configuration))
        }else if(response.error){
            dispatch(errorMessage(response.error))
        }
    }


    return (
        <>
            <CardInfo>Email verificado, puedes proceder a iniciar la session. </CardInfo>
            <StyledButton success onClick={onClick}>Iniciar Session</StyledButton>   
        </>                   
    )
}



const StyledButton = styled(Button)`
    margin-top: 24px;
    border-radius: 8px;
    max-height: revert;
    height: 45px;
`


export default EmailLinkLogin;