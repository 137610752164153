import React from 'react';
import TextInput from '../../Atoms/TextInput';
import { FormWrapper, FormTitle, FormBody, FormActions, FormActionsMobile } from '../../Atoms/Forms';
import { isEmail, isLength, isCedula, isTelefono,  validate } from '../../../shared/validators';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import { crearDoctor } from '../../../app/thunkActions/doctorsActions';
import useCheckForm from '../../../hooks/useCheckForm';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { updateProfileUser } from '../../../app/thunkActions/authActions';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { setLocalStorage } from '../../../shared/authHelpers';
import { updateUserState } from '../../../app/authReducer';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { errorMessage } from '../../../app/messagesReducer';

const EditUserProfile = ({
    showTitle=true,
    close
})=>{

    
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();    
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const user = useSelector(state=>state.auth.user);
    
    const initialState = {
        id: user._id,
        firstname: user.firstname || '',
        secondname: user.secondname || '',
        lastname: user.lastname || '',
        secondLastname: user.secondLastname || '',        
        email: user.email || '',
        telefono: user.telefono || ''        
    }
    const [values,setValues] = React.useState(initialState)
    
    const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',        
        email: '',
        telefono: ''        
    })

    
    const [formCompleted, errorCount] = useCheckForm(values,errors, ["firstname", "lastname"])
    
    
    const onChangeHandler = (event)=>{
        const {name, value} = event.target
        setValues(prevState => ({...prevState, [name]:value}))
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

    const onSubmit = async (event)=>{
        event.preventDefault();        
        //dispatch(updateProfileUser(values));    
        const options = createOptions('PUT',values)

        try{
            setIsLoading(true)
            let response = await goFetch(apiUrl + `users/update/${values.id}`, options)
            //update the local storage and the store since updating localstorage wont trigger store update/
            responseStateChanges(
                response, 
                ()=>{                    
                    setLocalStorage('user', response.user);
                    dispatch(updateUserState(response.user));
                }, 
                ()=>close())                            
        }catch(error){
            console.log(error);
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }        
        

    }

    return(
        <FormWrapper onSubmit={onSubmit}>
            
            <FormTitle isTablet={isTablet}>Editar Perfil</FormTitle>
            
            <FormBody isTablet={isTablet}>
                <TextInput
                    label="Primer Nombre" name="firstname" id="firstname"
                    value={values.firstname}
                    onChangeHandler={onChangeHandler}
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Segundo Nombre" name="secondname" id="secondname"
                    value={values.secondname}
                    onChangeHandler={onChangeHandler}
                    helperText={errors.secondname}
                    error={errors.secondname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Apellido" name="lastname" id="lastname"
                    value={values.lastname}
                    onChangeHandler={onChangeHandler}
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Segundo Apellido" name="secondLastname" id="secondLastname"
                    value={values.secondLastname}
                    onChangeHandler={onChangeHandler}
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />               
               
                <TextInput
                    disabled
                    label="Email" name="email" id="email"
                    value={values.email}
                    onChangeHandler={onChangeHandler}                    
                    error={errors.email}
                    onBlurValidation={(event)=>onBlur(event, [isEmail])}
                />
                 <TextInput
                    label="Telefono" name="telefono" id="telefono"
                    value={values.telefono}
                    onChangeHandler={onChangeHandler}
                    helperText={!errors.telefono ? "Campo Obligatorio": errors.telefono}
                    error={errors.telefono}
                    onBlurValidation={(event)=>onBlur(event, [isTelefono])}
                />                                            
            </FormBody>
            <FormActionsBasic                                   
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"                
                close={close}
                disabledSuccess={!formCompleted || errorCount>0}        
            />
        </FormWrapper>
    )
}



export default EditUserProfile;