import useMediaQuery from "../../../hooks/useMediaQuery"
import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card"
import ProductTable from "./ProductTable"





const ProductosDetalle = ({
    cirugia,
    scroll
})=>{

    const isLaptop = useMediaQuery('laptop');

    //previousely used ProductosTableMobile
    return (
        <CardWrapper>
            <CardTitle>Productos</CardTitle>
            <CardBody>
                
                <ProductTable cirugia={cirugia} productos= {cirugia.productos} scroll={scroll}/>

            </CardBody>

        </CardWrapper>
    )
}






export default ProductosDetalle