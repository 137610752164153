import styled from '@emotion/styled/macro';
import { height, useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader'
import ThumbsUp from '../Icons/ThumbsUp';
import { keyframes } from '@emotion/react';

const Button = ({
    primary, 
    secondary, 
    success, 
    warning,
    error, 
    color,
    outline, 
    textColor,
    hoverColor,
    preIcon,
    postIcon,
    children,
    disabled,
    fullsize,
    to,
    type,
    loading,
    successEvent,
    tabIndex,
    ...deferred
})=>{
        let theme = useTheme();
        let buttonColor= theme.palette.grey[700];
        let buttonColorHover= theme.palette.grey[500];
        let _textColor = "white"
        let textColorHover = "white"

        if(color){
            buttonColor = color;
            buttonColorHover = hoverColor;

        }

        if(primary) {
            buttonColor = theme.palette.primary.main;
            buttonColorHover = theme.palette.primary.hover;
            textColorHover = theme.palette.primary.contrastText;
        };
        if(secondary) {
            buttonColor = theme.palette.secondary;
            buttonColorHover = theme.palette.secondary.hover;
        };
        if(success) {
            buttonColor = theme.palette.success.light;
            buttonColorHover = theme.palette.success.hover;
        };
        if(warning) {
            buttonColor = theme.palette.warning.light;
            buttonColorHover = theme.palette.warning.hover;
        }   
        if(error) {
            buttonColor = theme.palette.error.light;
            buttonColorHover = theme.palette.error.hover;
        };
        if(color) buttonColor = color;

        if(outline){
            _textColor = buttonColor;            
        }else if(!outline && !textColor){
            _textColor = "white";
        }else{
            _textColor = textColor            
        }

        if(disabled){
            buttonColor = theme.palette.grey[300]
            _textColor = theme.palette.grey[600]
        }

        //made in order to incorporate react router Link
        if(to){
            return(
                <StyledButtonLink
                    to={to}
                    role="button"
                    tabIndex="0" 
                    outline={outline}
                    disabled={disabled} 
                    fullsize={fullsize}
                    style={{
                        '--button-color': buttonColor, 
                        '--button-color-hover': buttonColorHover,
                        '--text-color-hover': textColorHover
                    }} 
                    {...deferred}
                >
                    {preIcon && <IconWrapper>{preIcon}</IconWrapper>}
                    {children &&  <StyledSpan style={{"--text-color": _textColor}}>{loading? <BeatLoader  color={!outline? "#ffff ": buttonColor} size={8}/>: children}</StyledSpan>}
                    {postIcon}
                </StyledButtonLink>

            )
        }


        //console.log(buttonColor)
        return (
            <>
            <StyledButton
                role="button"                 
                type={type}
                tabIndex={tabIndex || 0} 
                outline={outline}
                disabled={disabled} 
                fullsize={fullsize}
                style={{
                    '--button-color': buttonColor , 
                    '--button-color-hover': buttonColorHover,
                    '--text-color-hover': textColorHover
                }} 
                {...deferred}
            >
                {preIcon && <IconWrapper>{preIcon}</IconWrapper>}
               {children && <StyledSpan style={{"--text-color": _textColor}}>{children}</StyledSpan>}                
                {postIcon}
                {(loading || successEvent  )&& 
                        <Layover
                            style={{
                                '--button-color': !outline ? buttonColor : '#ffff', 
                                '--button-color-hover': buttonColorHover,
                                '--text-color-hover': textColorHover
                            }} 
                        >
                            {loading && <BeatLoader css={{lineHeight:0}} color={!outline? "#ffff ": buttonColor} size={8} />}
                            {successEvent && <LayoverAnimatedIconWrapper><ThumbsUp color={_textColor}/></LayoverAnimatedIconWrapper>}
                        </Layover>
                }
            </StyledButton>
             
            </>
        )
    
}


const bounce = keyframes`
    0%{
        transform: scale(0.5)
    }

    50%{
        transform: scale(0.75)
    }

    80%{
        transform: scale(1)
    }

    90%{
        transform: scale(1.2)
    }

    95%{
        transform: scale(1.1)
    }
    100%{
        transform: scale(1.0)

    }
`



// took out line-height: 1.75;
const StyledSpan = styled.span`
    font-weight: 600;
    color: var(--text-color);
    font-size: .875rem;
    line-height:0;        
`

const LayoverAnimatedIconWrapper= styled.div`
    animation: ${bounce} 750ms ease-out;
`

const Layover = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius: 4px;
    display: grid;
    place-content:center;
    background-color: ${p=>p.outline? "transparent": 'var(--button-color)'};

`

const StyledButton = styled.a`
    position:relative;
    padding: 5px 15px;
    border-radius: 4px;
    min-width: 64px;
    min-height: 35px;
    max-height:35px;
    width: ${p=>p.fullsize ? '100%' : "revert"};
    background-color: ${p=>p.outline? "#ffff": 'var(--button-color)'};
    border: ${p=>p.outline? `1px solid var(--button-color)`: "none"};
    text-transform: none;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    transition: transform 200ms ease-in;
    transition: background-color 200ms ease-in;
    gap:8px;
    pointer-events: ${p=>p.disabled ? "none" : "auto"};
    cursor: pointer;
    
    &:hover{
        transform: scale(1.03);
        background-color: var(--button-color-hover);
        box-shadow: 2px 2px 8px ${p=>p.theme.palette.grey[300]};
        ${StyledSpan}{
            color: var(--text-color-hover);
        }
    }      
`


const StyledButtonLink = styled(Link)`
    text-decoration:none;
    padding: 5px 15px;
    border-radius: 4px;
    min-width: 64px;
    min-height: 35px;
    max-height:35px;
    width: ${p=>p.fullsize ? '100%' : "revert"};
    background-color: ${p=>p.outline? "#ffff": 'var(--button-color)'};
    border: ${p=>p.outline? `1px solid var(--button-color)`: "none"};
    text-transform: none;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    transition: transform 200ms ease-in;
    transition: background-color 200ms ease-in;
    gap:8px;
    pointer-events: ${p=>p.disabled ? "none" : "auto"};
    cursor: pointer;
    
    &:hover{
        transform: scale(1.03);
        background-color: var(--button-color-hover);
        box-shadow: 2px 2px 8px ${p=>p.theme.palette.grey[300]};
        ${StyledSpan}{
            color: var(--text-color-hover);
        }
    }      
`


const IconWrapper = styled.div`
    line-height:0;
    
`




export default Button;