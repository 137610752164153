import styled from '@emotion/styled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, resetCirugias, setSearchValueCirugias } from '../../../../app/cirugiasReducer';
import { searchCirugia } from '../../../../app/thunkActions/cirugiasActions';
import CirugiaLoading from '../../../cirugias/Molecules/CirugiaLoading';
import CirugiasSearchBar from '../../../cirugias/Molecules/CirugiasSearchBar';
import CirugiasMdCard from './CirugiasMdCard';
import CirugiasModalMd from './CirugiasModalMd';


const CirugiasSearchMd = ()=>{
     
    const dispatch = useDispatch();
    const {cirugias, isLoading, searchValue} = useSelector(state=>state.cirugias)
    //const [searchParameter, setSearchParameter] = React.useState();
    const [openModal, setOpenModal] = React.useState(false);
    const [cirugiaId, setCirugiaId] = React.useState(null);
    const [formType, setFormType] = React.useState();

    // React.useEffect(()=>{
    //     if(searchParameter){
    //         dispatch(searchCirugia(searchParameter))
    //     }else{
    //         dispatch(resetCirugias())
    //     }
        
    // },[searchParameter,dispatch])

    const onChangeHandler = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        dispatch(loading())        
        dispatch(setSearchValueCirugias(searchTerm))
        if(searchTerm.length){
            dispatch(searchCirugia(searchTerm))
        }

        if(!searchTerm){
            dispatch(resetCirugias())
        }
    }

    const handleOnClick = (formType, cirugiaId)=>{
        setOpenModal(true);
        setCirugiaId(cirugiaId)
        setFormType(formType)
    }

    return(
        <MainWrapper>

        
            <Wrapper>
                <CirugiasSearchBar
                    value={searchValue}
                    onChange={onChangeHandler}
                    md={true}
                />

                {isLoading && searchValue.length>2 ? <CirugiaLoading/> :
                           <GridWrapper>
                                <CirugiasMdCard
                                    cirugias={cirugias}
                                    handleOnClick={handleOnClick}
                                /> 
                           </GridWrapper>                                                         
                        
                    
                }

                <CirugiasModalMd
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    formType={formType}
                    cirugiaId={cirugiaId}
                />  

            </Wrapper>
            </MainWrapper>
        )
}

const MainWrapper = styled.div`
    height: 200%;  
    display:flex;
    gap:8px;
    justify-content: center;        
`

const Wrapper = styled.div`
    max-width:800px;
    height: 200%;
    flex:3;    
    display:flex;
    flex-direction:column;        
`


const GridWrapper = styled.div`    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(px, 1fr));
    gap:12px;
    
    @media ${p=>p.theme.media.tablet}{
        grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    }

`
export default CirugiasSearchMd;