import React from "react"
import styled from '@emotion/styled';
import { useDispatch } from "react-redux";
import { login } from "../../app/thunkActions/authActions";
import PasswordInput from "../Atoms/PasswordInput"
import TextInput from "../Atoms/TextInput"
import Button from "../Atoms/Button";
import { Link } from "react-router-dom";



const Login = ()=>{

    const dispatch = useDispatch();

    const [loginValues, setLoginValues] = React.useState({
        email: "",
        password: ''
    })

    //For plain textinput and select fields
    const onChangeHandler = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setLoginValues(prevState => ({...prevState, [name]:value}))
    }


    const onClickLogin = ()=>{
        dispatch(login(loginValues));
       console.log(loginValues);
    }

    return(
        <>
            <Title>Iniciar Sesión</Title>
            <TextInput label="Email" name="email" type="email" value={loginValues.email} onChange={onChangeHandler}/>                        
            <PasswordInput label="Contraseña" name="password" value={loginValues.password} onChange={onChangeHandler}/> 
            <PasswordReset to={'/auth/email-link-login-request'}>Recuperar contraseña</PasswordReset>            
            <LoginButton success onClick={onClickLogin}>Iniciar sesión</LoginButton>                      
        </>

    )
}








const Title = styled.h1`
    margin:0;
    margin-bottom: 24px;
    align-self: center;
    font-size: 1.75rem;   
`

const LoginButton = styled(Button)`
    margin-top: 24px;
    border-radius: 8px;
    max-height: revert;
    height: 45px;
`

const PasswordReset = styled(Link)`
    font-size: 0.75rem;
    text-decoration: none;
    color: ${p=>p.theme.palette.grey[600]}

`


export default  Login