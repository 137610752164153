
import { useTheme } from "@mui/system";
import React from "react";
import Eye from "../Icons/Eye";
import EyeOff from "../Icons/EyeOff";
import IconButton from "./IconButton";
import TextInput from "./TextInput"



const PasswordInput = ({
    label,
    name, 
    value,
    onChange,
    onBlurValidation,
    error,
    helperText
})=>{
    const theme = useTheme();
    const [showPassword, setShowPassword]  = React.useState(false)

    return(
        <TextInput
            label={label}
            type={!showPassword ? 'password' : 'text'}
            name={name}
            value={value}
            onChange={onChange}
            onBlurValidation={onBlurValidation}
            error={error}
            helperText={helperText}
            endIcon={
                <IconButton onClick={(e)=>{
                    console.log('clicked icon')
                    e.preventDefault(); 
                    e.stopPropagation();
                    setShowPassword(prevState=>!prevState)
                }
                    }>
                    {!showPassword ?
                    <EyeOff color={theme.palette.grey[600]} size="22"/>:
                    <Eye color={theme.palette.grey[600]} size="22"/>}
                </IconButton>
            }
        />
    )
}



export default PasswordInput;