

export const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }



export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const getMonthNameInSpanish = (monthNumber) => {
  // Create a date object with the current year and the specified month
  const currentDate = new Date();
  currentDate.setMonth(monthNumber); 
  // Get the name of the month in Spanish
  const monthNameInSpanish = currentDate.toLocaleString('es-ES', { month: 'long' });

  // Capitalize the first letter
  return monthNameInSpanish.charAt(0).toUpperCase() + monthNameInSpanish.slice(1);
};
