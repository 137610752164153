import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const RechazarAsignacion = ({
    cirugiaId,
    close
})=>{

    const isTablet= useMediaQuery('tablet');    
    const [comments, handleChangeComments] = React.useState('');
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();

    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker('Especialista rechazo asignacion');
        
        let infoRespuesta = {
            rechazado: true,
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion: accion
          }
                
        updateCirugia("respuesta-asignacion",infoRespuesta);          
    }        
             

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Rechazar asignacion</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                    helperText="Para rechazar debes colocar comentarios."
                />                                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successText="Rechazar"
                successEvent={successEvent}
                loading={isLoading}
                disabledSuccess={!comments}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default RechazarAsignacion;