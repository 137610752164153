import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    successMessage: "",
    errorMessage: ""    
}

export const messagesSlice = createSlice({
    name:"filter",
    initialState,
    reducers: {
        successMessage(state, action){            
            state.successMessage = action.payload;                  
        },
        errorMessage(state, action){            
            state.errorMessage = action.payload;                  
        },
        resetMessage(state,action){
            state.successMessage = "";
            state.errorMessage = ""
        }         
    }
})

const {actions, reducer} = messagesSlice

export const {successMessage, errorMessage, resetMessage} = actions;

export default reducer;