import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { loadingUpdate } from '../../../app/cirugiasReducer';
import { errorMessage } from '../../../app/messagesReducer';

import { updateCirugia } from '../../../app/thunkActions/cirugiasActions';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useCirugia, { useCirugiaWithLoading } from '../../../hooks/useCirugia';
import useCommentMaker from '../../../hooks/useCommentMaker';
import useUpdateCirugia from '../../../hooks/useUpdateCirugia';
import Button from '../../Atoms/Button';
import { FormWrapper } from '../../Atoms/Forms';
import MapPin from '../../Icons/MapPin';


const ConfirmCheckIn = ({
    cirugiaId,
    close
})=>{

    const dispatch = useDispatch();
    const accionMaker = useAccionMaker();
    const commentMaker = useCommentMaker()
    const [comments, handleChangeComments] = React.useState()
    const cirugia = useCirugia(cirugiaId)
    //const [cirugias, isLoadingUpdate] = useCirugiaWithLoading()
    const {isLoading,setIsLoading, successEvent, updateCirugia} = useUpdateCirugia(close);

    const onSubmit = (event)=>{
        
        event.preventDefault();
        const accion = accionMaker(`Check-In`);
        const commentObj = commentMaker(comments)
        setIsLoading(true);     

        navigator.geolocation.getCurrentPosition((position)=>{            
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
      
            let infoCheckIn = {
              longitude: longitude,
              latitude: latitude,
              cirugiaFecha: cirugia?.info?.fecha,
              centroId: cirugia?.centro?._id,              
              cirugiaId: cirugiaId,
              comentarios: commentObj,
              accion: accion
            }
                                    
            updateCirugia('checkIn',infoCheckIn)
          }, (error)=>{
            setIsLoading(false);
            if(error.code === error.PERMISSION_DENIED){
                dispatch(errorMessage('No se pudo hacer check-in, por favor autoriza a la aplicacion acceder a tu ubicacion.'));    
            }else{
                dispatch(errorMessage('No se pudo hacer check-in'));
            }
            
          })


    }


    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <Title>Confirmar Check-in</Title>
            <Body>            
                <Text>Por favor confirma que estas en el centro asignado. Una vez hecho el check-in no podras cambiarlo.</Text>

                <TextField
                        label="Comentarios"
                        multiline
                        variant= 'outlined'
                        rows={4}
                        value={comments}
                        onChange={(e)=>handleChangeComments(e.target.value)}
                    />
            </Body>
            <Actions>
                <Button error onClick={()=>close()}>Volver</Button>
                <Button 
                    success 
                    preIcon={<MapPin size={20} color="#ffff"/>}                     
                    loading={isLoading}
                    successEvent={successEvent}
                    type="submit"
                    as="button"
                >CheckIn</Button>
            </Actions>
        </StyledFormWrapper>
    )


}


const StyledFormWrapper = styled(FormWrapper)`
    padding: 16px;    
    gap: 12px;
    

    @media ${p=>p.theme.media.tablet}{
        padding: 24px;

        max-width: 600px;
    }
`


const Wrapper = styled.div`
    width:100%;
    padding: 16px;
    display:flex;
    flex-direction: column;
    gap: 12px;

    @media ${p=>p.theme.media.tablet}{
        padding: 24px;
    }

`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    margin-bottom: 18px;
    color: ${p=>p.theme.palette.grey[900]}
    
`
const Text = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[700]};
    margin-bottom: 18px;    
    
`
const Body = styled.div`
    width:100%;
    flex:2;
    display:flex;
    flex-direction: column;
`

const Actions = styled.div`
    display:flex;
    justify-content: space-between;
`

export default ConfirmCheckIn