import React from "react";


/// hook to help manage adding of centros for Crear Doctor form. 

const useCentrosArray = (values, setValues)=>{
    const[centrosArray, setCentrosArray] = React.useState([]);
    const [centrosError, setCentrosError] = React.useState();

    const onAddCentro = ()=>{
        if(centrosArray.length<3){
            setCentrosArray(prevState=>[...prevState, 1 ])
        }else{
            setCentrosError("Se permite un maximo de 3 centros")
            setTimeout(()=>{
                setCentrosError("")
            }, 3000);
        }        
    }

    const onDeleteCentro = ()=>{
        //check to see if centro field is empty already, if so do not delete from values.centros array
        if(!centrosArray.length>values.centros.length){
            let newCentros = [...values.centros];
            newCentros.pop()
            setValues(prevState=>({...prevState, centros: newCentros}))
        }
        setCentrosArray(prevState=>prevState.slice(0,-1))    
    }

    const onChangeCentros = (event, option, index)=>{
        if(option){
            let newCentros = [...values.centros]
            newCentros.splice(index,0,{
                _id:option._id,
                nombre: option?.nombre,
                ciudad: option?.ciudad,
                zona: option?.zona
            })
            console.log(newCentros)
            setValues(prevState=>({...prevState, centros: newCentros}))
        }else{
            let newCentros = [...values.centros]
            newCentros.splice(index,1)
            setValues(prevState=>({...prevState, centros: newCentros}))
        }        
    }

    return [onAddCentro, onDeleteCentro, onChangeCentros, centrosArray, centrosError]

}


export default useCentrosArray;
