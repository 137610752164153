import React from 'react';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import styled from '@emotion/styled';
import { Transition } from 'react-transition-group';
import IconButton from '../Atoms/IconButton'
import ChevronLeft from '../Icons/ChevronLeft';
import useSwipe from '../../hooks/useSwipe';

//changed swipe mechanisim Feb 9. Nos moving tranform directity not width. Did this to move entire content of drawer. 



const MobileDrawer = ({openModal, setOpenModal, children})=>{

    const [inProp, setInProp] =  React.useState(false)
    const duration=200;
    React.useEffect(()=>{
        if(openModal)setInProp(true)
    },[openModal])

   
    const close=React.useCallback(()=>{
        setInProp(false)
        setTimeout(()=>{
            setOpenModal(false)
        }, duration)
    },[setOpenModal])
    

    const [swipeRef, diff] = useSwipe(close, 50);
    

    const defaultStyles={
        transition: `transform ${duration}ms linear`,
        transform:'translateX(-100%)'
    }

    const defaultStylesOpacity={
        transition: `opacity ${duration}ms ease-in`,
        opacity:0
    }

    const transitionOverlayStyles={
        entering:{opacity: 0.5},
        entered:{opacity: 1},
        exiting:{opacity:0.1},
        exited:{opacity:0}
    }   

    const transitionStyles ={
        entering:{transform: 'translateX(-100%)'},
        entered:{transform: `translateX(-${diff}px)`},
        exiting:{transform: 'translateX(-100%)'},
        exited:{transform: 'translateX(-100%)'}
    }

    
    return (
        <Transition 
        in={inProp} 
        timeout={duration}                 
        >
            {state=>(     
                <StyledDialogOverlay isOpen={openModal} >
                    <BackDrop style={{...defaultStylesOpacity,...transitionOverlayStyles[state]}}></BackDrop>
                    <div ref={swipeRef} style={{height:'100%'}}>
                        <StyledDialogContent aria-label="navigation-drawer" style={{...defaultStyles,...transitionStyles[state]}} diff={diff}>                            
                            <Wrapper >
                                <IconButtonContainer style={{'--header-height':'65px'}}>
                                    <IconButton onClick={close}>
                                        <ChevronLeft  color={'white'}/>
                                    </IconButton>
                                </IconButtonContainer>
                                {React.cloneElement(children, {close})}                              
                            </Wrapper>                                                        
                        </StyledDialogContent>                    
                    </div>
                </StyledDialogOverlay>
            )}
        </Transition>
    )

}


const BackDrop = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: hsla(360, 0%, 29%, 0.77);
`

const StyledDialogOverlay = styled(DialogOverlay)`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    
`
const StyledDialogContent = styled(DialogContent)`
    position:relative;
    background-color: white;
    height:100%;
    width:50%;
    box-shadow:0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%); 
    overflow:hidden;    
`

const Wrapper = styled.div`
    height:100%;
    display:flex;
    flex-direction: column;
    background-color: ${p=>p.theme.palette.grey[800]};
`
const IconButtonContainer=styled.div`
    display:flex;
    height: var(--header-height);
    flex-flow: row-reverse;
    border-bottom: 1px solid black;
    align-items: center;
`



export default MobileDrawer;