import styled from '@emotion/styled';




const CardSuccess = ({
    children,
    fontSize
})=>{

    return(
        <StyledCardSuccess fontSize={fontSize}>
            {children}
        </StyledCardSuccess>
    )
}



export const StyledCardSuccess = styled.div`
    padding: 18px;
    border-radius: 8px;
    border: 1px solid ${p=>p.theme.palette.success.light};
    background-color: ${p=>p.theme.palette.success.pastel};
    color: ${p=>p.theme.palette.success.light};
    font-weight: 600;
    font-size: ${p=>p.fontSize ? p.fontSize : 'revert'}
`

export default CardSuccess