import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { updateUserState } from '../../../app/authReducer';
import { addDoctor } from '../../../app/doctorsReducer';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { setLocalStorage } from '../../../shared/authHelpers';
import { apiUrl } from '../../../shared/url';
import EditarCentros from '../../molecules/EditarCentros';



const MdEditarCentros = ({
    md,
    close
})=>{
    const dispatch = useDispatch();    
    const initialCentrosArray = md?.centros || []
    const [centrosArray, setCentrosArray] = React.useState(initialCentrosArray)    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    
    const onSubmit = async (event)=>{
        event.preventDefault();
        setIsLoading(true)

        const options = createOptions('PUT', {centros:centrosArray})
        let response;

        response = await goFetch(apiUrl+'md-users/update/'+ md?._id, options);
        //const updatedUser = response.user
        responseStateChanges(
            response,
            null,
            ()=>{
                setLocalStorage('user', response.user);
                dispatch(updateUserState(response.user));    
                close()}
        )        
    }


    return(
       <EditarCentros
        onSubmit={onSubmit}
        initialCentrosArray={initialCentrosArray}
        centrosArray={centrosArray}
        setCentrosArray={setCentrosArray}
        close={close}
        isLoading={isLoading}
        successEvent={successEvent}
       />
    )

}






export default MdEditarCentros; 