import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { CardBody, CardHeader, CardTitle, CardWrapper as Card } from "../Atoms/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly, faReceipt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermissions from '../../hooks/usePermissions';


const Operaciones = () => {
    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');

    const permissions = usePermissions()

    //temporary permission
    const canCrearReglas = true    
    const {
        canInvitarUsuarios,                
    }=permissions
    

    return (
        <Wrapper isTablet={isTablet}>
            { canCrearReglas &&
                <CardLink to="/app/operaciones/gestionAgendaCirugias">
                    <StyledCard>
                        <CardHeader>
                            <FontAwesomeIcon icon={faUser} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                            <StyledCardTitle>
                                Reglas para Agendar
                            </StyledCardTitle>
                        </CardHeader>                   
                        <CardBody>
                            <Text>Gestion de reglas para agendar cirugias</Text>
                        </CardBody>
                    </StyledCard>    
                </CardLink>
            }
            { true &&
                <CardLink to="/app/operaciones/activosFijos">
                    <StyledCard>
                        <CardHeader>
                            <FontAwesomeIcon icon={faUser} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                            <StyledCardTitle>
                                Activos fijos e instrumentos
                            </StyledCardTitle>
                        </CardHeader>                   
                        <CardBody>
                            <Text>Creacion de activos fijos e instrumentos</Text>
                        </CardBody>
                    </StyledCard>    
                </CardLink>
            }        
        </Wrapper>
        
    )
};




const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, auto));
    width: ${p=>p.isTablet ? '50%' : '100%'};
    margin-left:auto;
    margin-right:auto;
`



const CardLink = styled(Link)`
    text-decoration: none;
    

`
const StyledCardTitle = styled(CardTitle)`
    text-decoration: none;
    color: ${p=>p.theme.palette.grey[800]};
    margin: 0px;
    line-spacing: 0px;
    
`



const StyledCard = styled(Card)`

    min-width: 300px;
`

const Text = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]};
    
`

export default Operaciones;
