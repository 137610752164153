import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Atoms/Button';


const LandingHeader = ()=>{
    const isTablet = useMediaQuery('tablet');

    return(
        <>
            <Header>
                    <ImageRelativeContainer>
                    
                    <HeaderWrapper>                    
                        
                        {isTablet && <Logo src='/img/Logo_Inicio.png' alt="company logo"/>}
                        <div style={{display:'flex', gap: '8px', zIndex: 2, alignItems: 'center' }}>
                            {isTablet && <RegisterButton success to={"register-company"}>Registra tu empresa</RegisterButton> }
                            <LoginLink  primary to={"/auth/login"}>Login</LoginLink>
                            
                        </div>
                        <ImageFondoLogin src='img/Fondo_Login.png'/>    

                    </HeaderWrapper>

                    
                        
                    </ImageRelativeContainer>  
            </Header>

        {!isTablet && <LogoWrapperPhone><Logo src='/img/Logo_Inicio.png' alt="company logo"/></LogoWrapperPhone>}
        </>
    )
}




const Header = styled.div`
    height: 30%;    
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;            
`

const ImageRelativeContainer = styled.div`
    position:relative;
    width:100%;
    
    min-height: 200px;
    overflow:hidden;
`

const HeaderWrapper = styled.div`    
    width:100%;    
    display:flex;
    align-items: center;
    justify-content: flex-end;

    @media ${p=>p.theme.media.tablet}{
        justify-content: space-between;
    }
    
`

const ImageFondoLogin = styled.img`    
    position:absolute;
    
    top:-150px;
    right:-70px;

    height: 180%;
    object-fit:contain;
    aspect-ratio: 1/1;

    @media ${p=>p.theme.media.tablet}{
        top:-150px;
        right:-120px;
        height: 200%;
    }
`

const Logo = styled.img`    
    max-width: 375px;
    z-index: 2;
    
    @media ${p=>p.theme.media.laptop}{
        max-width: 350px;
    }
`

const RegisterButton = styled(Button)`
    border-radius: 18px;
    background: linear-gradient(90deg, rgba(0,141,54,1) 0%, rgba(57,169,53,1) 35%, rgba(147,192,31,1) 100%);
`


const LoginLink = styled(Link)`
    text-decoration: none;
    padding: 16px;
    color: white;
    font-weight: 600;

`

const LogoWrapperPhone = styled.div`
    display:flex;
    width:100%;
    justify-content: center;

`


export default LandingHeader;