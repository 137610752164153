import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { addDoctor } from '../../../app/doctorsReducer';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import FormActionsBasic from "../../Atoms/FormActionsBasic"
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import VentasAutocomplete from '../../Forms/CustomAutocomplete/VentasAutocomplete';



const DoctorEditarVentas = ({
    close,
    doctor
})=>{
    const dispatch = useDispatch();
    const [ventas, setVentas] = React.useState({})    
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    const onChangeValuesAutocomplete = (event, option, name)=>{
                
        const info = {
                ventasId: option?._id,
                ventasFullname: `${option?.firstname} ${option?.lastname}`                 
            }

        if(option){
            setVentas(info)
        }else{
            setVentas({})
        }
        
    }


    const onSubmit = async (event)=>{
        event.preventDefault();
        setIsLoading(true)
        
        const options = createOptions('PUT', ventas)
        let response;

        try {
            response = await goFetch(apiUrl+'doctors/ventas/update/'+ doctor?._id, options);
            responseStateChanges(response, ()=>dispatch(addDoctor(response.doctor)), ()=>close())            
        } catch (error) {
            console.log(error)            
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }

        
        
    }


    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Editar ejecutivo de ventas asignado</FormTitle>
            <FormBody>

                <VentasAutocomplete onChange={onChangeValuesAutocomplete}/>

            </FormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"                                
                loading={isLoading}
                successEvent={successEvent}   
                type="submit"
                as="button"         
            />

            
        </FormWrapper>
    )

}




export default DoctorEditarVentas; 