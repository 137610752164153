import React from 'react';
import styled from '@emotion/styled';
//import { Transition } from 'react-transition-group';


const Wrapper = ({children})=>{

    const [open, setOpen] = React.useState();

    const onClick = ()=>{
        setOpen(!open)
        console.log('Accordion clicked')
    }

    return (
            <StyledAccordionWrapper>
                {React.cloneElement(children[0],{onClick})}
                {React.cloneElement(children[1],{open})}
                {children.length>2 && React.cloneElement(children[2],{open})}                                
            </StyledAccordionWrapper>
    )
}

const Header = ({children, onClick})=>{

    const onKeyUp = (event)=>{
        if(event.keyCode === 13){
            onClick();
        }
    }

    return(
        <StyledAccordionHeader aria-expanded='false' role='button' tabIndex='0' onClick={onClick} onKeyUp={onKeyUp}>
            {children}
        </StyledAccordionHeader>
    )
}


const Content = ({children,open})=>{
    

    return(                
         <StyledAccordionContent role='region' open={open}>
             {children}
        </StyledAccordionContent>                                             
    )
                   
}

const Actions = ({children, open})=>{

    return(
        <StyledActions role='region' open={open}>
            {children}
        </StyledActions>
    )

}


const StyledAccordionWrapper = styled.div`
    background-color: white;
    width:100%;
    height: auto;    
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 
    0px 1px 1px 0px rgb(0 0 0 / 14%), 
    0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin-bottom: 16px;
    border-radius: 10px;
`

const StyledAccordionHeader = styled.div`
    min-height:120px;
    outline:none;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
`

const StyledAccordionContent = styled.div`
    overflow:hidden;     
    max-height: ${p=>p.open ? '1000px': '0px'};
    transition: max-height 400ms ease-out;
    
`

const StyledActions = styled.div`
    overflow:hidden;
    border-top: ${p=>p.open ? `1px solid ${p.theme.palette.grey[400]}` : "none"};
    max-height: ${p=>p.open ? '300px': '0px'};
    transition: max-height 400ms ease-out;
    
`




const Accordion = {
    Wrapper, Header, Content, Actions
}


export default Accordion

