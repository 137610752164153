
import styled from "@emotion/styled/macro";
import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
// import {
//     Menu,
//     MenuButton,
//   } from "@reach/menu-button";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutSuccess } from "../../app/authReducer";
import { signout } from "../../shared/authHelpers";
import Avatar from "../Atoms/Avatar";
//import { StyledMenuItem, StyledMenuLink, StyledMenuList } from "../Atoms/StyledDropdownReach";
import Bell from "../Icons/Bell";


// const AvatarMenu = ({
//     setFormType,
//     setOpenModal

// })=>{
//     const dispatch= useDispatch();
//     const theme = useTheme();
//     const user = useSelector(state=>state.auth.user)

//     const onSelect = (formType)=>{
//        setOpenModal(true);
//        setFormType(formType);
//        //setCirugiaId(cirugiaId);
//       }

//     const onSelectSignout = ()=>{
//         signout()
//         dispatch(logoutSuccess())
//     }

    

//     return(
//         <Menu>
//         {({isExpanded})=>{              
//             isExpanded ? document.querySelector('body').style.overflow='hidden' :
//                         document.querySelector('body').style.overflow='visible'
//             return(
//                 <>
//                 <AvatarMenuButton>                
//                     <Avatar src={user?.profilePicture}>T</Avatar>
//                 </AvatarMenuButton>
//                 <StyledMenuList>
//                     <StyledMenuLink to={'profile'}>
//                         <MenuItemWrapper>
//                             <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
//                             <Text>Ver Perfil</Text>
//                         </MenuItemWrapper>
//                     </StyledMenuLink>   
//                     <StyledMenuItem onSelect={()=>onSelect('notification')}>
//                         <MenuItemWrapper>
//                             <Bell size="18" color={theme.palette.grey[600]} />
//                             <Text>Notificaciones</Text>
//                         </MenuItemWrapper>
//                     </StyledMenuItem>                    
//                     <StyledMenuItem onSelect={onSelectSignout}>
//                         <MenuItemWrapper>
//                             <FontAwesomeIcon icon={faSignOutAlt} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
//                             <Text>Cerrar Session</Text>
//                         </MenuItemWrapper>
//                     </StyledMenuItem>
//                 </StyledMenuList>
//             </>
//             )
//         }}       
//     </Menu>
//     )
// }








const AvatarMenu = ({
    setFormType,
    setOpenModal

})=>{
    const dispatch= useDispatch();
    const theme = useTheme();
    const user = useSelector(state=>state.auth.user)

    const onSelect = (formType)=>{
       setOpenModal(true);
       setFormType(formType);
       //setCirugiaId(cirugiaId);
      }

    const onSelectSignout = ()=>{
        signout()
        dispatch(logoutSuccess())
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }
    

    return(
        
                <>
                <AvatarMenuButton
                    onClick={onClickHandler}
                >                
                    <Avatar src={user?.profilePicture}>T</Avatar>
                </AvatarMenuButton>
                <Menu
                     open={open}
                     onClose={onCloseHandler}
                     anchorEl={anchorEl}
                >
                    <StyledMenuLink to={'profile'} onClick={onCloseHandler}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Ver Perfil</Text>
                        </MenuItemWrapper>
                    </StyledMenuLink>   
                    <MenuItem onClick={()=>{
                                            onSelect('notification')
                                            setAnchorEl(null)
                                            }}
                    >
                        <MenuItemWrapper>
                            <Bell size="18" color={theme.palette.grey[600]} />
                            <Text>Notificaciones</Text>
                        </MenuItemWrapper>
                    </MenuItem>                    
                    <MenuItem onClick={()=>{
                                            onSelectSignout()
                                            setAnchorEl(null)
                                        }}
                    >
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faSignOutAlt} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Cerrar Session</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                </Menu>
            </>
           
    )
}

const AvatarMenuButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;

`

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Text = styled.p`
    margin:0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}
`

export const StyledMenuLink = styled(Link)`
display: inline-block;

text-decoration:none;
width: 100%;
padding: 12px 18px;
font-size: 0.875rem;

&:hover{
    background-color: ${p=>p.theme.palette.grey[300]}
}
`

export default AvatarMenu;