import styled from "@emotion/styled/macro";
import { faDollarSign, faEdit, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizontal from "../../Icons/MoreHorizontal";







const MoreButtonClient = ({
    setOpenModal,
    setFormType,
    setClientToModify,
    client
})=>{
    
    const theme = useTheme();
    
    

    const onSelect = (formType)=>{
       setOpenModal(true);
       setFormType(formType);
       setClientToModify(client);
      }


    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }
    
   
            return(
                <>
                <MoreButton
                    onClick={onClickHandler}
                >                
                    <MoreHorizontal color={theme.palette.grey[600]}/>
                </MoreButton>
                <Menu
                     open={open}
                     anchorEl={anchorEl}
                     onClose={onCloseHandler}
                >                                
                    <MenuItem onClick={()=>{
                                            onSelect('editarClient')
                                            setAnchorEl(null)
                                            }}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faEdit} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Editar</Text>
                        </MenuItemWrapper>
                    </MenuItem>                   
                    <MenuItem onClick={()=>{
                                            onSelect('quickClient')
                                            setAnchorEl(null)
                                            }}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faFileInvoice} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Acceso Cot.</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                    <MenuItem onClick={()=>{
                                            onSelect('priceList')
                                            setAnchorEl(null)
                                            }}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faDollarSign} style={{fontSize: "0.9rem"}} color={theme.palette.grey[600]}/>
                            <Text>Price List</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                   
                </Menu>
            </>
            )
     
}

const MoreButton = styled.button`
    border-radius: 50%;
    font-size: 1.5rem;
    padding: 12px;
    text-align:center;
    background-color: ${p=>p.color ? p.color:'transparent'};
    border:0;
    outline:0;
    display: inline-flex;
    align-self: center;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }

`

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Text = styled.p`
    margin:0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}
`

export default MoreButtonClient;
