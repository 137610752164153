import React from "react";
import { useDispatch } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { addClientsSearch} from "../../../app/clientsReducer";
import { fetchClients } from "../../../app/thunkActions/clientsActions";
import { apiUrl } from "../../../shared/url";
import SearchBar from "../../Atoms/SearchBar";




const ClientsSearchBar = ({
        
    setIsLoading
})=>{
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = React.useState();


    const onChangeSearchValue = async (event)=>{
        event.preventDefault();
        
        const searchTerm = event.target.value
        setSearchValue(searchTerm)
        if(searchTerm.length>2){            
            setIsLoading(true)
            const options = createOptions('GET')            
            const clients = await goFetch(apiUrl+ "clients/search/" + searchTerm, options);
            if(clients){
                console.log(clients)
                setIsLoading(false)
                dispatch(addClientsSearch(clients))
            }

        }
        if(!searchTerm){                        
            dispatch(fetchClients())        
        }
         

    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar Clientes"
        value={searchValue}
        onChange={onChangeSearchValue}
    />
    )
}



export default ClientsSearchBar