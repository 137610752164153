import {createSlice } from '@reduxjs/toolkit';


//doctors search was created to separate the state of doctors used in autocomplete and doctors used in search page
const initialState= {
    doctors:[],
    doctorsSearch: [],    
    doctor: null,
    nextCursor: "",
    isLoading: true,
    isLoadingMore: false,
    filter: {},
    searchValue:"",
    selected: ""
    
}

export const doctorsSlice = createSlice({
    name:"doctors",
    initialState,
    reducers: {
        loadingDoctors(state, action){
            state.isLoading = true
        },
        loadingMoreDoctors(state,action){
            state.isLoadingMore = true;
        },
        addDoctors(state, action){
           console.log(action.payload);
            state.doctors = action.payload.doctors;
            state.nextCursor = action.payload.nextCursor;
            state.isLoading= false;            
        },
        addDoctorsSearch(state, action){
            console.log(action.payload);
             state.doctorsSearch = action.payload.doctors;
             state.nextCursor = action.payload.nextCursor;
             state.isLoading= false;            
         },
        addNextDoctors(state, action){
             console.log(action.payload);
             state.doctorsSearch = [...state.doctorsSearch, ...action.payload.doctors];
             state.nextCursor = action.payload.nextCursor;
             state.isLoadingMore= false;            
         },
        addDoctor(state,action){
            state.doctor = action.payload
            state.isLoading= false;            
        },
        resetDoctors(state,action){
            state.doctors = [];
        },
        resetDoctorsSearch(state,action){
            console.log('reset doctors search')
            state.doctorsSearch = [];
        },       
        resetDoctor(state,action){
            state.doctor = null;
        },
        setFilter(state,action){
            state.filter = action.payload
        },
        setSearchValue(state,action){
            state.searchValue = action.payload
        },
        setSelected(state,action){
            state.selected = action.payload
        }      
    }
})

const {actions, reducer} = doctorsSlice

export const {loadingDoctors, loadingMoreDoctors, addDoctors, addDoctorsSearch, addNextDoctors, addDoctor, resetDoctors, resetDoctor,
   resetDoctorsSearch, setFilter, setSearchValue, setSelected
} = actions;

export default reducer;