import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { errorMessage} from '../../../app/messagesReducer';
import useCheckForm from '../../../hooks/useCheckForm';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import { isMaxLength} from '../../../shared/validators';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import {FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import TextInput from '../../Atoms/TextInput';
import useForm from '../../../hooks/useForm';

const AddActivosFijos = ({
    close
}) => {
    const dispatch = useDispatch();  
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    const initialState = {
        codigo: "",
        descripcion: "",
        ubicacion: "",
        fechaAdquisicion: "",
        valorAdquisicion: "",
        fabricante: "",
        serial: ""        
    };

    const [values, setValues] = React.useState(initialState);

    const [errors, setErrors] = React.useState({
        codigo: "",
        descripcion: "",
        ubicacion: "",
        fechaAdquisicion: "",
        valorAdquisicion: "",
        serial: ""
    });

    const {onChangeValuesValidation, trimValue} = useForm({setValues, setErrors, errors});

    const [formCompleted, errorCount] = useCheckForm(values, errors, [
        "codigo",
        "descripcion",
        "serial"
    ]);

    console.log(errorCount);
    console.log(formCompleted);

   

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
     
        const trimmedValues = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [key, trimValue(value)])
        );
     
        const options = createOptions("POST", trimmedValues);
        try {
            const response = await goFetch(apiUrl + "activosFijos", options);
            responseStateChanges(response, null, () => close());                                
        } catch(err) {
            dispatch(errorMessage(err.error));
        }
    };

    return (
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle>Agregar Activo Fijo</FormTitle>
            <StyledFormBody>
                <TextInput 
                    label="Código"
                    name="codigo"
                    value={values.codigo}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(20, event.target.value, true)])}                    
                    helperText={!errors.codigo ? "Campo Obligatorio" : errors.codigo}
                    error={errors.codigo}                    
                />
                <TextInput 
                    label="Descripción"
                    name="descripcion"
                    value={values.descripcion}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(200, event.target.value)])}                    
                    helperText={!errors.descripcion ? "Campo Obligatorio" : errors.descripcion}
                    error={errors.descripcion}                     
                />
                <TextInput 
                    label="Serial"
                    name="serial"
                    value={values.serial}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(50, event.target.value)])}                    
                    helperText={!errors.serial ? "Campo Obligatorio" : errors.serial}
                    error={errors.serial}                     
                />
                <TextInput 
                    label="Fabricante"
                    name="fabricante"
                    value={values.fabricante || ''}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(50, event.target.value)])}                    
                    helperText={errors.fabricante}
                    error={errors.fabricante}                     
                />
                <TextInput 
                    label="Fecha de Adquisición"
                    name="fechaAdquisicion"
                    type="date"
                    value={values.fechaAdquisicion || new Date().toISOString().split('T')[0]}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [])}
                    helperText={errors.fechaAdquisicion}
                    error={errors.fechaAdquisicion}                     
                />
            </StyledFormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"                                
                loading={isLoading}
                successEvent={successEvent}   
                type="submit"
                as="button"       
                disabledSuccess={!formCompleted || errorCount > 0}  
            />
        </StyledFormWrapper>
    );
};

const StyledFormWrapper = styled(FormWrapper)`
    padding-left: 12px;
    padding-right: 12px;

    @media ${p => p.theme.media.tablet} {
        min-width: 500px;
    }
`;

const StyledFormBody = styled(FormBody)`
    flex: 2;
    margin-bottom: 18px;
`;

export default AddActivosFijos;