//import { utcToZonedTime } from 'date-fns-tz'




const formatDate = (date2)=>{
  if(!date2) return '[fecha pendiente]'
  let dateToFormat = new Date(date2)
  //let dateToFormat = utcToZonedTime(date2, 'America/New_York')
  //console.log(date2)
  //console.log(dateToFormat)
  //console.log(dateToFormatNew)

  let dayNumber = dateToFormat.getDay();
  let date = dateToFormat.getDate();
  let monthNumber= dateToFormat.getMonth();
  let day;
  let month;

  switch(dayNumber){
    case 0:
      day = "Dom"
     break;
    case 1:
      day = "Lun"
      break;
    case 2:
      day = "Mar"
      break;
    case 3:
      day = "Mie"
      break;
    case 4:
      day = "Jue"
      break;
    case 5:
      day = "Vie"
      break;
    case 6:
      day = "Sab"
      break;
    default:
      break;
  }

  switch(monthNumber){
    case 0:
      month = "Enero"
     break;
    case 1:
      month = "Feb"
      break;
    case 2:
      month = "Marzo"
      break;
    case 3:
      month = "Abril"
      break;
    case 4:
      month = "Mayo"
      break;
    case 5:
      month = "Junio"
      break;
    case 6:
      month = "Julio"
      break;
    case 7:
      month = "Ago"
      break;
    case 8:
      month = "Sept"
    break;
    case 9:
      month = "Oct"
      break;
    case 10:
      month = "Nov"
      break;
    case 11:
      month = "Dic"
      break;
    default:
      break;
  }




  let formattedDate = `${day} ${month} ${date}`

  return formattedDate

}


export default formatDate;
