import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';




const Summary = ()=>{
    const theme = useTheme();
    return(
        <MainRowWrapper >                    
                        <MainTitle>Una plataforma para mejorar la coordinacion y asi dar un mejor servicio a los pacientes</MainTitle>
                        <ParagraphWrapper>
                            
                            <Text>Sabemos lo importante que es poder coordinar cirugias entre las casas comerciales, doctores, pacientes, hospitales y aseguradoras.</Text>
                            <Text>Soñamos con crear una plataforma que permita aprovechar la tecnologia mas moderna para facilitar esta coordinacion.</Text>
                            <Text> Mejorar la coordinacion permitira dar
                                un servicio a los pacientes mas eficiente y efectivo lo que se traducira en mejorar la calidad de servicio y reducir los costos del sistema de salud.</Text>
                            <Text><span style={{fontWeight: '800'}}>Xirugias</span> es el resultado de ese sueño. </Text>
                            <Text>Acompañanos a transformar el sector de dispositivos medicos en America Latina. </Text>
                            
                        </ParagraphWrapper>
                        
                    </MainRowWrapper>        

    )
}


const MainRowWrapper = styled.div`
    display:flex;
    position: relative;
    background-color: ${p=>p.theme.palette.primary.dark};
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: ${p=>p.reverse ? 'column-reverse' : 'revert'};
    justify-content: center;
    

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
    }

`


const MainTitle=styled.h1`
    margin:0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    color: white;
    font-size: 2rem;
    padding: 16px;
    
    @media ${p=>p.theme.media.laptop}{
        font-size: 2.25rem;
    }
`



const ParagraphWrapper = styled.div`
    
    padding: 32px;
    line-height: 1.8;
    align-items: center;
    @media ${p=>p.theme.media.tablet}{
        max-width: 75%;
    }

`

const Text = styled.p`
    margin: 0;
    padding-bottom: 16px;
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
    line-height:1.1;
    text-align: center;
`

export default Summary;