import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCirugia from '../../hooks/useCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import styled from '@emotion/styled/macro';
import Select from '../Atoms/Select';
import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../Atoms/CustomMuiInputLabel"
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/system';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const ModifyDocumentos = ({
    cirugiaId,
    close
})=>{

    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);
    const cirugia = useCirugia(cirugiaId);
    const documentos = cirugia?.documentos;
    console.log(cirugia)
    console.log(documentos)

    const initialValues = documentos && {
        documentos:   {
            carnet: {
              required: documentos?.carnet?.required,
              delivered: documentos?.carnet?.delivered
            },
            cedula: {
              required: documentos?.cedula?.required,
              delivered: documentos?.cedula?.delivered
            },
            aprobacion: {
              required: documentos?.aprobacion?.required,
              delivered: documentos?.aprobacion?.delivered
            },
            formulario: {
              required: documentos?.formulario?.required,
              delivered:documentos?.formulario?.delivered
            },
            indicacion: {
              required: documentos?.indicacion?.required,
              delivered: documentos?.indicacion?.delivered
            },
            pago: {
              required: documentos?.pago?.required,
              delivered: documentos?.pago?.delivered
            }
        }
    }

    const [values, setValues] = React.useState(initialValues ?? null);
   
    const uiValues = {
        carnet: (values.documentos.carnet.required && values.documentos.carnet.delivered && 'En oficina') ||
                (values.documentos.carnet.required && !values.documentos.carnet.delivered && 'Pendiente') ||
                (!values.documentos.carnet.required && 'No aplica'),
        cedula: (values.documentos.cedula.required && values.documentos.cedula.delivered && 'En oficina') ||
                (values.documentos.cedula.required && !values.documentos.cedula.delivered && 'Pendiente') ||
                (!values.documentos.cedula.required && 'No aplica'),
        aprobacion: (values.documentos.aprobacion.required && values.documentos.aprobacion.delivered && 'En oficina') ||
                (values.documentos.aprobacion.required && !values.documentos.aprobacion.delivered && 'Pendiente') ||
                (!values.documentos.aprobacion.required && 'No aplica'),
        formulario: (values.documentos.formulario.required && values.documentos.formulario.delivered && 'En oficina') ||
                (values.documentos.formulario.required && !values.documentos.formulario.delivered && 'Pendiente') ||
                (!values.documentos.formulario.required && 'No aplica'),
        indicacion: (values.documentos.indicacion.required && values.documentos.indicacion.delivered && 'En oficina') ||
                (values.documentos.indicacion.required && !values.documentos.indicacion.delivered && 'Pendiente') ||
                (!values.documentos.indicacion.required && 'No aplica'),
        pago: (values.documentos.pago.required && values.documentos.pago.delivered && 'En oficina') ||
                (values.documentos.pago.required && !values.documentos.pago.delivered && 'Pendiente') ||
                (!values.documentos.pago.required && 'No aplica')
      }
    

  
    const accionMaker = useAccionMaker();
       
     
    
    const options = [
        {label: "Carnet", name:"carnet"},
        {label: "Cedula", name:"cedula"},
        {label: "Aprobacion", name:"aprobacion"},
        {label: "Formulario", name:"formulario"},
        {label: "Indicacion", name:"indicacion"},
        {label: "Pago", name:"pago"}
    ]
      
    const onChangeValuesDocumentos = (event) =>{
        const {name, value} = event.target;
        let newObj = {}
      
        if (value=== 'Pendiente'){
          newObj.required = true;
          newObj.delivered = false;
          }
          else if (value === 'En oficina'){
            newObj.required = true;
            newObj.delivered = true;
            }
            else if (value === 'No aplica') {
              newObj.required = false;
              newObj.delivered = false;
              }
      
        console.log(newObj)
        let newDocumentos = {...values.documentos, [name]: newObj}
        setValues(prevState=>({...prevState, documentos: newDocumentos }))
        console.log(values)
      } 

    

      const color = (value)=>{
        let color

        if(value === "En oficina") color = theme.palette.success.light;
        if(value === "Pendiente") color = theme.palette.error.light;
        if(value === "No Aplica") color = "revert"; 
        
        return color;

      }

      const onSubmit = (event) =>{
        event.preventDefault();
    
        const accion = accionMaker(`Status Documentos Actualizados`);

        console.log(values);
        let infoDocumentos = {
            documentos: values.documentos,
            cirugiaId: cirugiaId,
            accion:accion
          }
        
        updateCirugia("update-documentos",infoDocumentos)
      
    }


      

    return(
      
        <FormWrapper  onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Modificar Documentos</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                {
                    options.map((option, index)=>(
                    <FormControl key={`${option} ${index}`} fullWidth>
                        <StyledLabel colorCalc={color(uiValues[option.name])}>{option.label}</StyledLabel>
                        <StyledSelect
                            id={option.name}
                            value={uiValues[option.name]}                
                            onChange={onChangeValuesDocumentos}
                            name={option.name}
                            colorCalc={color(uiValues[option.name])}
                            
                        >
                            <MenuItem value ="En oficina">En oficina</MenuItem>
                            <MenuItem value ="Pendiente">Pendiente</MenuItem>
                            <MenuItem value ="No aplica">No aplica</MenuItem>
                        </StyledSelect>
                    </FormControl>

                    ))
                }
            
                                            
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
            />
        
        </FormWrapper>
    

        
    )
}


const StyledSelect = styled(Select)`
    color: ${p=>p.colorCalc};
    & fieldset{
        border-color: ${p=>p.colorCalc};
        border-width: 2px;
    };

    &:hover .MuiOutlinedInput-notchedOutline{
        border-color: ${p=>p.colorCalc};
        border-width: 3px;
    };

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${p=>p.colorCalc};
        border-width: 3px;
    };
`

const StyledLabel = styled(CustomInputMuiLabel)`
    &.MuiInputLabel-root.Mui-focused{
        color: ${p=>p.colorCalc};
    };
    &.MuiInputLabel-root.MuiFormLabel-filled{
        color: ${p=>p.colorCalc};
    };
`



export default ModifyDocumentos;