import styled from '@emotion/styled';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addUpdatedCirugia } from '../../app/cirugiasReducer';
import { errorMessage, successMessage } from '../../app/messagesReducer';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';
import { getCookie } from '../../shared/authHelpers';
import { apiUrl } from '../../shared/url';
import Button from '../Atoms/Button';
import TextInput from '../Atoms/TextInput';



const DeleteArchivo = ({
    cirugiaId,
    deleteInfo,
    close,
    setOpenModal
})=>{

    const dispatch = useDispatch();
    const [disabled, setDisabled] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState();
    const [successEvent, setSuccessEvent] = React.useState();
    const [text, setText] = React.useState("")
    
    const onChange = (event)=>{        
        setText(event.target.value)
    }

    React.useEffect(()=>{
        if(text.toLowerCase() === "borrar"){
            setDisabled(false)
        }else{
            setDisabled(true)
        }
    },[text])

    const accionMaker = useAccionMaker(`Borrado archivo: ${deleteInfo?.docType}`); //provides user and accion object.  


    const deleteImage = async (docType, url)=>{
        let accion = accionMaker(`Borrado archivo: ${deleteInfo.docType}`)
        const token = getCookie('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
             }
        }

        try{
            setIsLoading(true)
            const response = await axios.post(apiUrl + 'cirugias/delete-files/' + cirugiaId, {docType:docType, url:url, accion:accion},config)            
            dispatch(addUpdatedCirugia(response.data.cirugia))
            setSuccessEvent(true)
            dispatch(successMessage("Archivo borrado exitosamente!"))
            setIsLoading(false)
            setTimeout(()=>{
                close && close();
                setSuccessEvent(false);
            }, 1500)
        }catch(error){
            console.log(error);
            setIsLoading(false)            
            dispatch(errorMessage("Problema de servidor, por favor intente mas tarde."))
        }            
    } 


    return(
        <DeleteWrapper>
            <Title>Borrar Archivo</Title>
            <Body>
                <Text>Borrar el archivo es definitivo. Por favor confirmar escribiendo la palabra <Italic>borrar</Italic>.</Text>
                <TextInput placeholder="borrar" onChange={onChange} value={text}/>
            </Body>
            
            <Actions>   
                <Button outline error onClick={()=>close()}>Volver</Button>
                <Button 
                    success 
                    disabled={disabled} 
                    onClick={()=>deleteImage(deleteInfo.docType, deleteInfo.url)} 
                    loading={isLoading}
                    successEvent={successEvent}
                >Borrar</Button>

            </Actions>
    </DeleteWrapper>
    )


}


const DeleteWrapper = styled.div`
  padding: 16px;
`


const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    padding-bottom: 36px;
`

const Text = styled.p`
  margin: 0;
  font-size: 0.875;
  color: ${p=>p.theme.palette.grey[600]}
`

const Italic = styled.span`
    font-style: italic;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[800]}
`

const Body = styled.div`
    display:flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
`

const Actions = styled.div`
    display:flex;
    justify-content: space-between;
`


export default DeleteArchivo;