import styled from '@emotion/styled';
import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import SmallButton from '../../Atoms/SmallButton';
import TextInput from '../../Atoms/TextInput';
import X from '../../Icons/X';

const ProductTable =({
    cirugia,
    productos,
    onAddProduct,
    onDeleteProducts,
    laptopWidth,
    consumo,
    onChangeHandler,
    deleteProducts,
    scroll = true
})=>{
    let containerWidth = "100%"
    const isLaptop = useMediaQuery('laptop');
    if(isLaptop) containerWidth = laptopWidth || "100%";
    const productTable =React.useRef()

    React.useEffect(()=>{
        if(productos.length>0 && scroll){
            productTable?.current?.scrollIntoView({
                behavior: "smooth"
            });
        }
        

        console.log(productTable.current)
    },[productos])

    
    console.log(productos);
    return (
        <>
            {
                productos?.length > 0 && 

                <TableContainer style={{'--container-width': containerWidth}}>
                    <Table ref={productTable}>
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell>Codigo</TableHeadCell>
                                <TableHeadCell>Descripcion</TableHeadCell>
                                {isLaptop && <TableHeadCell style={{textAlign: "center"}}>Aprobado</TableHeadCell>}
                                {isLaptop && cirugia?.reporte?.enviadoProductos && <TableHeadCell style={{textAlign: "center"}}>Usado</TableHeadCell>}
                                {isLaptop && consumo && <TableHeadCell style={{textAlign: "center"}}>Consumo</TableHeadCell>}

                                {!isLaptop && !cirugia?.reporte?.enviadoProductos && <TableHeadCell style={{textAlign: "center"}}>Aprobado</TableHeadCell>}                                
                                {!isLaptop && cirugia?.reporte?.enviadoProductos && <TableHeadCell style={{textAlign: "center"}}>Apr./Usado</TableHeadCell>}
                                
                                { deleteProducts && 
                                    <TableHeadCell></TableHeadCell>
                                    }
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {productos.map((producto, index)=>(            
                                    <TableRow key={`${producto._id}${index}`}>
                                        <TableCell>{producto.producto?.codigo}</TableCell>
                                        <TableCell>{producto.producto?.descripcion}</TableCell>
                                        {isLaptop && <TableCell style={{textAlign: "center"}}>{producto.cantidad || 0}</TableCell>}
                                        {isLaptop && cirugia?.reporte?.enviadoProductos && <TableCell style={{textAlign: "center"}}>{producto?.cantidadUsada || 0}</TableCell>}                                        
                                        
                                        {!isLaptop && !cirugia?.reporte?.enviadoProductos && <TableCell style={{textAlign: "center"}}>{producto.cantidad || 0}</TableCell>}                                        
                                        {!isLaptop && cirugia?.reporte?.enviadoProductos && <TableCell style={{textAlign: "center"}}>{`${producto.cantidad || 0} / ${producto?.cantidadUsada || 0}`}</TableCell>}
                                        
                                        {isLaptop && consumo && 
                                            <TableCell style={{textAlign: "center"}}>
                                                <SmallTextInput value={productos[index].cantidadUsada} onChange={(event)=>onChangeHandler(event, index)}/>
                                            </TableCell>}
                                        {deleteProducts &&
                                            <TableCell>
                                                <SmallButton 
                                                    onClick={()=>onDeleteProducts(index)}
                                                >
                                                    <X color="red" size="16"/>
                                                </SmallButton>
                                            </TableCell>
                                        }
                                    </TableRow>
                            ))}
                            
                        </TableBody>
                    </Table>
            </TableContainer>        
            }
        </>
        
    )
}


const SmallTextInput = styled(TextInput)`
    max-width: 35px;
`


const TableContainer = styled.div`
    
    border-radius: 4px;
    width: var(--container-width);
    overflow: auto;
`

const Table = styled.table`
    border-collapse: collapse;
    width:100%
`

const TableHead = styled.thead`
`

const TableHeadRow = styled.tr`
 border-top: 1px solid ${p=>p.theme.palette.grey[400]};
    
`
const TableHeadCell = styled.th`
    padding: 16px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    font-size: 0.875rem;
    text-align: left;
    white-space: wrap;
    
`

const TableBody = styled.tbody`
`

const TableRow = styled.tr`
    
`

const TableCell = styled.td`
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    padding: 16px;
    font-size: 0.875rem;


`



export default ProductTable;