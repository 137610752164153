import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { create } from '@mui/material/styles/createTransitions';

import React from 'react';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { planBasicoMensual, planPremiumMensual, planHospal } from '../../../shared/stripeProductCodes';
import { apiUrl } from '../../../shared/url';
import Button from '../../Atoms/Button';
import { CardActions, CardBody, CardTitle, CardWrapper } from '../../Atoms/Card';
import CardError from '../../Atoms/CardError';
import CardInfo from '../../Atoms/CardInfo';
import IconButton from '../../Atoms/IconButton';
import Modal from '../../Atoms/Modal';
import RefreshCw from '../../Icons/Refresh';
import NumberOfUsersForm from './NumberOfUsersForm';




const Billing = ()=>{    
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();
    const [plan, setPlan] = React.useState();
    
    const [cirugiasCreated,setCirugiasCreated] = React.useState(null);
    const smallBottomSwipeDrawers = ["activar", "desactivar"];
    const user = useCurrentUser();
    const theme = useTheme();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    
    const handleOnClick = (formType, plan)=>{
        setOpenModal(true);        
        setFormType(formType)
        setPlan(plan)
    }

    const getCirugiasCreatedTrial = async()=>{
        setIsLoading(true)
        const options = createOptions('POST',{})
        const response = await goFetch(`${apiUrl}checkout/cirugias-created-trial`, options)
        console.log(response)
        return responseStateChanges(response,setCirugiasCreated(response.cirugiasCreated),()=>{ })  
        
        
        //return setCirugiasCreated(response.cirugiasCreated)
    }

    //call api get cirugias cretedTrial
    React.useEffect(()=>{
        getCirugiasCreatedTrial()
    },[])
    
    const reachedMax = cirugiasCreated === 100;
    console.log(cirugiasCreated)
    return(
        <ExternalWrapper>
        <MainWrapper>

        <MainTitle>{`Selecciona el plan de subscripcion para ${user.company.name}`}</MainTitle>
        {!reachedMax ?
        <CardInfo>                            
                <CardInfoBody>
                    {`Han creado ${cirugiasCreated !== null && cirugiasCreated !== undefined ? cirugiasCreated : '?'} cirugias de las 100 permitidas en el plan de prueba`} <IconButton onClick={getCirugiasCreatedTrial} loading={isLoading}><RefreshCw color={theme.palette.grey[700]} size={20}/></IconButton>
                </CardInfoBody>                                                                
        </CardInfo>
        :
        <CardError>
                <CardInfoBody style={{marginTop:'0', marginBottom: '0'}}>
                    {`Han creado ${cirugiasCreated} cirugias el plan de prueba a terminado. Por favor elige el plan de subscripcion para poder seguir creando cirugias.`} 
                </CardInfoBody>
        </CardError>
        }
        <PlanWrapper>

           {/*<StyledCardWrapper>
                <StyledCardTitle>Plan Hospal</StyledCardTitle>
                <CardBody>
                    <PriceWrapper><Price>$1/</Price><span style={{fontWeight:'800', alignSelf:'flex-end'}}>por usuario al mes</span></PriceWrapper>
                    <List>
                        <Bullet>Gestion de cirugias (agendar, suspender, concluir, asignar)</Bullet>
                        <Bullet>Notificaciones a especialistas clinicos y fuerza de venta</Bullet>                        
                        <Bullet>Reporte general sobre cirugias realizadas</Bullet>
                        <Bullet>Monitor de actividades de los usuarios en la aplicacion</Bullet>                        
                        <Bullet>Reportes de cirugia</Bullet>
                        
                    </List>                    
                </CardBody>
                <CardActions>                    
                    <Button success fullsize onClick={()=>handleOnClick('numberOfUsers', planHospal)}>Seleccionar</Button>
                </CardActions>
            </StyledCardWrapper>
    */}
            <StyledCardWrapper>
                <StyledCardTitle>Plan Basico</StyledCardTitle>
                <CardBody>
                    <PriceWrapper><Price>$15 /</Price><span style={{fontWeight:'800', alignSelf:'flex-end'}}>por usuario al mes</span></PriceWrapper>
                    <List>
                        <Bullet>Gestion de cirugias (agendar, suspender, concluir, asignar)</Bullet>
                        <Bullet>Notificaciones a especialistas clinicos y fuerza de venta</Bullet>                        
                        <Bullet>Reporte general sobre cirugias realizadas</Bullet>
                        <Bullet>Monitor de actividades de los usuarios en la aplicacion</Bullet>                        
                        <Bullet>Reportes de cirugia</Bullet>
                        
                    </List>                    
                </CardBody>
                <CardActions>                    
                    <Button success fullsize onClick={()=>handleOnClick('numberOfUsers', planBasicoMensual)}>Seleccionar</Button>
                </CardActions>
            </StyledCardWrapper>
            <StyledCardWrapper>
                <StyledCardTitle>Plan Premium</StyledCardTitle>
                <CardBody>
                    <PriceWrapper><Price>$25 /</Price><span style={{fontWeight:'800', alignSelf:'flex-end'}}>por usuario al mes</span></PriceWrapper>
                    <List>
                        <Bullet>Todo lo incluid en el plan Basico</Bullet>
                        <Bullet>Compartir cirugias con doctores</Bullet>                                                
                        <Bullet>Check-in de especialistas clinicos</Bullet>
                    </List>
                </CardBody>
                <CardActions>                    
                <Button success fullsize onClick={()=>handleOnClick('numberOfUsers', planPremiumMensual)}>Seleccionar</Button>
                </CardActions>
            </StyledCardWrapper>
        </PlanWrapper>
        

        <Modal
            openModal={openModal}
            setOpenModal={setOpenModal}
            inProp={inProp}
            setInProp={setInProp}
            close={close}
            smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
        >
            {formType === "numberOfUsers" && <NumberOfUsersForm  close={close} plan={plan}/>}                                                        
        </Modal>  
    </MainWrapper>
    </ExternalWrapper>


    )
}

const ExternalWrapper = styled.div`
    height:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 36px;
    
`

const MainWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap:24px;    
    max-width: 1000px;
    align-self:center;
`

const PlanWrapper = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-self: center;

`

const MainTitle = styled.h1`
    margin:0

`

const StyledCardTitle = styled(CardTitle)`
    font-size: 1.5rem;
    align-self:center;
`

const StyledCardWrapper = styled(CardWrapper)`
    border-radius: 36px;
    padding: 24px;
    width: 350px;

    @media ${p=>p.theme.media.table}{
        
    }    
`

const List = styled.ul`
    
`

const Bullet = styled.li`
    padding-bottom: 8px;
`

const PriceWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
`


const Price = styled.span`
    font-size: 2rem;
    font-weight: 800;

`

const CardInfoBody = styled.div`
    display: flex;
    align-items: center;
    margin-top: -12px;
    margin-bottom: -12px;
    gap:12px;
`

export default Billing;