import styled from '@emotion/styled/macro';
import { useTheme } from "@mui/system";
import Button from '../../Atoms/Button';
//import {StyledMenu, StyledSpan, StyledMenuButton, StyledMenuItem, StyledMenuList}from '../../Atoms/StyledDropdownReach'
import { useReset } from '../../Forms/useReset';
import ChevronDown from '../../Icons/ChevronDown';

import { Menu, MenuItem } from "@mui/material";
import React from 'react';


//   const ResetMenuButton = ({
//     setOpenModal,
//     setFormType,
//     cirugiaId,
//     setCirugiaId
//   })=>{
//     let theme = useTheme();
//     let buttonColor= theme.palette.grey[600];
//     let buttonColorHover= theme.palette.grey[400];
//     let textColorHover = "white"

//     const [resetConsumo, resetReporte] = useReset(cirugiaId);
   
//     return (
//         <StyledMenu>
//             {({isExpanded})=>{              
//                 isExpanded ? document.querySelector('body').style.overflow='hidden' :
//                             document.querySelector('body').style.overflow='visible'
//                 return(
//                     <>
//                     <StyledMenuButton
//                         style={{
//                             "--button-color": buttonColor,
//                             "--button-color-hover": buttonColorHover,
//                             "--text-color-hover": textColorHover
//                         }}             
//                     >
//                         <StyledSpan>Reset</StyledSpan>
//                         <ChevronDown size={20} color={buttonColor}/>                        
//                     </StyledMenuButton>
//                     <NewStyledMenuList>
//                         <StyledMenuItem onSelect={()=>resetConsumo()}>
//                             Consumo
//                         </StyledMenuItem>
//                         <StyledMenuItem onSelect={()=>resetReporte()}>
//                             Reporte
//                         </StyledMenuItem>                        
//                     </NewStyledMenuList>
//                 </>
//                 )
//             }}       
//         </StyledMenu>
//     )
//   }


//   const NewStyledMenuList = styled(StyledMenuList)`
    
//   `


  const ResetMenuButton = ({
    setOpenModal,
    setFormType,
    cirugiaId,
    setCirugiaId
  })=>{
    let theme = useTheme();
    const [resetConsumo, resetReporte] = useReset(cirugiaId);
    
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }

   
    return (
        
                    <>
                    <Button
                      outline                      
                      postIcon={<ChevronDown size={20} color={theme.palette.grey[600]}/>}
                      onClick={onClickHandler}            
                    >
                        <StyledSpan>Reset</StyledSpan>
                        
                    </Button>
                    <Menu
                        open={open}
                        onClose={onCloseHandler}
                        anchorEl={anchorEl}
                    >
                        <MenuItem onClick={()=>{
                                            resetConsumo()
                                            setAnchorEl(null)
                                            }}>
                            Consumo
                        </MenuItem>
                        <MenuItem onClick={()=>{
                                                resetReporte()
                                                setAnchorEl(null)
                                                }}>
                            Reporte
                        </MenuItem>                        
                    </Menu>
                </>
                )                          
  }


  

  export const StyledSpan = styled.span`
                font-weight: 600;
                color: var(--button-color);
                font-size: .875rem;
                line-height: 1.75;
                `


  

  export default ResetMenuButton;