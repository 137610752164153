import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { addUpdatedMd } from '../../../app/mdReducer';
import { errorMessage } from '../../../app/messagesReducer';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import getFullName from '../../../shared/getFullname';
import { apiUrl } from '../../../shared/url';
import Button from '../../Atoms/Button';
import { Checkbox, FormControlCheckbox } from '../../Atoms/Checkbox';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import IconButton from '../../Atoms/IconButton';
import CentrosAutocomplete from '../../Forms/CustomAutocomplete/CentrosAutocomplete';
import Plus from '../../Icons/Plus';
import Send from '../../Icons/Send';
import { keyframes } from '@emotion/react';
import XCircle from '../../Icons/X-circle';
import SmallButton from '../../Atoms/SmallButton';
import useSuccessButton from '../../../hooks/useSuccessButton';



const CompartirAcceso = ({
    cirugiaId,
    close,
    md,
    changeAccess
})=>{
    const dispatch = useDispatch();
    const theme = useTheme();
    const user = useCurrentUser(); 
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()  
    const [centro, setCentro] = React.useState(null);
    const [showWarning, setShowWarning] = React.useState();
    
    
    const fullNameMd = getFullName(md);

    const mdHasAccess = md?.sharedFrom?.filter((shared)=>shared.mdUserId === user._id)[0]
    
    const [permissions, setPermissions] = React.useState({
        ver: {
            state: mdHasAccess?.permissions?.ver?.state || true,
            text: "Ver las cirugias."
        },
        agendar: {
            state: mdHasAccess?.permissions?.agendar?.state,
            text: "Usuario podra agendar y suspender cirugias."
        },
        centrosAll: {
            state: mdHasAccess?.permissions?.centrosAll?.state || true,
        },     
        centrosSome: {
            state: mdHasAccess?.permissions?.centrosSome?.state || false,
            array: mdHasAccess?.permissions?.centrosSome?.array || [],
        }
    });
 
    const onSubmit = async (event)=>{
        event.preventDefault();    
        setIsLoading(true);
                         
        const info={        
            from:{
                mdUserId: user._id,
                firstname: user.firstname,
                lastname: user.lastname,
                fullname: getFullName(user),                
            },
            with:{
                mdUserId: md._id,
                firstname: md.firstname,
                lastname: md.lastame,
                fullname: fullNameMd,                
            },
            permissions: permissions,
            updatePermissions: changeAccess && (permissions?.ver?.state || permissions?.agendar?.state)
        }
        
        const options = createOptions('POST', info)

        try{
            const response = await goFetch(`${apiUrl}md-users/share-access`, options);
            responseStateChanges(response,()=>dispatch(addUpdatedMd(response.md)),()=>close())            
        }catch(error){ 
            dispatch(errorMessage('Hubo un problema, no se guardo la informacion.'));
            setIsLoading(false);            
        }  
    }

    // ver selection depends on whether granting access for the first time or changing. If first time ver cannot be unselected. 
    const options = [
        {label: permissions.ver.text, name: 'ver', checked: changeAccess ? permissions.ver.state : true || permissions.agendar.state, disabled: permissions.agendar.state},        
        {label: permissions.agendar.text, name: 'agendar', checked: permissions.agendar.state},        
        
    ]

    const onChangePermissions = (event)=>{         
        let permissionObj = permissions[event.target.name]        
        permissionObj.state= event.target.checked        
        setPermissions(prevState=>({...prevState, [event.target.name]:permissionObj}))
        
        //when Seleccion centros is checked off
        if(event.target.name === "centrosSome" && !event.target.checked){            
            setPermissions(prevState=>{
                let newState;
                newState = {...prevState}
                newState.centrosAll.state = true;                            
                newState.centrosSome.array = [];
                return newState;
            })
        }

        //when Seleccion centros is checked
        if(event.target.name === "centrosSome" && event.target.checked){            
            setPermissions(prevState=>{
                const newCentrosAll = {...permissions.centrosAll.state}
                newCentrosAll.state= false
                return {...prevState, centrosAll: newCentrosAll};
            })
        }
    }


    //version for MUI Autocomplete
    const onChangeValuesAutocomplete = (event, option)=>{                        
        if(option){
            let info = {
                _id : option._id,
                nombre : option.nombre,
                ciudad : option.ciudad,
                zona : option.zona 
            }  
            setCentro(info);
        }else{
            setCentro(null);
        }        
    }

    const onAddCentro = (event)=>{        
        if(permissions.centrosSome.array.length<5){
            let centrosArray = [...permissions.centrosSome.array]
            centrosArray.push(centro)
            
            setPermissions(prevState=>{
                let newState;
                newState = {...prevState}            
                let centrosArray = [...prevState.centrosSome.array];
                centrosArray.push(centro)
                newState.centrosSome.array = centrosArray

                return newState;
            })        
            setCentro(null);
        }else{
            setShowWarning(true);
            setTimeout(()=>setShowWarning(false), 3000)
        }
    }
    
    const onDeleteCentros = (index)=>{
        setPermissions(prevState=>{
            let newState;
            newState = {...prevState}            
            let centrosArray = [...prevState.centrosSome.array];
            centrosArray.splice(index,1);
            newState.centrosSome.array = centrosArray
            return newState;
        })

    }


    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <StyledFormTitle>{!changeAccess ? 'Compartir Acceso' : 'Cambiar Acceso'}</StyledFormTitle>
            <StyledFormBody>
                {!changeAccess && <Text>{`${fullNameMd} tendra acceso a las cirugias donde tu estes como Doctor del caso. Por favor elige el nivel de acceso para este usuario.`}</Text>}
                {changeAccess && <Text>{`Puedes cambiar el acceso de ${fullNameMd} a tus cirugias. Por favor elige el nivel de acceso para este usuario.`}</Text>}
                <InnerWrapper>
                    <SubTitle>Permiso:</SubTitle>
                    { options?.map((option,index)=>(
                        <FormControlCheckbox 
                            key={`${option} ${index}`}
                            disabled={option.disabled} 
                            id={option.name}
                        >
                            <Checkbox label={option.label} name={option.name} checked={option.checked} disabled={option.disabled} onChange={onChangePermissions}/>
                        </FormControlCheckbox>
                    ))
                        
                    }
                    <SubTitle>Centros:</SubTitle>
                        <FormControlCheckbox id={'centrosAll'}>
                            <Checkbox 
                                label={"Todos los centros"} 
                                id={'centrosAll'}
                                name={'centrosAll'} 
                                checked={permissions.centrosAll.state} 
                                disabled={permissions.centrosSome.state} 
                                onChange={onChangePermissions}/>
                        </FormControlCheckbox>

                        <FormControlCheckbox id={'centrosSome'}>
                            <Checkbox label={"Seleccionar Centros"} id={'centrosSome'} name={'centrosSome'} checked={permissions.centrosSome.state} onChange={onChangePermissions}/>
                        </FormControlCheckbox>
                        {
                            permissions.centrosSome.state && 
                                <InputWrapper>
                                    <AutocompleteItem>
                                    <CentrosAutocomplete
                                        onChange={(event, option)=>onChangeValuesAutocomplete(event, option)}
                                        value={centro}                                    
                                    />

                                    </AutocompleteItem>
                                    {centro && <IconButton type="button" onClick={onAddCentro} ><Plus color={theme.palette.grey[600]}/></IconButton>}
                                </InputWrapper>
                        }

                        {permissions.centrosSome.array.length >0 ?
                            <>
                                <SubTitle>Centros seleccionados:</SubTitle>                            
                           {
                                permissions.centrosSome.array?.map((centro, index)=>(
                                    <CentrosTextWrapper key={`${centro._id}${index}`}>
                                        <CentrosText>{centro.nombre}</CentrosText>
                                        <SmallButton onClick={()=>{onDeleteCentros(index)}}>
                                            <XCircle color="red" size="16"/>
                                        </SmallButton>
                                    </CentrosTextWrapper>                                        
                                )) 
                           }
                            {showWarning &&  <Warning>Se permite un maximo de 5 centros.</Warning>}
                           </>     
                           :
                           null
                        }                        
                </InnerWrapper>
            </StyledFormBody>
            <StyledFormActions>
                <Button 
                    as="button"
                    type="submit"
                    success
                    fullsize={true}
                    preIcon={<Send color="#ffff" size="18"/>}
                    successEvent={successEvent}
                    loading={isLoading}
                    disabled={permissions.centrosSome.state && permissions.centrosSome.array.length<1}
                >Enviar</Button>
            </StyledFormActions>            
        </StyledFormWrapper>
    )
}


const fadeIn = keyframes`
    0%{
        transform: scale(0);
    }

    100%{
        transform: scale(1);
    }
`

const StyledFormWrapper = styled(FormWrapper)`
    max-width:500px;
`

const StyledFormTitle = styled(FormTitle)`
    margin-bottom: 18px;

`
const StyledFormBody = styled(FormBody)`
    margin-bottom: 8px;
`

const StyledFormActions = styled(FormActions)`
    padding-left: 4px;
    padding-right: 4px;

    @media ${p=>p.theme.media.tablet}{
        padding-left: 4px;
        padding-right: 4px;
    }
`

const InnerWrapper = styled.div`
    align-self:center;
    padding-bottom: 12px;
    display:flex;
    flex-direction:column;
    gap:12px;      
`

const InputWrapper = styled.div`
    width:100%;
    display: flex;
    gap: 4px;
    
    & > button{        
        animation: ${fadeIn} 500ms ease;
    }
`
const AutocompleteItem = styled.div`
    
    flex:3;
    transition: width 2s ease;  
`

const SubTitle = styled.p`
    margin:0;
    font-weight:600;

`

const Text = styled.p`
    margin:0;
    margin-bottom: 12px;
`

const CentrosText = styled.p`
    margin:0;
    font-size: 0.875rem;
`

const CentrosTextWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;

`


const Warning = styled.div`
    width:100%;
    border: 1px solid ${p=>p.theme.palette.error.light};
    height: 50px;
    display:grid;
    place-content:center;
    border-radius: 4px;
    background-color: ${p=>p.theme.palette.error.pastel};
    color: ${p=>p.theme.palette.error.light};
    font-weight: 500;
    animation: ${fadeIn} 500ms ease;

`



export default CompartirAcceso;