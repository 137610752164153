import {createSlice } from '@reduxjs/toolkit';

import getRestrictedCirugias from '../shared/getRestrictedCirugias';
import { rolePermissions } from '../shared/roles';

//const [restrictedVentasId, restrictedECId] =  getRestrictedCirugias()

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
let restrictedVentasId = false; 
let restrictedECId = false;

if(!user || user.role?.md){         
    
}else{
    const role = Object.keys(user?.role)[0]
    const permissions = rolePermissions[role];

    const totalPermissions = [...permissions, ...user.permissions]
    const canSeeAllCirugias = totalPermissions.includes("can_see_all_cirugias")   

    console.log(totalPermissions)
    if(role === "isVentas" && !canSeeAllCirugias){
        console.log("role is ventas")
        restrictedVentasId = user._id
        console.log("in get restricted cirugias")
        console.log(restrictedVentasId)        
    }

    if(role === "isEC" && !canSeeAllCirugias){
        restrictedECId = user._id        
    }
}
    


    



const initialState= {
    filter:{
        infoAgendada: true,
        infoRealizada: false,
        restrictedVentasId: restrictedVentasId,
        restrictedECId: restrictedECId 
    }    
}

export const filterSlice = createSlice({
    name:"filter",
    initialState,
    reducers: {
        modifyFilter(state, action){            
            state.filter = action.payload;                  
        },
        updateFilterAfterUserChange(state,action){
            state.filter = {...state.filter, ...action.payload}
        },
        setRestrictions(state,action){            
            state.filter.restrictedVentasId = action.payload.restrictedVentasId
            state.filter.restrictedECId = action.payload.restrictedECId
            
        }         
    }
})

const {actions, reducer} = filterSlice

export const {modifyFilter, updateFilterAfterUserChange, setRestrictions} = actions;

export default reducer;