import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import Button from '../../Atoms/Button';
import Send from '../../Icons/Send';




const EnviarButtonGroup = ({
    disabled,
    onClickGuardar,
    onClickEnviar,
    successEventGuardar,
    loadingGuardar,
    successEventEnviar,
    loadingEnviar,
    
})=>{

    const theme = useTheme();
    return(
        <ButtonGroup>
            <GuardarButton 
                success 
                onClick={onClickGuardar}
                successEvent={successEventGuardar}
                loading={loadingGuardar}            
            >Guardar</GuardarButton>
            <EnviarButton
                success
                preIcon={<Send size="18" color={!disabled ? '#ffff' : theme.palette.grey[500]}/>}
                disabled={disabled}
                onClick={onClickEnviar}
                successEvent={successEventEnviar}
                loading={loadingEnviar}
                
            ></EnviarButton>
        </ButtonGroup>
    )
}






const ButtonGroup = styled.div`
    display:flex;
`

const StyledButton = styled(Button)`
    &:hover{
        transform: revert;
        background-color: var(--button-color-hover);        
    }
`

const GuardarButton = styled(StyledButton)`
    border-radius: 4px 0px 0px 4px;
       
`

const EnviarButton = styled(StyledButton)`
    padding:0px 8px;
    min-width:20px;
    width: 45px;
    border-radius: 0px 4px 4px 0px;
    border-left: 1px solid ${p=>!p.disabled ? p.theme.palette.success.dark : p.theme.palette.grey[500]};
    gap:0px;


    

`

export default EnviarButtonGroup;