import { useSelector } from "react-redux"




export const useConfiguration=()=>{

    return useSelector(state=>state.auth.configuration)


}