import React from 'react';
import { DialogOverlay} from "@reach/dialog";
import styled from '@emotion/styled';
import useColor from "../../hooks/useColor";
import useSwipe from "../../hooks/useSwipe";
import { Transition } from 'react-transition-group';
import useMediaQuery from '../../hooks/useMediaQuery';



const BottomSwipeDrawer = ({
    openModal,
    setOpenModal,
    inProp,
    setInProp,
    close,
    title, 
    color, 
    smallDrawer,
    drawerOffsetPercentInput,
    children})=>{

    const isPhone = useMediaQuery('phone');
    let drawerOffsetPercent;
    
    
    const duration=300;
    React.useEffect(()=>{
        if(openModal)setInProp(true)
    },[openModal])

    
    const [backgroundColorHeader, textColor ] = useColor(color);
    

    // const close = React.useCallback(()=>{
    //     setInProp(false)
    //     setTimeout(()=>{
    //         setOpenModal(false)
    //     }, duration)
    // },[setOpenModal])
    
    const [swipeRef, diff] = useSwipe(close, 100, true);
    //console.log(diff);

    //created as work around to default Modal Overlay behaviour which focuses first element. Creates problem for forms.    
    const focusRef = React.useRef();

    const defaultStyles={
        transition: `transform ${duration}ms linear`,
        transform:'translateY(100%)'
    }

    const transitionStyles ={
        entering:{transform: 'translateY(100%)'},
        entered:{transform: 'translateY(0%)'},
        exiting:{transform: 'translateY(100%)'},
        exited:{transform: 'translateY(100%)'}
    }


    const defaultBackdropStyles ={  
        transition: `opacity ${duration}ms ease-in`,
        opacity: '0'
    }

    const transitionBackdropStyles={
        entering: {opacity:0},
        entered: {opacity:1},
        exiting: {opacity:0},
        exited: {opacity:0}
    }

    //change drawer size based on drawer size input
    if(smallDrawer){
        drawerOffsetPercent = '50%';
    }else if(drawerOffsetPercentInput){
        drawerOffsetPercent = drawerOffsetPercentInput;
    }else{
        drawerOffsetPercent = '10%';
    }

    //console.log('drawerOffsetPercent', drawerOffsetPercentInput, drawerOffsetPercent)

    return(
        <Transition 
            in={inProp} 
            timeout={duration}                 
        >
            {
                state =>{
                    
                    return(
                        <>
                            {openModal && isPhone &&
                            
                                
                                <MobileOverlay initialFocusRef={focusRef} >
                                    <Backdrop style={{...defaultBackdropStyles, ...transitionBackdropStyles[state]}}/>
                                    <Wrapper ref={swipeRef} >
                                        <MobileDialogContent drawerOffsetPercent={drawerOffsetPercent} diff={diff} style={{...defaultStyles,...transitionStyles[state]}}>                                         
                                            <Content>
                                                <Swipper/>
                                                {children}
                                            </Content>
                                                                                                                                                                                          
                                        </MobileDialogContent>
                                        <InvisibleInput ref={focusRef}/>
                                    </Wrapper>
                                </MobileOverlay>
                            
                            }
                        </>
                )
            }
            }
            
    </Transition>
    )
}



const MobileOverlay = styled(DialogOverlay)`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;        
`

const Backdrop = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;    
    background-color: hsla(360, 0%, 29%, 0.77);

`

const Wrapper = styled.div`
    height:100%;
`



//bottom:calc(0px - ${p=>p.diff}px);

const MobileDialogContent = styled.div`
    position: absolute;
    top:calc(${p=>p.drawerOffsetPercent} + ${p=>p.diff}px);
    bottom: 0;
    left:0;
    right:0;
    width:100%;
    /*height:100%; */
    background-color: white;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 
    0px 6px 10px 0px rgb(0 0 0 / 14%), 
    0px 1px 18px 0px rgb(0 0 0 / 12%);
    border-radius: 24px 24px 0 0;

`



const Content = styled.div`
    height: 100%;
    display: flex;
    overflow-y: auto;
    width:100%;
    padding: 8px;
    position:relative;
`

const Swipper = styled.div`
    height: 5px;
    width: 50px;
    border-radius: 25px;
    background-color: ${p=>p.theme.palette.grey[500]};
    position:absolute;
    
    left:42%;
`
//work around to take focus away from firt inputs when we have forms. 
const InvisibleInput = styled.button`
    background-color: transparent;
    border: none;
    &:focus{
        outline: none;
    }
`
export default BottomSwipeDrawer;