import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    users:[],
    user: undefined,
    ventas: [],
    ec: [],
    isLoading: true,
    activeUsers: undefined,
    searchedUsers: []
}

export const usersSlice = createSlice({
    name:"users",
    initialState,
    reducers: {
        loadingUsers(state,action){
            state.isLoading = true;
        },
        addUsers(state, action){
           console.log(action.payload);
            state.users = action.payload.users;
            //state.activeUsers = action.payload.activeUsers;
            state.searchedUsers = [];
            state.isLoading= false;            
        },        
        addActiveUsers(state,action){
            console.log(action.payload);
            state.activeUsers = action.payload.activeUsers;
            state.searchedUsers = [];
            state.isLoading = false;
        },
        addUpdatedUser(state,action){                            
            state.users.forEach((user, index) => {
                if(user._id === action.payload._id){                 
                    state.users[index] = action.payload
                }
                return                
            });
        },
        addSearchedUsers(state,action){
            state.searchedUsers = action.payload.searchedUsers;
            state.isLoading = false;
        },
        addVentas(state,action){
            state.ventas = action.payload;
            state.isLoading = false;
        },
        addEC(state,action){
            state.ec = action.payload;
            state.isLoading = false;
        },
        updateActiveUsers(state,action){
            state.activeUsers = action.payload
        }
  
    }
})

const {actions, reducer} = usersSlice

export const {loadingUsers, addUsers, addUpdatedUser, addVentas, addEC, updateActiveUsers, addActiveUsers, addSearchedUsers} = actions;

export default reducer;