import React from "react";
import styled from '@emotion/styled';
import IconButton from "../Atoms/IconButton";
import ClientsSearchBar from "./molecules/ClientsSearchBar";
import Plus from "../Icons/Plus";
import { useTheme } from "@mui/system";
import useModalAndDrawer from "../../hooks/useModalAndDrawer";
import CardInfo from "../Atoms/CardInfo";
import { CirugiaCardSkeleton } from "../cirugias/Molecules/CirugiasCardLoading";
import Modal from "../Atoms/Modal";
import ClientSummaryCard from "./ClientSummaryCard";
import ClientePrivadoInput from "../cotizaciones/molecules/ClientePrivadoInput";
import AccessCotClient from "./molecules/AccessCotClient";
import { useDispatch, useSelector } from "react-redux";
import { createOptions, goFetch } from "../../api/cirugiasApi";
import { apiUrl } from "../../shared/url";
import { fetchClients } from "../../app/thunkActions/clientsActions";
import PriceList from "./molecules/PriceList";






const Clients = ()=>{

    const theme = useTheme();
    const dispatch = useDispatch();
    //const [clients, setClients] = React.useState([]);
    const [clientToModify, setClientToModify] = React.useState(); 
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const smallBottomSwipeDrawers = ['modifyDoctor', 'crearPaciente']
    const clients = useSelector(state=>state.clients.clients)
   

    React.useEffect(()=>{
        dispatch(fetchClients());
    },[])

    const handleCrearClient = (formType)=>{
        setOpenModal(true);
        setFormType(formType)
    }

    console.log(clients)
    return (
        <>
            <Header>
                <SearchBarWrapper>
                    <ClientsSearchBar setIsLoading={setIsLoading}/>
                    <StyledIconButton type="button" onClick={()=>handleCrearClient('crearClients')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>
                </SearchBarWrapper>
                
            </Header>
            <br/>
            
            {clients?.length === 0 && !isLoading && <CardInfo>No se encontraron clientes</CardInfo>}   
            <ClientsWrapper>
                {isLoading && [1,2,3,4,5,6].map((index)=>(<CirugiaCardSkeleton key={`${index}`}/>))} 

                {clients.length > 0 && !isLoading && clients?.map((client)=>{                
                    return(                                    
                        <ClientSummaryCard   
                            key={client.id}                         
                            close={close}
                            setOpenModal={setOpenModal}
                            setFormType={setFormType}   
                            client={client}
                            setClientToModify={setClientToModify}
                        />                                    
                    )})
                }                
            </ClientsWrapper>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
                
                  
            >
            
            {formType === "crearClients" && <ClientePrivadoInput
                                                clientePrivado={null}                                                     
                                                crearNewClient={true}
                                                close ={close}
                                                />
            }   
            {formType === "quickClient" && <AccessCotClient 
                                                    close={close} 
                                                    client={clientToModify}                                                    
                                                    />}

            {formType === "priceList" && <PriceList 
                                                    close={close} 
                                                    client={clientToModify}                                                    
                                                    />}
                


            </Modal> 
            
            </>
        
    )
}


const ClientsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    

    @media ${p=>p.theme.media.tablet}{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;    
        
    }
`




const Header = styled.div`
    display: flex;
    gap:8px;
    justify-content: center;
`

const SearchBarWrapper = styled.div`
    width:100%;
    display: flex;
    gap:8px;
    justify-content: center;
    align-items:center;    
`

const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`





export default Clients;