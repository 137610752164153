import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import TextInput from '../Atoms/TextInput';
import styled from '@emotion/styled/macro';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const RechazarCirugia = ({
    cirugiaId,
    close
})=>{

    const isTablet = useMediaQuery('tablet');    
    const [confirmacion, setConfirmacion] = React.useState("");
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker()
  
    
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments)
        const accion = accionMaker('Cirugia rechazada')
        
        let infoRechazar = {            
            cirugiaId: cirugiaId,                        
            comentarios:commentObj,
            accion: accion
          }

        updateCirugia("rechazar-cirugia", infoRechazar)
    }        
      
    const onChangeHandler = (event)=>{
        setConfirmacion(event.target.value);
    }
    
       
    

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Rechazar Cirugia</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <Text>Por favor confirmar que desea rechazar esta cirugia escribiendo la palabra <StyledSuspender>rechazar.</StyledSuspender></Text>
                <TextInput
                    label="Rechazar"
                    value={confirmacion}
                    onChange={onChangeHandler}
                />
                <StyledTextField
                    label="Comentarios"
                    sx={{fontSize:'3rem'}}
                    multiline
                    variant='outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />                                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                disabledSuccess={confirmacion.toLowerCase() !=="rechazar"}
                successText="Rechazar"
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


const Text=styled.p`
    margin:0;
    margin-bottom: 8px;
`

const StyledTextField = styled(TextField)`
    & .MuiInputLabel-root{
        font-size: 0.875rem;
        font-weight: 500;
    };
    & fieldset{
        border-radius:12px;
        font-size:1rem;
        top:0;
    };
`

const StyledSuspender = styled.span`
    font-weight:700;
    font-style: italic;
`


export default RechazarCirugia