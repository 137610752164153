
import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import Button from '../../Atoms/Button';
import { CardHeader, CardBody,CardTitle, CardWrapper as Card } from "../../Atoms/Card";
import ProfilePictureAvatar from '../../Atoms/ProfilePictureAvatar';
import Mail from '../../Icons/Mail';
import Phone from '../../Icons/Phone';
import User from '../../Icons/User';
import blankProfile from '../../../blankProfile.svg'
import React from 'react';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { addDoctor, resetDoctors } from '../../../app/doctorsReducer';
import { useDispatch } from 'react-redux';
import { successMessage } from '../../../app/messagesReducer';
import Modal from '../../Atoms/Modal';
import DoctorInvite from './DoctorInvite';
import DoctorConnect from './DoctorConnect';
import CrearDoctor from '../../Forms/CrearDoctor';
import MoreButtonDoctorProfile from './MoreButtonDoctorProfile';
import DoctorEditarVentas from './DoctorEditarVentas';
import DoctorEditarCentros from './DoctorEditarCentros';
import DoctorCentrosList from './DoctorCentrosList';
import usePermissions from '../../../hooks/usePermissions';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import CardError from '../../Atoms/CardError';
import CardInfo from '../../Atoms/CardInfo';
import CardWarning from '../../Atoms/CardWarning';
import calcTimeAgo from '../../../shared/calcTimeAgo';
import dateFormat from '../../../shared/dateFormatCalendar';
import useDateAndTimeFormatter from '../../../hooks/useDateAndTimeFormatter';




const DoctorProfileCard = ({
    doctor
})=>{
    const theme = useTheme();
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const {dateFormat} = useDateAndTimeFormatter();
    let iconColor = theme.palette.grey[800]
    const permissions = usePermissions();
    const {canCrearEditarInvitarDoctores} = permissions;
    let [dotColor, setDotColor] = React.useState();    
    const [formType, setFormType] = React.useState();     
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();

    const [lastCirugiasFetch, setLastCirugiasFetch] = React.useState('');

    let ventas = doctor?.ventas ? `${doctor.ventas.ventasFullname}` : "";
    let isConnected = doctor?.connected?.status || false;
    let isInvited = doctor?.connected?.invited;
    let hasEmail = doctor?.email;
    let [isRegistered, setIsRegistered] = React.useState(isInvited || isConnected);
    const requestedInvite = doctor?.connected?.invitationRequest?.status && !isInvited;
    let dateInvite = doctor?.connected?.invitedDate;
    //check if invitation is older than 2 days
    let inviteGreaterThanTwoDays = new Date() - new Date(doctor?.connected?.invitedDate) > 172800000;
    let invitationsSent = doctor?.connected?.invitationsCount

    console.log(inviteGreaterThanTwoDays)

    console.log(dotColor);

    React.useEffect(()=>{
        const checkIfMdHasAccount = async()=>{
            console.log(doctor.email);
            const options = createOptions('POST', {email: doctor.email})                        
            try{
                let response = await goFetch(apiUrl+ "md-users/md-exists", options);
                if(response.status){
                    setIsRegistered(true)
                    setLastCirugiasFetch(response?.lastCirugiasFetch)
                }else{
                    // if(isInvited) setIsRegistered(true)
                    // if(!isInvited) setIsRegistered(false);                                        
                }
            }catch(error){
                console.log(error);
            }
        }

        // if(doctor && !isConnected && !isInvited){            
        //     checkIfMdHasAccount()            
        // }else if(isInvited){
        //     setIsRegistered(true);
        // }
        
        checkIfMdHasAccount()            

    },[isConnected, doctor, isInvited])

    console.log(`is registered ${isRegistered}`)

    React.useEffect(()=>{
        if(doctor){            
            if(isConnected) return setDotColor(theme.palette.success.light);
            if(isRegistered && !isConnected && isInvited) return setDotColor(theme.palette.warning.light);
            if(!isConnected && !isRegistered && !isInvited) return setDotColor (theme.palette.error.light);
            if(requestedInvite) return setDotColor(theme.palette.warning.light);
        }
    },[isConnected, doctor, isRegistered, theme, requestedInvite, isInvited])

    console.log("Is this doctor registered", isRegistered);


    const connect = async ()=>{

        const options = createOptions('POST', {
            companyId: user.company._id,
            companyName: user.company.name,
            companyDoctorId:doctor._id,
            email: doctor.email
        })                        
            
        let response = await goFetch(apiUrl+ "md-users/invitation", options);                
         
        //updated doctor state in the Store and resets doctor list to trigger using the doctor state.
        if(response.updatedDoctor){
            dispatch(successMessage(response.message))
            dispatch(addDoctor(response.updatedDoctor));
            dispatch(resetDoctors());
        }
        console.log(response.updatedDoctor) // reset doctor list which should trigger dispatch and fetch of doctor. 
    }


    const handleOnClick = (formType)=>{
        setOpenModal(true);        
        setFormType(formType)
    }

    const smallBottomSwipeDrawers = ["editarVentas", "editarCentros"]

    const formattedFetchDate = dateFormat(lastCirugiasFetch)
    const timeAgo = calcTimeAgo(lastCirugiasFetch)
    const timeSinceLastFetch = `${formattedFetchDate} - ${timeAgo}`
    
    return (                
        <ProfileWrapper>
                <StyledCardHeader>
                    <AvatarContainer>
                        <AvatarBorder/>
                        <ProfilePictureAvatar
                            src={doctor?.profilePicture || blankProfile}
                            size="large" 
                            alt="doctor avatar" 
                            style={{position:"absolute", top:'-75px'}}
                        ></ProfilePictureAvatar>                        
                    </AvatarContainer>
                    <TitleContainer>
                        <StyledCardTitle>{`${doctor?.firstname || ""} ${doctor?.lastname || ""}`}</StyledCardTitle>
                        <CardSubtitle>{`${doctor?.especialidad || ""}`}</CardSubtitle>
                        <br/>
                        {!hasEmail && <CardError> <Text style={{color:"red"}}>{`Ingresar email para invitar o conectarse con ${user.company.name}.`}</Text></CardError>}    
                        {!isRegistered && hasEmail && !isInvited && <CardError><Text style={{fontWeight:"600", color:theme.palette.error.light}}>Este email no esta asociado a una cuenta aun. Puedes invitarlo a unirse a Xirugias.</Text></CardError> }    
                        {isRegistered && !isConnected && !isInvited && !requestedInvite && <CardInfo><Text style={{fontWeight:"600"}}>{`El doctor es parte de Xirugias. Invitalo a conectarse con ${user.company.name}!`}</Text></CardInfo>}    
                        {isInvited && !isConnected && <CardWarning> <Text style={{fontWeight:"600"}}>{`Invitacion enviada ${calcTimeAgo(dateInvite).toLowerCase()}. ${invitationsSent ? `Invitaciones enviadas: ${invitationsSent}.`: ""} Esperando respuesta.`}</Text></CardWarning>}
                        {isConnected && <CardInfo><Text>Doctor connectado a tu compañia. Puede ver informacion sobre sus cirugias como tambien solicitar o suspender cirugias.</Text></CardInfo>}
                        {requestedInvite && <Text style={{fontWeight:"600", color:theme.palette.success.light}}>Solicito una invitacion a conectarse.</Text>}
                    </TitleContainer>                                        
                </StyledCardHeader>
                <StyledCardBody>
                    <Container>
                        <ContactInfoWrapper>
                            <ContactContainer>
                                <Mail color={iconColor}/>
                                <ContactText>{`${doctor?.email || ""}`}</ContactText>
                            </ContactContainer>
                            <ContactContainer>
                                <Phone color={iconColor}/>
                                <ContactText>{`${doctor?.telefono}`}</ContactText>
                            </ContactContainer>
                            <ContactContainer>
                                <User color={iconColor}/>
                                <ContactText>{ventas}</ContactText>
                            </ContactContainer>
                            
                        </ContactInfoWrapper>
                        <CentroContainer>
                            <DoctorCentrosList doctor={doctor}/>
                        </CentroContainer>
                    </Container>
                    {canCrearEditarInvitarDoctores &&
                        <Actions>
                            {lastCirugiasFetch && 
                                <TimeSinceLastFetch>{`Ultima busqueda de cirugias: ${timeSinceLastFetch}`}</TimeSinceLastFetch>
                            }                            
                            {((!isRegistered && hasEmail) || (inviteGreaterThanTwoDays && !isConnected)) && <Button outline primary onClick={()=>handleOnClick('invite')}>Invitar</Button>}
                            {(isRegistered && !isConnected && !isInvited) ? <Button outline primary onClick={()=>handleOnClick('conectar')}>Connectar</Button> : <Space/>}
                            <MoreButtonDoctorProfile setOpenModal={setOpenModal} setFormType={setFormType}/>                        
                        </Actions>
                    }
                    
                </StyledCardBody>
                <Dot style={{"--dot-color": dotColor}}/>
                <Modal
                    openModal={openModal}
                    setOpenModal={setOpenModal} 
                    inProp={inProp}
                    setInProp={setInProp}
                    close={close}
                    smallDrawer = {smallBottomSwipeDrawers.includes(formType)}           
                >
                    {formType === "invite" && <DoctorInvite  close={close} doctor={doctor}/>}                                
                    {formType === "conectar" && <DoctorConnect close={close} doctor={doctor}/>}
                    {formType === "editar" && <CrearDoctor edit close={close} doctor={doctor}/>}
                    {formType === "editarVentas" && <DoctorEditarVentas edit close={close} doctor={doctor}/>}                                                                
                    {formType === "editarCentros" && <DoctorEditarCentros edit close={close} doctor={doctor}/>}                                
                </Modal>              
            </ProfileWrapper>            
    )
}




const ProfileWrapper= styled(Card)`
    position:relative;
    display:flex;
    flex-direction:column;
    
    flex:1;
    min-height: 300px;
    min-width:350px;
    max-width: 700px;
    
    
    
`

const StyledCardHeader = styled(CardHeader)`
    flex-wrap: wrap;    
`
const AvatarContainer = styled.div`
    height:120px;
    width:200px;
    position:relative;
`

const AvatarBorder = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ffff;
    position:absolute;
    top: -84px;
    
`

const TitleContainer = styled.div`
    display:flex;
    flex-direction: column;
    flex:2;
    min-width:200px;
    align-items: flex-start;
    height:100%;
    padding-left:12px;
    
`

const StyledCardTitle = styled.h2`
    margin:0;
    padding-top:4px;
    color: ${p=>p.theme.palette.grey[800]}
`

const Text = styled.p`
    margin:0;
    font-size: 0.75rem;
`

const CardSubtitle = styled.h4`
    margin:0;
    color: ${p=>p.theme.palette.grey[600]}
`

const StyledCardBody = styled(CardBody)`
    padding-top: 12px;
    
`



const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap:8px;
    padding-left:12px;
`

const ContactInfoWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap:8px;
    flex:1
`
const ContactContainer = styled.div`
    display:flex;
    gap:8px;
    align-items: center;
    min-width:200px;
    height: 35px;
`
const ContactText = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]}
`

const CentroContainer = styled.div`
    flex:1;
    display:flex;
    gap:8px;
    flex-direction:column;
`

const Actions = styled.div`
    display:flex; 
    padding-top: 12px;
    padding-left: 12px;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;

`

const Dot = styled.div`
    width:16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--dot-color);
    position: absolute;
    right: 16px;
    top: 16px;
    box-shadow: 0.2px 0.3px 0.4px hsl(0deg 0% 63% / 0.41),
    0.8px 1px 1.4px -0.8px hsl(0deg 0% 63%/ 0.41),
    2px 2.5px 3.6px -1.7px hsl(0deg 0% 63% / 0.41),
    4.9px 6.2px 8.9px -2.5px hsl(0deg 0% 63% / 0.41);
    
`


const TimeSinceLastFetch = styled.span`
    font-size: 0.70rem
`



const Space = styled.div``

export default DoctorProfileCard;