import React from "react"
import styled from '@emotion/styled';
import { useDispatch } from "react-redux";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import useCheckForm from "../../../hooks/useCheckForm";
import { isMaxLength, validate } from "../../../shared/validators";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { errorMessage } from "../../../app/messagesReducer";
import { FormControlLabel, Switch } from "@mui/material";
import { FormBody, FormHeader, FormSubtitle, FormWrapper } from "../../Atoms/Forms";
import TextInput from "../../Atoms/TextInput";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { apiUrl } from "../../../shared/url";
import { updateClientsArray } from "../../../app/clientsReducer";
import PreciosMenuButton from "../../Admin/Productos/molecules/PreciosMenuButton";


const ListaPrecios = ({close, client}) => {
    console.log(client)
    const dispatch = useDispatch()
    const [acceso, setAcceso] = React.useState(client?.quickClient?.active || false)
    const [selectedPriceList, setSelectedPriceList] = React.useState(client?.priceList || "No seleccionado")
    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const accionMaker = useAccionMaker()
    

    const onSubmit = async(e)=>{
        e.preventDefault()
        setIsLoading(true)

        const accion = accionMaker("Acceso a cotizacion actualizado")

        const infoAccesoCot = {            
            priceList: selectedPriceList,
            accion
        }

        console.log(infoAccesoCot)

        const options = createOptions('PUT', infoAccesoCot)        
        try {
            console.log(client._id)
            const response = await goFetch(`${apiUrl}clients/price-list/${client._id}`, options)
            console.log(response)
            responseStateChanges(response, ()=>{
                //find client and update it
                dispatch(updateClientsArray(response))
                //const updatedClient = {...client, quickClient:response.quickClient}

            }, ()=>close())                                            
        } catch (error) {
            dispatch(errorMessage('Error al actualizar producto'))
        }

    }


  
    //console.log(client)
    return(
        <FormWrapper onSubmit={onSubmit} style={{maxWidth:'500px'}}>
            <FormHeader>
                Lista de Precios
                <FormSubtitle>{`${client?.name}`}</FormSubtitle>
            </FormHeader>
            
            <FormBody>
                <Wrapper>                  
                    <PreciosMenuButton
                        selectedPriceList={selectedPriceList}
                        setSelectedPriceList={setSelectedPriceList}
                    />
                </Wrapper>            
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                
            />

            

        </FormWrapper>
    )

    }


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
    
`



const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-size: 0.875rem;
    }    
`



export default ListaPrecios