import styled from '@emotion/styled';
import { CardWrapper } from './Card';



const CardInfo = ({
    children,
    fontSize,
    size   
})=>{

    return(
        <StyledCard fontSize={fontSize} size={size}>
            {children}        
        </StyledCard>
    )
}



const StyledCard = styled.div`
    padding: ${p=>p.size==="small" ? '8px' : '18px'};
    border-radius: 8px;
    border: 1px solid ${p=>p.theme.palette.grey[800]};
    background-color: ${p=>p.theme.palette.grey[300]};
    color: ${p=>p.theme.palette.grey[800]};
    font-weight: 600;
    font-size: ${p=>p.fontSize ? p.fontSize : 'revert'};    
`

export default CardInfo