import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    productos:[],
    isLoading: false
}

export const productosSlice = createSlice({
    name:"productos",
    initialState,
    reducers: {
        addProductos(state, action){
           // console.log(action.payload);
            state.productos = action.payload;
            state.isLoading= false;            
        },
        updateProductosArray(state,action){
            state.productos.map((producto, index)=>{
                if(producto._id === action.payload._id){
                    state.productos[index] = action.payload
                }
            })
        },
        initProductos(state,action){
            state.productos = [];
        },
        setLoading(state, action){
            state.isLoading = action.payload;
        }        
    }
})

const {actions, reducer} = productosSlice

export const {addProductos, updateProductosArray, initProductos, setLoading} = actions;

export default reducer;