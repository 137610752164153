import { InputLabel } from "@mui/material"
import styled from '@emotion/styled/macro';


const CustomInputMuiLabel  = ({children,...props})=>{

    return(
        <StyledInputLabel {...props}>
            {children}
        </StyledInputLabel>
    )
}


const StyledInputLabel=styled(InputLabel)`
    font-size: 0.875rem;
    font-weight: 500;
    &.MuiInputLabel-root.Mui-focused{
        transform: translate(14px, 4px) scale(0.70)
    };
    &.MuiInputLabel-root.MuiFormLabel-filled{
        transform: translate(14px, 4px) scale(0.70)
    };
`    


export default CustomInputMuiLabel