import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, searchUsers } from "../../../../app/thunkActions/usersActions";
import SearchBar from "../../../Atoms/SearchBar";




const UsersSearchBar = ()=>{
    const [searchValue, setSearchValue] = React.useState();

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.users.isLoading)

    const onChangeSearchValue = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        setSearchValue(searchTerm)
        if(searchTerm.length>2){
            dispatch(searchUsers(searchTerm))
        }

        if(!searchTerm){
            dispatch(fetchUsers('all'))
        }
        

    }

    return (
        <SearchBar
        label="Buscar usuario" 
        value={searchValue}
        onChange={onChangeSearchValue}
        fullWidth={true}
        isLoading={isLoading}
    />
    )
}



export default UsersSearchBar