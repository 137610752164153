import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';


const MdAgendaCirugiaChip = ({doctorAgendo})=>{
    const theme = useTheme()
    
    if(!doctorAgendo)return
     
    return(        
        <Chip icon={<FontAwesomeIcon icon={faUserMd} size="1x" color= {theme.palette.grey[700]}/>} outline color={theme.palette.grey[700]} />
    )
}



export default MdAgendaCirugiaChip;