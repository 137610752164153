import React from "react";
import ActionButton from "../Atoms/ActionButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd,faCalendarCheck, faProcedures } from '@fortawesome/free-solid-svg-icons'
import Modal from '../Atoms/Modal';
import { useTheme } from "@mui/system";
import useMediaQuery from "../../hooks/useMediaQuery";
import CrearCirugia from "../Forms/CrearCirugia";
import BottomSwipeDrawer from "../Atoms/BottomSwipeDrawer";
import CrearPaciente from "../Forms/CrearPaciente";
import CrearDoctor from "../Forms/CrearDoctor";

import useModalAndDrawer from "../../hooks/useModalAndDrawer";
import usePermissions from "../../hooks/usePermissions";




const CustomizedActionButton = ()=>{
    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');
    const permissions = usePermissions()

    const {       
        canCrearCirugias, 
        canCrearEditarInvitarDoctores,
        canCrearPacientes               
     }=permissions;
   

    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();

    const [modalNumber, setModalNumber] = React.useState();

    const onClickHandler = (event,index)=>{
        console.log(typeof(index))
        setOpenModal(true)
        setModalNumber(index);
    }
    
    let actions = [
        { icon: <FontAwesomeIcon icon= {faCalendarCheck} color={theme.palette.grey[600]} />, name: 'Cirugia', formInfo:{formType: 'cirugia', formTitle: 'Crear Cirugia'}, allowed:canCrearCirugias},
        { icon: <FontAwesomeIcon icon= {faProcedures}color={theme.palette.grey[600]}  />, name: 'Paciente', formInfo:{formType: 'paciente', formTitle: 'Crear Paciente'}, allowed: canCrearPacientes},
        { icon: <FontAwesomeIcon icon= {faUserMd} color={theme.palette.grey[600]} />, name: 'Doctor', formInfo:{formType: 'doctor', formTitle: 'Crear Doctor'}, allowed: canCrearEditarInvitarDoctores}
      ];


    

    return(
        <>
            <ActionButton
                actions={actions}
                onClick={onClickHandler}
            />
            { isTablet &&
               <Modal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                >
                    {modalNumber === 0 && <CrearCirugia close={close}/>}
                    {modalNumber === 1 && <CrearPaciente close={close}/>}
                    {modalNumber === 2 && <CrearDoctor close={close}/>}
                </Modal>     

            }

            { !isTablet && 
                <BottomSwipeDrawer
                    title={actions[modalNumber]?.formInfo?.formTitle}
                    openModal={openModal} 
                    setOpenModal={setOpenModal}
                    inProp={inProp}
                    setInProp={setInProp}
                    close={close}
                > 
                    {modalNumber === 0 && <CrearCirugia showTitle={false} close={close}/>}
                    {modalNumber === 1 && <CrearPaciente showTitle={false} close={close}/>}
                    {modalNumber === 2 && <CrearDoctor showTitle={false} close={close}/>}
                </BottomSwipeDrawer>     
            }
            



            
            
        </>
    )
}

export default CustomizedActionButton;