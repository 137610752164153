import styled from '@emotion/styled';
import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modifyFilter } from '../../../app/filterReducer';
import Button from '../../Atoms/Button';
import CentrosAutocomplete from '../../Forms/CustomAutocomplete/CentrosAutocomplete';
import DoctorsAutocomplete from '../../Forms/CustomAutocomplete/DoctorsAutocomplete';
import { EspecialidadSelectMultiple } from '../../Forms/CustomAutocomplete/EspecialidadSelect';
import EspecialistasAutocomplete from '../../Forms/CustomAutocomplete/EspecialistasAutocomplete';
import VentasAutocomplete from '../../Forms/CustomAutocomplete/VentasAutocomplete';



const FilterFields =({close})=>{

    const filter = useSelector(state=>state.filter.filter);
    
    //changed to using local state in order to do one fetch when user is done (filtrar button). Also to help with input fields that kept value when filter closed via filter chip.
    const [localState, setLocalState] = React.useState();

    React.useEffect(()=>{
        setLocalState({
            doctor: filter.doctor,
            centro: filter.centro,
            ventas: filter.ventas,
            ec: filter.ec,
            especialidad: filter.especialidad || [],
            ars: filter.ars,
            
        })
    },[filter])

    

    const dispatch = useDispatch();
    
    const showInitialState = {
        doctor: false, 
        centro: false,
        ventas: false,
        ec: false,
        especialidad: false,
        ars: false
    }

    const [show, setShow] = React.useState(showInitialState);   


   // console.log(show);

     //onChange for Autocomplete component
     const onChangeValuesId = (name, option)=>{
        console.log('Running on change')
        if(option){            
            console.log('Found option running filter')            
            setLocalState(prevState=>({...prevState,[name]:option?._id}))
        }else{            
            console.log('No option running with zero filter')            
            setLocalState(prevState=>({...prevState,[name]:""}))
        }
        
    }

    //version for MUI Autocomplete
    const onChangeValuesAutocomplete = (event, option, name)=>{
        if(option){                        
            setLocalState(prevState=>({...prevState,[name]:option?._id}))
        }else{                        
            setLocalState(prevState=>({...prevState,[name]:""}))            
        }
        
    }

     //For plain textinput and select fields
     const onChangeHandler = (event)=>{
        const name = event.target.name;
        const value = event.target.value;  
        setLocalState(prevState=>({...prevState,[name]:value}))              
    }

    const onChangeFields = (field)=>{
        // if swith is on, then user is closing switch. Send new filter request without this field populated. 
        if(show[field]){          
          dispatch(modifyFilter({...filter, [field]:"" }));                          
        }
       setShow(prevState => ({...show, [field]: !prevState[field]}))
    }


    const onClickFiltrar = ()=>{
        dispatch(modifyFilter({...filter,...localState}));                
        setShow(showInitialState);
        close();        
    }

    //checks if any field has been switched to open/show. 
    const showFilter = ()=>{
        for(const field in show){
            if(show[field]){
                return true
            }
        }
    } 

    const showFields = showFilter()
    return(
        <>
        <SwitchWrapper show={showFields}>
                    <SwitchContainer>
                        <StyledFormControlLabel control={<Switch size="small" checked={show?.doctor} onChange={()=>onChangeFields('doctor')} />} label="Doctor" />
                        <StyledFormControlLabel control={<Switch size="small"  checked={show?.centro} onChange={()=>onChangeFields('centro')} />} label="Centro" />
                        <StyledFormControlLabel control={<Switch size="small"  checked={show?.ventas} onChange={()=>onChangeFields('ventas')}/>} label="Ventas" />
                    </SwitchContainer>
                    <SwitchContainer>
                        <StyledFormControlLabel control={<Switch size="small" checked={show?.ec} onChange={()=>onChangeFields('ec')}/>} label="EC"/>
                        <StyledFormControlLabel control={<Switch size="small" checked={show?.especialidad} onChange={()=>onChangeFields('especialidad')}/>} label="Especialidad" />
                        <StyledFormControlLabel control={<Switch size="small" checked={show?.ars} onChange={()=>onChangeFields('ars')}/>} label="Ars" />  
                    </SwitchContainer>                
                </SwitchWrapper>      
        <Wrapper>
            {show?.doctor &&
                <DoctorsAutocomplete onChange={onChangeValuesId}/>
            }
            {
            show?.centro && 
                <CentrosAutocomplete  onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "centro")}/>
            }

            {show?.ventas &&
                <VentasAutocomplete onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "ventas")}/>
            }

            {show?.ec && 
                <EspecialistasAutocomplete onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "ec")}/>
            }

            {show?.especialidad &&
                <EspecialidadSelectMultiple
                    label="Especialidad"
                    id="especialidad"
                    name="especialidad"
                    onChange={onChangeHandler}
                    value={localState?.especialidad}
                />
            }
            
            {showFields && 
                <Button primary onClick={onClickFiltrar}>Filtrar</Button>
            }
        </Wrapper>

        </>
    )
}


const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    padding: 0px 4px;
    gap: 8px;
`

const SwitchWrapper = styled.div`
    display:flex;
    padding: 0px 12px;
    margin-bottom: ${p=>p.show? '12px':'0px'}
`

const SwitchContainer = styled.div`
    flex:1;
    display:flex;
    flex-direction: column;
`
const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-size: 0.875rem;
    }    
`

export default FilterFields;