import React from 'react'
import useMediaQuery from './useMediaQuery';



const useModalAndDrawer = ()=>{

    const tablet = useMediaQuery('tablet')

    const [openModal, setOpenModal] = React.useState(false);
    const [inProp, setInProp] =  React.useState(false)
    const close = React.useCallback(()=>{
        if(tablet) {
            setOpenModal(false)
        }else{
            setInProp(false)
            setTimeout(()=>{
                console.log("setTime out starting")
                setOpenModal(false)
            }, 500) //changed from 350 to 500 Sept 13,2022
        }

        
    },[setOpenModal, tablet])


    return {openModal, setOpenModal, inProp, setInProp, close}
}


export default useModalAndDrawer;