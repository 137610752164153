import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setSubToken } from "../../app/authReducer";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { setCookie } from "../../shared/authHelpers";
import socket from "../../shared/socketConnection";
import Layout from "../Layout/Layout";





const  RequireAuth = ()=>{
    const dispatch= useDispatch();
    const {isAuthenticated} = useSelector(state=>state.auth)
    const user = useCurrentUser();
    //const {setSubToken} = useSubscriptionToken();

    //checks for changes in the subscription. 
    React.useEffect(()=>{    
        socket?.on('subscriptionChange', (data)=>{
          let subToken = data.subToken;         
          setCookie('subToken', subToken) 
          dispatch(setSubToken(subToken))          
          return ()=>socket.disconnect()
        })
      },[])
    

    if(!isAuthenticated){
        return <Navigate to="/auth/login"/>
    };

    //if user is an MD must go to md app
    if(user?.role?.md){
        return <Navigate to="/md-app"/>
    }


    return (
        <Layout/>
    )


}


export default RequireAuth;