import styled from '@emotion/styled/macro';
import { useTheme } from "@mui/system"
import React from 'react';
import Button from '../../Atoms/Button';
import Check from "../../Icons/Check"
import Plus from "../../Icons/Plus";








const ConsumoButtonGroup = ({
    cirugia,
    onClick
 
})=>{

    const theme = useTheme();
    const [disableConsumo, setDisableConsumo] = React.useState(cirugia?.reporte?.enviadoProductos);
    const [disableReporte, setDisableReporte] = React.useState(!cirugia?.reporte?.enviadoProductos || cirugia?.reporte?.enviadoReporte); //reporte starts disabled waiting for Consumo to be saved. 

    //once product consumos is saved it disables Consumo button
    React.useEffect(()=>{
        if(cirugia?.reporte?.enviadoProductos){
            setDisableConsumo(true);
        }else{
            setDisableConsumo(false);
        }   
    },[cirugia?.reporte?.enviadoProductos])

    // checks if reporte is enviado and disables. If reporte has not been sent but consumo was saved then activate button
    React.useEffect(()=>{
        if(cirugia?.reporte?.enviadoReporte){
            setDisableReporte(true);
        }else if (cirugia?.reporte?.enviadoProductos){
            setDisableReporte(false);
        }else{
            setDisableReporte(true); //when reporte has not been sent yet, and products get reset, this disables reporte button.             
        }   
    },[cirugia?.reporte?.enviadoReporte, cirugia?.reporte?.enviadoProductos])


    
    return (
        <ButtonGroup>
                <ReporteButton 
                    disabled={disableReporte} 
                    preIcon={cirugia?.reporte?.enviadoReporte &&  cirugia?.reporte?.enviadoProductos && <Check size='16' color={theme.palette.success.light}/>}
                    onClick={()=>onClick('editarReporte')} 
                    primary
                >Reporte
                </ReporteButton>
                <ConsumoButton 
                    primary
                    disabled={disableConsumo} 
                    preIcon={cirugia?.reporte?.enviadoProductos && <Check size='16' color={theme.palette.success.light}/>} 
                    onClick={()=>onClick('consumo')}> Consumo 
                </ConsumoButton>
                <AddButton 
                    primary 
                    disabled={disableConsumo} 
                    preIcon={<Plus size="18" color={!disableConsumo ? '#ffff' : theme.palette.grey[500]}/>}
                    onClick={()=>onClick('addProducto')}
                />
                    
                
        </ButtonGroup>
    )
}


const ButtonGroup = styled.div`
display:flex;
`

const StyledButton = styled(Button)`
    &:hover{
        transform: revert;
        background-color: var(--button-color-hover);        
    }
`

const ReporteButton = styled(StyledButton)`
    border-radius: 4px 0px 0px 4px;
    border-right: 1px solid ${p=>!p.disabled ? p.theme.palette.primary.dark : p.theme.palette.grey[500]};
`

const ConsumoButton = styled(StyledButton)`
    border-radius: 0px;
       
`

const AddButton = styled(StyledButton)`
    padding:0px 8px;
    min-width:20px;
    border-radius: 0px 4px 4px 0px;
    border-left: 1px solid ${p=>!p.disabled ? p.theme.palette.primary.dark : p.theme.palette.grey[500]};
    gap:0px;


    

`


export default ConsumoButtonGroup;




