import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Avatar from '../../Atoms/Avatar';
import { CardBody,CardTitle, CardWrapper as Card } from "../../Atoms/Card";
import CardError from '../../Atoms/CardError';
import Modal from '../../Atoms/Modal';
import DoctorCard from './DoctorCard';
import DoctorCreate from './DoctorCreate';
import DoctorNew from './DoctorNew';


const DoctorCardGrid = ({
    doctors,
    setFormType,
    setOpenModal,
    setDoctor
})=>{
    const theme = useTheme();
    //const [openModal, setOpenModal] = React.useState(false)
    
    //const [formType, setFormType] = React.useState("")

    const onClickCardLinkModal = (formType,doctor)=>{
        setFormType(formType)
        setOpenModal(true)
        setDoctor(doctor)

    }
    
    const location = useLocation();
    

    return (
        <Wrapper>
            
            {doctors?.length >0 &&
                doctors?.map((doctor,index)=>{                    
                    const notInDatabase = doctor?.notInLocalDatabase;
                    
                    if(notInDatabase){                    
                        return(
                            <CardLinkModal key={`${doctor} ${index}`} onClick={()=>onClickCardLinkModal("options", doctor)}>
                                <DoctorCard
                                    doctor={doctor}
                                    index={index}                            
                                />                                   
                            </CardLinkModal>                            
                        )
                    }

                    return ( 
                        <CardLink key={`${doctor} ${index}`} to={`/app/doctors/${doctor._id}`} state={{from: location.pathname}}>     
                            <DoctorCard
                                doctor={doctor}
                                index={index}                            
                            />
                        </CardLink>
                    )
         })
        }

        
                
</Wrapper>
    )
}


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    width: 100%;       
    gap: 8px;
    
`

const CardLink = styled(Link)`
    text-decoration: none;
`

const CardLinkModal = styled.div`

    &:hover{
        cursor: pointer;
    }
`


export default DoctorCardGrid;