import React from 'react';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import styled from '@emotion/styled';
import {keyframes } from '@emotion/react';
import IconButton from "./IconButton";
import ChevronLeft from "../Icons/ChevronLeft";
import { useTheme } from "@mui/system";
import useColor from "../../hooks/useColor";
import useSwipe from "../../hooks/useSwipe";
import { Transition } from 'react-transition-group';


const MobileModal = ({openModal, setOpenModal, title, color, children})=>{

    const [inProp, setInProp] =  React.useState(false)
    const duration=200;
    React.useEffect(()=>{
        if(openModal)setInProp(true)
    },[openModal])

    const theme = useTheme();
    const [backgroundColorHeader, textColor ] = useColor(color);

    const close=React.useCallback(()=>{
        setInProp(false)
        setTimeout(()=>{
            setOpenModal(false)
        }, duration)
    },[setOpenModal])
    
    const [swipeRef, diff] = useSwipe(close, 100, true);
    console.log(diff);

    const defaultStyles={
        transition: `transform ${duration}ms ease-in`,
        transform:'translateY(100%)'
    }

    const transitionStyles ={
        entering:{transform: 'translateY(100%)'},
        entered:{transform: 'translateY(0%)'},
        exiting:{transform: 'translateY(100%)'},
        exited:{transform: 'translateY(100%)'}
    }

    return(
        <Transition 
            in={inProp} 
            timeout={duration}                 
        >
            {
                state =>{
                    console.log(state);
                    return(
                    <MobileOverlay isOpen={openModal}>
                        <Wrapper ref={swipeRef} >
                            <MobileDialogContent diff={diff} style={{...defaultStyles,...transitionStyles[state]}}>
                                <Header style={{
                                    "--background-color-header": backgroundColorHeader,
                                    "--text-color": "black"
                                    }}
                                >
                                    <Item>
                                        <IconButton onClick={close}>
                                            <ChevronLeft color={textColor}/>
                                        </IconButton>
                                    </Item>
                                    <Item style={{flex: 2}}>
                                        <Title>
                                            {title}
                                        </Title>
                                    </Item>
                                    
                                    <Item/>
                                </Header>
                                
        
                                {children}
        
                            </MobileDialogContent>
                         </Wrapper>
                    </MobileOverlay>
                )
            }
            }
            
    </Transition>
    )
}



const MobileOverlay = styled(DialogOverlay)`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
`

const Wrapper = styled.div`
    height:100%;
`

const MobileDialogContent = styled(DialogContent)`
    position: absolute;
    bottom:calc(0px - ${p=>p.diff}px);
    width:100%;
    height:90%;
    background-color: white;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 
    0px 6px 10px 0px rgb(0 0 0 / 14%), 
    0px 1px 18px 0px rgb(0 0 0 / 12%);
    border-radius: 24px 24px 0 0;
`

const Header = styled.div`
    display: flex;
    height: 65px;
    padding: 8px 16px;
    
`

const Title = styled.h1`
    font-size: 1.5rem;
    flex:2;
    text-align: center;
    vertical-align:middle;
    color: var(--text-color);
    font-weight: 600;
`

const Item = styled.div`
    flex:1;
    display: flex;
    align-items: center;
`


export default MobileModal