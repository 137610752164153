import React from 'react';
import styled from '@emotion/styled/macro';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody, FormSubtitle} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';
import useCirugia from '../../hooks/useCirugia';
import TextInput from '../Atoms/TextInput';



const CancelarCirugia = ({
    cirugiaId,
    close,
    setOpenModal
})=>{

    const isTablet = useMediaQuery('tablet');
    
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);    
    const cirugia = useCirugia(cirugiaId);
    const [confirmacion, setConfirmacion] = React.useState("");
    
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker(); 
  
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker('Cirugia cancelada');

        let infoCancelar = {       
            suspendida: false,
            cancelada: true,                 
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion: accion
        }
    
        updateCirugia('cancelar-cirugia', infoCancelar)
  }

  const onChangeHandler = (event)=>{
    setConfirmacion(event.target.value)
}

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>
                {`Cancelar Cirugia de ${cirugia?.paciente?.firstname} ${cirugia?.paciente?.lastname}; cotizacion ${cirugia.cotizacion}`}                
            </FormTitle>
            
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                
            <Text>Al cancelar la cirugia no la veras mas en los listados de cirugia. Podras buscarla por numero de cotizacion o paciente.</Text>    

                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                /> 
                <Text>Por favor confirmar que desea cancelar esta cirugia escribiendo las palabras <StyledConcluir>cancelar cirugia.</StyledConcluir></Text>
                <TextInput
                    label="Cancelar cirugia"
                    value={confirmacion}
                    onChange={onChangeHandler}
                />                               
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                cancelText={"Volver"}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={confirmacion.toLowerCase() !=="cancelar cirugia"}
           />
            
        </FormWrapper>
    )
}


const Text=styled.p`
    margin:0;
    margin-bottom: 8px;
`

const StyledConcluir = styled.span`
    font-weight:700;
    font-style: italic;
`



export default CancelarCirugia