import styled from '@emotion/styled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import FormActionsBasic from "../../Atoms/FormActionsBasic"
import { FormBody, FormHeader, FormSubtitle, FormTitle, FormWrapper } from "../../Atoms/Forms"
import TextInput from '../../Atoms/TextInput';
import { round } from '../../cotizaciones/CrearCotizaciones';
import PreciosMenuButton from "./molecules/PreciosMenuButton"
 
import { errorMessage } from '../../../app/messagesReducer';
import { updateProductosArray } from '../../../app/productosReducer';
import LabelValue from '../../Atoms/LabelValue';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import { FormControlRadio, Radio } from '../../Atoms/Radio';
import { FormControlLabel, Switch } from '@mui/material';


const ProductosPrecios = ({close, producto}) => {
    console.log(producto)
    const dispatch = useDispatch();
    const accionMaker = useAccionMaker()
    const [selectedPriceList, setSelectedPriceList] = React.useState("Base")
    const [subjectToItbis, setSubjectToItbis] = React.useState(producto?.subjectToItbis)
    const [pricePesos, setPricePesos] = React.useState({
        Base: producto?.price?.Base?.pesos || 0,
        Senasa: producto?.price?.Senasa?.pesos || 0,
        Humano: producto?.price?.Humano?.pesos || 0,
        Mapfre: producto?.price?.Mapfre?.pesos || 0,
        Universal: producto?.price?.Universal?.pesos || 0        
    })

    const [priceDolares, setPriceDolares] = React.useState({
        Base: producto?.price?.Base?.dolares || 0,
        Senasa: producto?.price?.Senasa?.dolares || 0,
        Humano: producto?.price?.Humano?.dolares || 0,
        Mapfre: producto?.price?.Mapfre?.dolares || 0,
        Universal: producto?.price?.Universal?.dolares || 0        
    })



    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const [currency, setCurrency] = React.useState("pesos")

    const onChangeHandler = (event, index) => {    
        
            console.log(event.target.value)
            console.log(currency)
            if(currency === "pesos"){
                if(event.target.value === ""){
                    const newPrice = {...pricePesos, [selectedPriceList]: 0}
                    setPricePesos(newPrice)    
                    return
                }
                const newPrice = {...pricePesos, [selectedPriceList]: event.target.value}
                console.log(newPrice)
                setPricePesos(newPrice)
            }else if(currency === "dolares"){
                if(event.target.value === ""){
                    setPriceDolares(0)
                    return
                }
                const newPrice = {...priceDolares, [selectedPriceList]: event.target.value}
                setPriceDolares(newPrice)
            }
        
    }


    const onSubmit = async (event) =>{
        event.preventDefault();
        const accion = accionMaker('Precios modificados')
        const infoPrices = {
           prices:{
            Base:{
                pesos: Number(pricePesos.Base),
                dolares: Number(priceDolares.Base)
            },
            Senasa: {
                pesos: Number(pricePesos.Senasa),
                dolares: Number(priceDolares.Senasa)
            },
            Humano: {
                pesos: Number(pricePesos.Humano),
                dolares: Number(priceDolares.Humano)
            },
            Mapfre: {
                pesos: Number(pricePesos.Mapfre),
                dolares: Number(priceDolares.Mapfre)
            },
            Universal: {
                pesos: Number(pricePesos.Universal),
                dolares: Number(priceDolares.Universal)
            }
           },
            subjectToItbis: subjectToItbis,
           accion             
        }

        const options = createOptions('POST', infoPrices)        
        try {
            const response = await goFetch(`${apiUrl}productos/precios/${producto._id}`, options)
            responseStateChanges(response, ()=>dispatch(updateProductosArray(response.producto)), ()=>close())                                            
        } catch (error) {
            dispatch(errorMessage('Error al actualizar precios'))
        }
        



    }

    const onChangeRadio = (event)=>{        
        setCurrency(event.target.value)
    }


    const onChangeItbis = (event)=>{
        setSubjectToItbis(!subjectToItbis)
    }

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormHeader style={{marginBottom: '16px'}}>
                Precios
                <FormSubtitle>{`${producto?.codigo}-${producto?.descripcion}`}</FormSubtitle>
            </FormHeader>
            <FormBody>
                <Wrapper>
                    <StyledFormControlLabel control={<Switch size="small" checked={subjectToItbis} onChange={()=>onChangeItbis()} />} label="ITBIS" />    
                    <RadioButtonWrapper>                
                        <FormControlRadio 
                            value={currency}
                            onChange={onChangeRadio}
                        >
                            <Radio
                                label="Pesos"
                                id="pesos"
                                name="pesos"
                                value="pesos"
                                checked={currency === "pesos"}
                            />
                            <Radio
                                label="Dolares"
                                id="dolares"
                                name="dolares"
                                value="dolares"   
                                checked={currency === "dolares"}                     
                            />                
                        </FormControlRadio>                         
                    </RadioButtonWrapper>

                    
                    <ContainerPrecioInput>

                        
                        <PreciosMenuButton 
                            selectedPriceList={selectedPriceList}
                            setSelectedPriceList={setSelectedPriceList}
                        />
                        <SmallTextInput                         
                            name="precio"
                            min="0"
                            label={`Precio ${selectedPriceList} ${currency.charAt(0).toUpperCase(0)+currency.slice(1) }`}                        
                            value={currency === 'pesos' ? pricePesos[selectedPriceList] : priceDolares[selectedPriceList]} 
                            onChange={(event)=>onChangeHandler(event)}
                        />
                    </ContainerPrecioInput>
                    <LabelValueContainer>                    
                        {currency === "pesos"?
                            Object.keys(pricePesos).map((key, index)=>{
                                return(
                                <LabelValueRow>
                                        <Label>{key}</Label>
                                        <Value>{round(pricePesos[key])}</Value>
                                        <Info>{(pricePesos[key] !== producto?.price?.[key].pesos && producto?.price?.[key].pesos !== undefined )? "Modificado" : "" }</Info>
                                </LabelValueRow>

                                )
                            })
                        :
                            Object.keys(priceDolares).map((key, index)=>{
                                return(
                                <LabelValueRow>
                                        <Label>{key}</Label>
                                        <Value>{round(priceDolares[key])}</Value>
                                        <Info>{(priceDolares[key] !== producto?.price?.[key].dolares && producto?.price?.[key].dolares !== undefined )? "Modificado" : "" }</Info>
                                </LabelValueRow>

                                )
                            })                        
                        }
                    </LabelValueContainer>
                </Wrapper>
                
            
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
            />

                    
        </FormWrapper>
    )

}


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

`

const Info = styled.span`
    font-size: 0.75rem;
    color: #999;
`


const ContainerPrecioInput = styled.div`
    display: flex;        
    align-items: center;
    justify-content: center;
    gap: 16px;
 `   

 const SmallTextInput = styled(TextInput)`
    width: 150px;
    padding: 0;
    text-align: end;
    padding-right: 8px;
`

const ListOfPrices = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    li{
        display: flex;
        justify-content: space-between;
    }
`


const LabelValueContainer = styled.div`     
    gap: 8px;
    display: flex;
    flex-direction: column ;
`

const LabelValueRow = styled.div`
        display: flex;
        flex-direction: row;
        gap: 8px;
    `

const Label= styled.span`
    font-weight: bold;
    font-size: 0.75rem;
    min-width: 100px;
`

const Value = styled.span`
    font-size: 0.75rem;
`

const RadioButtonWrapper = styled.div`
  
    

    @media ${p=>p.theme.media.tablet}{
        
        
    }
`

const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-size: 0.875rem;
    }    
`

export default ProductosPrecios