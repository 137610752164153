import styled from '@emotion/styled';




const CardError = ({
    children,
    fontSize,
    size,
    ...props
})=>{

    return(
        <StyledCardError fontSize={fontSize} size={size} {...props}>
            {children}
        </StyledCardError>
    )
}



export const StyledCardError = styled.div`
    padding: ${p=>p.size==="small" ? '8px' : '18px'};
    border-radius: 8px;
    border: 1px solid ${p=>p.theme.palette.error.light};
    background-color: ${p=>p.theme.palette.error.pastel};
    color: ${p=>p.theme.palette.error.light};
    font-weight: 600;
    font-size: ${p=>p.fontSize ? p.fontSize : 'revert'};
`

export default CardError