import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';
import React from "react";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import Autocomplete from "../../Atoms/Autocomplete";

const CompanyAutocomplete = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name
})=>{

    const [companies, setCompanies] = React.useState([])
    const user = useCurrentUser()

    React.useEffect(()=>{
        
        
        const fetchCompany = async ()=>{
            const options = createOptions();
            try {
                const companies = await goFetch(`${apiUrl}md-users/casas-comerciales-conectadas/connected`, options)
                setCompanies(companies.companies)    
            } catch (error) {
                console.log(error)
            }
                        
        }

        fetchCompany()
        

    },[])



    return(
        <Autocomplete                  
            label="Compañias"
            name="company"
            id="company"
            value={value}
            onChange={onChange}            
            options={companies}
            getOptionLabel={(option)=>`${option.name}`}
        />


    )
}

export default CompanyAutocomplete