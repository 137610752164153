import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setSearchValue } from "../../../app/doctorsReducer";
import { fetchDoctors, searchDoctors } from "../../../app/thunkActions/doctorsActions";
import isEmpty from "../../../shared/isEmptyOject";

import SearchBar from "../../Atoms/SearchBar";




const DoctorsSearchBar = ()=>{
    // gave it to paren in order to know when searching to change filter buttons
    //const [searchValue, setSearchValue] = React.useState();

    const { filter, searchValue, isLoading } = useSelector(state=>state.doctors);    

    const dispatch = useDispatch();

    const onChangeSearchValue = (event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        dispatch(setSearchValue(searchTerm))
        if(searchTerm.length>2){
            dispatch(searchDoctors(searchTerm))              
            if(Object.keys(filter).length !==0) dispatch(setFilter({}))            
        }

        if(!searchTerm){
            dispatch(fetchDoctors(filter,null, true))
            
        }
         

    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar doctor"
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoading}
    />
    )
}



export default DoctorsSearchBar