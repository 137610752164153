import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createOptions, goFetch } from "../api/cirugiasApi";
import { addOneCirugia } from "../app/cirugiaReducer";
import { addUpdatedCirugia } from "../app/cirugiasReducer";
import { errorMessage, successMessage } from "../app/messagesReducer";
import { apiUrl } from "../shared/url";


//provides loading and success event and includes fetch for updating cirugias


const useUpdateCirugia = (close)=>{
    const dispatch = useDispatch()
    const [isLoading,setIsLoading] = React.useState(false);
    const [successEvent, setSuccessEvent] = React.useState(false);
    const params = useParams();


    const checkIfCirugiaPage = (response)=>{
        console.log(response._id)
        console.log(params.cirugiaId)
        if(response._id === params.cirugiaId){
            console.log('in cirugia page')
            dispatch(addOneCirugia(response))
            return
        }else{
            return
        }
    }

    const updateCirugia = async(url, values)=>{

        const options = createOptions('PUT', values)
        let response;

        try{
            if(isLoading || successEvent) return
            setIsLoading(true);
            response = await goFetch(`${apiUrl}cirugias/${url}/${values.cirugiaId}`, options, dispatch)
            if(response.error) throw new Error(response.error) 
            checkIfCirugiaPage(response);
            dispatch(addUpdatedCirugia(response));
            //added this in order to have available cirugia after refresh on cirugia or when coming from page other than cirugias list
            //dispatch(addOneCirugia(response.cirugia));
            setSuccessEvent(true);
            dispatch(successMessage('Cirugia actualizada correctamente.'));
            setIsLoading(false);
            setTimeout(()=>{
                close && close();
                setSuccessEvent(false);
            }, 1500)
        }catch(error){
            console.log(error);
            setIsLoading(false)            
            dispatch(errorMessage(error.error || "Problema de servidor, por favor intente mas tarde."))
        }
    }


    return { 
        isLoading,
        setIsLoading,
        successEvent,
        setSuccessEvent,
        updateCirugia

    }
}


export default useUpdateCirugia;