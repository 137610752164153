import React from 'react';
import {useDispatch} from 'react-redux';
import styled from '@emotion/styled';
import TextInput from '../Atoms/TextInput';
import PacientesAutocomplete from './CustomAutocomplete/PacientesAutocomplete';
import DoctorsAutocomplete from './CustomAutocomplete/DoctorsAutocomplete';
import CentrosAutocomplete from './CustomAutocomplete/CentrosAutocomplete';
import EspecialidadSelect from './CustomAutocomplete/EspecialidadSelect';
import MetodoPagoRadio from './CustomAutocomplete/MetodoPagoRadio';
import Productos from '../cirugias/Molecules/ProductosModificationInterface';
import useMediaQuery from '../../hooks/useMediaQuery';
import { validate, isCotizacion } from '../../shared/validators';
import useCheckForm from '../../hooks/useCheckForm';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import VentasAutocomplete from './CustomAutocomplete/VentasAutocomplete';
import { FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import useSuccessButton from '../../hooks/useSuccessButton'
import { getCookie} from '../../shared/authHelpers';
import CardError from '../Atoms/CardError';
import useSubscriptionToken from '../../hooks/useSubscriptionToken';
import { useConfiguration } from '../../hooks/useConfiguration';


const CrearCirugia = ({
    showTitle=true,
    setOpenModal,
    close
})=>{

    
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();
    const user = useCurrentUser();    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const {isSubscribed, checkIfSubscribed} = useSubscriptionToken();
    const configuration = useConfiguration()
    const isPacienteRequired = (configuration?.cirugias?.requiredPaciente === false) ? false : true
    
    
    React.useEffect(()=>{
        if(!isSubscribed){           
            checkIfSubscribed()
        }
    },[isSubscribed,checkIfSubscribed])

    
    console.log(`Is user subscribed: ${isSubscribed}`)
   

    // changed paciente and doctor inital state from object to string so that it would work with useCheckForm August 27, 2022
    const initialState = {
        company:{
            _id: user.company._id,
            name: user.company.name
        },
        paciente: "",
        cotizacion: "",
        especialidad: "",
        doctor: "",
        centro: "",
        ventas: "",
        pago: {
          tipo: "",
          nombre: "",
          monto: ""
        },
        productos : [],
        info: {
          agendada: false,
          realizada: false,
        }
      }
    
    const [values, setValues] = React.useState(initialState)

    console.log(values);

      const [errors, setErrors] = React.useState({
        cotizacion: ""
      })

      const requiredFields = ["cotizacion", "especialidad", "doctor", "centro", "productos"]
      if(isPacienteRequired) requiredFields.push("paciente")

      const [formCompleted, errorCount] = useCheckForm(values,errors, requiredFields)

    console.log(values);
    

    //onChange for Autocomplete component
    const onChangeValuesId = (name, option, type)=>{
        console.log('Running on change')

        console.log(option)

        let info = {
            _id: option._id,
            firstname: option.firstname,
            secondname: option.secondname,
            lastname: option.lastname,
            secondLastname: option.secondLastname,
        }

        if(type ==="doctors") info.mdId = option?.connected?.mdId

        setValues(prevState => ({...prevState, [name]:info}))
        setValues(prevState=> {console.log(prevState); return prevState})
    }

    const onChangeValuesPaciente = (name, option, type)=>{
        console.log('Running on change')

        console.log(option)

        let info = {
            _id: option._id,
            firstname: option.firstname,
            secondname: option.secondname,
            lastname: option.lastname,
            secondLastname: option.secondLastname,
            nss: option.nss,
            cedula: option.cedula
        }

        //if(type ==="doctors") info.mdId = option.connected.mdId

        setValues(prevState => ({...prevState, [name]:info}))
        setValues(prevState=> {console.log(prevState); return prevState})
    }



    //version for MUI Autocomplete
    const onChangeValuesAutocomplete = (event, option, name)=>{

        let info;

        if(name==="centro" && option){
            info = {
                _id : option._id,
                nombre : option.nombre,
                ciudad : option.ciudad,
                zona : option.zona 
            }            
        }else if(option){
            info = {
                _id: option._id,
                firstname: option.firstname,
                secondname: option.secondname,
                lastname: option.lastname,
                secondLastname: option.secondLastname
            }
        }

        if(option){
            setValues(prevState => ({...prevState, [name]:info}))
        }else{
            setValues(prevState => ({...prevState, [name]:""}))
        }
        
    }

    //For plain textinput and select fields
    const onChangeHandler = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setValues(prevState => ({...prevState, [name]:value}))
    }

    //function to manage addition of products
    const onChangeProducts = (selectedProduct)=>{
        setValues(prevState=>({...prevState, productos: [...prevState.productos, selectedProduct]}))
    }

    //function to manage deletion of products
    const onDeleteProducts = (index)=>{
        let newProductArray = [...values.productos];
        newProductArray.splice(index,1);
        setValues(prevState=>({...prevState, productos:newProductArray}))
    }

    

    const onBlurCotizacion = async(event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
        if(!error){
            const options = createOptions('GET');
            const response = await goFetch(`${apiUrl}cirugias/cotizacion-exists/${value}`, options)
            if(response.exists){
                setErrors(prevState=>({...prevState, [name]:"Cotizacion ya existe en la base de datos. No se puede repetir."}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        }
    }

    //hay que arrelgar el chequeo de form completed porque no toma en cuenta objects que se borraron. mayo 6 2pm
    const onSubmit = async(event)=>{
        event.preventDefault();
        let cirugiaInfo = {...values}
        //check if centro is selected as pago method, if so, check if pago.nombre is empty, if it is set centro nombre as pago.nombre
        if(values.pago.tipo === "Centro" && !values.pago.nombre){
             cirugiaInfo.pago.nombre = values.centro.nombre
        }

        const subToken = getCookie('subToken')
        
        setIsLoading(true)

        const options = createOptions('POST', {...cirugiaInfo, ...{subToken, createCirugia: true} });
        const response = await goFetch(`${apiUrl}cirugias/create-cirugia`, options, dispatch)
        responseStateChanges(response, null, ()=>close());
               
    }


    return (
        <StyledFormWrapper onSubmit={onSubmit}>                
            <FormTitle isTablet={isTablet}>Crear Cirugia</FormTitle>
            {!isSubscribed && <CardError fontSize={'0.875rem'}>{`Suscripcion vencida, no podras crear cirugias.`}</CardError>}
            <br/>
            <FormBody isTablet={isTablet}>
                <PacientesAutocomplete onChange={onChangeValuesPaciente}/>
                <TextInput
                    label="Cotizacion"
                    name="cotizacion"
                    id="cotizacion"
                    value={values.cotizacion}
                    onChangeHandler={onChangeHandler}
                    helperText={!errors.cotizacion ? "Campo Obligatorio": errors.cotizacion}
                    error={errors.cotizacion}
                    onBlurValidation={(event)=>onBlurCotizacion(event, [])}
                />
                <EspecialidadSelect
                    label="Especialidad"
                    id="especialidad"
                    name="especialidad"
                    value={values.especialidad}
                    onChange={onChangeHandler}
                />
                <DoctorsAutocomplete onChange={(event, option)=>onChangeValuesId(event, option, 'doctors')}/>
                <CentrosAutocomplete onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "centro")} />
                <VentasAutocomplete onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "ventas")} />              
        
                <MetodoPagoRadio 
                    values={values}
                    setValues={setValues}
                />
                
                <Productos 
                    onChangeProducts={onChangeProducts}
                    onDeleteValuesProducts={onDeleteProducts}
                />
            </FormBody>
            
            <FormActionsBasic
                close={close}
                type="submit"
                as='button'
                loading={isLoading}
                successEvent={successEvent}
                disabledSuccess={!formCompleted || errorCount>0 || !isSubscribed}
                
           />
            
        </StyledFormWrapper>

    )
}



//overlow auto restored full 100% heigh as content grows
const Wrapper = styled.form`
    width:100%;
    height:100%;
    overflow-y:auto;
    min-width:500px;
    
`

const StyledFormWrapper = styled(FormWrapper)`
    max-width: 600px;

    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }

`

export default CrearCirugia;