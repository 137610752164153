import styled from '@emotion/styled/macro';
import useMediaQuery from '../../hooks/useMediaQuery';
import { CardBody,CardTitle, CardWrapper as Card } from "../Atoms/Card";
import Avatar from '../Atoms/Avatar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCentros } from '../../app/thunkActions/centrosActions';
import { Link } from 'react-router-dom';
import CentrosSearchBar from './molecules/CentrosSearchBar';
import CentrosListLoading from './molecules/CentrosListLoading';
import IconButton from '../Atoms/IconButton';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import CardInfo from '../Atoms/CardInfo';
import Plus from '../Icons/Plus';
import { useTheme } from '@mui/material';
import Modal from '../Atoms/Modal';
import CentroCrear from './molecules/CentroCrear';
import usePermissions from '../../hooks/usePermissions';



const CentrosList=()=>{

    const dispatch = useDispatch();
    const theme = useTheme();
    const isTablet =useMediaQuery('tablet');
    const { centros, isLoading } = useSelector(state=>state.centros);
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();   
    const smallBottomSwipeDrawers = [];
    const permissions = usePermissions()
    const {canCrearCentros} = permissions;
    
    React.useEffect(()=>{
        dispatch(fetchCentros())
    },[]);

    const handleOnClick = (formType)=>{
        setOpenModal(true);        
        setFormType(formType)
    }


    return (
        <ExternalWrapper>
            <Wrapper>
            <Header>
                <SearchBarWrapper>                
                    <CentrosSearchBar/>
                    {canCrearCentros && <StyledIconButton onClick={()=>handleOnClick('addCentro')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>                }
                </SearchBarWrapper>
            </Header>
               <Body>
                    { isLoading ? <CentrosListLoading/> :
                            (!centros || !centros.length > 0) ? <CardInfo>No se econtraron Centros</CardInfo> :
                            <GridWrapper isTablet={isTablet}>                                
                                {centros.length > 0 &&
                                    centros?.map((centro,index)=>{
                                        return ( 
                                            <CardLink key={`${centro} ${index}`} to={`/app/centro/${centro._id}`}>                            
                                                <Card style={{margin:0, paddingBottom: "8px"}} key={`${centro} ${index}`}>
                                                    <StyledCardHeader>
                                                        <Avatar src={centro?.profilePicture} alt="user avatar">{centro?.nombre[0]}</Avatar>
                                                        <UserDescription >
                                                            <StyledCardTitle>{`${centro?.nombre}`}</StyledCardTitle>
                                                            <CardSubTitle>{`${centro?.ciudad || ""}`}</CardSubTitle>                                            
                                                        </UserDescription>
                                                    
                                                    </StyledCardHeader>
                                                    <CardBody style={{flexDirection: "row"}}>
                                                        <Container>
                                                            <InfoContainer>
                                                                
                                                            </InfoContainer>                                                                 
                                                        </Container>
                                                        
                                                    </CardBody>
                                                </Card>
                                            </CardLink>
                                        )
                                })}
                                            
                            </GridWrapper>
                    }

                </Body>
                </Wrapper>
                <Modal
                    openModal={openModal}
                    setOpenModal={setOpenModal} 
                    inProp={inProp}
                    setInProp={setInProp}
                    close={close}
                    smallDrawer = {smallBottomSwipeDrawers.includes(formType)}           
                >
                {formType === "addCentro" && <CentroCrear  close={close}/>}            
                
            </Modal>        

        </ExternalWrapper>
        
    )
}


const ExternalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding-bottom: 24px;
`

const Wrapper = styled.div`
    width:100%;
    max-width: 1000px;
`

const Header = styled.div`
    display: flex;
    gap:8px;
    justify-content: center;
    margin-bottom: 24px;
`
const Body = styled.div`
    width:100%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 24px;

    @media ${p=>p.theme.media.tablet}{
        
    }

`

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));    
    gap: 8px;
    width:100%;

    @media ${p=>p.theme.media.tablet}{
        width: 75%;
        margin-left:auto;
        margin-right:auto;
    }
`



const StyledCardTitle = styled(CardTitle)`
    font-size: 1rem;
    margin:0;
    color: ${p=>p.theme.palette.grey[800]}; 
`

const StyledCardHeader = styled.div`
    display:flex;
    gap: 12px;        
`

const UserDescription = styled.div`
    flex-direction: column;
    justify-content: center;
    min-height: 55px;
    margin-bottom: 16px;
`

const CardSubTitle = styled.h5`
    margin: 0px;
    color: ${p=>p.theme.palette.grey[600]};    
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    
`

const Container = styled.div`
    display:flex;
    justify-content: space-between;
`


const CardLink = styled(Link)`
    text-decoration: none;
`

const SearchBarWrapper = styled.div`
    width:100%;
    display: flex;
    gap:8px;
    justify-content: center;
    align-items:center;    
`

const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`
export default CentrosList;