import React from 'react';
import styled from '@emotion/styled';
import IconButton from '../Atoms/IconButton';
import MenuIcon from '../Icons/Menu';
import AvatarMenu from '../molecules/AvatarMenu';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import Modal from '../Atoms/Modal';
import Notifications from '../Forms/Notification';
import InvitationNotification from '../md/layout/InvitationNotification';
import { useLocation } from 'react-router-dom';
import Button from '../Atoms/Button';
import CardError from '../Atoms/CardError';
import useSubscriptionToken from '../../hooks/useSubscriptionToken';
import CardInfo from '../Atoms/CardInfo';


const Header = ({open,scrolled,onClick})=>{

    const user = useCurrentUser();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState("");
    const smallBottomSwipeDrawers = ["notification"]
    const {isSubscribed, product} = useSubscriptionToken()
    const isMd = user?.role?.md

    let location = useLocation()

  React.useEffect(() => {
    
      // check for sw updates on page change
      console.log('checking for sw updates')
      
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    
  }, [location]);

  const handleUpdateServiceWorker = () => {
    navigator.serviceWorker
      .getRegistrations()
      .then((regs) => regs.forEach((reg) => {
        reg.waiting.postMessage({ type: "SKIP_WAITING" });
      }));

      const updateButton = document.getElementById('update');
      updateButton.style.display = "none";
      window.location.reload();
  };



    return(
        <>
        <StyledHeader open={open} scrolled={scrolled}>
            <Content>
                {!open && 
                <IconButton onClick={onClick}>
                    <MenuIcon style={{color:'white'}}/>
                </IconButton>
                } 
                {open && <Space/>}
                <InnerWrapper>
                    {product === 'trial' && !isMd && <CardInfo size="small" fontSize='0.875rem'>Periodo de prueba</CardInfo>}
                    {!isSubscribed && !isMd && <CardError size="small" fontSize='0.875rem'>Suscripcion no esta activada</CardError>}
                    <Button id="update" outline primary style={{display: "none"}} onClick={handleUpdateServiceWorker}>Actualizar</Button>
                    {user?.role?.md && <InvitationNotification/>}
                    <AvatarMenu setOpenModal={setOpenModal} setFormType={setFormType}/>  
                </InnerWrapper>
                
            </Content>            
        </StyledHeader>
        <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
            >
                {formType === "notification" && <Notifications close={close}/>}                
             </Modal>     

        </>
    )
}

 



const StyledHeader = styled.header`
    height: var(--header-height);
    background-color: ${p=>p.theme.palette.primary.main}; 
    box-shadow: ${p=>p.scrolled?`0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%);`: 'none'} ;
    width: ${p=> p.open ? 'calc(100%-100px)' : '100%'};
    width: ${p=> p.open ? 'calc(100% - var(--drawer-width))' : '100%'};
    
    position: fixed;
    top:0;
    left:${p=> p.open ? 'var(--drawer-width)' : '0'};
    display:grid;
    align-items: center;
    transition: width 300ms ease-in, left 300ms ease-in;
    z-index:100;
`
const Content = styled.div`
    display: flex;
    padding: 8px 24px;
    justify-content: space-between;
`

const Space = styled.div`
`

const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    
`



export default Header;

/*
height: var(--header-height);
    background-color: ${p=>p.theme.palette.primary.main}; 
    box-shadow: ${p=>p.scrolled?`0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%);`: 'none'} ;
    
    width: 100%;
    margin-left:${p=> p.open ? 'var(--drawer-width)' : '0px'};
    position: fixed;
    top:0;
    left:0;
    display:grid;
    align-items: center;
    transition: margin-left 300ms ease-in;
    z-index:100;

    */