import { useLocation, useNavigate, useParams } from "react-router-dom"
import styled from '@emotion/styled/macro';
import Comments from "./Comments";
import React from "react";
import { fetchCirugia } from "../../app/thunkActions/cirugiasActions";
import { useDispatch, useSelector } from "react-redux";
import Historia from "./Molecules/Historia";
import ProductosDetalle from "./Molecules/ProductosDetalleCard";
import CirugiasCard from "./Molecules/CirugiasCard";
import CirugiasModal from "./CirugiasModal";
import BackButton from "../Atoms/BackButton";
import Reporte from "./Molecules/Reporte";
import { CardBody, CardWrapper } from "../Atoms/Card";
import useModalAndDrawer from "../../hooks/useModalAndDrawer";
import { addOneCirugia, resetCirugia } from "../../app/cirugiaReducer";
import { addCirugia } from "../../app/cirugiasReducer";
import { CirugiaCardSkeleton } from "./Molecules/CirugiasCardLoading";


const Cirugia = ()=>{

    const params = useParams();    
    const dispatch = useDispatch();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer(); 
    const {cirugias} = useSelector(state=>state.cirugias)
    const [cirugiaId, setCirugiaId] = React.useState();
    const [formType, setFormType] = React.useState();
    const navigate = useNavigate();
    const location = useLocation();
    
    //let cirugia;
    
    const _cirugia= cirugias?.filter((cirugia)=>cirugia._id === params.cirugiaId)[0]

    const {cirugia, isLoading} = useSelector(state=>state.cirugia)
    
    
    //This component needs to manage two store states. 
    // First the state that comes from the Cirugias list. This is kept in order to speed up experience when working diretly from the original cirugia list filters
    //second in case of page refresh we must rehydrate the Cirugia state and the Cirugias state in order for the update hook to work as it updates the Cirugias list state when response comes back

    React.useEffect(()=>{
        //When coming from cirugia just load the cirugia from cirugias list in the cirugia store. We do this in order to not have a refresh when updating cirugia infromation
        if(location?.state?.from === 'cirugias' && _cirugia){
            dispatch(addOneCirugia(_cirugia));
         
        //if there is no cirugia loaded from a previous fetch or from the Cirugias list (i.e. when we refresh page or come from a place other than cirugias list) fetch an individual cirugia
        //modified cirugias action in order to update Cirugias list state with cirugia in order for update hook to work.     
        }else if(!cirugia && !_cirugia){
            dispatch(fetchCirugia(params.cirugiaId))
        }
        
            return ()=>dispatch(resetCirugia())
    },[_cirugia, dispatch, params.cirugiaId, location.state?.from, location.pathname, navigate])

    

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])



    React.useEffect(()=>{
        if(!cirugias && cirugia){
            dispatch(addCirugia({cirugias:[cirugia]}));
        }
        
    },[cirugia])
    
    // if(location?.state?.from !== 'cirugias'){
    //     cirugia = fetchedCirugia
    // }else{
    //     cirugia = fetchedCirugia || _cirugia;
    // }

    // cirugia = fetchedCirugia || _cirugia;

    //logic to be able to return to proper page after back button.
    let backNavigate = '/app/cirugias'    
    if(location.state?.path === '/app/cirugias/search'){
        backNavigate = location.state?.path
    }else if(location.state?.path === '/app/activity'){
        backNavigate = location.state?.path
    }else if(location.state?.path === '/app/cotizaciones'){
        backNavigate = location.state?.path
    }

            
    return(

        <>
        
            <ExternalWrapper>
                
                <Wrapper>
                {
                    !cirugia ? <CirugiaCardSkeleton/> :
                    <>                
                        <BackButton onClick={()=>navigate(backNavigate,{state:{from:"cirugia"}})}/>            
                        <CirugiasCard                        
                            cirugia={cirugia}
                            setOpenModal={setOpenModal}
                            setCirugiaId={setCirugiaId}
                            formType={formType}
                            setFormType={setFormType}                                        
                        />
                        <ProductosDetalle cirugia={cirugia} productos={cirugia?.productos} scroll={false}/>
                        <Row>
                            <Comments 
                                cirugiaId={cirugiaId} 
                                comments={cirugia?.comentarios}
                                close={close}
                                setOpenModal={setOpenModal}
                                setFormType={setFormType}
                                setCirugiaId={setCirugiaId} 
                                addComment={true}
                                />
                            <Historia cirugia={cirugia}/>   
                        </Row>
                        {cirugia.reporte?.enviadoReporte &&
                            <CardWrapper>
                                <CardBody>{
                                    }
                                <Reporte cirugia={cirugia}/>
                                </CardBody>
                                
                            </CardWrapper>
                        }
                        <CirugiasModal
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            inProp={inProp}
                            setInProp={setInProp}
                            close={close}
                            formType={formType}
                            cirugiaId={params.cirugiaId}
                            cirugia={cirugia}
                        />   
                    </>
                }
                </Wrapper>
            </ExternalWrapper>
        
        </>
    )



}

const ExternalWrapper = styled.div`
    display:flex;
    width:100%;
    justify-content: center;
`


const Wrapper = styled.div`    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap:4px;
    max-width: 800px;
    width:100%;

`

const Row = styled.div`
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${p=>p.theme.media.tablet}{
        flex-direction: row;
    }
    
`

const Item = styled.div`
    width: ${p=>p.isLaptop? "33%" : "100%"};
    
    
`

const ItemProducts = styled.div`
width: ${p=>p.isLaptop? "66%" : "100%"};

`



export default Cirugia;