import React from "react";
import BackDrop from "./BackDrop";
import styled from '@emotion/styled/macro';
import Button from './Button';
import { keyframes } from '@emotion/react';
import X from '../Icons/X';
import useMediaQuery from "../../hooks/useMediaQuery";








const ActionButton = ({actions, test, onClick})=>{
    //console.log(actions);
    //console.log(test);
    
    const isLapTop = useMediaQuery('laptop');
    const [open, setOpen] = React.useState(false);
    const [rotate, setRotate] = React.useState(false);
    
    const onClickHandler =()=>{
        if(open) {
            setOpen(false)
        }else{
            setOpen(true)
        }

        if(rotate) {
            setRotate(false)
        }else{
            setRotate(true)
        }
        
    

    }
    
    const onMouseOverHandler =()=>{
        if(!isLapTop)return
        setOpen(true);
        setRotate(true);
    }
    const onMouseLeaveHandler =()=>{
        if(!isLapTop)return
        setOpen(false);
        setRotate(false);
    }

    return(
        <>{ open &&
            <BackDrop onClick={onClickHandler}/>
        }
            <Container open={open}>
                    <StyledActionButton primary onClick={onClickHandler} onMouseOver={onMouseOverHandler}>
                        <StyledSpan rotate={rotate}><StyledX size={20}/></StyledSpan>    
                    </StyledActionButton>    
                {open &&
                    <Wrapper onMouseLeave={onMouseLeaveHandler}
                    >
                        {
                            actions?.map((action, index)=>{
                                if(!action.allowed) return ""

                                return(
                                <SelectionButton
                                    key={`${action} ${index}`}
                                     index={index}
                                     onClick={(e)=>{onClick(e,index); setOpen(false); setRotate(false); }}
                                >
                                    {action.icon}
                                </SelectionButton>
                            )})
                        }
                    
                    
                                              
                    </Wrapper>
                }            
            </Container>
           
        </>
    )
}

const StyledX = styled(X)`
    
`

const slideIn = keyframes`
        from{
            transform: scale(0);
        }
        to{
            transform:scale(1)        
        }
    `

const Container = styled.div`
    position: fixed;
    bottom:15px;
    right:15px;
    width:90px;    
    height:300px;
    pointer-events:${p=>p.open? 'revert' : 'none'};
`
const Wrapper = styled.div`
    display: flex;    
    flex-direction: column-reverse;
    
    
    height: 100%;
    padding-bottom: 80px;
    width:100%;
    align-items: center;
    
    
`




const StyledSpan = styled('span',{
    shouldForwardProp: prop=>prop!=="rotate"
})(props=>({
    width:"fit-content",
    display: "inline-block",
    lineHeight: "0",
    transform: props.rotate ? 'rotate(90deg)':'rotate(45deg)',
    transition: "transform 100ms ease-in",
}))
    
    
    

const StyledActionButton = styled(Button)`
    border-radius: 50%;
    padding: 16px;
    position: fixed;
    bottom:32px;
    right:32px;
    border: none;
    width: 56px;
    min-width:56px;
    height: 56px;
    max-height: revert;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 
    0px 6px 10px 0px rgb(0 0 0 / 14%), 
    0px 1px 18px 0px rgb(0 0 0 / 12%);
    box-sizing: border-box;
    line-height: none;
    outline: none;
    

    &:hover{        
        box-shadow: revert;
        
    }
`

const SelectionButton = styled(Button)`
    background-color:white;
    border-radius: 50%;
    border: none;
    width: 36px;
    min-width:36px;
    height: 36px;
      
    margin-bottom: 8px;
    animation: ${slideIn} ${p=>(p.index+1) * 100}ms ease-in;
    &:hover{        
        box-shadow: revert;
    } 
`

export default ActionButton;