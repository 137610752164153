import { useDispatch } from "react-redux";
import { updateCirugia } from "../../app/thunkActions/cirugiasActions";
import { useAccionMaker } from "../../hooks/useAccionMaker"
import useCirugia from "../../hooks/useCirugia";


export const useReset = (cirugiaId)=>{

    const accionMaker = useAccionMaker();
    const dispatch = useDispatch();
    const cirugia = useCirugia(cirugiaId)

    const resetConsumo = ()=>{

        
        let newArray = cirugia?.productos?.map((producto)=>({
            _id: producto._id,
            producto: {
                _id:producto.producto?._id,
                codigo: producto.producto?.codigo,
                descripcion: producto.producto?.descripcion
            },
        
            cantidad: producto.cantidad,
            cantidadUsada: ""
        }))
              
        

        const accion = accionMaker('Consumo de productos fue reabierto luego de guardado')
        
        let infoProductos ={
            productos: newArray,
            cirugiaId: cirugiaId,
            enviadoProductos: false,  // used in database to signal productos has been reported by EC and cannot be modified.
            resetEnviadoProductos: true,
            accion: accion
          }

        console.log(infoProductos)
        dispatch(updateCirugia("update-productos",infoProductos));

    }

    const resetReporte = ()=>{
        const accion = accionMaker('Reporte fue reabierto luego de enviado "reset"')
        
        let infoProductos ={           
            cirugiaId: cirugiaId,
            enviadoReporte: false,
            enviadoReporteFlag:true,
            accion: accion
          }

        console.log(infoProductos)
        dispatch(updateCirugia("update-reporte",infoProductos));
    }

    return [resetConsumo, resetReporte];
}