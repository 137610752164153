import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';

const DocumentacionChip = ({pago})=>{
    const theme = useTheme()
    let backgroundColor;
    const statusPago = pago?.statusPago || "No";
    

    if(statusPago === "Ok") backgroundColor = theme.palette.success.light;
    if(statusPago === "Ok OR") backgroundColor = theme.palette.warning.light;
    if(statusPago === "No") backgroundColor = theme.palette.error.light;
    
    return(        
        <Chip color={backgroundColor} label={statusPago} />
    )
}



export default DocumentacionChip;