import React from "react";
import styled from '@emotion/styled/macro';
import { CardActions, CardBody, CardHeader, CardWrapper } from "../../../Atoms/Card";
import ThumbsUp from "../../../Icons/ThumbsUp";
import ThumbsDown from "../../../Icons/ThumbsDown";
import Chip from "../../../Atoms/Chip";
import Check from "../../../Icons/Check";
import Calendar from "../../../Icons/Calendar";
import formatDate from "../../../../shared/dateFormatter";
import formatTime from "../../../../shared/timeFormatter";
import Clock from "../../../Icons/Clock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDolly, faHospital, faIdCard, faUser, faUserMd, faUserNurse, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import DocumentosChips from "../../../cirugias/Chips/DocumentosChips";
import Review from "./Review";
import { IconButton, useTheme } from "@mui/material";
import MessageSquare from "../../../Icons/Message-square";
import Button from "../../../Atoms/Button";
import userEvent from "@testing-library/user-event";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { createOptions, goFetch } from "../../../../api/cirugiasApi";
import { apiUrl } from "../../../../shared/url";
import useDateAndTimeFormatter from "../../../../hooks/useDateAndTimeFormatter";







const CirugiasMdCard = ({
    cirugias,
    handleOnClick
})=>{
        
    const theme = useTheme();
    const user = useCurrentUser()
    const {formatTime} = useDateAndTimeFormatter()



    const modifyCirugias = (cirugia)=>{

        //temporary escape hatch until all cirugias have an mdId
        //if(!cirugia.doctor.mdId) return true

        let result = true;

        if(cirugia.doctor?.mdId === user._id) return true;
        console.log(cirugia)

        user?.sharedFrom?.forEach((shared)=>{
            console.log(shared)
            if(cirugia.doctor.mdId === shared.mdUserId){
                console.log('cirugia matched')
                if(shared.permissions.agendar.state){
                    result = true
                    return
                }else{
                    result = false
                    return
                }
            }
            
                
            
        })
        
        return result
    }

    

    
    return(
        <>
        {
                    cirugias?.map((cirugia, index)=>{

                        let calendarColor = theme.palette.grey[800];

                        if(cirugia.info?.agendada && !cirugia.info?.aceptada && !cirugia.info?.suspendia && !cirugia.info?.rechazada){
                            calendarColor = theme.palette.warning.light;
                        }

                        if(cirugia.info?.suspendida){
                            calendarColor = theme.palette.error.light;
                        }

                        if(cirugia.info?.rechazada){
                            calendarColor = theme.palette.error.light;
                        }
                        
                        const agendada = cirugia?.info?.agendada;
                        const realizada = cirugia?.info?.realizada;
                        const aceptada = cirugia?.info?.aceptada;
                        const suspendida = cirugia?.info?.suspendida;
                        const rechazada = cirugia?.info?.rechazada;

                        const canModify = modifyCirugias(cirugia) // need to add mdId to all cirugias. 
                        
                        return(
                        <Card key={`${cirugia} ${index}`}>
                            <CardHeader>
                                <HeaderContainer>
                                    <PacienteContainer>
                                        <PacienteName>{`${cirugia.paciente.firstname} ${cirugia.paciente.secondname} ${cirugia.paciente.lastname} ${cirugia.paciente.secondLastname}`}</PacienteName> 
                                        <Subtitle>{cirugia.paciente.nss ? `NSS: ${cirugia.paciente.nss}` : "NSS: No disponible"}</Subtitle>
                                        <Subtitle>{cirugia.paciente.cedula ? `Cedula: ${cirugia.paciente.cedula}` : "Cedula: No disponible"}</Subtitle>
                                        <Subtitle>{`Cotizacion: ${cirugia.cotizacion}`}</Subtitle>
                                    </PacienteContainer>
                                    <ChipContainer>
                                        {
                                        cirugia.evaluacion.positiva && 
                                             <ThumbsUp size="22" color={theme.palette.success.light}/>
                                        }
                                        {
                                        cirugia.evaluacion.negativa && 
                                             <ThumbsDown size="22" color={theme.palette.error.light}/>
                                        }
                                        {agendada && aceptada && !realizada && !suspendida && !rechazada &&
                                            <Chip success label={"Agendada"}/>       
                                        }
                                        {agendada && !aceptada && !suspendida && !rechazada &&
                                            <Chip warning label={"Solicitada"}/>       
                                        }
                                        {
                                            realizada && 
                                            <Chip icon={<Check size="18" color="#ffff"/>} success label={"Realizada"}/>       
                                        }
                                        {
                                            suspendida && 
                                            <Chip error label={"Suspendida"}/>       
                                        }   
                                        {
                                            rechazada && 
                                            <Chip error label={"Rechazada"}/>       
                                        }             
                                                                
                                    </ChipContainer>
                                    
                                </HeaderContainer>
                                
                            </CardHeader>
                            <CardBody>
                                <Container>                            
                                    <Wrapper>
                                        <InfoWrapper>
                                            <Calendar size="18" color={calendarColor}/>
                                            <Text>{agendada ? cirugia.info.fecha && formatDate(cirugia.info.fecha) : "Pendiente"}</Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <Clock size="18" color={calendarColor}/>
                                            <Text>{agendada ? cirugia.info.fecha && formatTime(cirugia.info.fecha) : "Pendiente"}</Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faHospital} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{cirugia.centro?.nombre}</Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faIdCard} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{cirugia.pago?.nombre || "Privado"}</Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faUserMd} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{`${cirugia?.doctor?.firstname} ${cirugia?.doctor?.lastname}`}</Text>
                                        </InfoWrapper>
                                        
                                    </Wrapper>
                                    <CasaComercial>
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faWarehouse} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{`${cirugia?.company?.name}`}</Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faUserNurse} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{cirugia.especialista?.nombre ? `${cirugia.especialista?.nombre?.firstname || 'Pendiente'} ${cirugia.especialista?.nombre?.lastname || ''}` : 'Pendiente'}</Text>
                                        </InfoWrapper>                            
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faUser} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{`${cirugia?.ventas?.firstname} ${cirugia?.ventas?.lastname}`}</Text>
                                        </InfoWrapper>  
                                        <DocumentosChips documentos = {{...cirugia.documentos}}/>
                                        
                                    </CasaComercial>
                                </Container>

                            </CardBody>
                            <StyledCardActions>
                                <ButtonWrapper>
                                    {  realizada && !cirugia.evaluacion.positiva && !cirugia.evaluacion.negativa && canModify &&
                                       <Review
                                           handleOnClick={handleOnClick}
                                           cirugiaId={cirugia._id}
                                        />
                                    }   
                                    { (cirugia?.evaluacion?.negativa || cirugia?.evaluacion?.positiva) &&
                                        <IconButton onClick={()=>handleOnClick('comentarios', cirugia._id)}>
                                            <MessageSquare color={theme.palette.grey[600]}/>
                                        </IconButton>
                                    }

                                    <IconButton onClick={()=>handleOnClick("productos", cirugia._id)}>
                                        <FontAwesomeIcon icon={faDolly} style={{fontSize: "1.25rem"}} color= {theme.palette.grey[700]}/>
                                    </IconButton>

                                    { agendada && !realizada && !suspendida &&  !rechazada && canModify &&
                                        <Button outline error onClick={()=>handleOnClick('suspender', cirugia._id)}>Suspender</Button>
                                    }
                                    { (!agendada || suspendida || rechazada) && canModify &&
                                        <Button success onClick={()=>handleOnClick('agendar', cirugia._id)}>{!agendada ? "Agendar" : "Re-agendar"}</Button>
                                    }
                                    
                                </ButtonWrapper>
                                
                            </StyledCardActions>
                        </Card>
                    )
                }
                    
                    )
                }
        </>
    )


}


const Card = styled(CardWrapper)`
    margin:0;
    border-radius: 12px;
`

const StyledCardActions = styled(CardActions)`
    border-top: 1px solid ${p=>p.theme.palette.grey[300]};
    margin-top: 16px;
    min-height: revert;
    padding-top: 8px;
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 8px;
`
const PacienteContainer=styled.div`
    display:flex;
    flex-direction: column;
`

const ChipContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

const PacienteName = styled.p`
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[800]}    
`

const Subtitle = styled.p`
    margin:0;
    font-size: 0.7rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]}    
`
const Container = styled.div`
    display:flex;
    gap: 8px;
    
`


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex:1;
`

const CasaComercial = styled.div`
    display:flex;
    flex-direction:column;
    gap: 8px;
    flex:1
`

const InfoWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    

`

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]}
`


const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
`


export default CirugiasMdCard;

