
import { rolePermissions } from '../shared/roles';


const getRestrictedCirugias = ()=>{
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let restrictedVentasId = false; 
    let restrictedECId = false;

    if(!user || user.role?.md){         
        return [false,false]
    }

   
    const role = Object.keys(user.role)[0]
    const permissions = rolePermissions[role];
    
    const totalPermissions = [...permissions, ...user.permissions]
    const canSeeAllCirugias = totalPermissions.includes("can_see_all_cirugias")   

    console.log(totalPermissions)
    
    if(role === "isVentas" && !canSeeAllCirugias){
        console.log("role is ventas")
        restrictedVentasId = user._id
        console.log("in get restricted cirugias")
        console.log(restrictedVentasId)
        return [restrictedVentasId, restrictedECId]
    }

    if(role === "isEC" && !canSeeAllCirugias){
        restrictedECId = user._id
        return [restrictedVentasId, restrictedECId]
    }

    console.log('Returing restrictions'+restrictedECId+restrictedECId)
    
    return [restrictedVentasId, restrictedECId]

}



export default getRestrictedCirugias;