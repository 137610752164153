import styled from '@emotion/styled/macro';
import { useTheme } from "@mui/system";
import ChevronDown from "../../Icons/ChevronDown";
// import {StyledMenu, StyledSpan, StyledMenuButton, StyledMenuItem, StyledMenuList}from '../../Atoms/StyledDropdownReach'
import FileText from '../../Icons/File-text';
import MessageSquare from '../../Icons/Message-square';
import BookOpen from '../../Icons/BookOpen';
import Archive from '../../Icons/Archive';
import MapPin from '../../Icons/MapPin';
import usePermissions from '../../../hooks/usePermissions';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { Menu, MenuItem } from '@mui/material';
import Button from '../../Atoms/Button';
import React from 'react';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';






//   const VerMenuButton = ({
//     setOpenModal,
//     setFormType,
//     cirugiaId,
//     setCirugiaId,
//     currentUserIsEC,
//     currenUserIsVentas
//   })=>{
//     let theme = useTheme();
//     const permissions = usePermissions();
//     const user = useCurrentUser();

//     const {
//         canEditDocumentacion,
//         canSeeAllReportes
//     } = permissions
//     let buttonColor= theme.palette.grey[600];
//     let buttonColorHover= theme.palette.grey[400];
//     let textColorHover = "white";
    

//     const onSelect = (formType)=>{
//       setOpenModal(true);
//       setFormType(formType);
//       setCirugiaId(cirugiaId);
//     }

   
//     return (
//         <StyledMenu>
//             {({isExpanded})=>{              
//                 isExpanded ? document.querySelector('body').style.overflow='hidden' :
//                             document.querySelector('body').style.overflow='visible'
//                 return(
//                     <>
//                     <StyledMenuButton
//                         style={{
//                             "--button-color": buttonColor,
//                             "--button-color-hover": buttonColorHover,
//                             "--text-color-hover": textColorHover
//                         }}             
//                     >
//                     <StyledSpan>Ver</StyledSpan>
//                     <ChevronDown size={20} color={buttonColor}/>
//                     </StyledMenuButton>
//                     <NewStyledMenuList >
//                         { canEditDocumentacion &&
//                             <StyledMenuItem onSelect={()=>onSelect("documentos")}>
//                                 <WrapperMenuItem>
//                                     <FileText size="18px"/>
//                                     Documentos
//                                 </WrapperMenuItem>                            
//                             </StyledMenuItem>
//                         }
//                         <StyledMenuItem onSelect={()=>onSelect("comentarios")}>
//                             <WrapperMenuItem>
//                                 <MessageSquare size="18px" />
//                                 Comentarios
//                             </WrapperMenuItem>                            
//                         </StyledMenuItem>
//                     { (currentUserIsEC || currenUserIsVentas || canSeeAllReportes) &&
//                         <StyledMenuItem onSelect={()=>onSelect("reporte")}>
//                             <WrapperMenuItem>
//                                 <BookOpen size="18px" />
//                                 Reporte
//                             </WrapperMenuItem>                            
//                         </StyledMenuItem>
//                     }
//                         <StyledMenuItem onSelect={()=>onSelect("archivos")}>
//                             <WrapperMenuItem>
//                                 <Archive size="18px" />
//                                 Archivos
//                             </WrapperMenuItem>                            
//                         </StyledMenuItem>

//                         <StyledMenuItem onSelect={()=>onSelect("checkInResult")}>
//                             <WrapperMenuItem>
//                                 <MapPin size="18px" />
//                                 Check-In
//                             </WrapperMenuItem>                            
//                         </StyledMenuItem>
                        
//                     </NewStyledMenuList>
//                 </>
//                 )
//             }}       
//         </StyledMenu>
//     )
//   }



  const VerMenuButton = ({
    setOpenModal,
    setFormType,
    cirugiaId,
    setCirugiaId,
    currentUserIsEC,
    currenUserIsVentas
  })=>{
    let theme = useTheme();
    const permissions = usePermissions();    
    const subscription = useSubscriptionToken();

    const planAllowsCheckIn = subscription.companyPermissions.includes('checkIn');

    const {
        canEditDocumentacion,
        canSeeAllReportes
    } = permissions
    let buttonColor= theme.palette.grey[600];
    



    const onSelect = (formType)=>{
      setOpenModal(true);
      setFormType(formType);
      setCirugiaId(cirugiaId);
      setAnchorEl(null)
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);

   const onClickHandler = (event)=>{
       setAnchorEl(event.currentTarget)
   }

   const onCloseHandler = ()=>{
       setAnchorEl(null)
   }

    return (
        <div>
                    
                    <Button
                        outline    
                        onClick={onClickHandler} 
                        postIcon={<ChevronDown size={20} color={buttonColor}/>}       
                    >
                    <StyledSpan>Ver</StyledSpan>
                    
                    </Button>
                    <Menu 
                        open={open}
                        anchorEl={anchorEl}
                        onClose={onCloseHandler}
                        
                    >
                        { canEditDocumentacion &&
                            <MenuItem onClick={()=>onSelect("documentos")}>
                                <WrapperMenuItem>
                                    <FileText size="18px"/>
                                    Documentos
                                </WrapperMenuItem>                            
                            </MenuItem>
                        }
                        <MenuItem onClick={()=>onSelect("comentarios")}>
                            <WrapperMenuItem>
                                <MessageSquare size="18px" />
                                Comentarios
                            </WrapperMenuItem>                            
                        </MenuItem>
                    { (currentUserIsEC || currenUserIsVentas || canSeeAllReportes) &&
                        <MenuItem onClick={()=>onSelect("reporte")}>
                            <WrapperMenuItem>
                                <BookOpen size="18px" />
                                Reporte
                            </WrapperMenuItem>                            
                        </MenuItem>
                    }
                        <MenuItem onClick={()=>onSelect("archivos")}>
                            <WrapperMenuItem>
                                <Archive size="18px" />
                                Archivos
                            </WrapperMenuItem>                            
                        </MenuItem>
                    {planAllowsCheckIn &&
                        <MenuItem onClick={()=>onSelect("checkInResult")}>
                            <WrapperMenuItem>
                                <MapPin size="18px" />
                                Check-In
                            </WrapperMenuItem>                            
                        </MenuItem>
                    }
                        
                    </Menu>
                
                
                
        </div>
    )
  }


//   const NewStyledMenuList = styled(StyledMenuList)`
    
//   `

  const WrapperMenuItem = styled.div`
        display:flex;   
        gap: 8px; 
        align-items: center;
  `





  export const StyledSpan = styled.span`
  font-weight: 600;
  color: var(--button-color);
  font-size: .875rem;
  line-height: 1.75;
  `


  

  export default VerMenuButton;