import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { loginSuccess, setConfiguration, setSubToken } from '../../app/authReducer';
import { errorMessage } from '../../app/messagesReducer';
import { activateAccount } from '../../app/thunkActions/authActions';
import { authenticate, setCookie } from '../../shared/authHelpers';
import { apiUrl } from '../../shared/url';
import Button from '../Atoms/Button';



const ActivateCompanyAccount = ()=>{

    const dispatch = useDispatch()
    const params = useParams()
    const {isAuthenticated} = useSelector(state=>state.auth);
    
    const {token} = params;

    const activate = async ()=>{

        
        const options = createOptions('POST',{token} )

        const response = await goFetch(`${apiUrl}users/activate-company-account`, options, dispatch)
        
        if(response.token){
            authenticate(response);
            dispatch(loginSuccess({token: response.token, user:response.user}));
            dispatch(setSubToken(response.trialToken));
            setCookie('subToken', response.trialToken);
            dispatch(setConfiguration(response.configuration))
        }else if(response.error){
            dispatch(errorMessage(response.error))
        }
        
    }

    return (
        <>
        { isAuthenticated? <Navigate to={'/app'}/> 
            :
                <Wrapper>
                    <Text>Tu email fue verificado. Por favor activa tu cuenta. </Text>
                    <Button primary onClick={activate}>Activa tu Cuenta</Button>
                </Wrapper>
        }
        </>
    )
}


const Wrapper = styled.div`
    display:grid;
    place-content: center;
    height: 100%;
`

const Text = styled.h3`
    margin: 0;
    margin-bottom: 100px;
`

export default ActivateCompanyAccount;