import styled from '@emotion/styled/macro';
import { useTheme } from "@mui/system";
import {StyledMenu,StyledIconMenuButton, StyledMenuItem, StyledMenuList}from '../../../Atoms/StyledDropdownReach'
import MoreHorizontal from '../../../Icons/MoreHorizontal';





  const ActivarMenuButton = ({
    setOpenModal,
    setFormType,
    userActive,
    userId,
    setUserId
  })=>{
    let theme = useTheme();
    let buttonColor= theme.palette.grey[600];
    let buttonColorHover= theme.palette.grey[400];
    let textColorHover = "white"

    const onSelect = (formType)=>{
        setOpenModal(true);
        setFormType(formType);      
        setUserId(userId);
      
    }

    
   
    return (
        <StyledMenu>
            {({isExpanded})=>{              
                isExpanded ? document.querySelector('body').style.overflow='hidden' :
                            document.querySelector('body').style.overflow='visible'
                return(
                    <>
                    <StyledIconMenuButton
                        style={{
                            "--button-color": buttonColor,
                            "--button-color-hover": buttonColorHover,
                            "--text-color-hover": textColorHover
                        }}             
                    >                        
                        <MoreHorizontal size="18"></MoreHorizontal>                     
                    </StyledIconMenuButton>
                    <NewStyledMenuList>
                        {
                        !userActive &&                        
                            <StyledMenuItem onSelect={()=>onSelect('activar')}>
                                Activar
                            </StyledMenuItem>
                        }
                        { userActive &&
                            <StyledMenuItem onSelect={()=>onSelect('desactivar')}>
                                Desactivar
                            </StyledMenuItem>                        
                        }
                        
                    </NewStyledMenuList>
                </>
                )
            }}       
        </StyledMenu>
    )
  }


  const NewStyledMenuList = styled(StyledMenuList)`
    
  `

  


  

  export default ActivarMenuButton;