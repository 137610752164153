import ReactDOM from 'react-dom'
import styled from '@emotion/styled/macro';
import { border, useTheme } from '@mui/system';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { resetMessage } from '../../app/messagesReducer';
import useColor from '../../hooks/useColor';
import Button from './Button';
import XCircle from '../Icons/X-circle';
import Check from '../Icons/Check';
import IconButton from './IconButton';
import AlertOctagon from '../Icons/AlertOctagon';
import ThumbsUp from '../Icons/ThumbsUp';


const Alert = ({
    message="Informacion guardada correctamente",
    primary,
    secondary,
    success,
    warning,
    error,
    open,
    preIcon,
    close

})=>{

    
    const theme = useTheme();
    const dispatch = useDispatch();
    const [inProp, setInProp] =  React.useState(false)
    const duration=300;

    //created in order to by pass findDomNode error caused by deprecated support
    const node = useRef();

    const selectedColor = (primary && "primary") || (secondary && "secondary") || (success && "success") || (warning && "warning") || (error && "error");

    const  [borderColor, ,alertColor] = useColor(selectedColor)


    const defaultStyles={
        transition: `transform ${duration}ms ease-in`,
        transform:'translateX(-175%)'
    }

    const transitionStyles ={
        entering:{transform: 'translateX(-200%)'},
        entered:{transform: 'translateX(-0%)'},
        exiting:{transform: 'translateX(-0%)'},
        exited:{transform: 'translateX(-200%)'}
    }

    React.useEffect(()=>{
        if(open){
            setInProp(true)
        }
    },[open])


    React.useEffect(()=>{

        if(inProp){
            setTimeout(()=>{
                //checks if still showing in case it was closed by user
                if(inProp){
                    setInProp(false)
                }
            }, 3000)
        }

    },[inProp])

   return (
        <>
        
       { ReactDOM.createPortal(

            <Transition
                in={inProp} 
                timeout={duration}  
                nodeRef={node}
            >
            {
                state => {
                    
                    return(
                        <Wrapper ref={node} style={{"--alert-color": alertColor, "--border-color": borderColor, ...defaultStyles, ...transitionStyles[state]}}> 
                        {success && <IconWrapper><ThumbsUp color={borderColor} size="26"/></IconWrapper>}  
                        {error && <IconWrapper><AlertOctagon color={borderColor} size="28"/></IconWrapper>}                       
                           <Message>{message}</Message>  
                       <CloseWrapper>{close &&<IconButton onClick={()=>setInProp(false)}><XCircle color={borderColor} size="20"/></IconButton> }</CloseWrapper>                                      
                       </Wrapper> 
                    )                    
                }


            }
            </Transition>,
            document.getElementById("alert")
       )}
    </>
    )
      
     
    
   /* return(
        <Wrapper style={{"--alert-color": alertColor, "--border-color": borderColor}}> 
         {success && <IconWrapper><ThumbsUp color={borderColor} size="26"/></IconWrapper>}  
         {error && <IconWrapper><AlertOctagon color={borderColor} size="28"/></IconWrapper>}                       
            <Message>{message}</Message>  
        <CloseWrapper>{true &&<IconButton onClick={()=>setInProp(false)}><XCircle color={borderColor} size="20"/></IconButton> }</CloseWrapper>                                      
        </Wrapper> 
    )   
    */

}


const Wrapper = styled.div`    
    border: 1px solid var(--border-color);
    min-width:350px;
    max-width:350px;
    min-height: 50px;
    max-height: 75px;
    position: fixed;
    top: 5%;
    left: 0;
    right: 0;
    margin-left: auto; 
    margin-right: auto;
    background-color: var(--alert-color);
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0.2px 0.3px 0.4px hsl(0deg 0% 63% / 41%), 
        0.8px 1px 1.4px -0.8px hsl(0deg 0% 63% / 41%), 
        2px 2.5px 3.6px -1.7px hsl(0deg 0% 63% / 41%), 
        4.9px 6.2px 8.9px -2.5px hsl(0deg 0% 63% / 41%);
    z-index:100;
    
    

    @media ${p=>p.theme.media.tablet}{        
        max-width:500px;
        max-height: 50px;
        margin-left: revert; 
        margin-right: revert;
        top: 90%;
        left: 15%;
        right: revert;
    }


`

const Message = styled.p`
    margin:0;
    font-size: 0.875rem;
    line-heigh: 0;
    color: var(--border-color);
    flex:6;
    font-weight: 500;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    flex:1;

`

const CloseWrapper = styled.div`
    display: flex;
    align-items: center;
    flex:1;
    justify-content: flex-end
`


export default Alert