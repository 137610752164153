import styled from '@emotion/styled';
import React from 'react';
import TextInput from '../Atoms/TextInput';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import Button from '../Atoms/Button';
import useForm from '../../hooks/useForm';
import { isLength } from '../../shared/validators';
import ProductosAutocompleteAndList from '../molecules/ProductosAutocompleteAndList';
import useSuccessButton from '../../hooks/useSuccessButton';

import { apiUrl } from '../../shared/url';
import { createOptions, goFetch } from '../../api/cirugiasApi';

const EditarRegla = ({
    regla,
    reglas,
    setReglas,
    close}) => {

const [values, setValues] = React.useState({    
    nombreGrupo: regla.nombreGrupo || "",
    numeroCirugiasDia: regla.numeroCirugiasDia || "",
})
const initialStateProductos = regla.productos || []
const [productos, setProductos] = React.useState(initialStateProductos)

const [errors, setErrors] = React.useState({})
const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

const {onChangeHandler, onChangeValuesValidation, onBlurValidate} = useForm({setValues, setErrors, errors})

const onSubmit = async(e)=>{
    e.preventDefault();

    const reglaInfo = {        
        nombreGrupo: values.nombreGrupo,
        numeroCirugiasDia: values.numeroCirugiasDia,
        productos: productos
    }

        setIsLoading(true)
        const options = createOptions('POST', reglaInfo)
        let response;
          
        try{            
            response = await goFetch(`${apiUrl}agenda/update-regla/${regla._id}`, options );

            if(response.error) throw new Error(response.error) 

            //find regla in reglas and update it and set the new state
            
            const updatedReglas = reglas.map(regla=>{
                console.log("regla",regla)
                if(regla._id === response.regla._id){
                    return response.regla
                }else{
                    return regla
                }
            })

            setReglas(updatedReglas)

            responseStateChanges(response,null,()=>close())    
        }catch(error){
            console.log(error);
        }
    
}

  return (
    
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Editar Regla</FormTitle>
            <FormBody>
            <TextInput
                    label="Nombre del Grupo" name="nombreGrupo" id="nombreGrupo"
                    value={values.nombreGrupo}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isLength])}
                    helperText={!errors.nombreGrupo ? "Campo Obligatorio": errors.nombreGrupo}
                    error={errors.nombreGrupo}
                    onBlurValidation={(event)=>onBlurValidate(event, [isLength])}
                />
                <TextInput 
                    label="Numero de cirugias por dia" 
                    name="numeroCirugiasDia"
                    id="numeroCirugiasDia"
                    value={values.numeroCirugiasDia} 
                    onChangeHandler={(event)=>onChangeHandler(event)}
                    type="number"
                    helperText={!errors.numeroCirugiasDia ? "Campo Obligatorio": errors.numeroCirugiasDia}
                />
                <ProductosAutocompleteAndList productos={productos} setProductos={setProductos}/>   
            </FormBody>  

            <FormActions>
                <Button
                as={"button"}
                fullsize={true}
                success
                type="submit"
                disabled={false}
                loading={isLoading}
                successEvent={successEvent}                      
                >Editar Regla</Button>
           
            </FormActions> 
                  
    </FormWrapper>          
    
  );
};



const ExternalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    
    gap: 16px;
    padding: 16px;
    max-width: 1200px;
    @media ${p=>p.theme.media.tablet}{
        flex-direction: row;
    }
`


export default EditarRegla;
