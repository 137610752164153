export const commonClients = [
    {
        id: 1,
        name: 'Senasa',
        rnc: "401516454",
        address: 'Avenida 27 de Febrero, Esq. Tiradentes, Ensanche Piantini, Santo Domingo',        
        phone: '',
        email: '',        
        priceList: 'Senasa'
    },
    {
        id: 2,
        name: 'Humano',    
        rnc: '234224224',    
        address: 'Calle 2',
        phone: '123456789',
        email: '',        
        priceList: 'Humano'
    },
    {
        id: 3,
        name: 'Universal',
        rnc: '232322422',
        address: 'Calle 3',
        phone: '123456789',
        email: '',        
        priceList: 'Universal'
    },
   
]


 
export const especialidades = [
    {
        id: 1,
        name: 'Neurocirugia',
    },
    {   
        id: 2,
        name: 'Cardiologia',
    },
    {
        id: 3,
        name: 'Maxilo',
    },
    {
        id: 4,
        name: 'Cirugia General',
    },
    {
        id: 5,
        name: 'Tiroide',
    },
    {
        id: 6,
        name: 'Ginecologia',
    },
    {
        id: 7,
        name: 'Artroscopia',
    },
    {
        id: 8,
        name: 'Ortopedia',
    },
    {
        id: 9,
        name: 'Urologia',
    },
    {
        id: 10,
        name: 'ORL',
    },
    {
        id: 11,
        name: 'Columna',
    },
    {
        id: 12,
        name: 'Grapado Abierto',
    },
    {
        id: 13,
        name: 'Grapado TriStaple',
    },
    {
        id: 14,
        name: 'General',
    },

]


export const productosNeuro =[
    {
        _id: 1,
        codigo: "SERV-PRIMADO",
        descripcionShort: 'Primado',
        descripcion: 'Primado 2',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,        
        }
    },
    {
        _id: 2,
        codigo: "200-271",
        descripcionShort: 'Acracut',
        descripcion: 'Acracut DGR-O fresa autobloqueante 14mm/11mm, perfil 3mm',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        subjectToItbis: true
    },
    {
        _id: 3,
        codigo: "SERV-CCRA-4",
        descripcionShort: 'Cierre 4',
        descripcion: 'Servicio de Cierre de Craneo Icluye: 4 placas 2orf + 8 tornillos 1.5mm',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
    },
    {
        _id: 4,
        codigo: "DP5022",
        descripcionShort:'Duragen 2x2',
        descripcion: 'Duragen 2in x 2in (5cm x 5cm)',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
    },
    {
        _id: 5,
        codigo: "202050",
        descripcionShort:'Duraseal 5ml',
        descripcion: 'Duraseal 5ml - Sistema sellante dural',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
    },
    {
        _id: 6,
        codigo: "GF-7365",
        descripcionShort:'Tuft-it',
        descripcion: 'Gelita Tuft-it 75 x 50mm',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
    },
]


export const productosTiroide =[
    {
        id: 1,
        descripcion: 'Nerveana',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,        
        },
        selected:false
    },
    {
        id: 2,
        descripcion: 'Small Jaw',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
    {
        id: 3,
        descripcion: 'Gelita Tuft-it',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
    {
        id: 4,
        descripcion: 'Reservorio',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
    
                        
]


export const productosGinecologia =[
    {   
        id: 1,
        descripcion: 'Nerveana',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
]

export const productosMaxilo =[
    {   
        id: 1,
        descripcion: 'Nerveana',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
]

export const productosCardio =[
    {   
        id: 1,
        descripcion: 'Nerveana',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
]

export const productosCirugiaGeneral =[
    {   
        id: 1,
        descripcion: 'Nerveana',
        price:{
            base: 100,
            senasa: 90,
            humano: 90,
        },
        selected:false
    },
]


               
    