import { useContext } from "react"
import SubscriptionContext from "../context/subscription-context"




const useSubscriptionToken = ()=>{

    const sub = useContext(SubscriptionContext)

    //console.log(sub)
    return {
        isSubscribed: sub.isSubscribed,
        numberOfUsers: sub.numberOfUsers,
        plan: sub.plan,
        product: sub.product,
        companyPermissions: sub.companyPermissions,
        checkIfSubscribed: sub.checkIfSubscribed,
        //setSubToken: sub.setSubToken
    }
}



export default useSubscriptionToken;