import styled from '@emotion/styled';
import { FormControlRadio, Radio } from '../../Atoms/Radio';



const TipoRadio = ({
    tipo,
    onChangeRadio
}) => {
    console.log('tipo', tipo)

    return (
        <RadioButtonWrapper>                
        <FormControlRadio
            value={tipo}
            onChange={onChangeRadio}
        >
            <Radio
                label="Ars"
                id="Ars"
                name="Ars"
                value="Ars"
                checked={tipo === "Ars"}
            />
            <Radio
                label="Centro"
                id="Centro"
                name="Centro"
                value="Centro"   
                checked={tipo === "Centro"}                     
            />
            <Radio
                label="Otro"
                id="Otro"
                name="Otro"
                value="Otro"   
                checked={tipo === "Otro"}                     
            />   
     </FormControlRadio>        
     
    </RadioButtonWrapper>

    )

}


const RadioButtonWrapper = styled.div`
  
`


export default TipoRadio