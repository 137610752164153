import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import Avatar from '../../Atoms/Avatar';
import { CardBody,CardTitle, CardWrapper as Card } from "../../Atoms/Card";


const DoctorCard = ({
    doctor,
    index
})=>{

    const theme = useTheme();

    const isConnected = doctor?.connected?.status;
    const isInvited = doctor?.connected?.invited && !doctor?.connected?.status;
    const requestedInvite = doctor?.connected?.invitationRequest?.status && !doctor?.connected?.invited;
    const subscription = useSubscriptionToken()
    const planAllowsDoctorInvitation = subscription.companyPermissions.includes('invite_doctors')

    return(
                               
        <Card style={{margin:0, paddingBottom: "8px"}} key={`${doctor} ${index}`}>
            <StyledCardHeader>
                <Avatar src={doctor?.profilePicture} alt="user avatar">{doctor?.lastname ? doctor?.lastname[0]:null}</Avatar>
                <UserDescription >
                    <StyledCardTitle>{`${doctor?.firstname} ${doctor?.lastname}`}</StyledCardTitle>
                    <CardSubTitle>{`${doctor?.especialidad || ""}`}</CardSubTitle>
                    <CardSubTitle>{`${doctor?.centros[0]?.nombre || ""}`}</CardSubTitle>
                </UserDescription>                                
            </StyledCardHeader>
            <CardBody style={{flexDirection: "row"}}>
                <Container>
                    {planAllowsDoctorInvitation &&

                        <InfoContainer>
                            {isConnected && 
                                <>
                                    <Dot style={{"--dot-color": theme.palette.success.light}}/>
                                    <Text>Conectado</Text>                                                                                             
                                </>
                            }
                            {isInvited && 
                                <>
                                    <Dot style={{"--dot-color": theme.palette.warning.light}}/>
                                    <Text>Invitado</Text>                                                                                             
                                </>
                            }
                            {requestedInvite && 
                                <>
                                    <Dot style={{"--dot-color": theme.palette.warning.light}}/>
                                    <Text>Invitacion solicitada</Text>                                                                                             
                                </>
                            }
                            {/* {
                                notInDatabase &&
                                <>
                                    <Dot style={{"--dot-color": theme.palette.error.light}}/>
                                    <Text>No creado en tu base de datos</Text>                                                                                             
                                </> }*/
                            }
                        </InfoContainer>  
                    }                                                                              
                </Container>
                
            </CardBody>
        </Card>
    
    )

}



const StyledCardTitle = styled(CardTitle)`
    margin:0;
    color: ${p=>p.theme.palette.grey[800]}; 
`

const StyledCardHeader = styled.div`
    display:flex;
    gap: 12px;
    
    
`

const UserDescription = styled.div`
    flex-direction: column;
    justify-content: center;
    min-height: 55px;
    margin-bottom: 16px;
`

const CardSubTitle = styled.h5`
    margin: 0px;
    color: ${p=>p.theme.palette.grey[600]};    
`

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[600]};    
    font-weight: 500;
`


const InfoContainer = styled.div`
    display: flex;
    
    justify-content:center;
    gap: 8px;
    align-items:center;
`

const Container = styled.div`
    display:flex;
    justify-content: space-between;
`


const CardLink = styled(Link)`
    text-decoration: none;
`

const Dot = styled.div`
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--dot-color);
    
    box-shadow: 0.2px 0.3px 0.4px hsl(0deg 0% 63% / 0.41),
    0.8px 1px 1.4px -0.8px hsl(0deg 0% 63%/ 0.41),
    2px 2.5px 3.6px -1.7px hsl(0deg 0% 63% / 0.41),
    4.9px 6.2px 8.9px -2.5px hsl(0deg 0% 63% / 0.41);
    
`

export default DoctorCard;