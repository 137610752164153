import React from "react";
import styled from "@emotion/styled/macro";
import TextInput from "./TextInput";
import { useTheme } from "@mui/system";
import ChevronDown from "../Icons/ChevronDown";
import ChevronUp from "../Icons/ChevronUp";
import SmallButton from "./SmallButton";
import X from "../Icons/X";
import useMediaQuery from "../../hooks/useMediaQuery";
import { usePopper } from "react-popper";
import { attributes } from "js-cookie";

const SuggestionsBox = ({
    filteredOptions,
    onClickHandler,
    activeIndex,
    getOptionLabel,
    inputRef,
    setShowSuggestions,
    valueSelected,
    setInput,
    setExternalActive,
  
  
    
})=>{
    
    const [suggestionsRef, setSuggestionsRef] = React.useState();
    const refCallback = React.useCallback((node)=>{
        if(node){
           //console.log(node);
            setSuggestionsRef(node);
        }

    },[])

    React.useEffect(()=>{

        const onClickAwayHandler = (event)=>{
           // console.log('Running Click away handler')
            if(!suggestionsRef?.contains(event.target) && !inputRef.contains(event.target)){
                //console.log('Clicked away')
                setShowSuggestions(false);                 
                 //console.log(valueSelected)
                if(!valueSelected){
                    setInput('');
                    setExternalActive(false);
                }            
            }
        }
            window.addEventListener('click',onClickAwayHandler, true)
            //console.log('Running add event listener')
        
            return ()=>{
                window.removeEventListener('click',onClickAwayHandler,true)
            }
    },[suggestionsRef, inputRef, setExternalActive, setInput, setShowSuggestions,valueSelected ])

    //console.log(filteredOptions)

    return(
        <SuggestionsBoxWrapper  ref={refCallback} >
            <SuggestionsList role="listbox" tabIndex="-1">
                {(filteredOptions?.length<1 || filteredOptions === undefined) ? <StyledSpan>No options</StyledSpan> :
                filteredOptions?.map((option, index)=>{
                        
                        return(
                            <SuggestedItem 
                            key={`${option}${index}`}
                            onClick={(event)=>onClickHandler(event,option)}
                            selected = {activeIndex === index}
                            tabIndex='-1'
                            role="option"
                        >
                            {getOptionLabel(option)}
                        </SuggestedItem>
                        )                        
                    })
                }
            </SuggestionsList>  
        </SuggestionsBoxWrapper>
    )
}




const Autocomplete = ({
        options, 
        getOptionLabel,
        label,
        name,
        id,
        onChange,
        onInputChange,
        clear,
        setClear,
        value
    })=>{
    const theme = useTheme();
    //const inputRef = React.useRef();
    const [inputRef, setInputRef] = React.useState();
    const [input, setInput] = React.useState(value);
    //console.log(value)
    //console.log(input)
    const [valueSelected, setValueSelected] = React.useState(false);
    const [filteredOptions, setFilteredOptions] = React.useState([]);
    const [showSuggestions, setShowSuggestions] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(-1);
    const [externalActive, setExternalActive] = React.useState(true);
    const [showCloseButton, setShowCloseButton] = React.useState()
    const closeButtonRef = React.useRef();


    //runs filterOptions when new options come in after asyn call. 
    React.useEffect(()=>{
        //console.log(options)
        let filteredOptions = options?.filter((option)=>{         
            
            //decided to return true as not sure why we are filtering. Filter was causing problemas with displaying all returned data from server - sept 12, 2022
            //return getOptionLabel(option).toString().toLowerCase().indexOf(input?.toString().toLowerCase()) > -1
            return true
       })
       setFilteredOptions(filteredOptions)

    },[options, getOptionLabel, input])


    //pases value from props to value in textfield. Used for when we want to persist selection in store for when compentn exits and reapears.
    React.useEffect(()=>{
        if(value) setInput(value)
    },[value])


    const onChangeHandler = (event)=>{
        event.preventDefault();
        setValueSelected(false);

        //filter list of options
        const userInput = event.target.value;
        const userInputStringLowercase = userInput.toString().toLowerCase();
        let filteredOptions = options?.filter((option)=>{  
            //decided to return true as not sure why we are filtering. Filter was causing problemas with displaying all returned data from server - sept 12, 2022           
             //return getOptionLabel(option).toString().toLowerCase().indexOf(userInputStringLowercase) > -1
             return true
        })
        
        //when typing show suggestions
        setShowSuggestions(true);
        //set filtered options as user types
        setFilteredOptions(filteredOptions)
        //set input to show on input field
        setInput(userInput);
        //passes event to controlling component
        onInputChange && onInputChange(event)

        //when no characters close suggestions, reset Active index
        if(userInput===""){
            setShowSuggestions(false);
            setActiveIndex(-1);
        }

        
    }

    const onClickHandler = (event,option)=>{
        //when clicking option after using down chevron, make sure to focus on input field
        if(window.activeElement !== inputRef.current){
            inputRef.current.focus()
        }

        // console.log(event.target.value);
        // console.log(getOptionLabel(option));
        setValueSelected(true);
        setInput(getOptionLabel(option));
        setShowSuggestions(false);
        setActiveIndex(-1);
        //console.log(option);
        
        onChange && onChange(name,option);

    }

    
    const onBlur = (setActive)=>{
        //console.log("executing blur")
        
        //if showCloseButton is open, close once we blur. Required on touch screen since we dont register onMouseLeave event
        //if(showCloseButton) setShowCloseButton(false)
        
        if(!valueSelected){
            //onChange && onChange(name,""); // causing a render when mouse down which is before option is selected - april 22 2022
        }
        // check filtered options so it wont collapse when selecting options
        if((!valueSelected && filteredOptions?.length===0)){
            //console.log("run onchange")
            setInput('');
            setShowSuggestions(false);
            setActiveIndex(-1);
            setActive(false);
            onChange && onChange(name,"");
        }
    }

    const onKeyDown = (event)=>{
        
        //enter
        if(event.keyCode === 13){ 
            event.preventDefault();        
            if(activeIndex<0){
                setShowSuggestions(false);    
                return
            };
            if(filteredOptions[activeIndex]){
                setInput(getOptionLabel(filteredOptions[activeIndex]));    
                setValueSelected(true);
                onChange && onChange(name,filteredOptions[activeIndex]);
            }else{
                setInput("")
                setValueSelected(false);
            }
            setInput(getOptionLabel(filteredOptions[activeIndex]));
            onChange && onChange(name,filteredOptions[activeIndex]);
            setActiveIndex(-1);
            setShowSuggestions(false);
        }

        //down key
        if(event.keyCode === 40){
            if(!showSuggestions)return
              
            //if reached end of list set back to start in order to loop
            if(activeIndex===(filteredOptions.length-1)){
                setActiveIndex(0);
                 
                return
            }
            
            setActiveIndex(activeIndex+1)
            setActiveIndex(prev=>{console.log(prev); return prev})
        }

        //up key
        if(event.keyCode === 38){
            if(!showSuggestions)return
            if(activeIndex === 0){
                setActiveIndex(filteredOptions.length-1);
                return;
            }
            setActiveIndex(activeIndex-1)
            document.activeElement.nextSibling.focus();
        }
    }


    const onMouseEnter=()=>{
        setShowCloseButton(true)
    }

    const onMouseLeave=()=>{
        setShowCloseButton(false)
    }

    const onClickCloseButton = (event)=>{
            //trying to set Safari focus to button after input blur
            //console.log(event.target.matches('button'))
            event.target.focus();
            //console.log(document.activeElement)

            //gives focus back to input in order to trigger blur later
            inputRef.focus()
            setInput('');
            setValueSelected(false);
            setActiveIndex(-1);
            onChange && onChange(name,"");
            
            
    }

    const onClickChevron=(e)=>{
        //console.log('clicked chevron')
        //once you open list cursor goes to input, ready to receive information from user
        inputRef.focus()
        setShowSuggestions(!showSuggestions)
    }

    
   const onBlurHandler = (event)=>{
    
    //checks if event which had focus contains the event that will get focus after blur. 
    //We do this to check if we are clicking on the close button in order to be able to trigger it
    // console.log(event.currentTarget.contains(event.target))
    
    // console.log(event.currentTarget);
    // console.log(event.relatedTarget);

    // console.log(navigator.userAgent.indexOf("Chrome"));


    //Safari does not send focus to button, so this check to close the Close Button does not work. I disables the button when blurring.
   if(navigator.userAgent.indexOf("Chrome")!==-1){
        if(event.currentTarget.contains(event.relatedTarget)) return // modified from event.relatedTarget since safari does not fire focus for button event.
        setShowCloseButton(false);
    }
   } 
   
   //Used to manage internal state from outside. Clear will reset input
   React.useEffect(()=>{
    if(clear){
        setInput('');
        setValueSelected(false);
        setActiveIndex(-1);
        setClear(false);
        inputRef.focus()
    }
   },[clear, inputRef, setClear])


    return (
        <Wrapper 
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={()=>setShowCloseButton(true)}
            onBlur={onBlurHandler}
        >
            <TextInput 
                label={label} 
                name={name} 
                id={id} 
                autocomplete="off"
                setValue={setInput} 
                value={input} 
                onChangeHandler={onChangeHandler}
                onKeyDown={(event)=>onKeyDown(event,filteredOptions)}
                onBlur={onBlur}
                ref={setInputRef}
                externalActive={externalActive}
                setExternalActive={setExternalActive}
                endIcon={showSuggestions ? 
                    <>
                        <SmallButton onClick={onClickCloseButton} ref={closeButtonRef}>
                            <X size="18" color={theme.palette.grey[500]} style={{pointerEvents:"none"}} />
                        </SmallButton>
                        <SmallButton onClick={onClickChevron}>
                            <ChevronUp size="20" color={theme.palette.grey[500]} />                     
                        </SmallButton>
                        
                    </>
                    
                    : 
                    showCloseButton && valueSelected ?
                    <>
                        <SmallButton onClick={onClickCloseButton} ref={closeButtonRef}>
                            <X size="18" color={theme.palette.grey[500]}  style={{pointerEvents:"none"}}/>  
                        </SmallButton>
                        <SmallButton onClick={onClickChevron}>
                            <ChevronDown size="20" color={theme.palette.grey[500]}/>
                        </SmallButton>
                    </>
                        :
                        <SmallButton onClick={onClickChevron}>
                            <ChevronDown size="20" color={theme.palette.grey[500]} />
                        </SmallButton>
                }
            >

            </TextInput>            
                { showSuggestions &&
                    <SuggestionsBox
                        tabIndex="0"
                        filteredOptions={filteredOptions}
                        onClickHandler={onClickHandler}
                        activeIndex={activeIndex}
                        getOptionLabel={getOptionLabel}
                        inputRef={inputRef}
                        setShowSuggestions={setShowSuggestions}
                        valueSelected={valueSelected}
                        setInput={setInput}
                        setExternalActive={setExternalActive}
                    />
                }
        </Wrapper>


    )
}


const Wrapper = styled.div`
    position: relative;

`

const SuggestionsBoxWrapper = styled.div`
    position: absolute;
    background-color: white;
    z-index: 5;
    top: 56px;
    width:100%;
    max-height: 250px;
    overflow-y:auto;
    border: 1px solid ${p=>p.theme.palette.grey[300]};
    border-radius: 8px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 
    0px 6px 10px 0px rgb(0 0 0 / 14%), 
    0px 1px 18px 0px rgb(0 0 0 / 12%);
`

const SuggestionsList = styled.ul`
    padding: 4px 0px;
    margin: 8px 0px;
`
const StyledSpan = styled.span`
    padding:4px 16px;
    color : ${p=>p.theme.palette.grey[500]};
`

const SuggestedItem = styled.li`
    list-style: none;
    margin-left: 0;
    padding:4px 16px;
    background-color : ${p=>p.selected?p.theme.palette.grey[300] : 'none'};
    
    &:focus,
    &:hover{
        background-color : ${p=>p.theme.palette.grey[300]};
    }
`

const PopperWrapper = styled.div`
    width:100%
`


export default Autocomplete;