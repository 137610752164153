import React from "react";
import styled from '@emotion/styled';
import IconButton from "../Atoms/IconButton";
import ChevronLeft from '../Icons/ChevronLeft';

const Drawer = ({open, setOpen, children})=>{

    const onCloseHandler = ()=>{
        setOpen(false);
    }

    return (
        <Wrapper open={open}>
            <IconButtonContainer>
                <IconButton onClick={onCloseHandler}>
                    <ChevronLeft style={{color:"white"}}/>
                </IconButton>                     
            </IconButtonContainer>
            <NavigationWrapper>
                {children}
            </NavigationWrapper>                
        </Wrapper>
    )
}

const Wrapper = styled.aside`
    width: ${p=>p.open ? 'var(--drawer-width)' : '75px'};
    height: 100%;
    background-color: ${p=>p.theme.palette.grey[800]};
    display:flex;
    flex-direction: column;
    transition: width 300ms ease-in;
    position: fixed;
    left:0;
`
const IconButtonContainer = styled.div`
    height: var(--header-height);
    display: flex;
    flex-flow: row-reverse;
    padding: 8px;
    border-bottom: 1px solid black;
`

const NavigationWrapper = styled.nav`
    flex:1;
    background-color: ${p=>p.theme.palette.grey[800]};
    overflow:hidden;
    
`

export default Drawer;