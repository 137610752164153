import React from 'react';
import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms";
import Button from "../../Atoms/Button";
import Send from "../../Icons/Send";
import useCommentMaker from "../../../hooks/useCommentMaker";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import useUpdateCirugia from "../../../hooks/useUpdateCirugia";
import FormActionsBasic from "../../Atoms/FormActionsBasic";

const AddComment = ({
    cirugiaId,
    close,
}) => {
    const [comments, setComments] = React.useState('');
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();
    const { isLoading, successEvent, updateCirugia } = useUpdateCirugia(close);

    const handleChangeComments = (event) => {
        setComments(event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker('Se agregó un nuevo comentario');

        const info = {
            cirugiaId,
            comentarios: commentObj,
            accion
        };

        updateCirugia('add-comment', info);
    };

    return (
        <StyledFormWrapper onSubmit={onSubmit}>
            <StyledFormTitle>Agregar Comentario</StyledFormTitle>
            <StyledFormBody>
                <TextField
                    label="Comentario"
                    multiline
                    variant="outlined"
                    rows={4}
                    value={comments}
                    onChange={handleChangeComments}
                    fullWidth
                />
            </StyledFormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
            />
        </StyledFormWrapper>
    );
};

const StyledFormWrapper = styled(FormWrapper)`
    max-width: 500px;
`;

const StyledFormTitle = styled(FormTitle)`
    // Add any specific styles if needed
`;

const StyledFormBody = styled(FormBody)`
    margin-bottom: 16px;
`;

export default AddComment;