import styled from '@emotion/styled';




const CardWarning = ({
    children
})=>{

    return(
        <StyledCardError>
            {children}
        </StyledCardError>
    )
}



const StyledCardError = styled.div`
    padding: 18px;
    border-radius: 8px;
    border: 1px solid ${p=>p.theme.palette.warning.light};
    background-color: ${p=>p.theme.palette.warning.pastel};
    color: ${p=>p.theme.palette.warning.light};
    font-weight: 600;
`

export default CardWarning