import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';
import MapPin from '../../Icons/MapPin';


const CheckInChip = ({checkIn})=>{
    const theme = useTheme()
    let color;
    const fillMapPinColor = theme.palette.grey[300]
    
    if(!checkIn) return null

 
    if(checkIn.onTime && checkIn.checkInAtLocation) color = theme.palette.success.light;
    //checked in but centro has no location.
    if(checkIn.onTime && !checkIn.centroLocation) color = theme.palette.warning.light;
    if(!checkIn.onTime || (!checkIn.checkInAtLocation && checkIn.CentroLocation)) color = theme.palette.error.light;

    

    return(        
        <Chip icon={<MapPin size={18} color={color} /> } outline color={color} />
    )
}



export default CheckInChip;