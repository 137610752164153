import styled from '@emotion/styled/macro';
import useCirugia from '../../../hooks/useCirugia';
import { CardBody, CardTitle } from '../../Atoms/Card';
import ProductTable from './ProductTable';



//import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card"






const ProductTableModal = ({
    cirugiaId
})=>{

    
    const cirugia = useCirugia(cirugiaId);
    console.log(cirugia);
    
    return (
        <Wrapper>
        <CardTitle>Productos</CardTitle>
        <CardBody>                
            <ProductTable cirugia={cirugia} productos= {cirugia?.productos}/>
        </CardBody>

    </Wrapper>
        
    )
}


const Wrapper = styled.div`
    padding: 16px;
    
`






export default ProductTableModal