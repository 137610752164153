import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import IconButton from '../../Atoms/IconButton';
import UploadButton from '../../Atoms/UploadButton';
import ChevronLeft from '../../Icons/ChevronLeft'
import ChevronRight from '../../Icons/ChevronRight'
import Trash2 from '../../Icons/Trash2';
import imageCompression from 'browser-image-compression';
import { getCookie } from '../../../shared/authHelpers';
import { addUpdatedCirugia } from '../../../app/cirugiasReducer';
import axios from 'axios';
import useCirugia from '../../../hooks/useCirugia';
import { apiUrl } from '../../../shared/url';
import { useAccionMaker } from '../../../hooks/useAccionMaker';

import BeatLoader from 'react-spinners/BeatLoader'
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import usePermissions from '../../../hooks/usePermissions';

const Fotos = ({
    cirugiaId
})=>{

    const theme = useTheme();
    const dispatch = useDispatch();
    const cirugia = useCirugia(cirugiaId);
    const user = useCurrentUser();
    const permissions = usePermissions();
    const {isMaster} = permissions;

    
    //fotos list has previously loaded images
    let [fotoList, setFotoList] = React.useState(cirugia.reporte?.fotos);
    let [index, setIndex] = React.useState(0);
    let [progress, setProgress] = React.useState(0);
    let [loading, setLoading] = React.useState(false);
    let [loadingDelete, setLoadingDelete] = React.useState(false);

    const currentUserIsEC = user._id ===cirugia?.especialista?.nombre?._id;
    
    console.log(index);

    React.useEffect(()=>{
         setFotoList(cirugia.reporte?.fotos)

    },[cirugia])


    const uploadImageAxios = (formData) =>{
        setLoading(true)

        const token = getCookie('token');
  
        const config = {
        headers: {
            Authorization: `Bearer ${token}`
            },   
          onUploadProgress: function(progressEvent){            
            var percentCompleted =  parseInt(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            setProgress(percentCompleted);                        
          }
        }
  
        axios.post(apiUrl + 'cirugias/fotos-reporte/' + cirugiaId, formData, config)
        .then(response => {          
          dispatch(successMessage(response.data.message));
          dispatch(addUpdatedCirugia(response.data.cirugia))
          return response
        })
        .then(()=>{
          setIndex(fotoList.length)
          setLoading(false)
        })
        .catch((error)=> {dispatch(errorMessage(error.errors)); console.error('Error:', error)})
  
    }


    const onChangeUpload = (e)=>{
        let fotosArray
        
        if(e.dataTransfer?.files){
          fotosArray = Array.from(e.dataTransfer.files)
        }else if(e.target.files){
          fotosArray = Array.from(e.target.files)
        }        
  
        if((fotosArray.length + fotoList.length) >10){
          dispatch(errorMessage("Solo se puede guardar un maximo de 10 fotos."))
          return
        }
  
        let notImage=[]
  
        fotosArray.forEach((foto)=>{            
          if(foto.type !== "image/png" && foto.type !== "image/jpeg" ){
            notImage.push("not image");
          }
        })
  
        if(notImage.length>0){
          dispatch(errorMessage("Imagen debe ser tipo PNG o JPEG"))
          return
        }
   
        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 750
        }
    
        let promises = []

        fotosArray.forEach((foto, index)=>{        
            promises.push(new Promise((resolve, reject)=>{
                imageCompression(foto, options)
                .then((compressedFile)=>{resolve(compressedFile)})
                .catch(err=>console.log(err))
            }))
        })
  
      // Promise all waits for the loop doing the compression to finish then continues to .then()
      Promise.all(promises)
      .then((fotos)=>{        
        const formData = new FormData();
  
        fotos.forEach((foto, index)=>{          
          formData.append("fotos", foto);          
          })
  
          uploadImageAxios(formData)
          return formData
      })
      .then((formData)=>{
  
     console.log(formData.getAll('fotos'))
  
      })
  
    }

    const accionMaker = useAccionMaker(`Borrada foto del Reporte`); //provides user and accion object.

    const deleteFotos= async (fotoUrl, index)=>{        
        const token = getCookie('token');
        setLoadingDelete(true);
        const accion = accionMaker(`Borrada foto del Reporte`);

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
                }, 
        }

        try{
            const response = await axios.post(apiUrl + 'cirugias/delete-fotos-reporte/' + cirugiaId, {fotoUrl, accion}, config)
            dispatch(successMessage("Foto borrada exitosamente!"));
            dispatch(addUpdatedCirugia(response.data.cirugia));
            
            index === 0 ? setIndex(0) : setIndex(index-1); 
            setLoadingDelete(false);
        }catch(error){
            dispatch(errorMessage("No se pudo borrar la foto. Por favor intente mas tarde."));
            console.error('Error:', error);
        }                  
    }


    return (
        <Wrapper>
            <Gallery>
                {
                    fotoList.length > 0 ?
                    <Image alt="post your pictures" src={fotoList[index]} />
                    :
                    <Image alt="post your pictures" src="/img/posting_photo.svg" />    

                }
                
            </Gallery>
            <Actions>
                <GalleryControls>
                    <IconButton
                        onClick={()=>{
                            setIndex(prevState=>{
                              if(prevState === 0){
                                return fotoList.length-1
                              }else{
                                return prevState - 1
                              }
                            })
                          }}
                    >
                        <ChevronLeft color={theme.palette.grey[800]}/>
                    </IconButton>
                    <IconButton
                        onClick={()=>{
                            setIndex(prevState=>{
                              if(prevState === (fotoList.length-1)){
                                return 0
                              }else{
                                return prevState + 1
                              }
                            })
                          }}
                    >
                        <ChevronRight color={theme.palette.grey[800]}/>
                    </IconButton>
                </GalleryControls>
               
               {!cirugia?.reporte?.enviadoReporte &&  (currentUserIsEC || isMaster) &&         
                  <FileControl>
                        {loading ?
                            <BeatLoader size={8}/>
                        :
                            <UploadButton
                            id="fotos"
                            name="fotos"                        
                            onChange={onChangeUpload}                        
                            multiple                            
                            />
                        }
                                                                        
                        {   
                            loadingDelete ?    
                                <BeatLoader size={8}/>
                                
                            :
                                <IconButton>
                                    <Trash2 
                                        color={theme.palette.grey[800]}
                                        onClick={()=>deleteFotos(fotoList[index],index)}
                                    />
                                </IconButton>
                        }                                                                    
                    </FileControl>  
                }              
            </Actions>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px 16px 16px 16px;
    
    

    @media ${p=>p.theme.media.tablet}{
        min-width: 700px;
    }
`

const Gallery = styled.div`
    display:flex;
    justify-content: center;

`

const GalleryControls = styled.div`
    overflow: scroll;
    display:flex;
    align-items:center;
    
`

const FileControl = styled.div`
    display:flex;
    display:flex;
    align-items:center;

`

const Image = styled.img`
    object-fit: cover;
    width: 100%;
    max-height: 500px;
    
    @media ${p=>p.theme.media.tablet}{
        width: revert;
    }
    
`
const Actions = styled.div`
    display:flex;
    height: 65px;
    justify-content: space-between;
    align-items:center;
`

export default Fotos;