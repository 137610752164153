import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody } from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import CentrosAutocomplete from './CustomAutocomplete/CentrosAutocomplete';
import useCirugia from '../../hooks/useCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const ModifyCentro = ({
    cirugiaId,
    close
})=>{

    const isTablet = useMediaQuery('tablet');    
    const [selectedCentro, setSelectedCentro] = React.useState(null);
    const [comments, handleChangeComments] = React.useState('');
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);

    
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();
    const cirugia = useCirugia(cirugiaId);
  
    
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Centro cambio de ${cirugia?.centro?.nombre} a ${selectedCentro?.nombre}`);

        console.log(selectedCentro);
        let infoCentro = {
            centroId: selectedCentro._id,
            nombre: selectedCentro.nombre,
            ciudad: selectedCentro.ciudad,
            zona: selectedCentro.zona,
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion:accion
          }

        // console.log(infoCentro)
        // dispatch(updateCirugia("update-centro",infoCentro));
        // close(false);
        updateCirugia("update-centro",infoCentro)
      
    }        
      

    const onChangeHandler = (event, option)=>{
        if(option){
            setSelectedCentro(option)
        }else{
            setSelectedCentro("")
        }
        
    }

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Modificar Centro</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <CentrosAutocomplete
                    onChange={onChangeHandler}
                />                  

                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />                                
            </FormBody>
            
           <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyCentro;