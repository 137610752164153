import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { addDoctor } from '../../../app/doctorsReducer';
import { errorMessage} from '../../../app/messagesReducer';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import Send from '../../Icons/Send';
import DoctorPermissionsList from './DoctorPermissionsList';




const DoctorInvite = ({
    doctor,
    close
})=>{

    const user = useCurrentUser()
    const dispatch = useDispatch();
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const subscription = useSubscriptionToken()
    const planAllowsDoctorInvitation = subscription.companyPermissions.includes('invite_doctors')


    const connect = async ()=>{
        setIsLoading(true)
        const options = createOptions('POST', {
            companyId: user.company._id,
            companyName: user.company.name,
            companyDoctorId:doctor._id,
            email: doctor.email,
            firstname: doctor.firstname,
            lastname: doctor.lastname
        })                        
        
        try {
            let response = await goFetch(apiUrl+ "md-users/invitation", options);
            responseStateChanges(response, ()=>dispatch(addDoctor(response.doctor)), ()=>close())                    
        } catch (error) {
            console.log(error)            
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }                
    }    


    if(!planAllowsDoctorInvitation){
        return(
            <StyledFormWrapper>
                <StyledFormTitle></StyledFormTitle>
                <StyledFormBody>
                    <Text>Su subscripcion no permite invitar a doctores, debes tener el plan Premium.</Text>
                </StyledFormBody>
           </StyledFormWrapper> 
        )
    }


    return(
        <StyledFormWrapper>
            <StyledFormTitle>Invitacion a unirse a Xirugias.</StyledFormTitle>
            <StyledFormBody>

                <Text>{`Xirugias mandara un email a ${doctor.firstname} ${doctor.lastname} invitadolo a conectar a esta plataforma en nombre de ${user.company.name}.`}</Text>
                <Text>{`Al aceptar la invitacion podra ver informacion sobre las cirugias donde el o ella sea el doctor asignado.`}</Text>
                <br/>
                <DoctorPermissionsList/>                
            </StyledFormBody>
            
            <FormActionsBasic
                close={close}
                onSubmit={connect}
                successText="Enviar"
                cancelText="Volver"                
                preIconSuccess={<Send color="#ffff" size="18" />}
                loading={isLoading}
                successEvent={successEvent}
                
            />
            
        </StyledFormWrapper>
    )
}


const StyledFormTitle = styled(FormTitle)`
    margin-bottom: 20px;
    padding:0px;
    text-align:center;

    @media ${p=>p.theme.media.tablet}{
        text-align:revert;
    }
    
`


const StyledFormWrapper = styled(FormWrapper)`
    
    padding: 12px;
`

const StyledFormBody = styled(FormBody)`
    margin-bottom: 24px;
`

const Text = styled.p`
    margin: 0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}

`



export default DoctorInvite;