import styled from '@emotion/styled/macro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOptions, goFetch } from '../../api/cirugiasApi'
import { fetchVentas } from '../../app/thunkActions/usersActions'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import useMediaQuery from '../../hooks/useMediaQuery'
import useModalAndDrawer from '../../hooks/useModalAndDrawer'
import isEmpty from '../../shared/isEmptyOject'
import { apiUrl } from '../../shared/url'
import Button from '../Atoms/Button'
import { CardTitle, CardWrapper } from '../Atoms/Card'
import Chip from '../Atoms/Chip'
import { FormBody, FormTitle, FormWrapper } from '../Atoms/Forms'
import Modal from '../Atoms/Modal'
import Cotizacion from './Cotizacion'
import EditProductoCotizacion from './EditProductoCotizacion'
import { commonClients, especialidades, productosNeuro, productosTiroide } from './InfoPruebas'
import ClientePrivadoInput from './molecules/ClientePrivadoInput'
import CotizacionInformationSummary from './molecules/CotizacionInformationSummary'
import DescuentoTotalInput from './molecules/DescuentoTotalInput'
import DoctorAndCentroInput from './molecules/DoctorAndCentroInput'
import PacienteInput from './molecules/PacienteInput'
import ProductoInput from './molecules/ProductoInput'
import BeatLoader from 'react-spinners/BeatLoader'
import { useTheme } from '@mui/system'
import CurrencyRadio from '../molecules/CurrencyRadio'
import IconButton from '../Atoms/IconButton'
import Plus from '../Icons/Plus'
import Search from '../Icons/Search'
import SearchClients from './molecules/SearchClients'
import { setSelected } from '../../app/doctorsReducer'
import FadeIn from '../Atoms/FadeIn'
import Fade from '../Atoms/FadeIn'
import NotaCotizacion from './molecules/NotaCotizacion'
import useSuccessButton from '../../hooks/useSuccessButton'




export const round = (value) => {   
    value = Number(value)    
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
}

export const createSelectedProductObject = (producto, selectedClient, currency) => {

    console.log(producto)
    
    let selectedProducto  ={}
    selectedProducto._id = producto._id;
    selectedProducto.codigo = producto.codigo;
    selectedProducto.descripcion = producto.descripcion;
    selectedProducto.subjectToItbis = producto.subjectToItbis;
    
    //check if the product has a price for the selected client
    //if not, check if the product has a price for the base price list
    //if not, set the price to 0
    
    if(producto?.price && producto?.price?.[selectedClient?.priceList]?.[currency]){
        selectedProducto.clientPrice = producto?.price[selectedClient?.priceList][currency];
    }else if(producto?.price && producto?.price["Base"][currency]){
        selectedProducto.clientPrice = producto?.price["Base"][currency];
    }else{
        selectedProducto.clientPrice = 0; 
    }
    //selectedProducto.clientPrice = producto?.price[selectedClient?.priceList];    
    selectedProducto.quantity = 1;
    
    selectedProducto.discount = 0;
    selectedProducto.percentDiscount = 0;        
    selectedProducto.subtotal = (selectedProducto.clientPrice - selectedProducto.discount) * selectedProducto.quantity;          
    selectedProducto.itbis = producto.subjectToItbis ? (selectedProducto.clientPrice *.18) : 0;    
    selectedProducto.total = selectedProducto.subtotal + selectedProducto.itbis; 
    selectedProducto.price = producto.price;
    selectedProducto.currency = currency;
    return selectedProducto
}

//called when editing a product that has already been selected to the product table, also when discount is applied to all products at once. 
export const updateSelectedProductObject = (producto, newPrice, quantity, percentDiscount) => {    
    let updatedProducto ={}
    updatedProducto.clientPrice = newPrice ? newPrice : producto.clientPrice;
    updatedProducto.quantity = quantity;
    updatedProducto.total = producto.clientPrice;
    updatedProducto.percentDiscount = percentDiscount ? percentDiscount : producto.percentDiscount;
    updatedProducto.discount = percentDiscount/100 * updatedProducto.clientPrice;       
    //if (producto.clientPrice != null && producto.discount != null && producto.quantity != null) {
        updatedProducto.subtotal = (updatedProducto.clientPrice - updatedProducto.discount) * updatedProducto.quantity;
    //    }
    updatedProducto.itbis = producto.subjectToItbis ? ((updatedProducto?.clientPrice - updatedProducto?.discount) * updatedProducto.quantity *.18) : 0;
    updatedProducto.total = updatedProducto.subtotal + updatedProducto.itbis;
    
    return {...producto,...updatedProducto}
}

export const newTotalCotizacionCalc = (selectedProductos) => {
    console.log('in newTotalCotizacionCalc')
    const newTotalCotizacion = selectedProductos.reduce((totalCotizacion, producto) => {
        //totalSinItbis includes the total of the products that are not subject to itbis            
        totalCotizacion.totalSinItbis += producto.clientPrice * producto.quantity
        totalCotizacion.descuento += producto.discount * producto.quantity
        totalCotizacion.subtotal += producto.subtotal
        totalCotizacion.exentoItbis += producto.itbis === 0 ? producto.subtotal : 0
        totalCotizacion.totalGravado += producto.itbis > 0 ? producto.subtotal : 0
        totalCotizacion.itbis += producto.itbis
        totalCotizacion.totalGeneral += producto.total
        return totalCotizacion
    }, {    
        totalSinItbis: 0,
        descuento: 0,
        subtotal: 0,
        exentoItbis: 0,
        totalGravado: 0,
        itbis: 0,
        totalGeneral: 0
    })

    return newTotalCotizacion
}

//recalculate selected productos array when client or currency changes
export const recalculateSelectedProductos = (selectedProductos, selectedClient, currency) => {

    const recalculatedSelectedProductos = selectedProductos.map(producto => {        
            let selectedProducto  ={}
            selectedProducto._id = producto._id;
            selectedProducto.codigo = producto.codigo;
            selectedProducto.descripcion = producto.descripcion;
            selectedProducto.subjectToItbis = producto.subjectToItbis;
            
            //check if the product has a price for the selected client
            //if not, check if the product has a price for the base price list
            //if not, set the price to 0 
            
            console.log(selectedClient?.priceList)
            const clientPriceList = selectedClient?.priceList ? selectedClient?.priceList : "Base"
                        
            if(producto.price && producto?.price?.[clientPriceList]?.[currency]){
                selectedProducto.clientPrice = producto?.price[clientPriceList][currency];
            }else if(producto?.price && producto?.price["Base"][currency]){
                selectedProducto.clientPrice = producto?.price["Base"][currency];
            }else{
                selectedProducto.clientPrice = 0; 
            }
            //selectedProducto.clientPrice = producto?.price[selectedClient?.priceList];    
            selectedProducto.quantity = 1;
            
            selectedProducto.discount = 0;
            selectedProducto.percentDiscount = 0;        
            selectedProducto.subtotal = (selectedProducto.clientPrice - selectedProducto.discount) * selectedProducto.quantity;          
            selectedProducto.itbis = producto.subjectToItbis ? (selectedProducto.clientPrice *.18) : 0;    
            selectedProducto.total = selectedProducto.subtotal + selectedProducto.itbis; 
            selectedProducto.price = producto.price;
            return selectedProducto
    })


    return recalculatedSelectedProductos
}

        


const CrearCotizaciones = ({setIsNewCotizacion}) => {

    const user = useCurrentUser()
    const theme = useTheme()
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [clientePrivado, setClientePrivado] = React.useState(null);
    const [selectedEspecialidad, setSelectedEspecialidad] = React.useState(null);
    const [quickProductos, setQuickProductos] = React.useState([]);
    const [clientList, setClientList] = React.useState(commonClients);
    const [selectedProductos, setSelectedProductos] = React.useState([]);
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();
    const [productoId, setProductoId] = React.useState(null);
    const [selectedProductoEdit, setSelectedProductoEdit] = React.useState(null);    
    const [pacienteInfo, setPacienteInfo] = React.useState()
    const[newCotizacion, setNewCotizacion] = React.useState(null)
    const [doctor, setDoctor]   = React.useState(null)
    const [centro, setCentro]   = React.useState(null)
    const [nota, setNota] = React.useState(null)
    const [totalCotizacion, setTotalCotizacion] = React.useState({
        totalSinItbis: 0,
        descuento: 0, 
        subtotal: 0,
        exentoItbis: 0,
        totalGravado: 0,
        itbis: 0,
        totalGeneral: 0              
    });
    
    const [ventasSelected, setVentasSelected] = React.useState(false);
    const smallBottomSwipeDrawers = []
    const drawerOffsetPercentInputArray = ["addPaciente", "editProducto", "addProducto", "addClientePrivado", "addDoctorAndCentro", "descuentoTotal", "mobileInfoSummary"]
    const [isLoadingQuickProduct, setIsLoadingQuickProduct] = React.useState(false);
    const {isLoading:isLoadingVentas} = useSelector(state=>state.users)
    const [currency, setCurrency] = React.useState("pesos");
    const [otroCliente, setOtroCliente] = React.useState(false);
    const [quickClients, setQuickClients] = React.useState([])
    const [isLoadingQuickClients, setIsLoadingQuickClients] = React.useState(true)
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const [isButtonClicked, setIsButtonClicked] = React.useState(false);


    //check if using phone
    const isPhone = useMediaQuery('phone')

    //create a useEffect to get ventas users from server
    console.log(currency)
    
    const dispatch = useDispatch();
    const ventas = useSelector(state=>state.users.ventas)
    
    React.useEffect(()=>{
        const fetchQuickClients = async () => {
            setIsLoadingQuickClients(true)
            const options = createOptions("GET",{});
            const response = await goFetch(`${apiUrl}clients/fetch-clients/quick-clients`,options);
            if(response){
                setQuickClients(response)
                setIsLoadingQuickClients(false)
            }
        }
        fetchQuickClients()
        dispatch(fetchVentas())

    },[dispatch])
    
    //const ventas = []



    //check if this useEffect is still necesary since we added setTotalCotizacion to ProductoInput when product is added at guardar
    React.useEffect(() => {      
        //go through the selectedProducts array and update totalCotizacion object
        const newTotalCotizacion = newTotalCotizacionCalc(selectedProductos)
        setTotalCotizacion(newTotalCotizacion)        
    }, [selectedProductos])


    const onClickClientChip = (client) => {        
        
        //check if the client is already selected, if so, unselect it, if not, select it, and unselect the previous client, if any, and recalculate the selected productos array
        let clientSelected = selectedClient?.name === client?.name 
        if(!clientSelected){
            setSelectedClient(client)
        }else{            
            setSelectedClient(null)    
            //Since the client is unselected, set the client to null as it will go to the recalculateSelectedProductos function. This is so the price of the product will be calculated using the Base price list                    
            client = null
        }
    
        //if there are selected productos, recalculate the selected productos array in order to get new prices
        if(selectedProductos.length > 0){
            const newSelectedProductos = recalculateSelectedProductos(selectedProductos, client,currency)
            console.log(newSelectedProductos)
            setSelectedProductos(newSelectedProductos)
        }

    }

 

    const onClickEspecialidadChip = async (especialidad) => {
        
        setIsLoadingQuickProduct(true)
        

        if(especialidad.name === "Neurocirugia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosNeuro);
        }else if(especialidad.name === "Tiroide"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosTiroide);        
        }else if(especialidad.name === "Cardiologia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosCardio);
        }else if(especialidad.name === "Maxilo"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosMaxilo);
        }else if(especialidad.name === "Cirugia General"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosCirugiaGeneral); 
        }else if(especialidad.name === "Ginecologia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosGinecologia);
        }else if(especialidad.name === "Artroscopia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosArtroscopia);
        }else if(especialidad.name === "Ortopedia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosOrtopedia);
        }else if(especialidad.name === "Urologia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosUrologia);
        }else if(especialidad.name === "ORL"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosORL);
        }else if(especialidad.name === "Columna"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosColumna);
        }else if(especialidad.name === "Grapado Abierto"){
            setSelectedEspecialidad(especialidad)
        }else if(especialidad.name === "Grapado TriStaple"){
            setSelectedEspecialidad(especialidad)
        }else if(especialidad.name === "General"){
            setSelectedEspecialidad(especialidad)         
        }else{
            setSelectedEspecialidad(null)
            setQuickProductos([]);
        }

        // fetch the productos that have the selected especialidad and quickProductos is active
        const options = createOptions('GET')
        try {
            const response = await goFetch(`${apiUrl}productos/fetch-quickproductos/${especialidad.name}`, options)            
            setIsLoadingQuickProduct(false)
            setQuickProductos(response)    
        } catch (error) {
            console.log(error)
        }
        
        
    }

    const onClickQuickProduct = (producto) => {
        
        // add the product to the selectedProducts array if it is not already in the array, if it is in the array, remove it
        const newSelectedProductos = selectedProductos.filter((productoItem) => {
            return productoItem._id !== producto._id;
        }
        );

        if (newSelectedProductos.length === selectedProductos.length) {
            producto = createSelectedProductObject(producto, selectedClient, currency)            
            setSelectedProductos([...selectedProductos, producto]);
        }
        else {
            setSelectedProductos(newSelectedProductos);
        }
  
    }

    //function to check if product is already in the selectedProducts array
    const isProductSelected = (producto) => {
        const isProductSelected = selectedProductos.find((productoItem) => {
            return productoItem._id === producto._id;
        });
        return isProductSelected;
    };
    
    const onClickVentas = (user) => {
        let selectedVentas = ventasSelected?._id === user?._id 
        if(!selectedVentas){
            setVentasSelected(user)
        }else{
            setVentasSelected(null)
        }

    }



    //when click on crear cotizacion creates a new cotizacion object which includes the client info, products, totalCotizacion object and the date
    //then we send it to the backend to be saved in the database
    const onClickCrearCotizacion = async (event) => {
       
        if(isButtonClicked){
            return
        }
        setIsButtonClicked(true)
        
        console.log('in onClickCrearCotizacion')
        setIsLoading(true)
        //take out prices key for each object in the selectedProductos array. Price was added in order to allow for recalculation given changes in client and currency
        
        console.log(selectedProductos)
        const newSelectedProductos = selectedProductos.map(producto => {
            const {price, ...newProducto} = producto
            return newProducto
        })
        console.log(newSelectedProductos)

        const newCotizacion = {
            company:{
                _id: user.company._id,
                name: user.company.name,
            },
            currency: currency,
            client: {
                _id: selectedClient._id,
                name: selectedClient.name,                
                email: selectedClient.email,
                phone: selectedClient.phone,
                address: selectedClient.address,
                cedula: selectedClient.cedula,
                rnc: selectedClient.rnc,
                tipo: selectedClient.tipo,
                priceList: selectedClient.priceList,                            
            },
            productos: [newSelectedProductos],
            paciente: pacienteInfo,
            doctor: doctor,
            centro: centro,
            nota: nota,
            totalCotizacion: [totalCotizacion],
            //date: new Date(),
            ventas: {
                _id: ventasSelected._id,
                firstname: ventasSelected.firstname,
                secondName: ventasSelected.secondName,
                lastname: ventasSelected.lastname,
                secondLastname: ventasSelected.secondLastname,
                email: ventasSelected.email                
            },
            creadoPor:{
                _id: user._id,
                firstname: user.firstname,
                secondName: user.secondName,
                lastname: user.lastname,
                secondLastname: user.secondLastname,
                email: user.email,
                signature: user?.signature || null
            },
            dateModified: [new Date()]
        }
        
        
        //send the newCotizacion object to the backend to be saved in the database
        const options = createOptions("POST", newCotizacion)

        try {
            const response = await goFetch(apiUrl +"cotizaciones/create-cotizacion", options)
            responseStateChanges(response,()=>{}, ()=>{
                close();
                setNewCotizacion(response.cotizacion)
                setIsNewCotizacion(true)
            })                        
        } catch (error) {
            console.log(error)
        }                
    }

    const onClickVerCotizacion = () => {
        console.log(formType)
        console.log(openModal)
        setFormType("showCotizacion")        
        setOpenModal(true);            

    }

    const onChangeCurrency = (event) => {
        setCurrency(event.target.value)
        
        if(selectedProductos.length > 0){
            const newSelectedProductos = recalculateSelectedProductos(selectedProductos, selectedClient, event.target.value)
            console.log(newSelectedProductos)
            setSelectedProductos(newSelectedProductos)
        }
    }
    
    

    if(newCotizacion){
        return (
            <>
            <FormWrapper>
                <FormTitle>Cotizacion Creada</FormTitle>
                <FormBody>
                    <InfoAdicionalRow>
                        <TotalLabel>Cliente:</TotalLabel>
                        <Value>{newCotizacion?.client?.name}</Value>
                        
                    </InfoAdicionalRow>
                    <InfoAdicionalRow>
                        <TotalLabel>Numero cotizacion: </TotalLabel>
                        <Value>{newCotizacion?.cotizacionNumber}</Value>
                    </InfoAdicionalRow>
                    
                    
                    {newCotizacion.client && <Button success onClick={onClickVerCotizacion}>Ver cotizacion</Button>}
                </FormBody>
                
            </FormWrapper>
             <Modal
             openModal={openModal}
             setOpenModal={setOpenModal} 
             inProp={inProp}
             setInProp={setInProp}
             close={close}
             smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
               
               
         >            
             {formType === "showCotizacion" && <Cotizacion cotizacion={newCotizacion} test={'test'}/>}                         
         </Modal> 
        </>
            
        )
    }
    
    // console.log(formType)
    // console.log(clientePrivado)
    // console.log(selectedClient)
    
    return (
        <>
        <ExternalWrapper>
            <SideWrapper>
                <StepWrapper>
                    <CurrencyRadio currency={currency} onChangeRadio={onChangeCurrency}/>
                </StepWrapper>                
                <StepWrapper>
                    <StepHeading style={{minHeight: '50px'}}>Seleccionar cliente
                    {otroCliente &&
                        <FadeIn duration={600}>
                            <div style={{display: 'flex'}}>
                            <IconButton size={'small'}>
                            <Search size={20} onClick={()=>{setFormType('searchClients');setOpenModal(true)}}/>
                            </IconButton>
                            <IconButton size="small">
                            <Plus size={20} onClick={()=>{setFormType('addClientePrivado')
                                                setOpenModal(true)}}/>
                            </IconButton>
                            </div>
                        </FadeIn>
                    }    
                    

                    </StepHeading>
                    <StepButtonWrapper>
                    {isLoadingQuickClients && <Chip outline label={<BeatLoader css={{lineHeight:0}} color={theme.palette.grey[600]} size={6} />}/>}

                    {!isLoadingQuickClients &&
                        quickClients?.map(client => {
                            const primary = client.name === selectedClient?.name
                            const outline = !primary

                            return (   
                                <Chip 
                                    key={client.id} 
                                    primary={primary} 
                                    outline={!primary}                             
                                    label={client.quickClient.shortDescription} 
                                    onClick={()=>{
                                        onClickClientChip(client)
                                        otroCliente && setOtroCliente(false)
                                    }}
                                />
                            
                        )})                        
                    }
                       <Chip 
                            label="Otro"
                            outline={!otroCliente}
                            primary={otroCliente}
                            onClick={()=>{
                                setOtroCliente(!otroCliente)
                                onClickClientChip(null)
                            }}
                       /> 
                        
                    </StepButtonWrapper>
                </StepWrapper>

                <StepWrapper>
                    <StepHeading>Seleccionar grupo de productos</StepHeading>
                    <StepButtonWrapper>

                {
                    especialidades.map(especialidad => (   
                        <Chip
                            key={especialidad.id} 
                            primary={especialidad === selectedEspecialidad}
                            outline={especialidad !== selectedEspecialidad}
                            label={especialidad.name} 
                            onClick={()=>onClickEspecialidadChip(especialidad)}
                        />
                        
                    ))
                }
                    </StepButtonWrapper>
                </StepWrapper>
                <StepWrapper>
                    <StepHeading>Seleccionar productos
                        <div style={{display: 'flex'}}>
                            <IconButton size={'small'}>
                            <Search size={20} onClick={()=>{setFormType('addProducto');setOpenModal(true)}}/>
                            </IconButton>                            
                        </div>

                    </StepHeading>
                    <StepButtonWrapper>
                    
                        {isLoadingQuickProduct && <Chip outline label={<BeatLoader css={{lineHeight:0}} color={theme.palette.grey[600]} size={6} />}/>}
                        {!isLoadingQuickProduct && quickProductos.length >0 &&                         
                            quickProductos?.map(producto => (                                
                                <Chip 
                                    key={producto._id}
                                    success={isProductSelected(producto)} 
                                    outline={!isProductSelected(producto)}                                     
                                    label={producto.quickProducto.shortDescription} 
                                    onClick={()=>onClickQuickProduct(producto)}
                                />    ))}                                                             
                    </StepButtonWrapper>                    
                </StepWrapper>
                <StepWrapper>
                    <StepHeading>Seleccionar ventas</StepHeading>
                    <StepButtonWrapper>
                        {isLoadingVentas && <Chip outline label={<BeatLoader css={{lineHeight:0}} color={theme.palette.grey[600]} size={6} />}/>}
                        {!isLoadingVentas &&
                            ventas?.map(user => (
                                <Chip 
                                    key={user._id}
                                    success={user?._id === ventasSelected?._id} 
                                    outline={user?._id !== ventasSelected?._id}                                     
                                    label={`${user.firstname} ${user.lastname[0]}`} 
                                    onClick={()=>onClickVentas(user)}
                                />))
                        }                            
                    </StepButtonWrapper>                    
                </StepWrapper>
                <StepWrapper>
                    <StepHeading>Informacion adicional</StepHeading>
                    <StepButtonWrapper>                        
                        <Button primary onClick={()=>{
                            setFormType('addNota')
                            setOpenModal(true)
                       }}>Nota</Button>
                       <Button primary onClick={()=>{
                            setFormType('addPaciente')
                            setOpenModal(true)
                       }}>Paciente</Button>
                       <Button primary onClick={()=>{
                            setFormType('addDoctor&Centro')
                            setOpenModal(true)
                       }}>Doctor & Centro</Button>                     
                        <Button primary onClick={()=>{
                            setFormType('descuentoTotal')
                            setOpenModal(true)
                       }}>Descuento Cotizacion</Button>
                       
                    </StepButtonWrapper>
                    {isEmpty(newCotizacion)  && <Button primary onClick={onClickCrearCotizacion} success successEvent={successEvent}>Crear cotización</Button>}
                    {isPhone && <Button onClick={()=>{setFormType('mobileInfoSummary'); setOpenModal(true)}}>Ver informacion</Button>}
                    
                </StepWrapper>
            </SideWrapper>
            {!isPhone &&                        
                <SideWrapper style={{maxHeight:'700px', overflow: 'auto'}}>
                    <CotizacionInformationSummary                                                            
                        selectedClient={selectedClient}
                        pacienteInfo={pacienteInfo}
                        doctor={doctor}
                        centro={centro}
                        selectedProductos={selectedProductos}
                        setFormType={setFormType}
                        setOpenModal={setOpenModal}
                        setProductoId={setProductoId}
                        setSelectedProductoEdit={setSelectedProductoEdit}
                        totalCotizacion={totalCotizacion}
                        currency={currency} 
                        nota={nota}                   
                    />
                </SideWrapper>
}
        </ExternalWrapper>
        <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
                drawerOffsetPercentInput={drawerOffsetPercentInputArray.includes(formType) && "12%"}      
                  
            >
                {formType === "editProducto"  && <EditProductoCotizacion  
                                                    close={close}
                                                    productoId={productoId} 
                                                    selectedProductoEdit={selectedProductoEdit} 
                                                    selectedProductos={selectedProductos} 
                                                    setSelectedProductos={setSelectedProductos}
                                                    setTotalCotizacion={setTotalCotizacion}
                                                    currency={currency}
                                                />}            
                
                {formType === "addProducto" && <ProductoInput 
                                                    setProductos={setSelectedProductos} 
                                                    createSelectedProductObject={createSelectedProductObject}
                                                    selectedClient={selectedClient}
                                                    close={close}
                                                    currency={currency}
                                                    productos={selectedProductos}
                                                    setTotalCotizacion={setTotalCotizacion}
                                                    />}
                {formType === "addPaciente" && <PacienteInput pacienteInfo={pacienteInfo} setPacienteInfo={setPacienteInfo} close={close}/>}
                {formType === "addDoctor&Centro" && <DoctorAndCentroInput
                                                        setDoctor = {setDoctor}
                                                        setCentro = {setCentro}
                                                        close={close}
                                                    />}                
                
                {formType === 'addNota' && <NotaCotizacion
                                                nota={nota}
                                                setNota={setNota}
                                                close={close}
                                            />}
                {formType === "descuentoTotal" && <DescuentoTotalInput  
                                                    selectedProductos={selectedProductos} 
                                                    setSelectedProductos={setSelectedProductos} 
                                                    close={close}
                                                   />}

                 {formType === "addClientePrivado" && <ClientePrivadoInput
                                                        clientePrivado={clientePrivado}
                                                        setClientePrivado={setClientePrivado}
                                                        setSelectedClient={setSelectedClient}
                                                        close={close}
                                                      />}                                  
                
               {formType === "mobileInfoSummary" && <SideWrapper><CotizacionInformationSummary
                                                        selectedClient={selectedClient}
                                                        pacienteInfo={pacienteInfo}
                                                        doctor={doctor}
                                                        centro={centro}
                                                        selectedProductos={selectedProductos}
                                                        setFormType={setFormType}
                                                        setOpenModal={setOpenModal}
                                                        setProductoId={setProductoId}
                                                        setSelectedProductoEdit={setSelectedProductoEdit}
                                                        totalCotizacion={totalCotizacion}  
                                                        formType={formType}
                                                        productoId={productoId}
                                                        selectedProductoEdit={selectedProductoEdit}
                                                        setSelectedProductos={setSelectedProductos}
                                                        setTotalCotizacion={setTotalCotizacion}
                                                        currency={currency}
                                                        nota={nota}
                                                        /></SideWrapper>
                                                        }

                 {formType === "searchClients" && <SearchClients close={close} setSelectedClient={setSelectedClient}/>}                                       

            </Modal> 
     </>  
    )   
}



const ExternalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    
    gap: 16px;
    padding: 16px;
    max-width: 1200px;
    @media ${p=>p.theme.media.tablet}{
        flex-direction: row;
    }

`
const SideWrapper = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    gap: 16px;
`

const StepWrapper = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    justify-content: center;
`


const StepHeading = styled.div` 
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
    background-color: ${p=>p.theme.palette.grey[100]};
    border-radius: 12px;
    padding: 4px 8px;
`

const StepButtonWrapper = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    align-items: center;
`

const TotalLabel = styled.span`
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    font-size: 0.8rem;
`


const InfoAdicionalRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
`


const Value = styled.span`
    font-size: 0.8rem;    
    
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    flex:2;
`

export default CrearCotizaciones

