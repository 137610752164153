import React from "react"
import styled from '@emotion/styled';
import { useDispatch } from "react-redux";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import useCheckForm from "../../../hooks/useCheckForm";
import { isMaxLength, validate } from "../../../shared/validators";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { errorMessage } from "../../../app/messagesReducer";
import { FormControlLabel, Switch } from "@mui/material";
import { FormBody, FormHeader, FormSubtitle, FormWrapper } from "../../Atoms/Forms";
import TextInput from "../../Atoms/TextInput";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { apiUrl } from "../../../shared/url";
import { updateClientsArray } from "../../../app/clientsReducer";



const AccessCotClient = ({close, client}) => {
    console.log(client)
    const dispatch = useDispatch()
    const [acceso, setAcceso] = React.useState(client?.quickClient?.active || false)
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const accionMaker = useAccionMaker()
    
    
    const [values, setValues] = React.useState({        
        shortDescription: client?.quickClient?.shortDescription || "",
    })

    

      const [errors, setErrors] = React.useState({
        shortDescription: '',
        
      })

      const [formCompleted, errorCount] = useCheckForm(values,errors, ["shortDescription"])


      const onChangeValues = (event,validators, postCheck) => {    
        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }


    const onChange =()=>{
        console.log('clikcing')
        setAcceso(!acceso)

        if(acceso){            
            setValues(prevState=>({...prevState, shortDescription:""}))
        }
    }


    const onSubmit = async(e)=>{
        e.preventDefault()
        setIsLoading(true)

        const accion = accionMaker("Acceso a cotizacion actualizado")

        const infoAccesoCot = {
            active: acceso,            
            shortDescription: values.shortDescription || "",            
            accion
        }

        console.log(infoAccesoCot)

        const options = createOptions('PUT', infoAccesoCot)        
        try {
            console.log(client._id)
            const response = await goFetch(`${apiUrl}clients/quick-clients/${client._id}`, options)
            console.log(response)
            responseStateChanges(response, ()=>{
                //find client and update it
                dispatch(updateClientsArray(response))
                //const updatedClient = {...client, quickClient:response.quickClient}

            }, ()=>close())                                            
        } catch (error) {
            dispatch(errorMessage('Error al actualizar producto'))
        }

    }


  
    //console.log(client)
    return(
        <FormWrapper onSubmit={onSubmit} style={{maxWidth:'500px'}}>
            <FormHeader>
                Acceso a cotización
                <FormSubtitle>{`${client?.name}`}</FormSubtitle>
            </FormHeader>
            
            <FormBody>
                <Wrapper>
                    <StyledFormControlLabel control={<Switch size="small" checked={acceso} onChange={onChange} />} label="Acceso Cotizacion" />
                    {acceso && 
                    <InputWrapper>                                    
                    <TextInput
                        label="Descripcion corta" name="shortDescription" id="shortDescription"
                        value={values.shortDescription}                    
                        onChangeHandler={(event)=>onChangeValues(event,[()=>isMaxLength(15, event.target.value)])}
                        helperText={!errors.shortDescription ? "Debe ser menos de 15 caracteres": errors.shortDescription}
                        error={errors.shortDescription}
                        onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(15, event.target.value)])}
                    />                    
                    </InputWrapper>}                    
                </Wrapper>
            
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={acceso && (!formCompleted || errorCount>0)}
            />

            

        </FormWrapper>
    )

    }


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
    
`



const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-size: 0.875rem;
    }    
`



export default AccessCotClient