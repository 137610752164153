import { addActivosFijos } from "../activosFijosReducer";
import { apiUrl } from "../../shared/url";
import { createOptions, goFetch } from "../../api/cirugiasApi";
import { setLoading } from "../activosFijosReducer";


export const fetchActivosFijos = (search, filter = "active") => async (dispatch) => {
    const options = createOptions('GET')
    let activosFijos;
    dispatch(setLoading(true));

    try {
        activosFijos = await goFetch(`${apiUrl}activosFijos/${search}/${filter}`, options);
        dispatch(addActivosFijos(activosFijos));        
    }catch (error) {
        console.log(error);
        dispatch(setLoading(false));
    }

    
}