import styled from '@emotion/styled';
import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modifyFilter } from '../../../../app/filterReducer';
import { fetchCirugias } from '../../../../app/thunkActions/cirugiasActions';
import { setLocalStorage } from '../../../../shared/authHelpers';
import Button from '../../../Atoms/Button';
import CentrosAutocomplete from '../../../Forms/CustomAutocomplete/CentrosAutocomplete';
import CompanyAutocomplete from '../../../Forms/CustomAutocomplete/CompanyAutocomplete';



const FilterFieldsMd =()=>{

    const filter = useSelector(state=>state.filter.filter);
    
    //changed to using local state in order to do one fetch when user is done (filtrar button). Also to help with input fields that kept value when filter closed via filter chip.
    const [localState, setLocalState] = React.useState()

    React.useEffect(()=>{
        setLocalState({
            doctor: filter.doctor,
            centro: filter.centro,
            ventas: filter.ventas,
            ec: filter.ec,
            especialidad: filter.especialidad,
            ars: filter.ars,
            company: filter.company
        })
    },[filter])

    const dispatch = useDispatch();

    const showInitialState = {
        company: false, 
        centro: false,        
        ars: false
    }
    

    const [show, setShow] = React.useState(showInitialState);   


   // console.log(show);

      //onChange for Autocomplete component
      const onChangeValuesId = (name, option)=>{
        console.log('Running on change')
        if(option){            
            console.log('Found option running filter')            
            setLocalState(prevState=>({...prevState,[name]:option?.name}))
        }else{            
            console.log('No option running with zero filter')            
            setLocalState(prevState=>({...prevState,[name]:""}))
        }
        
    }

    //version for MUI Autocomplete
    const onChangeValuesAutocomplete = (event, option, name)=>{
        if(option){                        
            setLocalState(prevState=>({...prevState,[name]:option?._id}))
        }else{            
            setLocalState(prevState=>({...prevState,[name]:""}))            
        }
        
    }

    
    const onChangeFields = (field)=>{
        // if swith is on, then user is closing switch. Send new filter request without this field populated. 
        if(show[field]){          
          dispatch(modifyFilter({...filter, [field]:"" }))   
          setLocalState(prevState=>({...prevState,[field]:""}))                                   
        }
       setShow(prevState => ({...show, [field]: !prevState[field]}))
    }


    const onClickFiltrar = ()=>{
        dispatch(modifyFilter({...filter,...localState}))        
        //dispatch(fetchCirugias({...filter, ...localState}));
        setShow(showInitialState)
                                           
        //dispatch(modifyFilter({...values}))
    }

    //checks if any field has been switched to open/show. 
    const showFilter = ()=>{
        for(const field in show){
            if(show[field]){
                return true
            }
        }
    } 

    const showFields = showFilter()
    return(
        <>
        <SwitchWrapper show={showFields}>
                    <SwitchContainer>
                        <StyledFormControlLabel control={<Switch size="small"  checked={show?.company} onChange={()=>onChangeFields('company')} />} label="Compañia" />
                        <StyledFormControlLabel control={<Switch size="small"  checked={show?.centro} onChange={()=>onChangeFields('centro')} />} label="Centro" />
                        <StyledFormControlLabel control={<Switch size="small" checked={show?.ars} onChange={()=>onChangeFields('ars')}/>} label="Ars" />  
                        
                    </SwitchContainer>
                    
                </SwitchWrapper>      
        <Wrapper>
            {
                show?.company && 
                    <CompanyAutocomplete onChange={onChangeValuesId} id="company"  value={localState.company}/>
            }                    
            {
                show?.centro && 
                    <CentrosAutocomplete  onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "centro")}/>
            }                    
            {
                showFields && 
                    <Button primary onClick={onClickFiltrar}>Filtrar</Button>
            }
        </Wrapper>

        </>
    )
}


const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    padding: 0px 4px;
    gap: 8px;
`

const SwitchWrapper = styled.div`
    display:flex;
    padding: 0px 12px;
    margin-bottom: ${p=>p.show? '12px':'0px'}
`

const SwitchContainer = styled.div`
    flex:1;
    display:flex;
    flex-direction: column;
`
const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-size: 0.875rem;
    }    
`

export default FilterFieldsMd;