import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../Atoms/Button';
import { CardHeader,CardTitle, CardBody, CardWrapper as Card, CardActions } from "../../Atoms/Card";
import { FormControlRadio, Radio } from '../../Atoms/Radio';
import { updateCompanyUsers, updateUser } from '../../../app/thunkActions/usersActions';
import { Checkbox, FormControlCheckbox } from '../../Atoms/Checkbox';
import useHasPermissions from '../../../hooks/useHasPermissions';
import { permissionsDef, rolePermissions } from '../../../shared/roles';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import { useAccionMaker } from '../../../hooks/useAccionMaker';




const Roles = ({
    user
})=>{
    const dispatch = useDispatch();    
    const [role, setRole] = React.useState(user?.role || {})
    //get current role.
    const currentRole = Object.keys(role)[0]
    //check if subscription is premium
    const subscription = useSubscriptionToken();
    const planAllowsCotizaciones = subscription?.companyPermissions?.includes('cotizaciones');;
    const accionMaker = useAccionMaker()
    
    
    React.useEffect(()=>{
        //console.log(user?.role)
        user && setRole(user.role)
      }, [user])
      //console.log(user);
      //console.log(role);
    const onChange=(event)=>{        
        setRole({[event.target.value]: true})
        //console.log({[event.target.value]: true})
    }
    
    const options = [
        {label: "Administrador", name: 'role', value: "isAdmin"},
        {label: "Operaciones", name: 'role', value: "isOperaciones"},
        {label: "Ventas", name: 'role', value: "isVentas"},
        {label: "Servicio al Cliente", name: 'role', value: "isServicio"},
        {label: "Especialista Clinico", name: 'role', value: "isEC"},
        {label: "Observador", name: 'role', value: "isObservador"}
    ]


    const canCrearCirugias = useHasPermissions("can_crear_cirugias", user, currentRole);
    const canAgendar = useHasPermissions("can_agendar", user, currentRole);
    const canAceptarCirugias = useHasPermissions("can_aceptar_cirugias", user, currentRole);
    const canEditarCirugias = useHasPermissions("can_editar_cirugias", user, currentRole);
    const canSuspenderCirugias = useHasPermissions("can_suspender_cirugias", user, currentRole);
    const canConcluirCirugias = useHasPermissions("can_concluir_cirugias", user, currentRole);
    const canEditDocumentacion = useHasPermissions("can_edit_documentacion", user, currentRole);
    const canEditReporte = useHasPermissions("can_edit_reporte", user, currentRole);
    const canResetReporte = useHasPermissions("can_reset_reporte", user, currentRole);
    const canSeeAllCirugias = useHasPermissions("can_see_all_cirugias", user, currentRole);
    const canUpdateOperacionesStages = useHasPermissions("can_update_operaciones_stages", user, currentRole);
    const canUpdateFacturacionStages = useHasPermissions("can_update_facturacion_stages", user, currentRole);
    const canViewFacturacionStages = useHasPermissions("can_view_facturacion_stages", user, currentRole);
    const canAutorizarCirugias = useHasPermissions("can_autorizar_cirugias", user, currentRole);
    const canEditarPacientes = useHasPermissions("can_editar_pacientes", user, currentRole);
    const canCrearProductos = useHasPermissions("can_crear_productos", user, currentRole);
    const canInviteUsers = useHasPermissions("can_invite_users", user, currentRole);
    const canAccessBilling = useHasPermissions("can_access_billing", user, currentRole);       
    const canAsignarEc =  useHasPermissions("can_asignar_ec", user, currentRole);       
    const canSeeAllReportes = useHasPermissions("can_see_all_reportes", user, currentRole); 
    const canSeeCotizacionAndAutorizacion = useHasPermissions("can_see_cotizacion_and_autorizacion", user, currentRole)
    const canCrearEditarInvitarDoctores = useHasPermissions("can_crear_editar_invitar_doctores", user, currentRole);
    const canCrearPacientes = useHasPermissions('can_crear_pacientes', user, currentRole);
    const canViewDashboard = useHasPermissions('can_view_dashboard', user, currentRole);
    const canReceiveCirugiaReporteEmails = useHasPermissions('can_receive_cirugia_reporte_emails', user, currentRole);
    const canCrearCotizaciones = useHasPermissions('can_crear_cotizaciones', user, currentRole);
    const canEditarCotizaciones = useHasPermissions('can_editar_cotizaciones', user, currentRole);
    const canEditarProductosAndVentasCotizaciones = useHasPermissions('can_editar_productos_and_ventas_cotizaciones', user, currentRole);
    const canVerCotizaciones = useHasPermissions('can_ver_cotizaciones', user, currentRole);
    const canVerTodasCotizaciones = useHasPermissions('can_ver_todas_cotizaciones', user, currentRole);
    const canCancelarCirugias  = useHasPermissions('can_cancelar_cirugias', user, currentRole);
    const canReactivarCirugias = useHasPermissions('can_reactivar_cirugias', user, currentRole);
    const canViewOperacionesMenu = useHasPermissions('can_view_operaciones_menu', user, currentRole);

    
    

    const [permisos, setPermisos] = React.useState({
            canCrearCirugias: false,
            canAgendar: false,
            canAceptarCirugias: false,
            canEditarCirugias: false,
            canSuspenderCirugias: false,
            canConcluirCirugias: false,
            canEditDocumentacion: false,
            canEditReporte: false,
            canResetReporte: false,
            canSeeAllCirugias: false,
            canUpdateOperacionesStages: false,
            canUpdateFacturacionStages: false,
            canViewFacturacionStages: false,
            canAutorizarCirugias: false,                        
            canEditarPacientes: false,
            canCrearProductos: false,
            canInviteUsers: false,
            canAccessBilling: false,
            canAsignarEc: false,
            canSeeAllReportes: false,
            canSeeCotizacionAndAutorizacion:false,
            canCrearEditarInvitarDoctores: false,
            canCrearPacientes: false,
            canViewDashboard:false,
            canReceiveCirugiaReporteEmails: false,
            canCrearCotizaciones: false,
            canEditarCotizaciones: false,
            canEditarProductosAndVentasCotizaciones: false,
            canVerCotizaciones: false,
            canVerTodasCotizaciones: false,
            canCancelarCirugias: false,
            canReactivarCirugias: false,
            canViewOperacionesMenu: false,
            
    });;

    

    React.useEffect(()=>{

        
        setPermisos({
            canCrearCirugias: canCrearCirugias || false,
            canAgendar: canAgendar ||  false,
            canAceptarCirugias: canAceptarCirugias || false,
            canEditarCirugias: canEditarCirugias || false,
            canSuspenderCirugias: canSuspenderCirugias || false,
            canConcluirCirugias: canConcluirCirugias || false,
            canEditDocumentacion: canEditDocumentacion || false,
            canEditReporte: canEditReporte || false,
            canResetReporte: canResetReporte || false,
            canSeeAllCirugias: canSeeAllCirugias || false,
            canUpdateOperacionesStages: canUpdateOperacionesStages || false,
            canUpdateFacturacionStages: canUpdateFacturacionStages || false,
            canViewFacturacionStages: canViewFacturacionStages || false,
            canAutorizarCirugias: canAutorizarCirugias || false,                        
            canEditarPacientes: canEditarPacientes || false,
            canCrearProductos: canCrearProductos || false,
            canInviteUsers: canInviteUsers || false,
            canAccessBilling: canAccessBilling || false,
            canAsignarEc: canAsignarEc || false,
            canSeeAllReportes: canSeeAllReportes || false,
            canSeeCotizacionAndAutorizacion: canSeeCotizacionAndAutorizacion || false,
            canCrearEditarInvitarDoctores: canCrearEditarInvitarDoctores || false,
            canCrearPacientes: canCrearPacientes || false,
            canViewDashboard: canViewDashboard || false,
            canReceiveCirugiaReporteEmails: canReceiveCirugiaReporteEmails || false,
            canCrearCotizaciones: canCrearCotizaciones || false,
            canEditarCotizaciones: canEditarCotizaciones || false,
            canEditarProductosAndVentasCotizaciones: canEditarProductosAndVentasCotizaciones || false,
            canVerCotizaciones: canVerCotizaciones || false,
            canVerTodasCotizaciones: canVerTodasCotizaciones || false,            
            canCancelarCirugias: canCancelarCirugias || false,
            canReactivarCirugias: canReactivarCirugias || false,
            canViewOperacionesMenu: canViewOperacionesMenu || false,

    
        })


    },[
        canCrearCirugias,
        canAgendar, 
        canAceptarCirugias, 
        canEditarCirugias, 
        canSuspenderCirugias, 
        canConcluirCirugias, 
        canEditDocumentacion,
        canEditReporte,
        canResetReporte,
        canSeeAllCirugias,
        canUpdateOperacionesStages,
        canUpdateFacturacionStages,
        canViewFacturacionStages,
        canAutorizarCirugias,
        canEditarPacientes,                                
        canCrearProductos,
        canInviteUsers,
        canAccessBilling,    
        canAsignarEc,
        canSeeAllReportes,
        canSeeCotizacionAndAutorizacion,
        canCrearEditarInvitarDoctores,    
        canCrearPacientes,
        canViewDashboard,
        canReceiveCirugiaReporteEmails,
        canCrearCotizaciones,
        canEditarCotizaciones,
        canEditarProductosAndVentasCotizaciones,
        canVerCotizaciones,
        canVerTodasCotizaciones,    
        canCancelarCirugias,
        canReactivarCirugias,
        canViewOperacionesMenu,    
        role
    ])


   
    const permisosOperativosCheckbox = [
        {label: "Crear cirugias", name: 'canCrearCirugias', checked: permisos.canCrearCirugias, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canCrearCirugias)},        
        {label: "Agendar cirugias", name: 'canAgendar', checked: permisos.canAgendar, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canAgendar)},        
        {label: "Aceptar cirugias", name: 'canAceptarCirugias', checked: permisos.canAceptarCirugias, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canAceptarCirugias)},
        {label: "Suspender cirugias", name: 'canSuspenderCirugias', checked: permisos.canSuspenderCirugias, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canSuspenderCirugias)},
        {label: "Concluir cirugias", name: 'canConcluirCirugias', checked: permisos.canConcluirCirugias, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canConcluirCirugias)},
        {label: "Cancelar cirugias", name: 'canCancelarCirugias', checked: permisos.canCancelarCirugias, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canCancelarCirugias)},
        {label: "Reactivar cirugias", name: 'canReactivarCirugias', checked: permisos.canReactivarCirugias, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canReactivarCirugias)},
        {label: "Asignar Especialista Clinico", name: 'canAsignarEc', checked: permisos.canAsignarEc, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canAsignarEc)},
        {label: "Crear y Editar reportes", name: 'canEditReporte', checked: permisos.canEditReporte, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canEditReporte)},
        {label: "Ver todos los reportes", name: 'canSeeAllReportes', checked: permisos.canSeeAllReportes, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canSeeAllReportes)},        
        {label: "Editar cirugias", name: 'canEditarCirugias', checked: permisos.canEditarCirugias, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canEditarCirugias)},        
        {label: "Actualizar documentacion", name: 'canEditDocumentacion', checked: permisos.canEditDocumentacion, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canEditDocumentacion)},
        {label: "Ver Cotizacion y Autorizacion", name: 'canSeeCotizacionAndAutorizacion', checked: permisos.canSeeCotizacionAndAutorizacion, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canSeeCotizacionAndAutorizacion)},
        {label: "Ver todas las cirugias", name: 'canSeeAllCirugias', checked: permisos.canSeeAllCirugias, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canSeeAllCirugias)},
        {label: "Recibir email reportes de cirugia", name: 'canReceiveCirugiaReporteEmails', checked: permisos.canReceiveCirugiaReporteEmails, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canReceiveCirugiaReporteEmails)},
    ]

    const permisosAdministrativosCheckbox = [
        {label: "Autorizar cirugia", name: 'canAutorizarCirugias', checked: permisos.canAutorizarCirugias, disabled:rolePermissions[currentRole]?.includes(permissionsDef.canAutorizarCirugias)},
        {label: "Reiniciar consumos y reporte", name: 'canResetReporte', checked: permisos.canResetReporte, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canResetReporte)},
        {label: "Crear pacientes", name: 'canCrearPacientes', checked: permisos.canCrearPacientes, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canCrearPacientes)},
        {label: "Editar pacientes creados", name: 'canEditarPacientes', checked: permisos.canEditarPacientes, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canEditarPacientes)},        
        {label: "Crear productos", name: 'canCrearProductos', checked: permisos.canCrearProductos, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canCrearProductos)}, 
        {label: "Crear, editar e invitar doctores", name: 'canCrearEditarInvitarDoctores', checked: permisos.canCrearEditarInvitarDoctores, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canCrearEditarInvitarDoctores)},        
        {label: "Invitar usuarios", name: 'canInvitarUsers', checked: permisos.canInviteUsers, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canInviteUsers)},
        {label: "Actualizar operaciones", name: 'canUpdateOperacionesStages', checked: permisos.canUpdateOperacionesStages, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canUpdateOperacionesStages)},
        {label: "Actualizar facturacion", name: 'canUpdateFacturacionStages', checked: permisos.canUpdateFacturacionStages, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canUpdateFacturacionStages)},        
        {label: "Ver status facturacion", name: 'canViewFacturacionStages', checked: permisos.canViewFacturacionStages, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canViewFacturacionStages)},        
        {label: "Ver menu operaciones", name: 'canViewOperacionesMenu', checked: permisos.canViewOperacionesMenu, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canViewOperacionesMenu)},
    ]

    const permisosCotizacionesCheckbox = [
        {label: "Crear cotizaciones", name: 'canCrearCotizaciones', checked: permisos.canCrearCotizaciones, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canCrearCotizaciones)},
        {label: "Editar cotizaciones", name: 'canEditarCotizaciones', checked: permisos.canEditarCotizaciones, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canEditarCotizaciones)}, 
        {label: "Editar productos y ventas", name: 'canEditarProductosAndVentasCotizaciones', checked: permisos.canEditarProductosAndVentasCotizaciones, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canEditarProductosAndVentasCotizaciones)},
        {label: "Ver cotizaciones", name: 'canVerCotizaciones', checked: permisos.canVerCotizaciones, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canVerCotizaciones)},       
        {label: "Ver todas las cotizaciones", name: 'canVerTodasCotizaciones', checked: permisos.canVerTodasCotizaciones, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canVerTodasCotizaciones)},
    ]

      


    const permisosFinancierosCheckbox = [
        {label: "Facturacion y Pagos", name: 'canAccessBilling', checked: permisos.canAccessBilling, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canAccessBilling)},
        {label: "Ver tablero general", name: 'canViewDashboard', checked: permisos.canViewDashboard, disabled: rolePermissions[currentRole]?.includes(permissionsDef.canViewDashboard)},                
        
    ]


    const onClickActualizar = (role)=>{

        // 1st create permisosArray. Identify role, only get permisos not in role. 
        let permisosArray = []
        for(let permiso in permisos){
            
            //if permission is part of role dont add to permission array. 
            if(rolePermissions[currentRole].includes(permissionsDef[permiso])){
                continue
            }

            if(permisos[permiso] === true){
                permisosArray.push(permissionsDef[permiso])
            } 
        }

        //console.log(permisosArray)

        const accion = accionMaker('Permisos actualizados')
        const roleInfo ={
          userId: user?._id,
          role: role,
          permissions: permisosArray,
          accion: accion
        }
        //console.log(role)
        console.log(roleInfo)
        dispatch(updateCompanyUsers("update/role",roleInfo))
      }

    const onChangePermissions = (event)=>{
        setPermisos(prevState=>({...prevState, [event.target.name]:event.target.checked}))

    }

    

    

    return (
        <ExternalWrapper>
            <Card>
                
                <CardBody>
                    <Wrapper>
                            <InnerWrapper>
                            <h4>Role</h4>
                            
                            {
                                options.map((option, index)=>{                            
                                    return(

                                        <FormControlRadio key={`${option} ${index}`}>
                                        <Radio 
                                            label={option.label} 
                                            id={option.label} 
                                            name={option.name} 
                                            value={option.value} 
                                            onChange={onChange}
                                            checked={role?.hasOwnProperty(option.value) }
                                        />
                                    </FormControlRadio>

                                    )
                                    
                            })
                            }                                        
                        </InnerWrapper>
                        <InnerWrapper>
                        <h4>Permisos Operativos</h4>  

                        {
                                permisosOperativosCheckbox.map((option, index)=>{     
                                    
                                    //console.log(option)
                                    return(

                                    <FormControlCheckbox 
                                        key={`${option} ${index}`}
                                        disabled={option.disabled} 
                                        id={option.name}
                                    >
                                        <Checkbox label={option.label} name={option.name} checked={option.checked} disabled={option.disabled} onChange={onChangePermissions}/>
                                    </FormControlCheckbox>

                                    )
                                    
                            })
                            }                            
                        </InnerWrapper>   
                       
                        <InnerWrapper>
                        <h4>Permisos Administrativos</h4>  
                        
                        {
                                permisosAdministrativosCheckbox.map((option, index)=>{     
                                    
                                    //console.log(option)
                                    return(

                                    <FormControlCheckbox 
                                        key={`${option} ${index}`}
                                        disabled={option.disabled} 
                                        id={option.name}
                                    >
                                        <Checkbox label={option.label} name={option.name} checked={option.checked} disabled={option.disabled} onChange={onChangePermissions}/>
                                    </FormControlCheckbox>

                                    )
                                    
                            })
                            }                            
                        </InnerWrapper>  
                        {<InnerWrapper>
                        <h4>Permisos Cotizaciones</h4>  
                        
                        {true &&
                                permisosCotizacionesCheckbox.map((option, index)=>{     
                                    //console.log(option)
                                    return(

                                    <FormControlCheckbox 
                                        key={`${option} ${index}`}
                                        disabled={option.disabled} 
                                        id={option.name}
                                    >
                                        <Checkbox label={option.label} name={option.name} checked={option.checked} disabled={option.disabled} onChange={onChangePermissions}/>
                                    </FormControlCheckbox>

                                    )
                                    
                            })
                            }                            
                        </InnerWrapper>                            }
                        { currentRole=== 'isAdmin' &&
                            <InnerWrapper>
                            <h4>Permisos Financieros</h4>  
                            
                            {
                                    permisosFinancierosCheckbox.map((option, index)=>{     
                                        
                                        //console.log(option)
                                        return(
    
                                        <FormControlCheckbox 
                                            key={`${option} ${index}`}
                                            disabled={option.disabled} 
                                            id={option.name}
                                        >
                                            <Checkbox label={option.label} name={option.name} checked={option.checked} disabled={option.disabled} onChange={onChangePermissions}/>
                                        </FormControlCheckbox>
    
                                        )
                                        
                                })
                                }                            
                            </InnerWrapper>
                        }    
                        

                    </Wrapper>
                </CardBody>
                <StyledCardActions>
                    <Button primary onClick={()=>onClickActualizar(role)}>Aplicar</Button>
                </StyledCardActions>
            </Card>
            

        </ExternalWrapper>
    )
}


const ExternalWrapper = styled.div`
    display: flex;        
    gap: 8px;


`


const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media ${p=>p.theme.media.tablet}{

        & > div{
            border-right: 1px solid ${p=>p.theme.palette.grey[300]};
        }
                
        & div:last-child{
            border-right: none;
        }

    }

    
`
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 16px;

    
    
`

const StyledCardActions = styled(CardActions)`
    justify-content: flex-end;
`



export default Roles;