



const calcTimeAgo = (dateString)=>{

  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const WEEK = DAY * 7
  const MONTH = DAY * 30
  const YEAR = DAY * 365


  let timeAgoMili = new Date() - new Date(dateString)
  let timeAgoSeconds = Math.round(timeAgoMili/1000)
  let timeAgoMinutes = Math.round(timeAgoSeconds/60)
  let timeAgoHours = Math.round(timeAgoMinutes/60)
  let timeAgoDays = Math.round(timeAgoHours/24)
  let timeAgoMonths = Math.round(timeAgoDays/30)
  let timeAgoYears = Math.round(timeAgoMonths/12)

  if(timeAgoSeconds < MINUTE){
    return `Hace momentos`;
  }else if(timeAgoSeconds < HOUR){
    if(timeAgoMinutes < 2){
      return `Hace ${timeAgoMinutes} minuto`
    }else{
      return `Hace ${timeAgoMinutes} minutos`
    }

  }else if(timeAgoSeconds < DAY){
      if(timeAgoHours<2){
          return `Hace ${timeAgoHours} hora`
      }else{
        return `Hace ${timeAgoHours} horas`
      }

  }else if (timeAgoSeconds < MONTH){
    if(timeAgoDays < 2){
      return `Hace ${timeAgoDays} dia`
    }else{
      return `Hace ${timeAgoDays} dias`
    }
  }
  else if(timeAgoSeconds < YEAR){
    if(timeAgoMonths < 2){
      return `Hace ${timeAgoMonths} mes`
    }else{
      return `Hace ${timeAgoMonths} meses`
    }
  }
  /*else if(timeAgoSeconds < YEAR){
    if(timeAgoYears < 2){
      return `Hace ${timeAgoYears} mes`
    }else{
      return `Hace ${timeAgoYears} meses`
    }
  }*/
  else{
    if(timeAgoYears < 2){
      return `Hace ${timeAgoYears} año`
    }else{
      return `Hace ${timeAgoYears} años`
    }
  }
}

export default calcTimeAgo;
