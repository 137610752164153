import React from 'react';
import { FormWrapper, FormBody} from '../Atoms/Forms';
import Productos from '../cirugias/Molecules/ProductosModificationInterface';
import useCirugia from '../../hooks/useCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';


const AddProductos = ({
    cirugiaId,
    close,
    editCantidad,
    deleteProducts
})=>{

    const isLaptop = useMediaQuery('laptop');    
    const cirugia = useCirugia(cirugiaId);
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);

    
    //array used to update database
    const [productosArray, setProductosArray] = React.useState([])
    const accionMaker = useAccionMaker();

    //function to update product array sent to database. 
    const onChangeProducts = (selectedProduct)=>{
        setProductosArray(prevState=>([...prevState, selectedProduct]))
    }

    //function to manage deletion of products
    const onDeleteProducts = (index)=>{
        let newProductArray = [...productosArray];
        newProductArray.splice(index,1);
        setProductosArray(newProductArray)
    }

    const onSubmit = (event) =>{
        event.preventDefault();
        const accion = accionMaker(`Productos modificados`);
        let infoProductos = {
          productos: [...cirugia.productos, ...productosArray], // array with only producto Id used to update database
          cirugiaId: cirugiaId,
          accion: accion
        }
        
        updateCirugia("update-productos",infoProductos)
      
    }


    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormBody isLaptop={isLaptop} >
                <Productos
                    onChangeProducts={onChangeProducts}
                    onDeleteValuesProducts={onDeleteProducts}
                    cirugiaId={cirugiaId}
                    editCantidad={editCantidad}
                    deleteProducts={deleteProducts}
                    dontShowCirugiaProductos
                    
                />
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={productosArray.length === 0}
            />
        </FormWrapper>
        
    )
}




export default AddProductos;