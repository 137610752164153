import formatTime from "./timeFormatter";
import calcTimeAgo from "./calcTimeAgo";


const dateFormatDR = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${day}/${month}/${year}`;    
}

export const dateAndTimeFormatDR = (date) => {
    const d = new Date(date);
    const options = {
      timeZone: 'America/Santo_Domingo',
    };
    const formatter = new Intl.DateTimeFormat('en-US', {
      ...options,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  
    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },      
    ] = formatter.formatToParts(d);

    const formattedTime = formatTime(date)
    const timeAgo = calcTimeAgo(date)

    return `${day}/${month}/${year}, ${formattedTime}, ${timeAgo} `;
  };

export default dateFormatDR;