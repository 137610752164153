import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';


const StatusChip = ({info})=>{
    const theme = useTheme()

    let status;
    let chipStyle={
    }

     //if already accepted do not show status
     if(info?.agendada && info?.aceptada && !info?.suspendida && !info?.cancelada){
        return null;
    }

    if(info?.agendada && !info.aceptada ) {
        status="Solicitada";
        chipStyle={}
        chipStyle.color = theme.palette.warning.light
    };

    if(info?.suspendida) {
        status = "Suspendida";
        chipStyle={}
        chipStyle.error = true;
    };
    
    if(info?.realizada) {
        status = "Realizada";
        //reset chipStyle
        chipStyle={}
        chipStyle.success = true;
    }

    if(info?.rechazada) {
        status = "Rechazada";
        //reset chipStyle
        chipStyle={}
        chipStyle.error = true;
    }

    if(info?.cancelada) {
        status = "Cancelada";
        //reset chipStyle
        chipStyle={}
        chipStyle.error = true;
    }

   

    //if not requested, suspended or done do not show status
    if(!info?.suspendida && !info?.agendada && !info?.realizada && !info?.rechazada && !info?.cancelada){
        return null;
    }


    return(        
        <Chip outline label={status} {...chipStyle}/>
    )
}



export default StatusChip;