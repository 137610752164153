import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    cirugias:[],
    search: null,
    nextCursor: null,
    openFilter: false,
    isLoading: true,
    isLoadingUpdate: false,
    scrollPosition: 0,
    searchValue:"",
    success: false
}

export const cirugiaSlice = createSlice({
    name:"cirugias",
    initialState,
    reducers: {
        loading(state,action){
            state.isLoading = true;
        },
        loadingUpdate(state,action){
            state.isLoadingUpdate = true;
        },
        loadingUpdateReset(state,action){
            state.isLoadingUpdate = false;
        },
        addCirugia(state, action){
            state.cirugias = action.payload?.cirugias;
            state.nextCursor = action.payload?.nextCursor;
            state.isLoading = false;
        },
        addNextCirugias(state,action){                        
            state.cirugias = [...state.cirugias, ...action.payload.cirugias]
            state.nextCursor = action.payload?.nextCursor;    
            state.isLoadingUpdate = false;        
        },
        addUpdatedCirugia(state,action){     
            state.cirugias.forEach((cirugia, index) => {
                if(cirugia._id === action.payload._id){                 
                    state.cirugias[index] = action.payload
                }
                return                
            });
            state.isLoadingUpdate = false;
        }, 
        resetCirugias(state,action){
            state.cirugias=[];
            state.isLoading=true;
        },
        setScrollPosition(state,action){
            state.scrollPosition= action.payload
        },
        resetScrollPosition(state,action){
            state.scrollPosition = 0
        },
        setSearchValueCirugias(state,action){
            state.searchValue = action.payload
        },
        setSuccess(state,action){
            console.log('set succes invoked')
            state.success = action.payload;            
        }    
    }
})

const {actions, reducer} = cirugiaSlice

export const {
        loading, 
        loadingUpdate, 
        loadingUpdateReset, 
        addCirugia, 
        addNextCirugias, 
        addUpdatedCirugia, 
        resetCirugias,
        setScrollPosition,
        resetScrollPosition,
        setSearchValueCirugias,
        setSuccess
    } = actions;

export default reducer;