import styled from '@emotion/styled';
import useMediaQuery from '../../hooks/useMediaQuery';

const LabelValue = ({label, value, paddingBottom})=>{

    const isPhone = useMediaQuery('phone');

    return(
        <Container isPhone={isPhone} paddingBottom={paddingBottom}>
            <Item>
                <Label>{label}</Label>
            </Item>
            <Item>
                <Value>{value}</Value>                
            </Item>
        </Container>

    )
}


const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex:1;
    padding-bottom: ${p=>p.paddingBottom? p.paddingBottom : '8px'};
    min-width: ${p=>p.isPhone?'50%': 'none'};   
    
`

const Item = styled.div`

`

const Label = styled.span`
font-size: .75rem;
color: ${p =>p.theme.palette.grey[600]};
`

const Value = styled.span`
color: black;
font-size: .875rem;
`

export default LabelValue;