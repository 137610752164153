import React, { Suspense } from 'react';
import { ThemeProvider } from '@mui/system';
import customTheme from './util/Theme';
import {BrowserRouter, Routes, Route} from "react-router-dom";
//import Cirugias from './components/cirugias/Cirugias';
import Cirugia from './components/cirugias/Cirugia';
import DoctorsList from './components/Doctors/DoctorsList';
import DoctorProfile from './components/Doctors/DoctorProfile';
import CentrosList from './components/Centros/CentrosList';
import CentroProfile from './components/Centros/CentroProfile';
import UserProfile from './components/User/UserProfile';
import Activity from './components/activity/Activity';
import Admin from './components/Admin/Admin';
import Users from './components/Admin/Users/Users';
import LandingPage from './components/landing/LandingPage';
import Home from './components/home/Home';
import LoginLayout from './components/auth/LoginLayout';
import RequireAuth from './components/auth/RequireAuth';
import RequireAuthMd from './components/md/auth/RequireAuthMd';
import UsersDetails from './components/Admin/Users/UsersDetails';
//import CirugiasMd from './components/md/cirugias/CirugiasMd';
import RegisterMd from './components/landing/RegisterMd';
import ActivateAccount from './components/landing/ActivateAccount';
import CirugiasSearch from './components/cirugias/CirugiasSearch';
import Productos from './components/Admin/Productos/Productos';
import MdUsers from './components/md/mdUsers.js/MdUsers';
import CasaComercial from './components/md/casaComercial/CasaComercial';
import CirugiasSearchMd from './components/md/cirugias/Molecules/CirugiasSearchMd';
import MdUserProfile from './components/md/profile/MdUserProfile';
import { PermissionsContextProvider } from './context/permissions-context';
import PacientesList from './components/pacientes/PacientesList';
import Fallback from './components/Layout/Fallback';
import RegisterCompany from './components/landing/RegisterCompany';
import WelcomeMd from './components/landing/WelcomeMd'
import ActivateCompanyAccount from './components/landing/ActivateCompanyAccount';
import Login from './components/auth/Login';
import EmailLinkLoginRequest from './components/auth/EmailLinkLoginRequest';
import EmailLinkLogin from './components/auth/EmailLinkLogin';
import ActivateCompanyUser from './components/landing/ActivateCompanyUser';
import ActivateMdUser from './components/landing/ActivateMdUser';
import ReporteGeneral from './components/reporte/ReporteGeneral';
import HomeMdUser from './components/md/home/HomeMdUser';
import Billing from './components/Admin/billing/Billing';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentSuccess from './components/Admin/billing/PaymentSuccess';
import { SubscriptionContextProvider } from './context/subscription-context';
import ReturnFromPortal from './components/Admin/billing/ReturnFromPortal';
import BillingRedirect from './components/Admin/billing/BillingRedirect';
import SendAccountInvite from './components/master/SendAccountInvite';
import CrearCotizaciones from './components/cotizaciones/CrearCotizaciones';
import Clients from './components/clients/Clients';
import GhostUserLogin from './components/auth/GhostUserLogin';
import Operaciones from './components/operaciones/Operaciones';
import GestionAgendaCirugias from './components/operaciones/GestionAgendaCirugias';
import ActivosFijos from './components/operaciones/activosFijos/ActivosFijos';



//const RequireAuth = React.lazy(()=>import('./components/auth/RequireAuth'))
const Cirugias = React.lazy(()=>import('./components/cirugias/Cirugias'))
const CirugiasMd = React.lazy(()=>import('./components/md/cirugias/CirugiasMd'))
const Cotizaciones = React.lazy(()=>import('./components/cotizaciones/Cotizaciones'))
let STRIPE_PUBLIC_KEY = ''

//check if in production
if(process.env.NODE_ENV === 'production'){
  STRIPE_PUBLIC_KEY = 'pk_live_51JdIF0ISWOBZhKEKgXuEtidfAmlrpjYVZ3VLesNFeGMQqCl8U2pBRatVib64ZSi4nen6imGExINIIz4QJekRjNbj00T5mLseQo'
  
}else{
  STRIPE_PUBLIC_KEY = 'pk_test_51JdIF0ISWOBZhKEK4MS74OkTwlLe4n9OZoj5g6CeWi6axrZ41D0FdwSKZI0k9lJ6vBdJwtq937HhLJgceIEmsRJh00fW5ck1Ah'
}
    

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

function App() {

  
  return (
    <ThemeProvider theme={customTheme}>
      <PermissionsContextProvider>
        <Elements stripe={stripePromise}>
          <SubscriptionContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/register-company" element={<RegisterCompany/>}/>
                <Route path="/md-register" element={<WelcomeMd/>}/>
                <Route path="/activate/:token" element={<ActivateAccount/>}/>
                <Route path="/activate-company-account/:token" element={<ActivateCompanyAccount/>}/>
                
                <Route path="/auth" element={<LoginLayout/>}>
                  <Route path="login" element={<Login/>}/>
                  <Route path="email-link-login-request" element={<EmailLinkLoginRequest/>}/>                
                  <Route path="email-link-login/:token" element={<EmailLinkLogin/>}/>
                  <Route path="activate-company-user/:token" element={<ActivateCompanyUser/>}/>
                  <Route path="activate-mdUser/:token" element={<ActivateMdUser/>}/>
                </Route>
                
                <Route path="/app" element={<RequireAuth/>}>
                    <Route path="/app" element={<Home/>}/>
                    <Route path="activity" element={<Activity/>}/>
                    <Route path="doctors" element={<DoctorsList/>}/>
                    <Route path="doctors/:doctorId" element={<DoctorProfile/>}/>
                    <Route path="centros" element={<CentrosList/>}/>
                    <Route path="centro/:centroId" element={<CentroProfile/>}/>
                    <Route path="cirugias" element={<Suspense fallback={<Fallback/>}><Cirugias/></Suspense>}/>
                    <Route path="cirugias/:cirugiaId" element={<Cirugia/>}/>
                    <Route path="cirugias/search" element={<CirugiasSearch/>}/>
                    <Route path="profile" element = {<UserProfile/>}/>                  
                    <Route path="pacientes" element = {<PacientesList/>}/>            
                    <Route path="admin" element = {<Admin/>}/>            
                    <Route path="admin/users" element={<Users/>}/>
                    <Route path="admin/users/:userId" element={<UsersDetails/>}/>
                    <Route path="admin/productos" element={<Productos/>}/>
                    <Route path="admin/facturacion" element={<BillingRedirect/>}/>
                    <Route path="admin/facturacion/exitosa" element={<PaymentSuccess/>}/>
                    <Route path="admin/facturacion/return-portal" element={<ReturnFromPortal/>}/>
                    <Route path="reporte" element={<ReporteGeneral/>}/>
                    <Route path="activar-cuenta" element={<SendAccountInvite/>}/>
                    <Route path="cotizaciones" element={<Suspense fallback={<Fallback/>}><Cotizaciones/></Suspense>}/>
                    <Route path="crear-cotizacion" element={<CrearCotizaciones/>}/>
                    <Route path="clients" element={<Clients/>}/>
                    <Route path="ghostUsers" element={<GhostUserLogin/>}/>
                    <Route path="operaciones" element={<Suspense fallback={<Fallback />}><Operaciones/></Suspense>}/>
                    <Route path="operaciones/gestionAgendaCirugias" element={<GestionAgendaCirugias/>} />
                    <Route path="operaciones/activosFijos" element={<ActivosFijos/>} />
                    
                    
                </Route>
                <Route path="/md-app" element={<RequireAuthMd/>}>
                    <Route path="/md-app" element={<HomeMdUser/>}/>              
                    <Route path="cirugias" element={<Suspense fallback={<Fallback/>}><CirugiasMd/></Suspense>}/>              
                    <Route path="cirugias/search" element={<CirugiasSearchMd/>}/>
                    <Route path="md-users" element={<MdUsers/>}/>
                    <Route path="casa-comercial" element={<CasaComercial/>}/>
                    <Route path="profile" element = {<MdUserProfile/>}/>              
                </Route>                                                  
              </Routes>             
            </BrowserRouter>
          </SubscriptionContextProvider>
        </Elements>        
      </PermissionsContextProvider>
    </ThemeProvider>
  );
}



export default App;
