import React from 'react';
import styled from '@emotion/styled';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import { FormControl, TextField } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';

import useCommentMaker from '../../../hooks/useCommentMaker';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useCirugia from '../../../hooks/useCirugia';
import useUpdateCirugia from '../../../hooks/useUpdateCirugia';



const Autorizar = ({
    cirugiaId,
    close,
    cirugia
})=>{

    let _cirugia = useCirugia(cirugiaId)

    if(_cirugia){
        cirugia = _cirugia
    }
 
    const [status, setStatus] = React.useState(cirugia?.pago?.statusPago);
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);  
    const commentMaker = useCommentMaker(comments);
    const accionMaker = useAccionMaker();

   
    const onChange =(event)=>{
        setStatus(event.target.value)
    }


    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Autorizacion de la cirugia cambiada a ${status}`)
    
        let infoStatus = {
          statusPago: status,
          cirugiaId: cirugiaId,
          comentarios: commentObj,
          accion: accion
        }
             
       updateCirugia('update-payment-status', infoStatus)
      }



    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Autorizar Cirugia</FormTitle>
            <FormBody>
            <FormControl fullWidth>
            <CustomInputMuiLabel>{'Autorizacion'}</CustomInputMuiLabel>
            <Select
                labelId={"Autorizacion"}
                id={'Autorizacion'}
                value={status}                
                onChange={onChange}
                name="status"
            >
                <MenuItem value="Ok">Ok</MenuItem>
                <MenuItem value="Ok OR">Ok OR</MenuItem>
                <MenuItem value="No">No</MenuItem>                
            </Select>
            </FormControl>
            <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />


            </FormBody>
            <FormActionsBasic
                type="submit"
                as='button'
                close={close}                          
                successEvent={successEvent}
                loading={isLoading}

            />

        </FormWrapper>
    )
}



export default Autorizar;