import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import GridLoader from 'react-spinners/GridLoader'

const Fallback = ()=>{

    const theme = useTheme();

    return (
        <Wrapper>
            <Logo src='/img/xirugias_logo.png' alt="company logo"/>
            <GridLoader color={theme.palette.primary.light}/>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: #EDF5FB ;
    display: flex;
    flex-direction: column;
    align-items: center;    
    gap: 50px;
`

const Logo = styled.img`
    margin-top: 25%;
    margin-bottom: 25%;
    max-width: 200px;

`


export default Fallback;