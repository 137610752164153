import React from "react";
import styled from '@emotion/styled';
import { useDispatch } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { errorMessage } from "../../../app/messagesReducer";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { apiUrl } from "../../../shared/url";
import CardError from "../../Atoms/CardError";
import CardSuccess from "../../Atoms/CardSuccess";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms";
import PasswordInput from "../../Atoms/PasswordInput";
import { isPassword, validate } from "../../../shared/validators";



const ChangePassword = ({
    close
})=>{
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordMatch, setPasswordMatch] = React.useState(false)

    const [errors, setErrors] = React.useState({        
      })

    React.useEffect(()=>{
        if(password.length>0 && password === confirmPassword){
            if(passwordError){
               setPasswordError(false)     
            }
            setPasswordMatch(true)
        }else{
            setPasswordMatch(false)
        }

    },[password,confirmPassword, passwordError])


    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setPassword(value);
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }


    const changePassword = async (event)=>{

        event.preventDefault();
        if(password !== confirmPassword) return setPasswordError(true)

        setIsLoading(true)
        const options = createOptions('PUT', {password})                        
        
        try {
            let response = await goFetch(`${apiUrl}users/update-password/${user._id}`, options);
            responseStateChanges(response, ()=>{}, ()=>close())                                
        } catch (error) {
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }

    }


    const onBlur = (event, validators)=>{
        console.log('on blur called')
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target        
        const error = validate(value, validators)        
        setErrors(prevState=>({...prevState, [name]:error}))
        return error
    }

    const onBlurConfirmPassword = (event)=>{
        if(password !== confirmPassword){
            setPasswordError(true)

        }
    }


    return (
        <FormWrapper onSubmit={changePassword}>
            <FormTitle>Cambiar contraseña</FormTitle>
            <StyledFormBody>
                <PasswordInput 
                    label="Contraseña nueva"
                    name="password" 
                    value={password} 
                    onChange = {(event)=>onChangeValues(event, [isPassword], true)}
                    onBlurValidation={(event)=>onBlur(event, [isPassword])}
                    helperText={errors.password}
                    error={errors.password}
                />
                <PasswordInput 
                    label="Confirmar contraseña"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange = {(event)=>setConfirmPassword(event.target.value)}    
                    onBlurValidation={(event)=>onBlurConfirmPassword(event)}                
                />
                {passwordError && <CardError fontSize={"0.875rem"}>Las contraseñas no son iguales</CardError>}
                {passwordMatch && !errors.password && <CardSuccess>Las contraseñas son iguales</CardSuccess>}
            </StyledFormBody>
            <FormActionsBasic
                close={close}
                type="submit"
                as="button"
                successText="Guardar"
                cancelText="Volver"                            
                loading={isLoading}
                successEvent={successEvent}
                disabledSuccess={errors.password || (password !== confirmPassword) || !password}
            >
            </FormActionsBasic>
        </FormWrapper>
    )


}


const StyledFormBody = styled(FormBody)`
    margin-bottom: 48px;
    gap: 24px;
`


export default ChangePassword;