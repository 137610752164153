import { useSelector } from "react-redux";


export const useAccionMaker =(accion)=>{
    const {user} = useSelector(state=>state.auth);
    const accionMaker = (accion)=>{
      let accionObj =  {
        _id: user._id,
        firstname: user.firstname,
        lastname: user.lastname,
        profilePicture: user.profilePicture || "",
        accion: accion
      }
      console.log(accionObj)
      return accionObj
    }
    
    return accionMaker;

    
  
  }