import styled from '@emotion/styled/macro';


const DoctorPermissionsList = ()=>{

    return(
        <ListWrapper>
                    <TextBold>{`Podra:`}</TextBold>
                    <StyledList>
                        <StyledListItem>Ver hora y fecha de Cirugia</StyledListItem>
                        <StyledListItem>Ver nombre de paciente y Centro.</StyledListItem>
                        <StyledListItem>Tipo de pago (privado o nombre de la ARS)</StyledListItem>
                        <StyledListItem>Solicitar agendar la cirugia</StyledListItem>
                        <StyledListItem>Suspender la cirugia</StyledListItem>
                        <StyledListItem>Consumos reportados de cada producto</StyledListItem>
                        <StyledListItem>Calificar y comentar sobre calidad del servicio</StyledListItem>
                        <StyledListItem>Ver indicacion</StyledListItem>
                    </StyledList>
                    <br/>
                    <TextBold>{`No Podra:`}</TextBold>
                    <StyledList>
                        <XListItem>Ver Reporte de Cirugia creado por Especialista Clinico</XListItem>
                        <XListItem>Ver los comentarios internos de la compañia</XListItem>
                        <XListItem>Ver el historial de acciones de cada cirugia</XListItem>
                        <XListItem>Ver documentos de aprobacion o cotizacion</XListItem>
                        
                    </StyledList>
        </ListWrapper>
    )

}



const Text = styled.p`
    margin: 0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]}
`
const TextBold = styled(Text)`
    margin: 0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]};
    font-weight: 600;

`

const ListWrapper = styled.div`
    align-self: center;
`

const StyledList = styled.ul`
    list-style:none;
    display:inline;
    padding-left: 24px;
    margin:0;
    margin-bottom: 12px;
        
    @media ${p=>p.theme.media.tablet}{
        padding-left: revert;
    }

`


const StyledListItem = styled.li`    
    padding-bottom: 8px;
    position:relative;
    padding-left: 12px;
    margin-left: 12px;
    &::before{
        content: "\\2713";
        padding-right: 8px;
        color: ${p=>p.theme.palette.success.light};        
        position:absolute;
        left: -12px;
    }
`

const XListItem = styled.li` 
    padding-bottom: 8px;
    position:relative;
    padding-left: 12px;
    margin-left: 12px;
    &::before{
        content: "\\2715";
        padding-right: 8px;
        color: ${p=>p.theme.palette.error.main};
        position:absolute;
        left: -12px;        
    }
`





export default DoctorPermissionsList