import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon} from '@fortawesome/free-solid-svg-icons'
import { CardWrapper } from '../Atoms/Card';
import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import React from 'react';
import getRestrictedCirugias from '../../shared/getRestrictedCirugias';
import { modifyFilter, setRestrictions } from '../../app/filterReducer';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import getDate from '../../shared/getDate';



const greeting = ()=>{

    const time = new Date().getHours()
    
    if (time < 12) {
        return {
            text:"Buenos dias",
            icon: faSun,
            color: "hsl(54, 91%, 52%)"
        }
      } else if (time < 18) {
        return {
            text:"Buenas tardes",
            icon: faSun,
            color:"hsl(54, 91%, 52%)"
        }
      } else {
        return {
            text:"Buenas noches",
            icon: faMoon,
            color:"hsl(254, 80%, 15.4%, .4)",            
        }
      }
}


const UpdateBoxComponent = ({
    title,
    result,
    category,
    color,
    border
})=>{

    return(
        <UpdateBox style={{"--box-color": color, "--box-border": border}}>
            <BoxItem >
                <UpdateTitle>{title}</UpdateTitle>
            </BoxItem>
            <BoxItem>
                <Result>{result}</Result>
            </BoxItem>
                
            <BoxItem>
                <Category>{category}</Category>
            </BoxItem>
            
        </UpdateBox>

    )
}



const Home = ()=>{

    const {user} = useSelector(state=>state.auth)        
    const greet = greeting();
    const theme = useTheme()
    const dispatch = useDispatch();
    const filter = useSelector(state=>state.filter.filter);
    const [data, setData] = React.useState({});

    React.useEffect(()=>{
        if(user){
            const [restrictedVentasId, restrictedECId] =  getRestrictedCirugias()
            dispatch(setRestrictions({restrictedVentasId, restrictedECId}))
        }
        
    },[user, dispatch])


    React.useEffect(()=>{

        const getData = async()=>{
            const options = createOptions('POST',{userId:user._id});
            try {
                const data = await goFetch(`${apiUrl}cirugias/home-data`, options);
                setData(data);    
            }catch (error) {
                console.log(error)   
            }            
        }

        getData()

    },[])

    console.log(data)

    const [today, tomorrow, yesterday, startOfDay, endOfDay] = getDate(); //date are in JSON string due to redux limitation, does not allow for date objects in store


    return(
        <OuterWrapper>

        
            <Wrapper>
                <Header>
                    <FontAwesomeIcon icon={greet.icon} size="2x" color={greet.color}/>
                    <Title>{`${greet.text} ${user?.firstname}`}</Title>
                </Header>
                <Body>                    
                    <BoxLink 
                        to="cirugias" 
                        onClick={()=>dispatch(modifyFilter({...filter, today}))}
                    >
                        <UpdateBoxComponent title="Hoy" result={data?.todayCount} category={"Cirugias"} color={"hsla(123, 79%, 50%, .1)"} border={theme.palette.success.light}/>
                    </BoxLink>
                
                    <BoxLink to="cirugias" 
                        onClick={()=>dispatch(modifyFilter({...filter}))}
                        >
                        <UpdateBoxComponent title="Hoy" result={data?.porConfirmarCount} category={"Cirugias por Responder"} color={"hsla(43, 95%, 81%, 1)"} border={theme.palette.warning.light}/>                         
                    </BoxLink>
                                        
                    <BoxLink 
                        to="cirugias" 
                        onClick={()=>dispatch(modifyFilter({...filter, infoRealizada: true, sinReporte:true}))}
                    >
                        <UpdateBoxComponent title="Hoy" result={data?.reportesPendientesCount} category={"Reportes Pendientes"} color={data?.reportesPendientesCount > 0 ? "hsla(0, 95%, 83%, 1)" :"hsla(123, 79%, 50%, .1)"} border={data?.reportesPendientesCount > 0 ? theme.palette.error.light : theme.palette.success.light}/>
                    </BoxLink>
                    <BoxLink 
                        to="cirugias" 
                        onClick={()=>dispatch(modifyFilter({...filter, infoRealizada: true, yesterday}))}
                    >
                        <UpdateBoxComponent title="Ayer" result={data?.yesterdayCount} category={"Cirugias Realizadas"} color={"hsla(123, 79%, 50%, .1)"}/>
                    </BoxLink>
                    <BoxLink 
                        to="cirugias" 
                        onClick={()=>dispatch(modifyFilter({...filter, infoRealizada: true, mesActual:true}))}
                    >
                        <UpdateBoxComponent title="Este mes" result={data?.currentMonthCount} category={"Cirugias Realizadas"} color={"#ffff"}/>
                    </BoxLink>
                    <BoxLink 
                        to="cirugias" 
                        onClick={()=>dispatch(modifyFilter({...filter, infoRealizada: true, mesAnterior:true}))}
                    >
                        <UpdateBoxComponent title="Mes anterior" result={data?.lastMonthCount} category={"Cirugias Realizadas"} color={"#ffff"}/>
                    </BoxLink>
                </Body>
            </Wrapper>
        </OuterWrapper>
    )
}


const OuterWrapper = styled.div`
    display:flex;
    justify-content: center;
`

const Wrapper = styled.div`
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 1000px;
    
`

const Header = styled(CardWrapper)`
    flex-direction: row;
    justify-content: flex-start;
    background-color: #ffff;
    padding: 8px 8px 8px 12px;
    display:flex;
    gap: 12px;
    align-items: center;
    height: 80px;
    margin:0px;
    border: 1px solid ${p=>p.theme.palette.grey[500]}
    
`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    color: ${p=>p.theme.palette.grey[800]}
`

const Body = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    grid-template-rows: repeat(auto-fill, minmax(167px, auto));
    gap: 8px;
    


`
const UpdateBox = styled(CardWrapper)`
    width: 175px;
    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding:8px;
    margin:0px;
    justify-self: center;
    background-color: var(--box-color);
    border: 1px solid var(--box-border);
`


const UpdateTitle = styled.p`
    margin: 0;
    font-size:0.875rem;
    flex:1;
    color: ${p=>p.theme.palette.grey[900]};
    
`

const Result = styled.p`
    margin: 0;
    font-size: 1.5rem;
    flex:2;
    color: ${p=>p.theme.palette.grey[900]};
    
`

const Category = styled.p`
    margin: 0;
    font-size: 0.875rem;
    flex:1;
    color: ${p=>p.theme.palette.grey[900]}
    
`

const BoxItem = styled.div`
    flex:1;
    display:flex;
    align-items:center;
`


const BoxLink = styled(Link)`
    text-decoration: none;
`

export default Home;