import styled from '@emotion/styled';



const ConfirmarRegistro = ({
    values
})=>{


    return (
        <Wrapper>
            <Text>{`Muchas gracias! Estamos muy contentos que ${values.companyName} se una a Xirugias. Por favor revisa la siguiente informacion y confirma para continuar con el registro.`}</Text>
            
            <ul>
                <InfoItem>Pais: <Data>{`${values.pais}`}</Data></InfoItem>
                <InfoItem>Compañia: <Data>{`${values.companyName || ""}`}</Data></InfoItem>
                <InfoItem>Nombre: <Data>{`${values.firstname || ""}`}</Data></InfoItem>
                <InfoItem>Apellido: <Data>{`${values.lastname || ""}`}</Data></InfoItem>
                <InfoItem>Email: <Data>{`${values.email}`}</Data></InfoItem>
            </ul>
            
            
            <StyledList>
                <StyledListItem>Estaras abriendo una cuenta con un periodo de prueba de un total de 100 cirugias creadas</StyledListItem>
                <StyledListItem>Al concluir las 100 cirugas deberas elegir uno de nuestros planes segun la necesidad de tu compañia</StyledListItem>
                <StyledListItem>Estamos disponibles for chat, email o telefono para ayudarte a configurar tu cuenta y asi puedas sacar maximo provecha el periodo de prueba</StyledListItem>
                <StyledListItem>Luego de confirmar tu registro recibirar un email en las proximas horas con un enlace para que puedas activar tu cuenta.</StyledListItem>
            </StyledList>
        </Wrapper>
    )
}


const Wrapper = styled.div`
 margin-top: -20%;
`


const Text = styled.p`
    margin: 0;
    margin-bottom: 32px;
    font-size: 1.3rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[700]};
    
`

const InfoItem = styled.li`
    color: ${p=>p.theme.palette.grey[600]};
    list-style: none;
    margin-bottom: 4px;
`

const Data = styled.span`
    color: ${p=>p.theme.palette.grey[800]};
    font-weight: 500;
`


const StyledList = styled.ul`
    list-style:none;
    display:inline;
    padding-left: 24px;
    margin:0;
    margin-bottom: 12px;
    width:100%:
    height:100%;
        
    @media ${p=>p.theme.media.tablet}{
        padding-left: revert;
    }

`


const StyledListItem = styled.li`    
    padding-bottom: 8px;
    position:relative;
    padding-left: 12px;
    margin-left: 12px;
    color: ${p=>p.theme.palette.grey[700]};
    &::before{
        content: "\\2713";
        padding-right: 8px;
        color: ${p=>p.theme.palette.success.light};        
        position:absolute;
        left: -12px;
    }
`

export default ConfirmarRegistro