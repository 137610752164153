import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';
import TruckIcon from '../../Icons/Truck';

const OperacionesChip = ({statusOperaciones})=>{
    const theme = useTheme()
    const color = theme.palette.grey[600]
    const fillTruckColor = theme.palette.grey[300]
    
    if(!statusOperaciones) return null

    return(        
        <Chip icon={<TruckIcon size={18} color={color} fill={fillTruckColor}/> } outline color={color} label={statusOperaciones} />
    )
}



export default OperacionesChip;