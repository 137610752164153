import React from 'react';
import styled from '@emotion/styled';
import LabelValue from '../Atoms/LabelValue';




//  const [documentosPendientes, setDocumentosPendientes] = React.useState([]);
  //const documentosPendientes = ['formulario', 'cedula', 'aprobacion'];
  const DocumentosPendientes = ({documentos})=>{

    let pendientes =[];

    (documentos?.aprobacion?.required && !documentos?.aprobacion?.delivered) && pendientes.push('Aprobacion');
    (documentos?.carnet?.required && !documentos?.carnet?.delivered) && pendientes.push('Carnet');
    (documentos?.cedula?.required && !documentos?.cedula?.delivered) && pendientes.push('Cedula');
    (documentos?.formulario?.required && !documentos?.formulario?.delivered) && pendientes.push('Formulario');
    (documentos?.indicacion?.required && !documentos?.indicacion?.delivered) && pendientes.push('Indicacion');
    (documentos?.pago?.required && !documentos?.pago?.delivered) && pendientes.push('Pago');
    

   if(pendientes.length<1) return <LabelValue label="Documentos Pendientes" value="No hay pendientes"/>

    const pendientesList = pendientes.map((documento, index)=>{
      // checking to see if last doc on list in order to not include comma.

     let documentoL = `${documento},`;

     if (index === pendientes.length - 1){
       documentoL = `${documento}`
      }

    return (
      <StyledText key={`${documento}${index}`}> {documentoL}</StyledText>
    )
  }

  )

  return <LabelValue label="Documentos Pendientes" value={pendientesList}/>


  }

  const StyledText = styled.span`
    color: black;
    font-size: .875rem;
  `


  export default DocumentosPendientes;