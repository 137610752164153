import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { errorMessage } from '../../../app/messagesReducer';
import { updateActivosFijosArray } from '../../../app/activosFijosReducer';
import useCheckForm from '../../../hooks/useCheckForm';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import { isMaxLength } from '../../../shared/validators';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import TextInput from '../../Atoms/TextInput';
import useForm from '../../../hooks/useForm';

const EditarActivosFijos = ({
    activoFijoObj,
    close
}) => {

    const dispatch = useDispatch();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    const [values, setValues] = React.useState({
        codigo: activoFijoObj?.codigo,
        descripcion: activoFijoObj?.descripcion,        
        fechaAdquisicion: activoFijoObj?.fechaAdquisicion,        
        fabricante: activoFijoObj?.fabricante,
        serial: activoFijoObj?.serial
    });

    const [errors, setErrors] = React.useState({
        codigo: "",
        descripcion: "",
        serial: ""
    });

    const {onChangeValuesValidation, trimValue} = useForm({setValues, setErrors, errors});

    const [formCompleted, errorCount] = useCheckForm(values, errors, [
        "codigo",
        "descripcion",
        "serial"
    ]);

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const trimmedValues = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [key, trimValue(value)])
        );

        const options = createOptions("POST", trimmedValues);
        try {
            const response = await goFetch(`${apiUrl}activosFijos/edit/${activoFijoObj._id}`, options);
            responseStateChanges(response, () => dispatch(updateActivosFijosArray(response.activoFijo)), () => close());
        } catch(err) {
            dispatch(errorMessage(err.error));
        }
    };

    //in fecha de adquisicion I used the helperText to show the label. Since the label was showing up in the input and showed together with the emnpty date value, I decided to use the helperText to show the label.

    return (
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle>Editar Activo Fijo</FormTitle>
            <StyledFormBody>
                <TextInput 
                    label="Código"
                    name="codigo"
                    value={values.codigo}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(20, event.target.value, true)])}
                    helperText={!errors.codigo ? "Campo Obligatorio" : errors.codigo}
                    error={errors.codigo}
                />
                <TextInput 
                    label="Descripción"
                    name="descripcion"
                    value={values.descripcion}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(200, event.target.value)])}
                    helperText={!errors.descripcion ? "Campo Obligatorio" : errors.descripcion}
                    error={errors.descripcion}
                />
                <TextInput 
                    label="Serial"
                    name="serial"
                    value={values.serial}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(50, event.target.value)])}
                    helperText={!errors.serial ? "Campo Obligatorio" : errors.serial}
                    error={errors.serial}
                />
                <TextInput 
                    label="Fabricante"
                    name="fabricante"
                    value={values.fabricante || ''}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(50, event.target.value)])}
                    helperText={errors.fabricante}
                    error={errors.fabricante}
                />
                <TextInput 
                    label=""
                    name="fechaAdquisicion"
                    type="date"
                    value={values.fechaAdquisicion || ''}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [])}
                    helperText={"Fecha de Adquisición"}
                    error={errors.fechaAdquisicion}
                />
            </StyledFormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"
                loading={isLoading}
                successEvent={successEvent}
                type="submit"
                as="button"
                disabledSuccess={!formCompleted || errorCount > 0}
            />
        </StyledFormWrapper>
    );
};

const StyledFormWrapper = styled(FormWrapper)`
    padding-left: 12px;
    padding-right: 12px;

    @media ${p => p.theme.media.tablet} {
        min-width: 500px;
    }
`;

const StyledFormBody = styled(FormBody)`
    flex: 2;
    margin-bottom: 18px;
`;

export default EditarActivosFijos;