import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVentas } from "../../../app/thunkActions/usersActions";
import StyledAutocomplete from '../../Atoms/StyledMUIAutocomplete';
import { TextField } from '@mui/material';


const VentasAutocomplete = ({
    onChange
})=>{

    const dispatch = useDispatch();
    const ventas = useSelector(state=>state.users.ventas)
    
    React.useEffect(()=>{
        dispatch(fetchVentas())
    },[dispatch])
    

    return (
        <StyledAutocomplete
            id="ventas"
            name="ventas"
            onChange={onChange}
            options={ventas}
            getOptionLabel={(option)=>`${option.firstname} ${option.lastname}`}
            renderInput={(params) => <TextField {...params} outlined="true" label="Ventas" />}
        />

    )

}






export default VentasAutocomplete;