import React from 'react';
import styled from '@emotion/styled';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useUpdateCirugia from '../../../hooks/useUpdateCirugia';
import Button from '../../Atoms/Button';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import TextInput from '../../Atoms/TextInput';
import Minus from '../../Icons/Minus';
import Plus from '../../Icons/Plus';
import { errorMessage } from '../../../app/messagesReducer';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { planBasico } from '../../../shared/stripeProductCodes';

const NumberOfUsersForm = ({    
    close,
    plan
})=>{

    const isTablet= useMediaQuery('tablet');        
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);  
    const [numberOfUsers, setNumberOfUsers] = React.useState(1);  
    const stripe = useStripe();
    const dispatch = useDispatch();
    const user = useCurrentUser()

    const addUsers = ()=>{
        setNumberOfUsers(state=>state + 1)
    }

    const subtractUsers = ()=>{
        setNumberOfUsers(state=>{
            if(state ===1)return state
            else{
                state=state-1
                return state
            }
        })
    }

    const handleNumberOUsers = (e)=>{
        const users = e.target.value;
        console.log(isNaN(users))
        if(users === '') return setNumberOfUsers(Number(0))
        var regex=/^[0-9]+$/;
        if (!users.match(regex))return
        setNumberOfUsers(Number(users))
    }


         
    const onSubmit = async(event)=>{
        event.preventDefault();

        if(numberOfUsers < 1){
            dispatch(errorMessage('Debes tener al menos un usuario seleccionado'))
            return
        }
        
        const options = createOptions('POST',{
            priceId: plan,
            numberOfUsers,
            email: user.email
        })

        const response = await goFetch(`${apiUrl}checkout`, options)
        const {sessionId} = response

        const {error} = await stripe.redirectToCheckout({
            sessionId
        })

        if(error){
            console.log(error)
        }

    }

      
      

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Usuarios</FormTitle>
            <FormSubTitle isTablet={isTablet}>Seleccionar numero de usuarios para la subscripcion. Este numero puede ser modificado en cualquier momento en el futuro segun la necesidad de su compañia. </FormSubTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

            <div style={{display:'flex', alignItems:'center', gap:'8px', alignSelf: 'center'}}>
            <p>Numero de usuarios</p>        
            <div style={{width:'75px'}}>
                <TextInput style={{textAlign: 'end'}} value={numberOfUsers} onChange={handleNumberOUsers}></TextInput>
            </div>
            <Button primary onClick={addUsers}><Plus/></Button>
            <Button primary onClick={subtractUsers}><Minus/></Button>
        </div>
                                                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                successText={"Continuar"}
           />
            
        </StyledFormWrapper>
    )
}

const StyledFormWrapper = styled(FormWrapper)`
    max-width: 400px;
`

const FormSubTitle = styled.p`
    margin: 0;
    font-size: 0.875rem;
    padding: 0px 24px 24px 24px;
    margin-top: -12px;
`


export default NumberOfUsersForm