import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { addDoctorsSearch, resetDoctorsSearch, setFilter, setSearchValue, setSelected } from "../../../app/doctorsReducer";
import usePermissions from "../../../hooks/usePermissions";
import { apiUrl } from "../../../shared/url";
import Button from "../../Atoms/Button"


const DoctorsFiltros = ({
    //selected,
    //setSelected
})=>{

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { filter, searchValue, isLoading, selected } = useSelector(state=>state.doctors);  
    const permissions = usePermissions();
    const {canCrearEditarInvitarDoctores} = permissions;  
    const [isLoadingNoCreados, setIsLoadingNoCreados] = React.useState(false)
    

    //this resets state after visiting a profile in order to allow for fethcing doctors to work. 
    const checkStateAndReset = ()=>{
        if(location.state?.from === "profile"){ 
            navigate('/app/doctors')
        }
    }

    const onClickTodos = ()=>{
        dispatch(setSelected('todos'))
        checkStateAndReset()        
        dispatch(setSearchValue(''))
        dispatch(resetDoctorsSearch())
        dispatch(setFilter({}))
        
    }

    const onClickConectados =()=>{
        dispatch(setSelected('conectados'))
        console.log()
        checkStateAndReset()
        dispatch(resetDoctorsSearch())
        dispatch(setSearchValue(''))        
        dispatch(setFilter({'connected.status': true}))
        
    }

    const onClickInvitados = ()=>{
        checkStateAndReset()   
        dispatch(setSearchValue(''))
        dispatch(resetDoctorsSearch())    
        dispatch(setSelected('invitados'))

        // need to check if connected.status exists. If it does needs to be false, if it doesnt also select.
        dispatch(setFilter({'$or':[
            {'connected.status':{'$exists':true, '$in':[false]}, 'connected.invited': true},
            {'connected.status':{'$exists':false}, 'connected.invited': true},
            
        ]
    }))
    }

    const onClickInvitacionSolicitada = ()=>{
        checkStateAndReset()  
        dispatch(setSearchValue(''))
        dispatch(resetDoctorsSearch())
        dispatch(setFilter({'connected.invitationRequest.status': true, 'connected.invited':false}))
        dispatch(setSelected('invitacion_solicitada'))
    }

    const onClickSolicitudNoCreados = async ()=>{
        dispatch(setSelected('no_creados'))
        checkStateAndReset()
        dispatch(setSearchValue(''))
        dispatch(resetDoctorsSearch())
        //this filter prevents fetching of doctors when activated in order to fetch from Company collection          
        dispatch(setFilter({"noCreados": true}))
        setIsLoadingNoCreados(true)
        const options = createOptions('POST');        
        const doctors = await goFetch(`${apiUrl}doctors/invitation`, options)
        setIsLoadingNoCreados(false)
        dispatch(addDoctorsSearch(doctors))
        
        
    }



    
    const todosSelected = Object.keys(filter).length !== 0 || searchValue;
    const connectadosSelected = selected === 'conectados';
    const invitadosSelected = selected === 'invitados'
    const invitacionSolicitada = selected === 'invitacion_solicitada'
    const invitacionSolicitadaNoCreados = selected === 'no_creados'

    return (
        <>
            <Button 
                outline={todosSelected} 
                onClick={onClickTodos}
                loading={isLoading && !todosSelected}
            >Todos</Button>    
            <Button 
                outline={!connectadosSelected} 
                onClick={onClickConectados}
                loading={isLoading && connectadosSelected}
            >Conectados</Button>    
            <Button 
                outline={!invitadosSelected} 
                onClick={onClickInvitados}
                loading={isLoading && invitadosSelected}
            >Invitados</Button>
            <Button 
                outline={!invitacionSolicitada}
                onClick={onClickInvitacionSolicitada}
                loading={isLoading && invitacionSolicitada}
            >Invitacion solicitada</Button> 
            
            {canCrearEditarInvitarDoctores &&
                <Button 
                    outline={!invitacionSolicitadaNoCreados}
                    onClick={onClickSolicitudNoCreados}
                    loading={isLoadingNoCreados && invitacionSolicitadaNoCreados}
                >No creados</Button> 
            }
        </>
    )
}


export default DoctorsFiltros;