import { addPacientes} from "../pacientesReducer";
import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";

export const fetchPacientes = (filter, nextCursor)=> async(dispatch)=>{

    const options = createOptions('GET')
    let pacientes;
      
    try{
        pacientes = await goFetch(apiUrl+ "pacientes",options );
       // console.log(pacientes);
    }catch(error){
        console.log(error);
    }

    dispatch(addPacientes(pacientes));

}


export const searchPacientes = (search)=> async(dispatch)=>{

    const options = createOptions('GET')
    let pacientes;
      
    try{
        pacientes = await goFetch(apiUrl+ "pacientes/search/"+search,options );
        dispatch(addPacientes(pacientes));
       // console.log(pacientes);
    }catch(error){
        console.log(error);
    }

    

}


export const crearPaciente = (values)=> async(dispatch)=>{

    const options = createOptions('POST', values)
    let paciente;
      
    try{
        paciente = await goFetch(apiUrl+ "pacientes", options );
        console.log(paciente);
    }catch(error){
        console.log(error);
    }
}