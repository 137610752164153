import styled from '@emotion/styled/macro';


const ProfilePictureAvatar = ({
    src,
    children,
    disabled,
    size="small",
    style,
    ...props
})=>{

    let measurement = "40px"
    if(size==="large"){
        measurement = "180px";
    }


    return (
        <Wrapper style={{"--measurement": measurement, ...style}}   >
            {
                src &&
                <Image src={src}/>
            }
            {
                !src &&  children
            }
           {disabled &&
            <Disabled/>
           }
                
           
            
        </Wrapper>
    )
}


const Wrapper = styled.div`
    object-fit: cover;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0;
    background-color: ${p=>p.theme.palette.primary.dark};
    font-weight: 600;
    color: #ffff;
    position:absolute;
    top:-52px;
    left: 10px;

`

const Disabled = styled.div`
    position:absolute;
    top:0;
    left:0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: hsla(345, 2%, 72%, 0.62);
`

const Image = styled.img`
    object-fit: cover;
    width:100%;
    height:100%;
    
`


export default ProfilePictureAvatar;