import React from 'react';



const useMediaQuery = (screenSize)=>{

    const [match, setMatch] = React.useState(false);

    let mediaQuery;
    switch (screenSize) {
        case 'sm':
        case 'phone':
            mediaQuery = '(max-width:599px)'
            break;
        case 'md':
        case 'tablet':
            mediaQuery = '(min-width:600px)'
            break;
        case 'lg':
        case "laptop":
            mediaQuery = '(min-width:900px)'
            break;
        case 'xl':
        case 'desktop':
            mediaQuery = '(min-width:1200px)'
             break;
        default:
            break;
    }
   // console.log(mediaQuery)

    React.useEffect(()=>{
        const media = window.matchMedia(mediaQuery)

        if(media.matches !== match){
            setMatch(media.matches)
        }

        const listener = (e)=>{
            if(e.matches){
                setMatch(true)
                
            }else{
                setMatch(false)
                
            }
        }

        media.addEventListener('change',listener)

        return ()=>media.removeEventListener('change', listener)

    },[match,mediaQuery])
    
    return match;

}

export default useMediaQuery;