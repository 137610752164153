import React from 'react';
import {Outlet} from 'react-router-dom'
import styled from '@emotion/styled';
import useScrollTrigger from '../../hooks/useScrollTrigger';
import Drawer from './Drawer';
import useMediaQuery from '../../hooks/useMediaQuery';
import MobileDrawer from './MobileDrawer';
import Header from './Header';
import CustomizedActionButton from './CustomizedActionButton';
import DrawerNavigation from './DrawerNavigation';
import Alert from '../Atoms/Alert';
import AlertMessages from '../molecules/AlertMessages';
import usePermissions from '../../hooks/usePermissions';

const Layout = ()=>{

    const [openDesktopDrawer, setOpenDesktopDrawer] = React.useState(false);
    const scrolled = useScrollTrigger();    
    const isPhone = useMediaQuery('phone');
    const permissions = usePermissions()

    const {       
        canCrearCirugias, 
        canCrearEditarInvitarDoctores,
        canCrearPacientes               
     }=permissions;
   

    const [openModal, setOpenModal] = React.useState(false);

    const onClickHandler = ()=>{
        if(!isPhone){
            setOpenDesktopDrawer(prevState=>!prevState);
        }else{
            setOpenModal(true);
        }        
    }

    //changed drawer width from 180px to 225px on June 2024
    return (
        <LayoutWrapper style={{
            '--header-height':'65px',
            '--drawer-width' : '225px'
        }}>
            <Header open={openDesktopDrawer} onClick={onClickHandler} scrolled={scrolled}>
            </Header>
           
            <MainWrapper>                
                {
                    !isPhone &&
                    <Drawer open={openDesktopDrawer} setOpen={setOpenDesktopDrawer}>
                        <DrawerNavigation/>
                    </Drawer>
                }   
                {
                    openModal && 
                    <MobileDrawer openModal={openModal} setOpenModal={setOpenModal}>
                        <DrawerNavigation setOpenModal={setOpenModal}/>
                    </MobileDrawer>
                }                 
                    
                <ContentWrapper open={openDesktopDrawer} isPhone={isPhone}>                    
                    <Outlet/>                    
                </ContentWrapper>
            </MainWrapper>
            
            
            {(canCrearCirugias || canCrearEditarInvitarDoctores || canCrearPacientes) && <CustomizedActionButton/>}
            <AlertMessages/>
        </LayoutWrapper>
    )
}


const LayoutWrapper = styled.div`
    min-height:100%;
    isolation:isolate;
    position:relative;
`

const ContentWrapper= styled.div`
    height:100%;
    margin-top: var(--header-height);
    margin-left: ${({isPhone, open})=>(
        (isPhone) 
        ? '0px'
        : (open) ? 'var(--drawer-width)' : '75px'
        )};
    transition: ${({isPhone})=>isPhone? 'none' :'margin-left 300ms ease-in'};
    padding: 16px 8px;
    width:100%;
    
    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
    }

`

const MainWrapper = styled.div`
 height:100%;
 display:flex;
`




export default Layout;