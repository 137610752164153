import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";
import { addClients } from "../clientsReducer";

export const fetchClients = (filter={})=> async(dispatch)=>{
    const options = createOptions('POST', filter)
    let response;
      
    try{
        response = await goFetch(apiUrl+ "clients/fetch-clients",options );
        dispatch(addClients(response));
       console.log(response);
    }catch(error){
        console.log(error);
    }

    

}