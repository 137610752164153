import Select from '@mui/material/Select';
import { useTheme } from "@mui/system";



const CustomSelect  = ({children,...props})=>{
    const theme = useTheme();

    return(
        <Select sx={{
            borderRadius:'12px',
            fontSize:"1rem",
            '& > svg':{
                right: "22px"
            },
            '& > fieldset':{
                borderColor: theme.palette.grey[500],
            },
            '&:hover > fieldset':{
                borderColor: theme.palette.primary.main,
            }

        }} 
        {...props}
        >
            {children}
        </Select>
    )
}




export default CustomSelect