import React from 'react';
import {Outlet} from 'react-router-dom'
import styled from '@emotion/styled';
import useScrollTrigger from '../../../hooks/useScrollTrigger';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Header from '../../Layout/Header';
import Drawer from '../../Layout/Drawer';
import MobileDrawer from '../../Layout/MobileDrawer';
import AlertMessages from '../../molecules/AlertMessages';
import DrawerNavigationMd from './DrawerNavigationMd';

const LayoutMd = ()=>{

    const [openDesktopDrawer, setOpenDesktopDrawer] = React.useState(false);
    const scrolled = useScrollTrigger();    
    const isPhone = useMediaQuery('phone');

    const [openModal, setOpenModal] = React.useState(false);

    const onClickHandler = ()=>{
        if(!isPhone){
            setOpenDesktopDrawer(prevState=>!prevState);
        }else{
            setOpenModal(true);
        }        
    }

    return (
        <LayoutWrapper style={{
            '--header-height':'65px',
            '--drawer-width' : '180px'
        }}>
            <Header open={openDesktopDrawer} onClick={onClickHandler} scrolled={scrolled}>
            </Header>
           
            <MainWrapper>                
                {
                    !isPhone &&
                    <Drawer open={openDesktopDrawer} setOpen={setOpenDesktopDrawer}>
                        <DrawerNavigationMd/>
                    </Drawer>
                }   
                {
                    openModal && 
                    <MobileDrawer openModal={openModal} setOpenModal={setOpenModal}>
                        <DrawerNavigationMd/>                        
                    </MobileDrawer>
                }                 
                    
                <ContentWrapper open={openDesktopDrawer} isPhone={isPhone}>                    
                    <Outlet/>                    
                </ContentWrapper>
            </MainWrapper>
            
            
            
            <AlertMessages/>
        </LayoutWrapper>
    )
}


const LayoutWrapper = styled.div`
    min-height:100%;
    isolation:isolate;
    position:relative;
`

const ContentWrapper= styled.div`
    margin-top: var(--header-height);
    margin-left: ${({isPhone, open})=>(
        (isPhone) 
        ? '0px'
        : (open) ? 'var(--drawer-width)' : '75px'
        )};
    transition: ${({isPhone})=>isPhone? 'none' :'margin-left 300ms ease-in'};
    padding: 16px 8px;
    width:100%;

    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
    }
`

const MainWrapper = styled.div`
 height:100%;
 display:flex;
`




export default LayoutMd;