import {createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../shared/authHelpers';


const initialState= {
        isLoading: false,
        isAuthenticated: getCookie('token') ? true : false,
        token: getCookie('token'),
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        //errMess: null,
        //signup: false
        subToken: getCookie('subToken') || false,   
        configuration: localStorage.getItem('configuration') ? JSON.parse(localStorage.getItem('configuration')) : null, 
}



export const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers: {
        loginSuccess(state, action){
            state.isLoading = false;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.user = action.payload.user;            
        },
        logoutSuccess(state,action){
            console.log('log out success')
            state.isAuthenticated = false;
            state.token = "";
            state.user = null;
        },
        updateUserState(state,action){
            state.user = action.payload;
        },
        updatePictureLink(state,action){
            console.log(action.payload);
            state.user.profilePicture = action.payload
        },
        setSubToken(state,action){
            state.subToken = action.payload
        },
        setConfiguration(state,action){
            state.configuration = action.payload
        }
       
    }
})

const {actions, reducer} = authSlice

export const {
    loginSuccess, 
    logoutSuccess, 
    updateUserState,
    updatePictureLink,
    setSubToken,
    setConfiguration
} = actions;

export default reducer;