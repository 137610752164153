import styled from "@emotion/styled/macro";
import { faEdit, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import MoreHorizontal from "../../Icons/MoreHorizontal";
import { errorMessage, successMessage } from "../../../app/messagesReducer";
import { updateActivosFijosArray } from "../../../app/activosFijosReducer";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import IconButton from "../../Atoms/IconButton";

const MoreButtonActivosFijos = ({
    setOpenModal,
    setFormType,
    activoFijoObj,
    setActivoFijoObj,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    console.log(activoFijoObj);

    const onSelect = (formType) => {
        setOpenModal(true);
        setFormType(formType);       
        setActivoFijoObj(activoFijoObj);
        setAnchorEl(null);
    }

    const activate = async(activoFijo) => {
        let data = {
            deactivate: false,            
        }
        
        const options = createOptions("POST", data);
        try {
            const response = await goFetch(`${apiUrl}activosFijos/deactivate/${activoFijo._id}`, options);
            dispatch(successMessage(response.message));
            dispatch(updateActivosFijosArray(response.activoFijo));                                                
        } catch(err) {
            dispatch(errorMessage(err.error));
        }
    }
           
    const onClickHandler = (event) => {
        setAnchorEl(event.currentTarget);
    }
 
    const onCloseHandler = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <IconButton onClick={onClickHandler}>                
                <MoreHorizontal color={theme.palette.grey[600]}/>
            </IconButton>
            <Menu
                open={open}
                onClose={onCloseHandler}
                anchorEl={anchorEl}
            >
                <MenuItem onClick={() => onSelect('editar')}>
                    <MenuItemWrapper>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: "0.9rem"}} color={theme.palette.grey[600]}/>
                        <Text>Editar</Text>
                    </MenuItemWrapper>
                </MenuItem>

                {activoFijoObj?.deactivated &&
                    <MenuItem onClick={() => activate(activoFijoObj)}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faLockOpen} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Activar</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                }
                
                {!activoFijoObj?.deactivated &&
                    <MenuItem onClick={() => onSelect('deactivate')}>
                        <MenuItemWrapper>
                            <FontAwesomeIcon icon={faLock} style={{fontSize: "1rem"}} color={theme.palette.grey[600]}/>
                            <Text>Desactivar</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                }
            </Menu>
        </>
    )
}

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const Text = styled.p`
    margin: 0;
    font-size: 1rem;
    color: ${p => p.theme.palette.grey[800]}
`

export default MoreButtonActivosFijos;

