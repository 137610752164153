import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card"

const PaymentSuccess = ()=>{
    
    const params = useParams()
    const {isSubscribed, checkIfSubscribed} = useSubscriptionToken()    

    React.useEffect(()=>{

        if(!isSubscribed){
            checkIfSubscribed()
        }

    },[isSubscribed, checkIfSubscribed])


    console.log(params)

    return (
        <Wrapper>
            <CardWrapper>
                <CardTitle>Suscripcion creada exitosamente</CardTitle>
                <CardBody>
                    Puedes continuar usando Xirugias para hacer mas efectiva la gestion de Cirugias.
                </CardBody>
            </CardWrapper>

        </Wrapper>

    )
}



const Wrapper = styled.div`
`


export default PaymentSuccess;