import styled from '@emotion/styled/macro';
import React from 'react';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { CardWrapper } from "../../Atoms/Card";
import DoctorCardGrid from '../../Doctors/Molecules/DoctorCardGrid';




const DoctoresCentro = ({
    centroId
})=>{

    const [doctors, setDoctors] = React.useState();


    React.useEffect(()=>{
       const filterDoctors = async()=>{
            const options = createOptions('POST', {centroId})
            let doctors;
            
            try{
                doctors = await goFetch(apiUrl+ "doctors/filter",options );
                setDoctors(doctors);                
            }catch(error){
                console.log(error);
            }
       } 
    
       filterDoctors();

    },[])

    console.log(doctors);
    return (
        <>        
            <Header>
                <Title>Doctores</Title>
                
            </Header>
            <GridWrapper>
                <DoctorCardGrid doctors={doctors}/>
            </GridWrapper>
            
        </>
    )
}




const Header = styled(CardWrapper)`
    width:100%;
`

const Title = styled.h2`
    margin:0;
    font-size: 1.25rem;
`

const GridWrapper = styled.div`
    width: 100%;
    margin:8px;
    display:flex;

`

export default DoctoresCentro;