import styled from '@emotion/styled';
import Button from '../../Atoms/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Download from '../../Icons/Download';
import { useTheme } from '@mui/system';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../app/messagesReducer';
import useUploadFile from '../../../hooks/useUploadFile';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { useCurrentUser } from '../../../hooks/useCurrentUser';


const ProductosDownload = ()=>{
    const user = useCurrentUser()
    const theme = useTheme();
    const dispatch = useDispatch();
    const [uploadFileAxios, isLoading, progress] = useUploadFile();
    console.log(progress);
    //no data as we are only generating a template with headers
    const apiData = []

    const headers = {
        header: ["Codigo", "Descripcion", "Fabricante"]
    }  
    const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";


    const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData, headers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    };


    const uploadCsvFile = (fileName, file)=>{
        console.log('Ready to send')
        if(file.type !=='text/csv'){
            dispatch(errorMessage("El archivo debe tener formato CSV."))
            return
        };

        let formData = new FormData();
        formData.append('productos', file);

        let options={
            data: formData,
            successMessage: "Archivo cargado exitosamente",
            url: "productos/bulk-upload/",        

        }
        uploadFileAxios(options)
    }


  const download = async (e)=>{
    const options = createOptions('GET',{})
    let response
    try {
         response = await goFetch(`${apiUrl}productos`, options)

         if(response.error){
            dispatch(errorMessage(response.error))
            return
         }
        console.log(response)
    } catch (error) {
        console.log(error)
        dispatch(errorMessage("Error al descargar el archivo"))
    }


    const selectedFields = response?.map(item => {
        return {
                _id: item._id,
                codigo: item.codigo,
                descripcion: item.descripcion,
                'Base - pesos': item.price ? XLSX.utils.format_cell({v: item.price.Base.pesos, t: 'n', z: '#,##0.00'}) : 0,
                'Base - dolares': item.price ? XLSX.utils.format_cell({v: item.price.Base.dolares, t: 'n', z: '#,##0.00'}) : 0,
                'Senasa - pesos': item.price ? XLSX.utils.format_cell({v: item.price.Senasa.pesos, t: 'n', z: '#,##0.00'}) : 0,
                'Senasa - dolares': item.price ? XLSX.utils.format_cell({v: item.price.Senasa.dolares, t: 'n', z: '#,##0.00'}) : 0,
                'Humano - pesos': item.price ? XLSX.utils.format_cell({v: item.price.Humano.pesos, t: 'n', z: '#,##0.00'}) : 0,
                'Humano - dolares': item.price ? XLSX.utils.format_cell({v: item.price.Humano.dolares, t: 'n', z: '#,##0.00'}) : 0,
                'Mapfre - pesos': item.price ? XLSX.utils.format_cell({v: item.price.Mapfre.pesos, t: 'n', z: '#,##0.00'}) : 0,
                'Mapfre - dolares': item.price ? XLSX.utils.format_cell({v: item.price.Mapfre.dolares, t: 'n', z: '#,##0.00'}) : 0,
                'Universal - pesos': item.price ? XLSX.utils.format_cell({v: item.price.Universal.pesos, t: 'n', z: '#,##0.00'}) : 0,
                'Universal - dolares': item.price ? XLSX.utils.format_cell({v: item.price.Universal.dolares, t: 'n', z: '#,##0.00'}) : 0            
        };
      });



        const worksheet = XLSX.utils.json_to_sheet(selectedFields);

        // Add a header row to the worksheet
        XLSX.utils.sheet_add_aoa(worksheet, [['_id','Codigo','Descripcion', 'Base - pesos', 'Base - dolares', 'Senasa - pesos', 'Senasa - dolares', 'Humano - pesos', 'Humano - dolares', 'Mapfre - pesos', 'Mapfre - dolares', 'Universal - pesos', 'Universal - dolares']], {origin: 'A1'});

        // Convert the worksheet to an Excel file
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, worksheet, 'Productos');
        const excelBuffer = XLSX.write(workBook, {bookType: 'xlsx', type: 'array'});

        // Create a Blob object for the Excel data
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        FileSaver.saveAs(blob, `productos_${user.company.name}_xirugias.xlsx`)    
        
  }


    return(
        <Wrapper>
            <Title>Descarga de archivo en excel de todos los Productos</Title>
            <SubTitle>Sigue las instrucciones para poder hacer una carga masiva de los productos.</SubTitle>
            <Instructions>
                
                <Text>Baja la plantilla en Excel presionando el boton de plantilla.</Text>                
            </Instructions>
            <Acciones>
                              
                    <Button 
                        outline
                        preIcon={<Download color={theme.palette.grey[700]}/>}
                        onClick={download}
                    >Bajar Archivo</Button>
                                
            </Acciones>
            
        </Wrapper>
    )

}


const Wrapper = styled.div`
    max-width: 500px;
    
    padding: 24px;
    display:flex;
    flex-direction: column;


`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
    margin-bottom:18px;
`

const SubTitle = styled.h3`
    margin:0;
    font-size: 1rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
`

const Instructions = styled.ol`
    display:flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 36px;
    padding-left:20px;
    color: ${p=>p.theme.palette.grey[800]};
    
`

const Acciones = styled.div`
    display:flex;
    gap: 8px;
`


const Text = styled.li`
    margin:0;
    font-size: 1rem;
`

export default ProductosDownload;