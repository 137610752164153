import { DialogOverlay, DialogContent } from "@reach/dialog";
import styled from '@emotion/styled';
import useMediaQuery from "../../hooks/useMediaQuery";
import IconButton from "./IconButton";
import MobileModal from "./MobileModal";
import X from "../Icons/X";
import React from "react";
import BottomSwipeDrawer from "./BottomSwipeDrawer";



const Modal = ({
    openModal, 
    setOpenModal, 
    inProp,
    setInProp,
    close,
    smallDrawer,
    drawerOffsetPercentInput,
    fullWidth,
    children})=>{

    const isPhone = useMediaQuery('phone');
    const isTablet = useMediaQuery('tablet')
    const isDesktop = useMediaQuery('xl');

    
   
    let DialogContentMinWidth = React.useRef()

    React.useEffect(()=>{
        DialogContentMinWidth.current = isTablet ? '20%': '50%';
    }, [isTablet])
    


   if(isPhone) return (<BottomSwipeDrawer
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            inProp={inProp}
                            setInProp={setInProp}
                            close={close}
                            color="primary"
                            title="Agendar Cirugia"
                            smallDrawer={smallDrawer}
                            drawerOffsetPercentInput={drawerOffsetPercentInput}
                        >
                            {children}
                            </BottomSwipeDrawer>
                            )




    return(
        <StyledDialogOverlay isOpen={openModal} onClick={()=>setOpenModal(false)}>
            <BackDrop/>
            <StyledDialogContent  fullwidth={fullWidth} aria-label="additional info" style={{'--minWidth': DialogContentMinWidth.current}}>                
                
                {children}
                
            </StyledDialogContent>
        </StyledDialogOverlay>
    )
}



const BackDrop = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: hsla(360, 0%, 29%, 0.77); 
`
const StyledDialogOverlay = styled(DialogOverlay)`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: grid;
    place-items:center;  
`

//min-height: 50%;
const StyledDialogContent = styled(DialogContent)`
    position: relative;
    background-color: white;
    min-width: var(--minWidth);
    
    border-radius: 16px;
    max-height:${p=>p.fullwidth ? '100%' : '90%'};
    max-width:${p=>p.fullwidth ? '100%' : '90%'};
    overflow-y:auto;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 
    0px 24px 38px 3px rgb(0 0 0 / 14%), 
    0px 9px 46px 8px rgb(0 0 0 / 12%);
    
`

const StyledButton = styled(IconButton)`
    position: absolute;
    right:0;
    top: -40px;
    
`



export default Modal;