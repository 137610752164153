import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import dateFormat from '../../shared/dateFormatCalendar';
import useCommentMaker from '../../hooks/useCommentMaker';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';
import useDateAndTimeFormatter from '../../hooks/useDateAndTimeFormatter';

const AgendarCirugia = ({
    cirugiaId,
    close,
    inputDate
})=>{

    const isTablet = useMediaQuery('tablet');    
    const [date, handleDateChange] = React.useState(inputDate || new Date());
    const [comments, handleChangeComments] = React.useState('');
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);    
    const commentMaker = useCommentMaker(comments)
    const {dateFormat} = useDateAndTimeFormatter();
    const formattedDate = dateFormat(date);
    const accionMaker = useAccionMaker(`Cirugia agendada para el ${formattedDate} `)
  
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Cirugia agendada para el ${formattedDate} `)
        let infoAgendar = {
          fecha: new Date(date),
          agendada: true,
          suspendida: false,
          cirugiaId: cirugiaId,
          comentarios: commentObj,
          accion: accion
        } 
        updateCirugia('agendar-cirugia',infoAgendar)
      }

    
    

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Agendar Cirugia</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label ="Fecha Cirugia"
                      value={date}
                      onChange={handleDateChange}
                    >
                    </DateTimePicker>

                </LocalizationProvider>
                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />
                
                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default AgendarCirugia