import styled from '@emotion/styled';
import { createOptions, goFetch } from "../../api/cirugiasApi"
import { useCurrentUser } from "../../hooks/useCurrentUser"
import useSuccessButton from '../../hooks/useSuccessButton';
import { urlBase64ToUint8Array } from "../../shared/helpers"
import { apiUrl } from "../../shared/url"
import Button from "../Atoms/Button"
import CardError from '../Atoms/CardError';
import CardInfo from "../Atoms/CardInfo"
import CardSuccess from "../Atoms/CardSuccess"
import { FormActions, FormBody, FormTitle, FormWrapper } from "../Atoms/Forms"



const Notifications = ({
    close
})=>{

    const user = useCurrentUser();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    
    const activateNotifications = ()=>{

        
        Notification.requestPermission((result)=>{
          if(result!== 'granted'){
            console.log('No notificacion permisssion granted.')
          }else if (result === 'granted'){
              // Does not seem to be in the right place. Confirmation should come after subsicription has been sent to the server - July 7, 2022
            // if('serviceworker' in navigator){
            //   var options={
            //     body: "Tu subscripcion a notificaciones ha sido exitosa!"
            //   }
    
            //   navigator.serviceWorker.ready
            //   .then((swreg)=>{
            //     swreg.showNotification('Subscripcion exitosa', options)
            //   })
            // }
    
            configurePushSub();
            //onClickDialogClose();
          }
        })
    
      }
    
    
    
      const configurePushSub =async ()=>{
        console.log('configure push sub started')
        if(!('serviceWorker' in navigator)){
            return;
          }
          
          var reg;
          navigator.serviceWorker.ready
          .then((swreg)=>{
            reg = swreg;            
            return swreg.pushManager.getSubscription()
          })
          .then((sub)=>{
            if (sub === null){              
              var vapidPublicKey = 'BHePoQlEBsIVWcgeVgmLHFztYeHFl8jk3OG3oz-NHOAI_Fj9Rwlb48YVKNHVNvaME8GI3bshWAxRHlhsY6s4wc0';
              var convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey)
              return reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidPublicKey
              });
            } else{
              //we have subscription
              return null
            }
          })
          .then(async(newSub)=>{
              if(newSub){
                console.log('ready to send new sub to server')
                const options = createOptions('POST', {newSub})                        
                const response = await goFetch(`${apiUrl}users/notifications/${user._id}`, options)  
                responseStateChanges(response,null,()=>{                    
                    close()    
                })                             
              }
            
          })
      }
    

      if(!('Notification' in window)){
        return (
            <FormWrapper>
                <FormTitle>Notificaciones</FormTitle>
                <FormBody><CardError fontSize='0.875rem'>Lo sentimos. Tu sistema no permite notificaciones. </CardError></FormBody>
            </FormWrapper>
        )
    }

    if ( Notification.permission === "granted"){
        return (
            <FormWrapper>
                <FormTitle>Notificaciones</FormTitle>
                <FormBody><CardSuccess fontSize='0.875rem'>Ya estas subscrito a notificaciones! </CardSuccess></FormBody>
            </FormWrapper>
        )
      }else if (Notification.permission === "denied"){
        return (
            <StyledFormWrapper>
                <FormTitle>Notificaciones</FormTitle>
                <FormBody>
                    <CardInfo fontSize={"0.875rem"}>Anteriormente bloqueaste las notificaciones.
                        Por favor ir a Preferencias en el navegador y aceptar notificaciones para esta aplicacion.
                    </CardInfo>
                </FormBody>
            </StyledFormWrapper>
    
        )
      }else{
        return (
            <FormWrapper>
                <FormTitle>Notificaciones</FormTitle>
                <FormBody> 
                    <CardSuccess fontSize='0.875rem'>Puedes activar notificaciones</CardSuccess>                    
                </FormBody>
                <FormActions>
                    <Button 
                        primary 
                        fullsize={true}
                        successEvent={successEvent}
                        loading={isLoading}
                        onClick={activateNotifications}
                    >Activar Notificaciones</Button>
                </FormActions>            
            </FormWrapper>
    
        )
      }

}



const StyledFormWrapper = styled(FormWrapper)`
    max-width: 500px;
`
export default Notifications