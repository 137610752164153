import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    centros:[],
    centro: "",
    isLoading: true
}

export const centrosSlice = createSlice({
    name:"centros",
    initialState,
    reducers: {
        loadingCentros(state,action){
            state.isLoading = true;
        },
        addCentros(state, action){
            state.centros = action.payload;
            state.isLoading= false;            
        },
        addCentro(state,action){            
            state.centro = action.payload;             
            state.isLoading= false;                 
        },
        resetCentro(state){
            state.centro = null;
        }           
    }
})

const {actions, reducer} = centrosSlice

export const {loadingCentros, addCentros, addCentro, resetCentro} = actions;

export default reducer;