import styled from '@emotion/styled';
import Button from '../../Atoms/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import UploadCloud from '../../Icons/UploadCloud';
import Download from '../../Icons/Download';
import { useTheme } from '@mui/system';
import UploadButton from '../../Atoms/UploadButton';
import { fil } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../app/messagesReducer';
import useUploadFile from '../../../hooks/useUploadFile';
import usePermissions from '../../../hooks/usePermissions';


const ProductosUpload = ()=>{

    const theme = useTheme();
    const dispatch = useDispatch();
     
    const [uploadFileAxios, isLoading, progress] = useUploadFile();
    const [uploadFileAxiosPrices, isLoadingPrices, progressPrices] = useUploadFile();
    console.log(progress);
    //no data as we are only generating a template with headers
    const apiData = []

    const headers = {
        header: ["Codigo", "Descripcion", "Fabricante"]
    }  
    const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";


    const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData, headers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    };

    
    const upload = (e)=>{
        let fileName = e.target.files[0].name
        let file = e.target.files[0]     
        let url = "productos/bulk-upload/"
        file && console.log("got file")
        if(file.type !=='text/csv'){
            dispatch(errorMessage("El archivo debe tener formato CSV."))
            return
        };

        let formData = new FormData();
        formData.append('productos', file);

        let options={
            data: formData,
            successMessage: "Archivo cargado exitosamente",
            url: url,        

        }
        uploadFileAxios(options)
    }

    const uploadPrices = (e)=>{
        console.log("upload prices")
        let fileName = e.target.files[0].name
        let file = e.target.files[0]
        let url = "productos/bulk-upload-prices"
        file && console.log("got file") 
        if(file.type !=='text/csv'){
            dispatch(errorMessage("El archivo debe tener formato CSV."))
            return
        };

        let formData = new FormData();
        formData.append('productos', file);

        let options={
            data: formData,
            successMessage: "Archivo cargado exitosamente",
            url: url,        

        }
        uploadFileAxiosPrices(options) 
    }
        



    return(
        <Wrapper>
            <Title>Carga masiva de Productos</Title>
            <SubTitle>Sigue las instrucciones para poder hacer una carga masiva de los productos.</SubTitle>
            <Instructions>
                
                <Text>Baja la plantilla en Excel presionando el boton de plantilla.</Text>
                <Text>Debes llenar la informacion usando las mismas columnas de la plantilla. La columna "Codigo" y "Descripcion son obligatorias.</Text>
                <Text>Guarda el archivo en formato CSV. </Text>
                <Text>Presiona el boton de Subir Productos y adjunta el archivo CSV.</Text>
            </Instructions>
            <Acciones>
                              
                    <Button 
                        outline
                        preIcon={<Download color={theme.palette.grey[700]}/>}
                        onClick={()=>exportToCSV(apiData, "Producto Template")}
                    >Bajar plantilla</Button>
                
                <UploadButton
                    id="file"
                    name="file"
                    onChange={upload}
                >  
                    <Button 
                        preIcon={<UploadCloud color={'#ffff'} size={20}/>}
                        loading={isLoading}
                    >Subir data</Button>
                </UploadButton>
               { //needs to be limited by subscription permissions
                <UploadButton
                    id="filePrice"
                    name="filePrice"
                    onChange={uploadPrices}
                >  
                    <Button 
                        preIcon={<UploadCloud color={'#ffff'} size={20}/>}
                        loading={isLoadingPrices}
                    >Subir precios</Button>
                </UploadButton>}
            </Acciones>
            
        </Wrapper>
    )

}


const Wrapper = styled.div`
    max-width: 500px;
    
    padding: 24px;
    display:flex;
    flex-direction: column;


`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
    margin-bottom:18px;
`

const SubTitle = styled.h3`
    margin:0;
    font-size: 1rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
`

const Instructions = styled.ol`
    display:flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 36px;
    padding-left:20px;
    color: ${p=>p.theme.palette.grey[800]};
    
`

const Acciones = styled.div`
    display:flex;
    gap: 8px;
`


const Text = styled.li`
    margin:0;
    font-size: 1rem;
`

export default ProductosUpload;