import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePictureAvatar from '../../Atoms/ProfilePictureAvatar';
import blankProfile from '../../../blankProfile.svg'
import Button from '../../Atoms/Button';
import Camera from '../../Icons/Camera';
import UploadCloud from '../../Icons/UploadCloud';
import ImageInput from '../../Atoms/ImageInput';
import React from 'react';
import imageCompression from 'browser-image-compression';
import axios from "axios";
import { apiUrl } from '../../../shared/url';
import { updatePictureLink } from '../../../app/authReducer';
import { useTheme } from '@mui/system';
import { getCookie } from '../../../shared/authHelpers';
import ProgressBar from '../../Atoms/ProgressBar';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import useSuccessButton from '../../../hooks/useSuccessButton';




const FotoPerfilUpload = ({
    close,
    route
})=>{

    const user = useSelector(state=>state.auth.user);    
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const [src, setSrc] = React.useState();
    const [image, setImage] = React.useState();
    const [progress, setProgress] = React.useState(0);
    const [disableUpload, setDisableUpload] = React.useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();

    const onChangeImage = (e)=>{
        if(disableUpload) setDisableUpload(false)
        setImage(e.target.files[0]);
        //setImageName(e.target.files[0].name)
        const reader = new FileReader();
        reader.onloadend = (e)=>{
            setSrc(e.target.result)
        }
        reader.readAsDataURL(e.target.files[0]); // when file is read onloadend function is triggered. result has a url which is then used to render preview.
    }

    const onSubmit = (e)=>{
        
        console.log('submitted')
        //setDisableUpload(true)
        if(isLoading)return

        var options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 500
        }
    
        imageCompression(image, options)
          .then((compressedFile)=>{
            console.log('compressed')
            const formData = new FormData();
            formData.append('image',compressedFile)
    
            
            uploadImageAxios(formData, user._id)
          })
          .catch(err=>console.log(err))
        
    }


    const uploadImageAxios = async (formData, userId) =>{
        setIsLoading(true)
        const token = getCookie('token');

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
         },  
          onUploadProgress: function(progressEvent){            
            var percentCompleted =  parseInt(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            console.log(percentCompleted);
            setProgress(percentCompleted);            
         }
        }
        
        try {

            //axios.post(apiUrl + 'users/add-profile-picture/' + userId, formData, config)
            const response = await axios.post(`${apiUrl}${route}/add-profile-picture/${userId}`, formData, config);
            responseStateChanges(
            response, 
            ()=>{                    
                let user = JSON.parse(localStorage.getItem('user'))
                user.profilePicture = response.data.userPictureLink
                localStorage.setItem('user', JSON.stringify(user))
                dispatch(updatePictureLink(response.data.userPictureLink));      
            }, 
            ()=>close())                                   
        } catch (error) {
            console.log(error);
            dispatch(errorMessage("Hubo un error, por favor intente mas tarde."));
        }

    }

    return(
        <Wrapper>
            
            <ProfilePictureAvatar
                src={src || user?.profilePicture || blankProfile}
                size="large" 
                alt="doctor avatar" 
                style={{position:"relative", top:0, left:0}}
            />
            <ProgressBar progress={progress}/>
            <ActionWrapper>
                <Button outline error onClick={()=>close()}>Volver</Button>
                <ImageInput
                    onChange={onChangeImage}
                >
                    <Button outline preIcon={<Camera/>}/>
                </ImageInput>                         
                {src && <Button 
                            disabled={disableUpload} 
                            success 
                            onClick={onSubmit} 
                            preIcon={<UploadCloud color={"#ffff"}/>} 
                            successEvent={successEvent}
                            loading={isLoading}
                        />}
            </ActionWrapper>
        </Wrapper>
    )
}



const Wrapper = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 24px;
    flex-direction: column;
    gap: 48px;
`

const ActionWrapper = styled.div`
    display: flex;
    gap: 8px;

`

export default FotoPerfilUpload;