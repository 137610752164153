import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { loginSuccess, setConfiguration, setSubToken } from '../../app/authReducer';
import { errorMessage } from '../../app/messagesReducer';
import usePasswordMatch from '../../hooks/usePasswordMatch';
import { authenticate } from '../../shared/authHelpers';
import { apiUrl } from '../../shared/url';
import { isLength, isPassword, validate } from '../../shared/validators';
import Button from '../Atoms/Button';
import CardError from '../Atoms/CardError';
import CardSuccess from '../Atoms/CardSuccess';
import { FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import PasswordInput from '../Atoms/PasswordInput';
import TextInput from '../Atoms/TextInput';
import { useJwt } from "react-jwt";



const ActivateCompanyUser = ()=>{

    const dispatch = useDispatch()
    const params = useParams();
    const token = params.token
    const {decodedToken, isExpired} = useJwt(token)
    
    console.log(decodedToken)

    const [values, setValues] = React.useState({
        firstname: decodedToken?.firstname || '',
        lastname: decodedToken?.lastname || "",        
        password: ''
    })

    React.useEffect(()=>{
        setValues(prevState=>({...prevState, firstname: decodedToken?.firstname, lastname: decodedToken?.lastname}))

    },[decodedToken])

    const [errors, setErrors] = React.useState({
        cotizacion: ""
      })

      
     const [
        password, 
        passwordError, 
        passwordMatch, 
        confirmPassword,
        setConfirmPassword, 
        onChangePasswordValues, 
        onBlurConfirmPassword
     ] = usePasswordMatch(errors,setErrors);

    //For plain textinput and select fields
    const onChangeHandler = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setValues(prevState => ({...prevState, [name]:value}))
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

    

    const activate = async()=>{
        const options = createOptions('POST',{
                     token,
                     firstname: values.firstname,
                     lastname: values.lastname,
                     password   
                })
        
        const response = await goFetch(`${apiUrl}users/create-company-user`, options, dispatch)
        
        if(response.token){
            authenticate(response)
            dispatch(loginSuccess({token: response.token, user:response.user}))
            dispatch(setSubToken(response.subToken))
            dispatch(setConfiguration(response.configuration))
        }else if(response.error){
            dispatch(errorMessage(response.error))
        }
        
    }

    if(isExpired){

        return(
            <CardError>El tiempo limite para registrate expiro. Por favor solicita otra invitacion.</CardError>
        )

    }

    return (
        
            
            <StyledFormWrapper>
                <StyledFormTitle><Text>{`Invitacion por parte de ${decodedToken?.companyName}`}</Text></StyledFormTitle>
                <StyledFormTitle><Text>¡Tu email fue verificado! Por favor verifica tu nombre y elige tu contrasena y procede activar tu cuenta. </Text></StyledFormTitle>
                <StyledFormBody>
                <TextInput 
                    label="Nombre"
                    name={"firstname"}
                    value={values.firstname}
                    onChange={onChangeHandler}                            
                    helperText={errors.firstname}
                    error={errors.firstname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />

             <TextInput 
                    label="Apellido"
                    name={"lastname"}
                    value={values.lastname}
                    onChange={onChangeHandler}                            
                    helperText={errors.lastname}
                    error={errors.lastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
            />

            <PasswordInput 
                    label="Contraseña nueva"
                    name="password" 
                    value={password} 
                    onChange = {(event)=>onChangePasswordValues(event, [isPassword], true)}
                    onBlurValidation={(event)=>onBlur(event, [isPassword])}
                    helperText={errors.password}
                    error={errors.password}
                />
            <PasswordInput 
                    label="Confirmar contraseña"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange = {(event)=>setConfirmPassword(event.target.value)}    
                    onBlurValidation={(event)=>onBlurConfirmPassword(event)}                
                />
                {passwordError && <CardError fontSize={"0.875rem"}>Las contraseñas no son iguales</CardError>}
                {passwordMatch && !errors.password && <CardSuccess>Las contraseñas son iguales</CardSuccess>}

                </StyledFormBody>
                <Button 
                    primary 
                    onClick={activate}
                    disabled={errors.password || (password !== confirmPassword) || !password || !values.firstname || !values.lastname}
                >Activa tu Cuenta</Button>
            </StyledFormWrapper>
            
        
    )
}


const Wrapper = styled.div`
    display:grid;
    place-content: center;
    height: 100%;
`

const Text = styled.h3`
    margin: 0;
    font-size: 1.15rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[700]}
`

const StyledFormWrapper = styled(FormWrapper)`
    gap: 16px;

    @media ${p=>p.theme.media.tablet}{
        min-width: revert;
        padding: 0;
    }
    
`

const StyledFormTitle = styled(FormTitle)`
    margin: 0;
    padding: 0px 18px;
    
`

const StyledFormBody = styled(FormBody)`
    margin-bottom: 0;

`

export default ActivateCompanyUser;