import { addDoctor, addDoctors, addDoctorsSearch, addNextDoctors, loadingDoctors, loadingMoreDoctors} from "../doctorsReducer";
import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";


export const fetchDoctors = (filter, nextCursor, search)=> async(dispatch)=>{
 
    const options = createOptions('POST',{filter,nextCursor})
    
    let response;
      console.log(filter)
    try{
        if(nextCursor){
            dispatch(loadingMoreDoctors());
            response = await goFetch(apiUrl+ "doctors",options, dispatch);
            console.log(response);
            dispatch(addNextDoctors(response));            
        }else{
            console.log('ready to get doctors')
            dispatch(loadingDoctors())
            response = await goFetch(apiUrl+ "doctors",options, dispatch );
            console.log(response);
            if(search){
                dispatch(addDoctorsSearch(response));    
            }else{
                dispatch(addDoctors(response));    
            }
            
            
        }                        
    }catch(error){
        console.log(error);
    }        
}


// Not using it anymore, api is called from the component - March 23 2022
// export const crearDoctor = (values)=> async(dispatch)=>{

//     const options = createOptions('POST', values)
//     let doctor;
      
//     try{
//         doctor = await goFetch(apiUrl+ "doctors/create-doctor", options );
//         console.log(doctor);
//     }catch(error){
//         console.log(error);
//     }
// }


export const searchDoctors = (search)=> async(dispatch)=>{

    const options = createOptions('GET')
    let response;
      
    try{
        dispatch(loadingDoctors());
        response = await goFetch(apiUrl + 'doctors/search/'+ search,options, dispatch );
        console.log(response.doctors)
        dispatch(addDoctorsSearch({doctors: response.doctors}));
    }catch(error){
        console.log(error);
    }
    
    
}


export const fetchDoctor = (doctorId)=> async(dispatch)=>{

    const options = createOptions('GET')
    let doctor;
      
    try{
       doctor = await goFetch(apiUrl + 'doctors/'+ doctorId, options, dispatch );
       dispatch(addDoctor(doctor));
    }catch(error){
        console.log(error);
    }
    
    
}