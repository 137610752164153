import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';
import { useConfiguration } from "../../../hooks/useConfiguration";
import ciudades from "../../../shared/cities";







const CiudadesSelect = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name,    
})=>{


    const configuration = useConfiguration()
    const pais = configuration.country || 'republica_dominicana'
    const ciudadesPais = ciudades[pais]



    return(
        <FormControl fullWidth>
            <CustomInputMuiLabel>{label}</CustomInputMuiLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}                
                onChange={onChange}
                name="ciudad"
            >
                {
                    ciudadesPais.map((ciudad)=>(
                        <MenuItem key={ciudad} value={ciudad}>{ciudad}</MenuItem>
                    ))
                }                
                
            </Select>
        </FormControl>


    )
}


export default CiudadesSelect;