import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUsers } from '../../../app/thunkActions/usersActions';
import BackButton from '../../Atoms/BackButton';
import Roles from './Roles';
import UsersProfile from './UsersProfile';





const UsersDetails = ()=>{


    const dispatch = useDispatch();
    const {userId} = useParams();
    const { users, isLoading } = useSelector(state=>state.users);
    const navigate = useNavigate();
    
    
    const getUserFromUsersState = (userId)=>{
        const user = users.filter((user)=>(user._id===userId))[0]
        return user
    }

    const user = getUserFromUsersState(userId)
      // if doctor is available from previous navigation then use it. If not fetch from database. 
    React.useEffect(()=>{
        if(!user){
            dispatch(fetchUsers('all'))
        }                
    },[user, dispatch])

    
    return(
        <Wrapper>
            <BackButtonWrapper>
                 <BackButton onClick={()=>navigate(-1)}/>        
            </BackButtonWrapper>
            
            <UsersProfile user={user}/>
            <Roles user={user}/>            
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;

`

const BackButtonWrapper = styled.div`
    width: fit-content;
    align-self: flex-start;
    margin-left: 8px;

    @media ${p=>p.theme.media.tablet}{
        margin-top: 16px;
        margin-left: 24px;
    }
`


export default UsersDetails;