import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import React from 'react';
import useCirugia from '../../../../hooks/useCirugia';
import Button from '../../../Atoms/Button';

import { FormActions, FormBody, FormTitle, FormWrapper } from '../../../Atoms/Forms';
import Send from '../../../Icons/Send';



const Comentarios = ({
    cirugiaId,
    close
})=>{

    const [comments, handleChangeComments] = React.useState();
    const cirugia = useCirugia(cirugiaId);

    const onSubmit = ()=>{


    }

    return(
        <StyledFormWrapper >
            <StyledFormTitle>Comentarios evaluacion de la cirugia</StyledFormTitle>
            <StyledFormBody>
                <InnerWrapper>
                  { !cirugia.evaluacion?.razones.length >0 && !cirugia?.evaluacion?.comentarios && 
                            <Text>{'No se registraron razones o comentarios.'}</Text>

                  }  
                {
                    cirugia.evaluacion.razones && 
                    <RazonesList>
                        {
                        cirugia.evaluacion.razones.map((razon)=>(
                                <Razon>{razon}</Razon>
                                ))
                        }
                    </RazonesList>
                }
                {   cirugia?.evaluacion?.comentarios && 
                    <Text>{cirugia.evaluacion.comentarios}</Text>
                }
                

                </InnerWrapper>
                
            </StyledFormBody>
            <StyledFormActions>
                <Button 
                    primary
                    fullsize={true}
                    onClick={()=>close()}                    

                >Volver</Button>
            </StyledFormActions>
            

        </StyledFormWrapper>
    )
}


const StyledFormWrapper = styled(FormWrapper)`
    max-width:500px;
`

const StyledFormTitle = styled(FormTitle)`
    

`
const StyledFormBody = styled(FormBody)`
    margin-bottom: 8px;
`

const StyledFormActions = styled(FormActions)`
    padding-left: 4px;
    padding-right: 4px;

    @media ${p=>p.theme.media.tablet}{
        padding-left: 4px;
        padding-right: 4px;
    }
    

`

const Text = styled.p`
    margin:0;
    margin-bottom: 12px;
`

const RazonesList = styled.ul`
list-style:none;
display:inline;
padding-left: 24px;
margin:0;
margin-bottom: 12px;
    
@media ${p=>p.theme.media.tablet}{
    padding-left: revert;
}
`

const Razon = styled.li`
padding-bottom: 8px;
    position:relative;
    padding-left: 12px;
    margin-left: 12px;
    &::before{
        content: "\\2715";
        padding-right: 8px;
        color: ${p=>p.theme.palette.error.main};
        position:absolute;
        left: -12px;        
    }
`

const InnerWrapper = styled.div`
    align-self:center;
    display:flex;
    flex-direction: column;
    gap:8px;

`

export default Comentarios;