import React, { useState, useEffect } from "react";
//created by chat-gpt

const FadeIn = ({ duration, children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
  };

  return (
    <div
      style={{
        ...defaultStyle,
        ...(isMounted ? transitionStyles.entered : transitionStyles.entering),
      }}
    >
      {children}
    </div>
  );
};

export default FadeIn;





