import styled from '@emotion/styled';
import React from 'react';
import ProductosAutocomplete from '../Forms/CustomAutocomplete/ProductosAutocomplete';
import IconButton from '../Atoms/IconButton';
import Plus from '../Icons/Plus';
import { useTheme } from '@mui/material';
import XCircle from '../Icons/X-circle';

const ProductosAutocompleteAndList = ({productos, setProductos}) => {
  // Your component logic here

    const theme = useTheme();

    const [clear, setClear] = React.useState(false)  
    const [producto, setProducto] = React.useState("")
    
    
    
    
    const onChangeHandlerProducto = (event,option)=>{                    
        console.log(option)
        console.log(`On change handler producto`)
        if(!option)return;           
        setProducto(option)
}

const onAddProducto = ()=>{
    console.log(`On add producto`)
    console.log(producto)
    //set productos is an array
    setProductos(prevState => ([...prevState, producto]))
    setClear(true)
}


const onDeleteProducto = (index)=>{
    console.log(`On delete producto`)
    console.log(index)
    setProductos(prevState => (prevState.filter((item, i)=> i !== index)))
    
}
    



  return (
            <>    
                <ProductoWrapper>
                    <ProductoAutocompleteContainer>
                        <ProductosAutocomplete onChange={onChangeHandlerProducto} clear={clear} setClear={setClear}/> 
                    </ProductoAutocompleteContainer>
                    
                    <IconButton
                    type="button"
                    onClick={onAddProducto}
                    ><Plus color={theme.palette.grey[600]}/></IconButton>
                </ProductoWrapper>      
                    {productos.length > 0 && productos.map((producto, index)=>{
                        return (
                            <ProductItemContainer key={`${producto._id} ${index}`}>
                                <Text>{`${index+1}. ${producto.descripcion}`}</Text>                                    
                                <IconButton type="button" size="small"><XCircle color={theme.palette.error.main} size={18} onClick={()=>onDeleteProducto(index)}/></IconButton>                                        
                            </ProductItemContainer>
                        )})
                    }   
            </>
  );


};


const ProductItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;

`

const ProductoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 100%;
`

const ProductoAutocompleteContainer = styled.div`
    width: 100%;
    flex:3;
`

const Text = styled.p`

    font-size: 0.82rem;  
    font-weight: 400;
    color: ${props => props.theme.palette.text.primary};
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;
    
    
`

export default ProductosAutocompleteAndList;
