import React from 'react';
import TextInput from '../../Atoms/TextInput';
import { FormWrapper, FormTitle, FormBody } from '../../Atoms/Forms';
import { isEmail, isLength, validate } from '../../../shared/validators';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/system';

import useCheckForm from '../../../hooks/useCheckForm';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import Send from '../../Icons/Send';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useSuccessButton from '../../../hooks/useSuccessButton';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import CardError from '../../Atoms/CardError';

const InviteUser = ({
    showTitle=true,
    close
})=>{

    
    const isTablet = useMediaQuery('tablet');    
    const theme = useTheme();
    const user = useCurrentUser();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const {numberOfUsers:subscribedUsers, plan} = useSubscriptionToken();
    const { activeUsers} = useSelector(state=>state.users); 

    
    //changed activeUsers to activeUsers.length on Sept 2024, likely due to changes made a few weekg ago to user pages. 
    const canAddUsers = subscribedUsers >= activeUsers.length;

    const initialState = {
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',        
        email: '',
        role: '',
        companyId: user.company._id,
        companyName: user.company.name,
        inviterFirstname: user.firstname,
        inviterLastname: user.lastname
        
    }
    const [values,setValues] = React.useState(initialState)
    
    const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        email: '',
    })

    
    const [formCompleted, errorCount] = useCheckForm(values,errors, ["firstname", "lastname", "email"])
    
    
    const onChangeHandler = (event)=>{
        const {name, value} = event.target
        setValues(prevState => ({...prevState, [name]:value}))
    }

    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }


    const inviteUser = async(values)=>{
        setIsLoading(true)
        const options = createOptions('POST', values)
        let response;
          
        try{
            response = await goFetch(apiUrl+ "users/invite-company-user", options );
            console.log(response);
            responseStateChanges(response,null,()=>close())    
        }catch(error){
            console.log(error);
        }
    }


    const onSubmit = (event)=>{
        event.preventDefault();
        inviteUser(values);
    }

    const sendDisabled = !formCompleted || errorCount>0 || (!canAddUsers && plan !== 'trial')

    return(
        <FormWrapper onSubmit={onSubmit}>
            
            <FormTitle isTablet={isTablet}>Invitar Usuario</FormTitle>
            {!canAddUsers && plan !== 'trial' && <CardError>Para agregar usuarios debe aumentar el numero de usuarios suscritos.</CardError>}
            <br/>
            
            <FormBody isTablet={isTablet}>
                <TextInput
                    label="Primer Nombre" name="firstname" id="firstname"
                    value={values.firstname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Segundo Nombre" name="secondname" id="secondname"
                    value={values.secondname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={errors.secondname}
                    error={errors.secondname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Apellido" name="lastname" id="lastname"
                    value={values.lastname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />
                <TextInput
                    label="Segundo Apellido" name="secondLastname" id="secondLastname"
                    value={values.secondLastname}
                    onChangeHandler={(event)=>onChangeValues(event,[isLength])}
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    onBlurValidation={(event)=>onBlur(event, [isLength])}
                />                                               
                <TextInput
                    label="Email" name="email" id="email"
                    value={values.email}
                    onChangeHandler={(event)=>onChangeValues(event,[isEmail], true)}
                    helperText={!errors.email ? "Campo Obligatorio": errors.email}
                    error={errors.email}
                    onBlurValidation={(event)=>onBlur(event, [isEmail])}
                />
                                           
            </FormBody>
            <FormActionsBasic
                 close={close}
                 successEvent={successEvent}
                 loading={isLoading}
                 type="submit"
                 as="button"
                 disabledSuccess={sendDisabled}
                successText="Enviar"
                preIconSuccess={
                    <Send color={sendDisabled? theme.palette.grey[600] : '#ffff'} size={18}/>
                }                
            />
        </FormWrapper>
    )
}






export default InviteUser;