import Autocomplete from "../../Atoms/Autocomplete"
import React from 'react';
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";

//Wraps pacientes specific logic for autocomplete


const ClientsAutocomplete = ({
    onChange
})=>{
 
    const [clients, setClients] = React.useState([]);

    const onInputChange = async(e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length > 2){
            try{
                const options = createOptions('GET',{})
                const response = await goFetch(apiUrl + `clients/search/${e.target.value}`,options)
                if(response){
                    setClients(response)
                }
            }catch(error){
                console.log(error)
            }
            
            

        }
    }

    
    return(
        <Autocomplete                  
            label="Cliente"
            name="client"
            id="client"
            onChange={onChange}
            onInputChange={onInputChange}
            options={clients}
            getOptionLabel={(option)=>`${option.name}`}
        />
    )
}


export default ClientsAutocomplete;