import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "../app/messagesReducer";
import { getCookie } from "../shared/authHelpers";
import { apiUrl } from "../shared/url";

const useUploadFile = () => {
    const [progress, setProgress] = React.useState();
    const [isLoading, setIsLoading] = React.useState();
    const dispatch = useDispatch()

    const uploadFileAxios = (options) => {
        setIsLoading(true);
        setProgress(0);
        const formData = options.data
        const successMsg = options.successMessage
        const url = options.url
        const callback = options.callback
        
        // Only append 'accion' if it exists in options
        if (options.accion) {
            formData.append('accion', JSON.stringify(options.accion))
        }
        
        const token = getCookie('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                ...options.headers, // Include any custom headers
                'Content-Type': options.headers?.['Content-Type'] || 'application/json'
            },
            onUploadProgress: function(progressEvent) {
                var percentCompleted = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                setProgress(percentCompleted);
            }
        }

        axios.post(apiUrl + url, formData, config)
            .then(response => {
                dispatch(successMessage(response.data.message || successMsg || "Operacion exitosa!"));
                setIsLoading(false);
                callback && callback(response.data); // proposed by AI Agusto 20, used to not have response.data passed in
            })
            .catch((error) => {
                const errorMsg = error.response?.data?.error || error.message || "An error occurred";
                dispatch(errorMessage(errorMsg));
                setIsLoading(false);
                console.error('Error:', error)
            })
    }

    return [uploadFileAxios, isLoading, progress]
}

export default useUploadFile;