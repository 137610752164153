import { validate } from "../shared/validators";




const useForm = ({
    setValues,
    setErrors,
    errors
})=>{

    //updates the input values, no validation
    const onChangeHandler = (event)=>{
        const {name, value} = event.target        
        setValues(prevState => ({...prevState, [name]:value}))
    }

    //registers the input values and validates them
    const onChangeValuesValidation = (event,validators, postCheck) => {        
        const {name, value} = event.target
        
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){            
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    //disables autocomplete
    const onFocusDisableAutocomplete = (event, validators)=>{
        event.target.setAttribute('autocomplete', 'off');
                    
    }

    //validates the input values onBlur
    const onBlurValidate = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target        
        const error = validate(value, validators)        
        setErrors(prevState=>({...prevState, [name]:error}))
        return error
    }

    const trimValue = (value) => {
        if (typeof value === 'string') {
            // Trim and normalize spaces
            return value.trim().replace(/\s+/g, ' ');
        }
        return value;
    };

    return {onChangeHandler, onChangeValuesValidation, onFocusDisableAutocomplete, onBlurValidate, trimValue}

}


export default useForm;