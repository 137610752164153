import React from "react";
import { validate } from "../shared/validators";



const usePasswordMatch = (errors, setErrors)=>{
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordMatch, setPasswordMatch] = React.useState(false)

    React.useEffect(()=>{
        if(password.length>0 && password === confirmPassword){
            if(passwordError){
               setPasswordError(false)     
            }
            setPasswordMatch(true)
        }else{
            setPasswordMatch(false)
        }

    },[password,confirmPassword, passwordError])

    const onChangePasswordValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setPassword(value);
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onBlurConfirmPassword = (event)=>{
        if(password !== confirmPassword){
            setPasswordError(true)

        }
    }


    return [password, passwordError, passwordMatch,confirmPassword, setConfirmPassword, onChangePasswordValues, onBlurConfirmPassword]

}




export default usePasswordMatch;