import { addCentro, addCentros, loadingCentros} from "../centrosReducer";
import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";

export const fetchCentros = (filter, nextCursor)=> async(dispatch)=>{

    const options = createOptions('GET')
    let centros;
      
    try{
        dispatch(loadingCentros());
        centros = await goFetch(apiUrl+ "centros",options );
    }catch(error){
        console.log(error);
    }

    dispatch(addCentros(centros));

}



export const searchCentros = (search)=> async(dispatch)=>{

    const options = createOptions('GET')
    let centros;
      
    try{
        dispatch(loadingCentros());
        centros = await goFetch(apiUrl + 'centros/search/'+ search, options);       
        dispatch(addCentros(centros));
    }catch(error){
        console.log(error);
    }    
}


export const fetchCentro = (centroId)=> async(dispatch)=>{

    const options = createOptions('GET')
    let centro;
      
    try{
       centro = await goFetch(apiUrl + 'centros/'+ centroId, options );
       dispatch(addCentro(centro));
    }catch(error){
        console.log(error);
    }
    
    
}

