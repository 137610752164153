import React from 'react';
import styled from '@emotion/styled/macro';
import TextInput from '../Atoms/TextInput';
import Button from '../Atoms/Button';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { isEmail, isLength, isCedula, isTelefono,  validate } from '../../shared/validators';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useDispatch } from 'react-redux';
import Plus from '../Icons/Plus';
import CentrosAutocomplete from './CustomAutocomplete/CentrosAutocomplete'
import SmallButton from '../Atoms/SmallButton';
import XCircle from '../Icons/X-circle';
import { useTheme } from '@mui/system';
import useCentrosArray from '../../hooks/useCentrosArray';
import EspecialidadSelect from './CustomAutocomplete/EspecialidadSelect';
import useCheckForm from '../../hooks/useCheckForm';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import { addDoctor } from '../../app/doctorsReducer';
import useSuccessButton from '../../hooks/useSuccessButton';
import useForm from '../../hooks/useForm';
import { useConfiguration } from '../../hooks/useConfiguration';

const CrearDoctor = ({
    showTitle=true,
    close,
    doctor,
    edit
})=>{

    
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();
    // const [isLoading,setIsLoading] = React.useState(false);
    // const [successEvent, setSuccessEvent] = React.useState(false);
    const theme = useTheme();
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    
    const configuration = useConfiguration()
    const country = configuration?.country || "republica_dominicana"
    const showDoctorId = configuration?.doctor?.id?.show
    const isDoctorIdRequired = configuration?.doctor?.id?.required
    const doctorIdName = configuration?.doctor?.id?.name || "Cedula"

    const initialState = {
        firstname: doctor?.firstname || '',
        secondname: doctor?.secondname || '',
        lastname: doctor?.lastname || '',
        secondLastname: doctor?.secondLastname || '',
        cedula: doctor?.cedula || '',
        email: doctor?.email || '',
        especialidad: doctor?.especialidad || '',
        ventas: doctor?.ventas || '',
        centros: doctor?.centros || [],
        telefono: doctor?.telefono || ""
        }

    const [values,setValues] = React.useState(initialState)

    const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        email: '',
    })


    const [onAddCentro, onDeleteCentro, onChangeCentros, centrosArray, centrosError]= useCentrosArray(values,setValues)
    const {onChangeHandler, onChangeValuesValidation, onBlurValidate} = useForm({setValues, setErrors, errors})
    const [formCompleted, errorCount] = useCheckForm(values,errors, ["firstname", "lastname", "especialidad"])
    
    
    

    const onSubmit = async (event)=>{
        event.preventDefault();
        const options = createOptions('POST', values)
        let response;
        setIsLoading(true)
        let path = edit ? `doctors/update/${doctor._id}` : 'doctors/create-doctor';
    
        response = await goFetch(apiUrl+path, options, dispatch );
        responseStateChanges(response, 
            ()=>{
                
                if(edit) {
                    console.log('in edit, should update doctor')
                    dispatch(addDoctor(response.doctor))
                }
            }, 
            ()=>{
                if(!edit){
                    setValues(initialState)
                    close();
                };
            })        
                                
    }

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            
            <FormTitle isTablet={isTablet}>{!edit ? "Crear Doctor": "Editar Doctor" }</FormTitle>
            
            <FormBody isTablet={isTablet}>
                <TextInput
                    label="Primer Nombre" name="firstname" id="firstname"
                    value={values.firstname}                    
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isLength])}
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    onBlurValidation={(event)=>onBlurValidate(event, [isLength])}
                />
                <TextInput
                    label="Segundo Nombre" name="secondname" id="secondname"
                    value={values.secondname}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isLength])}
                    helperText={errors.secondname}
                    error={errors.secondname}
                    onBlurValidation={(event)=>onBlurValidate(event, [isLength])}
                />
                <TextInput
                    label="Apellido" name="lastname" id="lastname"
                    value={values.lastname}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isLength])}
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    onBlurValidation={(event)=>onBlurValidate(event, [isLength])}
                />
                <TextInput
                    label="Segundo Apellido" name="secondLastname" id="secondLastname"
                    value={values.secondLastname}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isLength])}
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    onBlurValidation={(event)=>onBlurValidate(event, [isLength])}
                />
                <EspecialidadSelect
                    label="Especialidad"
                    id="especialidad"
                    name="especialidad"
                    value={values.especialidad}
                    onChange={onChangeHandler}
                    helperText={"Campo Obligatorio"}
                />
                <Text>Campo Obligatorio</Text>
                {showDoctorId &&
                    <TextInput
                        label={`${doctorIdName}`} name="cedula" id="cedula"
                        value={values.cedula}
                        onChangeHandler={(event)=>onChangeHandler(event,[(e)=>isCedula(e,country)], true)}
                        helperText={!errors.cedula ? isDoctorIdRequired ? "Campo Obligatorio" : "": errors.cedula}
                        error={errors.cedula}
                        onBlurValidation={(event)=>onBlurValidate(event, [(e)=>isCedula(e,country)])}
                    />
                }
                <TextInput
                    label="Telefono" name="telefono" id="telefono"
                    value={values.telefono}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[(event)=>isTelefono(event,country)], true)}
                    helperText={errors.telefono}
                    error={errors.telefono}
                    onBlurValidation={(event)=>onBlurValidate(event, [(event)=>isTelefono(event,country)])}
                />
                <TextInput
                    label="Email" name="email" id="email"
                    value={values.email}
                    onChangeHandler={(event)=>onChangeValuesValidation(event,[isEmail], true)}
                    helperText={errors.email}
                    error={errors.email}
                    onBlurValidation={(event)=>onBlurValidate(event, [isEmail])}
                />
                {
                    !edit && 
                    <>
                        <Item style={{display:"flex", justifyContent:"flex-start", alignItems:"center", gap:'8px'}}>
                            <Button primary onClick={onAddCentro} preIcon={<Plus color="#ffff" size="16"/>}>Centro</Button>
                            {
                                centrosArray.length>0 && 
                                <SmallButton onClick={onDeleteCentro}><XCircle size="20" color={theme.palette.error.light}/></SmallButton>                        
                            }
                                {centrosError && <Error>{centrosError}</Error>}
                        </Item> 
                        <Item>
                            {centrosArray.map((centro, index)=>(
                                <CentroWrapper key={`${centro}${index}`}>
                                    <CentrosAutocomplete onChange={(event, option)=>onChangeCentros(event, option, index)}/>
                                </CentroWrapper>
                            ))                    
                            }
                        </Item>
                    </>
                }
                
                
                
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={!formCompleted || errorCount>0}
            />
        </StyledFormWrapper>
    )
}


const StyledFormWrapper = styled(FormWrapper)`
    
    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }
`


const Item = styled.div`
    width:100%;
`
const CentroWrapper = styled.div`
    margin-bottom: 8px;
`

const Error = styled.p`
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.error.light}
`


const Text = styled.p`
margin: 0;
margin-left:10px;
margin-top: -8px;
font-size: 0.675rem;
color: ${p=>p.theme.palette.grey[600]}
`
export default CrearDoctor;