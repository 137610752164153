import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../../api/cirugiasApi';
import { errorMessage } from '../../../../app/messagesReducer';
import { useAccionMaker } from '../../../../hooks/useAccionMaker';
import useUpdateCirugia from '../../../../hooks/useUpdateCirugia';
import { apiUrl } from '../../../../shared/url';
import Button from '../../../Atoms/Button';
import { Checkbox, FormControlCheckbox } from '../../../Atoms/Checkbox';
import FormActionsBasic from '../../../Atoms/FormActionsBasic';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../../../Atoms/Forms';
import Send from '../../../Icons/Send';



const NegativeReview = ({
    cirugiaId,
    close
})=>{
    const dispatch = useDispatch();
    const accionMaker = useAccionMaker()
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);    

    const [comments, handleChangeComments] = React.useState();

    const [reasons, setReasons] = React.useState({
        materialIncompleto: {
            state: false,
            text: "Material aprobado incompleto"
        },
        equiposIncompletos: {
            state: false,
            text: "Equipos incompletos"
        },
        fallaTecnicaMaterial:{
            state:false,
            text: "Falla tecnica del material aprobado"
        },
        fallaTecnicaEquipo: {
            state: false,
            text: "Falla tecnica de algun equipo"
        },
        tardeEc: {
            state: false,
            text: "Llegada tarde de Espcialista Clinico"
        },
        malServicioEc: {
            state: false,
            text: "Problemas con el servicio del Especialista Clinico"
        },
        otros: {
            state: false,
            text: "otros"
        }
    });
    console.log(reasons);

    const onSubmit = async(event)=>{
        event.preventDefault();    
                
        const accion = accionMaker('Doctor dejo comentarios sobre su evaluacion.')
        let reasonsArray = []
        for(let reason in reasons){            
            if(reasons[reason].state){                
                reasonsArray.push(reasons[reason].text);
            }            
        } 

        const info={
            cirugiaId,
            razones: reasonsArray,
            comentarios: comments,
            accion
        }

        updateCirugia('mdreview',info)

        // const options = createOptions('POST', info)

        // try{
        //     const cirugia = await goFetch(`${apiUrl}cirugias/mdReview/${cirugiaId}`, options);
        //     setIsLoading(false)    
        //     setSuccessEvent(true)
        //     setTimeout(()=>setSuccessEvent(false), 2000)            
        // }catch(error){ 
        //     dispatch(errorMessage('Hubo un problema, no se guardo la informacion.'));
        //     setIsLoading(false);            
        // }  


        
    }

   
    const options = [
        {label: reasons.materialIncompleto.text, name: 'materialIncompleto', checked: reasons.materialIncompleto.state},        
        {label: reasons.equiposIncompletos.text, name: 'equiposIncompletos', checked: reasons.equiposIncompletos.state},        
        {label: reasons.fallaTecnicaMaterial.text, name: 'fallaTecnicaMaterial', checked: reasons.fallaTecnicaMaterial.state},        
        {label: reasons.fallaTecnicaEquipo.text, name: 'fallaTecnicaEquipo', checked: reasons.fallaTecnicaEquipo.state},        
        {label: reasons.tardeEc.text, name: 'tardeEc', checked: reasons.tardeEc.state},        
        {label: reasons.malServicioEc.text, name: 'malServicioEc', checked: reasons.malServicioEc.state},        
        {label: reasons.otros.text, name: 'otros', checked: reasons.otros.state},        
    ]


    const onChangePermissions = (event)=>{         
        let reasonObj = reasons[event.target.name]
        console.log(reasonObj)
        reasonObj.state= event.target.checked
        
        setReasons(prevState=>({...prevState, [event.target.name]:reasonObj}))
    }

    

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <StyledFormTitle>Experiencia Negativa</StyledFormTitle>
            <StyledFormBody>
                <Text>Sentimos mucho que no hayas tenido una buena experiencia en tu cirugia. Por favor provee mas informacion.</Text>
                
                <InnerWrapper>
                    { options?.map((option,index)=>(
                        <FormControlCheckbox 
                            key={`${option} ${index}`}
                            disabled={option.disabled} 
                            id={option.name}
                        >
                            <Checkbox label={option.label} name={option.name} checked={option.checked} onChange={onChangePermissions}/>
                        </FormControlCheckbox>

                    ))
                        
                    }
                </InnerWrapper>

                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />
            </StyledFormBody>
            <StyledFormActions>
                <Button
                    type="submit" 
                    as="button"
                    success
                    fullsize={true}
                    preIcon={<Send color="#ffff" size="18"/>}
                    successEvent={successEvent}
                    loading={isLoading}
                >Enviar</Button>
            </StyledFormActions>
            

        </StyledFormWrapper>
    )
}


const StyledFormWrapper = styled(FormWrapper)`
    max-width:500px;
    align-self: center;
`

const StyledFormTitle = styled(FormTitle)`
    margin-bottom: 18px;

`
const StyledFormBody = styled(FormBody)`
    margin-bottom: 8px;
`

const StyledFormActions = styled(FormActions)`
    padding-left: 4px;
    padding-right: 4px;

    @media ${p=>p.theme.media.tablet}{
        padding-left: 4px;
        padding-right: 4px;
    }
`

const InnerWrapper = styled.div`
    align-self:center;
    padding-bottom: 8px;

`

const Text = styled.p`
    margin:0;
    margin-bottom: 12px;
`



export default NegativeReview;