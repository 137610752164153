import { FormControl } from "@mui/material"
import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';

const ClasificacionSelect = ({
    value, 
    onChange,
    labelId,
    id,
    label,
    name
})=>{


    return(
        <FormControl fullWidth>
            <CustomInputMuiLabel>{label}</CustomInputMuiLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}                
                onChange={onChange}
                name={name}
            >
               <MenuItem value="Excelente">Excelente</MenuItem>
                <MenuItem value='Buena'>Buena</MenuItem>
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Mala">Mala</MenuItem>
            </Select>
        </FormControl>


    )
}

export default ClasificacionSelect