import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import useCheckForm from '../../../hooks/useCheckForm';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import { isMaxLength, validate } from '../../../shared/validators';
import Button from '../../Atoms/Button';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import TextInput from '../../Atoms/TextInput';
import useForm from '../../../hooks/useForm';






const ProductosAdd = ({
    close
})=>{

    const dispatch = useDispatch();  
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    //useForm
    const [values, setValues] = React.useState({
        codigo:"",
        descripcion:"",
        fabricante:""
    })

    const [errors, setErrors] = React.useState({
        codigo:"",
        descripcion:"",
        fabricante:""
    })

    const {onChangeValuesValidation} = useForm({setValues, setErrors, errors});


    const [formCompleted, errorCount] = useCheckForm(values,errors, [
        "codigo",
        "descripcion"        
        ])

    const onSubmit= async(event)=>{
        event.preventDefault();
        setIsLoading(true)
     
        const options = createOptions("POST", values)
        try{
            const response = await goFetch(apiUrl + "productos", options)
            responseStateChanges(response, null, ()=>close())                                
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        

    }

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle>Agregar producto</FormTitle>
            <StyledFormBody>
                <TextInput 
                    label="Codigo"
                    name="codigo"
                    value={values.codigo}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(20, event.target.value, true)])}                                        
                    helperText={!errors.codigo ? "Campo Obligatorio": errors.codigo}
                    error={errors.codigo}                    
                ></TextInput>
                <TextInput 
                    label="Descripcion"
                    name="descripcion"
                    value={values.descripcion}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(200, event.target.value)])}                    
                    helperText={!errors.descripcion ? "Campo Obligatorio": errors.descripcion}
                    error={errors.descripcion}                     
                ></TextInput>
                <TextInput 
                    label="Fabricante"
                    name="fabricante"
                    value={values.fabricante}
                    onChangeHandler={(event) => onChangeValuesValidation(event, [() => isMaxLength(100, event.target.value)])}                                        
                    helperText={errors.fabricante}
                    error={errors.fabricante}                     
                ></TextInput>
            </StyledFormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"                                
                loading={isLoading}
                successEvent={successEvent}   
                type="submit"
                as="button"       
                disabledSuccess={!formCompleted || errorCount>0}  
            />
            
        </StyledFormWrapper>
    )

}


const StyledFormWrapper = styled(FormWrapper)`
    padding-left:12px;
    padding-right:12px;

    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }
    

`

const StyledFormBody = styled(FormBody)`
flex:2;
 margin-bottom: 18px;
`


export default ProductosAdd;