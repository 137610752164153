import {createSlice } from '@reduxjs/toolkit';


const initialState= {
    clients:[],
    nextCursor: null,
    isLoading: true
}

export const clientsSlice = createSlice({
    name:"clients",
    initialState,
    reducers: {
        addClients(state, action){
           // console.log(action.payload);
            state.clients = action.payload.clients;
            state.nextCursor = action.payload.nextCursor;
            state.isLoading= false;            
        },
        addClientsSearch(state, action){
            state.clients = action.payload;
            state.nextCursor = null;
        },       
        updateClientsArray(state,action){                        
            state.clients.forEach((client, index)=>{                
                if(client._id === action.payload._id){
                    state.clients[index] = action.payload
                }
                return
            })            
        },
        initClients(state,action){
            state.clients = [];
        }        
    }
})

const {actions, reducer} = clientsSlice

export const {addClients, addClientsSearch, updateClientsArray, initClients} = actions;

export default reducer;