import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { addPacientesSearch, pacientesIsLoading, resetPacientes, setSearchValue } from "../../../app/pacientesReducer";
import { searchPacientes } from "../../../app/thunkActions/pacientesActions";
import isEmpty from "../../../shared/isEmptyOject";
import { apiUrl } from "../../../shared/url";

import SearchBar from "../../Atoms/SearchBar";




const PacientesSearchBar = ({isLoading})=>{
    
    const { searchValue } = useSelector(state=>state.pacientes);    

    const dispatch = useDispatch();

    const onChangeSearchValue = async(event)=>{
        event.preventDefault();
        const searchTerm = event.target.value
        console.log(searchTerm)
        dispatch(setSearchValue(searchTerm))
        if(searchTerm.length>2){
            dispatch(pacientesIsLoading())
            //dispatch(searchPacientes(searchTerm))  
            
            const options = createOptions('GET')
            let pacientes;
            
            try{
                pacientes = await goFetch(apiUrl+ "pacientes/search/"+searchTerm,options );
                dispatch(addPacientesSearch(pacientes));            
            }catch(error){
                console.log(error);
            }


        }
        // changed this since it was reseting after every keystroke which. 
        //  if(!searchTerm.length<3){
        //      console.log('resetting pacientes')
        //     dispatch(resetPacientes({}))
        //  }

          if(searchTerm.length<3){
             console.log('resetting pacientes')
            dispatch(resetPacientes({}))
         }
         

    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar Paciente"
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoading}
    />
    )
}



export default PacientesSearchBar