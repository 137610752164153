import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetMds } from "../../../app/mdReducer";
import { searchMds } from "../../../app/thunkActions/mdsActions";

import SearchBar from "../../Atoms/SearchBar";




const MdSearchBar = ({
    firstRender,
    setFirstRender,
    resetSearchValue,
   setResetSearchValue,
   access,
   setAccess
})=>{
    const [searchValue, setSearchValue] = React.useState();
    const {mds, isLoading} = useSelector(state=>state.mds)

    React.useEffect(()=>{
        setSearchValue('')
    },[resetSearchValue])

    const dispatch = useDispatch();

    const onChangeSearchValue = (event)=>{
        event.preventDefault();
        if(access)setAccess("");
        
        const searchTerm = event.target.value
        setSearchValue(searchTerm)
        setResetSearchValue(false)
        if(searchTerm.length>2){
            if(firstRender)setFirstRender(false);
            
            dispatch(searchMds(searchTerm))
        }

        if(!searchTerm){
            setFirstRender(true)
            setResetSearchValue(false)
            dispatch(resetMds())
        }

                 
    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar"
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoading}
    />
    )
}



export default MdSearchBar