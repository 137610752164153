import styled from '@emotion/styled';
import Chip from '../Atoms/Chip';
import EspecialistaChip from './Chips/EspecialistaChip';
import DocumentacionChip from './Chips/DocumentacionChip';
import StatusChip from './Chips/StatusChip';
import useMediaQuery from '../../hooks/useMediaQuery';
import ReporteChip from './Chips/ReporteChip';
import OperacionesChip from './Chips/OperacionesChips';
import FacturacionChip from './Chips/FacturacionChip';
import CheckInChip from './Chips/CheckInChip';
import usePermissions from '../../hooks/usePermissions';
import EvaluacionChip from './Chips/EvaluacionChip';
import MdAgendaCirugiaChip from './Chips/MdAgendaCirugiaChip';
import useSubscriptionToken from '../../hooks/useSubscriptionToken';


const ChipContainer = ({cirugia})=>{
    const isPhone = useMediaQuery('phone');
    const permissions = usePermissions()
    const {canViewFacturacionStages} = permissions;
    const subscription = useSubscriptionToken();
    const planAllowsCheckIn = subscription.companyPermissions.includes('checkIn');
    let especialidad;

    if(cirugia?.especialidad === "Neurocirugia") {
        especialidad = "Neuro";
    }else{
        especialidad = cirugia.especialidad;
    }
    return(
        <Wrapper isPhone={isPhone}>
            <EvaluacionChip cirugia={cirugia}/>
            <Chip label={especialidad} primary/>
            {/*<EspecialistaChip especialista={cirugia?.especialista}/>*/}
            <MdAgendaCirugiaChip doctorAgendo={cirugia?.info?.agendadaDoctor}/>
            <DocumentacionChip pago={cirugia?.pago}/>
            {planAllowsCheckIn && <CheckInChip checkIn={cirugia.especialista?.checkIn}/>}
            <StatusChip info={cirugia?.info}/>
            <ReporteChip cirugia={cirugia}/>
            <OperacionesChip statusOperaciones={cirugia.info?.statusOperaciones}/>
            {canViewFacturacionStages && <FacturacionChip statusFacturacion={cirugia.info?.statusFacturacion}/>}
        </Wrapper>
    )

}


const Wrapper = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;     
    min-width: ${p=>p.isPhone?'50%': 'none'};
    
`



export default ChipContainer;

