
import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import { successMessage } from "../../../app/messagesReducer";
import formatDate from "../../../shared/dateFormatter";
import formatTime from "../../../shared/timeFormatter";
import IconButton from "../../Atoms/IconButton"
import Copy from "../../Icons/Copy"
import useDateAndTimeFormatter from "../../../hooks/useDateAndTimeFormatter";





const CopyCirugia  = ({
    cirugia
})=>{

    const theme = useTheme();
    const {formatTime} = useDateAndTimeFormatter()


    const dispatch = useDispatch();


  const listaProductos =(productos)=>{
    let lista="\n"

    productos.forEach((producto)=>{
      lista = lista +"-" + producto.producto.codigo + " " + producto.producto.descripcion +" : " + producto.cantidad + " und. \n"
    })

    return lista
  }

  const copyCirugiaClipboard = async(cirugia)=>{
        
     await navigator.clipboard.writeText(
`Fecha: ${formatDate(cirugia.info.fecha)}
Hora: ${formatTime(cirugia.info.fecha)}
Cotización: ${cirugia.cotizacion}
Paciente: ${cirugia.paciente.firstname + " " + cirugia.paciente.lastname}
Doctor: ${cirugia.doctor.firstname + " " + cirugia.doctor.lastname}
Centro: ${cirugia.centro.nombre}
Ventas: ${cirugia?.ventas?.firstname || ""} ${cirugia?.ventas?.lastname || ""}
EC: ${cirugia?.especialista?.nombre?.firstname || ""} ${cirugia?.especialista?.nombre?.lastname || ""}

Productos: ${listaProductos(cirugia.productos)}`)
                    //.then(()=>{dispatch(successMessage("Cirugia copiada"))})

    dispatch(successMessage("Cirugia copiada"))

  }




    return(
        <IconButton  onClick={()=>copyCirugiaClipboard(cirugia)}>
            <Copy size="20" color={theme.palette.grey[800]}/>
        </IconButton>
    )

}



export default CopyCirugia