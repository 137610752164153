import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import Button from '../../Atoms/Button';



const Registra = ()=>{
    const theme = useTheme();
    return(
            <MainRowWrapper style={{gap:'8px', justifyContent: 'space-between'}}>                                                      
                        <Info style={{maxWidth: '500px'}}>
                            
                                    <MainTitle><span style={{color: theme.palette.success.light, fontWeight:300}}>Plataforma destinada a facilitar la </span><span style={{color: theme.palette.primary.dark}}>gestion de cirugias a distribuidores de dispositivos medicos</span></MainTitle>                        
                                
                                <RegisterWrapper>    
                                    <Actions>
                                        <RegisterButton success to={"register-company"}>Registra tu empresa</RegisterButton>                    
                                        {/*<Button success to={"md-register"}>Personal Medico</Button>*/}
                                    </Actions>
                                </RegisterWrapper>
                                
                                
                        </Info>
                        <ImageWrapper style={{maxWidth: '500px'}}>
                                <Image src="/img/mainPage1.svg" />
                        </ImageWrapper>
                    </MainRowWrapper>    

    )
}



const MainRowWrapper = styled.div`
    display:flex;
    gap: 48px;
    width:100%;
    min-height:100%;
    flex-wrap: wrap;
    flex-direction: ${p=>p.reverse ? 'column-reverse' : 'revert'};
    

    @media ${p=>p.theme.media.laptop}{
        flex-direction: row;
    }

`


const Info = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: 12px;    
    width:100%;
    padding: 8px;        
`


const MainTitle=styled.h1`
    margin:0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    color: ${p=>p.theme.palette.grey[800]};
    font-size: 2.25rem;

    @media ${p=>p.theme.media.laptop}{
        font-size: 2.5rem;
    }
`

const RegisterWrapper = styled.div`
    display:flex;
    flex-direction: column;
    aling-items: center;
    width:100%;    
    min-width: 350px;
`

const Actions = styled.div`
    display:flex;
    gap: 8px;
    justify-content: center;
`

const RegisterButton = styled(Button)`
    border-radius: 18px;
    background: linear-gradient(90deg, rgba(0,141,54,1) 0%, rgba(57,169,53,1) 35%, rgba(147,192,31,1) 100%);
`

const ImageWrapper = styled.div`
    flex: 1;
    width:100%;

    min-width:350px;
    max-width:500px;
    display:flex;
    flex-direction: column;
    
`

const Image = styled.img`    
    width:100%;
    height: 100%;
    object-fit:contain;
`

export default Registra