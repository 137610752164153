import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useTheme } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../../api/cirugiasApi';
import { addUpdatedCirugia } from '../../../../app/cirugiasReducer';
import { errorMessage } from '../../../../app/messagesReducer';
import { useAccionMaker } from '../../../../hooks/useAccionMaker';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { apiUrl } from '../../../../shared/url';
import IconButton from '../../../Atoms/IconButton';
import ThumbsDown from '../../../Icons/ThumbsDown';
import ThumbsUp from '../../../Icons/ThumbsUp';
import BeatLoader from 'react-spinners/BeatLoader'
import Check from '../../../Icons/Check';
import CheckCircle from '../../../Icons/CheckCircle';



const Review = ({
    handleOnClick,
    cirugiaId
})=>{
    const theme = useTheme();
    const user = useCurrentUser();
    const dispatch = useDispatch()
    const accionMaker = useAccionMaker()
    const[successEvent, setSuccessEvent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    


    const sendReview = async(url,review, accionText)=>{
        setIsLoading(true);
        const accion = accionMaker(accionText);
        const info = {
            evaluacionPositiva: review==="thumbsUp"?true: false,
            evaluacionNegativa: review==="thumbsDown"?true: false,
            autor: {
                _id: user._id,
                firstname: user.firstname,
                lastname: user.lastname
            },
            accion
        }

        const options = createOptions('POST', info)

        try{
            const cirugia = await goFetch(`${apiUrl}${url}`, options);
            setIsLoading(false)    
            setSuccessEvent(true)
            setTimeout(()=>{
                handleOnClick(review, cirugiaId);
                setSuccessEvent(false);
                dispatch(addUpdatedCirugia(cirugia))
            }, 1000)
        }catch(error){
            console.log(error);
            dispatch(errorMessage('Hubo un problema, no se guardo la informacion.'));
            setIsLoading(false);            
        }          


    }

   const onClickThumbsUp = async ()=>{
        sendReview(`cirugias/thumbs/${cirugiaId}`, 'thumbsUp', 'Doctor califico como positiva la cirugia.')      
   }

   const onClickThumbdsDown = async ()=>{
        sendReview(`cirugias/thumbs/${cirugiaId}`, 'thumbsDown', 'Doctor califico como negativa la cirugia.')      
   }

   

    return(
        <ReviewWrapper>
        
            <IconButton onClick={onClickThumbsUp}>
                    <ThumbsUp color={theme.palette.success.light}/>
                </IconButton>
                <IconButton                 
                    onClick={onClickThumbdsDown}
                    successIcon={<ThumbsDown color={theme.palette.error.light}/>}
                >
                    <ThumbsDown color={theme.palette.error.light}/>
                </IconButton>
                {(isLoading || successEvent) && 
                        <Layover >
                            {isLoading && <BeatLoader size={8} color={theme.palette.grey[500]} />}
                            {successEvent && <LayoverAnimatedIconWrapper>{<CheckCircle size={26} color={theme.palette.success.light}/>}</LayoverAnimatedIconWrapper>}
                        </Layover>
                }                                                               
        </ReviewWrapper>

    )
}


const bounce = keyframes`
    0%{
        transform: scale(0.5)
    }

    50%{
        transform: scale(0.75)
    }

    80%{
        transform: scale(1)
    }

    90%{
        transform: scale(1.2)
    }

    95%{
        transform: scale(1.1)
    }
    100%{
        transform: scale(1.0)

    }
`


const ReviewWrapper = styled.div`
    padding: 4px;
    border: 1px solid;
    display:flex;
    border-radius: 8px;
    color: ${p=>p.theme.palette.grey[300]};
    align-items:center;
    position:relative;
    overflow: hidden;
`

const Layover = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius: 4px;
    display: grid;
    place-content:center;
    background-color: #ffff;
`
const LayoverAnimatedIconWrapper= styled.div`
    animation: ${bounce} 1s ease-out;
`

export default Review