import React from 'react';
import styled from '@emotion/styled';
import useColor from '../../hooks/useColor';
import { useTheme } from '@mui/system';



export const FormControlCheckbox =({
    children, 
    color="primary",
    onChange,
    disabled
})=>{
    const theme = useTheme();
    let [selectedColor] = useColor(color);


    if(disabled) selectedColor = theme.palette.grey[400]
    
    /*const onChange = (event)=>{
        console.log(event.target.value)
    }
    */
    return(
        <FormControl style={{"--selected-color": selectedColor}} onChange={onChange}>
            {children}
        </FormControl>
    )

}


export const Checkbox = ({
    id,
    name,
    color,
    label,
    value,
    checked,
    disabled,
    ...props
})=>{

    
    return(
        <CheckboxWrapper>            
            <StyledInput type ="checkbox" checked={checked} id={id} name={name} value={value} disabled={disabled} {...props} />       
            <StyledLabel htmlFor={id} name={name} disabled={disabled}>
            
                {label}
            </StyledLabel>
        </CheckboxWrapper>
    )
}

const FormControl = styled.div`
    width: 100%;
    display:flex;
    align-items:center;
    gap:8px;
`

const CheckboxWrapper = styled.div`
    display:flex;
    gap:4px;
    align-items:center;
`

const StyledInput = styled.input`
    appearance: none;
    background-color: #fff;
    margin: 2px;
    font: inherit;
    width: 1.15rem;
    height: 1.15rem;
    border: 2px solid ${p=>!p.disabled ? 'var(--selected-color)' : p.theme.palette.grey[500]};
    display:grid;
    place-content:center;
    cursor: ${p=>!p.disabled ? 'pointer' : 'revert'};


    &::before{
        content:"";
        width: 0.60em;
        height: 0.60em;
        
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--selected-color);
    }

    &:checked::before{
        transform: scale(1);
    }
    
`

const StyledLabel = styled.label`
    font-size:0.875rem;
    color: ${p=>!p.disabled ? p.theme.palette.grey[700] : p.theme.palette.grey[500]}
`
