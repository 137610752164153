import styled from '@emotion/styled/macro';
import useMediaQuery from '../../hooks/useMediaQuery';
import { CardBody,CardTitle, CardWrapper as Card } from "../Atoms/Card";
import Avatar from '../Atoms/Avatar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctors } from '../../app/thunkActions/doctorsActions';
import DoctorsSearchBar from './Molecules/DoctorsSearchBar';
import DoctorCardGrid from './Molecules/DoctorCardGrid';
import DoctorsListLoading from './Molecules/DoctorsListLoading';
import BackButton from '../Atoms/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Plus from '../Icons/Plus';
import UploadCloud from '../Icons/UploadCloud';
import { useTheme } from '@mui/system';
import IconButton from '../Atoms/IconButton';
import Modal from '../Atoms/Modal';
import CrearDoctor from '../Forms/CrearDoctor';
import DoctorsUpload from './Molecules/DoctorsUpload';
import Button from '../Atoms/Button';
import DoctorsFiltros from './Molecules/DoctorFiltros';
import CardError from '../Atoms/CardError';
import DoctorNew from './Molecules/DoctorNew';
import DoctorCreate from './Molecules/DoctorCreate';
import DoctorFusionar from './Molecules/DoctorFusionar';
import usePermissions from '../../hooks/usePermissions';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import CardInfo from '../Atoms/CardInfo';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import useSubscriptionToken from '../../hooks/useSubscriptionToken';


const DoctorsList=()=>{

    const dispatch = useDispatch();    
    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');
    const permissions = usePermissions()
    const user = useCurrentUser();
    const {canCrearEditarInvitarDoctores} = permissions;
    const { doctorsSearch, isLoading,isLoadingMore, nextCursor, filter, searchValue, selected} = useSelector(state=>state.doctors);        
    const [formType, setFormType] = React.useState();   
    const [doctor, setDoctor] = React.useState()
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const subscription = useSubscriptionToken();
    const planAllowsDoctorInvitation = subscription.companyPermissions.includes('invite_doctors')
    
    
    const location = useLocation();

    console.log(location)    
    React.useEffect(()=>{        
        if(location.state?.from === "profile"){                 
            return
        }else if(filter['noCreados'] !== true){ 
            //added a 3 argument to fetch doctors in order to use new doctorsSearch state to separate state from autocomplete           
            dispatch(fetchDoctors(filter,null, true))
        }
        
    },[filter]);

    const handleOnClick = (formType)=>{
        setOpenModal(true);        
        setFormType(formType)
    }

    const loadMore = async()=>{    
        dispatch(fetchDoctors({},nextCursor))        
      }

    const smallBottomSwipeDrawers = ["addProducto", "options"]

    console.log(filter);

    const todosSelected = Object.keys(filter).length !== 0 || searchValue;
    // const connectadosSelected = filter['connected.status'] === true;
    // const invitadosSelected = filter['connected.invited'] === true ;
    // const invitacionSolicitada = filter['connected.invitationRequest.status'] === true
    // const invitacionSolicitadaNoCreados = filter['noCreados'] === true

    const connectadosSelected = selected === 'conectados';
    const invitadosSelected = selected === 'invitados'
    const invitacionSolicitada = selected === 'invitacion_solicitada'
    const invitacionSolicitadaNoCreados = selected === 'no_creados'
    const doctorInvitationFilterSelected = connectadosSelected || invitadosSelected || invitacionSolicitada || invitacionSolicitadaNoCreados
    

    let infoText;
    if(connectadosSelected) infoText = `Doctores conectados a ${user.company.name}`
    if(invitadosSelected) infoText = `Doctores invitados a conectarse con ${user.company.name}. En espera de respuesta.`
    //if(invitadosSelected) infoText = `Doctores invitados a conectarse con ${user.company.name}. En espera de respuesta.`
    if(invitacionSolicitada) infoText = `Doctores que han solicitado una invitacion por parte de  ${user.company.name} para conectarse.`
    if(invitacionSolicitadaNoCreados) infoText = `Doctores que han solicitado una invitacion por parte de  ${user.company.name} para conectarse pero no estan en la base de datos.`

    if(!planAllowsDoctorInvitation && doctorInvitationFilterSelected) infoText = `Su plan de subscripcion no permite invitar a doctores a conectar con ${user.company.name}`
 
    return (
        <ExternalWrapper>
            <Wrapper>            
                <Header>
                    <SearchBarWrapper>
                        <DoctorsSearchBar/>   
                        {canCrearEditarInvitarDoctores &&
                            <>
                                <StyledIconButton onClick={()=>handleOnClick('addDoctor')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>
                                {isTablet && <StyledIconButton onClick={()=>handleOnClick('upload')}><UploadCloud color={theme.palette.grey[700]}/></StyledIconButton>}                                
                            </>
                        }                         
                    </SearchBarWrapper>   
                    <FilterWrapper>
                        <DoctorsFiltros />
                        
                    </FilterWrapper>                      
                    {infoText && <CardInfo><Text>{infoText}</Text></CardInfo>}
                </Header>
                <Body>
                    {(!planAllowsDoctorInvitation && doctorInvitationFilterSelected) ? null : //checks if plan allows for doctor invitation interaction
                        doctorsSearch?.length === 0 && !isLoading ? 
                            <CardError>
                                No se encontraron doctores
                            </CardError>
                        :
                        
                        <DoctorCardGrid doctors={doctorsSearch} setDoctor={setDoctor} setOpenModal={setOpenModal} setFormType={setFormType}/>                        
                    }
                    <br/>
                 {nextCursor && !isLoading &&<Button fullsize={true} primary onClick={loadMore} loading={isLoadingMore}>Bajar mas doctores </Button>}                                       
                </Body>    
                
            </Wrapper>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}           
            >
                {formType === "addDoctor" && <CrearDoctor  close={close}/>}            
                {formType === "upload" && <DoctorsUpload close={close}/>}    
                {formType === "options" && <DoctorNew doctor={doctor} setFormType={setFormType} close={close}/>}                        
                {formType === "crearDoctor" && <DoctorCreate doctor={doctor} close={close}/>}                                                                 
                {formType === "fusionarDoctor" && <DoctorFusionar doctor={doctor} close={close}/>}                                                                 
            </Modal>             
            
        </ExternalWrapper>
        
    )
}


const ExternalWrapper = styled.div`
    height:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 36px;
    
`

const Wrapper = styled.div`
    width:100%;
    max-width: 1000px;
`

const Header = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-bottom: 16px;
`

const Body = styled.div`
    width:100%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 24px;

    @media ${p=>p.theme.media.tablet}{
        
    }

`


const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`

const SearchBarWrapper = styled.div`
    width:100%;
    display: flex;
    gap:8px;
    justify-content: center;
    align-items:center;    
`

const FilterWrapper = styled.div`
    display:flex;
    gap: 8px;
    flex-wrap: wrap;
    
`
const Text = styled.p`
    margin:0;
    font-size: 0.875rem;
`

export default DoctorsList;