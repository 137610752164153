import React from "react"
import styled from '@emotion/styled';
import FormActionsBasic from "../../../Atoms/FormActionsBasic"
import { FormBody, FormHeader, FormSubtitle, FormTitle, FormWrapper } from "../../../Atoms/Forms"
import { FormControlRadio, Radio } from "../../../Atoms/Radio"
import { FormControlLabel, Switch } from '@mui/material';
import EspecialidadSelect from "../../../Forms/CustomAutocomplete/EspecialidadSelect";
import e from "cors";
import { createOptions, goFetch } from "../../../../api/cirugiasApi";
import { useDispatch } from "react-redux";
import useSuccessButton from "../../../../hooks/useSuccessButton";
import { updateProductosArray } from "../../../../app/productosReducer";
import { errorMessage } from "../../../../app/messagesReducer";
import { apiUrl } from "../../../../shared/url";
import TextInput from "../../../Atoms/TextInput";
import useCheckForm from "../../../../hooks/useCheckForm";
import { isLength, isMaxLength, validate } from "../../../../shared/validators";
import { useAccionMaker } from "../../../../hooks/useAccionMaker";
import GrupoSelectMultiple from "../../../cotizaciones/molecules/GrupoSelectMultiple";

const AccesoCot = ({close, producto}) => {
    const dispatch = useDispatch()
    const [acceso, setAcceso] = React.useState(producto?.quickProducto?.active || false)
    const [especialidad, setEspecialidad] = React.useState(producto?.quickProducto?.especialidad || "");    
    const {isLoading, setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const accionMaker = useAccionMaker()
    const [grupo, setGrupo] = React.useState(producto?.quickProducto?.grupo || [])
    console.log(producto)
    console.log(grupo)
    const [values, setValues] = React.useState({        
        shortDescription: producto?.quickProducto?.shortDescription || "",
    })

    

      const [errors, setErrors] = React.useState({
        shortDescription: '',
        
      })

      const [formCompleted, errorCount] = useCheckForm(values,errors, ["shortDescription"])


      const onChangeValues = (event,validators, postCheck) => {    
        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }


    const onChange =()=>{
        console.log('clikcing')
        setAcceso(!acceso)

        if(acceso){
            setEspecialidad("")
            setValues(prevState=>({...prevState, shortDescription:""}))
        }
    }


    const onSubmit = async(e)=>{
        e.preventDefault()
        setIsLoading(true)

        const accion = accionMaker("Acceso a cotizacion actualizado")

        const infoAccesoCot = {
            active: acceso,
            grupo: grupo ||  [],
            shortDescription: values.shortDescription || "",
            accion
        }

        console.log(infoAccesoCot)

        const options = createOptions('PUT', infoAccesoCot)        
        try {
            const response = await goFetch(`${apiUrl}productos/quickProductos/${producto._id}`, options)
            responseStateChanges(response, ()=>dispatch(updateProductosArray(response.producto)), ()=>close())                                            
        } catch (error) {
            dispatch(errorMessage('Error al actualizar producto'))
        }

    }


    const onChangeGrupo = (e)=>{
        

        
        
        setGrupo(e.target.value)
    }


    return(
        <FormWrapper onSubmit={onSubmit} style={{maxWidth:'500px'}}>
            <FormHeader>
                Acceso a cotización
                <FormSubtitle>{`${producto?.codigo}- ${producto?.descripcion}`}</FormSubtitle>
            </FormHeader>
            
            <FormBody>
                <Wrapper>
                    <StyledFormControlLabel control={<Switch size="small" checked={acceso} onChange={onChange} />} label="Acceso Cotizacion" />
                    {acceso && 
                    <InputWrapper>                
                    <GrupoSelectMultiple
                        label="Grupo de Productos"
                        id="grupo"
                        name="grupo"
                        value={grupo}                        
                        onChange={onChangeGrupo}
                      
                      />                      
                    <TextInput 
                        label="Descripcion corta" name="shortDescription" id="shortDescription"
                        value={values.shortDescription}                    
                        onChangeHandler={(event)=>onChangeValues(event,[()=>isMaxLength(15, event.target.value)])}
                        helperText={!errors.shortDescription ? "Debe ser menos de 15 caracteres": errors.shortDescription}
                        error={errors.shortDescription}
                        onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(15, event.target.value)])}
                    />

                    
                    </InputWrapper>}
                </Wrapper>
            
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                disabledSuccess={acceso && (!formCompleted || errorCount>0)}
            />

            

        </FormWrapper>
    )

    }


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
    
`



const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-size: 0.875rem;
    }    
`



export default AccesoCot
    
