import { Step, StepLabel, Stepper } from "@mui/material"
import styled from '@emotion/styled/macro';
import { useDispatch } from "react-redux"
import { useCirugiaWithLoading } from "../../../hooks/useCirugia"
import useMediaQuery from "../../../hooks/useMediaQuery"
import Button from "../../Atoms/Button"
import IconButton from "../../Atoms/IconButton"
import TextInput from "../../Atoms/TextInput"
import ChevronLeft from "../../Icons/ChevronLeft"
import ChevronRight from "../../Icons/ChevronRight"
import { errorMessage } from "../../../app/messagesReducer";
import React from "react";
import { updateCirugia } from "../../../app/thunkActions/cirugiasActions";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import BeatLoader from 'react-spinners/BeatLoader'
import { useTheme } from "@mui/system";
import useUpdateCirugia from "../../../hooks/useUpdateCirugia";
import RefreshCw from "../../Icons/Refresh";


const Operaciones = ({
    cirugiaId
})=>{


    const [activeStep, setActiveStep] = React.useState(1);
    const tablet= useMediaQuery('tablet');
    const dispatch = useDispatch();
    const theme = useTheme();
    const [cirugia, isLoadingUpdate] = useCirugiaWithLoading(cirugiaId);
    const [isLoadingNext, setIsLoadingNext] = React.useState(false);
    const [isLoadingBack, setIsLoadingBack] = React.useState(false);
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia();
    
    console.log(isLoadingUpdate)
    const steps = React.useMemo(()=>[
        "Pendiente",
        "Preparada",
        "Despach.",
        "Entregada",
        "Retirada",
        "Recibida"
    ],[])

    React.useEffect(()=>{
        steps.forEach((step, index)=>{
          if(cirugia?.info?.statusOperaciones === step)
          setActiveStep(index+1)
        })
      },[cirugia, steps])

    const accionMaker = useAccionMaker();

    const handleNext = ()=>{
        
        let accion = accionMaker(`Cambio status operaciones a ${steps[activeStep]}`)
        
        // no need to adjust activeStep as is would equal zero, -1 +1 (+1 to increase step, but -1 to adjust for non zero start of activeStep)
        let operacionesInfo={
            cirugiaId: cirugiaId,
            statusOperaciones: steps[activeStep],
            accion: accion
        }

        updateCirugia('operaciones', operacionesInfo)
    }

    const handleBack = ()=>{   
        let accion = accionMaker(`Cambio status operaciones a ${steps[activeStep-1-1]}`)
        
        //subtracts two, one to adjust for active versus checkes, the second one to decrease from current place "go back".
        let operacionesInfo={
            cirugiaId: cirugiaId,
            statusOperaciones: steps[activeStep-1-1],
            accion: accion
        }    
                  
        updateCirugia('operaciones', operacionesInfo)
    }

    const refresh=()=>{

        let accion = accionMaker(`Cambio status operaciones a ${steps[0]}`)
        let operacionesInfo={
            cirugiaId: cirugiaId,
            statusOperaciones: steps[0],
            accion: accion
        }    
                  
        updateCirugia('operaciones', operacionesInfo)

    }

    
    return(
        <Wrapper>
            <Title>Operaciones</Title>
            <StepperWrapper>            
                <Stepper activeStep={activeStep} alternativeLabel={tablet?true:false}  orientation={tablet? "horizontal" : "vertical"}>
                    {
                        steps.map((step, index)=>(
                            <Step key={`${step} ${index}`}>
                                <StyledStepLabel>{step}</StyledStepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </StepperWrapper>
            
            <Actions>
                <Space>
                    <IconButton onClick={refresh}>
                        <RefreshCw size={18} color={theme.palette.grey[600]}/>    
                    </IconButton>   
                </Space>
                             
                <Controls>                       
                    <IconButton onClick={handleBack}>
                        <ChevronLeft size="32" color={theme.palette.grey[600]}/>
                    </IconButton>
                    <TextWrapper>
                            {
                                isLoading ? <BeatLoader size={8} color={theme.palette.grey[600]}/> 
                                :                                
                                <Text>{steps[activeStep-1]}</Text>                                                                
                            }
                    </TextWrapper>                                            
                    <IconButton onClick={handleNext}>
                        <ChevronRight size="32" color={theme.palette.grey[600]} />
                    </IconButton>
                </Controls>
                <Space/>
                    
                                   
            </Actions>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction:column;   
    width:100%;
    padding: 24px 12px;
    gap:12px;
    
    @media ${p=>p.theme.media.tablet}{
        padding: 24px 36px 12px ;
    }
`

const StyledStepLabel = styled(StepLabel)`
    & .MuiStepIcon-root.Mui-active{
            color: ${p=>p.theme.palette.grey[500]}
    }
`

const StepperWrapper = styled.div`
    flex:2;
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 120px;
    
    @media ${p=>p.theme.media.tablet}{
        margin-bottom:24px;
    }
`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    margin-bottom: 36px;    
`

const TextWrapper = styled.div`
    min-width: 175px;
    display:flex;
    justify-content: center;    
`

const Text = styled.p`
    margin:0;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0px 36px;
    color: ${p=>p.theme.palette.grey[800]}    
`


const Actions = styled.div`
    flex:2;
    max-height: 65px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
`

const Controls = styled.div`
    display:flex;
    align-items:center;
`

const Space = styled.div`
    flex:1
`



export default Operaciones;