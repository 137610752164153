import useMediaQuery from "../../hooks/useMediaQuery"
import Button from "./Button"
import { FormActions, FormActionsMobile } from "./Forms"



const FormActionsBasic = ({
    
    close,
    onSubmit,
    successText,
    cancelText,
    disabledSuccess,
    preIconSuccess,
    postIconSuccess,
    loading,
    successEvent,
    type,
    as,
    onCloseAction
})=>{
    //changed to isPhone as FormsActionMobile was being painted for a few ms on desktop before MediaQuery was loaded.Causing a brief flash of the desktop version of the form
    //const isTablet = useMediaQuery('tablet');
    const isPhone = useMediaQuery('phone');

    return (
        <>
           { isPhone ?
            <FormActionsMobile>
                {cancelText !== "none" && <Button outline error onClick={()=>{
                                                                            close() 
                                                                            onCloseAction && onCloseAction()
                                                                            }}
                                            >{cancelText || "Cancelar"}</Button> }
                <Button  
                    success
                    as={as}  
                    type={type || "button"}  
                    onClick={onSubmit} 
                    disabled={disabledSuccess}
                    preIcon={preIconSuccess || null}
                    postIcon={postIconSuccess || null}
                    loading={loading}
                    successEvent={successEvent}
                >
                    {successText || "Guardar"}
                </Button>   
            </FormActionsMobile>
            :
            <FormActions>
                {cancelText !== "none" && <Button outline error onClick={()=>{
                                                                                close()
                                                                                onCloseAction && onCloseAction()
                                                                            }}
                                            >{cancelText || "Cancelar"}</Button> }
                <Button  
                    success
                    as={as}  
                    type={type || "button"}
                    onClick={onSubmit || null} 
                    disabled={disabledSuccess}
                    preIcon={preIconSuccess || null}
                    postIcon={postIconSuccess || null}
                    loading={loading}
                    successEvent={successEvent}
                >
                    {successText || "Guardar"}
                </Button>    
            </FormActions>
            }
        </>
        
    )
}

export default FormActionsBasic;