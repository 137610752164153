import styled from '@emotion/styled/macro';
import Loading from '../../Atoms/Loading';
import { CardBody,CardTitle, CardWrapper as Card } from "../../Atoms/Card";




export const CirugiaCardSkeleton = ()=>{

    return(
        <StyledCard>
        <InnerWrapper>
            <Row style={{justifyContent: "space-between"}}>
                <Item>
                    <NameLoading/>
                    <NameLoading/>
                    
                </Item>    
                <Item style={{flexWrap: "wrap", justifyContent: 'flex-end'}}>
                    <ChipLoading/>
                    <ChipLoading/>                                    
                    <ChipLoading/>                                    
                </Item>    
            </Row>
            <Row style={{justifyContent: 'space-between', flex: '2'}}>
                <Item>                                
                    <Item column>
                        <LoadingText/>    
                        <LoadingText/>    
                        <LoadingText/>
                        <LoadingText/>
                    </Item>
                    <Item column>
                        <LoadingText/>    
                        <LoadingText/>    
                        <LoadingText/>
                        <LoadingText/>
                    </Item>
                </Item>
                <Item column>
                    <LoadingText/>    
                    <LoadingText/>    
                    <LoadingText/>
                    <LoadingText/>
                </Item>
                
            </Row>
            <Divider/>
            <Row style={{justifyContent: 'flex-end', alignItems: "center"}}>
                <LoadingButton/>
                <LoadingButton/>
                <LoadingButton/>
            </Row>
            
            
        </InnerWrapper>                
    </StyledCard>
    )
}





const CirugiasCardLoading = ()=>{


    const list = [1,2,3,4,5,6]


    return(
        <Wrapper>

            {
                list.map((list, index)=>(
                   <CirugiaCardSkeleton key={`${list}} ${index}`}/>

                ))
            }
            

            
        </Wrapper>
        
    )

}



const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:12px;
    
    align-items: center;
`

/*
const Card = styled(Loading)`    
    height: 100px;
    border-radius: 10px;    
`
*/

const StyledCard = styled(Card)`
    min-height: 400px;
    border-radius: 10px;   
    max-width: 800px;
    width: 100%;
    margin:0;
`

const InnerWrapper = styled.div`
    height: 100%;
    display:flex;
    gap: 16px;    
    flex-wrap: wrap;
    flex-direction: column;
`


const Row = styled.div`
    flex:1;
    display:flex;
    width: 100%;
    gap: 8px;
`

const Item = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: ${p=>p.column? "column": "row"}
    

`



const NameLoading = styled(Loading)`
    width: 100px;
    border-radius: 16px;
    height:30px
`

const ChipLoading = styled(Loading)`
    width: 65px;
    border-radius: 16px;
    height:20px
`
const LoadingText =styled(Loading)`
    width: 85px;
    height:20px;
    border-radius: 8px;
`

const LoadingButton = styled(Loading)`
    width: 80px;
    height:35px;
    border-radius: 8px;
`

const LoadingTextCentro =styled(Loading)`
    width: 175px;
    height:20px;
    border-radius: 8px;
`

const Divider = styled.div`
    border-top: 1px solid ${p=>p.theme.palette.grey[300]}
`

export default CirugiasCardLoading;