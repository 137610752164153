import styled from '@emotion/styled';
import React from 'react';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCirugia from '../../hooks/useCirugia';
import useMediaQuery from '../../hooks/useMediaQuery';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../Atoms/Forms';
import ProductosTableMobile from '../cirugias/Molecules/ProductosTableMobile';
import ProductTable from '../cirugias/Molecules/ProductTable';


const Consumo = ({
    cirugiaId,
    close
})=>{
    const isTablet =useMediaQuery('tablet');
    const cirugia = useCirugia(cirugiaId);    
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);
    const accionMaker = useAccionMaker(); 
    

    //create new array of product objects in order to be able to add cantidad usada. If not we get error - object is not extensible
    const initialValuesUsedProductos = React.useCallback(()=>{
        let newArray = cirugia?.productos?.map((producto)=>({
            _id: producto._id,
            producto: {
            _id:producto.producto._id,
            codigo: producto.producto.codigo,
            descripcion: producto.producto.descripcion
            },
        
            cantidad: producto.cantidad,
            cantidadUsada: ""
            }))
            return newArray
        },[cirugia]);
    

    const [productosArray, setProductosArray] = React.useState(initialValuesUsedProductos);


    //used to set initial product array after first render before initialValuesUsedProductos is ready
    React.useEffect(()=>{
        setProductosArray(initialValuesUsedProductos)
      },[cirugia, initialValuesUsedProductos])
       

    const onChangeHandler = (event, index)=>{
            const newArray = [...productosArray]
            console.log(newArray);
            console.log(newArray[index])
            newArray[index].cantidadUsada = event.target.value
            setProductosArray(newArray);
    }

    const onSubmit = (event)=>{
        event.preventDefault();
        const accion = accionMaker('Consumo de productos guardado');
        let infoProductos ={
            productos: productosArray, // array with only producto Id used to update database
            cirugiaId: cirugiaId,
            enviadoProductos: true,
            accion: accion
          }
        
        updateCirugia("update-productos",infoProductos)
    }

    // we pass cirugia and products seperatly because productosArray is a new productArray including a cantidadUsada property.

    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Consumos</FormTitle>
            <FormBody isTablet={isTablet}>
                {isTablet && <ProductTable consumo cirugia={cirugia} productos={productosArray} onChangeHandler={onChangeHandler}/>}
                {!isTablet && <ProductosTableMobile consumo cirugia={cirugia} productos={productosArray} onChangeHandler={onChangeHandler} />}
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
            />
        </FormWrapper>
    )
}


const Wrapper = styled.div`
`


export default Consumo;