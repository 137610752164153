import styled from '@emotion/styled/macro';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { CardBody,CardTitle, CardWrapper as Card } from "../../Atoms/Card";
import Avatar from '../../Atoms/Avatar';
import React from 'react';
import { fetchActiveUsers, fetchUsers } from '../../../app/thunkActions/usersActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import calcTimeAgo from '../../../shared/calcTimeAgo';
import IconButton from '../../Atoms/IconButton';
import UserPlus from '../../Icons/User-plus';
import ActivarMenuButton from './Molecules/ActivarMenuButton';
import Modal from '../../Atoms/Modal';
import DesactivarUser from '../Forms/DesactivarUser';
import ActivarUser from '../Forms/ActivarUser';
import UsersSearchBar from './Molecules/UsersSearchBar';
import InviteUser from '../Forms/InviteUser';
import UsersListLoading from './Molecules/UsersListLoading';
import { Link } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import useSubscriptionToken from '../../../hooks/useSubscriptionToken';
import CardSuccess, { StyledCardSuccess } from '../../Atoms/CardSuccess';
import SubscribedUsers from './Molecules/SubscribedUsers';
import Button from '../../Atoms/Button';



const Users=()=>{

    const dispatch = useDispatch();
    const isTablet =useMediaQuery('tablet');
    const permissions = usePermissions();
    const {canInvitarUsuarios} = permissions
    const { users, activeUsers, isLoading, searchedUsers } = useSelector(state=>state.users);
    const theme = useTheme(); 
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [userId, setUserId] = React.useState(null);
    const [formType, setFormType] = React.useState();
    const smallBottomSwipeDrawers = ["activar", "desactivar"];
    const [activeFilter, setActiveFilter] = React.useState('active');
    
    //console.log(users);

    React.useEffect(()=>{
        //dispatch active users as it is default filter
        dispatch(fetchActiveUsers());
    },[activeFilter]);
    const usersToShow = searchedUsers?.length > 0 
        ? searchedUsers 
        : (activeFilter === 'all' ? users : activeUsers);

    const onClickAddUser = ()=>{
        setFormType('addUser')
        setOpenModal(true);
    }
    
    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
    }

    return (
        <ExternalWrapper>
            <SubscribedUsers/>
            
            <Header>
                <HeaderContent>
                    <FilterButtons>
                        <Button 
                            onClick={() => {
                                dispatch(fetchActiveUsers());
                                handleFilterChange('active')
                            }} 
                            primary={activeFilter === 'active' && searchedUsers.length === 0} 
                            outline={activeFilter !== 'active' || searchedUsers.length > 0}
                        >
                            Usuarios activos
                        </Button>
                        <Button 
                            onClick={() => {
                                dispatch(fetchUsers('all'));
                                handleFilterChange('all')
                            }} 
                            primary={activeFilter === 'all' && searchedUsers.length === 0} 
                            outline={activeFilter !== 'all' || searchedUsers.length > 0}
                        >
                            Todos los usuarios
                        </Button>
                    </FilterButtons>
                    <SearchWrapper>
                        <UsersSearchBar />
                        {canInvitarUsuarios && <StyledIconButton onClick={onClickAddUser}><UserPlus color={theme.palette.primary.light}/></StyledIconButton>}
                    </SearchWrapper>
                </HeaderContent>
            </Header>

            {!usersToShow?.length > 0 && !isLoading &&  <div>No se encontraron Usuarios</div>}
            <GridWrapper isTablet={isTablet}>
                {
                    usersToShow?.length > 0 && usersToShow.map((user, index) => {
                        const userActive = !user.deactivated;
                        
                        let textColor = !user.deactivated ? theme.palette.grey[800] : theme.palette.grey[400];
                        const timeAgo = calcTimeAgo(user?.lastLogin) ? calcTimeAgo(user?.lastLogin) : "No disponible"

                        return (
                            <StyledCard key={`${user} ${index}`} style={{margin:0, paddingBottom: "8px"}}>
                                <CardLink to={`${user._id}`}>
                                    <StyledCardHeader>
                                        <Avatar disabled={user?.deactivated} src={user?.profilePicture} alt="user avatar">{user?.lastname ? user?.lastname[0]:null}</Avatar>
                                        <UserDescription style={{"--user-text-color":textColor}}>
                                            <StyledCardTitle>{`${user?.firstname} ${user?.lastname}`}</StyledCardTitle>
                                            <CardSubTitle></CardSubTitle>
                                        </UserDescription>
                                    </StyledCardHeader>
                                    <CardBody style={{flexDirection: "row"}}>
                                        <Container>
                                            <InfoContainer>
                                                <Text>{!user?.deactivated? "Activo" : "Inactivo"}</Text> 
                                                <TimeAgoText>{`Ultimo ingreso: ${timeAgo}`}</TimeAgoText>                                                              
                                            </InfoContainer>                                                                             
                                        </Container>                                    
                                    </CardBody>
                                </CardLink>
                                <ActivarAbsoluteWrapper>
                                    <ActivarMenuButton 
                                        userId={user._id} 
                                        setUserId={setUserId} 
                                        userActive={userActive} 
                                        setOpenModal={setOpenModal} 
                                        setFormType={setFormType}
                                    />
                                </ActivarAbsoluteWrapper>
                            </StyledCard>
                        )
                    })
                }                                                                                        
            </GridWrapper>
        
        <Modal
            openModal={openModal}
            setOpenModal={setOpenModal}
            inProp={inProp}
            setInProp={setInProp}
            close={close}
            smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
        >
            {formType === "activar" && <ActivarUser userId={userId} close={close}/>}
            {formType === "desactivar" && <DesactivarUser userId={userId} close={close}/>}
            {formType === "addUser" && <InviteUser userId={userId} close={close}/>}
                                
        </Modal>  

        </ExternalWrapper>
        
    )
}


const ExternalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 12px;
`

const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    
`

const FilterButtons = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    
`

const SearchWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
`

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    width: 100%;    
    gap: 8px;

    @media ${p=>p.theme.media.tablet}{
        width: 75%;
        margin-left:auto;
        margin-right:auto;
    }
`

const StyledCard = styled(Card)`
    position:relative;
`

const StyledCardTitle = styled(CardTitle)`
    margin:0;
    color: var(--user-text-color);    
`

const StyledCardHeader = styled.div`
    display:flex;
    gap: 12px;
    
    
`

const UserDescription = styled.div`
    flex-direction: column;
    justify-content: center;
    
    margin-bottom: 16px;
`

const CardSubTitle = styled.h6`
    margin: 0px;
    color: var(--user-text-color);    
`

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[600]};    
`
const TimeAgoText = styled.p`
    margin: 0;
    font-size: 0.8rem;
    color: ${p=>p.theme.palette.grey[600]};    
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    
`

const Container = styled.div`
    display:flex;
    justify-content: space-between;
`


const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.primary.light};
    background-color: #ffff;
`

const CardLink = styled(Link)`
    text-decoration: none;    
`

const ActivarAbsoluteWrapper = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: fit-content;
`


const CardSucessStyled = styled(StyledCardSuccess)`    
    align-self:flex-end;
    background-color: white;
`
export default Users;
    