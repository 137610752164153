import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSearchValueCirugias } from '../../../app/cirugiasReducer';
import BackButton from '../../Atoms/BackButton';
import SearchBar from '../../Atoms/SearchBar';


const CirugiasSearchBar = ({
    value,
    onChange,
    md
})=>{

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [backNavigation, setBackNavigation] = React.useState('/app/cirugias');

    React.useEffect(()=>{
        if(location.state?.path === '/app/pacientes'){
            setBackNavigation(location.state?.path)
        }
        if(md){
            setBackNavigation('/md-app/cirugias')
        }
        if(location.state?.path === '/app/pacientes'){
            setBackNavigation(location.state?.path)
        }
    },[])

    //let backNavigation = '/app/cirugias';
    // if(md) backNavigation = '/md-app/cirugias';
    // if(location.state?.path === '/app/pacientes'){
    //     backNavigation = location.state?.path
    // }

    console.log(backNavigation)

    return(
        <Wrapper>
            <BackButton
                onClick={()=>{
                    navigate(backNavigation);
                    dispatch(setSearchValueCirugias(''));                    
                }}
            />
            <SearchWrapper>
                <SearchBar
                    placeholder="Nro. cotizacion, NSS, nombre paciente"
                    label="Buscar cirugia"
                    value={value}
                    onChange={onChange}
                />
            </SearchWrapper>
            
        </Wrapper>
            
        
    )
}


const Wrapper = styled.div`
     margin-bottom: 16px;
     width:100%;
     display:flex;
     align-items: center;
     
     gap: 8px;
     
     

     @media ${p=>p.theme.media.tablet}{
        
        gap:32px;
        
     }
`

const SearchWrapper = styled.div`
    flex:1
`

export default CirugiasSearchBar;