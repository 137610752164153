import styled from "@emotion/styled/macro"



const CirugiaDataCard = ({
    when,
    values
})=>{


    return(
        <UpdateBox>
        <BoxTitle>{when}</BoxTitle>
        <BoxItem>Agendadas</BoxItem>
        <BoxItemData>{values?.agendada}</BoxItemData>
        <BoxItem>Realizadas</BoxItem>
        <BoxItemData>{values?.realizada}</BoxItemData>
        <BoxItem>Suspendidas</BoxItem>
        <BoxItemData>{values?.suspendida}</BoxItemData>
    </UpdateBox>
    )
}


const UpdateBox = styled.div`
    background-color: #ffff;
    border-radius: 4px;
    max-width: 100%;
    padding: 16px;
    box-shadow: 0.2px 0.3px 0.4px hsl(0deg 0% 63% / 0.41),
    0.8px 1px 1.4px -0.8px hsl(0deg 0% 63%/ 0.41),
    2px 2.5px 3.6px -1.7px hsl(0deg 0% 63% / 0.41),
    4.9px 6.2px 8.9px -2.5px hsl(0deg 0% 63% / 0.41);
    margin:8px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    min-width: 150px;
    height: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding:8px;
    margin:0px;
    justify-self: center;
    background-color: #fffff;
    border: 1px solid var(--box-border);
`


const BoxTitle = styled.div`
    flex:1;
    display:flex;
    align-items:center;
    color: ${p=>p.theme.palette.grey[700]}
    font-weight: 900;
    font-size: 1.4rem;
`

const BoxItem = styled.div`
    flex:1;
    display:flex;
    align-items:center;
    color: ${p=>p.theme.palette.grey[700]};
    font-size: 0.875rem;
`

const BoxItemData = styled(BoxItem)`    
    font-size: 1.15rem;
    font-weight: 700
`

export default CirugiaDataCard