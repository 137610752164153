import styled from '@emotion/styled/macro';
import { Link, Outlet } from 'react-router-dom';
import { CardBody, CardHeader, CardTitle, CardWrapper as Card } from "../Atoms/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly, faDownload, faReceipt, faUpload, faUser} from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/system';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermissions from '../../hooks/usePermissions';



const Admin = ()=>{

    const theme = useTheme();
    const isTablet = useMediaQuery('tablet');

    const permissions = usePermissions()

    const {
        canInvitarUsuarios,        
        canCrearProductos,
        canAccessBilling,
        isMaster        
    }=permissions
    

    return (
        <Wrapper isTablet={isTablet}>
            { canInvitarUsuarios &&
                <CardLink to="/app/admin/users">
                    <StyledCard>
                        <CardHeader>
                            <FontAwesomeIcon icon={faUser} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                            <StyledCardTitle>
                                Usuarios
                            </StyledCardTitle>
                        </CardHeader>                   
                        <CardBody>
                            <Text>Inivite y de accesos a sus usuarios.</Text>
                        </CardBody>
                    </StyledCard>    
                </CardLink>
            }
        { canCrearProductos &&
            <CardLink to="/app/admin/productos">
                <StyledCard>
                    <CardHeader>
                        <FontAwesomeIcon icon={faDolly} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                        <StyledCardTitle>
                            Productos
                        </StyledCardTitle>
                    </CardHeader>
                    <CardBody>
                        <Text>Cargue informacion como productos, </Text>
                    </CardBody>
                </StyledCard>
            </CardLink>
        }
        { false &&
            <StyledCard>
                <CardHeader>
                    <FontAwesomeIcon icon={faUpload} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                    <StyledCardTitle>
                        Carga de informacion
                    </StyledCardTitle>
                </CardHeader>
                <CardBody>
                    <Text>Cargue informacion como productos, </Text>
                </CardBody>
            </StyledCard>
        }
        { canAccessBilling &&
            <CardLink to="/app/admin/facturacion">
                <StyledCard>
                    <CardHeader>
                        <FontAwesomeIcon icon={faReceipt} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                        <StyledCardTitle>
                            Planes y Facturacion
                        </StyledCardTitle>
                    </CardHeader>
                    <CardBody>
                        <Text>Administre su plan e informacion sobre sus pagos. </Text>    
                    </CardBody>
                </StyledCard>  
            </CardLink>  
        }

            {false &&
                <StyledCard>
                    <CardHeader>
                        <FontAwesomeIcon icon={faDownload} style={{fontSize: '1.5em'}} color={theme.palette.grey[800]}></FontAwesomeIcon>
                        <StyledCardTitle>
                            Importar Datos
                        </StyledCardTitle>
                    </CardHeader>
                    <CardBody>
                        <Text>Sabe lo importante que son tus datos. Aca podras bajar la informacion que necesite tu empresa.</Text>       
                    </CardBody>
                </StyledCard>    
            }   

        </Wrapper>
        
    )
}



const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, auto));
    width: ${p=>p.isTablet ? '50%' : '100%'};
    margin-left:auto;
    margin-right:auto;
`



const CardLink = styled(Link)`
    text-decoration: none;
    

`
const StyledCardTitle = styled(CardTitle)`
    text-decoration: none;
    color: ${p=>p.theme.palette.grey[800]};
    margin: 0px;
    line-spacing: 0px;
    
`



const StyledCard = styled(Card)`

    min-width: 300px;
`

const Text = styled.p`
    margin:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]};
    
`

export default Admin;