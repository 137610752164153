import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetCentro } from '../../app/centrosReducer';
import { fetchCentro } from '../../app/thunkActions/centrosActions';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import usePermissions from '../../hooks/usePermissions';
import Button from '../Atoms/Button';
import { CardWrapper as Card, CardWrapper } from "../Atoms/Card";
import IconButton from '../Atoms/IconButton';
import Modal from '../Atoms/Modal';
import Edit from '../Icons/Edit';
import MapPin from '../Icons/MapPin';
import ResultsCardGrid from '../molecules/ResultsCardGrid';
import CentroEdit from './molecules/CentroEdit';
import DoctoresCentro from './molecules/DoctoresCentro';



const CentroProfile = ()=>{
    
    const dispatch = useDispatch();
    const permissions = usePermissions()

    const {
        canEditCentroInformation
    }=permissions

    const {centroId} = useParams();
    const { centros } = useSelector(state=>state.centros);
    const {centro:fetchedCentro, isLoading} = useSelector(state=>state.centros);
    const [formType, setFormType] = React.useState();  
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    
    const getDoctorFromDoctorsState = (centroId)=>{
        const centro = centros.filter((centro)=>(centro._id===centroId))
        return centro[0]
    }
    
    //looking for centro in Centros State from Centros List. 
    const _centro = getDoctorFromDoctorsState(centroId)
    
    // if doctor is available from previous navigation then use it. If not fetch from database. 
    React.useEffect(()=>{
        if(!_centro){
            dispatch(fetchCentro(centroId))
        }        
        return ()=>dispatch(resetCentro());

    },[_centro, dispatch, centroId])

    const centro = _centro || fetchedCentro;

    const hasLocation = centro?.location?.latitude && centro?.location?.longitude

    const handleOnClick = (formType)=>{
        setOpenModal(true);        
        setFormType(formType)
    }

    return (
        <Container>        
            <Wrapper>
                {
                    isLoading ? <div>Loading</div> :
                    <>
                        <InformacionGeneral>
                            <Item>
                                <Title>{centro?.nombre}</Title> 
                                {hasLocation && <IconButton><MapPin size="20"/></IconButton>   }
                            </Item>
                            <Item>                                
                                <Value>{centro?.ciudad}</Value>
                                { canEditCentroInformation &&
                                    <IconButton
                                    onClick={()=>handleOnClick("centroEdit")}
                                ><Edit size="18"/></IconButton>
                                }
                            </Item>                            
                        </InformacionGeneral>
                       <ResultsCardGrid target={centro} search={{centroId:centro?._id}}/>
                       <DoctoresCentro centroId={centro?._id}/>
                    </>                
                }
                            
            </Wrapper>
            <Modal
                    openModal={openModal}
                    setOpenModal={setOpenModal} 
                    inProp={inProp}
                    setInProp={setInProp}
                    close={close}                    
                >
                    {formType === "centroEdit" && <CentroEdit  close={close} centro={centro}/>}                                
                    
                </Modal>    

        </Container>
    )
}

const Container = styled.div`
    display:flex;
    justify-content: center;
`

const Wrapper = styled.div`
    display:flex;
    flex-wrap: wrap;
    
    justify-content: center;
    max-width: 1500px;
    flex:1;    
`


const InformacionGeneral = styled(CardWrapper)`
    max-width: 700px;
    min-width: 350px;
    display:flex;
    flex-direction: column;
    gap: 8px;
    flex:1;

    
`

const Title = styled.h1`
    margin: 0;
    font-size: 1.5rem;
    color: ${p=>p.theme.palette.grey[800]}

`
const Item = styled.div`
    display:flex;
    gap:8px;
    align-items: center;
    justify-content: space-between;
`

const Label = styled.p`
    margin:0;
`

const Value = styled.p`
    margin:0;

`



export default CentroProfile