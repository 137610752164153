import { createTheme } from '@mui/material/styles';



const customTheme =  createTheme({
    palette: {
        primary: {
          main: '#1976d2',
          contrastText: 'white',
          hover:'hsla(210 79% 56% /100)'
        },
        secondary:{
          main:"#9c27b0",
          light:"#ba68c8",
          hover: 'hsla(291 47% 70% /100)',
        },
        success:{
          main: "#2e7d32",
          light:"#4caf50",
          hover: 'hsla(122 39% 57% /100)',          
          pastel:'hsla(154 54% 87% /100)',
        },
        warning:{
          main: "#ed6c02",
          light:"#ff9800",
          hover: 'hsla(36 100% 60% /100)',
          pastel: 'hsla(50 89% 95% /100)'
        },
        error:{
          main: "#d32f2f",
          light:"#ef5350",
          hover: 'hsla(1 83% 73% /100)',
          pastel: 'hsla(2 82% 95% /100)'
        },
        highlight:{          
          green:'#c8e6c9',
          red: '#ffcdd2',
          purple:'#e1bee7',
          lime:'#f0f4c3',
          deepOrange: '#ffccbc'
        }
      },
    media:{
      tablet: '(min-width: 600px)',
      laptop: '(min-width: 900px)'
    }
})


export default customTheme;