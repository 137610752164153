import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import EspecialistasAutocomplete from './CustomAutocomplete/EspecialistasAutocomplete';
import useCommentMaker from '../../hooks/useCommentMaker';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const AgendarCirugia = ({
    cirugiaId,    
    close
})=>{

    const isTablet= useMediaQuery('tablet');    
    const [especialista, setEspecialista] = React.useState(null);
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);    
    
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker()
  
    
    const onSubmit = (event) =>{
        event.preventDefault();
        
        const commentObj = commentMaker(comments)
        const accion = accionMaker(`${especialista?.firstname} ${especialista?.lastname} asignado como EC`)
        
        let infoAsignar = {
            _id: especialista._id,
            firstname: especialista.firstname,
            secondname: especialista.secondname,
            lastname: especialista.lastname,
            secondLastname: especialista.secondLastname,
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion: accion
          }

        updateCirugia("asignar-cirugia",infoAsignar)
        }        
      
        const onChangeHandlerAutoComplete = (event, newValue)=>{
            setEspecialista(newValue)
        }
    
    

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Asignar Cirugia</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <EspecialistasAutocomplete
                    onChange={onChangeHandlerAutoComplete}
                />
                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />                                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default AgendarCirugia