import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody} from '../Atoms/Forms';
import { TextField } from '@mui/material';
import { useAccionMaker } from '../../hooks/useAccionMaker';
import useCommentMaker from '../../hooks/useCommentMaker';
import DoctorsAutocomplete from './CustomAutocomplete/DoctorsAutocomplete';
import useCirugia from '../../hooks/useCirugia';
import FormActionsBasic from '../Atoms/FormActionsBasic';
import useUpdateCirugia from '../../hooks/useUpdateCirugia';

const ModifyDoctor = ({
    cirugiaId,
    close
})=>{

    const isTablet = useMediaQuery('tablet');    
    const [selectedDoctor, setSelectedDoctor] = React.useState(null);
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCirugia} = useUpdateCirugia(close);
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();
    const cirugia = useCirugia(cirugiaId);
  
    
    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Doctor cambio de ${cirugia?.doctor?.lastname} a ${selectedDoctor?.lastname}`);

        
        let infoDoctor = {
            doctorId: selectedDoctor._id,
            firstname: selectedDoctor.firstname,
            secondname: selectedDoctor.secondname || "",
            lastname: selectedDoctor.lastname,
            secondLastname: selectedDoctor.secondname || "",
            fullname: selectedDoctor.firstname + " " + selectedDoctor.lastname,
            cirugiaId: cirugiaId,
            comentarios: commentObj,
            accion: accion
          }

        updateCirugia("update-doctor",infoDoctor)
        }        
      
       const onChangeHandler = (name,option)=>{                 
            setSelectedDoctor(option)
            setSelectedDoctor(prevState=> {console.log(prevState); return prevState})
       }

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet} >Modificar Doctor</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <DoctorsAutocomplete
                    onChange={onChangeHandler}
                />                  

                <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />                                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyDoctor