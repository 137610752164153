import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { resetMds } from "../../../app/mdReducer";
import { searchMds } from "../../../app/thunkActions/mdsActions";
import { apiUrl } from "../../../shared/url";

import SearchBar from "../../Atoms/SearchBar";




const CasaSearchBar = ({
    firstRender,
    setFirstRender,
    resetSearchValue,
    setResetSearchValue, 
    setCompanies,
    setCompanyLinks,
    setLinkRequests,
    status,
    setStatus
})=>{
    const [searchValue, setSearchValue] = React.useState();
    const [isLoading, setIsLoading] = React.useState();
    

    React.useEffect(()=>{
        setSearchValue('')
    },[resetSearchValue])

    
    const searchCompanies = async(searchTerm)=>{

        const options = createOptions("GET") 
        try {
            setIsLoading(true)
            const {companies, companyLinks, linkRequests} = await goFetch(`${apiUrl}md-users/casas-comerciales/${searchTerm}`, options)            
            setCompanies(companies)
            setCompanyLinks(companyLinks);
            setLinkRequests(linkRequests)   
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }       
                
    }



    const onChangeSearchValue = async (event)=>{
        event.preventDefault();
        //first render is tracked so that we dont show no results available when we firt reach the 3 character of the search
        if(status)setStatus('')    

        const searchTerm = event.target.value
        console.log(searchTerm);
        setSearchValue(searchTerm)
        setResetSearchValue(false)
        if(searchTerm.length>2){                        
            await searchCompanies(searchTerm)
            if(firstRender)setFirstRender(false);
            
        }

        if(!searchTerm){
            setFirstRender(true)
            setResetSearchValue(false)
            setCompanies([])
        }

                 
    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar"
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoading}
    />
    )
}



export default CasaSearchBar