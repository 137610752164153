import React from 'react';
import styled from '@emotion/styled';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { FormWrapper, FormTitle, FormBody, FormActions, FormHeader, FormSubtitle} from '../../Atoms/Forms';
import { useDispatch } from 'react-redux';

import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { updateUser } from '../../../app/thunkActions/usersActions';
import { productosSlice, updateProductosArray } from '../../../app/productosReducer';
import { errorMessage, successMessage } from '../../../app/messagesReducer';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { apiUrl } from '../../../shared/url';
import Button from '../../Atoms/Button';
import useCheckForm from '../../../hooks/useCheckForm';

const ProductoDesactivar = ({
    producto,
    setOpenModal
})=>{

    const isTablet= useMediaQuery('tablet');
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [successEvent, setSuccessEvent] = React.useState(false);

    

    const onSubmit= async(event)=>{
        event.preventDefault();
        setIsLoading(true)
        console.log("called on submit")
        let data = {
            deactivate:true,            
          }
        
        const options = createOptions("POST", data)
        try{
            const response = await goFetch(`${apiUrl}productos/deactivate/${producto._id}`, options)
            dispatch(successMessage(response.message));
            dispatch(updateProductosArray(response.producto));
            setIsLoading(false);
            setSuccessEvent(true);
            setTimeout(()=>setSuccessEvent(false), 2000)
            
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        

        }

    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormHeader isTablet={isTablet}>
                Desactivar Producto
                <FormSubtitle>{`${producto?.codigo}- ${producto?.descripcion}`}</FormSubtitle>
            </FormHeader>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <Wrapper>
                    <Text>El producto sera desactivado. No podra ser seleccionado para programar cirugias. Toda la informacion referente a este producto continuara guardada en la base de datos.</Text>
                    <Text>Puedes volver activar el producto en el futuro si lo necesitas.</Text>                                
                    <Text>Desea continuar con la desactivacion del producto?</Text>                                
                </Wrapper>
                
            </FormBody>
            
            <FormActions>
                <Button error outline onClick={()=>setOpenModal(false)}>Volver</Button>
                <Button
                      as="button" 
                      type="submit" 
                      success
                      loading={isLoading}
                      successEvent={successEvent}                      
                >Desactivar</Button>
            </FormActions>
            
            
        </FormWrapper>
    )
}


const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:12px;
    max-width: 500px;
    color: ${p=>p.theme.palette.grey[800]}
`

const Text = styled.p`
    margin:0;
`

export default ProductoDesactivar;