import Autocomplete from "../../Atoms/Autocomplete"
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductos } from "../../../app/thunkActions/productosActions";
import TextInput from "../../Atoms/TextInput";
import Button from '../../Atoms/Button';
import styled from '@emotion/styled';
import ProductTable from "./ProductTable";
import useCirugia from "../../../hooks/useCirugia";
//Wraps pacientes specific logic for autocomplete


const ProductosAutocomplete = ({
    onChange,
    clear,
    setClear
})=>{

    const dispatch = useDispatch();    
    const productos = useSelector(state=>state.productos.productos)
    
    React.useEffect(()=>{
     //   dispatch(fetchProductos())
    },[dispatch])

     //manages changes in keystroked in autocomplete to call up products
     const onInputChange = (e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length > 2){
            dispatch(fetchProductos(e.target.value))
        }
    }
    

    return(
        <Autocomplete                  
            label="Productos"
            name="productos"
            id="productos"
            onChange={onChange}
            onInputChange={onInputChange}
            options={productos}
            clear={clear}
            setClear={setClear}
            getOptionLabel={(option)=>`${option.codigo} - ${option.descripcion}`}
        />
    )
}





const Productos = ({
    onChangeProducts,
    onDeleteValuesProducts,
    cirugiaId,
    editCantidad=true,
    deleteProducts=true,
    dontShowCirugiaProductos
})=>{
    //onlyDescription is used to allow to only add product and not quanitity. This is used when EC add products during Consumo.

    // Get current cirugia if applicable
    const cirugia = useCirugia(cirugiaId); 
    
    const [producto, setProducto] = React.useState()
    const [cantidad, setCantidad] = React.useState();

    let initialUiProductosArray=[]

    //added in order to not show products when adding new products after cirugia was performed, i.e. during reporting of Consumo
    if(!dontShowCirugiaProductos && cirugia?.productos) {
        initialUiProductosArray=cirugia.productos
    }


    const [uiProductosArray, setUiProductosArray] = React.useState(initialUiProductosArray);
    const [clear, setClear] = React.useState();
    

    //gets state from Productos autocomplete
    const onChangeHandlerProducto = (event,option)=>{    
        if(!option)return;
        setProducto(option)
    }

    //gets state from cantidad
    const onChangeHandlerCantidad = (event)=>{
        setCantidad(event.target.value)
    }

    //adds product and cantidad to two arrays
    //1. Array for Ui table 2. Values state managed in form to send to backend
    const onAddProduct = ()=>{
        if(!editCantidad && !producto) return
        if(!producto || (editCantidad && !cantidad)) return

        //setProductosArray(prevState=>[...prevState,{producto:producto._id,cantidad}]);
        setUiProductosArray(prevState=>[...prevState,{producto,cantidad}]);
        
        //comes from Crear Cirugias, updates state of values to be sent to backend.
        //onChangeProducts({producto: producto._id, cantidad})
        onChangeProducts({producto, cantidad})
        setProducto(undefined);
        setCantidad(undefined);
        setClear(true);
    }

    //deletes products from both arrays
    const onDeleteProducts = (index)=>{
        //delet from Array that shows table in UI
        let newProductArray = [...uiProductosArray];
        newProductArray.splice(index,1);
        setUiProductosArray(newProductArray);

        //delete from Values which get sent to backend
        onDeleteValuesProducts(index);
    }

    
    return (
        <Wrapper>
            <Item>
                <Title>Productos</Title>
            </Item>
            <Item >
                <ProductosAutocomplete 
                    onChange={onChangeHandlerProducto}
                    clear={clear} 
                    setClear={setClear}                
                />    
            </Item> 
        {  editCantidad &&
            <Item>
                <TextInput
                    label="Cantidad"
                    name="cantidad"
                    id="cantidad"
                    value={cantidad}
                    onChangeHandler={onChangeHandlerCantidad}
                    
                />                
            </Item> 
            }           
            
            <Item style={{display:"flex", justifyContent:"flex-end"}}>
                <Button outline primary onClick={onAddProduct}>Agregar</Button>
            </Item>           
            
            <ProductsItem >
                <ProductTable 
                    productos={uiProductosArray}
                    onDeleteProducts={onDeleteProducts}
                    deleteProducts={deleteProducts}
                    
                />
            </ProductsItem>
        </Wrapper>
    )

   
}

const Title = styled.h3`

`

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;

    & div:not(:first-of-type){
        margin-bottom:8px;
    }
    
`

const Item = styled.div`
    width:100%;
`

const ProductsItem = styled(Item)`    
    margin-top: 8px;
`



export default Productos;
