import { addCirugia, addNextCirugias, addUpdatedCirugia, loading, loadingUpdate, loadingUpdateReset, setSuccess } from "../cirugiasReducer";
import { apiUrl } from "../../shared/url";
import {createOptions, goFetch} from "../../api/cirugiasApi";
import { addOneCirugia } from "../cirugiaReducer";
import useCirugia from "../../hooks/useCirugia";
import { errorMessage, successMessage } from "../messagesReducer";

export const fetchCirugias = (filter, nextCursor)=> async(dispatch)=>{

    const options = createOptions('POST', {filter: filter, nextCursor: nextCursor})
    let cirugias;
    
    try{
        if(!nextCursor) dispatch(loading())
        else dispatch(loadingUpdate())
        
        cirugias = await goFetch(apiUrl+ "cirugias/fetch-cirugias",options, dispatch );
    }catch(error){
        console.log(error);
    }

    //console.log(cirugias)
    if(!nextCursor){
        dispatch(addCirugia(cirugias));
    }else{
        console.log('dispatching next cirugias')
        dispatch(addNextCirugias(cirugias));
    }
    

}

export const fetchCirugia = (cirugiaId)=> async(dispatch)=>{



    const options = createOptions('GET')
    let cirugia;
    
    try{
        cirugia = await goFetch(apiUrl+ "cirugias/fetch-one-cirugia/" + cirugiaId,options, dispatch);
        console.log('cirugia received')        
        dispatch(addOneCirugia(cirugia))
    }catch(error){
        console.log(error);
    }
    

}


export const searchCirugia = (searchParameter)=> async(dispatch)=>{

    const options = createOptions('GET')
    let cirugias;
    
    try{
        cirugias = await goFetch(apiUrl+ "cirugias/search-cirugias/" + searchParameter,options, dispatch);
        dispatch(addCirugia(cirugias));
    }catch(error){
        console.log(error);
    }
    

}



export const crearCirugia =(values)=>async (dispatch)=>{

    

    const options = createOptions('POST', values)
    let response;

    try{
        dispatch(loadingUpdate()) 
        response = await goFetch(`${apiUrl}cirugias/create-cirugia`, options, dispatch)
        dispatch(setSuccess(true))
        dispatch(successMessage('Cirugia creada exitosamente.'))
        dispatch(loadingUpdateReset())        
        setTimeout(()=>dispatch(setSuccess(false)), 1500)
        console.log(response);
    }catch(error){
        console.log(error);
        dispatch(loadingUpdateReset())
        dispatch(errorMessage("Problema de servidor, por favor intente mas tarde."))
    }
}


/// replaced updateCirugia with useUpdateCirugia hook. Now we manage button loading and success state locally. 

export const updateCirugia =(url, values)=>async (dispatch)=>{
        
    const options = createOptions('PUT', values)
    let response;
    
    try{
        dispatch(loadingUpdate()) 
        response = await goFetch(`${apiUrl}cirugias/${url}/${values.cirugiaId}`, options, dispatch)        
        dispatch(addUpdatedCirugia(response))
        dispatch(setSuccess(true))
        dispatch(successMessage('Cirugia actualizada correctamente.'))
        dispatch(loadingUpdateReset())
        setTimeout(()=>dispatch(setSuccess(false)), 2000)
    }catch(error){
        console.log(error);
        dispatch(loadingUpdateReset())
        dispatch(errorMessage("Problema de servidor, por favor intente mas tarde."))
    }
}

