import { rolePermissions } from "../shared/roles";
import { useCurrentUser } from "./useCurrentUser";





const useHasPermissions = (permission, testUser, testRole)=>{

    //testRole come from component in order to update permisos selection as Role is changed

    
    const user = useCurrentUser();
    if(!testUser || testUser.role.md){    
        return false
        // if(typeof permission === 'string'){
        //     return user.permissions.include(permission) 
        // }
    }else{   //console.log(permission)
            //console.log(testUser.permissions)
            
            // Get role 
            // Get standard permission of that role
            // Get custom permissions
            // Check if permission being tested is part of the above permissions
            const role = Object.keys(testUser.role)[0]
            //console.log(role);
            let permissions = rolePermissions[testRole || role];
            //console.log(permissions);
            let totalPermissions = [...permissions, ...testUser.permissions]
           // console.log(totalPermissions);
            //console.log(totalPermissions.includes(permission))
            return totalPermissions.includes(permission)         
    }

}



export default useHasPermissions;